import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type DocumentFragment = { __typename?: 'Document', id: string, httpURL: string, filename: string, extension?: string | null, contentType?: string | null, fileType?: string | null, tags?: Array<string> | null, classification?: string | null, sizeInBytes: number, createdAt: any, sourcedAttributes?: Array<{ __typename?: 'AttributeMetadata', name: string, displayName: string, source?: string | null, parent?: string | null, dataType: string, filterable: boolean, groupable: boolean }> | null, streamGroups?: Array<{ __typename?: 'StreamGroup', group?: string | null, stream: { __typename?: 'Stream', id: string, name?: string | null } }> | null };

export const DocumentFragmentDoc = gql`
    fragment document on Document {
  id
  httpURL
  filename
  extension
  contentType
  fileType
  tags
  classification
  sourcedAttributes {
    name
    displayName
    source
    parent
    dataType
    filterable
    groupable
  }
  sizeInBytes
  createdAt
  streamGroups {
    stream {
      id
      name
    }
    group
  }
}
    `;