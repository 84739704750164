import { EuiThemeModifications } from 'ui';

export const AON_THEME: EuiThemeModifications = {
  colors: {
    LIGHT: { primary: '#EB0017' },
  },
};

export const AON_LOGO_STYLE = {
  logo: {
    height: '24px',
    margin: '12px 24px 0px 12px',
    width: '95px',
  },
};

export const WTW_THEME: EuiThemeModifications = {
  colors: {
    LIGHT: { primary: '#7f35b2' },
  },
};

export const WTW_LOGO_STYLE = {
  logo: {
    height: '24px',
    margin: '12px 24px 0px 12px',
    width: '95px',
  },
};

export const DEFAULT_LOGO_STYLE = {
  logo: {
    height: '24px !important',
    margin: '14px 24px 0px 12px',
    width: '140px !important',
  },
};

export const MARSH_THEME: EuiThemeModifications = {
  colors: {
    LIGHT: { primary: '#002C77' },
  },
};

export const MARSH_LOGO_STYLE = {
  logo: {
    height: '24px !important',
    margin: '14px 24px 0px 12px',
    width: '140px !important',
  },
};

export const AJG_THEME: EuiThemeModifications = {
  colors: {
    LIGHT: { primary: '#0075BC' },
  },
};

export const AJG_LOGO_STYLE = {
  logo: {
    height: '24px !important',
    margin: '14px 24px 0px 12px',
    width: '140px !important',
  },
};

export const ALLIANT_THEME: EuiThemeModifications = {
  colors: {
    LIGHT: { primary: '#0075BC' },
  },
};

export const ALLIANT_LOGO_STYLE = {
  logo: {
    height: '24px !important',
    margin: '14px 24px 0px 12px',
    width: '140px !important',
  },
};

export const ALT_LOGO_STYLE = {
  logo: {
    height: '20px !important',
    margin: 0,
    width: '25px !important',
  },
};
