import { createContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { IS_DEV_PLATFORM, MIXPANEL_PROXY_URL, MIXPANEL_TOKEN, PLATFORM } from '@app/config';
import { MockMixpanel } from './MockMixpanel';
import { ITrackerContext } from './types';

if (!IS_DEV_PLATFORM) {
  mixpanel.init(MIXPANEL_TOKEN, {
    api_host: MIXPANEL_PROXY_URL,
    persistence: 'localStorage',
  });
}

let warnedAboutMixpanel = false;

/* eslint-disable no-console */
export const mockMixpanel: MockMixpanel = {
  alias: () => {
    console.info('.mixpanel: alias');
  },
  has_opted_out_tracking: () => false,
  identify: () => {
    console.info('.mixpanel: identify');
  },
  opt_in_tracking: () => {
    console.info('.mixpanel: opt in tracking');
  },
  opt_out_tracking: () => {
    console.info('.mixpanel: opt out tracking');
  },
  people: {
    set: () => {},
    set_once: () => {},
  },

  register: () => {
    console.info('.mixpanel: register');
  },
  reset: () => {
    console.info('.mixpanel: reset');
  },
  track: (eventName, eventAttributes, callback) => {
    console.info('.mixpanel: track', eventName, eventAttributes);

    // HACK: It's a bit of a hack to call aptrinsic here, but it's the only way to get it done without going after all the 'mixpanel.' references.
    // FIX ME
    // @ts-ignore
    if (window?.aptrinsic) {
      console.debug('.aptrinsic: track', eventName, eventAttributes);
      // FIX ME
      // @ts-ignore
      window.aptrinsic('track', eventName, eventAttributes);
    }

    if (callback) {
      callback('1');
    }
  },
};

/* eslint-enable no-console */

export const value: ITrackerContext = {
  get mixpanel() {
    if (IS_DEV_PLATFORM) {
      if (warnedAboutMixpanel === false && PLATFORM !== 'testing') {
        warnedAboutMixpanel = true;
        console.warn(
          [
            'Mixpanel is called in dev mode.',
            'Returning a mock object which may not implement all required features.',
            'See components/Tracker/TrackerContext.tsx to add additional features.',
          ].join(' '),
        );
      }

      return mockMixpanel;
    }

    return mixpanel;
  },
};

export default createContext<ITrackerContext>(value);
