import styled from '@emotion/styled';

export const GridCell = styled.div`
  position: absolute;
  cursor: pointer;
  transition: all 0.2s ease;
`;

export const InnerElement = styled.div`
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
`;
