import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplySovJobMutationVariables = Types.Exact<{
  input: Types.ApplySovJobInput;
}>;


export type ApplySovJobMutation = { __typename?: 'Mutation', applySOVJob: { __typename?: 'Job', id: string, status: Types.JobStatus, type: Types.JobType, orgName: string, details?: string | null } };


export const ApplySovJobDocument = gql`
    mutation applySOVJob($input: ApplySOVJobInput!) {
  applySOVJob(input: $input) {
    id
    status
    type
    orgName
    details
  }
}
    `;
export type ApplySovJobMutationFn = Apollo.MutationFunction<ApplySovJobMutation, ApplySovJobMutationVariables>;

/**
 * __useApplySovJobMutation__
 *
 * To run a mutation, you first call `useApplySovJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplySovJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applySovJobMutation, { data, loading, error }] = useApplySovJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplySovJobMutation(baseOptions?: Apollo.MutationHookOptions<ApplySovJobMutation, ApplySovJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplySovJobMutation, ApplySovJobMutationVariables>(ApplySovJobDocument, options);
      }
export type ApplySovJobMutationHookResult = ReturnType<typeof useApplySovJobMutation>;
export type ApplySovJobMutationResult = Apollo.MutationResult<ApplySovJobMutation>;
export type ApplySovJobMutationOptions = Apollo.BaseMutationOptions<ApplySovJobMutation, ApplySovJobMutationVariables>;