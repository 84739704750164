import { css } from '@emotion/css';

const container = (isUpdating: boolean) => css`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
  .euiCheckbox {
    display: ${isUpdating ? 'none' : 'inherit'};
  }
`;

export default {
  container,
};
