import { css } from '@emotion/css';

const container = css`
  margin: 0 !important;
`;

const textContainer = css`
  text-align: center;
  width: 560px;
`;

export default {
  container,
  textContainer,
};
