import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgPreCheckConfigQueryVariables = Types.Exact<{
  input: Types.OrgPreCheckConfigInput;
}>;


export type OrgPreCheckConfigQuery = { __typename?: 'Query', orgPreCheckConfig: { __typename?: 'OrgPreCheckConfig', orgID: string, orgName: string, enablePreCheck: boolean, brandDomain?: string | null } };


export const OrgPreCheckConfigDocument = gql`
    query OrgPreCheckConfig($input: OrgPreCheckConfigInput!) {
  orgPreCheckConfig(input: $input) {
    orgID
    orgName
    enablePreCheck
    brandDomain
  }
}
    `;

/**
 * __useOrgPreCheckConfigQuery__
 *
 * To run a query within a React component, call `useOrgPreCheckConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPreCheckConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPreCheckConfigQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgPreCheckConfigQuery(baseOptions: Apollo.QueryHookOptions<OrgPreCheckConfigQuery, OrgPreCheckConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgPreCheckConfigQuery, OrgPreCheckConfigQueryVariables>(OrgPreCheckConfigDocument, options);
      }
export function useOrgPreCheckConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgPreCheckConfigQuery, OrgPreCheckConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgPreCheckConfigQuery, OrgPreCheckConfigQueryVariables>(OrgPreCheckConfigDocument, options);
        }
export type OrgPreCheckConfigQueryHookResult = ReturnType<typeof useOrgPreCheckConfigQuery>;
export type OrgPreCheckConfigLazyQueryHookResult = ReturnType<typeof useOrgPreCheckConfigLazyQuery>;
export type OrgPreCheckConfigQueryResult = Apollo.QueryResult<OrgPreCheckConfigQuery, OrgPreCheckConfigQueryVariables>;