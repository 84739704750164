import React from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  Button,
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  MultiSelect,
  Spacer,
  TextField,
  useToast,
} from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { GET_ORGANIZATION_PAGE } from '@app/queries/organizations/getOrganizationsPage';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import styles from './AdvancedTab.emotion';
import DeleteStreamButton from './DeleteStreamButton';
import useAdvancedTab from './useAdvancedTab';

const dataToOption = (data) => ({
    label: data?.label || data?.name,
    value: data?.value || data?.id,
  });

const getSelectedOptions = (fixedSelections, newSelections, selectionsMap) => {
  const fixedSelectionsMap = fixedSelections.reduce((acc, { label }) => ({ ...acc, [label]: dataToOption(selectionsMap[label]) }), {});

  const additionalSelections = newSelections.reduce((acc, { label }) => {
    if (!fixedSelectionsMap[label]) {
      return [...acc, dataToOption(selectionsMap[label])];
    }
    return acc;
  }, []);
  return [...fixedSelections, ...additionalSelections];
};

const AdvancedTab: React.FC = () => {
  const {
    copyOfStream,
    downloadValidationReport,
    exportOptions,
    getEmailsArray,
    membershipExpressionChanged,
    reportOptions,
    setFieldValue,
    streamConfiguration,
    touch,
  } = useAdvancedTab();

  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const toast = useToast();

  const { data, loading } = useQuery(GET_ORGANIZATION_PAGE, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    // do we need this? this is an admin-only view
    skip: !isAdmin,
    variables: {
      cursor: null,
      filters: [
        {
          name: 'ENABLE_SUBMISSION_DASHBOARD',
          operator: 'EQUAL',
          values: ['true'],
        },
      ],
      pageSize: 50,
      sortBy: [{ attributeName: 'name', order: 'ASCENDING' }],
    },
  });

  if (!copyOfStream || !setFieldValue || !touch) {
    return null;
  }

  const streamIncludedInSubmissionDashboards = streamConfiguration?.submissionDashboardOrgs || [];

  const initialSelected = streamIncludedInSubmissionDashboards.map(dataToOption);

  const options = data?.organizationsPage?.organizations.map(dataToOption);

  // The org ID is supposed to be an internal ID, but has been leaked in places. We haven't created
  // an external unique ID for orgs yet, so we have used org Name in recent APIs as the unique
  // identifier
  const organizationsMap = (data?.organizationsPage?.organizations || []).reduce((acc, org) => ({ ...acc, [org.name]: org }), {});

  return (
    <>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-email-list">
        <EuiFormRow fullWidth label="Enter list of emails">
          <TextField
            fullWidth
            placeholder="first.email@test.com,second.email@test.com,..."
            onChange={(event) =>
              setFieldValue('configuration.notificationEmails', getEmailsArray(event.target.value))
            }
            value={streamConfiguration?.notificationEmails?.join(',')}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-membership-expression">
        <EuiFormRow fullWidth label="Enter membership expression">
          <TextField
            fullWidth
            value={get(copyOfStream, 'configuration.membershipExpression') || ''}
            onChange={(event) =>
              setFieldValue('configuration.membershipExpression', event.target.value)
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-property-status">
        <EuiFormRow fullWidth label="Property Status Label">
          <TextField
            fullWidth
            value={get(copyOfStream, 'propertyStatusLabel') || ''}
            onChange={(event) => setFieldValue('propertyStatusLabel', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-exports-enabled">
        <EuiFlexGroup className={styles.inlineFlexGroup}>
          <EuiFlexItem className={styles.inlineCheckboxContainer} grow={false}>
            <EuiFormRow fullWidth>
              <Checkbox
                checked={!!get(copyOfStream, 'configuration.exportsEnabled')}
                id="exports-enabled"
                label="Exports Enabled"
                onChange={(event) =>
                  setFieldValue('configuration.exportsEnabled', event.target.checked)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          {streamConfiguration?.exportsEnabled && (
            <EuiFlexItem>
              <EuiFormRow fullWidth label="Export Formats">
                <MultiSelect
                  fullWidth
                  onChange={(value) => {
                    const finalValue = value.map((format) => format.value);
                    setFieldValue('configuration.allowedExports', finalValue);
                  }}
                  options={exportOptions}
                  initialSelected={
                    streamConfiguration?.allowedExports?.map((format) => ({
                      label: format,
                      value: format,
                    })) || []
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-reports-enabled">
        <EuiFlexGroup className={styles.inlineFlexGroup}>
          <EuiFlexItem className={styles.inlineCheckboxContainer} grow={false}>
            <EuiFormRow fullWidth>
              <Checkbox
                checked={!!get(copyOfStream, 'configuration.reportsEnabled')}
                id="reports-enabled"
                label="Reports Enabled"
                onChange={(event) =>
                  setFieldValue('configuration.reportsEnabled', event.target.checked)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          {streamConfiguration?.reportsEnabled && (
            <EuiFlexItem>
              <EuiFormRow fullWidth label="Enabled Reports">
                <MultiSelect
                  fullWidth
                  data-testid="enabled-reports-input"
                  onChange={(value) => {
                    const finalValue = !value.find((v) => v.value === 'RMAssessment')
                      ? value.filter((v) => v.value !== 'ZurichUwAR').map((report) => report.value)
                      : value.map((report) => report.value);

                    setFieldValue('configuration.allowedReports', finalValue);
                  }}
                  options={
                    streamConfiguration?.allowedReports?.includes('RMAssessment')
                      ? reportOptions
                      : reportOptions?.filter((o) => o.value !== 'ZurichUwAR')
                  }
                  initialSelected={
                    streamConfiguration?.allowedReports
                      ?.map((format) => {
                        const finalLabel = reportOptions?.filter(
                          (option) => option.value === format,
                        )[0]?.label;
                        if (!finalLabel) {
                          return null;
                        }
                        return {
                          label: finalLabel,
                          value: format,
                        };
                      })
                      ?.filter(Boolean) || []
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-share-loss-data">
        <EuiFormRow className={styles.checkboxContainer} fullWidth>
          <>
            <Checkbox
              checked={!!get(copyOfStream, 'configuration.shareLossData')}
              id="share-loss-data-enabled"
              label="Losses Enabled"
              onChange={(event) =>
                setFieldValue('configuration.shareLossData', event.target.checked)
              }
            />
            <Spacer size="s" />
            <EuiText color="subdued">
              If enabled, loss data is shown in map view and the property modal, as well as
              accessible via API.{' '}
            </EuiText>
            <EuiText color="subdued">
              The grid attributes, explorers, and microcharts are configured separately in the
              Attribute Metadata and Explorers Config tabs.
            </EuiText>
          </>
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-include-stream">
        <EuiFlexGroup className={styles.inlineFlexGroup}>
          <EuiFlexItem className={styles.includeStreamLabel} grow={false}>
            <EuiText size="s">Include {STREAMS_LABEL} In</EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow fullWidth label="Accounts List">
              <MultiSelect
                isLoading={loading}
                fullWidth
                placeholder="Select one or more options"
                data-testid="submission-dashboard-input"
                onChange={(selected) => {
                  const selectedOptions = getSelectedOptions(
                    initialSelected,
                    selected,
                    organizationsMap,
                  );
                  setFieldValue(
                    'configuration.submissionDashboardOrgNames',
                    selectedOptions.map(({ label }) => label),
                  );
                  return selectedOptions;
                }}
                isClearable={false}
                hideX={true}
                options={options}
                initialSelected={initialSelected}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <Spacer size="s" />
        <EuiText color="subdued">
          To disconnect an {STREAMS_LABEL} from the Accounts list, go to the Accounts page and
          remove the {STREAMS_LABEL} via the additional actions.
        </EuiText>
      </EuiFlexItem>
      {membershipExpressionChanged && (
        <EuiFlexItem>
          <EuiFormRow className={styles.checkboxContainer} fullWidth>
            <Checkbox
              checked={!!get(copyOfStream, 'updateMembership')}
              id="refresh-stream-membership"
              label="Refresh Stream Membership"
              onChange={(event) => setFieldValue('updateMembership', event.target.checked)}
            />
          </EuiFormRow>
        </EuiFlexItem>
      )}
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-download-report">
        <EuiFormRow fullWidth>
          <Button label="Download Validation Report" onClick={downloadValidationReport} />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-advanced-tab-delete-stream">
        <EuiFormRow fullWidth>
          <DeleteStreamButton />
        </EuiFormRow>
      </EuiFlexItem>
    </>
  );
};

export default AdvancedTab;
