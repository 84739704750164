import React from 'react';
import styled from '@emotion/styled';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Dimmer: React.FC = () => (
  <LoadingContainer>
    <LoadingSpinnerV2 />
  </LoadingContainer>
);

export default Dimmer;
