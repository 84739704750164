import React, { memo, useContext } from 'react';
import { areEqual } from 'react-window';
import classNames from 'classnames';
import { getStyleDefinition } from './getInlineStyles';
import { GridCell } from './Grid.emotion';
import GridContext from './GridContext';
import { Column } from './types';
import { getColumnId, getParentColumn } from './utils';

// TODO: Take from react-window
interface IProps {
  columnIndex: number;
  rowIndex: number;
}

const render = memo(<Col extends Column>({ columnIndex, rowIndex }: IProps) => {
  const { rows, columns, renderCell, expansions, rowHeight } = useContext(GridContext);

  const column = columns[columnIndex];
  const row = rows[rowIndex];
  const parentColumn = getParentColumn<Col>(columns, columnIndex);

  if (parentColumn !== undefined && expansions[parentColumn.id] === undefined) {
    return null;
  }

  if (!row) {
    return null;
  }
  if (row.id === undefined) {
    return null;
  }

  // TODO: Move this to @emotion
  const className = classNames(
    getStyleDefinition('column', getColumnId(column)),
    getStyleDefinition('width', getColumnId(column)),
  );

  const top = rowIndex * rowHeight;

  return (
    <GridCell
      data-testid={`cell-${rowIndex}-${columnIndex}`}
      data-tourid={`cell-${rowIndex}-${columnIndex}`}
      className={className}
      style={{
        top,
      }}
    >
      {renderCell({
        column,
        columnIndex,
        rowIndex,
      })}
    </GridCell>
  );
}, areEqual);

export default render;
