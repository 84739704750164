import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SnapshotFragment = { __typename?: 'Snapshot', id: string, organizationId: string, name: string, date: any, description?: string | null, bound: boolean, projectID?: string | null, visible: boolean, displayDate?: any | null, type: Types.SnapshotType };

export const SnapshotFragmentDoc = gql`
    fragment snapshot on Snapshot {
  id
  organizationId
  name
  date
  description
  bound
  projectID
  visible
  displayDate
  type
}
    `;