import { useQuery } from '@apollo/client';
import {
  GET_STREAM,
  GetStreamData,
  GetStreamVariables,
} from '@onarchipelago/cx/Reports/queries/getStream';
import { SnapshotTypeEnum } from '@app/queries/streams/types';
import { ReportsContext } from './types';

export default (streamSlug: string, isAdmin: boolean): ReportsContext['stream'] => {
  const { data, error, loading } = useQuery<GetStreamData, GetStreamVariables>(GET_STREAM, {
    skip: !streamSlug,
    variables: {
      isAdmin,
      slug: streamSlug,
      userCode: '',
    },
  });

  return {
    displayCurrency: data?.stream?.displayCurrency,
    hasError: !!error,
    loading,
    orgId: data?.stream?.organizationId,
    orgName: data?.stream?.orgName,
    snapshots: data?.stream?.snapshots?.filter((s) => s.type === SnapshotTypeEnum.STANDARD),
    streamId: data?.stream?.id,
    streamName: data?.stream?.name,
    streamSlug,
  };
};
