import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem, Spacer } from 'ui';
import { ChartValues } from '@app/queries/streams/types';
import ChartTitle from '../../../../Charts/ChartTitle/ChartTitle';
import BarRow from './BarRow';
import { ContainerHeight, ContainerWidth, PanelContainer } from './Charts.emotion';
import { currentAndPrevBound } from './const';
import LegendKey from './LegendKey';
import { LegendItem } from './types';

interface BarsProps {
  bars: ChartValues[];
  secondaryBars?: ChartValues[];
  keyItems?: LegendItem[];
  showBarLabels?: boolean;
  showTIV?: boolean;
  showKey?: boolean;
  title?: string;
  titleSize?: 'medium' | 'large';
  includeSecondBar?: boolean;
  justifyContentLegend?: EuiFlexGroupProps['justifyContent'];
  chartType: string;
}

const numOfBars = (
  includeSecondBar: boolean,
  bars: ChartValues[],
  chartType: string,
  showBarLabels: boolean = true,
  showTIV: boolean = false,
  keyItems: LegendItem[] = currentAndPrevBound,
  secondaryBars?: ChartValues[],
) => {
  if (secondaryBars) {
    return (
      <PanelContainer
        tabIndex={0}
        className={chartType === 'GeographyAndHazards' ? '' : 'eui-yScroll'}
        hasShadow={false}
        hasBorder={false}
      >
        {bars.map((item: any, idx: number) => (
          <EuiFlexItem key={item.label}>
            <BarRow
              values={item}
              secondaryValues={secondaryBars[idx] as any}
              showBarLabels={showBarLabels}
              showTIV={showTIV}
              title={item.label}
              includeSecondBar={includeSecondBar}
              keyItems={keyItems}
              chartType={chartType}
              testid={`${item.label}-bar`}
            />
          </EuiFlexItem>
        ))}
      </PanelContainer>
    );
  }
  return (
    <PanelContainer tabIndex={0} className={'eui-yScroll'} hasShadow={false} hasBorder={false}>
      {bars.map((item: any) => (
        <EuiFlexItem key={item.label}>
          <BarRow
            values={item}
            showPercentages
            showTooltips
            growFull
            showBarLabels={showBarLabels}
            showTIV={showTIV}
            title={item.label}
            includeSecondBar={includeSecondBar}
            keyItems={keyItems}
            chartType={chartType}
            testid={`${item.label}-bar`}
          />
        </EuiFlexItem>
      ))}
    </PanelContainer>
  );
};

const HorizontalBarChart: FC<BarsProps> = ({
  bars,
  secondaryBars,
  keyItems = currentAndPrevBound,
  showBarLabels = true,
  showTIV = false,
  showKey = true,
  title,
  titleSize = 'large',
  includeSecondBar = true,
  justifyContentLegend,
  chartType,
}) => (
    <ContainerWidth chartType={chartType} direction="column" gutterSize="none">
      <Spacer size="s" />
      {title && (
        <EuiFlexItem data-testid="risk-bar-chart-title" grow={false}>
          <ChartTitle titleSize={titleSize}>{title}</ChartTitle>
        </EuiFlexItem>
      )}
      <Spacer size="m" />
      <EuiFlexItem grow={false}>
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>
            <ContainerHeight direction="column" gutterSize="none" chartType={chartType}>
              {showKey && (
                <>
                  <EuiFlexGroup data-testid="risk-bar-chart-key" gutterSize="l">
                    <EuiFlexItem grow={3}>
                      <LegendKey
                        items={keyItems}
                        gutterSize="s"
                        justifyContent={justifyContentLegend}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={2}>{/* Dead space to constrain the Key */}</EuiFlexItem>
                  </EuiFlexGroup>
                  <Spacer size="m" />
                </>
              )}
              {numOfBars(
                includeSecondBar,
                bars,
                chartType,
                showBarLabels,
                showTIV,
                keyItems,
                secondaryBars,
              )}
            </ContainerHeight>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </ContainerWidth>
  );

export default HorizontalBarChart;
