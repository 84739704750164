import { AllExposureLevels, ExposureLevel, SegmentPercents } from '../types';

export default (percentScore: number, segmentPercents: SegmentPercents): ExposureLevel => {
  const level = AllExposureLevels.find((l) => percentScore <= segmentPercents[l]);

  if (!level) {
    throw new Error('Error analyzing percent score from segment values');
  }

  return level;
};
