import { GetStreamV2Data } from '@app/cx/Reports/queries/getStream';
import { TableRow } from '../cards/IndexBenchmarksCard/BenchmarksTable/benchmarksTableTypes';

const mainAttributeScores = {
  floodZoneHighRiskPct: true,
  quakeZonePct: true,
  sprinklersNonePct: true,
  windZonePct: true,
};
export const makeExposureRows = (
  data: GetStreamV2Data,
): [Array<TableRow>, Array<TableRow>, { [key: string]: Array<TableRow> }] => {
  const rowData = data?.streamV2?.stream?.exposure?.attributeScores || [];
  const allAttributeRows: Array<TableRow> = [];
  const mainAttributeRows: Array<TableRow> = [];
  const rowGroups = {};

  rowData?.forEach((r) => {
    const row = {
      gradeDefinitions: r?.gradeDefinitions,
      groupName: r?.groupName,
      label: r?.displayName,
      percentScore: r?.percentile,
      tiv: r?.TIVPct?.toFixed(1),
    };

    rowGroups[r?.groupName] ? rowGroups[r?.groupName].push(row) : (rowGroups[r?.groupName] = [row]);

    allAttributeRows.push(row);
    if (mainAttributeScores[r.name]) {
      mainAttributeRows.push(row);
    }
  });
  return [mainAttributeRows, allAttributeRows, rowGroups];
};
