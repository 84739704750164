import gql from 'graphql-tag';

export const UPDATE_JOB = gql`
  mutation updateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      name
      description
      attributeNames
      status
      doerProfile {
        userID
        email
        givenName
        familyName
        pictureURL
      }
      reviewerProfile {
        userID
        email
        givenName
        familyName
        pictureURL
      }
    }
  }
`;
