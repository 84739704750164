import React from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import { COPILOT_URL } from '@app/config';
import { ProtectedImage } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/ProtectedImage';
import { NavigationPage } from './types';

interface Props {
  page: NavigationPage;
}

export const SourcePage: React.FC<Props> = ({ page }) => (
  <EuiFlexGroup>
    <EuiFlexItem grow={3}>
      <ProtectedImage src={`${COPILOT_URL}/highlights/${page.cid}`} alt={page.cid} />
    </EuiFlexItem>
    <EuiFlexItem grow={1}>{page.text}</EuiFlexItem>
  </EuiFlexGroup>
);
