import { History } from 'history';
import { IStep } from '@app/components/Tour/types';
import { ExcludesFalse, ExcludesUndefined } from '@app/utils/typeguards';
import { MODE } from '../Distillers/types';
import { IPageState } from '../types';
import { getPageStateFromQueryState } from '../utils';
import { IStreamTourStep, IStreamTourStepActionParams } from './types';

const defaultPageState: Partial<IPageState> = {
  changesSince: null,
  expandedColumns: null,
  explorerTab: null,
  filters: null,
  groupByV2: [],
  mode: MODE.GRID,
};

type GatherDOMNodes = (selectors: Array<string>) => Array<Element>;
const gatherDOMNodes: GatherDOMNodes = (selectors) =>
  selectors
    .map((selector) => document.querySelector(selector), [])
    .filter(Boolean as any as ExcludesFalse);

const TIMEOUT = 20000;

const waitForSelectors = (selectors: Array<string>) =>
  new Promise<void>((resolve, reject) => {
    let nodes: Array<Element> = [];
    const timer = setTimeout(() => {
      const missingNodes = selectors.reduce((arr, selector) => {
        if (document.querySelector(selector)) {
          return arr;
        }

        return arr.concat(selector);
      }, [] as Array<string>);
      reject(
        new Error(`
      Could not find all elements within ${TIMEOUT} ms. Missing ${
          missingNodes.length
        } nodes, ${missingNodes.join(', ')}
    `),
      );
    }, TIMEOUT);

    const checkForNodes = () => {
      nodes = gatherDOMNodes(selectors);
      if (nodes.length === selectors.length) {
        observer.disconnect(); //eslint-disable-line
        resolve();
        clearTimeout(timer);
      }
    };

    const observer = new MutationObserver(() => {
      checkForNodes();
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    checkForNodes();
  });

type ConvertUrlToPageState = (url: string) => {
  path?: string;
  pageState?: Partial<IPageState>;
};
const convertUrlToPageState: ConvertUrlToPageState = (url) => {
  if (url.includes('/')) {
    return { path: url };
  }
  const qs = new URLSearchParams(url);
  const pageState = getPageStateFromQueryState(qs);
  return { pageState };
};

const wait = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

type PrepareSteps = (
  steps: Array<IStreamTourStep>,
  params: IStreamTourStepActionParams,
  history: History<any>,
) => Array<IStep>;
export const prepareSteps: PrepareSteps = (steps, { pageState, setPageState }, history) => {
  const callback = (
    {
      pageState: nextPageState = {},
      path,
    }: {
      pageState?: Partial<IPageState>;
      path?: string;
    },
    highlights: string,
  ) => {
    const fieldsNeedingUpdates = Object.entries(nextPageState).reduce((arr, [key, value]) => {
      const matches = JSON.stringify(value) === JSON.stringify(pageState[key]);

      if (!matches) {
        return arr.concat(key);
      }

      return arr;
    }, [] as Array<string>);

    if (fieldsNeedingUpdates.length) {
      setPageState({
        ...defaultPageState,
        ...nextPageState,
        highlights,
        userCode: pageState.userCode,
      });
    }

    if (path) {
      history.push(path);
    }
  };

  return steps.map((step, index) => ({
    ...step,
    action: async () => {
      const highlights = `${index + 1}`;
      if (step.url) {
        callback(convertUrlToPageState(step.url), highlights);
      } else {
        setPageState({
          highlights,
        });
      }

      // wait for changes in the URL to propagate
      await wait(1);
      const selectors = ([] as Array<string | undefined>)
        .concat(step.selectors || [], step.attach)
        .filter(Boolean as any as ExcludesUndefined);

      if (selectors.length) {
        await waitForSelectors(selectors);
        await wait(300);
      }
    },
  }));
};

export default prepareSteps;
