import React, { FC } from 'react';
import Img from '@app/components/Img/Img';
// FIX ME
// @ts-ignore
import defaultImage from '@app/containers/Property/default_property_image.png';
import { IGraphQLPicture, IGraphQLProperty } from '@app/queries/properties/types';

interface IProps {
  locationName: IGraphQLProperty['locationName'];
  pictures: IGraphQLProperty['pictures'];
  width?: number;
  height?: number;
  showDefault?: boolean;
}

type GetFilename = (pictures: Array<IGraphQLPicture> | undefined) => string;
const getFilename: GetFilename = (pictures) => {
  if (!pictures?.length) {
    return defaultImage;
  }
  const image = pictures[0];
  const { httpURLs } = image;

  return httpURLs?.original || httpURLs?.medium || httpURLs?.small || defaultImage;
};

const PropertyImg: FC<IProps> = ({ locationName, pictures, width, height, showDefault = true }) => {
  const src = getFilename(pictures);

  return (
    <Img
      src={src}
      height={height}
      width={width}
      alt={locationName || ''}
      title={locationName || ''}
      defaultSrc={showDefault ? defaultImage : undefined}
      delay={50}
    />
  );
};

export default PropertyImg;
