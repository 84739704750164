import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';

const border = '1px solid #343741';
const tickStyle = { borderTop: border, width: 5 } as any;
const textStyle = {
  left: -32,
  position: 'relative',
  textAlign: 'right',
  top: -10,
  width: 28,
} as any;

interface Props {
  scale: number[];
  showPercent?: boolean;
}
const VerticalRule: FC<Props> = ({ scale, showPercent = false }) => {
  const percent = showPercent ? '%' : '';
  return (
    <EuiFlexGroup
      gutterSize="none"
      direction="columnReverse"
      alignItems="flexEnd"
      style={{ width: 28 }}
    >
      <EuiFlexItem grow={false} style={{ ...tickStyle, height: 2 }}>
        <EuiText size="xs" style={{ ...textStyle }}>
          <small>
            {scale[0]}
            {percent}
          </small>
        </EuiText>
      </EuiFlexItem>
      {scale.splice(1, scale.length).map((item) => (
        <EuiFlexItem grow={1} style={tickStyle} key={item}>
          <EuiText size="xs" style={textStyle}>
            <small>
              {item}
              {percent}
            </small>
          </EuiText>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default VerticalRule;
