import React, { useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import CancelEditsModal from '@onarchipelago/cx/Stream/EditDataFlyout/CancelEditsModal';
import { JobErrors } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  ButtonEmpty,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiText,
  EuiTitle,
  useToast,
} from 'ui';
import { AnimatedPanel } from '@app/components/Flyout/AnimatedPanel';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { ModalContext } from '@app/contexts/ModalContext';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { GetStreamData, GetStreamVariables, IGraphQLStream } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import JobErrorsCallout from './Callouts/JobErrorsCallout';
import TimeRemainingCallout from './Callouts/TimeRemainingCallout';
import useSubmitEdit from './useSubmitEdit';
import { getUploadFlyoutTitle } from './utils';

interface Props {
  jobType: EJobType;
  hoursRemaining?: number;
  jobID: string;
  nSelectedAttributes: number;
  nSelectedProperties: number;
  stream: IGraphQLStream;
  worksheetLink: string;
  refetch: () => void;
}

const UploadData: React.FC<Props> = ({
  hoursRemaining,
  jobID,
  nSelectedAttributes,
  nSelectedProperties,
  stream,
  worksheetLink,
  jobType,
  refetch,
}) => {
  const [jobErrors, setJobErrors] = useState<JobErrors | null>(null);
  const [attemptRetry, setAttemptRetry] = useState<boolean>(false);
  const [worksheet, setWorksheet] = useState<File>();
  const { closeFlyout } = useContext(FlyoutContext);
  const toast = useToast();
  const { showModal } = useContext(ModalContext);
  const mixpanel = useTracker('mixpanel');
  const { account } = useAuth();

  const [getStreamQuery] = useLazyQuery<GetStreamData, GetStreamVariables>(GET_STREAM, {
    onCompleted: () => {
      toast({
        'data-testid': 'edited-your-properties-toast',
        label: 'Refreshing your stream now. It may take up to 20 minutes to see changes.',
        title: 'Edited your properties',
      });
      closeFlyout();
    },
  });

  const {
    submit,
    loading: loadingSubmit,
    retry: retrySubmit,
  } = useSubmitEdit({
    jobType,
    onAPIError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    onJobError: (errors) => setJobErrors(errors),
    onSuccess: () => {
      getStreamQuery({
        variables: {
          isAdmin: account?.permissions?.admin || false,
          slug: stream.slug,
          userCode: account?.userCode || '',
        },
      });
    },
    onTimeout: () => setAttemptRetry(true),
    stream,
  });

  const isEditData = jobType === EJobType.Enrichment || jobType === EJobType.EditLosses;

  const handleCancel = (): void => {
    if (isEditData) {
      sendMixpanelEvent(mixpanel, 'Edit Data Flyout - Click cancel edits', stream);
    }
    showModal(
      <CancelEditsModal
        closeFlyout={closeFlyout}
        jobID={jobID}
        stream={stream}
        refetch={refetch}
      />,
    );
  };

  const handleUpload = (files: FileList): void => {
    if (files?.length) {
      isEditData &&
        mixpanel.track('Manage Properties - Edit - Upload', {
          jobID,
          stream_id: stream.id,
          stream_name: stream.name,
          stream_slug: stream.slug,
        });
      setWorksheet(files[0]);
    }
  };

  const title = getUploadFlyoutTitle(jobType);

  return (
    <AnimatedPanel type="primary">
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <h2>{title}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm>
          {!!hoursRemaining && (
            <EuiFormRow>
              <TimeRemainingCallout hoursRemaining={hoursRemaining} />
            </EuiFormRow>
          )}
          <EuiFormRow>
            <EuiText>
              We downloaded a worksheet to your computer. Edit any data you like, then upload it
              here when you’re done. (If the file’s gone missing,{' '}
              <a href={worksheetLink}>download a new copy</a>
              .)
            </EuiText>
          </EuiFormRow>
          <EuiFormRow label="Selected Properties">
            <ButtonEmpty disabled label={`${nSelectedProperties} selected`} />
          </EuiFormRow>
          <EuiFormRow label="Selected Attributes">
            <ButtonEmpty disabled label={`${nSelectedAttributes} selected`} />
          </EuiFormRow>
          <EuiFormRow label="Upload Completed Worksheet">
            <EuiFilePicker data-testid="m-m-edit-file-picker" onChange={handleUpload} />
          </EuiFormRow>
          {!!jobErrors && (
            <EuiFormRow>
              <JobErrorsCallout jobErrors={jobErrors} />
            </EuiFormRow>
          )}
        </EuiForm>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <ButtonEmpty
              data-testid="m-m-edit-cancel"
              color="warning"
              onClick={handleCancel}
              label="Cancel Edits"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <Button
              data-testid="m-m-edit-submit"
              fill
              disabled={attemptRetry || !worksheet}
              loading={loadingSubmit}
              onClick={() => {
                if (attemptRetry) {
                  retrySubmit();
                } else {
                  setJobErrors(null);
                  submit(jobID, worksheet as File);
                }

                setAttemptRetry(false);
              }}
              label={attemptRetry ? 'Retry' : 'Submit edits'}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </AnimatedPanel>
  );
};

export default UploadData;
