import { useParams } from 'react-router-dom';
import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { usePrecheckContext } from '../context/PrecheckContext';
import { usePrecheckClient } from './usePrecheckClient';

const RANKING = gql`
  query propertiesToRankByPriority($input: PropertiesToRankByPriorityInput!) {
    propertiesToRankByPriority(input: $input) {
      properties {
        region
        revisionId
        archipelagoId
        organizationId
        orgName

        buildingReplacementCost
        buildingReplacementCostDisplay
        buildingReplacementCostPartner
        buildingReplacementCostPercentDifference
        buildingReplacementCostPercentDifferenceRange
        buildingReplacementCostValueDifference

        acquiredOrBuilt
        businessInterruptionCost
        businessInterruptionCostDisplay
        owner

        constructionType
        constructionTypeATC
        constructionTypeISO
        constructionTypeRMS
        contentsReplacementCost
        contentsReplacementCostDisplay

        fireEstimatedMaximumLoss
        fireProbableMaximumLoss
        floorArea

        geoCodeType

        hazardSummary
        seismicZone
        floodZone
        windZone

        numberOfHighPriorityRecommendations
        id
        lastEngineeringVisitDate

        location
        locationId
        locationName
        city
        county
        country
        latitude
        longitude
        streetAddress
        state
        postalCode

        pictures {
          filename
          httpURLs {
            original
          }
        }

        lossEngineeringReportPresent
        propertyManager
        modelingAvailable

        numberOfBuildings
        numberOfStoriesAboveGround

        occupancyType
        occupancyTypeATC
        occupancyTypeSIC
        occupancyTypeNAICS
        multiTenant
        primaryTenantNAICS

        businessContinuityPlans
        commodityHazardClass
        fireProtectionDescription
        leasedOrOwned
        securityDescription

        quakeScenarioEstimatedLoss
        quakeScenarioUpperLoss

        replacementCostMethodology
        replacementCostPerSquareFootage
        replacementCostPerSquareFootageDisplay
        replacementCostPerFloorAreaPartner
        replacementCostPerFloorAreaPartnerDifference

        roofInspectionProgram
        roofImage
        roofSystem
        roofInstallYear
        roofEstimatedReplacementYear

        seismicStatus

        buildingSprinklered
        buildingSprinklerType
        percentageSprinklered

        structuralDescription
        totalInsuredValue
        totalInsuredValueDisplay

        yearBuilt
        yearLastUpgraded
        yearsInPortfolio

        propertyName
        buildingDescription
        seismicHazardClass
        windHazardClass
        distanceToCoast
        baseFloodElevation
        hailHazardClass
        tornadoHazardClass
        lightningHazardClass
        wildfireHazardClass
        convectiveStormZone
        wildfireZone

        specificOccupancy
        acquisitionOrOccupancyDate
        numberOfStoriesBelowGround
        buildingFootprintClass
        firstFloorHeight
        distanceToNearestBuilding
        constructionTypeCluster
        constructionDescription
        constructionTypeArchipelago
        constructionTypeAIR
        buildingFoundationType
        constructionQuality
        structuralSystemUpgraded
        roofDescription
        roofDeckType
        roofGeometry
        roofParapets
        roofDrainageType
        roofingMaterialAnchorage
        roofDeckAnchorage
        roofSystemAnchorage
        roofEquipmentAnchorage
        rooftopSolarPanels
        roofChimneysAnchorage
        rooftopWaterTanks
        wallCladdingSystem
        windowType
        windowProtection
        glassPercentage
        sprinklerPipesSeismicallyBraced
        backupGenerator
        permanentFloodMeasuresPresent
        emergencyFloodProtectionMeasures
        fireProtectionInspectionProgram
        generatorTestingProgram

        enriched
        portfolio

        liquefactionSusceptibility
        landslideSusceptibility
        siteSoilClassification
        distanceToNearestFault
        tsunamiHazardClass
        seismicDesignQuality
        seismicDesignRValue
        roofHailMitigation
        roofSolarPanelOwnership
        roofSolarPanelCoverage
        roofSolarPanelDescription
        naturalGasAutomaticShutoff
        backupGeneratorLocation
        firstFloorElevation

        sprinklerLeakDetectionSystem
        remoteMonitoringOfSprinklerSystem
        waterSupplyFlowRate
        suitabilityOfFireProtectionMeasures
        backupGeneratorDieselStorageCapacity
        naturalGasPipesSeismicallyBraced
        originalAddress

        contingency
        exclusions
        occupancyDescription

        airEqBrickVeneer
        airEqBuildingCondition
        airEqBuildingExteriorOpening
        airEqBuildingShape
        airEqChimney
        airEqColdFormedTub
        airEqColumnBasementType
        airEqEquipment
        airEqFoundationConnection
        airEqFoundationType
        airEqInternalPartitions
        airEqIsFireSprinklerAvailable
        airEqIsValueType
        airEqLatticeType
        airEqMultiStoryHallType
        airEqOrnamentation
        airEqPounding
        airEqRedundancy
        airEqRetrofitMeasures
        airEqShortColumn
        airEqSoftStory
        airEqSpecialEQResistiveSystems
        airEqStructuralIrregularity
        airEqTallOneStory
        airEqTank
        airEqTorsion
        airEqTransitionInSRCType
        airEqWallSiding
        airEqWallType
        airEqWaterHeater
        airEqWeldingDetail
        airModifiers
        airWindAdjacentBuildingHeight
        airWindAppurtenantStructures
        airWindBaseFloodElevation
        airWindBaseFloodElevationUnit
        airWindBasementFinishType
        airWindBasementLevelCount
        airWindCertifiedStructuresIbhs
        airWindContentVulnerability
        airWindCustomElevation
        airWindCustomElevationUnit
        airWindCustomFloodStandard
        airWindCustomFloodZone
        airWindExteriorDoors
        airWindFirmCompliance
        airWindFirstFloorHeight
        airWindFirstFloorHeightUnit
        airWindFloorOfInterest
        airWindGlassPercentage
        airWindGlassType
        airWindLargeMissiles
        airWindRoofAnchorage
        airWindRoofAttachedStructures
        airWindRoofCovering
        airWindRoofCoveringAttachment
        airWindRoofDeck
        airWindRoofDeckAttachment
        airWindRoofGeometry
        airWindRoofPitch
        airWindRoofYearBuilt
        airWindSealOfApproval
        airWindServiceEquipmentProtection
        airWindSmallDebris
        airWindTerrainRoughness
        airWindTreeExposure
        airWindWallAttachedStructure
        airWindWindowProtection
        rmsEqAppendagesAndOrnamentation
        rmsEqBaseIsolation
        rmsEqConstructionQuality
        rmsEqContentsRateGradeEQ
        rmsEqCrippleWalls
        rmsEqEarthquakeSprinklerCoverageFlag
        rmsEqEngineeredFoundation
        rmsEqEnvelopeOpening
        rmsEqEquipmentEQBracing
        rmsEqEquipmentSupportMaintenance
        rmsEqExteriorWallsCladding
        rmsEqFrameBoltedtoFoundation
        rmsEqPounding
        rmsEqPurlinAnchoringTiltUpRetrofit
        rmsEqRedundancy
        rmsEqShapeConfiguration
        rmsEqShortColumnCondition
        rmsEqSoftStory
        rmsEqSprinklerLeakageSusceptibility
        rmsEqSprinklerType
        rmsEqStructuralUpgradeNonURM
        rmsEqTank
        rmsEqTorsion
        rmsEqUnreinforcedMasonryPartition
        rmsEqUnreinforcedMasonryRetrofit
        rmsEqVerticalIrregularitySetbackandOverhangs
        rmsModifiers
        rmsOtherBIPreparedness
        rmsOtherBIRedundancy
        rmsOtherPercentageContentsBelowGrade
        rmsRoofSheathingAttachment
        rmsWindBIDependency
        rmsWindBasementProtection
        rmsWindCauseOfLossFlag
        rmsWindCladding
        rmsWindCommercialAppurtenantStructures
        rmsWindConstructionQuality
        rmsWindContentsRateGradeWind
        rmsWindContentsVulnerabilityDueToFlood
        rmsWindContentsVulnerabilityDueToWind
        rmsWindCopingAndFlashingQuality
        rmsWindFinishedFloorHeightAboveGround
        rmsWindFinishedFloorHeightMatchLevel
        rmsWindFirstFloorElevation
        rmsWindFloodMissiles
        rmsWindFloodProtection
        rmsWindFloorType
        rmsWindFrameFoundationConnection
        rmsWindGroundLevelEquipment
        rmsWindOpeningProtection
        rmsWindResidentialAppurtenantStructures
        rmsWindResistanceDoors
        rmsWindRoofAge
        rmsWindRoofAnchorage
        rmsWindRoofEquipmentWindBracing
        rmsWindRoofFramingType
        rmsWindRoofGeometry
        rmsWindRoofMaintenance
        rmsWindRoofParapetsChimneys
        rmsWindRoofSystemCovering
        rmsWindVerticalExposureDistribution
        rmsWindWindMissiles

        lastValuationDate
        valuationCurrency

        numberOfUnits
        hasSeismicInspectionReport
        seismicInspectionDate

        organizationLevelOne
        organizationLevelTwo
        organizationLevelThree
        organizationLevelFour

        buildingExteriorOpening
        doorAndFrameType
        equipmentEarthquakeBracing
        elevationOfGroundOrBasementLevelMEPEquipment
        ornamentation
        roofScreensSignsAnchorageandBracing

        sovSortOrder

        buildingCondition
        buildingRedundancy
        contentsVulnerabilityFlood
        contentsVulnerabilityVerticalDistributionOfContents
        contentsVulnerabilityWind
        crippleWalls
        eqSpecialSystems
        floodMissiles
        floorSystem
        foundationToFrameConnection
        glassType
        interiorPartitions
        roofCopingAndFlashing
        roofStructures
        shortColumnConcrete
        softStory
        structuralUpgradeType
        surfaceRoughnessWind
        torsion
        verticalIrregularity
        waterHeaterBracing
        windMissiles

        exchangeRate
        exchangeRateDate
        currencyPair

        contingentBusinessInterruptionFlag
        contingentBusinessInterruptionComments
        dependencyFlag
        dependencyCoveragePercentage
        dependencyValue
        dependencyValueDisplay
        dependencyComments
        dispositionDate
        numberOfFireHazardAreas
        highHazardAreaSprinklerType
        sprinklerHeadSize
        sprinklerHeadTemperatureRating
        flammableLiquidDescription
        flammableLiquidStorageLocation
        flammableLiquidCapacity
        flammableLiquidsOnsite
        flammableLiquidProgram
        waterSupplyTime
        firePumpFlowRate
        firePumpPowerSupply
        firePumpBackupPeriod
        firePumpTestResults
        firePumpChurnRate
        firePumpExcessCapacity
        hydrantFlowTestResultsStatic
        hydrantFlowTestResultsResidual
        rackStoragePresent
        heightOfRackStorage
        storageArrangementDescription
        inRackSprinklerType
        hotWorkProgram
        controlOfCombustiblesProgram
        smokingControlsProgram
        valueInspectionProgram
        fireDetectionSystems
        flammableLiquidGasLineManagement
        portableFireExtinguisherProgram
        stockThroughputFactor
        stockThroughputInventoryValue
        stockThroughputInventoryValueDisplay
        stockThroughputExcessInventoryValue
        stockThroughputExcessInventoryValueDisplay
      }
    }
  }
`;

const SAVE_RANK = gql`
  mutation submitPropertyPriorityRanks($input: SubmitPropertyPriorityRanksInput!) {
    submitPropertyPriorityRanks(input: $input) {
      submission {
        properties {
          locationName
        }
      }
    }
  }
`;

export const useRanking = () => {
  const { streamId } = useParams<{
    streamId: string;
  }>();
  const client = usePrecheckClient();

  const { data, loading, refetch, networkStatus } = useQuery(RANKING, {
    client,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        streamSlug: streamId,
      },
    },
  });

  const [saveRank, { loading: saveLoading }] = useMutation(SAVE_RANK, {
    client,
  });

  return {
    data,
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
    saveLoading,
    saveRank,
  };
};
