import styled from '@emotion/styled';

export const DocumentColumn = styled.span`
  > svg {
    margin: 12px;
  }
`;

export const ActionError = styled.span`
  background-color: #bd271e; // $euiColorDanger
  border-radius: 50%;
  height: 32px;
  width: 32px;
  > svg {
    margin: 4px;
  }
`;

export const ActionUploading = styled.span`
  background-color: #e0e6eb; // $euiColorLightShade
  border-radius: 50%;
  height: 32px;
  width: 32px;
  > svg {
    margin: 4px;
  }
`;

export const ActionComplete = styled.span`
  background-color: #21b59a;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  > svg {
    margin: 4px;
  }
`;

export const ActionProcessing = styled.span`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  background-color: #e0e6eb; // $euiColorLightShade
  border-radius: 50%;
  height: 32px;
  width: 32px;
  > svg {
    animation: rotation 2s infinite linear;
    margin: 4px;
  }
`;
