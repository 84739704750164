import { Component } from '@app/queries/streams/types';
import { GradeColors } from './insurerInsightsTypes';

const abbreviatedFields = {
  CAEQ_AGGREGATION: 'CAEQ Aggregation',
  CAT: 'CAT (WS/EQ/FL/Tor/Hail)',
  CAT_FOOTPRINT: 'CAT',
  MGMT_PROGRAMS: 'Mgmt. Programs',
  RE_RISK_GRADE: 'RE Risk Grade',
  USWS_AGGREGATION: 'USWS Aggregation',
};

// This function transform text from "HAZARD_GRADE" to "Hazard Grade"
const normalizeTitle = (text: string) =>
  Object.keys(abbreviatedFields).includes(text)
    ? abbreviatedFields[text]
    : text
        .split('_')
        .join(' ')
        .toLowerCase()
        .replace(/\b\w/g, (l) => l.toUpperCase());

/**
 * Formats data to use on Highcharts
 * https://api.highcharts.com/highcharts/series
 */
const getChartData = (component: Component, gradedMetrics: string[] = []) => {
  if (component?.propertyAssessmentMetrics) {
    return component?.propertyAssessmentMetrics
      ?.map((metric) => ({
        color: GradeColors[metric.grade.color.toUpperCase()],
        data: [metric.pctOfAssessments],
        name: metric.grade.category,
        ...metric,
      }))
      .filter(Boolean);
  }

  if (component?.propertyGradeMetrics) {
    return component?.propertyGradeMetrics
      ?.map((metric) => {
        const name = metric?.grade.category;

        // The requirement for this conditional is that if the attribute is
        // RE_RISK_GRADE or PREDICTIVE_RISK_GRADE then we use `pctOfGradedTIV`
        // but instead of hardcoding those values in there, I added an argument
        // so we can pass an array in case we want to expand the data.
        const tiv = gradedMetrics.includes(component?.attribute)
          ? metric.pctOfGradedTIV
          : metric.pctOfTIV;

        return {
          color: GradeColors[metric.grade.color.toUpperCase()],
          data: [tiv],
          name,
          ...metric,
        };
      })
      .filter(Boolean);
  }

  return [
    {
      color: GradeColors[component.grade.color.toUpperCase()],
      data: [component.grade.value || 100],
      name: component.grade.category,
      ...component,
    },
  ];
};

export { getChartData, normalizeTitle };
