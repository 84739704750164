export const SUBHEADER_TOOLTIP =
  'The valuations integration excludes properties due to errors, missing attribute data, specific \
  property selection, or other reasons. Additionally, this integration only supports US valuations \
  and excludes non-US properties.';

export const OVERALL_DIFFERENCE_PERCENT_TITLE = 'Overall Valuation Difference (%)';
export const OVERALL_DIFFERENCE_PERCENT_TOOLTIP =
  'This is a percentage of the total Replacement Cost Value (RCV) provided on Archipelago.';
export const OVERALL_DIFFERENCE_DOLLAR_TITLE = 'Overall Valuation Difference ($)';
export const OVERALL_DIFFERENCE_DOLLAR_TOOLTIP =
  'This is the difference between the total Replacement Cost Value (RCV) and the total estimated valuation for all properties included in this report.';
