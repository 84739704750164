import { IAccountState } from '@app/containers/AuthProvider/types';
import { SelectedOrganizationSummary } from '@app/contexts/UserSessionContext';
import { IGraphQLStream, OrgInfo } from '@app/queries/streams/types';
import { FeatureFlag } from '@app/utils/FeatureFlags/types';

// checks to see if the account has access to the submission org  (if selected org has submissions enabled)
export const userHasSubmissionAccess = (
  orgs: OrgInfo[],
  selectedOrg?: SelectedOrganizationSummary,
) => {
  // find the selected org
  const hasSelectedOrg = !!orgs?.find((org) => org?.id === selectedOrg?.id);
  // return true if the account has the selected org and the org has submissions enabled, otherwise return false
  return hasSelectedOrg && selectedOrg?.enableAccountDashboard;
};

// returns the account's primary org if it is a submission org
export const getAccountSubmissionOrg = (account: IAccountState): OrgInfo => account?.submissionCreateOrgs?.find((org) => org?.name === account?.organizationName);
