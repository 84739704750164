import React, { useEffect, useRef, useState } from 'react';
import { ButtonEmpty, ContextMenu, EuiFlexGroup, EuiPanel, EuiSpacer, Tabs, useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useCasualtyPageQuery } from '@app/graphql/casualty/queries/__generated__/casualtyPage.generated';
import { useOrgSummaryQuery } from '@app/graphql/queries/organization/__generated__/OrgSummary.generated';
import { useInboxContext } from '@app/platform/Inbox/context/InboxContext';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import {
  FEATURE_PRECHECK_WORKFLOWS_V2,
  FEATURE_TYPE_ACTIVITY_LOG,
  FEATURE_TYPE_CASUALTY_ONLY,
} from '../../platform/SystemSettings/Flags/types';
import { Charts } from '../Charts/Charts';
import { Helmet } from '../Helmet';
import { tabToPath } from '../Layout/Layout';
import {
  CASUALTY_PATH,
  HOME_LABEL,
  PRECHECK_PATH,
  STREAMS_PATH,
} from '../Layout/SideNav/constants';
import { Card, CARD_CONFIG, CARD_CONFIG_CASUALTY } from './Card';
import { Dashboard } from '../Dashboard/Dashboard';
import { HomePageHeaderContainer } from './HomePage.emotion';
import { TrackInsights, useTracker } from '@app/hooks/useTracker';
import { EventsContainer } from './ActivityFeed/EventsContainer';
import { TaskProvider, useTaskContext } from '@app/precheck/Components/V2/context/TaskContext';
import { handlePDFExport } from '@app/utils/pdfExport';

export const HomePageWithoutContext: React.FC<{ taskCount: number }> = ({ taskCount }) => {
  const { account } = useAuth();
  const toast = useToast();
  const { selectedOrganization } = useUserSession();
  const contentRef = useRef();
  const mixpanel = useTracker('mixpanel');

  useEffect(() => {
    mixpanel.track(`${TrackInsights.prefix} Page viewed`, {
      event_surface: TrackInsights.event_surface,
      organization_id: selectedOrganization?.id,
      organization_name: selectedOrganization?.name,
    });
  }, []);

  const { loading: loadingOrgSummary, data: orgSummaryData } = useOrgSummaryQuery({
    skip: !selectedOrganization?.name,
    variables: { input: { orgName: selectedOrganization?.name } },
  });

  const casualtyOnly = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_CASUALTY_ONLY,
  );

  const { loading: loadingCasualty, data: dataCasualty } = useCasualtyPageQuery({
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !casualtyOnly,
    variables: {
      input: {
        offset: 0,
        orgName: selectedOrganization?.name,
        pageSize: 100,
      },
    },
  });

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties');

  const showActivityLog =
    isFeatureEnabled(selectedOrganization?.enabledFeatures, FEATURE_TYPE_ACTIVITY_LOG) &&
    canManageProperties;

  const orgSummary = orgSummaryData?.orgSummary;
  const getConfig = (key) => {
    if (key === 'casualty') {
      const config = { ...CARD_CONFIG_CASUALTY[key] };
      config.to = tabToPath(CASUALTY_PATH, selectedOrganization?.name);
      config.data = dataCasualty?.casualtyPage?.casualtyList?.length || 0;
      config.loading = loadingCasualty;
      return config;
    }
    const config = CARD_CONFIG[key];

    if (key === 'tasks') {
      config.to = '/inbox';
      config.data = taskCount;
    } else if (key === 'sov') {
      config.to = tabToPath(STREAMS_PATH, selectedOrganization?.name);
      config.data = orgSummary?.sovCount;
      config.loading = loadingOrgSummary;
    } else if (key === 'recommendations') {
      config.to = tabToPath(PRECHECK_PATH, selectedOrganization?.name);
      config.data = orgSummary?.propertyRecommendationsGrade;
      config.loading = loadingOrgSummary;
    }

    return config;
  };

  const renderCard = (configKey: string) => {
    const cardConfig = getConfig(configKey);
    return (
      <Card
        config={cardConfig}
        loading={!!cardConfig.loading}
        canManageProperties={canManageProperties}
      />
    );
  };

  if (casualtyOnly) {
    return (
      <div ref={contentRef}>
        <EuiPanel paddingSize="l">
          <Helmet title={HOME_LABEL} />
          <HomePageHeader contentRef={contentRef} isExportShown={false} />
          <EuiFlexGroup responsive>
            {Object.keys(CARD_CONFIG_CASUALTY).map(renderCard)}
          </EuiFlexGroup>
          <Charts />
        </EuiPanel>
      </div>
    );
  }

  return (
    <EuiPanel paddingSize="l">
      <Helmet title={HOME_LABEL} />
      <div ref={contentRef}>
        <HomePageHeader contentRef={contentRef} />
        <EuiFlexGroup responsive>{Object.keys(CARD_CONFIG).map(renderCard)}</EuiFlexGroup>
        {showActivityLog ? (
          <>
            <EuiSpacer size="l" />
            <Tabs
              initialTabId={'dash'}
              size="l"
              bottomBorder
              tabs={[
                {
                  content: <Dashboard />,
                  id: 'dash',
                  label: 'Dashboard',
                },
                {
                  content: <EventsContainer />,
                  id: 'list',
                  label: 'Activity Log',
                },
              ]}
            />
          </>
        ) : (
          <Dashboard />
        )}
      </div>
    </EuiPanel>
  );
};

const HomePageHeader = ({ contentRef, isExportShown = true }) => {
  const handleExportPDF = () => {
    handlePDFExport(contentRef.current, 'homepage');
  };

  const { selectedOrganization } = useUserSession();
  const [isPopoverOpen, setPopover] = useState(false);

  return (
    <HomePageHeaderContainer
      pageTitle={selectedOrganization?.name}
      rightSideItems={
        isExportShown
          ? [
              <ContextMenu
                key="export-menu"
                open={isPopoverOpen}
                onClose={() => setPopover(false)}
                panel={{
                  items: [
                    {
                      icon: 'document',
                      label: 'Export to PDF',
                      onClick: handleExportPDF,
                    },
                  ],
                  width: 160,
                }}
                anchorPosition="downRight"
                button={
                  <ButtonEmpty
                    onClick={() => setPopover(!isPopoverOpen)}
                    iconSide="right"
                    iconName="chevronDown"
                    label="Export"
                  />
                }
              ></ContextMenu>,
            ]
          : null
      }
    />
  );
};

const HomePageWithV1Tasks = () => {
  const { inbox } = useInboxContext();

  return <HomePageWithoutContext taskCount={inbox?.all?.length || 0} />;
};

const HomePageWithV2Tasks = () => {
  const { tasks } = useTaskContext();
  return <HomePageWithoutContext taskCount={tasks.length} />;
};

export const HomePage = () => {
  const { selectedOrganization } = useUserSession();
  const isPrecheckWorkflowsV2Enabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_PRECHECK_WORKFLOWS_V2,
  );

  if (isPrecheckWorkflowsV2Enabled) {
    return (
      <TaskProvider>
        <HomePageWithV2Tasks />
      </TaskProvider>
    );
  } else {
    return <HomePageWithV1Tasks />;
  }
};
