import { DataPayloads, DataType, LoadMore, SetData } from '../types/commonTypes';
import { isEmptyObject } from '../utils/utils';

// Summary: This hook provides a `loadMore` function, which, when invoked, fetches the additional
// page of the paginated data.

interface Props {
  cursor: string | undefined;
  dataType: DataType;
  fetchMore: any;
  setData: SetData;
}

export default ({ cursor, dataType, fetchMore, setData }: Props): LoadMore => {
  const updateQuery = (
    prev: DataPayloads,
    { fetchMoreResult }: { fetchMoreResult: DataPayloads },
  ): {} => {
    const noPrev = isEmptyObject(prev) || !prev;
    const noFetchMoreResult = isEmptyObject(fetchMoreResult) || !fetchMoreResult;
    if (noPrev && noFetchMoreResult) {return {};}
    if (noPrev && !noFetchMoreResult) {return fetchMoreResult;}
    if (!noPrev && noFetchMoreResult) {return prev;}

    let finalTableData: DataPayloads;

    if (dataType === 'documents') {
      finalTableData = {
        ...fetchMoreResult,
        organizationDocumentsPage: {
          // @ts-expect-error: not concerned that result could possible have an alternative shape
          ...fetchMoreResult.organizationDocumentsPage,
          documents: [
            // @ts-expect-error: not concerned that result could possible have an alternative shape
            ...prev.organizationDocumentsPage.documents,
            // @ts-expect-error: not concerned that result could possible have an alternative shape
            ...fetchMoreResult.organizationDocumentsPage.documents,
          ],
        },
      };
    } else if (dataType === 'properties') {
      finalTableData = {
        ...fetchMoreResult,
        organizationDocumentsPropertiesPage: {
          // @ts-expect-error: not concerned that result could possible have an alternative shape
          ...fetchMoreResult.organizationDocumentsPropertiesPage,
          properties: [
            // @ts-expect-error: not concerned that result could possible have an alternative shape
            ...prev.organizationDocumentsPropertiesPage.properties,
            // @ts-expect-error: not concerned that result could possible have an alternative shape
            ...fetchMoreResult.organizationDocumentsPropertiesPage.properties,
          ],
        },
      };
    } else {
      throw new Error('invalid data type parameter');
    }

    // @ts-expect-error: not concerned that result could possible have an alternative shape
    setData(finalTableData);

    // `finalTableData` *must* be returned or else infinite scrolling pagination will not work.
    return finalTableData;
  };

  return () =>
    fetchMore?.({
      updateQuery,
      variables: {
        cursor,
      },
    });
};
