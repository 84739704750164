// this is duplicated in `apps/client/src/cx/Reports/ReadinessReport/ReadinessReport.emotion.ts` in emotion style
// remove once all references to this are exhausted
export const reportPageStyle = {
  height: '100%',
  margin: '0px auto',
  padding: '0px 32px',
  // Desired panel width is 1164 + 32 padding each side + 12 each side to offset flexgroup margins
  width: '1252px',
};

// this is duplicated in `apps/client/src/cx/Reports/ReadinessReport/ReadinessReport.emotion.ts` in emotion style
// remove once all references to this are exhausted
export const flexColumnStyle = {
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
} as React.CSSProperties;

export const headingStyles = {
  fontWeight: 800,
};

export const filtersStyle = {
  paddingBottom: '16px',
};
