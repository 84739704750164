import React, { FC } from 'react';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ProtectedModalRoutes from '@app/containers/App/Routes/ProtectedModalRoutes';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import StreamPage from '../StreamPage';
import { useStreamContext } from '../StreamProvider';
import ErrorMessage from './ErrorMessage';
import Redirect from './Redirect';
import { Props } from './types';
import { useStreamRenderStepTwo } from './useStreamRenderStepTwo';

const StreamRenderStepTwo: FC<Props> = ({ options }) => {
  const { account } = useAuth();
  const { loading, error, stream } = useStreamContext();
  const { pageState, setPageState, userCode } = useStreamRenderStepTwo(account);

  if (loading) {
    return <LoadingSpinnerV2 dataTestId="loading-spinner" />;
  }

  if (!userCode) {
    return <ErrorMessage />;
  }

  if (error || stream === undefined) {
    return <Redirect path="/" />;
  }

  return (
    <>
      <ProtectedModalRoutes />
      <StreamPage
        options={options}
        account={account}
        pageState={pageState}
        setPageState={setPageState}
      />
    </>
  );
};

export default StreamRenderStepTwo;
