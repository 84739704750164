import { useHistory } from 'react-router-dom';
import { MAIN_APP_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';

export const useRedirectFromPrecheck = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();

  if (!selectedOrganization) {return;}

  // any legacy non archipelago users that are on old beta domain, redirect them to main app domain
  const redirectFromPrecheck =
    window.location.host.includes('platform-beta') && !account.email.includes('onarchipelago.com');

  if (redirectFromPrecheck) {
    window.location.replace(`${MAIN_APP_URL}/${selectedOrganization.name}/precheck`);

    
  }
};
