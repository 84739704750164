import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from 'ui';

import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { colorMap } from './constants/colorMap';
import { labelMap } from './constants/labelMap';
import { StyledBadge } from './TaskTable.emotion';
import { useTaskContext } from './context/TaskContext';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import {
  StyledBadgeContainer,
  StyledDescription,
  StyledExamples,
  StyledLoadContainer,
  StyledPanel,
  StyledPercent,
} from './DataCompleteness.emotion';
import { AttritionalReason, CATReasons, COPEReason } from './context/types';

const formatPercentage = (tiv, totalTiv) => {
  const percent = (tiv / totalTiv) * 100;

  if (percent < 1 && percent > 0) {
    return percent.toFixed(2);
  } else {
    return Math.round(percent);
  }
};

export const DataCompleteness = () => {
  const { filters, totalTiv, taskTotals, loading, error } = useTaskContext();

  const copeActive = filters.reasons.includes(COPEReason.cope);
  const cats = Object.values(CATReasons);

  const catActive = cats.every((reason) => filters.reasons.includes(reason));
  const attrActive = filters.reasons.includes(AttritionalReason.attritional);

  if (loading) {
    return (
      <StyledLoadContainer>
        <LoadingSpinnerV2 />
      </StyledLoadContainer>
    );
  }

  if (error) {
    console.error(error);
    return <div>Oops... Something went wrong, please try again</div>;
  }

  return (
    <EuiFlexGroup wrap>
      <EuiFlexItem style={{ minWidth: '400px' }}>
        <a
          style={{ color: 'unset' }}
          href="javascript:void(0)"
          onClick={(e) => {
            e.preventDefault();
            if (copeActive) {
              filters.setFilter(
                'reasons',
                filters.reasons.filter((r) => r !== COPEReason.cope),
              );
            } else {
              filters.setFilter('reasons', [...filters.reasons, COPEReason.cope]);
            }
          }}
        >
          <StyledPanel active={copeActive} hasShadow={false}>
            <StyledBadgeContainer>
              <StyledBadge withMargin color={colorMap[COPEReason.cope]}>
                {labelMap[COPEReason.cope]}
              </StyledBadge>
            </StyledBadgeContainer>
            <EuiSpacer size="m" />
            <div style={{ minHeight: '50px' }}>
              <EuiTitle size="xxs">
                <h3>
                  <StyledPercent>{formatPercentage(taskTotals.cope.tiv, totalTiv)}%</StyledPercent>(
                  {formatStreamCurrency(taskTotals.cope.tiv)}) of total TIV is missing relevant COPE
                  data
                </h3>
              </EuiTitle>
            </div>

            <EuiSpacer size="xs" />

            <StyledDescription>
              <EuiText size="s">
                COPE data provides the fundamental property information needed to assess catastrophe
                and all other peril risks.
              </EuiText>
            </StyledDescription>

            <EuiSpacer size="xs" />

            <StyledExamples>
              Examples: construction type, year built, fire protection
            </StyledExamples>
          </StyledPanel>
        </a>
      </EuiFlexItem>
      <EuiFlexItem style={{ minWidth: '400px' }}>
        {' '}
        <a
          style={{ color: 'unset' }}
          href="javascript:void(0)"
          onClick={(e) => {
            e.preventDefault();
            if (catActive) {
              filters.setFilter(
                'reasons',
                filters.reasons.filter((r: CATReasons) => !cats.includes(r)),
              );
            } else {
              filters.setFilter('reasons', [...filters.reasons, ...cats]);
            }
          }}
        >
          <StyledPanel active={catActive} hasShadow={false}>
            <StyledBadgeContainer>
              {filters.reasonOptions.map((reason) =>
                cats.includes(reason.value as any) ? (
                  <StyledBadge withMargin color={colorMap[reason.value]}>
                    {labelMap[reason.value]}
                  </StyledBadge>
                ) : null,
              )}
            </StyledBadgeContainer>
            <EuiSpacer size="m" />
            <div style={{ minHeight: '50px' }}>
              <EuiTitle size="xxs">
                <h3>
                  <StyledPercent>{formatPercentage(taskTotals.cat.tiv, totalTiv)}%</StyledPercent>(
                  {formatStreamCurrency(taskTotals.cat.tiv)}) of total TIV is missing relevant CAT
                  modifier data
                </h3>
              </EuiTitle>
            </div>

            <EuiSpacer size="xs" />

            <StyledDescription>
              <EuiText size="s">
                Catastrophe (CAT) secondary modifiers influence property vulnerability to perils
                such as earthquakes, floods, or hurricanes, affecting average annual loss models.
              </EuiText>
            </StyledDescription>

            <EuiSpacer size="xs" />

            <StyledExamples>Examples: first floor elevation, roofing material</StyledExamples>
          </StyledPanel>
        </a>
      </EuiFlexItem>
      <EuiFlexItem style={{ minWidth: '400px' }}>
        <a
          style={{ color: 'unset' }}
          href="javascript:void(0)"
          onClick={(e) => {
            e.preventDefault();
            if (attrActive) {
              filters.setFilter(
                'reasons',
                filters.reasons.filter((r) => r !== 'Attritional risk'),
              );
            } else {
              filters.setFilter('reasons', [...filters.reasons, 'Attritional risk']);
            }
          }}
        >
          <StyledPanel active={attrActive} hasShadow={false}>
            <StyledBadgeContainer>
              <StyledBadge withMargin color={colorMap['Attritional risk']}>
                {labelMap['Attritional risk']}
              </StyledBadge>
            </StyledBadgeContainer>
            <EuiSpacer size="m" />
            <div style={{ minHeight: '50px' }}>
              <EuiTitle size="xxs">
                <h3>
                  <StyledPercent>
                    {formatPercentage(taskTotals.attritional.tiv, totalTiv)}%
                  </StyledPercent>
                  ({formatStreamCurrency(taskTotals.attritional.tiv)}) of total TIV is missing
                  relevant attritional data
                </h3>
              </EuiTitle>
            </div>

            <EuiSpacer size="xs" />

            <StyledDescription>
              <EuiText size="s">
                Attritional attributes are a subset of COPE data that impact higher frequency, lower
                severity losses.
              </EuiText>
            </StyledDescription>

            <EuiSpacer size="xs" />

            <StyledExamples>Examples: building condition, year built</StyledExamples>
          </StyledPanel>
        </a>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
