import { SnapshotOption } from '@app/cx/Reports/contexts/reportsContext/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';
import { TableRow } from '../cards/IndexBenchmarksCard/BenchmarksTable/benchmarksTableTypes';
import { ExposureSnapshot } from '../queries/getExposureSnapshots';
import { ExposureLevel } from '../types';

export interface ExposureIndexContext {
  snapshots: {
    loading: boolean;
    snapshots: ExposureSnapshot[];
    snapshotOptions: SnapshotOption[];
    currentSnapshot: string;
    setCurrentSnapshot: (value: string) => void;
  };
  stream: {
    displayCurrency?: string;
    hasError: boolean;
    loading: boolean;
    orgId?: string;
    orgName?: string;
    streamId?: string;
    streamName?: string;
    streamSlug: string;
    snapshots?: Array<IGraphQLSnapshot>;
  };
  exposure: {
    hasError: boolean;
    loading: boolean;
    noData: boolean;
    exposureGrade: ExposureLevel;
    portfolioN: number;
    mainExposureRows: TableRow[];
    allExposureRows: TableRow[];
    rowGroups: { [key: string]: Array<TableRow> };
  };
}

export enum ExposureGroups {
  Seismic = 'Seismic',
  Wind = 'Wind',
  Other = 'Other',
}
