import { css } from '@emotion/css';

const attributeEditorContainer = css`
  margin-left: 16px;
  overflow: auto;
  width: 96%;
`;

const contentForm = css`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const contentFlexGroup = css`
  height: 100%;
  margin-right: 16px;
  padding-top: 16px;
`;

const tabs = css`
  margin-bottom: 16px;
`;

export default {
  attributeEditorContainer,
  contentFlexGroup,
  contentForm,
  tabs,
};
