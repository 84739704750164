import { IDisclosure, IFlattenedRow, Indices, IRow, ISimpleRow } from './types';

export function getItemSizeForRow<T>(row: IRow<T> | IFlattenedRow<T>): number | undefined {
  if (typeof row.options.height === 'function') {
    return row.options.height();
  }

  return row.options.height;
}

export function flattenRows<T>(
  rows: Array<IRow<T>>,
  disclosures: Array<IDisclosure> = [],
  indices: Array<number> = [],
  parent?: IFlattenedRow<T>,
): Array<IFlattenedRow<T>> {
  const allRows: Array<IFlattenedRow<T>> = [];

  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    const disclosure: IDisclosure = disclosures[index] || { disclosures: [], open: false };
    const rowWithDisclosure: IFlattenedRow<T> = {
      indices: [...indices, index],
      open: disclosure.open,
      options: {
        ...row.options,
        showDisclosureButton: !!((row.rows || []).length > 0 && row.options.showDisclosureButton),
      },
      parent,
      props: row.props,
    };
    allRows.push(rowWithDisclosure);

    if (row.rows) {
      allRows.push(
        ...flattenRows(row.rows, disclosure.disclosures, [...indices, index], rowWithDisclosure),
      );
    }
  }

  return allRows;
}

export function isRowFocused<T>(
  row: IFlattenedRow<T>,
  disclosures: Array<IDisclosure>,
  indices: Indices,
  focusAt?: number,
): boolean {
  if (focusAt === undefined || focusAt > indices.length) {
    return true;
  }

  let currentDisclosures = disclosures;
  for (let i = 0; i < indices.length; i++) {
    const index = indices[i];
    const currentDisclosure = currentDisclosures[index];
    if (!currentDisclosure?.open) {
      return true;
    }
    currentDisclosures = currentDisclosure.disclosures;
  }

  for (let i = 0; i < row.indices.length; i++) {
    if (i >= indices.length) {
      return true;
    }

    if (row.indices[i] !== indices[i]) {
      return false;
    }
  }

  return true;
}

export function isRowVisible<T>(row: IFlattenedRow<T>) {
  return row.parent === undefined || row.parent.open;
}

export function isRenderable<T>(row: IFlattenedRow<T>, overscan: number): boolean {
  if (isRowVisible(row)) {
    return true;
  }

  if (overscan <= 0) {
    return false;
  }

  if (row.parent) {
    return isRenderable(row.parent, overscan - 1);
  }

  return true;
}

export function getOpenForDisclosure(
  disclosure: undefined | IDisclosure,
  initialDisclosureDepth: number,
): boolean {
  if (disclosure?.open !== undefined) {
    return disclosure.open;
  }

  return initialDisclosureDepth > 0;
}

export function getDisclosuresFromRows<T>(
  rows: Array<ISimpleRow>,
  initialDisclosureDepth: number,
  disclosures: Array<IDisclosure> = [],
): Array<IDisclosure> {
  const disclosuresFromRows: Array<IDisclosure> = [];
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    const disclosure: IDisclosure | null = disclosures[index] || null;
    disclosuresFromRows.push({
      disclosures: getDisclosuresFromRows<T>(
        row.rows || [],
        initialDisclosureDepth - 1,
        disclosure?.disclosures || [],
      ),
      open: getOpenForDisclosure(disclosure, initialDisclosureDepth),
    });
  }

  return disclosuresFromRows;
}

export const getClosedDisclosure = (disclosure: IDisclosure): IDisclosure => ({
  disclosures: (disclosure.disclosures || []).map((child) => getClosedDisclosure(child)),
  open: false,
});

interface IModifyDisclosuresOptions {
  multiple?: boolean;
}

export function modifyDisclosuresForIndices<T>(
  disclosures: Array<IDisclosure> = [],
  indices: Array<number> = [],
  options: IModifyDisclosuresOptions = {},
): Array<IDisclosure> {
  const index = indices[0];
  const disclosuresForIndices: Array<IDisclosure> = [];

  if (disclosures.length === 1) {
    return [
      {
        disclosures: disclosures[0].disclosures,
        open: true,
      },
    ];
  }

  for (let i = 0; i < disclosures.length; i++) {
    const disclosure = disclosures[i];

    if (i === index) {
      if (indices.length === 1) {
        // we've found the element to modify
        disclosuresForIndices.push(
          disclosure.open
            ? getClosedDisclosure(disclosure)
            : {
                disclosures: disclosure.disclosures,
                open: true,
              },
        );
      } else {
        // keep digging
        disclosuresForIndices.push({
          disclosures: modifyDisclosuresForIndices<T>(
            disclosure.disclosures,
            indices.slice(1),
            options,
          ),
          open: disclosure.open,
        });
      }
    } else if (options.multiple) {
      disclosuresForIndices.push(disclosure);
    } else {
      disclosuresForIndices.push(getClosedDisclosure(disclosure));
    }
  }

  return disclosuresForIndices;
}
