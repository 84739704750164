import { GradingDetail } from './tabs/GradingDetails/types';

const words = ['CAT', 'RE'];

export const toTitle = (str: string): string =>
  str
    .split('_')
    .map((word) =>
      words.includes(word) ? word : word[0].toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(' ');

const GRADE_COLORS = {
  blue: '#4870c6',
  gray: '#E0E6EB',
  green: '#19a5b6',
  orange: '#F7AD0099',
  red: '#ff7669',
  yellow: '#f7ad00',
};

export const getColor = (color: string): string => GRADE_COLORS[color] || color;

const GRADE_BADGE_COLORS = {
  blue: 'zurichBlue',
  gray: 'zurichGray',
  green: 'zurichGreen',
  orange: 'zurichOrange',
  red: 'zurichRed',
  yellow: 'zurichYellow',
};

export const getBadgeColor = (color: string): string => GRADE_BADGE_COLORS[color];

export const makeGradingSection = (attribute: string, __typename, assessmentMetrics = []) => {
  const isCAT =
    attribute.includes('CAT') || attribute.includes('CAEQ') || attribute.includes('USWS');

  const isIndustryLoss = attribute.includes('INDUSTRY');

  let type =
    __typename === 'PropertyAssessmentMetric' || assessmentMetrics?.length > 0
      ? GradingDetail.Assessment
      : GradingDetail.Grade;

  let value = attribute;

  if (isCAT) {
    value = 'CAT_FLOOD';
    type = GradingDetail.Grade;
  }

  if (isIndustryLoss || attribute === 'CUSTOMER_AVERAGE_LOSS') {
    value = 'CUSTOMER_AVERAGE_LOSS';
    type = GradingDetail.AverageLoss;
  }

  if (attribute === 'CUSTOMER_LOSS_FREQUENCY') {
    type = GradingDetail.AverageLoss;
  }

  return { type, value };
};
