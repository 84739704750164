import { css } from '@emotion/css';

const container = css`
  display: flex;
  position: relative;
`;

const gap = css`
  height: 8px;
  width: 1px;
`;

export default {
  container,
  gap,
};
