import { useEffect, useRef, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { IJob, JobErrors, TransitionStatus } from '@onarchipelago/dice/EnrichmentProjects/types';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
// FIX ME
// @ts-ignore
import CREATE_JOB from '@app/queries/organizations/createJob.gql';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { SUBMIT_JOB } from '@app/queries/organizations/submitJob';
import {
  EJobStatus,
  EJobType,
  ICreateJobData,
  ICreateJobVariables,
  IGetJobData,
  IGetJobVariables,
  ISubmitJobData,
  ISubmitJobVariables,
} from '@app/queries/organizations/types';

interface Props {
  orgName: string;
  defaultJobID: string | null;

  onSuccess: (job: IJob) => void;
  onAPIError: (err: ApolloError) => void;
  onJobError: (e: JobErrors) => void;
  onTimeout: () => void;
}
interface Return {
  loading: boolean;
  submit: (worksheet: File) => void;
  retry: () => void;
}

const RETRY_LIMIT = 10;

export default ({
  orgName,
  defaultJobID,
  onSuccess,
  onAPIError,
  onJobError,
  onTimeout,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [jobID, setJobID] = useState<string>(defaultJobID || '');
  const [worksheet, setWorksheet] = useState<File | null>(null);
  const { account } = useAuth();

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    setJobID(defaultJobID || '');
  }, [defaultJobID]);

  const retry = async (callback: Function) => {
    if (retryCount + 1 >= RETRY_LIMIT) {
      setIsLoading(false);
      onTimeout();
      return;
    }

    clearTimeout(timerRef.current);
    const retryTime = 3000;
    timerRef.current = setTimeout(() => {
      callback();
    }, retryTime);

    setRetryCount(retryCount + 1);
  };

  useEffect(() => () => clearTimeout(timerRef.current), []);

  let checkCurrentTransition: (j: IJob) => void;

  const [getJobQuery] = useLazyQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      checkCurrentTransition(data.job);
    },
    onError: (error) => {
      setIsLoading(false);
      setRetryCount(0);
      onAPIError(error);
    },
  });

  const [createJob] = useMutation<ICreateJobData, ICreateJobVariables>(CREATE_JOB, {
    onCompleted: ({ createJob: { id } }) => {
      setJobID(id);
      getJobQuery({ variables: { jobID: id } });
    },
    onError: (error) => {
      setIsLoading(false);
      setRetryCount(0);

      onAPIError(error);
    },
    variables: {
      input: {
        doerUserID: account?.userId,
        name: 'PAL Job',
        orgName,
        type: EJobType.PALIngest,
      },
    },
  });

  const getJob = () => {
    if (jobID) {
      getJobQuery({ variables: { jobID } });
    }
  };

  const [submitJobMutation] = useMutation<ISubmitJobData, ISubmitJobVariables>(SUBMIT_JOB, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      getJob();
    },
    onError: (error) => {
      setIsLoading(false);
      setRetryCount(0);

      onAPIError(error);
    },
  });

  const submitJob = (w: File) => {
    submitJobMutation({
      variables: {
        input: {
          comment: 'Submit PAL job',
          jobID,
          worksheet: w,
        },
      },
    });
  };

  checkCurrentTransition = (j: IJob) => {
    if (j !== null && j.lifeCycle.length > 0) {
      const currentTransition = j.lifeCycle[0];

      switch (currentTransition.progress) {
        case TransitionStatus.Started:
          retry(() => {
            getJobQuery({ variables: { jobID: j.id } });
          });
          break;

        case TransitionStatus.Failed:
          setIsLoading(false);
          setRetryCount(0);
          onJobError({
            dataErrors: currentTransition.dataErrors,
            systemErrors: currentTransition.systemErrors,
          });

          break;

        case TransitionStatus.Completed:
          setRetryCount(0);
          clearTimeout(timerRef.current);

          if (currentTransition.to === EJobStatus.Ready) {
            submitJob(worksheet as File);
          } else if (currentTransition.to === EJobStatus.Complete) {
            setIsLoading(false);
            setJobID('');
            onSuccess(j);
          }
          break;

        default:
          break;
      }
    }
  };

  const submit = (w: File) => {
    setIsLoading(true);
    setWorksheet(w);

    if (!jobID) {
      createJob();
    } else {
      submitJob(w);
    }
  };

  const retrySubmit = () => {
    setRetryCount(0);
    setIsLoading(true);
    getJob();
  };
  return {
    loading: isLoading,
    retry: retrySubmit,
    submit,
  };
};
