import styled from '@emotion/styled';

export const HeadingTooltip = styled.h4`
  ${({ color }: { color: string }) =>
    `
    color: ${color} !important;
  `}
`;

export const ParagraphTooltip = styled.p`
  ${({ color }: { color: string }) =>
    `
    color: ${color} !important;
  `}
`;
