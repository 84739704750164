import React, { useContext } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import EditStreamModal from '../EditModal/EditStreamModal';
import { PanelItem } from './types';

export default (): PanelItem => {
  const { showModal } = useContext(ModalContext);
  const { stream } = useStreamContext();

  return {
    'data-testid': 'edit-stream-button',
    iconName: 'gear',
    label: 'Edit Stream',
    onClick: () =>
      showModal(
        <EditStreamModal
          // FIX ME
          // @ts-ignore
          stream={stream}
        />,
      ),
  };
};
