import React from 'react';
import styled from '@emotion/styled';
import RoleAssignment from '@onarchipelago/cx/RoleChecker/RoleAssignment';
import { useFormikContext } from 'formik';
import {
  Button,
  EuiFormRow,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  Spacer,
  TextField,
  useEuiTheme,
} from 'ui';
import { stripPx } from '@app/utils/styles';
import { RoleAssignmentsData } from './types';

const UnorderedList = styled.ul`
  overflow: auto;
`;
interface Props {
  data?: RoleAssignmentsData;
  handleClose: () => void;
  isLoading: boolean;
  submittedValues?: Record<string, string>;
}

const RoleCheckerFormContent: React.FC<Props> = ({
  data,
  isLoading,
  handleClose,
  submittedValues,
}) => {
  const { euiTheme } = useEuiTheme();
  const { size } = euiTheme;
  const { handleChange, errors, touched, handleBlur } = useFormikContext<Record<string, string>>();

  const isInvalid = !!errors?.principalId && touched.principalId;
  const assignments = data?.roleAssignmentsV2?.assignments || [];

  const userDetails = (label: string, testID: string, value?: string) =>
    !!value && (
      <>
        <EuiText data-testid={testID}>
          <b> {label}</b>: {value}
        </EuiText>
      </>
    );

  const primaryOrgName = data?.roleAssignmentsV2.primaryOrgName;
  const legacyRole = data?.roleAssignmentsV2.legacyRole;

  return (
    <>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Role Checker</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow
          fullWidth
          isInvalid={isInvalid}
          label="Enter an email or client id to check a user's role:"
          helpText={isInvalid ? 'Please check the principal id formatting' : undefined}
        >
          <TextField
            data-testid="role-checker-email-input"
            fullWidth
            invalid={isInvalid}
            onChange={(event) => handleChange('principalId')(event?.target?.value?.trim())}
            onBlur={handleBlur('principalId')}
          />
        </EuiFormRow>
        <Spacer />
        {userDetails('Primary account', 'primary-org-label', primaryOrgName)}
        {userDetails('Legacy role', 'legacy-role-label', legacyRole)}
        {(!!primaryOrgName || !!legacyRole) && <Spacer />}
        {!!assignments.length && (
          <>
            <EuiText data-testid="roles-message">{`${
              assignments?.[0].principalId || 'User'
            }   has the following roles:`}</EuiText>
            <Spacer />
            <UnorderedList
              style={{ maxHeight: `${stripPx(size.base) * 26}px`, paddingRight: size.base }}
            >
              {assignments.map((roleAssignment, index) => (
                <RoleAssignment
                  key={`${roleAssignment.role}-${index}`}
                  assignment={roleAssignment}
                />
              ))}
            </UnorderedList>
          </>
        )}
        {!!data && assignments.length === 0 && (
          <p data-testid="no-roles-message">{`${submittedValues?.principalId} has no roles.`}</p>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <Button
          data-testid="role-checker-cancel-button"
          color="danger"
          label="Cancel"
          loading={isLoading}
          onClick={handleClose}
          type="button"
        />
        <Button
          data-testid="role-checker-submit-button"
          fill
          label="Check Roles"
          loading={isLoading}
          type="submit"
        />
      </EuiModalFooter>
    </>
  );
};

export default RoleCheckerFormContent;
