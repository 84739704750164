import gql from 'graphql-tag';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';

export const PRECHECK_SUMMARY = gql`
  query preCheckRecommendationSummary($input: PreCheckRecommendationSummaryInput!) {
    preCheckRecommendationSummary(input: $input) {
      mlRecommendationsStatus
    }
  }
`;

export const usePrecheckUser = () => {
  try {
    const { selectedOrganization } = useUserSession();
    const { account } = useAuth();
    const isAdmin = account?.permissions?.admin;

    const permissions = getOrgPermissions(account, selectedOrganization?.id);
    const isBroker = permissions?.includes('canManageProperties');

    let type;

    if (isAdmin) {
      type = 'Admin';
    } else if (isBroker) {
      type = 'Broker';
    } else {
      type = 'Customer';
    }

    return {
      broker: isBroker,
      type: isAdmin ? 'Admin' : isBroker ? 'Broker' : 'Customer',
    };
  } catch (e) {
    return {};
  }
};
