import React, { FC } from 'react';
import { EuiFlexItem, EuiPanel } from 'ui';

const ReportContainer: FC = ({ children }) => (
  <EuiFlexItem>
    <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
      <EuiFlexItem style={{ margin: 0 }}>{children}</EuiFlexItem>
    </EuiPanel>
  </EuiFlexItem>
);

export { ReportContainer };
