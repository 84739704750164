import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiStat, EuiText, EuiToolTip, Icon } from 'ui';
import { formatCurrency, formatNumber } from '@app/utils/format';
import { useGradingDetailsContext } from '../context';
import GradeBadge from '../GradeBadge';
import { getSummary } from './tableData';

interface Stat {
  description: string;
  content: React.ReactNode;
  showPeriod: boolean;
  tooltip?: React.ReactNode;
}

const Stats: React.FC = () => {
  const { carrierAssessment, currentAttribute } = useGradingDetailsContext();
  const {
    overallGrade,
    avgPaidIndemnity,
    period,
    lossFrequency,
    lossFrequencyBenchmarkDescription,
    benchmark,
  } = getSummary(currentAttribute, carrierAssessment);

  const stats: Array<Stat> = [
    {
      content: <GradeBadge grade={overallGrade} filterable={false} />,
      description: 'Overall Grade',
      showPeriod: false,
    },
  ];

  let formattedBenchmark = `${benchmark}`;
  if (currentAttribute.value === 'CUSTOMER_AVERAGE_LOSS') {
    stats.push({
      content: formatCurrency(avgPaidIndemnity),
      description: 'Avg. Paid Indemnity per Year',
      showPeriod: true,
    });
    formattedBenchmark = formatCurrency(benchmark);
  } else if (currentAttribute.value === 'CUSTOMER_LOSS_FREQUENCY') {
    stats.push({
      content: formatNumber(lossFrequency),
      description: 'Avg. Loss Frequency per Year',
      showPeriod: true,
    });
    formattedBenchmark = formatNumber(benchmark);
  }

  stats.push({
    content: formattedBenchmark,
    description: 'Zurich Benchmark',
    showPeriod: true,
    tooltip: (
      <EuiToolTip position="top" content={lossFrequencyBenchmarkDescription}>
        <Icon color="primary" name="info" />
      </EuiToolTip>
    ),
  });

  return (
    <EuiFlexGroup direction="row">
      {stats.map(({ description, content, showPeriod, tooltip }, key) => (
        <EuiFlexItem grow={false} key={key}>
          <EuiStat
            titleSize="m"
            description={
              <EuiText>
                <h4 style={{ display: 'inline' }}>{description}</h4> {tooltip}
              </EuiText>
            }
            title={content}
          />
          {showPeriod && <span>For {period}</span>}
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default Stats;
