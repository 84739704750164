import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Children, IPropertiesProps } from './types';

interface IProps {
  visible: boolean;
  duration: number;
  children: Children;
  props: IPropertiesProps;
  indices: Array<number>;
  loading: boolean;
}

interface ContainerProps {
  visible?: boolean;
  duration: number;
}

const Container = styled.div<ContainerProps>`
  label: PropertiesGroupedListPropertiesContainer;
  display: flex;
  flex: 1;
  transition-duration: ${({ duration }) => duration}ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  max-height: ${(props) => (props.visible ? '100%' : '0%')};
  min-width: 0;
`;

const PropertiesGroupedListProperties: FC<IProps> = ({
  visible,
  duration,
  children,
  indices,
  props,
  loading,
}) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [localVisible, setLocalVisible] = useState(visible);

  /* If row is invisible, don't render the grid, since it's
   * a heavy rendering operation and we don't want to
   * render hidden grids.
   *
   * On the other hand, during a closing animation,
   * the height will be 0 but we want to wait until the
   * completion of animation before destroying the grid
   * to avoid a FOUC.
   */
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (visible) {
      setLocalVisible(true);
    } else {
      setTimer(
        setTimeout(() => {
          setLocalVisible(false);
        }, duration),
      );
    }

    return () => {
      clearTimeout(timer);
    };
  }, [visible]);

  if (localVisible) {
    return (
      <Container visible={!loading} duration={duration}>
        {children({
          group: props.group,
          indices,
          visible,
        })}
      </Container>
    );
  }

  return <Container duration={duration} />;
};

export default PropertiesGroupedListProperties;
