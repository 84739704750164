import React, { useEffect, useState } from 'react';
import { StreamLanguage } from '@codemirror/language';
import * as yamlMode from '@codemirror/legacy-modes/mode/yaml';
import { linter, lintGutter } from '@codemirror/lint';
import CodeMirror from '@uiw/react-codemirror';
import useAxios from 'axios-hooks';
import parser from 'js-yaml';
import { Button, EuiFlexGroup, EuiFlexItem, EuiTitle, useToast } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import { COPILOT_URL } from '@app/config';
import { Container } from './ConfigTab.emotion';

const yaml = StreamLanguage.define(yamlMode.yaml);

const yamlLinter = linter((view) => {
  const diagnostics = [];

  try {
    parser.load(view.state.doc as unknown as string);
  } catch (e) {
    const loc = e.mark;
    const from = loc ? loc.position : 0;
    const to = from;
    const severity = 'error';

    diagnostics.push({
      from,
      message: e.message,
      severity,
      to,
    });
  }

  return diagnostics;
});

interface Props {
  configFile: string;
}
export const ConfigTab: React.FC<Props> = ({ configFile }) => {
  const [yml, setYML] = useState('');
  const toast = useToast();
  const [{ loading, data, error: loadingError }, getSettings] = useAxios(
    { url: `${COPILOT_URL}/settings/${configFile}` },
    { manual: true },
  );
  const [{ loading: updating, error }, saveSettings] = useAxios(
    {
      method: 'POST',
      url: `${COPILOT_URL}/settings`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  );

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (error) {
      toast({ title: 'could not save settings', type: 'danger' });
    }
  }, [error]);

  useEffect(() => {
    if (!loading) {
      setYML(data?.yamlConfig);
    }
  }, [loading, data?.yamlConfig]);

  return (
    <Container>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="m">
            <h2>Settings</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            loading={loading || updating}
            label="Save"
            onClick={() => saveSettings({ data: { config: configFile, yaml: yml } })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {loadingError ? (
        <ErrorMessage title="Could not load copilot settings" />
      ) : (
        <EuiFlexGroup direction="column" className="codeEditor">
          <EuiFlexItem grow={1}>
            <CodeMirror
              value={yml}
              onChange={(v) => setYML(v)}
              extensions={[yaml, lintGutter(), yamlLinter]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </Container>
  );
};
