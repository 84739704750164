import React from 'react';
import {
  Grade,
  PropertyAssessmentDetail,
  PropertyAssessmentMetric,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import SingleBarChart, { Props as SingleBarCartProps } from '../../../charts/SingleBarChart';
import { getColor } from '../../../utils';
import GradeBadge from '../GradeBadge';
import { OverallTableData, renderPct } from './tableData';

const columns = [
  {
    field: 'filterByGrade',
    name: 'Filter by Grade',

    render: (grade: Grade) => <GradeBadge grade={grade} filterable />,
  },
  {
    field: 'hazardGrade',
    name: 'Risk Factor',
  },
  {
    field: 'assessmentCnt',
    name: 'Inspection Count',
  },
  {
    field: 'pctOfAssessments',
    name: '% of Inspections',
    render: ({ value, options }) => (
      <>
        {renderPct(value)}
        <SingleBarChart {...(options as SingleBarCartProps)} />
      </>
    ),
  },
];

const getTotalRow = (metrics: Array<PropertyAssessmentMetric>) => {
  let totalAssessmentPct = 0;
  let totalAssessmentCount = 0;
  const totalPctAssessmentsChartOptions = [];

  metrics.forEach((v) => {
    const color = getColor(v.grade.color);
    totalAssessmentPct += v.pctOfAssessments;
    totalAssessmentCount += v.assessmentCnt;
    totalPctAssessmentsChartOptions.push({
      color,
      value: v.pctOfAssessments,
    });
  });

  return {
    assessmentCnt: totalAssessmentCount,
    filterByGrade: {
      category: 'Total',
      code: '',
      color: 'gray',
      value: null,
    },
    hazardGrade: '-',

    pctOfAssessments: {
      options: {
        bars: totalPctAssessmentsChartOptions.reverse(),
        withLegend: true,
      },
      value: totalAssessmentPct / 100,
    },
  };
};

export const getPropertyAssessmentData = (current: PropertyAssessmentDetail): OverallTableData => {
  const total = getTotalRow(current.summaryMetrics);
  return {
    columns,
    items: current.summaryMetrics.map((metric) => ({
      assessmentCnt: metric.assessmentCnt,
      filterByGrade: metric.grade,
      hazardGrade: metric.grade.code,

      pctOfAssessments: {
        options: {
          bars: [
            {
              opacity: 0,
              value: total.pctOfAssessments.value * 100 - metric.pctOfAssessments,
            },
            {
              color: getColor(metric.grade.color),
              value: metric.pctOfAssessments,
            },
          ],
          withLegend: false,
        },
        value: metric.pctOfAssessments / 100,
      },
    })),
    overallGrade: current.overallMetric.grade,
    totalRow: total,
  };
};
