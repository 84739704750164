export const CSV_LABEL = 'CSV';
export const XLSX_LABEL = 'XLSX';
export const RMS_LABEL = 'RMS';
export const AIR_LABEL = 'AIR';
export const RAPID_LABEL = 'RAPID';
export const ZORBA_LABEL = 'ZORBA';

export const ARCHIPELAGO_DATA_LABEL = 'Archipelago Data';
export const LOSS_DATA_LABEL = 'Loss Data';
export const CHANGE_ANALYSIS_LABEL = 'Change Analysis';
export const POLICY_DATA_LABEL = 'Policy Data';

export const AD_XLSX_KEY = 'Archipelago-Data-XLSX';
export const AD_RMS_KEY = 'Archipelago-Data-RMS';
export const AD_AIR_KEY = 'Archipelago-Data-AIR';
export const AD_RAPID_KEY = 'Archipelago-Data-RAPID';
export const AD_ZORBA_KEY = 'Archipelago-Data-ZORBA';

export const LD_XLSX_KEY = 'Loss-Data-XLSX';
export const PD_XLSX_KEY = 'Policy-Data-XLSX';
export const CA_XLSX_KEY = 'Change-Analysis-XLSX';
