import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, useToast } from 'ui';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';
// FIX ME
// @ts-ignore
import IMPORT_PROPERTY_METADATA from '@app/queries/propertyMetadata/importPropertyMetadata.gql';
import { ISetAttributeMetadataVariables } from '@app/queries/propertyMetadata/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { getMetadataArray, toGraphqlArray } from '../utils';
import styles from './UploadButton.emotion';

const UploadButton: React.FC = () => {
  const { organizationId, streamId } = useAttributeMetadataEditorContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const toast = useToast();
  const [importPropertyMetadata] = useMutation<Boolean, ISetAttributeMetadataVariables>(
    IMPORT_PROPERTY_METADATA,
    {
      onCompleted: () =>
        toast({ title: 'Successfully imported the metadata file', type: 'success' }),
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    },
  );

  const handleUpload = async (file: string) => {
    setIsUploading(true);

    const array = getMetadataArray(file);

    await importPropertyMetadata({
      refetchQueries: [
        { query: GET_ORGANIZATION_ATTRIBUTE_METADATA, variables: { organizationId, streamId } },
      ],
      variables: { input: { metadata: toGraphqlArray(array), organizationId, streamId } },
    });

    setIsUploading(false);
  };

  const onFileChange = (fr: FileReader) => () => {
    handleUpload(fr.result as string);
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div data-testid="attribute-metadata-upload-button">
      <Button
        color="success"
        iconName="upload"
        label="Upload"
        loading={isUploading}
        onClick={handleButtonClick}
        size="s"
      />
      <input
        accept="application/json"
        className={styles.input}
        id="upload-file"
        ref={inputRef}
        type="file"
        onChange={(event) => {
          const { files } = event.target;
          if (files && files.length > 0) {
            const fr = new FileReader();
            fr.onloadend = onFileChange(fr);
            fr.readAsText(files[0]);
          }
        }}
        onClick={(event) => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
      />
    </div>
  );
};

export default UploadButton;
