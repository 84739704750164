import React, { FC } from 'react';
import { EnrichmentOverallQualityChangeData } from '@onarchipelago/cx/Reports/queries/getEnrichmentOverallQualityChange';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { formatCurrency, formatNumber, formatPercentage } from '@app/utils/format';
import Line from '../../ReadinessReport/Line';
import Stat from '../../ReadinessReport/Stat';
import { formatScore } from '../../ReadinessReport/utils';

interface Props {
  enrichmentOverallQualityChangeData: EnrichmentOverallQualityChangeData['enrichmentOverallQualityChange'];
}

const PropertyMetrics: FC<Props> = ({ enrichmentOverallQualityChangeData }) => (
  <EuiFlexItem data-testid="enrichment-report-property-metrics" grow={2}>
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <Stat
              data-testid="enriched-properties"
              color={
                enrichmentOverallQualityChangeData.enrichedProperties === 0 ? 'subdued' : 'success'
              }
              title="Enriched Properties"
              value={formatNumber(enrichmentOverallQualityChangeData.enrichedProperties)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="enriched-tiv"
              color={enrichmentOverallQualityChangeData.enrichedTiv === 0 ? 'subdued' : 'success'}
              footer={`${formatPercentage(
                enrichmentOverallQualityChangeData.enrichedPctOfTiv,
              )} of TIV`}
              title="Enriched TIV"
              value={formatCurrency(enrichmentOverallQualityChangeData.enrichedTiv)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="enriched-improvement"
              color={
                enrichmentOverallQualityChangeData.enrichedAvgImprovement === 0
                  ? 'subdued'
                  : 'success'
              }
              hasIcon
              iconColor={
                enrichmentOverallQualityChangeData.enrichedAvgImprovement === 0
                  ? 'subdued'
                  : 'success'
              }
              footer="Between Snapshots"
              title="Avg Improvement in Data Quality"
              value={formatScore(enrichmentOverallQualityChangeData.enrichedAvgImprovement)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <Line orientation="horizontal" />
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <Stat
              data-testid="added-properties"
              color={
                enrichmentOverallQualityChangeData.addedProperties === 0 ? 'subdued' : 'success'
              }
              title="Added Properties"
              value={formatNumber(enrichmentOverallQualityChangeData.addedProperties)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="added-tiv"
              color={enrichmentOverallQualityChangeData.addedTiv === 0 ? 'subdued' : 'success'}
              title="Added TIV"
              value={formatCurrency(enrichmentOverallQualityChangeData.addedTiv)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="added-dqs"
              color={enrichmentOverallQualityChangeData.addedAvgScore === 0 ? 'subdued' : 'success'}
              title="Avg Data Quality Score"
              value={
                enrichmentOverallQualityChangeData.addedAvgScore === 0
                  ? '--'
                  : formatScore(enrichmentOverallQualityChangeData.addedAvgScore)
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <Line orientation="horizontal" />
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <Stat
              data-testid="disposed-properties"
              color={
                enrichmentOverallQualityChangeData.disposedProperties === 0 ? 'subdued' : 'success'
              }
              title="Disposed Properties"
              value={formatNumber(enrichmentOverallQualityChangeData.disposedProperties)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="disposed-tiv"
              color={enrichmentOverallQualityChangeData.disposedTiv === 0 ? 'subdued' : 'success'}
              title="Disposed TIV"
              value={formatCurrency(enrichmentOverallQualityChangeData.disposedTiv)}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Stat
              data-testid="disposed-dqs"
              color={
                enrichmentOverallQualityChangeData.disposedAvgScore === 0 ? 'subdued' : 'success'
              }
              title="Avg Data Quality Score"
              value={
                enrichmentOverallQualityChangeData.disposedAvgScore === 0
                  ? '--'
                  : formatScore(enrichmentOverallQualityChangeData.disposedAvgScore)
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText color="subdued">
          {STREAMS_LABEL} data quality improves when more precise and evidenced information is
          provided, e.g. via document uploads. It can also decrease if new properties with lower
          data quality are added to the {STREAMS_LABEL} or properties with high data quality are
          disposed.
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiFlexItem>
);

export default PropertyMetrics;
