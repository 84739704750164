import React, { FC } from 'react';
import styled from '@emotion/styled';
import { EuiLink,EuiText } from 'ui';
import { IPageState } from './types';

const Container = styled.div`
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  background-color: white;
  padding: 5px;
  padding-left: 20px;
`;

const CustomChevron = styled.span`
  padding-left: 6px;
  padding-right: 6px;
  vertical-align: middle;
  color: gray;

  height: 12px;
  width: 12px;
`;

const BreadcrumbGroupByV2: FC<{
  pageState: IPageState;
  onBreadcrumbClick: (index: number) => void;
}> = ({ pageState, onBreadcrumbClick }) => {
  const { groupByV2 } = pageState;

  const values = groupByV2?.filter((group) => group.value !== '').map((group) => group.value);
  const length = values.length;
  if (length < 1) {
    return null;
  }
  return (
    <Container>
      <EuiText size="s">
        <EuiLink
          color="primary"
          style={{ lineHeight: '24px', textDecorationLine: 'underline' }}
          onClick={() => {
            if (onBreadcrumbClick) {
              onBreadcrumbClick(-1);
            }
          }}
        >
          All
        </EuiLink>
        <CustomChevron>/</CustomChevron>
        {values.map((value, index) => {
          const isLast = index === length - 1;
          return (
            <>
              {isLast ? (
                <EuiLink
                  color="primary"
                  style={{
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    textDecorationLine: 'underline',
                  }}
                  onClick={() => {
                    if (onBreadcrumbClick) {
                      onBreadcrumbClick(index);
                    }
                  }}
                >
                  {value}
                </EuiLink>
              ) : (
                <>
                  <EuiLink
                    color="primary"
                    style={{ lineHeight: '24px', textDecorationLine: 'underline' }}
                    onClick={() => {
                      if (onBreadcrumbClick) {
                        onBreadcrumbClick(index);
                      }
                    }}
                  >
                    {value}
                  </EuiLink>
                  <CustomChevron>/</CustomChevron>
                </>
              )}
            </>
          );
        })}
      </EuiText>
    </Container>
  );
};

export default BreadcrumbGroupByV2;
