import { useContext } from 'react';
import { makeReportNamesReadable } from '@onarchipelago/cx/Stream/EditModal/Tabs/utils';
import {
  ExportCode,
  IStream,
  IStreamConfiguration,
  reportCodes,
} from '@onarchipelago/cx/Stream/types';
import { API_BASE_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import download from '@app/utils/download';
import StreamEditContext from '../../StreamEditContext';

interface Return {
  copyOfStream?: IStream;
  downloadValidationReport: () => Promise<void>;
  exportOptions: Array<{ label: string; value: string }>;
  getEmailsArray: (string: string) => string[];
  membershipExpressionChanged: boolean;
  reportOptions: Array<{ label: string; value: string }>;
  setFieldValue: (field: string, value: any) => void;
  streamConfiguration?: IStreamConfiguration;
  touch?: (field: string) => void;
}

const exportOptions = [
  ExportCode.AIR,
  ExportCode.CHANGE_ANALYSIS,
  ExportCode.ARCHIPELAGO,
  ExportCode.RMS,
  ExportCode.LOSSES,
].map((c) => ({ label: c, value: c }));

const reportOptions = reportCodes.map((reportCode) => ({
  label: makeReportNamesReadable(reportCode),
  value: reportCode,
}));

export default (): Return => {
  const { copyOfStream, initialValues, setFieldValue, touch } = useContext(StreamEditContext);
  const { getIdToken } = useAuth();
  const validateReportLink = `${API_BASE_URL}/stream/${copyOfStream?.id}/export/validate`;
  const streamConfiguration = copyOfStream?.configuration;

  const allowedReportOptions = reportOptions.filter((option) => {
    const allowedReports = streamConfiguration?.allowableReports || [];
    return allowedReports.includes(option.value);
  });

  const getEmailsArray = (string: string) => string.replace(/ /g, '').split(',');

  const initialMembershipExpression = initialValues?.configuration?.membershipExpression || '';
  const newMembershipExpression = copyOfStream?.configuration?.membershipExpression || '';

  const membershipExpressionChanged =
    initialMembershipExpression.trim() !== newMembershipExpression.trim();

  const downloadValidationReport = async () => {
    await download({
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${getIdToken ? getIdToken() : ''}`,
        },
      ],
      url: validateReportLink,
    });
  };

  return {
    copyOfStream,
    downloadValidationReport,
    exportOptions,
    getEmailsArray,
    membershipExpressionChanged,
    reportOptions: allowedReportOptions,
    setFieldValue,
    streamConfiguration,
    touch,
  };
};
