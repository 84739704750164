import { GroupedMetadata } from '../types';

export default (data: Array<GroupedMetadata>) =>
  data.map((attribute, index) => {
    const {
      children,
      columnPosition,
      dimension,
      displayName,
      filterable,
      groupable,
      name,
      source,
      subdimension,
    } = attribute;

    const finalName = children?.length ? `${name} (${children.length})` : name;

    return {
      children,
      columnPosition,
      dimension,
      displayName,
      edit: attribute,
      filterable: filterable ? 'Yes' : 'No',
      groupable: groupable ? 'Yes' : 'No',
      id: index,
      includeInStreams: { attribute, id: index },
      name: finalName,
      source,
      subdimension,
    };
  });
