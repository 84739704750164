import { ReactElement } from 'react';
import { ExportCode, ReportCode } from '@onarchipelago/cx/Stream/types';
import { IStep } from '@app/components/Tour/types';
import { ExposureLevel } from '@app/cx/ExposureIndex/types';
import { IGraphQLPropertyLock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

export interface GetStreamData {
  propertyAttributeMetadataV2: Array<IGraphQLAttributeMetadata>;
  streamV2: {
    lock: IGraphQLPropertyLock;
    permissions: StreamPermissions;
    stream: IGraphQLStream;
  };
}

export interface GetStreamVariables {
  isAdmin: boolean;
  slug: string;
  userCode: string | undefined;
}

export interface GetStreamV2Variables extends GetStreamVariables {
  snapshot?: string;
}

export interface ITour {
  author?: {
    name: string;
    avatar?: string;
  };
  steps: Array<IStep>;
}

export interface IGraphQLStream {
  __typename: string;
  broker: string;
  documentsCount: number;
  effectiveDate: number | undefined;
  expiryDate: number | undefined;
  id: string;
  isPublic: number;
  highlights: null | string;
  name: string;
  organizationId: undefined | string;
  orgName: string;
  propertiesCount: number;
  policiesCount: number;
  reit: string;
  slug: string;
  status: string;
  totalInsuredValue: number;
  updateStatus: number;
  allowedExports: ExportCode[];
  allowedReports: ReportCode[];
  propertyStatusLabel: string;
  snapshots: Array<IGraphQLSnapshot>;
  defaultSnapshot?: string;
  defaultStreamView: string;
  configuration?: IGraphQLStreamConfiguration;
  isMyProperties: boolean;
  stockThroughputEnabled: boolean;
  updatedAt: string;
  displayCurrency: string;
  enabledFeatures: string[];
  streamSettings: StreamSettings;
  exposure?: Exposure;
}

export interface Exposure {
  indexGrade?: ExposureLevel;
  index?: number;
  attributeScores?: ExposureAttribute;
}

export interface ExposureAttribute {
  name: string;
  displayName: string;
  tivPct: number;
  percentile: number;
  indexGrade: ExposureLevel;
  gradeDefinitions: ExposureGradeDefinition;
}

export interface ExposureGradeDefinition {
  indexGrade: ExposureLevel;
  industryName: string;
  minPercentileValue: number;
  maxPercentileValue: number;
  minPercentileThreshold: number;
  maxPercentileThreshold: number;
}

export interface StreamSettings {
  unitsOfMeasurement: string;
}

export interface StreamPermissions {
  canViewReports?: boolean;
  canAddProperties: boolean;
  canEditProperties: boolean;
  canEditAllProperties: boolean;
  canDeleteProperties?: boolean;
  canAddLosses: boolean;
  canEditLosses: boolean;
  canViewLosses: boolean;
  canExportPolicies: boolean;
  canExportLosses: boolean;
  canManageSnapshots: boolean;
  canChat: boolean;
  canSharePreCheck: boolean;
}
export interface IGraphQLStreamGroup {
  stream: IGraphQLStream;
  group: string;
}

export interface OrgInfo {
  id: string;
  name: string;
  enabledFeatures: string[];
  enableUploadPortal: boolean;
  enableSubmissionDashboard: boolean;
  enableRedirectOnSOVUpload?: boolean;
  enableUnderwriterEdit?: boolean;
}

export interface GetStreamsResponse {
  streamsV2: {
    streams: Array<IGraphQLStream>;
    lossExportOrgs: Array<OrgInfo>;
    policyExportOrgs: Array<OrgInfo>;
  };
}

export interface IGraphQLStreamConfiguration {
  explorersConfig: string;
  notificationEmails: string[];
  lastUpdateNote: string;
  membershipExpression: string;
  exportsEnabled: boolean;
  allowedExports: ExportCode[];
  reportsEnabled: boolean;
  defaultStreamView: string;
  allowedReports: ReportCode[];
}

export enum SnapshotTypeEnum {
  STANDARD = 'STANDARD',
  SOV = 'SOV',
  VALUATION = 'VALUATION',
}

export interface IGraphQLSnapshot {
  id: string;
  name: string;
  date: string;
  description?: string;
  bound?: boolean;
  visible?: boolean;
  displayDate: string | null;
  type?: SnapshotTypeEnum;
}

export interface IOrganization {
  id: string;
  name: string | undefined;
}

export interface IUpdateStreamMembershipVariables {
  orgName: string;
  streamSlug?: string;
  updateReportingViews: boolean;
}

// Carrier Assessments

export interface IGetStreamCarrierAssessmentVariables {
  input: {
    name: string;
    snapshotId: string;
    streamId: string;
  };
}

export interface IGetStreamCarrierAssessmentData {
  carrierAssessment: CarrierAssessmentData;
}

export interface Metric {
  grade: Grade;
  pctOfProperties: number;
  pctOfGradedProperties?: number;
  pctOfTIV: number;
  propertyCnt: number;
  tiv: number;
  pctOfAssessments?: number;
  pctOfGradedTIV?: number;
}

export interface Component {
  __typename?: string;
  attribute: string;
  description?: string;
  grade: Grade;
  propertyAssessmentMetrics?: Metric[];
  propertyGradeMetrics?: Metric[];
}

export interface Grade {
  __typename?: string;
  category: string;
  color: string;
  value?: string;
  code?: string;
}

export interface Pillar {
  __typename?: string;
  attribute: string;
  components: Component[];
  description?: string;
  factorDescription?: string;
  grade: Grade;
}

export interface CarrierAssessmentData {
  carrierId: string;
  carrierLogo: string;
  // gradeDetails:
  gradeSchemes: Grade;
  headerText: string;
  // lossFrecuencyGeography:
  // lossFrecuencyHistogram:
  // lossSummaryMetrics:
  // lossYears:
  name: string;
  pillars: Pillar[];
  snapshotId: string;
  streamId: string;
}

// End of Carrier Assessments

export interface IGetStreamRiskSummaryData {
  streamRiskSummary: StreamRiskSummaryData;
}

export interface StreamRiskSummaryData {
  charts: RiskSummaryChartData[];
  priorSnapshotName?: string;
  snapshotName: string;
}

export interface RiskSummaryChartData {
  facets: string[];
  name: string;
  summaryStats: ISummaryStats[];
  values: ChartValues[];
}

export interface ISummaryStats {
  name: string;
  value: number;
}

export interface ChartValues extends GroupedAttributes {
  groupedBy: GroupedAttributes[] | null;
}

export interface GroupedAttributes {
  label: string;
  attributes: Attributes[];
}

export interface Attributes {
  displayName: string;
  value: number;
  pctOfTotal: number;
  priorValue: number;
  priorPctOfTotal: number;
  pctChange: number;
}

export interface LocationHazardValues {
  [key: string]: ChartValues;
}

export interface IGetStreamRiskSummaryVariables {
  streamSlug: string;
  snapshotName: string;
}

export interface ManagedOrgInfo {
  org: OrgInfo;
  editProperties: boolean;
  editDocuments: boolean;
  editSubmissions: boolean;
  editProjects: boolean;
  hasStreamInvites: boolean;
  viewRAPIDExport: boolean;
  importCarrierMetrics: boolean;
  manageUsers: boolean;
  viewAccounts: boolean;
}

export interface DeleteStreamVariables {
  input: {
    slug: string;
  };
}
