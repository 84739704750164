import { IGraphQLSnapshot } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';

export const getSnapshotLabel = (snapshots: Array<IGraphQLSnapshot>, snapshotName?: string) => {
  if (!snapshotName || !snapshots || snapshots?.length < 1) {
    return undefined;
  }

  for (let i = 0; i < snapshots.length; i++) {
    const snapshot = snapshots[i];
    if (snapshot.name === snapshotName) {
      return formatDate(snapshot.displayDate || snapshot.date);
    }
  }
  return undefined;
};
