import { Hazard } from './types';

const hazardFilterOptions: Hazard[] = [
  { dropdownDisplay: 'Show all properties', inputDisplay: 'All properties', value: 'All' },
  {
    dropdownDisplay: 'Show earthquake exposed properties',
    inputDisplay: 'Earthquake exposed properties',
    value: 'Earthquake',
  },
  {
    dropdownDisplay: 'Show wind exposed properties',
    inputDisplay: 'Wind exposed properties',
    value: 'Wind',
  },
  {
    dropdownDisplay: 'Show flood exposed properties',
    inputDisplay: 'Flood exposed properties',
    value: 'Flood',
  },
];

export default hazardFilterOptions;
