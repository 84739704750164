import React from 'react';
import { ButtonIcon } from 'ui';

export const HelpButton = () => (
    <ButtonIcon
      className="topnav-help-button"
      iconName="help"
      size="m"
      aria-label="help button"
      color="primary"
    />
  );
