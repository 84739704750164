import React from 'react';
import { Button, EuiFlexGroup, EuiFlexItem, useToast } from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { Status } from '@app/graphql/jobs/jobs.types';
import { useSetHazardsMutation } from '@app/graphql/jobs/mutations/__generated__/setHazards.generated';
import { useSetHazardsProgressPoller } from '@app/hooks/useSetHazardsProgressPoller';

export const HazardsTab = ({ stream }) => {
  const jobsApolloClient = useJobsApolloClient();
  const toast = useToast();

  const [setHazardsMutation, { loading }] = useSetHazardsMutation({
    client: jobsApolloClient,
    onCompleted: () => {
      toast({ label: 'Hazards computation initiated.', title: 'Success' });
      startSetHazardsProgressPoll(stream?.orgName);
    },
    onError: () => {
      toast({ label: 'There was an error initiating hazards computation.', title: 'Error' });
    },
  });

  const onComputationCompleted = () => {
    toast({ label: 'Hazards computation completed.', title: 'Success' });
  };

  const onError = () => {
    toast({ label: 'Hazards computation encountered an error.', title: 'Error' });
  };

  const onTimeout = () => {
    toast({ label: 'Hazards computation timed out.', title: 'Error' });
  };

  const { startSetHazardsProgressPoll, isPolling } = useSetHazardsProgressPoller(
    onComputationCompleted,
    {
      interval: 2000,
      onError,
      onTimeout,
      targetStatus: [Status.Completed],
    },
  );

  const handleClick = () => {
    setHazardsMutation({
      variables: {
        input: {
          orgName: stream?.orgName,
        },
      },
    });
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={0}>
        <Button
          loading={isPolling || loading}
          label="Compute Archipelago Hazards"
          onClick={handleClick}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
