// TODO - Remove once state code is added to LossRegion type

export const stateCodeMap = {
  Alabama: 'us-al',
  Alaska: 'us-ak',
  'American Samoa': 'us-as',
  Arizona: 'us-az',
  Arkansas: 'us-ar',
  California: 'us-ca',
  Colorado: 'us-co',
  Connecticut: 'us-ct',
  Delaware: 'us-de',
  'District of Columbia': 'us-dc',
  Florida: 'us-fl',
  Georgia: 'us-ga',
  Guam: 'us-gu',
  Hawaii: 'us-hi',
  Idaho: 'us-id',
  Illinois: 'us-il',
  Indiana: 'us-in',
  Iowa: 'us-ia',
  Kansas: 'us-ks',
  Kentucky: 'us-ky',
  Louisiana: 'us-la',
  Maine: 'us-me',
  Maryland: 'us-md',
  Massachusetts: 'us-ma',
  Michigan: 'us-mi',
  Minnesota: 'us-mn',
  Mississippi: 'us-ms',
  Missouri: 'us-mo',
  Montana: 'us-mt',
  Nebraska: 'us-ne',
  Nevada: 'us-nv',
  'New Hampshire': 'us-nh',
  'New Jersey': 'us-nj',
  'New Mexico': 'us-nm',
  'New York': 'us-ny',
  'North Carolina': 'us-nc',
  'North Dakota': 'us-nd',
  'Northern Mariana Islands': 'us-mp',
  Ohio: 'us-oh',
  Oklahoma: 'us-ok',
  Oregon: 'us-or',
  Pennsylvania: 'us-pa',
  'Puerto Rico': 'us-pr',
  'Rhode Island': 'us-ri',
  'South Carolina': 'us-sc',
  'South Dakota': 'us-sd',
  Tennessee: 'us-tn',
  Texas: 'us-tx',
  'United States Minor Outlying Islands': 'us-um',
  Utah: 'us-ut',
  Vermont: 'us-vt',
  'Virgin Islands US': 'us-vi',
  Virginia: 'us-va',
  Washington: 'us-wa',
  'West Virginia': 'us-wv',
  Wisconsin: 'us-wi',
  Wyoming: 'us-wy',
};
