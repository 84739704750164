import { CheckedRows, DataPayloads, DataType } from '../types/commonTypes';
import { Filter } from '../types/contextTypes';
import { QueryFilter, QueryFilterName } from '../types/queryTypes';

export const arrayRange = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(null)
    .map((_, index) => start + index);

export const camelCase = (string: string) => {
  const trimmedString = string.split(' ').join('');
  return trimmedString.charAt(0).toLowerCase() + trimmedString.slice(1);
};

export const getCheckedRowsRecord = (start: number, end: number): CheckedRows => {
  const checkedRowsRecord: CheckedRows = {};

  arrayRange(start, end).forEach((index) => {
    checkedRowsRecord[index] = false;
  });

  return checkedRowsRecord;
};

export const getDataLength = (data: DataPayloads, dataType: DataType): number => {
  if (dataType === 'documents') {
    // @ts-expect-error: not concerned that data could be a different shape
    return data?.organizationDocumentsPage?.documents?.length;
  }
  if (dataType === 'properties') {
    // @ts-expect-error: not concerned that data could be a different shape
    return data?.organizationDocumentsPropertiesPage?.properties?.length;
  }
  throw new Error('unexpected dataType parameter');
};

export const getLastNItemsInObject = (object: Object, n: number): Array<any> => {
  const arrayN = Array.from(Array(n).keys());

  const objectLength = Object.keys(object).length;

  return arrayN.map((i) => {
    const key = Object.keys(object)[objectLength - 1 - i];
    return { [key]: object[key] };
  });
};

export const isEmptyObject = (object: object) => Object.keys(object).length === 0;

export const removeQueryFilter = (queryFilters: QueryFilter[], nameToRemove: QueryFilterName) =>
  queryFilters.filter((queryFilter) => queryFilter.name !== nameToRemove);

export const removeStringUnderscore = (string: string) => string.replace(/_/g, ' ');

export const underscoreString = (string: string) => string.replace(/\s+/g, '_');

// Given the current filter options and the parameter set in the query string, determine which
// option is equal to what is set in the query string by parsing the query string (removing the
// underscore)
export const getOptionFromQueryString = (
  filterOptions: Filter[],
  queryStringParam: string,
): Filter => {
  const id = removeStringUnderscore(queryStringParam);
  return filterOptions.filter((filterOption) => filterOption.id === id)[0];
};
