import { css } from '@emotion/css';

const emptyDiv = css`
  width: 24px;
`;

const grandchildContainer = css`
  padding-left: 12px !important;
`;

export default {
  emptyDiv,
  grandchildContainer,
};
