import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetryJobMutationVariables = Types.Exact<{
  input: Types.RetryJobInput;
}>;


export type RetryJobMutation = { __typename?: 'Mutation', retryJob?: { __typename?: 'Job', id: string } | null };


export const RetryJobDocument = gql`
    mutation RetryJob($input: RetryJobInput!) {
  retryJob(input: $input) {
    id
  }
}
    `;
export type RetryJobMutationFn = Apollo.MutationFunction<RetryJobMutation, RetryJobMutationVariables>;

/**
 * __useRetryJobMutation__
 *
 * To run a mutation, you first call `useRetryJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryJobMutation, { data, loading, error }] = useRetryJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryJobMutation(baseOptions?: Apollo.MutationHookOptions<RetryJobMutation, RetryJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryJobMutation, RetryJobMutationVariables>(RetryJobDocument, options);
      }
export type RetryJobMutationHookResult = ReturnType<typeof useRetryJobMutation>;
export type RetryJobMutationResult = Apollo.MutationResult<RetryJobMutation>;
export type RetryJobMutationOptions = Apollo.BaseMutationOptions<RetryJobMutation, RetryJobMutationVariables>;