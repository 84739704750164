import moment from 'moment';
import { UploadDate } from '../../types/commonTypes';
import { Filter } from '../../types/contextTypes';

export default (): Filter[] => {
  const dateFormat = 'MMM D';

  const currentDate = moment();
  const currentDateFormat = moment().format(dateFormat);

  const thisWeekStartDate = currentDate.clone().startOf('week');
  const thisWeekStartDateUTC = thisWeekStartDate.utc().toISOString();
  const thisWeekStartDateFormat = thisWeekStartDate.format(dateFormat);

  const thisMonthStartDate = currentDate.clone().startOf('month');
  const thisMonthStartDateUTC = thisMonthStartDate.utc().toISOString();
  const thisMonthStartDateFormat = thisMonthStartDate.format(dateFormat);

  const lastMonthStartDate = currentDate.clone().startOf('month').subtract(1, 'months');
  const lastMonthStartDateUTC = lastMonthStartDate.utc().toISOString();
  const lastMonthStartDateFormat = lastMonthStartDate.format(dateFormat);
  const lastMonthEndDate = currentDate.clone().startOf('month');
  const lastMonthEndDateUTC = lastMonthEndDate.utc().toISOString();
  const lastMonthEndDateFormat = lastMonthEndDate.subtract(1, 'days').format(dateFormat);

  const thisQuarterStartDate = currentDate.clone().startOf('quarter');
  const thisQuarterStartDateUTC = thisQuarterStartDate.utc().toISOString();
  const thisQuarterStartDateFormat = thisQuarterStartDate.format(dateFormat);

  const thisYearStartDate = currentDate.clone().startOf('year');
  const thisYearStartDateUTC = thisYearStartDate.utc().toISOString();
  const thisYearStartDateFormat = thisYearStartDate.format(dateFormat);

  return [
    { headerLabel: 'All', id: UploadDate.ALL, rowLabel: 'All' },
    {
      headerLabel: `This Week (${thisWeekStartDateFormat} - ${currentDateFormat})`,
      id: UploadDate.THIS_WEEK,
      meta: { startDate: thisWeekStartDateUTC },
      rowLabel: 'This Week',
    },
    {
      headerLabel: `This Month (${thisMonthStartDateFormat} - ${currentDateFormat})`,
      id: UploadDate.THIS_MONTH,
      meta: { startDate: thisMonthStartDateUTC },
      rowLabel: 'This Month',
    },
    {
      headerLabel: `Last Month (${lastMonthStartDateFormat} - ${lastMonthEndDateFormat})`,
      id: UploadDate.LAST_MONTH,
      meta: {
        endDate: lastMonthEndDateUTC,
        startDate: lastMonthStartDateUTC,
      },
      rowLabel: 'Last Month',
    },
    {
      headerLabel: `This Quarter (${thisQuarterStartDateFormat} - ${currentDateFormat})`,
      id: UploadDate.THIS_QUARTER,
      meta: { startDate: thisQuarterStartDateUTC },
      rowLabel: 'This Quarter',
    },
    {
      headerLabel: `This Year (${thisYearStartDateFormat} - ${currentDateFormat})`,
      id: UploadDate.THIS_YEAR,
      meta: { startDate: thisYearStartDateUTC },
      rowLabel: 'This Year',
    },
  ];
};
