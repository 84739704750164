import { SizeBy } from '@app/components/PropertiesMap/types';
import squash from '@app/utils/squash';

export const MIN = {
  color: [195, 86, 64],
  fontSize: 8,
  size: 45,
};

export const MAX = {
  color: [214, 84, 56],
  fontSize: 14,
  size: 84,
};

const asHsl = (hsl: [number, number, number]) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

const range: [number, number] = [0, 1];

// size is defined as a number between 0 and 1
const getStyle = (val: number, sizeRange: [number, number], sizeBy: SizeBy | undefined) => {
  if (val < range[0] || val > range[1]) {
    throw new Error(
      `getClusterSize should return a value between ${range[0]} and ${range[1]}. Returned value is ${val}`,
    );
  }

  const backgroundColor =
    sizeBy === 'num'
      ? asHsl([MIN.color[0], MIN.color[1], MIN.color[2]])
      : asHsl([
          squash(val, range, [MIN.color[0], MAX.color[0]]),
          squash(val, range, [MIN.color[1], MAX.color[1]]),
          squash(val, range, [MIN.color[2], MAX.color[2]]),
        ]);
  const size = squash(val, range, sizeRange);
  const fontSize = squash(val, range, [MIN.fontSize, MAX.fontSize]);
  const fontWeight = sizeRange[0] > 1 ? ('bold' as 'bold') : ('normal' as 'normal');

  return {
    backgroundColor,
    color: 'black',
    fontSize,
    fontWeight,
    height: size,
    marginLeft: size / -2,
    marginTop: size / -2,
    width: size,
  };
};

export default getStyle;
