import { useQuery } from '@apollo/client';
import {
  GetOrgAttributeMetadataVariables,
  GetPropertyAttributeMetadataV2Result,
  GetPropertyAttributeMetadataV2Variables,
  OrgAttributeMetadataResult,
} from '@app/queries/propertyMetadata/types';
import {
  GET_EXPORT_CONFIGURATIONS,
  GET_ORGANIZATION_ATTRIBUTE_METADATA,
  GET_PROPERTY_ATTRIBUTE_METADATA_V2,
} from '../graphql/queries';
import {
  ExportConfigurationsResultsType,
  ExportConfigurationsVariablesType,
} from '../graphql/types';

interface IUseQueries {
  orgId: string;
  streamId?: string;
  streamSlug?: string;
}

export const useManageExportsQueries = ({ orgId, streamId, streamSlug }: IUseQueries) => {
  const { data: exportConfigsResult, loading: exportConfigsLoading } = useQuery<
    ExportConfigurationsResultsType,
    ExportConfigurationsVariablesType
  >(GET_EXPORT_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: { input: { organizationID: orgId, streamID: streamId } },
  });

  const { data: orgAttrData, loading: orgAttrLoading } = useQuery<
    OrgAttributeMetadataResult,
    GetOrgAttributeMetadataVariables
  >(GET_ORGANIZATION_ATTRIBUTE_METADATA, {
    fetchPolicy: 'no-cache',
    variables: { organizationId: orgId },
  });

  const { data: propAttrData, loading: propAttrLoading } = useQuery<
    GetPropertyAttributeMetadataV2Result,
    GetPropertyAttributeMetadataV2Variables
  >(GET_PROPERTY_ATTRIBUTE_METADATA_V2, {
    fetchPolicy: 'no-cache',
    skip: !streamSlug,
    variables: { input: { streamSlug } },
  });

  return {
    exportConfigsLoading,
    exportConfigsResult,
    orgAttrData,
    orgAttrLoading,
    propAttrData,
    propAttrLoading,
  };
};
