import React, { FC, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EuiFlexGroup, EuiFlexItem, useToast } from 'ui';
import { FilterQuery } from '@app/components/Filter/types';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import { IAccountState } from '@app/containers/AuthProvider/types';
import { PaginationProvider } from '@app/contexts/PaginationContext/PaginationContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useQueryState } from '@app/hooks/useQueryState';
import { TrackStreamPage, useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import ADD_VIEWED_HIGHLIGHT from '@app/queries/users/addViewedHighlight.gql';
import Aside from './Aside/Aside';
import Distillers from './Distillers/Distillers';
import { MODE } from './Distillers/types';
import Reef from './Reef/Reef';
import StreamTour from './StreamTour/StreamTour';
import {
  addExpandValue,
  addToGroupByV2,
  clearValuesAfter,
  getActiveGroupIndex,
  getGroupByFromState,
  removeExpandValue,
} from './groupbyv2';
import StreamHeader from './StreamHeader';
import StreamMetaStoreContext, { initStreamContext } from './StreamMetaStoreContext';
import { Container, ElasticShim, ReefContainer } from './StreamPage.emotion';
import { GroupByV2, IPageState, StreamPageOptions } from './types';
import { getDefaultDistillers, getMetadataByType, isMode,KEYS } from './utils';

interface IProps {
  options: StreamPageOptions;
  account: IAccountState;
  pageState: IPageState;
  setPageState: (props: Partial<IPageState>) => void;
}
const emptyChangesSince: Array<GroupByV2> = [
  {
    attributeName: 'changesSince',
    value: '',
  },
];

export const getNotificationMessage = (value: string) =>
  `Got it! Open a group to see the ${value}.`;

const StreamPage: FC<IProps> = ({ account, pageState, setPageState, options }) => {
  const toast = useToast();
  const mixpanel = useTracker('mixpanel');
  const { selectedOrganization } = useUserSession();

  const { stream, propertyAttributeMetadata, permissions } = useStreamContext();
  const groupable = getMetadataByType(propertyAttributeMetadata, 'groupable');
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');
  const [queryState] = useQueryState();

  const distillers = useMemo(
    () =>
      getDefaultDistillers(
        {
          groupable,
        },
        pageState,
        permissions,
      ),
    [JSON.stringify(groupable), pageState.groupByV2, pageState.sizeBy],
  );

  const urlMode = queryState.get(KEYS.mode);

  const currentMode = isMode(urlMode)
    ? urlMode
    : isMode(stream?.configuration?.defaultStreamView)
    ? stream?.configuration?.defaultStreamView
    : MODE.GRID;

  pageState.mode = currentMode;
  const filters = pageState.filters || undefined;

  const handleFilterQueryChange = (filterQuery: FilterQuery | undefined) =>
    setPageState({
      filters: filterQuery || null,
    });

  const setActiveGroup = (activeGroup: Array<number>, label: string) => {
    const derivedLabel = label === '' ? 'No Label' : label;

    mixpanel.track(`${TrackStreamPage.prefix}: Active group distiller applied`, {
      event_surface: TrackStreamPage.event_surface,
      group_by_index: activeGroup,
      group_by_value: derivedLabel,
      organization_name: stream.orgName,
      stream_name: stream.name,
      stream_slug: stream.slug,
    });
    const activeGroupIndex = activeGroup.length > 0 ? activeGroup[0] : undefined;
    const previousActiveGroupIndex = getActiveGroupIndex(pageState.groupByV2);
    if (activeGroupIndex === previousActiveGroupIndex) {
      // Closing a grouping
      return setPageState({
        [KEYS.groupByV2]: removeExpandValue(pageState.groupByV2),
      });
    }
    // Opening a grouping

    // This is a bit of a hack.
    // When using "Changes since" as a grouping, pageState.groupByV2 is always empty.
    // When using "Group by" as a grouping, pageState.groupByV2 has always atleast 1 row in the array.
    const groupByValue =
      pageState.groupByV2.length === 0
        ? addExpandValue(emptyChangesSince, derivedLabel, activeGroupIndex)
        : addExpandValue(pageState.groupByV2, derivedLabel, activeGroupIndex);

    return setPageState({
      [KEYS.groupByV2]: groupByValue,
      mapPosition: null,
    });
  };

  const onBreadcrumbClick = (index: number) => {
    setPageState({
      [KEYS.groupByV2]: clearValuesAfter(pageState.groupByV2, index),
    });
  };

  const onSizeByChange = (value: string) => {
    setPageState({
      [KEYS.sizeBy]: value,
    });
  };

  const onGroupByChange = (name: string, value?: string[]) => {
    if (value.length > 0) {
      if (name === 'sizeBy') {
        mixpanel.track(`${TrackStreamPage.prefix}: Size By`, {
          event_surface: TrackStreamPage.event_surface,
          organization_name: stream.orgName,
          sizeBy: value,
          stream_name: stream.name,
          stream_slug: stream.slug,
        });
      } else {
        const mixpanelPayload = {
          event_surface: TrackStreamPage.event_surface,
          group_by_count: value.length,
          organization_name: stream.orgName,
          stream_name: stream.name,
          stream_slug: stream.slug,
        };
        for (let i = 0; i < value.length; i++) {
          const v = value[i];
          mixpanelPayload[`group_by_${i + 1}`] = v;
        }
        mixpanel.track(`${TrackStreamPage.prefix}: Grouped By`, mixpanelPayload);
      }
    } else {
      mixpanel.track(`${TrackStreamPage.prefix}: Cleared Grouped By`, {
        event_surface: TrackStreamPage.event_surface,
        organization_name: stream.orgName,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
    }

    const setBlankIfOpposite = (target: string, opposite: string) => ({
      [target]: name === opposite ? '' : distillers[target]?.value || '',
    });

    if (name === 'sizeBy') {
      onSizeByChange(value?.[0]);
      return;
    }

    // Removes changesSince grouping from array when we change from "Changes since" to "Group by".
    const withoutChanges = pageState.groupByV2.filter((x) => x.attributeName !== 'changesSince');
    const newGroupByV2State = addToGroupByV2(withoutChanges, value);
    setPageState({
      ...setBlankIfOpposite(KEYS.groupByV2, 'changesSince'),
      ...setBlankIfOpposite('changesSince', KEYS.groupByV2),
      [KEYS.groupByV2]: newGroupByV2State,
    });
  };

  const setHighlights = (highlights: number | null) =>
    setPageState({
      highlights: highlights === null ? null : `${highlights}`,
    });

  const setMode = (mode: MODE) => {
    mixpanel.track(`${TrackStreamPage.prefix}: View mode changed`, {
      event_surface: TrackStreamPage.event_surface,
      organization_name: stream.orgName,
      stream_name: stream.name,
      stream_slug: stream.slug,
      viewCurrent: mode,
      viewPrevious: pageState.mode,
    });

    setPageState({
      mode,
    });
  };

  const setSort = (sort: IGraphQLSort) => setPageState({ sort });

  const setExpandedColumns = (expandedColumns: Array<string>) =>
    setPageState({
      expandedColumns: expandedColumns.filter(Boolean).join(','),
    });

  const groupBy = getGroupByFromState(pageState.groupByV2);

  const hasViewedHighlights = !!(account?.viewedHighlights || {})[stream?.slug];
  const [addHighlight] = useMutation(ADD_VIEWED_HIGHLIGHT);
  const [addedHighlight, setAddedHighlight] = useState(hasViewedHighlights);

  const handleOpenTour = (step: number) => {
    setHighlights(step + 1);

    if (!addedHighlight && stream?.slug) {
      addHighlight({
        variables: {
          label: '',
          streamSlug: stream.slug,
        },
      });
      setAddedHighlight(true);
      if (account?.addViewedHighlight) {
        account.addViewedHighlight(stream.slug, '');
      }
    }
  };

  return (
    <StreamMetaStoreContext.Provider value={initStreamContext(stream)}>
      <PaginationProvider stream={stream}>
        <ElasticShim>
          <Container data-testid="stream-page">
            <EuiFlexGroup direction="column" style={{ flexGrow: 0 }}>
              <EuiFlexItem grow={false}>
                <StreamHeader
                  options={options}
                  streamTour={
                    <StreamTour
                      stream={stream}
                      pageState={pageState}
                      setPageState={setPageState}
                      tour={stream.highlights !== null ? JSON.parse(stream.highlights) : null}
                      setHighlights={setHighlights}
                      handleOpenTour={handleOpenTour}
                      hasSeenTour={hasViewedHighlights}
                    />
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <Distillers
                  stream={stream}
                  distillers={distillers}
                  handleModeChange={(value: MODE) => {
                    // When grouping is enabled, and we haven't uncollapsed the group, we'll show a
                    // notification to the user that he should collapse a group to see the data.
                    if (groupBy && getActiveGroupIndex(pageState.groupByV2) === undefined) {
                      toast({ title: getNotificationMessage(value) });
                    }
                    setMode(value);
                  }}
                  handleChange={onGroupByChange}
                  filterQuery={filters}
                  onFilterQueryChange={handleFilterQueryChange}
                  pageState={pageState}
                  filterable={filterable}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <ReefContainer data-testid="reef-container">
              <Reef
                handleActiveGroupChange={setActiveGroup}
                handleSortChange={setSort}
                handleExpandColumns={setExpandedColumns}
                pageState={pageState}
                distillers={distillers}
                filters={convertRuleGroupToFilters(filters)}
                onBreadcrumbClick={onBreadcrumbClick}
              />
            </ReefContainer>
          </Container>
          <Aside />
        </ElasticShim>
      </PaginationProvider>
    </StreamMetaStoreContext.Provider>
  );
};

export default StreamPage;
