import { css } from '@emotion/css';

const checkboxContainer = css`
  margin-right: 16px !important;
`;

const container = css`
  height: 100%;
  width: 100%;
`;

const imageContainer = css`
  margin-left: 16px !important;
`;

const subTitle = css`
  font-size: 12px !important;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`;

const textButton = css`
  :focus {
    background: initial !important;
    text-decoration: initial !important;
  }
  :hover {
    text-decoration: initial !important;
  }
  > span {
    justify-content: flex-start;
  }
`;

const textButtonContainer = css`
  height: 100%;
  justify-content: center;
  width: 250px;
`;

const textContainer = css`
  align-items: baseline;
  width: 100%;

  .euiToolTipAnchor {
    width: 100%;
  }
`;

const title = css`
  font-size: 14px !important;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`;

export default {
  checkboxContainer,
  container,
  imageContainer,
  subTitle,
  textButton,
  textButtonContainer,
  textContainer,
  title,
};
