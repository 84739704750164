import React, { useEffect } from 'react';
import { EuiText } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { AdditionalAttributes } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { useTracker } from '@app/hooks/useTracker';
import { useStreamContext } from '../../StreamProvider';
import { TabContentGroup } from '../AddPropertyFlyout.emotion';
import { useAddPropertyFlyoutContext } from '../context/AddPropertyFlyout.context';

export const AdditionalAttributesTab = () => {
  const { stream } = useStreamContext();
  const { property } = useAddPropertyFlyoutContext();
  const mixpanel = useTracker('mixpanel');
  useEffect(() => {
    if (!stream) {
      return;
    }
    const payload: any = {
      organization_name: stream?.orgName,
    };

    if (!!property) {
      const { archipelagoId, locationId, locationName } = property;
      payload.archipelago_id = archipelagoId;
      payload.location_id = locationId;
      payload.location_name = locationName;
    }

    sendMixpanelEvent(mixpanel, 'Open additional attributes tab', stream, payload);
  }, [stream?.slug, property?.archipelagoId]);

  return (
    <TabContentGroup direction="column">
      <EuiText size="s" color="subdued">
        We recommend filling out additional attributes to help with enrichment. You can use the
        search bar to find and add attributes not shown below.
      </EuiText>
      <AdditionalAttributes />
    </TabContentGroup>
  );
};
