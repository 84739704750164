import React, { FC } from 'react';
import { EuiEmptyPrompt, EuiText } from 'ui';

const ErrorState: FC = () => (
  <EuiEmptyPrompt
    iconType="alert"
    iconColor="subdued"
    title={
      <EuiText size="m" data-testid="dqs-error">
        We had some trouble loading your data. Please try refreshing the page.
      </EuiText>
    }
  />
);

export default ErrorState;
