import React, { FC, MouseEvent, ReactElement, useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'ui';
import Markdown from '@app/components/Markdown/Markdown';
// FIX ME
// @ts-ignore
import { AttributeEditInfo } from '@app/queries/properties/generated_types';
import SourceLink from '../GridCell/SourceLink/SourceLink';
import { IProvenanceForTooltip, ProvenanceDocument } from './types';
import { getSourceType } from './utils';

// Timeout before calling handle open event (ms)
const HANDLE_OPEN_TIMEOUT = 500;
const cancelPropagate = (e: MouseEvent) => e.stopPropagation();

const Popup = styled.div`
  label: PropertiesGridTooltipPopup;
  font-weight: 600;
  font-style: normal;
  display: flex;
  max-width: 440px;
  flex-direction: column;

  a {
    color: #2d9cdb;
    text-decoration: none;
  }
`;

const Title = styled.div`
  label: PropertiesGridTooltipChildren;
  flex: 0 1 auto; /* Fixes IE11 */
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 15.8px;
  line-height: 25px;
  letter-spacing: 0.005em;
  color: #333333;
`;

const Provenance = styled.div`
  label: PropertiesGridTooltipProvenance;
  flex-direction: column;
  display: flex;
  background-color: #fbfbfb;
  padding: 24px;
  min-width: 256px;
`;

const HeaderContainer = styled.div`
  label: PropertiesGridTooltipHeaderContainer;
  display: flex;
`;

const IconContainer = styled.div`
  label: PropertiesGridTooltipIcon;
  margin-right: 16px;
`;

const DetailsContainer = styled.div`
  label: PropertiesGridTooltipDetailsContainer;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 256px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0025em;
  color: #828282;
`;

const Description = styled.div`
  label: PropertiesGridTooltipDescription;
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 13.82px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #333333;
`;

const SolidLine = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  margin-left: -1px;
  margin-right: -1px;
`;

const stopPropagation = (e: MouseEvent<HTMLElement>) => e.stopPropagation();

const TitleContainer = ({ title }: Pick<IProps, 'title'>) =>
  !title ? null : <Title>{title}</Title>;

const ProvenanceContainer = ({
  provenance,
  handleDocumentDownload,
}: Pick<IProps, 'provenance' | 'handleDocumentDownload'>) => {
  if (!provenance) {
    return null;
  }
  const source = Array.isArray(provenance.sources)
    ? provenance.sources[0]
    : (provenance.sources as any);
  return (
    <Provenance data-testid="provenance-modal-body">
      <HeaderContainer>
        <IconContainer>
          <Icon color="primary" name="info" />
        </IconContainer>
        <Markdown
          content={source || 'Provenance'}
          handleClick={stopPropagation}
          handleLinkClick={(url) => {
            handleDocumentDownload({ filename: 'Provenance Link', httpURL: url });
          }}
        />
      </HeaderContainer>
      <DetailsContainer>
        <SourceLink
          sourceDocuments={provenance.sourceDocuments}
          externalSourceURLs={provenance.externalSourceURLs}
          sourceType={getSourceType(source)}
          handleDocumentDownload={handleDocumentDownload}
        />
      </DetailsContainer>
    </Provenance>
  );
};

const DescriptionContainer = ({ description }: Pick<IProps, 'description'>) =>
  !description ? null : <Description>{description}</Description>;

const TitleProvenanceBorder = ({
  title,
  provenance,
  description,
}: Pick<IProps, 'title' | 'provenance' | 'description'>) =>
  title && (provenance || description) ? <SolidLine /> : null;

const ProvenanceDescriptionBorder = ({
  provenance,
  description,
}: Pick<IProps, 'provenance' | 'description'>) =>
  provenance && description ? <SolidLine /> : null;

export interface IProps {
  provenance?: IProvenanceForTooltip;
  editInfo: Array<AttributeEditInfo>;
  handleOpen?: () => void;
  handleDocumentDownload: (doc: ProvenanceDocument) => void;
  description?: ReactElement | string;
  title?: string;
}

const PropertiesGridTooltipBody: FC<IProps> = ({
  provenance,
  editInfo,
  handleOpen,
  handleDocumentDownload,
  description,
  title,
}) => {
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (handleOpen) {
        handleOpen();
      }
    }, HANDLE_OPEN_TIMEOUT);
    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return (
    <Popup onClick={cancelPropagate}>
      <TitleContainer title={title} />
      <TitleProvenanceBorder title={title} provenance={provenance} description={description} />
      <ProvenanceContainer
        provenance={provenance}
        handleDocumentDownload={handleDocumentDownload}
      />
      {editInfo?.map(({ author }) => (
        <ProvenanceContainer
          provenance={{
            externalSourceURLs: [],
            sourceDocuments: [],
            sources: [`Edited by ${author}`],
          }}
          handleDocumentDownload={handleDocumentDownload}
        />
      ))}
      <ProvenanceDescriptionBorder provenance={provenance} description={description} />
      <DescriptionContainer description={description} />
    </Popup>
  );
};

export default PropertiesGridTooltipBody;
