import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiTitle } from 'ui';
import { COPILOT_URL } from '@app/config';
import { ProtectedImage } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/ProtectedImage';
import { SourceDocument } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';
import { useCopilotContext } from '../../context/context';

interface Props {
  sources: Array<SourceDocument>;
}

const SourceDocumentItem: React.FC<{ source: SourceDocument; index: number }> = ({
  source,
  index,
}) => {
  const { openSource } = useCopilotContext();
  return (
    <>
      <EuiLink
        onClick={() => {
          openSource(source);
        }}
      >
        <h4>{source?.sources?.[0]?.doc_class || `Document ${index}`}</h4>
      </EuiLink>
      <EuiFlexGroup alignItems="flexStart">
        {source.sources.map((source) => (
          <EuiFlexItem grow={false}>
            <ProtectedImage
              src={`${COPILOT_URL}/highlights/${source.cid}`}
              size="m"
              alt={source.id}
              allowFullScreen
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </>
  );
};
export const SourceList: React.FC<Props> = ({ sources }) => (
  <>
    <EuiTitle>
      <h3>Sources</h3>
    </EuiTitle>
    <EuiFlexGroup direction="column">
      {sources?.map((source, index) => (
        <EuiFlexItem>
          <SourceDocumentItem source={source} key={source.document_id} index={index} />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  </>
);
