import { HazardExposureType } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import gql from 'graphql-tag';
import { BarScore } from '../Charts/HorizontalBarChart/types';

export const GET_ATTRIBUTE_SCORES = gql`
  query getQualityAttribute($input: QualityAttributeInput!) {
    qualityAttribute(input: $input) {
      attributeName
      scores {
        locations
        scoreQuality
        tiv
        tivPct
      }
    }
  }
`;

export interface AttributeScoresData {
  qualityAttribute: {
    attributeName: string;
    scores: BarScore[];
  }[];
}

export interface AttributeScoresVariables {
  input: {
    hazard: HazardExposureType;
    snapshotName: string;
    streamSlug: string;
  };
}
