import React from 'react';
import { GradeDefinitions } from '@app/cx/Reports/queries/getStream';
import { ExposureLevel, SegmentPercents, TableRow } from '../../types';
import analyzePercentScore from '../../utils/analyzePercentScore';
import styles from './Bar.emotion';
import BarDot from './BarDot';
import BarSegment from './BarSegment';
import { getBarSegmentPercent } from './utils';

interface Props {
  TIVPct: string | number;
  showSegmentBackgrounds?: boolean;
  row: TableRow;
}

const getSegmentPercentsFromGrades = (
  gradeDefinitions: Array<GradeDefinitions>,
): SegmentPercents => {
  const m = {};
  gradeDefinitions.forEach(({ indexGrade, maxPercentileValue }) => {
    m[indexGrade] = maxPercentileValue;
  });

  return m as SegmentPercents;
};

const Bar: React.FC<Props> = ({ TIVPct, showSegmentBackgrounds = false, row }) => {
  const segmentPercents = getSegmentPercentsFromGrades(row.gradeDefinitions);

  const exposureLevel = analyzePercentScore(parseFloat(TIVPct.toString()), segmentPercents);

  return (
    <div data-testid="exposure-bar" className={styles.container}>
      <BarDot exposureLevel={exposureLevel} TIVPct={TIVPct} />
      <BarSegment
        percent={getBarSegmentPercent(row, ExposureLevel.VeryLow)}
        currentExposureLevel={exposureLevel}
        exposureLevel={ExposureLevel.VeryLow}
        showSegmentBackgrounds={showSegmentBackgrounds}
      />
      <div className={styles.gap} />
      <BarSegment
        percent={getBarSegmentPercent(row, ExposureLevel.Low)}
        currentExposureLevel={exposureLevel}
        exposureLevel={ExposureLevel.Low}
        showSegmentBackgrounds={showSegmentBackgrounds}
      />
      <div className={styles.gap} />
      <BarSegment
        percent={getBarSegmentPercent(row, ExposureLevel.Medium)}
        currentExposureLevel={exposureLevel}
        exposureLevel={ExposureLevel.Medium}
        showSegmentBackgrounds={showSegmentBackgrounds}
      />
      <div className={styles.gap} />
      <BarSegment
        percent={getBarSegmentPercent(row, ExposureLevel.High)}
        currentExposureLevel={exposureLevel}
        exposureLevel={ExposureLevel.High}
        showSegmentBackgrounds={showSegmentBackgrounds}
      />
      <div className={styles.gap} />
      <BarSegment
        percent={getBarSegmentPercent(row, ExposureLevel.VeryHigh)}
        currentExposureLevel={exposureLevel}
        exposureLevel={ExposureLevel.VeryHigh}
        showSegmentBackgrounds={showSegmentBackgrounds}
      />
    </div>
  );
};

export default Bar;
