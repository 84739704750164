import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLRawJSON } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { ChartData, chartDataIsEqual } from './utils';

const formatPercentage = (value = 0) => {
  const percentage = (value * 100).toFixed(2);

  return parseFloat(percentage);
};

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const buildChartData = (explorer: IGraphQLRawJSON): Array<ChartData> => {
  const chartData: Array<ChartData> = [];
  const data = JSON.parse(explorer.json);
  const { data: payload } = data[0];

  for (let i = 0; i < payload.length; i++) {
    chartData.push({
      name: payload[i].label,
      y: formatPercentage(payload[i].pct),
    });
  }

  return chartData;
};

const getOptions = (queryData: Array<ChartData>, title?: string) => ({
  chart: {
    height: 160,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  lang: {
    numericSymbols: undefined,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      pointWidth: 10,
    },
  },
  series: [
    {
      data: queryData,
      name: 'R-Value',
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${this.point.name}<br><b>${this.point.y}%</b>`;
    },
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    endOnTick: true,
    labels: {
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    max: 100,
    min: 0,
    showLastLabel: true,
    tickPositions: [0, 20, 40, 60, 80, 100],
    title: {
      text: undefined,
    },
  },
});

const TivRValue: React.FC<IProps> = ({ explorer, title }) => {
  const queryData = buildChartData(explorer as IGraphQLRawJSON);

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={getOptions(queryData, title)} />
    </Explorer>
  );
};

export default memo(TivRValue, chartDataIsEqual);
