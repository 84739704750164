import React from 'react';
import { Grade, PropertyGradeDetail } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { gradedMetrics } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/InsurerInsights';
import { memoize } from 'lodash';
import { EuiBasicTableColumn } from 'ui';
import { getLocale } from '@app/utils/format';
import SingleBarChart, { Props as SingleBarCartProps } from '../../../charts/SingleBarChart';
import { getColor } from '../../../utils';
import GradeBadge from '../GradeBadge';
import { OverallTableData, renderPct } from './tableData';

// `formatCurrency` isn't rounding correctly the values even when passing
// the right options. I'm creating this temporary function that addresses
// this specific table needs.
const formatNumber = (val) =>
  new Intl.NumberFormat(getLocale(), {
    currency: 'USD',
    maximumFractionDigits: 1,
    notation: 'compact',
    style: 'currency',
    useGrouping: true,
  }).format(Number(val));

const getColumns = memoize((riskAttribute: string) => {
  const columns: Array<EuiBasicTableColumn<any>> = [
    {
      field: 'filterByGrade',
      name: 'Filter by Grade',

      render: (grade: Grade) => <GradeBadge grade={grade} filterable />,
    },
  ];

  const hasGradedMetrics = gradedMetrics.includes(riskAttribute);
  const pctOfPropertiesLabel = hasGradedMetrics ? '% of Graded Properties' : '% of Properties';
  const pctOfTIVLabel = hasGradedMetrics ? '% of Graded TIV' : '% of TIV';

  if (riskAttribute === 'HAZARD_GRADE') {
    columns.push({
      field: 'hazardGrade',
      name: 'Hazard Grade',
    });
  }

  return [
    ...columns,
    {
      field: 'propertyCount',
      name: 'Property Count',
    },
    {
      field: 'pctOfProperties',
      name: pctOfPropertiesLabel,
      render: ({ value, options }) => (
        <>
          {renderPct(value)}
          <SingleBarChart {...(options as SingleBarCartProps)} />
        </>
      ),
    },
    {
      field: 'tiv',
      name: 'TIV',
    },
    {
      field: 'pctOfTIV',
      name: pctOfTIVLabel,
      render: ({ value, options }) => (
        <>
          {renderPct(value)}
          <SingleBarChart {...(options as SingleBarCartProps)} />
        </>
      ),
    },
  ];
});

export const getTotalRow = ({
  summaryMetrics,
  attribute,
  totalTIV,
  totalGradedTIV,
  totalPropertyCnt,
  totalGradedTIVPd,
  totalGradedPropertyCnt,
}: PropertyGradeDetail) => {
  const hasGradedMetrics = gradedMetrics.includes(attribute);
  const isPredictive = attribute === 'PREDICTIVE_RISK_GRADE';
  const pctOfPropertiesKey = hasGradedMetrics ? 'pctOfGradedProperties' : 'pctOfProperties';
  const pctOfTIVKey = hasGradedMetrics ? 'pctOfGradedTIVPd' : 'pctOfTIV';
  const usedTotalPropertyCount = hasGradedMetrics ? totalGradedPropertyCnt : totalPropertyCnt;
  let usedTotalTIV = hasGradedMetrics ? totalGradedTIV : totalTIV;
  if (isPredictive) {
    usedTotalTIV = totalGradedTIVPd;
  }
  let totalPctOfProperties = 0;
  let totalPctOfTIV = 0;

  const totalPctPropertiesChartOptions = [];
  const totalPctTIVChartOptions = [];

  summaryMetrics.forEach((metric) => {
    const color = getColor(metric.grade.color);

    totalPctOfProperties += metric[pctOfPropertiesKey];
    totalPctOfTIV += metric[pctOfTIVKey];

    totalPctPropertiesChartOptions.push({
      color,
      value: metric[pctOfPropertiesKey],
    });

    totalPctTIVChartOptions.push({
      color,
      value: metric[pctOfTIVKey],
    });
  });

  return {
    filterByGrade: {
      category: 'Total',
      code: '',
      color: 'gray',
      value: null,
    },
    hazardGrade: '-',
    pctOfProperties: {
      options: {
        bars: totalPctPropertiesChartOptions.reverse(),
        withLegend: true,
      },
      value: Math.round(totalPctOfProperties / 100),
    },

    pctOfTIV: {
      options: {
        bars: totalPctTIVChartOptions.reverse(),
        withLegend: true,
      },
      value: Math.round(totalPctOfTIV / 100),
    },
    propertyCount: usedTotalPropertyCount,
    tiv: formatNumber(usedTotalTIV),
  };
};

export const getPropertyGradeData = (current: PropertyGradeDetail): OverallTableData => {
  const total = getTotalRow(current);

  const isPredictive = current?.attribute === 'PREDICTIVE_RISK_GRADE';
  // Use `pctOfGraded` keys for attributes matching the grade metrics.
  const hasGradedMetrics = Object.values(gradedMetrics).includes(current?.attribute);
  const propertyKey = hasGradedMetrics ? 'pctOfGradedProperties' : 'pctOfProperties';
  let tivKey = hasGradedMetrics ? 'pctOfGradedTIV' : 'pctOfTIV';
  if (isPredictive) {
    tivKey = 'pctOfGradedTIVPd';
  }

  // We want to avoid showing "Not Available" metrics in the breakdown for
  // RE Risk Grade and Predictive Risk Grade only. Skip filtering any CAT.
  const metricsWithData = current.summaryMetrics.filter((metric) => {
    if (current.attribute.includes('CAT')) {
      return true;
    }

    return metric.grade.category !== 'Not Available';
  });

  return {
    columns: getColumns(current.attribute),
    items: metricsWithData.map((metric) => ({
      filterByGrade: metric.grade,
      hazardGrade: metric.grade.code,
      pctOfProperties: {
        options: {
          bars: [
            {
              opacity: 0,
              value: total.pctOfProperties.value * 100 - metric[propertyKey],
            },
            {
              color: getColor(metric.grade.color),
              value: metric[propertyKey],
            },
          ],
          withLegend: false,
        },
        value: metric[propertyKey] / 100,
      },
      pctOfTIV: {
        options: {
          bars: [
            {
              opacity: 0,
              value: total.pctOfTIV.value * 100 - metric[tivKey],
            },
            {
              color: getColor(metric.grade.color),
              value: metric[tivKey],
            },
          ],

          withLegend: false,
        },
        value: metric[tivKey] / 100,
      },
      propertyCount: metric.propertyCnt,
      tiv: formatNumber(isPredictive ? metric.tivPd : metric.tiv),
    })),
    overallGrade: current.overallGrade,
    totalRow: total,
  };
};
