import React, { FC, useContext, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IJob, IProject } from '@onarchipelago/dice/EnrichmentProjects/types';
import { renderAttributeLabel } from '@onarchipelago/dice/MyJobs/MyJobs';
import {
  Button,
  ButtonEmpty,
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiText,
  EuiTextArea,
  EuiTitle,
  TextField,
  useToast,
} from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { GET_JOBS } from '@app/queries/organizations/getJobs';
import { GET_PROJECT } from '@app/queries/organizations/getProject';
import { TAKE_SNAPSHOT } from '@app/queries/organizations/takeSnapshot';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useJobsApolloClient } from '../JobsApolloClient';
import { generateSnapshotName, getCurrentProjectJobs } from './utils';

interface IProps {
  jobs?: IJob[];
  metadataLabels?: any;
  orgName: string;
  project?: IProject;
  refetchJobs: () => {};
  refetchProject?: () => {};
  setModal?: () => {};
}

interface ISnapshotForm {
  name: string;
  description: string;
  updateReportingViews: boolean;
}

const TakeSnapshotModal: FC<IProps> = ({
  jobs = [],
  metadataLabels = [],
  orgName,
  project = {},
  refetchJobs,
  refetchProject = () => {},
  setModal,
}) => {
  const defaultSnapshotForm: ISnapshotForm = {
    description: '',
    // FIX ME
    // @ts-ignore
    name: generateSnapshotName(project.snapshots),
    updateReportingViews: true,
  };

  const { closeModal } = useContext(ModalContext);
  const [values, setValues] = useState<ISnapshotForm>(defaultSnapshotForm);
  const toast = useToast();

  const closeHandler = setModal ? setModal : closeModal;
  const [takeSnapshot, { loading }] = useMutation(TAKE_SNAPSHOT, {
    onCompleted: () => {
      refetchJobs();
      refetchProject();
      closeHandler();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const onFormChange = (field: string) => (event: any) => {
    setValues({ ...values, [field]: event.target.value });
  };

  const takeSnapshotHandler = () => {
    takeSnapshot({
      variables: {
        input: {
          orgName,
          // FIX ME
          // @ts-ignore
          projectName: project.name,
          ...values,
        },
      },
    });
  };
  // FIX ME
  // @ts-ignore
  const currentProjectJobs = getCurrentProjectJobs(project, jobs);
  return (
    <EuiModal data-testid="take-snapshot-modal" onClose={closeHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          Take snapshot
          <EuiText size="xs">
            <h3>Taking a snapshot will reflect only completed jobs within this project.</h3>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFormRow fullWidth label="Name">
            <TextField
              data-testid="snapshot-name-input"
              value={values.name}
              onChange={onFormChange('name')}
              fullWidth
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Description (limited to 150 characters or less) (Optional)">
            <EuiTextArea
              value={values.description}
              onChange={onFormChange('description')}
              fullWidth
            />
          </EuiFormRow>
        </EuiForm>
        <br />
        {currentProjectJobs.length > 0 && (
          <EuiFlexItem grow={false}>
            <EuiPanel paddingSize="s">
              <EuiFlexGroup gutterSize="xs" alignItems="flexEnd">
                <EuiFlexItem>
                  <EuiTitle size="xxs">
                    <h4>Job name</h4>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiTitle size="xxs">
                    <h4>Attributes</h4>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiTitle size="xxs">
                    <h4>Completion date</h4>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
              {currentProjectJobs.map((job: IJob) => (
                <EuiFlexGroup gutterSize="xs" alignItems="flexEnd" key={job.name}>
                  <EuiFlexItem>
                    <EuiTitle size="xxs">
                      <h4>{job.name}</h4>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="xxs">
                      <h4>{renderAttributeLabel(job.attributeNames, metadataLabels)}</h4>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="xxs">
                      <h4>n/a</h4>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ))}
            </EuiPanel>
          </EuiFlexItem>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <ButtonEmpty onClick={closeHandler} label="Cancel" />
        <Button
          data-testid="confirm-create-snapshot-button"
          onClick={takeSnapshotHandler}
          disabled={loading || !values.name}
          loading={loading}
          fill
          label="Confirm"
        />
      </EuiModalFooter>
    </EuiModal>
  );
};

export default TakeSnapshotModal;
