import gql from 'graphql-tag';

export const GET_SUPPORT_TICKET = gql`
  query GetSupportTicket($input: SupportTicketInput!) {
    supportTicket(input: $input) {
      ticket {
        title
        status
        priority
        authorEmail
        assigneeEmails
        followerEmails
        createdAt
        updatedAt

        chatRoomID
        chatRoomName

        org {
          id
          name
        }
      }
    }
  }
`;
