import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { useIntegrationRunProvider } from '@app/platform/Integrations/IntegrationRunProvider';
import { StreamType } from '../contexts/reportsContext/types';
import { GET_STREAM_PROPERTY_VALUATIONS } from '../queries/getStreamPropertyValuations';
import { GET_STREAM_VALUATION_SUMMARY } from '../queries/getStreamValuationSummary';
import { GET_VALUATION_SUMMARY_FILTER_VALUES } from '../queries/getValuationSummaryFilterValues';
import { extractFilterOptions, formatSelectedFilters } from './utils/filterUtils';
import { getSortValue, makeBRCComparison, makeBRCSummary } from './utils/valuationsSummaryUtils';
import { DEFAULT_FILTER_VALUE } from './constants';
import {
  BRCComparison,
  BRCSummary,
  FilterOptionsType,
  PropertyValuationSortAttribute,
  TableSortOptionsType,
} from './types';

export interface IValuationSummaryReportContext {
  loading: boolean;
  error?: ApolloError;
  brcSummary?: BRCSummary;
  brcComparison?: BRCComparison;
  tableData?: any;
  summaryData?: any;
  stream: StreamType;
  streamSlug: string;
  filterOptions: FilterOptionsType;
  tableSort: TableSortOptionsType | PropertyValuationSortAttribute;
  constructionFilter: string;
  occupancyFilter: string;
  stateFilter: string;
  setConstructionFilter: (value: string) => void;
  setOccupancyFilter: (value: string) => void;
  setStateFilter: (value: string) => void;
  setTableSortValue: (value: TableSortOptionsType) => void;
  propertyValTableIsLoading: boolean;
}

export const ValuationSummaryReportContext = createContext({} as IValuationSummaryReportContext);

export const ValuationSummaryReportProvider = ({ children }: { children: ReactNode }) => {
  const { stream } = useReportsContext();
  const { loading: integrationRunLoading } = useIntegrationRunProvider();

  const { streamSlug } = stream;

  const [constructionFilter, setConstructionFilterValue] = useState<string>(DEFAULT_FILTER_VALUE);
  const [occupancyFilter, setOccupancyFilterValue] = useState<string>(DEFAULT_FILTER_VALUE);
  const [stateFilter, setStateFilterValue] = useState<string>(DEFAULT_FILTER_VALUE);
  const [tableSort, setTableSort] = useState<TableSortOptionsType | PropertyValuationSortAttribute>(
    PropertyValuationSortAttribute.BRCPercentOfTotal,
  );
  const setConstructionFilter = (value: string) => setConstructionFilterValue(value);
  const setOccupancyFilter = (value: string) => setOccupancyFilterValue(value);
  const setStateFilter = (value: string) => setStateFilterValue(value);
  const setTableSortValue = (value: TableSortOptionsType) => setTableSort(value);

  const filter = formatSelectedFilters(occupancyFilter, constructionFilter, stateFilter);

  const {
    data: filterOptionsData,
    error: filterOptionsError,
    loading: filterOptionsIsLoading,
  } = useQuery(GET_VALUATION_SUMMARY_FILTER_VALUES, {
    variables: {
      input: {
        streamSlug,
      },
    },
  });

  const {
    data: propertyValTableData,
    error: propertyValTableError,
    loading: propertyValTableIsLoading,
  } = useQuery(GET_STREAM_PROPERTY_VALUATIONS, {
    variables: {
      input: {
        filter,
        limit: 10,
        sortBy: [
          {
            attribute: getSortValue(tableSort),
            order:
              tableSort === TableSortOptionsType.BRCDiffLowHigh ||
              tableSort === TableSortOptionsType.BRCCurrencyDiffLowHigh
                ? 'ASCENDING'
                : 'DESCENDING',
          },
        ],
        streamSlug,
      },
    },
  });

  const {
    data: valSummaryData,
    error: valSummaryError,
    loading: valSummaryIsLoading,
  } = useQuery(GET_STREAM_VALUATION_SUMMARY, {
    variables: {
      input: {
        filter,
        streamSlug,
      },
    },
  });

  const filterOptions = filterOptionsData
    ? extractFilterOptions(filterOptionsData?.valuationSummaryFilterValues?.attributeValues || [])
    : ({} as FilterOptionsType);

  const valuationsSummaryData = valSummaryData?.streamValuationSummary;

  const brcComparison = makeBRCComparison(
    valuationsSummaryData?.avgReplacementCostPerFloorArea,
    valuationsSummaryData?.avgReplacementCostPerFloorAreaPartner,
  );

  const brcSummary = makeBRCSummary(
    valuationsSummaryData?.brcPercentDiff,
    valuationsSummaryData?.brcValueDiff,
  );

  return (
    <ValuationSummaryReportContext.Provider
      value={{
        brcComparison,
        brcSummary,
        constructionFilter,
        error: propertyValTableError || valSummaryError || filterOptionsError,
        filterOptions,
        loading: valSummaryIsLoading || filterOptionsIsLoading || integrationRunLoading,
        occupancyFilter,
        propertyValTableIsLoading,
        setConstructionFilter,
        setOccupancyFilter,
        setStateFilter,
        tableData: propertyValTableData,
        setTableSortValue,
        summaryData: valuationsSummaryData,
        stateFilter,
        tableSort,
        stream,
        streamSlug,
      }}
    >
      {children}
    </ValuationSummaryReportContext.Provider>
  );
};

// ----- USE CONTEXT HOOK ----- //

export const useValuationSummaryReportContext = (): IValuationSummaryReportContext =>
  useContext(ValuationSummaryReportContext);
