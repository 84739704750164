import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiToolTip, Icon } from 'ui';
import { MissingAttributesContainer } from './MissingAttributes.emotion';
import { Assignment } from '../V2/Assignment';
import { UploadDocuments } from '../V2/UploadDocuments';
import { getAttributeRow } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { getDescription } from '@app/platform/Inbox/utils/utils';
import { AttributeRowLayoutEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';

export const MissingAttributes = ({ task, disabled }) => {
  return (
    <MissingAttributesContainer direction="column">
      {disabled && <Assignment type="assignToMe" tasks={[task]} />}
      <UploadDocuments
        disabled={disabled}
        recommendedDocs={task.recommendedDocuments}
        propertyID={task.propertyId}
      />
      <EuiForm>
        <EuiFlexGroup direction="column">
          {task.attributes.map((attribute) => {
            const orgAttributeMetadata = attribute.attributeMetadata;
            if (!orgAttributeMetadata) {
              return null;
            }

            return getAttributeRow(
              //for some reason pre check api has two different fields then what this wants
              // but those fields are not used in the function
              orgAttributeMetadata as any,
              undefined,
              undefined,
              // FIX ME
              // @ts-ignore
              {
                labelAction: disabled ? (
                  <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <EuiToolTip
                        content={getDescription(attribute.name)}
                        display="block"
                        position="top"
                      >
                        <Icon color="text" name="help" />
                      </EuiToolTip>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiToolTip
                        content="Only the assignee can edit these fields"
                        display="block"
                        position="top"
                      >
                        <Icon name="slash" />
                      </EuiToolTip>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  <EuiToolTip
                    content={getDescription(attribute.name)}
                    display="block"
                    position="top"
                  >
                    <Icon color="text" name="help" />
                  </EuiToolTip>
                ),
                layout: AttributeRowLayoutEnum.vertical,
                readOnly: disabled,
              },
            );
          })}
        </EuiFlexGroup>
      </EuiForm>
    </MissingAttributesContainer>
  );
};
