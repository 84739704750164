import React, { ComponentType, FC } from 'react';
import AutoSizer, { AutoSizerProps } from 'react-virtualized-auto-sizer';

const style = {
  flex: 1,
  minHeight: 1,
};

export interface MeasureProps {
  height: number;
  width: number;
}

interface IMeasureComponentProps {
  children: AutoSizerProps['children'];
}

export const Measure: FC<IMeasureComponentProps> = ({ children }) => (
  <div
    style={{
      ...style,
      minHeight: 1,
    }}
  >
    <AutoSizer>{children}</AutoSizer>
  </div>
);

const measure =
  <P extends object>(Component: ComponentType<P>) =>
  (props: any) =>
    (
      <Measure>
        {({ width, height }) => <Component {...props} height={height} width={width} />}
      </Measure>
    );

export default measure;
