import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { SettingsDisplay, SettingsState } from './types';

interface Props {
  display: SettingsDisplay[];
  derivedFields?: string[];
  loading: boolean;
  settingsState: SettingsState;
  setSettingsState: Dispatch<SetStateAction<SettingsState>>;
}

const SubmissionSettingsBody: React.FC<Props> = ({
  display,
  loading,
  settingsState,
  setSettingsState,
  derivedFields = [],
}) => {
  const disabledCheckboxes = new Set(['name', 'effectiveDate']);

  const requiredGroups = new Set([
    'Stakeholders',
    'Basic Information',
    `${STREAMS_LABEL} dates`,
    null,
  ]);
  const handleEnabledCheck = (id: string, checked: boolean, group?: string) => {
    setSettingsState((prevState) => ({
      ...prevState,
      [id]: {
        enabled: checked,
        required: group === 'Admin settings' ? prevState?.[id]?.required : undefined,
      },
    }));
  };

  const handleRequiredCheck = (id: string, checked: boolean) => {
    setSettingsState((prevState) => ({
      ...prevState,
      [id]: { enabled: true, required: checked },
    }));
  };

  if (loading) {
    return <LoadingSpinnerV2 dataTestId="submission-settings-modal-body-spinner" />;
  }

  return (
    <EuiFlexGroup
      data-testid="submission-settings-modal-body-content"
      direction="column"
      gutterSize="s"
    >
      {display.map((displayGroup) => (
        <>
          <EuiFlexItem>
            <EuiText>{displayGroup.label || 'General'}</EuiText>
          </EuiFlexItem>
          {displayGroup.settings.map((setting) => (
            <>
              <EuiFlexItem key={`${setting.id}-enabled`}>
                <Checkbox
                  checked={settingsState[setting.id]?.enabled}
                  id={`${setting.id}-enabled`}
                  label={setting.label}
                  onChange={(event) =>
                    handleEnabledCheck(setting.id, event.target.checked, displayGroup.label)
                  }
                  data-testid={`checkbox-enable-${setting.label}`}
                  disabled={disabledCheckboxes.has(setting.id)}
                />
              </EuiFlexItem>
              {requiredGroups.has(displayGroup.label) &&
                !disabledCheckboxes.has(setting.id) &&
                !derivedFields.includes(setting.id) && (
                  <EuiFlexItem key={`${setting.id}-required`} style={{ marginLeft: '16px' }}>
                    <Checkbox
                      checked={settingsState[setting.id]?.required}
                      id={`${setting.id}-required`}
                      label="Required"
                      onChange={(event) => handleRequiredCheck(setting.id, event.target.checked)}
                      disabled={!settingsState[setting.id]?.enabled}
                      data-testid={`checkbox-required-${setting.label}`}
                    />
                  </EuiFlexItem>
                )}
            </>
          ))}
          <Spacer />
        </>
      ))}
    </EuiFlexGroup>
  );
};

export default SubmissionSettingsBody;
