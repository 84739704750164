"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rejectStyle = exports.acceptStyle = exports.focusedStyle = exports.baseStyle = exports.fileListStyle = exports.fileLabelStyle = exports.iconContainerStyle = exports.promptContainerStyle = exports.promptStyle = void 0;
var themes_1 = require("../../../themes");
exports.promptStyle = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
};
exports.promptContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};
exports.iconContainerStyle = {
    marginBottom: '16px',
};
exports.fileLabelStyle = {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'center',
};
exports.fileListStyle = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
};
exports.baseStyle = {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 0,
    borderBottomColor: themes_1.colors.eui.forms.borderOpaqueColor,
    borderTopColor: themes_1.colors.eui.forms.borderOpaqueColor,
    borderRightColor: themes_1.colors.eui.forms.borderOpaqueColor,
    borderLeftColor: themes_1.colors.eui.forms.borderOpaqueColor,
    borderStyle: 'solid',
    backgroundColor: themes_1.colors.eui.forms.backgroundColor,
    color: themes_1.colors.eui.darkestShade,
    outline: 'none',
};
exports.focusedStyle = {
    borderBottomColor: themes_1.colors.eui.primary,
    borderBottomWidth: 3,
    backgroundColor: themes_1.colors.eui.forms.backgroundFocus,
};
exports.acceptStyle = {
    borderBottomColor: themes_1.colors.eui.success,
    borderBottomWidth: 3,
    backgroundColor: themes_1.colors.eui.forms.backgroundFocus,
};
exports.rejectStyle = {
    borderBottomColor: themes_1.colors.eui.danger,
    borderBottomWidth: 3,
    backgroundColor: themes_1.colors.eui.forms.backgroundFocus,
};
