import React, { FC, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, Select, Spacer } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import LineChart from './Charts/LineChart';
import { LossYAxisTypes } from './Charts/lineChartTypes';
import { Subdued } from './LossesTab.emotion';
import NoData from './NoData';
import { filterLosses, findLossChart } from './utils';

interface Props {
  data?: RiskSummaryChartData[];
}
const LossesTab: FC<Props> = ({ data }) => {
  if (!data?.[0] || data[0].values.length === 0) {
    return <NoData message="This stream doesn't have any loss data yet." />;
  }
  const [type, setType] = useState<string>('LOSS_ATTRITIONAL');
  const [includeDisposed, setIncludeDisposed] = useState<string>('PROPERTY_STATUS_ANY');

  const filteredCharts = filterLosses(data, type, includeDisposed);

  return (
    <>
      {data.length > 0 && (
        <>
          <EuiFlexGroup direction="row" justifyContent="flexStart">
            <EuiFlexItem style={{ maxHeight: '100px', maxWidth: '200px' }}>
              <Select
                options={[
                  { label: 'Attritional', value: 'LOSS_ATTRITIONAL' },
                  { label: 'Catastrophe', value: 'LOSS_CATASTROPHE' },
                ]}
                value={type}
                onChange={(value) => setType(value)}
              />
            </EuiFlexItem>
            <EuiFlexItem style={{ maxHeight: '100px', maxWidth: '320px' }}>
              <Select
                options={[
                  { label: 'Include Disposed Properties', value: 'PROPERTY_STATUS_ANY' },
                  { label: 'Exclude Disposed Properties', value: 'PROPERTY_STATUS_ONGOING_OR_NEW' },
                ]}
                value={includeDisposed}
                onChange={(value) => setIncludeDisposed(value)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <Spacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <Subdued>
                <p>
                  The charts below include only losses during the property membership in the{' '}
                  {STREAMS_LABEL}.
                </p>
              </Subdued>
            </EuiFlexItem>
          </EuiFlexGroup>
          <Spacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <LineChart
                yAxisLabel="Loss"
                lineData={findLossChart(filteredCharts, 'LOSS_SEVERITY_OVER_TIME') as any}
                title="Loss Severity"
                subTitle="Gross total loss per year"
                metric="Gross Loss"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <LineChart
                yAxisType={LossYAxisTypes.COUNT}
                yAxisLabel="Count"
                lineData={findLossChart(filteredCharts, 'LOSS_FREQUENCY_OVER_TIME') as any}
                title="Loss Frequency"
                subTitle="Count of property losses per year"
                metric="Loss Count"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <LineChart
                yAxisType={LossYAxisTypes.UNITS}
                yAxisLabel="Loss Cost"
                lineData={findLossChart(filteredCharts, 'LOSS_COST_OVER_TIME') as any}
                title="Loss Cost"
                subTitle="Annual loss per $1K TIV"
                metric="Loss Cost"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </>
  );
};

export default LossesTab;
