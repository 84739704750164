import React, { FC } from 'react';
import { CallOut, EuiFormRow, EuiText, Spacer } from 'ui';
import { DataError } from '@app/dice/EnrichmentProjects/types';

interface Props {
  warnings: DataError[];
}

const DuplicatePropertyIDsCallout: FC<Props> = ({ warnings }) => {
  const renderWarningContent = () => {
    //if there are more than the specified number of properties, show the cutoff number of IDs and then indicate the # of remaining ids
    const cutoff = 9;
    if (warnings.length > cutoff) {
      const displayIDs = warnings.slice(0, cutoff);
      return (
        <EuiText style={{ fontSize: '12px' }}>
          {displayIDs.map((w, index) => `${w.value}${index !== cutoff - 1 ? ',' : ''} `)} and{' '}
          {warnings.length - cutoff} more
        </EuiText>
      );
    }
    return (
      <EuiText style={{ fontSize: '12px' }}>
        {warnings.map(
          (w, index) =>
            `${index === warnings.length - 1 ? 'and ' : ''}${w.value}${
              index !== warnings.length - 1 ? ',' : ''
            } `,
        )}
      </EuiText>
    );
  };
  return (
    <EuiFormRow>
      <CallOut
        data-testid="edit-data-warnings-duplicate-properties"
        type="warning"
        title={`We found ${warnings?.length} duplicate client IDs`}
      >
        <>
          <Spacer />
          <EuiText>
            {warnings[0].message}
            <br />
            {renderWarningContent()}
          </EuiText>
        </>
      </CallOut>
    </EuiFormRow>
  );
};

export default DuplicatePropertyIDsCallout;
