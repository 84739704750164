import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { IMarker } from './types';

const Container = styled.div`
  label: MarkerWrapper;
`;

interface Props<T> {
  children: ReactElement;
  marker: IMarker<T>;
  lat: number;
  lng: number;
  testId: string;
}

const MarkerWrapper = <T extends any>({ children, testId }: Props<T>): ReactElement => (
  <Container data-testid={testId}>{children}</Container>
);

export default MarkerWrapper;
