import gql from 'graphql-tag';

export const GET_JOBS = gql`
  query GetJobs($jobsFilter: JobsFilter, $pageCursor: String, $pageSize: Int) {
    jobsV2(filters: $jobsFilter, pageCursor: $pageCursor, pageSize: $pageSize) {
      jobs {
        id
        name
        createdAt
        description
        type
        attributeNames
        priority
        sovDocId
        version
        doerProfile {
          userID
          email
          givenName
          familyName
          pictureURL
        }
        reviewerProfile {
          userID
          email
          givenName
          familyName
          pictureURL
        }
        status
        lifeCycle {
          from
          to
          startTime
          endTime
          comment
          progress
          worksheet {
            filename
            extension
            httpURL
          }
          errors {
            value
            message
            location
            columnHeader
          }
        }
        projectName
        orgName
        dueOn
        references {
          id
          filename
          httpURL
          thumbnailURLs {
            small
          }
        }
      }
      pageInfo {
        cursor
        totalCount
      }
    }
  }
`;
