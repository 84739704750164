import React from 'react';
import HorizontalBarChart from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/HorizontalBarChart';
import { BarsData } from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/types';
import { EuiFlexGroup, EuiFlexItem } from 'ui';

interface Props {
  charts: {
    data: BarsData;
    title: string;
  }[];
}

const CoreAttributesBeforeAfterRow: React.FC<Props> = ({ charts }) => (
  <EuiFlexGroup>
    {charts.map((chart, index) => (
      <EuiFlexItem key={`${chart.title}-${index}`}>
        <HorizontalBarChart bars={chart.data} showKey={false} title={chart.title} />
      </EuiFlexItem>
    ))}
  </EuiFlexGroup>
);

export default CoreAttributesBeforeAfterRow;
