"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelect = void 0;
var react_1 = __importStar(require("react"));
var eui_1 = require("@elastic/eui");
var CustomSelect = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.compressed, compressed = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, initialSelected = _a.initialSelected, _e = _a.invalid, invalid = _e === void 0 ? false : _e, _f = _a.isClearable, isClearable = _f === void 0 ? true : _f, onBlur = _a.onBlur, onChange = _a.onChange, onCreateOption = _a.onCreateOption, options = _a.options, placeholder = _a.placeholder, _g = _a.async, async = _g === void 0 ? false : _g, onSearchChange = _a.onSearchChange, _h = _a.isLoading, isLoading = _h === void 0 ? false : _h;
    var _j = __read((0, react_1.useState)(initialSelected || []), 2), selectedOptions = _j[0], setSelectedOptions = _j[1];
    var handleChange = function (chosenOptions) {
        setSelectedOptions(chosenOptions);
        if (onChange) {
            onChange(chosenOptions);
        }
    };
    var handleCreateOption = function (value, options) {
        var createdOption = onCreateOption(value, options);
        if (createdOption !== false) {
            setSelectedOptions([{ label: value, value: value }]);
        }
    };
    return (react_1.default.createElement(eui_1.EuiComboBox, { "data-testid": dataTestId || 'ui-atom-input-custom-select', fullWidth: fullWidth, isClearable: isClearable, isDisabled: disabled, isInvalid: invalid, onBlur: onBlur, onChange: handleChange, onCreateOption: onCreateOption ? handleCreateOption : undefined, options: options, placeholder: placeholder, selectedOptions: selectedOptions, singleSelection: { asPlainText: true }, async: async, isLoading: isLoading, onSearchChange: onSearchChange, compressed: compressed }));
};
exports.CustomSelect = CustomSelect;
