import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StreamSearchQueryVariables = Types.Exact<{
  input: Types.StreamPropertiesPageInput;
}>;


export type StreamSearchQuery = { __typename?: 'Query', streamPropertiesPage: { __typename?: 'StreamPropertiesPageResponse', properties?: Array<{ __typename?: 'Property', id: string, locationId: string, locationName?: string | null, streetAddress?: string | null, postalCode?: string | null, archipelagoId: string }> | null } };


export const StreamSearchDocument = gql`
    query StreamSearch($input: StreamPropertiesPageInput!) {
  streamPropertiesPage(input: $input) {
    properties {
      id
      locationId
      locationName
      streetAddress
      postalCode
      archipelagoId
    }
  }
}
    `;

/**
 * __useStreamSearchQuery__
 *
 * To run a query within a React component, call `useStreamSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStreamSearchQuery(baseOptions: Apollo.QueryHookOptions<StreamSearchQuery, StreamSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreamSearchQuery, StreamSearchQueryVariables>(StreamSearchDocument, options);
      }
export function useStreamSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreamSearchQuery, StreamSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreamSearchQuery, StreamSearchQueryVariables>(StreamSearchDocument, options);
        }
export type StreamSearchQueryHookResult = ReturnType<typeof useStreamSearchQuery>;
export type StreamSearchLazyQueryHookResult = ReturnType<typeof useStreamSearchLazyQuery>;
export type StreamSearchQueryResult = Apollo.QueryResult<StreamSearchQuery, StreamSearchQueryVariables>;