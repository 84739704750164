import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportPropertyRecommendationsMutationVariables = Types.Exact<{
  input: Types.ImportPropertyRecommendationsInput;
}>;


export type ImportPropertyRecommendationsMutation = { __typename?: 'Mutation', importPropertyRecommendations: { __typename?: 'ImportPropertyRecommendationsResponse', mlRecommendationsStatus: Types.PropertyRecommendationsStatus } };


export const ImportPropertyRecommendationsDocument = gql`
    mutation ImportPropertyRecommendations($input: ImportPropertyRecommendationsInput!) {
  importPropertyRecommendations(input: $input) {
    mlRecommendationsStatus
  }
}
    `;
export type ImportPropertyRecommendationsMutationFn = Apollo.MutationFunction<ImportPropertyRecommendationsMutation, ImportPropertyRecommendationsMutationVariables>;

/**
 * __useImportPropertyRecommendationsMutation__
 *
 * To run a mutation, you first call `useImportPropertyRecommendationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPropertyRecommendationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPropertyRecommendationsMutation, { data, loading, error }] = useImportPropertyRecommendationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPropertyRecommendationsMutation(baseOptions?: Apollo.MutationHookOptions<ImportPropertyRecommendationsMutation, ImportPropertyRecommendationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPropertyRecommendationsMutation, ImportPropertyRecommendationsMutationVariables>(ImportPropertyRecommendationsDocument, options);
      }
export type ImportPropertyRecommendationsMutationHookResult = ReturnType<typeof useImportPropertyRecommendationsMutation>;
export type ImportPropertyRecommendationsMutationResult = Apollo.MutationResult<ImportPropertyRecommendationsMutation>;
export type ImportPropertyRecommendationsMutationOptions = Apollo.BaseMutationOptions<ImportPropertyRecommendationsMutation, ImportPropertyRecommendationsMutationVariables>;