import styled from '@emotion/styled';

export const GridHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  top: 0;
  left: 0;
  position: sticky;
  z-index: 1;
`;

export const GridHeaderThContainer = styled.div`
  transform: none;
  position: relative;
  height: 40px;
  transition: all 0.2s ease;

  span {
    overflow: hidden;
    width: 100%;
  }

  &.sort {
    color: #2f80ed;

    span {
      color: inherit;
    }
  }
`;

export const GridHeaderThInner = styled.div`
  overflow: hidden;
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  align-items: flex-end;
`;
