import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
import { RecommendationFragmentDoc } from './RecommendationFragment.generated';
export type TaskFragment = { __typename?: 'Task', id: string, status?: Types.TaskStatus | null, assignees?: Array<{ __typename?: 'TaskAssignee', assignedDate?: any | null, assignee?: string | null, userInfo?: { __typename?: 'UserProfile', email: string, givenName?: string | null, familyName?: string | null } | null } | null> | null, propertyRecommendation: { __typename?: 'Recommendation', archipelagoID: string, bucket?: number | null, attribute?: Array<{ __typename?: 'Attribute', name: string, recommendationType: Types.RecommendationType, documents?: Array<string | null> | null, reasons?: Array<string | null> | null, attributeMetadata?: { __typename?: 'AttributeMetadata', columnPosition: number, dataType: string, derived: boolean, displayName: string, displayWidth: number, filterable: boolean, groupable: boolean, hiddenInGrid: boolean, name: string, enumMetadata?: Array<{ __typename?: 'EnumValueMetadata', valueID: string, value: string, valueDisplay?: string | null, position: number, clusterValue?: string | null, hexColorCode?: string | null, externalMappings?: Array<{ __typename?: 'ExternalMapping', externalCoding: Types.ExternalCoding, valueID: string, value: string }> | null, softMappings?: Array<{ __typename?: 'SoftMapping', modelSchema: Types.SoftMappingModelSchema, secondaryModifier: string, value?: string | null } | null> | null }> | null } | null }> | null }, property: { __typename?: 'Property', city?: string | null, id: string, locationName?: string | null, postalCode?: string | null, state?: string | null, streetAddress?: string | null, totalInsuredValueDisplay?: number | null, buildingValue?: number | null, improvementsValue?: number | null, miscBuildingReplacementCostValue?: number | null, buildingReplacementCost?: number | null, floorArea?: number | null, replacementCostPerSquareFootage?: number | null, documents?: Array<{ __typename?: 'Document', filename: string, classification?: string | null, httpURL: string, createdAt: any }> | null } };

export const TaskFragmentDoc = gql`
    fragment task on Task {
  id
  status
  assignees {
    assignedDate
    assignee
    userInfo {
      email
      givenName
      familyName
    }
  }
  propertyRecommendation {
    ...recommendation
  }
  property {
    city
    id
    locationName
    postalCode
    state
    streetAddress
    totalInsuredValueDisplay
    documents {
      filename
      classification
      httpURL
      createdAt
    }
    buildingValue
    improvementsValue
    miscBuildingReplacementCostValue
    buildingReplacementCost
    floorArea
    replacementCostPerSquareFootage
  }
}
    ${RecommendationFragmentDoc}`;