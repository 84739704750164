import {
  IGroupedProps,
  IPropertiesProps,
  IPropertyGroupRow,
} from '@app/components/PropertiesGroupedList/types';
import { IGraphQLStream } from '@app/queries/streams/types';

type GetStringIndices = (
  indices: Array<number>,
  groups: Array<string>,
  groupedProperties?: Array<IPropertyGroupRow<IGroupedProps | IPropertiesProps>>,
) => Array<string>;

export const getStringIndices: GetStringIndices = (indices, groups, groupedProperties = []) => {
  let stringIndices: Array<string> = [];
  if (groupedProperties.length > 0 && indices.length > 1) {
    const groupingField = groups[indices[1]];
    const groupedProperty = (groupedProperties[indices[0]].props as IGroupedProps).label;
    stringIndices = [groupingField, groupedProperty];
    return stringIndices;
  }

  return stringIndices;
};

export const pad = <T>(properties: Array<T>, count: number): Array<T | undefined> => {
  const remainder = count - properties.length;
  const length = remainder > 0 ? remainder : 0;

  const arr = [...properties, ...Array(length).fill(undefined)];
  return arr;
};

export const getPropertyCount = (stream: IGraphQLStream, group?: IGroupedProps): number => {
  if (group?.filteredPropertyCount) {
    return group.filteredPropertyCount;
  }

  if (stream.propertiesCount) {
    return stream.propertiesCount;
  }
  return 0;
};
