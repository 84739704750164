import { IAccountState } from '@app/containers/AuthProvider/types';
import { IGraphQLStream } from '@app/queries/streams/types';

export const shouldRedirectToUpload = (account: IAccountState, stream: IGraphQLStream) => {
  const orgs = account?.managedOrgs || [];
  const currentOrg = orgs.find((o) => o?.org?.id === stream?.organizationId);
  const hasUploadEnabled = currentOrg?.org?.enableUploadPortal;
  const admin = account?.permissions?.admin;
  const isPublicStream = stream?.isPublic === 1;

  return !admin && hasUploadEnabled && !isPublicStream;
};
