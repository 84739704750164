import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ButtonEmpty,EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText , useEuiTheme } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { useInboxContext } from '@app/platform/Inbox/context/InboxContext';
import { useWorkflowContext } from '@app/platform/Workflow/context/WorkflowContext';
import { useIsVisible } from '@app/precheck/hooks/useIsVisible';
import { usePrecheckContext } from '../../context/PrecheckContext';
import { BucketNameContainer } from './Bucket.emotion';
import { Properties } from './Properties';

export const BUCKET_MAPPING = [
  'Critical Priority',
  'High Priority',
  'Medium Priority',
  'Low Priority',
];

const BUCKET_CATEGORIES = ['critical', 'high', 'medium', 'low'];

const Circle = styled.span`
  flex-shrink: 0;
  display: inline-block;
  line-height: 24px;
  border-radius: 24px;
  inline-size: 24px;
  block-size: 24px;
  text-align: center;
  color: #ffffff;
  background-color: ${(props) =>
    // FIX ME
    // @ts-ignore
    props.backgroundColor};
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Bucket = ({ bucket, index }) => {
  const { euiTheme } = useEuiTheme();
  const { selectedOrganization } = useUserSession();
  const { precheckUser, recommendations, canEditAllProperties, stream } = usePrecheckContext();
  const { workflowStarted } = useWorkflowContext();
  const { inbox, loading: getInboxLoading } = useInboxContext();

  const mixpanel = useTracker('mixpanel');
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (index === 0 && isVisible) {
      mixpanel.track('Pre-Check: Recommendations Viewed', {
        event_surface: 'PreCheck',
        organization_id: selectedOrganization.id,
        precheck_user_type: precheckUser.type,
        stream_id: stream.id,
        stream_slug: stream.slug,
      });
    }
  });

  // user set property when they "dismiss" a bucket by clicking on the trash icon
  if (bucket.hidden) {
    return null;
  }

  // the API will not return a bucket if there are no properties in it, so we create this bucket
  if (bucket.properties.length === 0) {
    return (
      <EuiPanel grow={false} paddingSize="l">
        <EuiFlexGroup
          style={{ flexBasis: 'fit-content', flexWrap: 'nowrap' }}
          wrap={false}
          gutterSize="s"
        >
          <EuiFlexItem style={{ flexBasis: 'fit-content', flexWrap: 'nowrap' }} grow={false}>
            <BucketNameContainer
              ref={ref}
              style={{
                color: '#1a1c21',
                fontSize: '1.0000rem',
                fontWeight: 600,
                lineHeight: '1.5000rem',
              }}
            >
              {BUCKET_MAPPING[parseInt(bucket?.name) - 1]}
            </BucketNameContainer>
            <EuiSpacer size="s" />
            <EuiText>
              All {BUCKET_CATEGORIES[parseInt(bucket?.name) - 1]} recommendations have been
              completed.
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    );
  }

  if (bucket.properties.length === 0) {
    return null;
  }

  const recordExpansion = () => {
    mixpanel.track('Pre-Check: Expanded Recommendation', {
      event_surface: 'PreCheck',
      organization_id: selectedOrganization.id,
      precheck_user_type: precheckUser.type,
      properties: BUCKET_MAPPING[parseInt(bucket?.name) - 1],
      stream_id: stream.id,
      stream_slug: stream.slug,
    });
  };

  return (
    <EuiPanel grow={false} paddingSize="l">
      <EuiFlexGroup
        style={{ flexBasis: 'fit-content', flexWrap: 'nowrap' }}
        wrap={false}
        gutterSize="s"
      >
        <EuiFlexItem style={{ flexBasis: 'fit-content', flexWrap: 'nowrap' }} grow={false}>
          <Circle
            // FIX ME
            // @ts-ignore
            backgroundColor={euiTheme.colors.primary}
          >
            {bucket?.name}
          </Circle>
        </EuiFlexItem>
        <EuiFlexItem style={{ flexBasis: 'fit-content', flexWrap: 'nowrap' }} grow={false}>
          <BucketNameContainer ref={ref}>
            {BUCKET_MAPPING[parseInt(bucket?.name) - 1]}
          </BucketNameContainer>
          <EuiSpacer size="s" />
        </EuiFlexItem>
        {canEditAllProperties && !getInboxLoading && !(workflowStarted || inbox?.all.length > 0) && (
          <EuiFlexItem>
            <div style={{ alignSelf: 'flex-end' }}>
              <ButtonEmpty
                size="s"
                iconName="trash"
                label=""
                color="danger"
                onClick={() => {
                  recommendations.setDismissedBuckets((buckets) => [...buckets, bucket.name]);
                }}
              />
            </div>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <Properties properties={bucket.properties} recordExpansion={recordExpansion} />
    </EuiPanel>
  );
};
