import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetJobQueryVariables = Types.Exact<{
  jobID: Types.Scalars['ID']['input'];
}>;


export type GetJobQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, name: string, description?: string | null, type: Types.JobType, attributeNames?: Array<string> | null, propertyIDs?: Array<any> | null, dueOn?: any | null, sovDocId?: string | null, status: Types.JobStatus, projectName: string, orgName: string, doerProfile?: { __typename?: 'UserProfile', userID: string, email: string, givenName?: string | null, familyName?: string | null, pictureURL?: string | null } | null, reviewerProfile?: { __typename?: 'UserProfile', userID: string, email: string, givenName?: string | null, familyName?: string | null, pictureURL?: string | null } | null, lifeCycle?: Array<{ __typename?: 'StateTransition', id: string, from: Types.JobStatus, to: Types.JobStatus, startTime: any, endTime?: any | null, comment?: string | null, progress: Types.Status, worksheet?: { __typename?: 'Worksheet', id: string, filename: string, extension?: string | null, httpURL: string } | null, errors?: Array<{ __typename?: 'DataError', value: string, message: string, location?: string | null, columnHeader?: string | null }> | null, warnings?: Array<{ __typename?: 'DataError', type?: Types.DataErrorType | null, attributeName?: string | null, sheetName?: string | null, location?: string | null, columnHeader?: string | null, value: string, message: string }> | null, dataErrors?: Array<{ __typename?: 'DataError', type?: Types.DataErrorType | null, value: string, sheetName?: string | null, message: string, location?: string | null, columnHeader?: string | null }> | null, systemErrors?: Array<{ __typename?: 'SystemError', sheetName?: string | null, rowNumber?: number | null, archipelagoID?: string | null, message: string, referenceID?: string | null }> | null }> | null, references?: Array<{ __typename?: 'Document', id: string, filename: string, extension?: string | null, httpURL: string, thumbnailURLs?: { __typename?: 'ImageURLs', small: string } | null }> | null } | null };


export const GetJobDocument = gql`
    query GetJob($jobID: ID!) {
  job(jobID: $jobID) {
    id
    name
    description
    type
    attributeNames
    propertyIDs
    dueOn
    sovDocId
    doerProfile {
      userID
      email
      givenName
      familyName
      pictureURL
    }
    reviewerProfile {
      userID
      email
      givenName
      familyName
      pictureURL
    }
    status
    lifeCycle {
      id
      from
      to
      startTime
      endTime
      comment
      progress
      worksheet {
        id
        filename
        extension
        httpURL
      }
      errors {
        value
        message
        location
        columnHeader
      }
      warnings {
        type
        attributeName
        sheetName
        location
        columnHeader
        value
        message
      }
      dataErrors {
        type
        value
        sheetName
        message
        location
        columnHeader
      }
      systemErrors {
        sheetName
        rowNumber
        archipelagoID
        message
        referenceID
      }
    }
    projectName
    orgName
    dueOn
    references {
      id
      filename
      extension
      httpURL
      thumbnailURLs {
        small
      }
    }
  }
}
    `;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      jobID: // value for 'jobID'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;