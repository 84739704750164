import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StartWorkflowQueryVariables = Types.Exact<{
  input: Types.StartWorkflowInput;
}>;


export type StartWorkflowQuery = { __typename?: 'Query', startWorkflow: boolean };


export const StartWorkflowDocument = gql`
    query StartWorkflow($input: StartWorkflowInput!) {
  startWorkflow(input: $input)
}
    `;

/**
 * __useStartWorkflowQuery__
 *
 * To run a query within a React component, call `useStartWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartWorkflowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartWorkflowQuery(baseOptions: Apollo.QueryHookOptions<StartWorkflowQuery, StartWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StartWorkflowQuery, StartWorkflowQueryVariables>(StartWorkflowDocument, options);
      }
export function useStartWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartWorkflowQuery, StartWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StartWorkflowQuery, StartWorkflowQueryVariables>(StartWorkflowDocument, options);
        }
export type StartWorkflowQueryHookResult = ReturnType<typeof useStartWorkflowQuery>;
export type StartWorkflowLazyQueryHookResult = ReturnType<typeof useStartWorkflowLazyQuery>;
export type StartWorkflowQueryResult = Apollo.QueryResult<StartWorkflowQuery, StartWorkflowQueryVariables>;