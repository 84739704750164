import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EuiFormRow, EuiFormRowProps, SearchSelect, SearchSelectProps } from 'ui';
import {
  GoogleAutocompleteInput,
  IGoogleAutocompleteInput,
} from '../GoogleAutocomplete/GoogleAutocompleteInput';

export interface IFormGoogleAutocompleteInputProps {
  name: string;
  inputProps?: Omit<IGoogleAutocompleteInput, 'onChange' | 'onSelect'>;
  formRowProps?: EuiFormRowProps;
  onChange?: IGoogleAutocompleteInput['onChange'];
  onSelect?: IGoogleAutocompleteInput['onSelect'];
}

export const FormGoogleAutocompleteInput = ({
  name,
  onChange,
  onSelect,
  inputProps,
  formRowProps = {} as EuiFormRowProps,
}: IFormGoogleAutocompleteInputProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const inputError = errors?.[name];

  return (
    <EuiFormRow
      id="form-select-input"
      isInvalid={!!inputError}
      error={inputError?.message}
      {...formRowProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const controllerOnChange = field.onChange;
          const handleChange = (value: string) => {
            if (onChange) {
              onChange(value);
            }
            controllerOnChange(value);
          };
          return (
            <GoogleAutocompleteInput
              aria-labelledby="form-select-input"
              inputRef={field.ref}
              name={field.name}
              value={field.value}
              onBlur={field.onBlur}
              {...inputProps}
              onChange={handleChange}
              onSelect={onSelect}
            />
          );
        }}
      />
    </EuiFormRow>
  );
};
