import styled from '@emotion/styled';

export const RiskSummaryTab = styled.div`
  padding: 13.5px 24px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  ${({ active }: { active: boolean }) =>
    active
      ? `
        border-bottom: 2px solid #2D9CDB;
        p {
          color: #2D9CDB;
        }
      `
      : ''}
`;

export const RiskSummaryTitle = styled.h3`
  margin: 0px;
`;

export const RiskSummaryTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px -24px;
  border-bottom: 1px solid #e5e5e5;
`;
