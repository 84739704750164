import React from 'react';
import { CallOut, EuiText, Spacer } from 'ui';

interface Props {
  items: string;
}

const InProgressCallout: React.FC<Props> = ({ items }) => (
  <CallOut
    data-testid="edit-data-error-message-locked"
    title="Edits already in progress for some data"
    type="danger"
  >
    <EuiText>
      Some of the properties & attributes you’ve selected are currently being edited by someone
      else. Unselect the following data to proceed:
    </EuiText>
    <Spacer />
    <EuiText>{items}</EuiText>
  </CallOut>
);

export default InProgressCallout;
