import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { EuiModal } from 'ui';
import { object, string } from 'yup';
import { ModalContext } from '@app/contexts/ModalContext';
import RoleCheckerFormContent from './RoleCheckerFormContent';
import useGetRoleAssignments from './useGetRoleAssignments';

/* Regular expression to validate principal id input, it can either be:
  - Email address (ex: test@mail.com)
  - Client id (ex: testid@clients)
*/
const principalIdRegexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/s;

const RoleChecker: React.FC = () => {
  const { data, handleSubmit, isLoading, submittedValues } = useGetRoleAssignments();
  const { closeModal } = useContext(ModalContext);

  const validationSchema = object().shape({
    principalId: string().required().matches(principalIdRegexp),
  });

  return (
    <EuiModal onClose={closeModal}>
      <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form>
          <RoleCheckerFormContent
            data={data}
            isLoading={isLoading}
            handleClose={closeModal}
            submittedValues={submittedValues}
          />
        </Form>
      </Formik>
    </EuiModal>
  );
};

export default RoleChecker;
