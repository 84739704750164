import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { MapWrapper } from '../Components/Charts/MapWrapper';
import { ScoreQuality } from '../Components/ScoreQuality';
import { usePrecheckContext } from '../context/PrecheckContext';

export const OverviewPanel = () => {
  const { portfolio, recommendations } = usePrecheckContext();

  if (recommendations.query.loading) {
    return <LoadingSpinnerV2 />;
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem style={{ display: 'block', flexDirection: 'row', width: '20%' }} grow={3}>
          <ScoreQuality />
          <EuiSpacer size="l" />
          <hr />
          <EuiSpacer size="m" />
          <EuiFlexGroup style={{ flexWrap: 'nowrap', gap: '16px' }}>
            <EuiFlexItem>
              <div
                style={{
                  color: 'white',
                  height: '120px',
                  marginBottom: '12px',

                  paddingTop: '16px',
                }}
              >
                <EuiFlexGroup direction="column" alignItems="center">
                  <EuiFlexItem>
                    <EuiTitle size="xs">
                      <h3>TIV</h3>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="l">
                      <h3>{portfolio.formattedTiv}</h3>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            </EuiFlexItem>
            <EuiFlexItem>
              <div
                style={{
                  color: 'white',
                  height: '120px',
                  marginBottom: '12px',

                  paddingTop: '16px',
                }}
              >
                <EuiFlexGroup direction="column" alignItems="center">
                  <EuiFlexItem>
                    <EuiTitle size="xs">
                      <h3>Properties</h3>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="l">
                      <h3>{portfolio.locationCount}</h3>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={5}>
          <MapWrapper />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
