import { BarScore } from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/types';
import { AttributeScoresData } from '@onarchipelago/cx/Reports/queries/getAttributeScores';

const mutateAddressScore = (scores: BarScore[]) =>
  scores.map((score) =>
    score.scoreQuality === 'Precise' ? { ...score, scoreQuality: 'Evidenced' } : score,
  );

const getAttributes = (
  data: AttributeScoresData['qualityAttribute'],
  attributes: Record<string, string>,
) => data.filter((attribute) => Object.keys(attributes).includes(attribute.attributeName));

const getChart = (
  key: string,
  title: string,
  beforeScores: AttributeScoresData['qualityAttribute'],
  afterScores: AttributeScoresData['qualityAttribute'],
) => {
  let beforeScore = beforeScores.filter((attribute) => attribute.attributeName === key)[0].scores;
  let afterScore = afterScores.filter((attribute) => attribute.attributeName === key)[0].scores;

  if (key === 'geoCodeType') {
    beforeScore = mutateAddressScore(beforeScore);
    afterScore = mutateAddressScore(afterScore);
  }

  return {
    data: [
      { label: 'Before', scores: beforeScore },
      { label: 'After', scores: afterScore },
    ],
    title,
  };
};

export default (
  currentSnapshotData: AttributeScoresData,
  afterSnapshotData: AttributeScoresData,
  attributes: Record<string, string>,
) => {
  const beforeScores = getAttributes(currentSnapshotData.qualityAttribute, attributes);
  const afterScores = getAttributes(afterSnapshotData.qualityAttribute, attributes);

  return Object.keys(attributes)
    .filter((attribute) => {
      const finalBeforeScores = beforeScores.filter((attr) => attr.attributeName === attribute);
      const finalAfterScores = afterScores.filter((attr) => attr.attributeName === attribute);

      return finalBeforeScores.length > 0 && finalAfterScores.length > 0;
    })
    .map((attribute) => getChart(attribute, attributes[attribute], beforeScores, afterScores));
};
