import gql from 'graphql-tag';

export const GET_CARRIER_PROPERTY_CLAIMS = gql`
  query GetCarrierPropertyClaims($input: CarrierPropertyClaimsInput!) {
    carrierPropertyClaims(input: $input) {
      uwYear
      claimCnt
      totalPaidIndemnity
      avgPaidIndemnity
      claims {
        fileNumber
        lossOccurrence
        firstNotification
        causeOfLoss
        perilType
        address
        city
        state
        country
        paidIndemnity
      }
    }
  }
`;

export interface Claim {
  fileNumber: string;
  lossOccurrence: Date;
  firstNotification: Date;
  causeOfLoss: string;
  perilType: string;
  address: string;
  country: string;
  paidIndemnity: number;
}

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export interface Sort {
  attributeName: string;
  order: SortOrder;
}

export interface CarrierPropertyClaimFilter {
  year: number;
  sortBy: Sort;
  offset: number;
  limit: number;
  category?: string;
}

export interface CarrierPropertyClaimInput {
  input: {
    streamId: string;
    reportName: string;
    filter: CarrierPropertyClaimFilter;
  };
}

export interface CarrierPropertyClaims {
  uwYear: number;
  claimCnt: number;
  totalPaidIndemnity: number;
  avgPaidIndemnity: number;
  claims: Claim[];
}
