import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetPropertiesStatusMutationVariables = Types.Exact<{
  input: Types.SetPropertiesStatusInput;
}>;


export type SetPropertiesStatusMutation = { __typename?: 'Mutation', setPropertiesStatus?: { __typename?: 'SetPropertiesStatusResponse', count: number } | null };


export const SetPropertiesStatusDocument = gql`
    mutation SetPropertiesStatus($input: SetPropertiesStatusInput!) {
  setPropertiesStatus(input: $input) {
    count
  }
}
    `;
export type SetPropertiesStatusMutationFn = Apollo.MutationFunction<SetPropertiesStatusMutation, SetPropertiesStatusMutationVariables>;

/**
 * __useSetPropertiesStatusMutation__
 *
 * To run a mutation, you first call `useSetPropertiesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertiesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertiesStatusMutation, { data, loading, error }] = useSetPropertiesStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPropertiesStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPropertiesStatusMutation, SetPropertiesStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPropertiesStatusMutation, SetPropertiesStatusMutationVariables>(SetPropertiesStatusDocument, options);
      }
export type SetPropertiesStatusMutationHookResult = ReturnType<typeof useSetPropertiesStatusMutation>;
export type SetPropertiesStatusMutationResult = Apollo.MutationResult<SetPropertiesStatusMutation>;
export type SetPropertiesStatusMutationOptions = Apollo.BaseMutationOptions<SetPropertiesStatusMutation, SetPropertiesStatusMutationVariables>;