import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccountUsersQueryVariables = Types.Exact<{
  input: Types.AccountUsersInput;
}>;


export type GetAccountUsersQuery = { __typename?: 'Query', accountUsers?: Array<{ __typename?: 'AccountUsers', userID: string, profile: { __typename?: 'UserProfile', userID: string, email: string, givenName?: string | null, familyName?: string | null, title?: string | null, pictureURL?: string | null, phone?: string | null, userCode?: string | null, companyName?: string | null, lastLogin?: any | null, active: boolean }, roles?: Array<{ __typename?: 'AccountRole', role: Types.Role, accountName: string, accountID: string, isSSOProvisioned: boolean, enterpriseAccountName?: string | null, enterpriseAccountID?: string | null, stream?: { __typename?: 'Stream', id: string, name?: string | null, slug?: string | null } | null, properties: Array<{ __typename?: 'Property', archipelagoId: string }> }> | null }> | null };


export const GetAccountUsersDocument = gql`
    query GetAccountUsers($input: AccountUsersInput!) {
  accountUsers(input: $input) {
    userID
    profile {
      userID
      email
      givenName
      familyName
      title
      pictureURL
      phone
      userCode
      companyName
      lastLogin
      active
    }
    roles {
      role
      accountName
      accountID
      isSSOProvisioned
      enterpriseAccountName
      enterpriseAccountID
      stream {
        id
        name
        slug
      }
      properties {
        archipelagoId
      }
    }
  }
}
    `;

/**
 * __useGetAccountUsersQuery__
 *
 * To run a query within a React component, call `useGetAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<GetAccountUsersQuery, GetAccountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountUsersQuery, GetAccountUsersQueryVariables>(GetAccountUsersDocument, options);
      }
export function useGetAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountUsersQuery, GetAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountUsersQuery, GetAccountUsersQueryVariables>(GetAccountUsersDocument, options);
        }
export type GetAccountUsersQueryHookResult = ReturnType<typeof useGetAccountUsersQuery>;
export type GetAccountUsersLazyQueryHookResult = ReturnType<typeof useGetAccountUsersLazyQuery>;
export type GetAccountUsersQueryResult = Apollo.QueryResult<GetAccountUsersQuery, GetAccountUsersQueryVariables>;