import { css } from '@emotion/css';
import { colorLevels } from '../../colors';
import { ExposureLevel } from '../../types';
import { barWidth } from './exposureBarConstants';

const segment = (exposureLevel: ExposureLevel, percent: number) => css`
  background: ${colorLevels[exposureLevel]};
  height: 24px;
  width: ${(percent * barWidth) / 100}px;
`;

export default {
  segment,
};
