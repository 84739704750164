import React, { FC, useContext } from 'react';
import { FlyoutContext, FlyoutProvider } from '@app/contexts/FlyoutContext';

const Flyout: FC = ({ children }) => {
  const { isFlyoutOpen, FlyoutContent } = useContext(FlyoutContext);
  const flyout = <>{FlyoutContent}</>;

  return (
    <>
      {children}
      {isFlyoutOpen && flyout}
    </>
  );
};

const FlyoutWithContext: FC = ({ children }) => (
  <FlyoutProvider>
    <Flyout>{children}</Flyout>
  </FlyoutProvider>
);

export default FlyoutWithContext;
