import { MODE } from '@onarchipelago/cx/Stream/Distillers/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { PROPERTY_GROUPED_LIST_GROUP_HEIGHT } from './PropertiesGroupedListRow';
import {
  Groups,
  IGroupedProps,
  IPropertiesProps,
  IPropertyGroupRow,
  IRow,
  ISize,
  VALID_GROUPINGS,
  ValidGrouping,
} from './types';

type GetAttb = (attb: string, property: IGraphQLProperty) => any;

const getAttb: GetAttb = (attb, property) =>
  attb.split('.').reduce((prop, part) => {
    const found = prop[part];
    if (found === undefined) {
      throw new Error(`Could not find for part ${part}`);
    }

    return found;
  }, property);

const getPropertiesForGrouping = (
  properties: Array<IGraphQLProperty>,
  group: string,
): Array<{
  label: string;
  properties: Array<IGraphQLProperty>;
}> => {
  const propertiesByGroup: {
    [index: string]: Array<IGraphQLProperty>;
  } = {};
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    const key = getAttb(group, property);
    if (!propertiesByGroup[key]) {
      propertiesByGroup[key] = [];
    }
    propertiesByGroup[key].push(property);
  }

  return Object.entries(propertiesByGroup).map(([label, groupProperties]) => ({
    label,
    properties: groupProperties,
  }));
};

type GroupProperties = (
  properties: Array<IGraphQLProperty>,
  groups: Groups,
) => Array<IPropertyGroupRow<IGroupedProps | IPropertiesProps>>;

// Enables the ability to group properties on the client side
export const groupProperties: GroupProperties = (properties = [], groups = []) => {
  if (groups.length === 0) {
    return [
      {
        props: {
          properties,
          type: 'properties',
        },
      },
    ];
  }

  return getPropertiesForGrouping(properties, groups[0]).map((group) => {
    if (groups.length <= 1) {
      return {
        props: {
          explorers: [],
          filteredPropertyCount: 0,
          // hacks - these come from the server, but are unnecessary for this
          // function's use case
          id: `${group.label}`,
          label: group.label,
          totalPropertyCount: 0,
          type: 'group',
        },
        rows: [
          {
            props: {
              properties: group.properties,
              type: 'properties',
            },
          },
        ],
      };
    }

    return {
      props: {
        explorers: [],
        filteredPropertyCount: 0,
        // hacks - these come from the server, but are unnecessary for this
        // function's use case
        id: `${group.label}`,
        label: group.label,
        totalPropertyCount: 0,
        type: 'group',
      },
      rows: groupProperties(group.properties, groups.slice(1)),
    };
  });
};

type DecorateRows = (
  mode: MODE,
  rows: Array<IPropertyGroupRow<IGroupedProps | IPropertiesProps>>,
  size: ISize,
  groups: Groups,
) => Array<IRow>;

// Get font and background color based on group label
const getGroupColor = (props: IGroupedProps) => {
  switch (props.label) {
    case 'New':
      return { background: '#F6FBFE', font: '#2D9CDB' };
    case 'Disposed':
      return { background: '#FEF6F6', font: '#EB5757' };
    default:
      return { background: 'white', font: 'black' };
  }
};
export const decorateRows: DecorateRows = (mode, rows, size, groups) =>
  rows.map((row) => {
    if (row.props.type === 'group') {
      return {
        ...row,
        options: {
          color: getGroupColor(row.props as IGroupedProps),
          height: PROPERTY_GROUPED_LIST_GROUP_HEIGHT,
          showDisclosureButton: true,
        },
        rows: decorateRows(mode, row.rows || [], size, groups),
      };
    }

    if (row.props.type === 'properties') {
      return {
        ...row,
        options: {
          showDisclosureButton: false,
        },
        rows: decorateRows(mode, row.rows || [], size, groups),
      };
    }

    return {
      ...row,
      options: {
        height: PROPERTY_GROUPED_LIST_GROUP_HEIGHT,
        showDisclosureButton: false,
      },
      rows: decorateRows(mode, row.rows || [], size, groups),
    };
  });

const isGroup = (group: string): group is ValidGrouping => VALID_GROUPINGS.includes(group);

export const getGroupingsFromString = (groups: Array<string>): Groups => groups.filter(isGroup);
