import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import QualityProgressBar from '../../ReadinessReport/QualityProgressBar';
import { BarScore } from './types';

interface Props {
  'data-testid'?: string;
  closeBarLabels?: boolean;
  scores: BarScore[];
  showBarLabels: boolean;
  title: string;
  currency?: string;
}

const HorizontalBar: FC<Props> = ({
  'data-testid': dataTestId,
  closeBarLabels = false,
  scores,
  showBarLabels,
  title,
  currency,
}) => {
  const itemStyle = closeBarLabels ? { alignItems: 'end' } : null;
  const textStyle = closeBarLabels ? { marginRight: '20px' } : null;

  return (
    <EuiFlexGroup direction="row" gutterSize="xs">
      {showBarLabels && (
        <EuiFlexItem grow={2} style={itemStyle}>
          <EuiText data-testid="bar-large-title" size="m" style={textStyle}>
            <small>{title}</small>
          </EuiText>
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={3}>
        <QualityProgressBar data-testid={dataTestId} scores={scores} size="l" currency={currency} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
export default HorizontalBar;
