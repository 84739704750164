import { useState } from 'react';
import { DocumentsContext, PreviewType } from '../../types/contextTypes';
import { Document } from '../../types/dataTypes';

export default (): DocumentsContext['preview'] => {
  const [previewDocument, setPreviewDocument] = useState<Document | null>(null);
  const [previewType, setPreviewType] = useState<PreviewType | null>(null);

  const setStateFinal = (document: Document | null, type: PreviewType | null) => {
    setPreviewDocument(document);
    setPreviewType(type);
  };

  return {
    setState: setStateFinal,
    state: previewDocument,
    type: previewType,
  };
};
