import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_PROPERTY_GROUPS from '@app/queries/explorers/getOrganizationPropertyGroups.gql';
// FIX ME
// @ts-ignore
import GET_PROPERTIES from '@app/queries/properties/getProperties.gql';
import { IGraphQLProperty } from '@app/queries/properties/types';

interface Props {
  onError: (error: ApolloError) => void;
  onCompleted: (properties: Array<IGraphQLProperty>) => void;
  orgName: string;
}

interface Return {
  loading: boolean;
  start: (propertyIds: Array<string>) => void;
}
export default ({ onError, onCompleted, orgName }: Props): Return => {
  const [getPropertyGroups, { loading: loadingGroups, data: orgGroupsData }] = useLazyQuery(
    GET_ORGANIZATION_PROPERTY_GROUPS,
    {
      onError,
    },
  );

  const query = GET_PROPERTIES;
  const queryVariables = {
    cursor: '',
    groupID: orgGroupsData?.organizationPropertyGroups?.groupID,
    pageSize: 100000000,
  };

  const { loading: loadingProperties } = useQuery(query, {
    onCompleted: (data) => onCompleted(data.propertiesPage.properties),
    onError,
    skip: !orgGroupsData?.organizationPropertyGroups?.groupID,
    variables: {
      ...queryVariables,
      sortBy: [
        { attributeName: 'locationName', order: 'ASCENDING' },
        { attributeName: 'streetAddress', order: 'ASCENDING' },
        { attributeName: 'city', order: 'ASCENDING' },
      ],
    },
  });

  return {
    loading: loadingGroups || loadingProperties,
    start: (propertyIds) =>
      getPropertyGroups({
        variables: {
          filter: [
            {
              name: 'archipelagoId',
              operator: 'IN',
              values: propertyIds,
            },
          ],
          groupBy: undefined,
          organizationName: orgName,
        },
      }),
  };
};
