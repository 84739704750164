// UUID_REGEX - will check for valid UUID
const UUID_REGEX =
  '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[14][a-fA-F0-9]{3}-[89aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}';

// OPTIONAL_REGEX - is to be appended to every url that is checking for regex to make sure that you
// can always add more to the URL. Example is with the :propertyId. If you leave ${OPTIONAL_REGEX}
// out then urls with ?uCode= will not work.
const OPTIONAL_REGEX = '([?|/].+)*';

// Home page path
export const HOME_PATH = '/home';

// Regular stream paths
export const STREAM_PATH = '/streams/:streamSlug';

// STREAM_ACTION_PATH allows you to specify /edit after the stream url and automatically open the stream edit page.
export const VIEWERS_PATH = `${STREAM_PATH}/viewers`;
export const DOMAINS_PATH = `${STREAM_PATH}/domains`;
export const STREAM_ACTION_PATH = `${STREAM_PATH}/:action`;
export const PROPERTY_PATH = `${STREAM_PATH}/properties/:propertyId(${UUID_REGEX})`;
export const DOCUMENT_PATH = `${STREAM_PATH}/documents`;
export const VI_PATH = `${STREAM_PATH}/properties/:propertyId(${UUID_REGEX})/vi${OPTIONAL_REGEX}`;
export const STREAM_ADD_PROPERTIES_PATH = `${STREAM_PATH}/add-properties`;
export const STREAM_EDIT_PROPERTIES_PATH = `${STREAM_PATH}/edit-properties`;

// My Properties paths to open the MP page and property+document modals.
export const MP_STREAM_PATH = '/organizations/:organizationName/properties';
export const MP_PROPERTY_PATH = `${MP_STREAM_PATH}/:propertyId(${UUID_REGEX})`;
export const MP_DOCUMENT_PATH = `${MP_STREAM_PATH}/documents`;
export const MP_ADD_PROPERTIES_PATH = `${MP_STREAM_PATH}/add-properties`;
export const MP_EDIT_PROPERTIES_PATH = `${MP_STREAM_PATH}/edit-properties`;

// Enrichment projects path
export const PROJECTS_PATH = '/organizations/:organizationName/projects';
export const PROJECTS_DETAILS_PATH = '/organizations/:organizationName/projects/:projectName';

// System overview paths
export const STREAM_SYSTEM_OVERVIEW = `${STREAM_PATH}/system`;
export const ORG_SYSTEM_OVERVIEW = '/organizations/:organizationName/system';

// Integrations
export const INTEGRATIONS_PATH = '/integrations/:streamSlug';

export const FLAGS_PATH = '/flags';

export const REPORT_PATH = '/reports/:streamSlug/:reportType?';
export const REPORT_PROPERTY_PATH = `${REPORT_PATH}/properties/:propertyId(${UUID_REGEX})`;

export const SYSTEM_ALERTS_CONFIG_PATH = '/system/:setting?';

export const SUBMISSIONS_PATH_BASE = '/accounts';
export const ORG_SUBMISSIONS_PATH = `/organizations/:organizationName${SUBMISSIONS_PATH_BASE}`;
