import gql from 'graphql-tag';

export const FRAGMENT_GRADE = gql`
  fragment grade on Grade {
    category
    color
    value
    code
  }
`;
