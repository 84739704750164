import React from 'react';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';

const EmptyStateMessage: React.FC = () => (
  <ErrorMessage
    testId="stream-no-properties"
    title="No properties found"
    message="There weren't any properties that match the filters you applied"
  />
);

export default EmptyStateMessage;
