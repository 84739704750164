import React from 'react';
import styled from '@emotion/styled';
import PropertyImg from '@app/components/PropertyImg/PropertyImg';
import { IMAGE_SIZES } from '@app/config';
import { ColumnFormattingOptions } from '../types';
import { getEdits, getProvenance, ICell } from './Cell';

const SIZE = 32;

const Container = styled.div(
  (props: ColumnFormattingOptions) => `
  label: PropertiesGridImage;
  img {
    margin: 0 auto;
    display: block;
    width: ${props.width || SIZE}px;
    height: ${props.height || SIZE}px;
    object-fit: cover;
    border-radius: 2px;
    overflow: hidden;
  }
`,
);

const PropertiesGridImage = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const property = props.prop;
  const provenance = getProvenance(property, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(property, id),
    provenance,
    title: property?.locationName,
  };

  const body = (
    <Container
      height={props.column.formattingOptions.height}
      width={props.column.formattingOptions.width}
    >
      <PropertyImg
        locationName={property?.locationName}
        pictures={property?.pictures}
        width={IMAGE_SIZES.small.width}
      />
    </Container>
  );

  return { body, tooltip };
};

export default PropertiesGridImage;
