import gql from 'graphql-tag';

export const START_ADD_LOSSES_JOB = gql`
  mutation StartAddLossesJob($input: StartAddLossesJobInput!) {
    startAddLossesJob(input: $input) {
      jobID
      worksheet {
        id
        httpURL
        filename
        extension
        sizeInBytes
        contentType
      }
    }
  }
`;
