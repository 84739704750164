import { AttributeFilter } from '@app/graphql/types';

export enum ResourceType {
  Org = 'Org',
  Stream = 'Stream',
  MPStream = 'MPStream',
  Document = 'Document',
  Property = 'Property',
  Submission = 'Submission',
  ConsumerAttributes = 'ConsumerAttributes',
}

export enum Action {
  View = 'View',
  List = 'List',
  Edit = 'Edit',
  Create = 'Create',
}

export enum ScopeType {
  StreamSlug = 'StreamSlug',
  OrgId = 'OrgId',
}

export type PrincipalType = 'User' | 'Org';

export enum Role {
  EnterpriseAdmin = 'EnterpriseAdmin',
  RiskManager = 'RiskManager',
  DataManager = 'DataManager',
  DataEngineer = 'DataEngineer',
  DocUploader = 'DocUploader',
  PropertyViewer = 'PropertyViewer',
  PropertyEditor = 'PropertyEditor',
}

export enum RoleDescription {
  RiskManager = 'can see all properties in your account.',
  DataManager = 'can see all properties in your account, projects, and jobs.',
  DataEngineer = 'can see all properties in your account, as well as jobs they\'ve been assigned.',
  DocUploader = 'can upload documents in the account',
  PropertyViewer = 'can view some properties in your account',
  PropertyEditor = 'can edit some properties in your account',
}

interface RoleFilterInput {
  description: string;
  resource: ResourceType;
  resourceIDs: Array<string>;
  predicates: Array<AttributeFilter>;
}

interface Resource {
  id: string;
  name?: string;
}

interface RoleFilter {
  description: string;
  resourceType: ResourceType;
  resources: Array<Resource>;
  predicates: Array<AttributeFilter>;
}

interface Scopes {
  type: ScopeType;
  values: Array<string>;
}

interface Scope {
  type: ScopeType;
  value: string;
}

export interface RoleAssignment {
  principalId: string;
  principalType: PrincipalType;
  role: Role;
  scopes: Array<Scope>;
}
export interface GetRoleAssignmentsVariables {
  principalId?: string;
  scope?: Scope;
}
export interface GetRoleAssignmentsData {
  roleAssignmentsV2: {
    assignments: Array<RoleAssignment>;
  };
}

export interface RoleAssignmentRole {
  principalId: string;
  principalType: PrincipalType;
  role: Role;
  scopes: Array<Scope>;
}

export interface RoleAssignmentV2 {
  active: boolean;
  lastLogin?: Date;
  userId: string;
  roles: [RoleAssignmentRole];
}

export interface GetRolesVariables {
  orgName: string;
}

export interface GetRolesData {
  userRoles: [RoleAssignmentV2];
}

export interface AssignRoleV2Input {
  principalId: string;
  principalType?: PrincipalType;
  role: Role;
  scope: Scope;

  filter?: RoleFilterInput;
}
export interface AssignRoleVariables {
  input: AssignRoleV2Input;
}
export interface AssignRoleData {
  assignRoleV2: {
    found: boolean;
    roleAssignment: RoleAssignment;
  };
}

interface RemoveScopesInput {
  principalId: string;
  role: Role;
  scopeType: ScopeType;
  scopeValues: Array<string>;
}
export interface RemoveScopesVariables {
  input: RemoveScopesInput;
}
