import React, { memo } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import HighMaps from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import { colors } from 'ui';
import { IGraphQLChart, IGraphQLTIVChart } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { buildTIVMapData, chartDataIsEqual } from './utils';

const worldLowRes = require('./worldLowRes');
const europe = require('./europe');

const explorerNameMap = {
  tiv_by_country: worldLowRes,
  tiv_by_eu_state: europe,
};

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

HighMaps.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const getOptions = (
  queryData: any[],
  formatCurrency?: FormatCurrency,
  map?: any,
  title?: string,
): any => ({
  chart: {
    animation: false,
    height: '70%',
    map: map || worldLowRes,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: colors.dataViz.archipelagoGreen4,
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: false,
  },
  mapNavigation: {
    enabled: false,
  },
  plotOptions: {
    map: {
      margin: 0,
      states: {
        hover: {
          color: '#EEDD66',
        },
      },
    },
  },
  series: [
    {
      data: queryData,
      dataLabels: {
        enabled: false,
        format: '{point.name}',
      },
    },
  ],
  subtitle: {
    text: null,
  },
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${this.point.name}<br><b>${formatCurrency?.(this.point.value)}</b>`;
    },
    valuePrefix: '$',
  },
});

const TivByCountry: React.FC<IProps> = ({ explorer, title }) => {
  const queryData = buildTIVMapData(explorer as IGraphQLTIVChart);
  const { formatStreamCurrency } = useStreamContext();
  const options = getOptions(
    queryData,
    formatStreamCurrency,
    explorerNameMap[explorer.name],
    title,
  );
  return (
    <Explorer>
      <HighchartsReact highcharts={HighMaps} constructorType="mapChart" options={options} />
    </Explorer>
  );
};

export default memo(TivByCountry, chartDataIsEqual);
