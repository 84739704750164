import { css } from '@emotion/css';
import { colorLevels } from '../../colors';
import { ExposureLevel } from '../../types';
import { barWidth } from './barConstants';

interface segmentProps {
  exposureLevel: ExposureLevel;
  percent: number;
  showBackground: boolean;
}

const segment = ({ exposureLevel, percent, showBackground }: segmentProps) => css`
  background: ${showBackground ? colorLevels[exposureLevel] : '#E0E6EB'};
  height: 8px;
  width: ${(percent * barWidth) / 100}px;
`;

export default {
  segment,
};
