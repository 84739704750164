import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenSupportTicketMutationVariables = Types.Exact<{
  input: Types.OpenSupportTicketInput;
}>;


export type OpenSupportTicketMutation = { __typename?: 'Mutation', openSupportTicket: { __typename?: 'OpenSupportTicketOutput', ticket: { __typename?: 'SupportTicket', title: string, status: Types.TicketStatus, authorEmail: string, assigneeEmails?: Array<string> | null, chatRoomID: string, chatRoomName: string } } };


export const OpenSupportTicketDocument = gql`
    mutation OpenSupportTicket($input: OpenSupportTicketInput!) {
  openSupportTicket(input: $input) {
    ticket {
      title
      status
      authorEmail
      assigneeEmails
      chatRoomID
      chatRoomName
    }
  }
}
    `;
export type OpenSupportTicketMutationFn = Apollo.MutationFunction<OpenSupportTicketMutation, OpenSupportTicketMutationVariables>;

/**
 * __useOpenSupportTicketMutation__
 *
 * To run a mutation, you first call `useOpenSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openSupportTicketMutation, { data, loading, error }] = useOpenSupportTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenSupportTicketMutation(baseOptions?: Apollo.MutationHookOptions<OpenSupportTicketMutation, OpenSupportTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenSupportTicketMutation, OpenSupportTicketMutationVariables>(OpenSupportTicketDocument, options);
      }
export type OpenSupportTicketMutationHookResult = ReturnType<typeof useOpenSupportTicketMutation>;
export type OpenSupportTicketMutationResult = Apollo.MutationResult<OpenSupportTicketMutation>;
export type OpenSupportTicketMutationOptions = Apollo.BaseMutationOptions<OpenSupportTicketMutation, OpenSupportTicketMutationVariables>;