import { css } from '@emotion/css';

const container = css`
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);
  background-color: hsl(195, 86%, 64%);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-weight: bold;
  height: 18px;
  width: 37px;
  padding: 4px;
  font-size: 8px;
  line-height: 10px;
  margin-bottom: 6px;
`;

export default {
  container,
};
