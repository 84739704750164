import { css } from '@emotion/css';

const loadingContainer = css`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export default {
  loadingContainer,
};
