import React, { useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiText, Pagination } from 'ui';
import { useGradingDetailsContext } from '../context';
import { Container } from './styles';
import Summary from './Summary';
import { getTableData } from './tableData';

const AnnualLossTable: React.FC = () => {
  const { currentAttribute, carrierAssessment } = useGradingDetailsContext();
  const { columns, items, title, summaryTitle, summary, summaryColumns } = getTableData(
    currentAttribute,
    carrierAssessment,
  );
  const [pageIndex, setPageIndex] = useState<number>(0);

  const pagination: Pagination = {
    // FIX ME
    // @ts-ignore
    hidePerPageOptions: true,
    pageIndex,
    pageSize: 7,
    pageSizeOptions: [7],
    totalItemCount: carrierAssessment.lossYears.length,
  };

  return (
    <Container>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <EuiText size="s">{summaryTitle}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <Summary columns={summaryColumns} summary={summary} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <EuiText size="s">{title}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiInMemoryTable
                columns={columns}
                items={items}
                pagination={pagination}
                onChange={(e) => setPageIndex(e.page.index)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Container>
  );
};

export default AnnualLossTable;
