"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var toastTransform = (0, react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    transform: translateY(-150%) scale(0.95);\n    opacity: 0.5;\n  }\n\n  to {\n    transform: translateY(0) scale(1);\n    opacity: 1;\n  }\n"], ["\n  from {\n    transform: translateY(-150%) scale(0.95);\n    opacity: 0.5;\n  }\n\n  to {\n    transform: translateY(0) scale(1);\n    opacity: 1;\n  }\n"])));
var toast = function (animation) { return (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  [class*='euiToastHeader'] {\n    align-items: center;\n  }\n  [class*='euiToastHeader__title'] {\n    width: 100%;\n  }\n  &::before {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: -1;\n  }\n\n  border-top-width: 2px;\n  border-top-style: solid;\n  border-left: 0 solid transparent;\n  position: relative;\n  margin: 8px;\n  display: block;\n  z-index: auto;\n  transition: all 0.6s ease-in-out;\n  animation: ", " ", " ", ";\n"], ["\n  [class*='euiToastHeader'] {\n    align-items: center;\n  }\n  [class*='euiToastHeader__title'] {\n    width: 100%;\n  }\n  &::before {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: -1;\n  }\n\n  border-top-width: 2px;\n  border-top-style: solid;\n  border-left: 0 solid transparent;\n  position: relative;\n  margin: 8px;\n  display: block;\n  z-index: auto;\n  transition: all 0.6s ease-in-out;\n  animation: ", " ", " ", ";\n"])), animation.normal, toastTransform, animation.resistance); };
var toastList = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 24px;\n  width: 60%;\n  margin-left: -30%; /* half the width */\n  left: 50%;\n  right: 50%;\n  transition: all 0.6s ease-in-out;\n  z-index: 10000;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column-reverse;\n"], ["\n  position: fixed;\n  top: 24px;\n  width: 60%;\n  margin-left: -30%; /* half the width */\n  left: 50%;\n  right: 50%;\n  transition: all 0.6s ease-in-out;\n  z-index: 10000;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column-reverse;\n"])));
exports.default = {
    toast: toast,
    toastList: toastList,
};
var templateObject_1, templateObject_2, templateObject_3;
