import styled from '@emotion/styled';
import { EuiFlexItem } from 'ui';

export const HeaderContainer = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderButtonsContainer = styled(EuiFlexItem)`
  min-width: 0;
`;
