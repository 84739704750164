// FIX ME
// @ts-ignore
import { QualityScore } from './types';

export const PROPERTY_RANK_ATTRIBUTES = [
  'numberOfBuildings',
  'constructionType',
  'distanceToNearestBuilding',
  'primaryTenantNAICS',
  'percentageSprinklered',
  'geoCodeType',
  'numberOfStoriesAboveGround',
  'specificOccupancy',
  'buildingSprinklered',
  'propertyManager',
  'replacementCostMethodology',
  'floorArea',
  'roofInstallYear',
  'roofSystem',
  'acquiredOrBuilt',
  'buildingCondition',
  'numberOfStoriesBelowGround',
  'wallCladdingSystem',
  'buildingSprinklerType',
  'equipmentEarthquakeBracing',
  'buildingFootprintClass',
  'numberOfHighPriorityRecommendations',
  'roofScreensSignsAnchorageandBracing',
  'floorSystem',
  'roofDeckType',
  'yearBuilt',
  'roofSystemAnchorage',
  'roofGeometry',
  'buildingFoundationType',
  'roofParapets',
  'buildingExteriorOpening',
  'rooftopWaterTanks',
  'roofingMaterialAnchorage',
  'roofChimneysAnchorage',
  'yearLastUpgraded',
  'windowType',
  'doorAndFrameType',
  'glassPercentage',
  'baseFloodElevation',
  'constructionQuality',
  'totalInsuredValue',
  'buildingRedundancy',
  'permanentFloodMeasuresPresent',
  'roofEquipmentAnchorage',
  'windowProtection',
  'rooftopSolarPanels',
  'roofDrainageType',
  'roofCopingAndFlashing',
  'softStory',
  'sprinklerPipesSeismicallyBraced',
  'structuralUpgradeType',
  'suitabilityOfFireProtectionMeasures',
  'surfaceRoughnessWind',
  'torsion',
  'foundationToFrameConnection',
  'verticalIrregularity',
  'floodMissiles',
  'waterHeaterBracing',
  'waterSupplyFlowRate',
  'windMissiles',
  'firstFloorHeight',
  'firstFloorElevation',
  'fireProtectionInspectionProgram',
  'eqSpecialSystems',
  'emergencyFloodProtectionMeasures',
  'contentsVulnerabilityFlood',
  'sprinklerLeakDetectionSystem',
  'backupGeneratorDieselStorageCapacity',
  'roofSolarPanelCoverage',
  'shortColumnConcrete',
  'crippleWalls',
  'backupGenerator',
  'naturalGasPipesSeismicallyBraced',
  'roofHailMitigation',
  'naturalGasAutomaticShutoff',
  'lossEngineeringReportPresent',
  'lastEngineeringVisitDate',
  'contentsVulnerabilityWind',
  'roofStructures',
  'backupGeneratorLocation',
  'interiorPartitions',
  'glassType',
  'generatorTestingProgram',
  'seismicDesignQuality',
  'seismicDesignRValue',
  'roofDeckAnchorage',
  'remoteMonitoringOfSprinklerSystem',
  'structuralSystemUpgraded',
  'multiTenant',
];

export const scores: QualityScore[] = [
  {
    color: '#013B6F',
    detail: 'There is provenance to evidence the accuracy of this value.',
    label: 'Evidenced',
    name: 'Evidenced',
    score: 4,
  },
  {
    color: '#21B59A',
    detail:
      'A clear, acceptable, and specific value is provided which doesn’t require interpretation. An example is construction type “Reinforced Masonry”.',
    label: 'Precise' /* $euiColorSuccess */,
    name: 'Precise',
    score: 3,
  },
  {
    color: '#FCCAA7',
    detail:
      'The value is vague and allows for different interpretations. An example is construction type “Masonry” where more granularity is recommended to improve the value to a precise score.',
    label: 'Ambiguous',
    name: 'Ambiguous',
    score: 2,
  },
  {
    color: '#FF7E62',
    detail: 'A value was present but could not be interpreted or used.',
    label: 'Invalid',
    name: 'Invalid',
    score: 1,
  },
  { color: '#98A2B3', detail: 'No value present.', label: 'Blank', name: 'None', score: 0 },
];

export const attributeMappings = {
  ATTRIIONAL: {
    buildingSprinklerType: 'Building Sprinkler Type',
    remoteMonitoringOfSprinklerSystem: 'Remote Monitoring of Sprinkler System',
    backupGenerator: 'Back-up Generator',
    roofInstallYear: 'Roofing Material Installation Year',
    buildingFoundationType: 'Building Foundation',
    sprinklerLeakDetectionSystem: 'Sprinkler Leak Detection System',
    buildingCondition: 'Building Condition',
    roofSystem: 'Roofing Material',
    numberOfStoriesBelowGround: 'Number of Stories - Below Ground',
    wallCladdingSystem: 'Cladding / Wall System',
  },
  COPE: {
    buildingSprinklered: 'Building Sprinklered',
    constructionType: 'Specific Construction Class',
    floorArea: 'Floor Area',
    numberOfStoriesAboveGround: 'Stories Above Ground',
    specificOccupancy: 'Specific Occupancy',
    yearBuilt: 'Year Built',
  },
  EARTHQUAKE: {
    buildingCondition: 'Building Condition',
    buildingFootprintClass: 'Building Footprint Class',
    buildingFoundationType: 'Building Foundation',
    constructionQuality: 'Construction Quality',
    distanceToNearestBuilding: 'Distance to Nearest Building',
    equipmentEarthquakeBracing: 'Equipment Earthquake Bracing',
    softStory: 'Soft Story',
    sprinklerPipesSeismicallyBraced: 'Sprinkler Piping Seismic Bracing',
    verticalIrregularity: 'Vertical Irregularity',
    wallCladdingSystem: 'Cladding / Wall System',
  },
  FIRE: {
    backupGenerator: 'Back-up Generator',
    backupGeneratorDieselStorageCapacity: 'Back-up Generator Diesel Storage Capacity',
    buildingSprinklerType: 'Building Sprinkler Type',
    distanceToNearestBuilding: 'Distance to Nearest Building',
    percentageSprinklered: 'Percentage Sprinklered',
    remoteMonitoringOfSprinklerSystem: 'Remote Monitoring of Sprinkler System',
    roofDeckType: 'Roof Deck Material',
    roofSystem: 'Roofing Material',
    sprinklerLeakDetectionSystem: 'Sprinkler Leak Detection System',
    wallCladdingSystem: 'Cladding / Wall System',
  },
  FLOOD: {
    baseFloodElevation: 'Base Flood Elevation',
    buildingFoundationType: 'Building Foundation',
    contentsVulnerabilityFlood: 'Contents Vulnerability - Flood',
    elevationOfGroundOrBasementLevelMEPEquipment: 'MEP Equipment (Basement or 1st Floor)',
    emergencyFloodProtectionMeasures: 'Emergency Flood Protection Measures',
    firstFloorElevation: 'First Floor Elevation',
    firstFloorHeight: 'First Floor Height',
    floorSystem: 'Floor System',
    numberOfStoriesBelowGround: 'Number of Stories - Below Ground',
    permanentFloodMeasuresPresent: 'Permanent Flood Protection Measures',
  },
  GEOCODE: {
    geoCodeType: 'Geocode Type',
  },
  SEVERE_CONVECTIVE_STORM: {
    buildingCondition: 'Building Condition',
    roofDeckAnchorage: 'Roof Deck Anchorage',
    buildingFoundationType: 'Building Foundation',
    roofInstallYear: 'Roofing Material Installation Year',
    roofDeckType: 'Roof Deck Material',
    roofSystem: 'Roofing Material',
    roofEquipmentAnchorage: 'Roof Equipment Anchorage/Bracing',
    roofSystemAnchorage: 'Roof System Anchorage',
    wallCladdingSystem: 'Cladding / Wall System',
    windowType: 'Window & Frame Type',
  },
  WIND: {
    constructionQuality: 'Construction Quality',
    roofDeckAnchorage: 'Roof Deck Anchorage',
    roofDeckType: 'Roof Deck Material',
    roofEquipmentAnchorage: 'Roof Equipment Anchorage/Bracing',
    roofGeometry: 'Roof Geometry',
    roofSystem: 'Roofing Material',
    roofInstallYear: 'Roofing Material Installation Year',
    windowType: 'Window & Frame Type',
    roofSystemAnchorage: 'Roof System Anchorage',
    wallCladdingSystem: 'Cladding / Wall System',
  },
};
