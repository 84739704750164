import React, { useEffect } from 'react';
import { EuiFlexGroup, EuiPageHeader, EuiPanel, useToast } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useCasualtyPageQuery } from '@app/graphql/casualty/queries/__generated__/casualtyPage.generated';
import { TrackGroupCasualty, useTracker } from '@app/hooks/useTracker';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { Helmet } from '../Helmet';
import { CasualtyDashboard } from './CasualtyDashboard';
import { CasualtyDashboardButtons } from './CasualtyDashboardButtons';

export const CasualtyPage: React.FC = () => {
  const { selectedOrganization } = useUserSession();
  const toast = useToast();
  const mixpanel = useTracker('mixpanel');

  const { loading: loadingCasualty, data: dataCasualty } = useCasualtyPageQuery({
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        offset: 0,
        orgName: selectedOrganization?.name,
        pageSize: 100,
      },
    },
  });

  useEffect(() => {
    mixpanel.track(`${TrackGroupCasualty.prefix}: Casualty page opened`);
  }, []);

  return (
    <EuiPanel paddingSize="l">
      <Helmet title="Casualty" />
      <EuiFlexGroup direction="column">
        <EuiPageHeader
          pageTitle={'Casualty'}
          rightSideItems={[
            <CasualtyDashboardButtons
              casualtyList={dataCasualty?.casualtyPage?.casualtyList || []}
            />,
          ]}
        />
      </EuiFlexGroup>
      <CasualtyDashboard
        casualtyList={dataCasualty?.casualtyPage?.casualtyList || []}
        loading={loadingCasualty}
      />
    </EuiPanel>
  );
};
