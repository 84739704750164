export const reportsHeader = {
  paddingBottom: '16px',
};

export const reportsHeaderNoSubtitle = {
  paddingBottom: '4px',
};

export const reportsSubtitle = {
  paddingBottom: '4px',
};
