import { useState } from 'react';
import { GetPropertyMarkersData } from '@app/queries/properties/types';

export type Cache = {
  [K: string]: GetPropertyMarkersData;
};

interface Return {
  cache: Cache;
  store: (k: string, v: GetPropertyMarkersData) => void;
}

const useMapCache = (): Return => {
  const [cache, setCache] = useState<Cache>({});

  const store = (k: string, v: GetPropertyMarkersData) => setCache((c) => ({ ...c, [k]: v }));
  return { cache, store };
};

export default useMapCache;
