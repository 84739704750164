import { AllExposureLevels, SegmentPercents } from '../../types';

// TODO - Move min/max scores and thresholds to a separate GQL api to make it the unique source of truth
const minScore = 23;
const maxScore = 282;

const thresholds = [minScore, 113, 133, 173, 193, maxScore];
export const getSegmentPercents = (): SegmentPercents => {
  const percents = thresholds
    .slice(1)
    .map((t, i) => ((t - thresholds[i]) / (maxScore - minScore)) * 100);

  if (percents.length !== AllExposureLevels.length) {
    throw new Error(
      'error calculating segment percents: number of thresholds does not match with number of exposure levels',
    );
  }

  const percentsMap = {};
  AllExposureLevels.forEach((l, i) => (percentsMap[l] = percents[i]));
  return percentsMap as SegmentPercents;
};
