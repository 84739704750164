import { css } from '@emotion/css';

const container = css`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const loadingContainer = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const loadingPercentage = css`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  padding: 8px;
  position: relative;
  text-align: center;
  top: 48px;
`;

export default {
  container,
  loadingContainer,
  loadingPercentage,
};
