import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const TableGrid = styled.div`
  display: grid;
  grid-auto-rows: 50px;
  align-items: center;
`;

const chartContainer = css`
  width: 654px;
`;

const comparisonContainer = css`
  text-align: start;
  width: 542px;
`;

const exposureContainer = css`
  text-align: end;
  width: 300px;
`;

const filterContainer = css`
  text-align: end;
  width: 140px;
`;

const filterChild = css`
  align-items: center;
  display: flex;
  .euiText {
    margin-right: 4px;
  }
`;

const rowContainer = css`
  align-items: center;
  display: flex;
`;

const spacer = css`
  width: 16px;
`;

const tivContainer = css`
  text-align: right;
  width: 50px;
`;

const tivKey = css`
  align-items: center;
  display: flex;
  margin-left: 382px;
`;

export default {
  chartContainer,
  comparisonContainer,
  exposureContainer,
  filterChild,
  filterContainer,
  rowContainer,
  spacer,
  tivContainer,
  tivKey,
};
