import { css } from '@emotion/css';

const icon = css`
  margin: 16px 0 0 0 !important;
`;

const sectionDescription = css`
  width: 320px;
`;

const sectionHeader = css`
  text-decoration: underline;
`;

export default {
  icon,
  sectionDescription,
  sectionHeader,
};
