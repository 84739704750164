import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGradingDetailsContext } from '../tabs/GradingDetails/context';

const CustomerAvgLossChart: React.FC = () => {
  const {
    carrierAssessment: { lossYears: unsortedLossYears },
  } = useGradingDetailsContext();

  const lossYears = unsortedLossYears.slice().sort((a, b) => (a.uwYear > b.uwYear ? 1 : -1));
  const options: Highcharts.Options = {
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
    },

    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
        pointInterval: 1,
        pointStart: lossYears?.[0]?.uwYear,
      },
    },

    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              align: 'center',
              layout: 'horizontal',
              verticalAlign: 'bottom',
            },
          },
          condition: {
            maxWidth: 200,
            minWidth: 200,
          },
        },
      ],
    },

    series: [
      {
        color: '#2167AE',
        // FIX ME
        // @ts-ignore
        data: lossYears.map((l) => l.avgPaidIndemnity),
        name: 'Avg. Paid Indemnity',
      },
      {
        color: '#19A5B6',
        dashStyle: 'ShortDot',
        // FIX ME
        // @ts-ignore
        data: lossYears.map((l) => l.avgPaidIndemnityBenchmark),
        name: 'Zurich Benchmark',
      },
    ],

    title: {
      style: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '600',
      },
      text: 'Avg. Paid Indemnity Compared to the Benchmark',
    },

    xAxis: {
      accessibility: {
        rangeDescription: 'Range: 2010 to 2017',
      },

      allowDecimals: false,
      title: { text: 'UW Year' },
    },

    yAxis: {
      title: {
        text: 'Loss ($K)',
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CustomerAvgLossChart;
