import React from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  AttributeScoresData,
  AttributeScoresVariables,
  GET_ATTRIBUTE_SCORES,
} from '@onarchipelago/cx/Reports/queries/getAttributeScores';
import getCharts from '@onarchipelago/cx/Reports/utils/getCharts';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';
import CoreAttributesBeforeAfterComponent from './CoreAttributesBeforeAfterComponent';

const CoreAttributesBeforeAfterContainer: React.FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const { afterSnapshot, currentSnapshot } = snapshots;

  const {
    data: currentSnapshotData,
    loading: currentSnapshotLoading,
    error: currentSnapshotError,
  } = useQuery<AttributeScoresData, AttributeScoresVariables>(GET_ATTRIBUTE_SCORES, {
    variables: {
      input: {
        hazard: 'All',
        snapshotName: currentSnapshot?.snapshotName || '',
        streamSlug,
      },
    },
  });

  const {
    data: afterSnapshotData,
    loading: afterSnapshotLoading,
    error: afterSnapshotError,
  } = useQuery<AttributeScoresData, AttributeScoresVariables>(GET_ATTRIBUTE_SCORES, {
    variables: {
      input: {
        hazard: 'All',
        snapshotName: afterSnapshot?.snapshotName || '',
        streamSlug,
      },
    },
  });

  if (
    (currentSnapshotLoading || afterSnapshotLoading) &&
    !currentSnapshotError &&
    !afterSnapshotError
  ) {
    return <LoadingSpinnerV2 styles={{ height: '690px' }} />;
  }

  if (
    !currentSnapshotLoading &&
    !afterSnapshotLoading &&
    (currentSnapshotError || afterSnapshotError)
  ) {
    return <ErrorState />;
  }

  if (
    currentSnapshotData &&
    afterSnapshotData &&
    !currentSnapshotLoading &&
    !afterSnapshotLoading &&
    !currentSnapshotError &&
    !afterSnapshotError
  ) {
    const attributes = {
      constructionType: 'Specific Construction Class',
      floorArea: 'Floor Area',
      geoCodeType: 'Address',
      numberOfBuildings: 'Number of Buildings',
      numberOfStoriesAboveGround: 'Stories Above Ground',
      specificOccupancy: 'Specific Occupancy',
      yearBuilt: 'Year Built',
    };

    return (
      <CoreAttributesBeforeAfterComponent
        charts={getCharts(currentSnapshotData, afterSnapshotData, attributes)}
      />
    );
  }

  return null;
};

export default CoreAttributesBeforeAfterContainer;
