import React from 'react';
import { ExposureLevel, SegmentPercents } from '../../types';
import styles from './ExposureBarSegment.emotion';

interface Props {
  exposureLevel: ExposureLevel;
  segmentPercents: SegmentPercents;
}

const ExposureBarSegment: React.FC<Props> = ({ exposureLevel, segmentPercents }) => (
  <div
    data-testid="exposure-bar-segment"
    className={styles.segment(exposureLevel, segmentPercents[exposureLevel])}
  />
);

export default ExposureBarSegment;
