import { scores } from './const';

export const qualityScoresForSnapshots = scores.filter((item) => item.score !== 1);
export const qualityScoresForSOVs = scores.filter((item) => item.score !== 4);

// Addresses don't use the normal names/descriptions for their scores so we need to override them
export const qualityScoresForAddress = scores
  .filter((item) => item.score !== 1 && item.score !== 3)
  .map((item) =>
    item.score === 4
      ? {
          ...item,
          detail:
            'Roof Centroid geocoding provides a precise location of the properties relative to the local hazards.',
          label: 'Roof Centroid',
        }
      : item,
  )
  .map((item) =>
    item.score === 2
      ? {
          ...item,
          detail:
            'Address data is present but allows for lower-precision geo-coding only (e.g., Street, City, State levels).',
          label: 'Unconfirmed',
        }
      : item,
  );

export const scoreByName = (name: string) =>
  scores.find((item) => item.label.toLowerCase() === name);

export const colorByName = (name: string) =>
  scores.find((item) => item.name.toLowerCase() === name.toLowerCase())?.color || 'blue';

export const formatScore = (score?: number): string =>
  Math.round(100 * Number.parseFloat((score || 0).toFixed(2))).toString();

export const getNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatPercentage = (value = 0) => {
  const percentage = (value * 100).toFixed(2);

  return parseFloat(percentage);
};

export const getReadinessGrade = (score: number): string => {
  if (score <= 50) {
    return 'Level 1';
  }
  if (score < 60) {
    return 'Level 2';
  }
  return 'Level 3';
};

export const getReadinessGradeMessage = (score: number): string => {
  if (score <= 50) {
    return 'This portfolio would generally receive a lot of questions if accepted for review, as well as more conservative pricing stemming from less certainty.';
  }
  if (score < 60) {
    return 'This portfolio would generally require little, if any, clarification and would often have differentiated pricing that aligns with the resiliency.';
  }
  return 'This portfolio would generally have greater participation from insurers, receive differentiated pricing and be underwritten the fastest.';
};
