import { Dispatch, SetStateAction } from 'react';
import { Mutation } from '@app/components/Apollo/types';
// FIX ME
// @ts-ignore
import GET_STREAMS from '@app/queries/streams/getStreams.gql';
import { ICreateStreamData, ICreateStreamVariables } from '@app/queries/streams/inputs';
import { DateStyle, formatDate } from '@app/utils/format';
import { IForm } from './utils';

interface Props {
  closeModal: () => void;
  createStream: Mutation<ICreateStreamData, ICreateStreamVariables>;
  organizationName: string;
  setShowSaveButton: Dispatch<SetStateAction<boolean>>;
  values: IForm;
}

export default ({
  closeModal,
  createStream,
  organizationName,
  setShowSaveButton,
  values,
}: Props) => {
  closeModal();
  setShowSaveButton(false);
  createStream({
    refetchQueries: [{ query: GET_STREAMS, variables: { orgName: organizationName } }],
    variables: {
      input: {
        orgName: organizationName,
        stream: {
          ...values,
          // These almost certainly don't have to be formatted as toDate() looks correct
          // We can also modify the formatDate function to take Moment objects as well
          effectiveDate: formatDate(values.effectiveDate.toDate(), DateStyle.Server),
          expiryDate: formatDate(values.expiryDate.toDate(), DateStyle.Server),
        },
      },
    },
  });
};
