import { IGraphQLDocument } from '@app/queries/documents/types';
import { EJobStatus, EJobType } from '@app/queries/organizations/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';

/**
 * Somewhat of an equivalent of IGraphQLProperty but with a lot less fields.
 */
export interface IGraphQLPropertySlim {
  id: string;
  archipelagoId: string;
  locationId: string;
  locationName: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  pictures?: any; // TODO this should be strongly typed
  propertyStatus?: string;
  // attributeProvenance?: // TODO this should be strongly typed
}

export interface IProject {
  name: string;
  orgName: string;
  effectiveFrom: string | undefined;
  dueOn: string | undefined;
  jobs?: string[];
  snapshots: IGraphQLSnapshot[];
}

export interface IGraphQLUser {
  userID: string;
  email: string;
  givenName?: string;
  familyName?: string;
  pictureUrl?: string;
  title?: string;
}

export interface UserProfile {
  userID: string;
  email: string;
  givenName?: String;
  familyName?: String;
  title?: String;
  pictureURL?: String;
  phone?: String;
  userCode?: String;
  companyName?: String;
}

interface WorkSheet {
  id: string;
  httpURL: string;
  filename: string;
  extension: string;
  contentType: string;
}

export enum DataErrorType {
  DuplicateColumnHeader = 'DuplicateColumnHeader',
  UnknownColumnHeader = 'UnknownColumnHeader',
  InvalidPropertyIDs = 'InvalidPropertyIDs',
  InvalidDataFormat = 'InvalidDataFormat',
  InternalServerError = 'InternalServerError',
  DuplicateClientID = 'DuplicateClientID',
}

export interface DataError {
  location: string;
  value?: string;
  columnHeader: string;
  message?: string;
  type?: DataErrorType;
  attributeName?: string;
  sheetName?: string;
}

export enum TransitionStatus {
  Started = 'Started',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface StateTransition {
  id: string;
  worksheet: WorkSheet;
  progress: TransitionStatus;
  dataErrors: DataError[];
  systemErrors: DataError[];
  warnings: DataError[];
  to: string;
  from: string;
}

export enum JobPriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export interface IJob {
  id: string;
  name: string;
  createdAt: string;
  expiryTime: string;
  description: string;
  type: EJobType;
  attributeNames: string[];
  sovDocId: string;
  doerProfile: IGraphQLUser;
  reviewerProfile: IGraphQLUser;
  requesterProfile: IGraphQLUser;
  lifeCycle: StateTransition[];
  status: EJobStatus;
  dueOn: string;
  projectName: string;
  propertyIDs: string[];
  orgName: string;
  references: IGraphQLDocument[];
  details: string;
  worksheetFormat: string;
  priority?: JobPriority;
  version: number;
}

interface ValueList {
  value: string;
  list: string[];
}

export interface OrgEnumValues {
  valueDisplayName: string;
  listDisplayName: string;
  valueList: ValueList[];
}

export interface JobErrors {
  dataErrors: Array<DataError>;
  systemErrors: Array<DataError>;
}

export interface JobWarnings {
  warnings: Array<DataError>;
  status: EJobStatus;
}

/**
 * EPropertySelectType is an enum that let's you pick which set of properties should be shown in
 * the properties list.
 */
export enum EPropertySelectType {
  /**
   * Queries all properties that are visible in the stream.
   */
  AllProperties = 'AllProperties',
  /**
   * Queries all properties that have loss data associated with the property.
   */
  AllPropertiesWithLosses = 'AllPropertiesWithLosses',
  /**
   * Queries all properties that are deleted.
   */
  DeletedProperties = 'DeletedProperties',
  /**
   * Queries all properties that are disposed.
   */
  DisposedProperties = 'DisposedProperties',
  /**
   * Queries all properties that can be enriched. Technically this means all properties that are
   * considered active. Which means all properties that are NOT Deleted, Disposed or Superceded.
   */
  EnrichableProperties = 'EnrichableProperties',
}
