import React from 'react';
import moment from 'moment';
import { IGroupedSnapshots, IStreamSnapshot } from '@app/components/Snapshot/types';

const optionLatest = {
  date: '',
  displayDate: '',
  id: 'latest-snapshot',
  label: 'Latest',
  name: 'latest-snapshot',
  shortLabel: 'Latest',
};

interface Props {
  isAdmin?: boolean;
  defaultSnapshot?: string;
  changesSinceSnapshot: IStreamSnapshot;
  currentSnapshot: IStreamSnapshot;
  snapshotType: 'changesSince' | 'current';
  snapshots: Array<IGroupedSnapshots>;
  marketVisibleToggle: boolean;
}

interface Return {
  flatSnapshots: Array<IStreamSnapshot>;
  snapshotOptions: {
    badge?: string;
    description?: string;
    disabled: boolean;
    label: string;
    value: string;
  }[];
}

export default ({
  changesSinceSnapshot,
  defaultSnapshot,
  currentSnapshot,
  isAdmin,
  snapshotType,
  snapshots,
  marketVisibleToggle,
}: Props): Return => {
  const flatSnapshots: Array<IStreamSnapshot> = [];

  // Flatten the snapshots, sort them by date, and add them to the `finalSnapshots` array.
  snapshots
    .map((snapshot) => snapshot.items)
    .forEach((items) => {
      items.forEach((item) => flatSnapshots.push(item));
    });
  flatSnapshots.sort((a, b) => moment(b.date).diff(a.date));

  const snapshotOptions = [];
  let lastBoundVisited = false;
  flatSnapshots.forEach((snapshot) => {
    // For example, if snapshot '2020-07-01' is selected for the "changes since" option, we should
    // not allow the user to select that same snapshot in the "current" option.
    let badge = null;
    // FIX ME
    // @ts-ignore
    if (snapshot.marketVisible && snapshot.bound) {
      badge = 'Bound - Market';
      // FIX ME
      // @ts-ignore
    } else if (snapshot.marketVisible) {
      badge = 'Market';
    } else if (snapshot.bound) {
      badge = 'Bound';
    }

    const item = {
      badge,
      description: snapshot?.description && isAdmin && snapshot.description,
      disabled: false,
      label: snapshot.label,
      value: snapshot.id,
    };
    if (snapshotType === 'current' && changesSinceSnapshot?.id === snapshot.id) {
      item.disabled = true;
    }
    if (snapshotType === 'changesSince' && currentSnapshot?.id === snapshot.id) {
      item.disabled = true;
    }

    if (snapshot.bound && !lastBoundVisited) {
      snapshotOptions.unshift(item);
      lastBoundVisited = true;
    } else {
      snapshotOptions.push(item);
    }
  });

  if (snapshotType === 'current' && !defaultSnapshot && !marketVisibleToggle) {
    flatSnapshots.push(optionLatest);
    snapshotOptions.unshift({
      label: optionLatest.label,
      value: optionLatest.id,
    });
  }

  return {
    flatSnapshots,
    snapshotOptions,
  };
};
