import React, { createContext, useContext, useEffect } from 'react';
import { LazyQueryResult, QueryResult } from '@apollo/client';
import useAxios, { ResponseValues } from 'axios-hooks';
import { chain, Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import { useToast } from 'ui';
import { COPILOT_URL } from '@app/config';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { ProvenanceJobDataQuery } from '@app/graphql/jobs/queries/__generated__/provenanceJobData.generated';
import { useGetOrganizationAttributeMetadataQuery } from '@app/graphql/queries/attributeMetadata/__generated__/getOrganizationAttributeMetadata.generated';
import { PropertiesByAiDsQuery } from '@app/graphql/queries/property/__generated__/propertiesByAiDs.generated';
import { useOrgPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/OrgPropertiesPage.generated';
import { Exact, OrgAttributeMetadata, PropertiesByAiDsInput, Property } from '@app/graphql/types';
import { SourceDocument } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';
import { SOVPropertyData, SOVPropertyVariables } from '@app/queries/properties/types';
import {
  AttributeProvenanceInput,
  CopilotMapping,
  IWorkspace,
  IWorkspaceConfig,
  PropertyReport,
} from '../types';

interface SearchQuery {
  exec: () => void;
  loading: boolean;
}

interface MappedQuery<T> {
  items: Array<T>;
  dict: Dictionary<T>;
  loading: boolean;
}

interface IContext {
  openSource: (source: SourceDocument) => void;
  workspaceData: IWorkspaceConfig;
  partialUpdateWorkspaceSettings: (id: string, payload: Partial<IWorkspace>) => void;
  updateSearchForm: (key: string) => (value: any) => void;

  propertiesQuery?: MappedQuery<Property>;
  attributesQuery?: MappedQuery<OrgAttributeMetadata>;
  attributeQueryMappings: Dictionary<string>;
  sovPropertyAttributes: Dictionary<string>;
  propertyReportsQuery?: ResponseValues<Array<PropertyReport>, any, any>;
  loadingMappings?: boolean;
  submitCorrection: (workspaceID: string, answer: string) => void;
  searchQuery: SearchQuery;

  addToProvenanceJob: (attributeProvenances: Array<AttributeProvenanceInput>) => Promise<void>;
  removeFromProvenanceEnrichment: (attributeName: string) => void;
  submitProvenanceEnrichment: () => void;
  provenanceJobData: ProvenanceJobDataQuery;
  propertiesByAIDsQuery: QueryResult<
    PropertiesByAiDsQuery,
    Exact<{ input: PropertiesByAiDsInput }>
  >;
  sovPropertyQuery: LazyQueryResult<SOVPropertyData, SOVPropertyVariables>;
}

const Context = createContext<IContext>({
  addToProvenanceJob: async (_) => {},
  attributeQueryMappings: {},
  openSource: () => {},
  partialUpdateWorkspaceSettings: (id, payload) => {},
  propertiesByAIDsQuery: null,
  removeFromProvenanceEnrichment: () => {},
  provenanceJobData: null,
  searchQuery: { exec: () => {}, loading: false },
  sovPropertyAttributes: {},
  sovPropertyQuery: null,
  submitCorrection: () => {},
  workspaceData: null,
  submitProvenanceEnrichment: () => {},
  updateSearchForm: (key: string) => (value: string) => {},
});

export const Provider: React.FC<IContext> = ({ children, ...props }) => {
  const { selectedOrganization } = useUserSession();
  const toast = useToast();

  const { data: propertiesPage, loading: loadingProperties } = useOrgPropertiesPageQuery({
    skip: !selectedOrganization,
    variables: {
      input: {
        filter: [],
        limit: 200,
        orgName: selectedOrganization?.name,
      },
    },
  });

  const [{ data: mappings, loading: loadingMappings, error }] = useAxios<Array<CopilotMapping>>({
    method: 'GET',
    url: `${COPILOT_URL}/mappings`,
  });

  const { data: attributeMetadata, loading: loadingMetadata } =
    useGetOrganizationAttributeMetadataQuery({
      skip: !selectedOrganization,
      variables: { organizationId: selectedOrganization?.id },
    });

  useEffect(() => {
    if (!!error) {
      toast({ title: 'Could not get attribute mappings from copilot', type: 'danger' });
    }
  }, [error]);

  const attributeQueryMappings = chain(mappings)
    .keyBy('attributeName')
    .mapValues('question')
    .value();

  const sovPropertyAttributes = chain(props.sovPropertyQuery?.data?.sovProperty?.attributes || [])
    .keyBy('key')
    .mapValues('value')
    .value();

  const properties = propertiesPage?.orgPropertiesPage?.properties as Array<Property>;
  const propertyMap = keyBy(properties, 'archipelagoId');

  const attributes =
    attributeMetadata?.organizationAttributeMetadata as Array<OrgAttributeMetadata>;
  const attributeMap = keyBy(attributes, 'metadata.name');

  const getMapping = (attributeName: string) =>
    attributeQueryMappings[attributeName] ||
    `Describe the ${attributeMap?.[attributeName]?.metadata?.displayName}.
Please provide your final answer in the following form:
“FINAL ANSWER: <your specific answer>” along with an explanation.`;

  const selectedAttribute = props?.workspaceData?.searchFormValues?.attributeName;
  useEffect(() => {
    if (!!selectedAttribute && !!attributeMap && !!attributeQueryMappings) {
      props.updateSearchForm('question')(!!selectedAttribute ? getMapping(selectedAttribute) : '');
    }
  }, [selectedAttribute, JSON.stringify(attributeQueryMappings), JSON.stringify(attributeMap)]);

  return (
    <Context.Provider
      value={{
        ...props,
        attributeQueryMappings,
        attributesQuery: {
          dict: attributeMap,
          items: attributes,
          loading: loadingMetadata,
        },
        loadingMappings,
        propertiesQuery: {
          dict: propertyMap,
          items: properties,
          loading: loadingProperties,
        },
        sovPropertyAttributes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCopilotContext = () => useContext(Context);
