import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_ID,
  DATADOG_SERVICE,
  DATADOG_SITE,
  LOGGING_ENABLED,
  PLATFORM,
} from './config';

if (LOGGING_ENABLED) {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_ID,
    env: PLATFORM,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: DATADOG_SERVICE,
    site: DATADOG_SITE,
  });
  datadogRum.init({
    allowedTracingOrigins: [
      'https://platform.onarchipelago.com',
      'https://staging.onarchipelago.com',
      'https://testing.onarchipelago.com',
    ],
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_ID,
    defaultPrivacyLevel: 'mask-user-input',
    env: PLATFORM,
    service: DATADOG_SERVICE,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: DATADOG_SITE,
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: 'v1',
  });
}

datadogRum.startSessionReplayRecording();

require('./bootstrap');
