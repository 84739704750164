import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
import { useGradingDetailsContext } from '../context';
import { ViewType } from '../types';
import AnnualLossTable from './AnnualLossTable';
import AverageLossHistogram from './AverageLossHistogram';
import AverageLossMap from './AverageLossMap';
import CustomerAvgLoss from './CustomerAvgLoss';
import Stats from './Stats';

const AverageLossPanel: React.FC = () => {
  const { currentAttribute, viewType } = useGradingDetailsContext();
  switch (currentAttribute.value) {
    case 'CUSTOMER_AVERAGE_LOSS':
      return <CustomerAvgLoss />;

    case 'CUSTOMER_LOSS_FREQUENCY':
      return (
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiText color="subdued">
              Includes only Gross Paid Indemnity (Zurich Share) on closed non-zero claims.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <Stats />
                <Spacer />
                {viewType === ViewType.Map ? <AverageLossMap /> : <AverageLossHistogram />}
              </EuiFlexItem>
              <EuiFlexItem>
                <AnnualLossTable />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      );

    default:
      return null;
  }
};

export default AverageLossPanel;
