import { css } from '@emotion/css';

const container = css`
  label: UploadButtonContainer;
  display: flex;
  flex-direction: row;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export default {
  container,
};
