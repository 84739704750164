import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IStream } from '@onarchipelago/cx/Stream/types';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useToast,
} from 'ui';
import Dimmer from '@app/components/Dimmer/Dimmer';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import {
  getUpdateStreamInput,
  IUpdateStreamData,
  IUpdateStreamVariables,
} from '@app/queries/streams/inputs';
import { IUpdateStreamMembershipVariables } from '@app/queries/streams/types';
// FIX ME
// @ts-ignore
import UPDATE_STREAM from '@app/queries/streams/updateStream.gql';
// FIX ME
// @ts-ignore
import UPDATE_STREAM_MEMBERSHIP from '@app/queries/streams/updateStreamMembership.gql';
import TabActionButtons from './Tabs/TabActionButtons';
import Tabs from './Tabs/Tabs';
import styles from './EditStreamModal.emotion';
import StreamEditContext from './StreamEditContext';
import { getDefaultStreamMeta, StreamFormMeta, validate } from './validator';

interface IEditStreamModalAttribute {
  stream: IStream;
  onClose: () => void;
}

const EditStreamModal: React.FC<IEditStreamModalAttribute> = ({ stream, onClose }) => {
  const { account } = useAuth();
  const { selectedOrganization } = useUserSession();
  const { closeModal } = useContext(ModalContext);
  if (!stream || !account) {
    return null;
  }

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const simplifiedStreamSetting =
    !account?.permissions?.admin && permissions?.includes('canManageProperties');

  const firstTab = simplifiedStreamSetting ? 'snapshots' : 'basicInfo';

  const toast = useToast();
  const [copyOfStream, setCopyOfStream] = useState<IStream>(stream);
  const [meta, setMeta] = useState<StreamFormMeta>(getDefaultStreamMeta());
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [openTabId, setOpenTabId] = useState(firstTab);
  const NO_BUTTON_TAB_IDS = ['attributeMetadata', 'sharing', 'snapshots'];
  const onTabChange = (tabId: string) => {
    setOpenTabId(tabId);
  };

  const setFieldError = (field: string, error: string) => {
    const newMeta = cloneDeep(meta);

    set(newMeta, field, { ...get(newMeta, field), error });
    setMeta(newMeta);
  };

  const setFieldValue = (
    field: string,
    value: any,
    secondaryField?: string,
    secondaryValue?: any,
  ) => {
    // Check for differences in touched fields only
    if (field in touchedFields) {
      if (touchedFields[field] === value) {
        const copyOfTouchedFields = touchedFields;
        delete copyOfTouchedFields[field];
        setTouchedFields(copyOfTouchedFields);
      }
    } else {
      setTouchedFields({
        ...touchedFields,
        [field]: value,
      });
    }

    const newValues = cloneDeep(copyOfStream);
    set(newValues, field, value);

    if (secondaryField && secondaryValue) {
      set(newValues, secondaryField, secondaryValue);
    }

    setCopyOfStream(newValues);

    setFieldError(field, validate(field, value));
  };
  const touch = (field: string) => {
    const newMeta = cloneDeep(meta);

    set(newMeta, field, {
      ...get(newMeta, field),
      touched: get(copyOfStream, field) !== get(stream, field),
    });
    setMeta(newMeta);
  };
  const [message, setMessage] = useState<string>('');
  const [updateStream] = useMutation<IUpdateStreamData, IUpdateStreamVariables>(UPDATE_STREAM);
  const [updateStreamMembership] = useMutation<number, IUpdateStreamMembershipVariables>(
    UPDATE_STREAM_MEMBERSHIP,
  );

  const submit = async () => {
    setTouchedFields({});
    let err = false;

    const refetchQueries = [
      {
        query: GET_STREAM,
        variables: {
          isAdmin: account.permissions.admin,
          slug: stream.slug,
          userCode: account.userCode,
        },
      },
    ];

    const { updateMembership } = copyOfStream;
    setSubmitting(true);

    await updateStream({
      refetchQueries: updateMembership ? [] : refetchQueries,
      variables: {
        input: getUpdateStreamInput(copyOfStream, message),
      },
    }).catch(() => {
      err = true;
    });

    if (!err && updateMembership) {
      toast({
        title: 'Successfully updated stream. Refreshing stream membership...',
        type: 'success',
      });
      await updateStreamMembership({
        refetchQueries,
        variables: {
          orgName: stream.orgName,
          streamSlug: stream.slug,
          updateReportingViews: true,
        },
      }).catch(() => {
        err = true;
      });
    }

    setSubmitting(false);
    if (!err) {
      toast({
        title: updateMembership
          ? 'Successfully refreshed stream membership'
          : 'Successfully updated stream',
        type: 'success',
      });
      if (onClose) {
        onClose();
      }
    } else {
      toast({ title: 'An error occurred', type: 'danger' });
    }
  };

  return (
    <StreamEditContext.Provider
      value={{
        copyOfStream,
        initialValues: stream,
        message,
        meta,
        setFieldError,
        setFieldValue,
        setMessage,
        setTouchedFields,
        submit,
        submitting,
        touch,
        touchedFields,
      }}
    >
      <EuiModal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        className={styles.modal}
        onClose={closeModal}
      >
        <EuiModalHeader data-testid="edit-stream-modal">
          <EuiModalHeaderTitle>Edit {STREAMS_LABEL}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody className={styles.modalBody}>
          {submitting && <Dimmer />}
          <Tabs
            simplifiedStreamSetting={simplifiedStreamSetting}
            stream={stream}
            onTabChange={onTabChange}
          />
        </EuiModalBody>
        {!NO_BUTTON_TAB_IDS.includes(openTabId) && (
          <EuiModalFooter>
            <TabActionButtons onClose={onClose} />
          </EuiModalFooter>
        )}
      </EuiModal>
    </StreamEditContext.Provider>
  );
};

export default EditStreamModal;
