import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useUserSession } from '@app/contexts/UserSessionContext';
// FIX ME
// @ts-ignore
import GET_STREAMS from '@app/queries/streams/getStreams.gql';
import { GetStreamsResponse } from '@app/queries/streams/types';

export const PrecheckRoot = () => {
  const { selectedOrganization } = useUserSession();
  const { setOrgByName } = useUserSession();

  const { organizationName } = useParams<{ organizationName: string }>();
  const decodedOrg = decodeURIComponent(organizationName);

  if (!selectedOrganization) {return <></>;}

  if (selectedOrganization.name !== decodedOrg) {
    setOrgByName(decodedOrg);
    return <></>;
  }

  const { loading, data } = useQuery<
    GetStreamsResponse,
    {
      orgName: string | null;
    }
  >(GET_STREAMS, {
    variables: {
      orgName: selectedOrganization.name,
    },
  });

  if (loading) {return null;}

  const stream = data?.streamsV2?.streams.find((stream) => stream?.slug.match(/-my-properties/i));

  return <Redirect to={`/organizations/${organizationName}/precheck/${stream?.slug}`} />;
};
