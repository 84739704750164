import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { EuiFlexGroup, EuiFlexItem, EuiFormControlLayout, TextField, Toggle } from 'ui';

interface Props {
  multiple?: boolean;
  onlyShowSelected: boolean;
  placeholder: string;
  searchText: string;
  searchType?: string;
  selectAll?: () => void;
  selectVisible?: boolean;
  setOnlyShowSelected: (bool: boolean) => void;
  setSearchText: (str: string) => void;
  withProvenance?: boolean;
}

const SearchInput: React.FC<Props> = ({
  multiple,
  onlyShowSelected,
  placeholder,
  searchText,
  searchType,
  selectAll,
  selectVisible,
  setOnlyShowSelected,
  setSearchText,
  withProvenance,
}) => {
  const [input, setInput] = useState(searchText);

  const [,] = useDebounce(
    () => {
      setSearchText(input);
    },
    250,
    [input],
  );

  const fieldTextProps = input ? { clear: { onClick: () => setInput('') } } : {};

  return (
    <EuiFlexGroup data-testid="search-input" direction="column">
      <EuiFlexItem grow={false}>
        <EuiFormControlLayout fullWidth {...fieldTextProps}>
          <TextField
            disabled={onlyShowSelected}
            fullWidth
            iconName="search"
            onChange={(event) => setInput(event.target.value)}
            placeholder={placeholder}
            value={input}
          />
        </EuiFormControlLayout>
      </EuiFlexItem>
      {searchType === 'properties' && multiple && !withProvenance && (
        <EuiFlexItem grow={false}>
          <Toggle
            checked={selectVisible}
            label={`${selectVisible ? 'Deselect' : 'Select'} All Visible`}
            onChange={() => selectAll()}
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false} style={{ marginBottom: '24px' }}>
        {!withProvenance && (
          <Toggle
            checked={onlyShowSelected}
            label="Only show selected"
            onChange={(event) => setOnlyShowSelected(event?.target?.checked)}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default SearchInput;
