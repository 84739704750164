import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import TivBarCharts from '../GeographyAndLosses/TIVBarCharts/TivBarCharts/TivBarCharts';
import TivMap from '../GeographyAndLosses/TIVMap/TivMap';
import NoData from './NoData';

interface IProps {
  data: RiskSummaryChartData[];
  pageState?: any;
  riskSummaryTab?: any;
}

const GeographyHazardsTab: FC<IProps> = ({ data }) => {
  const mapData: RiskSummaryChartData[] = [];
  const countryCharts = data.find((chart) => chart.name === 'TOP_3_COUNTRIES_BY_TIV');
  const stateCharts = data.find((chart) => chart.name === 'TOP_3_STATES_BY_TIV');

  if (data) {
    data.forEach((chart) => {
      if (chart.name === 'HAZARD_GEOGRAPHY') {
        mapData.push(chart);
      }
    });
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          {countryCharts && stateCharts && (
            <TivBarCharts countryCharts={countryCharts} stateCharts={stateCharts} />
          )}
        </EuiFlexItem>
        <EuiFlexItem style={{ borderRight: '1px solid #eee', maxWidth: '.5px' }} aria-hidden />
        <EuiFlexItem>
          {mapData ? (
            <TivMap mapData={mapData} />
          ) : (
            <NoData message="This stream doesn’t have properties exposed to hazards." />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default GeographyHazardsTab;
