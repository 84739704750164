import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';

interface IProps {
  alt: string;
  title: string;
  src: string;
  height: number;
}

const Container = styled.img<{ opacity: number; height: number }>`
  label: Img;
  transition-duration: 0.1s;
  height: ${({ height }) => height || '24'}px;
  opacity: ${({ opacity }) => opacity};
`;

const loadImage = (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
  });

const Img: FC<IProps> = ({ alt, title, src, height }) => {
  const [opacity, setOpacity] = useState<number>(0);

  useEffect(() => {
    let cancel = false;

    // waitForImage
    (async () => {
      await loadImage(src);

      // cancel when code has been cleaned-up
      if (cancel) {
        return;
      }

      setOpacity(1);
    })();

    return () => {
      cancel = true;
    };
  }, []);

  return <Container opacity={opacity} src={src} alt={alt} title={title} height={height} />;
};

export default Img;
