import { SourceDocumentHighlight } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';
import { NavSettings, SourceData } from './types';

export const initNavSettings = (
  document: SourceData,
  highlights: Array<SourceDocumentHighlight>,
): NavSettings => {
  const settings: NavSettings = { currentPageIndex: 0, highlightIndexes: [], pages: [] };

  for (let i = 0; i < document.pageCount; i++) {
    settings.pages.push({ cid: `${document.documentID}-${i + 1}-0-0`, type: 'page' });
  }

  highlights.forEach((highlight) =>
    settings.pages.push({ cid: highlight.cid, text: highlight.text, type: 'highlight' }),
  );

  settings.pages = settings.pages.sort((a, b) => (a.cid < b.cid ? -1 : 1));
  settings.pages.forEach((p, index) => {
    if (p.type === 'highlight') {
      settings.highlightIndexes.push(index);
    }
  });
  settings.currentPageIndex = settings.highlightIndexes[0];
  return settings;
};
