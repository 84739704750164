import React from 'react';
import { Toggle } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_MARKET_VISIBLE_TOGGLE } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { useStreamContext } from '../Stream/StreamProvider';

export const MarketVisibleToggle = () => {
  const { showmarketVisibleToggle, handleSetMarketVisible, marketVisibleToggle } =
    useStreamContext();
  const { selectedOrganization } = useUserSession();

  const enabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_MARKET_VISIBLE_TOGGLE,
  );

  if (!enabled) {return null;}
  if (!showmarketVisibleToggle) {return null;}

  return (
    <Toggle
      checked={marketVisibleToggle}
      label="Market visible view"
      onChange={(e) => {
        handleSetMarketVisible(e.target.checked);
      }}
    />
  );
};
