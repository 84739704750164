import React, { useContext } from 'react';
import {
  Button,
  ButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { useTracker } from '@app/hooks/useTracker';
import { IGraphQLStream } from '@app/queries/streams/types';
import useCancelEdit from './useCancelEdit';

interface Props {
  closeFlyout: () => void;
  refetch?: () => void;
  jobID: string;
  stream: IGraphQLStream;
}

const CancelEditsModal: React.FC<Props> = ({ closeFlyout, refetch, jobID, stream }) => {
  const { closeModal } = useContext(ModalContext);
  const mixpanel = useTracker('mixpanel');
  const { handleCancel, isLoading } = useCancelEdit(
    () => {
      mixpanel.track('Manage Properties - Edit - Upload', {
        jobID,
        stream_id: stream.id,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
      closeFlyout();
      closeModal();
      refetch();
    },
    jobID,
    stream?.slug,
    stream,
  );

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Cancel your edits?</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>
          You will not be able to submit your worksheet one you cancel these edits, but you can
          always start new edits.
        </EuiText>
      </EuiModalBody>
      <EuiModalFooter>
        <ButtonEmpty onClick={closeModal} label="Keep Editing" />
        <Button
          data-testid="m-m-edit-cancel-confirm"
          color="danger"
          fill
          label="Cancel Edits"
          loading={isLoading}
          onClick={handleCancel}
        />
      </EuiModalFooter>
    </EuiModal>
  );
};

export default CancelEditsModal;
