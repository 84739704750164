import { QualityScore } from './types';

export const scores: QualityScore[] = [
  {
    color: '#013B6F',
    detail: 'There is provenance to evidence the accuracy of this value.',
    label: 'Evidenced',
    name: 'Evidenced',
    score: 4,
  },
  {
    color: '#21B59A',
    detail:
      'A clear, acceptable, and specific value is provided which doesn’t require interpretation. An example is construction type “Reinforced Masonry”.',
    label: 'Precise' /* $euiColorSuccess */,
    name: 'Precise',
    score: 3,
  },
  {
    color: '#FCCAA7',
    detail:
      'The value is vague and allows for different interpretations. An example is construction type “Masonry” where more granularity is recommended to improve the value to a precise score.',
    label: 'Ambiguous',
    name: 'Ambiguous',
    score: 2,
  },
  {
    color: '#FF7E62',
    detail: 'A value was present but could not be interpreted or used.',
    label: 'Invalid',
    name: 'Invalid',
    score: 1,
  },
  { color: '#98A2B3', detail: 'No value present.', label: 'Blank', name: 'None', score: 0 },
];

export const attributeMappings = {
  EARTHQUAKE: {
    buildingCondition: 'Building Condition',
    buildingExteriorOpening: 'Building Exterior Opening',
    buildingFootprintClass: 'Building Footprint Class',
    buildingAppendages: 'Ornamentation',
    buildingFoundationType: 'Building Foundation',
    buildingRedundancy: 'Building Redundancy',
    constructionQuality: 'Construction Quality',
    crippleWalls: 'Cripple Walls',
    distanceToNearestBuilding: 'Distance to Nearest Building',
    eqSpecialSystems: 'EQ Special Systems',
    equipmentEarthquakeBracing: 'Equipment Earthquake Bracing',
    foundationToFrameConnection: 'Foundation to Frame Connection',
    interiorPartitions: 'Interior Partitions',
    naturalGasAutomaticShutoff: 'Natural Gas Automatic Shut-off',
    naturalGasPipesSeismicallyBraced: 'Natural Gas Pipe Seismic/Sway Bracing',
    roofStructures: 'Roof Structures',
    shortColumnConcrete: 'Short Column Concrete',
    softStory: 'Soft Story',
    structuralSystemUpgrade: 'Structural System Upgrade',
    torsion: 'Torsion',
    verticalIrregularity: 'Vertical Irregularity',
    wallCladdingSystem: 'Cladding / Wall System',
    waterHeaterBracing: 'Water Heater Bracing',
    yearLastUpgraded: 'Year of Structural Upgrade',
  },
  FIRE: {
    buildingSprinklerType: 'Building Sprinkler Type',
    buildingSprinklered: 'Building Sprinklered',
    percentageSprinklered: 'Percentage Sprinklered',
  },
  FLOOD: {
    backupGeneratorLocation: 'Back-up Generator Location',
    baseFloodElevation: 'Base Flood Elevation',
    contentsVulnerabilityFlood: 'Contents Vulnerability - Flood',
    contentsVulnerabilityVerticalDistributionOfContents:
      'Contents Vulnerability - Vertical Distribution of Contents',
    elevationOfGroundOrBasementLevelMEPEquipment: 'MEP Equipment (Basement or 1st Floor)',
    emergencyFloodProtectionMeasures: 'Emergency Flood Protection Measures',

    firstFloorElevation: 'First Floor Elevation',
    firstFloorHeight: 'First Floor Height',
    floodMissiles: 'Flood Missiles',
    floorSystem: 'Floor System',
    numberOfStoriesBelowGround: 'Number of Stories - Below Ground',
    permanentFloodMeasuresPresent: 'Permanent Flood Protection Measures',
  },
  WIND: {
    constructionQuality: 'Construction Quality',
    contentsVulnerabilityWind: 'Contents Vulnerability - Wind',
    doorAndFrameType: 'Door and Frame Type',
    glassPercentage: 'Glass Percentage',
    glassType: 'Glass Type',
    buildingCondition: 'Building Condition',
    roofCopingAndFlashing: 'Roof Coping And Flashing',
    roofDeckAnchorage: 'Roof Deck Anchorage',
    roofDeckType: 'Roof Deck Material',
    roofDrainageType: 'Roof Drainage System',
    roofEquipmentAnchorage: 'Roof Equipment Anchorage/Bracing',
    roofGeometry: 'Roof Geometry',
    roofHailMitigation: 'Roof Hail Mitigation',
    roofInstallYear: 'Roofing Material Installation Year',
    roofParapets: 'Roof Parapet Anchorage / Bracing',
    roofSolarPanelCoverage: 'Roof Solar Panel Coverage',
    roofSystem: 'Roofing Material',
    roofSystemAnchorage: 'Roof System Anchorage',
    roofingMaterialAnchorage: 'Roofing Material Anchorage',
    surfaceRoughnessWind: 'Surface Roughness Wind',
    wallCladdingSystem: 'Cladding / Wall System',
    windMissiles: 'Wind Missiles',
    windowProtection: 'Window Glazing Protection',
    windowType: 'Window & Frame Type',
  },
};

export const attributeOrder = {
  EARTHQUAKE: [
    'softStory',
    'eqSpecialSystems',
    'wallCladdingSystem',
    'constructionQuality',
    'equipmentEarthquakeBracing',
    'buildingFootprintClass',
    'buildingFoundationType',
    'foundationToFrameConnection',
    'buildingCondition',
    'shortColumnConcrete',
    'interiorPartitions',
    'crippleWalls',
    'structuralUpgradeType',
    'yearLastUpgraded',
    'roofStructures',
    'distanceToNearestBuilding',
    'structuralSystemUpgrade',
    'verticalIrregularity',
    'buildingExteriorOpening',
    'buildingAppendages',
    'buildingRedundancy',
    'waterHeaterBracing',
    'naturalGasAutomaticShutoff',
    'naturalGasPipesSeismicallyBraced',
    'torsion',
  ],
  FIRE: ['buildingSprinklered', 'buildingSprinklerType', 'percentageSprinklered'],
  FLOOD: [
    'numberOfStoriesBelowGround',
    'baseFloodElevation',
    'firstFloorHeight',
    'firstFloorElevation',
    'permanentFloodMeasuresPresent',
    'elevationOfGroundOrBasementLevelMEPEquipment',
    'floodMissiles',
    'emergencyFloodProtectionMeasures',
    'contentsVulnerabilityFlood',
    'contentsVulnerabilityVerticalDistributionOfContents',
    'floorSystem',
    'backupGeneratorLocation',
  ],
  WIND: [
    'roofSystem',
    'roofGeometry',
    'wallCladdingSystem',
    'constructionQuality',
    'roofEquipmentAnchorage',
    'roofInstallYear',
    'windowProtection',
    'yearLastUpgraded',
    'roofHailMitigation',
    'roofSolarPanelCoverage',
    'buildingAppendages',
    'glassPercentage',
    'roofDeckType',
    'roofDeckAnchorage',
    'roofParapets',
    'roofSystemAnchorage',
    'roofingMaterialAnchorage',
    'windowType',
    'roofStructures',
    'buildingCondition',
    'glassType',
    'roofCopingAndFlashing',
    'roofDrainageType',
    'contentsVulnerabilityWind',
    'doorAndFrameType',
    'surfaceRoughnessWind',
    'windMissiles',
  ],
};
