import React, { FC } from 'react';
import { ActionProps } from 'react-querybuilder';
import { ButtonIcon } from 'ui';

const RemoveRuleAction: FC<ActionProps> = ({ handleOnClick }) => (
  <ButtonIcon
    data-testid="query-builder-wrapper-remove-rule-action"
    color="danger"
    iconName="x"
    onClick={handleOnClick}
  />
);

export default RemoveRuleAction;
