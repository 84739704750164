import { FC, ReactElement, useEffect, useState } from 'react';
import useIfMounted from '@app/hooks/useIfMounted';

interface IScroll {
  top: number;
  left: number;
}

export type HandleScroll = (props: IScroll) => void;

interface IChildrenProps {
  handleScroll: HandleScroll;
}

interface IProps {
  children: (props: IChildrenProps) => ReactElement;
  loadMore?: () => void;
  threshold: number | undefined;
  rows: number;
  totalRows: number;
  handleError?: (e: Error) => void;
}

const ScrollContainer: FC<IProps> = ({
  children,
  loadMore,
  threshold,
  rows,
  totalRows,
  handleError,
}) => {
  const [scroll, setScroll] = useState<IScroll | undefined>();
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);
  const ifMounted = useIfMounted();

  if (rows > totalRows) {
    const err = new Error(`${rows} rows were specified but only ${totalRows} were expected`);
    if (handleError) {
      handleError(err);
    } else {
      throw err;
    }
  }

  const checkWhetherToLoadMore = async () => {
    if (fetchingMore === false && loadMore && threshold !== undefined && scroll && totalRows) {
      const { top } = scroll;
      if (top > threshold && !fetchingMore && rows < totalRows) {
        setFetchingMore(true);
        await loadMore();
        ifMounted(() => setFetchingMore(false));
      }
    }
  };

  useEffect(() => {
    if (scroll) {
      checkWhetherToLoadMore();
    }
  }, [rows]);

  const handleScroll: HandleScroll = (e) => {
    ifMounted(() => {
      setScroll(e);
      checkWhetherToLoadMore();
    });
  };

  return children({
    handleScroll,
  });
};

export default ScrollContainer;
