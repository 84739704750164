import keyBy from 'lodash/keyBy';
import { ARCHIPELAGO_ZONE_ID } from '@app/components/PropertiesGrid/GridCell/HazardText';

const groupableAttributes = [
  'region',
  'generatorTestingProgram',
  'buildingFootprintClass',
  'acquiredOrBuilt',
  'city',
  'constructionType',
  'constructionTypeAIR',
  'constructionTypeATC',
  'constructionTypeCluster',
  'constructionTypeISO',
  'constructionTypeRMS',
  'contingency',
  'country',
  'county',
  'enriched',
  'exclusions',
  'fireProtectionInspectionProgram',
  'floodZone',
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_FLOOD_ZONE,
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_SEISMIC_ZONE,
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_WIND_ZONE,
  'floorArea',
  'geoCodeType',
  'hailHazardClass',
  'hasSeismicInspectionReport',
  'numberOfHighPriorityRecommendations',
  'landslideSusceptibility',
  'lightningHazardClass',
  'liquefactionSusceptibility',
  'lossEngineeringReportPresent',
  'propertyManager',
  'multiTenant',
  'numberOfBuildings',
  'numberOfStoriesAboveGround',
  'occupancyType',
  'occupancyTypeATC',
  'originalAddress',
  'owner',
  'portfolio',
  'primaryTenantNAICS',
  'seismicZone',
  'roofInspectionProgram',
  'roofEstimatedReplacementYear',
  'roofSystem',
  'seismicHazardClass',
  'seismicStatus',
  'siteSoilClassification',
  'specificOccupancy',
  'buildingSprinklered',
  'buildingSprinklerType',
  'state',
  'numberOfStoriesBelowGround',
  'streetAddress',
  'structuralSystemUpgraded',
  'tornadoHazardClass',
  'tsunamiHazardClass',
  'wildfireHazardClass',
  'windHazardClass',
  'windZone',
  'yearBuilt',
  'yearLastUpgraded',
  'postalCode',
  'sprinklerLeakDetectionSystem',
  'buildingReplacementCostPercentDifferenceRange',
  'businessContinuityPlans',
  'commodityHazardClass',
  'leasedOrOwned',

  // Note: CNA attributes
  'consumerText1',
  'consumerText2',
  'consumerText3',
  'consumerText4',
  'consumerText5',
  'consumerText6',
  'consumerText7',
  'consumerText8',
  'consumerText9',
  'consumerText10',
  'consumerText11',
  'consumerText12',
  'consumerText13',
  'consumerText14',
  'consumerText15',
  'consumerDecimal1',
  'consumerDecimal2',
  'consumerDecimal3',
  'consumerDecimal4',
  'consumerDecimal5',
  'consumerDecimal6',
  'consumerDecimal7',
  'consumerDecimal8',
  'consumerDecimal9',
  'consumerDecimal10',
  'consumerDecimal11',
  'consumerDecimal12',
  'consumerDecimal13',
  'consumerDecimal14',
  'consumerDecimal15',

  // Note: Owner Attributes
  'ownerText1',
  'ownerText2',
  'ownerText3',
  'ownerText4',
  'ownerText5',
  'ownerText6',
  'ownerText7',
  'ownerText8',
  'ownerText9',
  'ownerText10',
  'ownerText11',
  'ownerText12',
  'ownerText13',
  'ownerText14',
  'ownerText15',
  'ownerText101',
  'ownerText102',
  'ownerText103',
  'ownerText104',
  'ownerText105',
  'ownerText106',
  'ownerText107',
  'ownerText108',
  'ownerText109',
  'ownerText110',
  'ownerText111',
  'ownerText112',
  'ownerText113',
  'ownerText114',
  'ownerText115',
  'ownerText116',
  'ownerText117',
  'ownerText118',
  'ownerText119',
  'ownerText120',
  'ownerText121',
  'ownerText122',
  'ownerText123',
  'ownerText124',
  'ownerText125',
  'ownerText126',
  'ownerText127',
  'ownerText128',
  'ownerText129',
  'ownerText130',
  'ownerDecimal1',
  'ownerDecimal2',
  'ownerDecimal3',
  'ownerDecimal4',
  'ownerDecimal5',
  'ownerDecimal6',
  'ownerDecimal7',
  'ownerDecimal8',
  'ownerDecimal9',
  'ownerDecimal10',
  'ownerDecimal11',
  'ownerDecimal12',
  'ownerDecimal13',
  'ownerDecimal14',
  'ownerDecimal15',
  'ownerDecimal101',
  'ownerDecimal102',
  'ownerDecimal103',
  'ownerDecimal104',
  'ownerDecimal105',
  'ownerDecimal106',
  'ownerDecimal107',
  'ownerDecimal108',
  'ownerDecimal109',
  'ownerDecimal110',
  'ownerDecimal111',
  'ownerDecimal112',
  'ownerDecimal113',
  'ownerDecimal114',
  'ownerDecimal115',
  'ownerDecimal116',
  'ownerDecimal117',
  'ownerDecimal118',
  'ownerDecimal119',
  'ownerDecimal120',
  'ownerDecimal121',
  'ownerDecimal122',
  'ownerDecimal123',
  'ownerDecimal124',
  'ownerDecimal125',
  'ownerDecimal126',
  'ownerDecimal127',
  'ownerDecimal128',
  'ownerDecimal129',
  'ownerDecimal130',

  // organization level attributes
  'organizationLevelOne',
  'organizationLevelTwo',
  'organizationLevelThree',
  'organizationLevelFour',
];

const groupableAttributesMap = keyBy(groupableAttributes);

// TODO - this should come from the Metadata API
export const isGroupable = (attributeName: string): boolean =>
  !!groupableAttributesMap[attributeName];
