import styled from '@emotion/styled';
import { EuiBasicTable } from 'ui';

// FIX ME
// @ts-ignore
export const Table = styled(EuiBasicTable)`
  .euiTableHeaderCell {
    vertical-align: bottom !important;
    white-space: normal !important;
  }
`;
