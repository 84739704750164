import React, { FC, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { ButtonEmpty } from 'ui';

interface IProps {
  handleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  author?: {
    name: string;
    avatar?: string;
  };
  pulse?: boolean;
  open?: boolean;
  step?: undefined | number;
}

const Avatar = styled.img`
  label: StreamTourButtonAvatar;
  height: 24px;
  margin-right: 16px;
`;

const getTourLabel = (open?: boolean, step?: number) => {
  if (open === false && step && step >= 1) {
    return 'Resume Highlights';
  }

  return 'View Highlights';
};

const StreamTourButton: FC<IProps> = ({ handleClick, author, step, open }) => (
  <ButtonEmpty
    onClick={handleClick}
    size="s"
    data-testid="highlights-button"
    label={getTourLabel(open, step)}
  />
);

export default StreamTourButton;
