import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ProvenanceDocument } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { IDocumentLinkForTooltip } from '../types';
import DocumentLink from './DocumentLink';
import ExternalLink from './ExternalLink';

export interface IProps {
  sourceDocuments: null | Array<IDocumentLinkForTooltip>;
  externalSourceURLs: null | Array<string>;
  sourceType?: string;
  handleDocumentDownload: (doc: ProvenanceDocument) => void;
}

const Container = styled.div`
  label: SourceDocumentContainer;
`;

const getDocuments = (sourceDocuments: Array<IDocumentLinkForTooltip> | null) =>
  (sourceDocuments || []).map(({ document }) => document);

const SourceLink: FC<IProps> = ({
  sourceDocuments,
  externalSourceURLs,
  sourceType,
  handleDocumentDownload,
}) => {
  if (sourceDocuments === null && externalSourceURLs === null) {
    return null;
  }

  const documents = getDocuments(sourceDocuments);
  return (
    <Container>
      {documents.map((doc, index) => (
        <DocumentLink
          document={doc}
          key={index}
          type={sourceType}
          handleClick={handleDocumentDownload}
        />
      ))}
      {externalSourceURLs?.map((url, index) => (
        <ExternalLink
          key={index}
          url={url}
          type={sourceType}
          handleClick={handleDocumentDownload}
        />
      ))}
    </Container>
  );
};

export default SourceLink;
