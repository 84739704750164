import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiPanel } from 'ui';

export const PropertyTabContainer = styled(EuiPanel)`
  height: 100%;

  > .euiFlexGroup {
    height: 100%;
  }
`;

export const PropertyTabReportContainer = styled(EuiFlexItem)`
  height: 100%;
  overflow-y: auto;
`;

export const ArchipelagoSearchFormatContainer = styled(EuiFlexGroup)`
  padding-bottom: 16px;
`;
