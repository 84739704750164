import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import {
  FilterLabelStyle,
  OccupancyFilterStyles,
  titleContainerStyles,
} from '../Filters/Filters.emotion';
import { PropertyValuationSortAttribute, TableSortOptionsType } from '../types';
import { useValuationSummaryReportContext } from '../ValuationSummaryReportContext';

const TableSort = ({ labelStyles }) => {
  const { loading, tableSort, setTableSortValue } = useValuationSummaryReportContext();
  const sortOptions = [
    { label: '% Total RCV', value: PropertyValuationSortAttribute.BRCPercentOfTotal },
    { label: '$ RCV', value: PropertyValuationSortAttribute.BRC },
    { label: '% Difference - High to Low', value: TableSortOptionsType.BRCDiffHighLow },
    { label: '% Difference - Low to High', value: TableSortOptionsType.BRCDiffLowHigh },
    { label: '$ Difference - High to Low', value: TableSortOptionsType.BRCCurrencyDiffHighLow },
    { label: '$ Difference - Low to High', value: TableSortOptionsType.BRCCurrencyDiffLowHigh },
  ];

  return (
    <EuiFlexGroup style={titleContainerStyles} alignItems="center" justifyContent="flexStart">
      <EuiText style={{ ...FilterLabelStyle, ...labelStyles }}>Sort By</EuiText>
      <EuiFlexItem style={OccupancyFilterStyles}>
        <Select
          options={sortOptions}
          value={tableSort}
          onChange={(value: TableSortOptionsType) => setTableSortValue(value)}
          loading={loading}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default TableSort;
