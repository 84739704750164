import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { EuiFlexGroup, MultiSelect, MultiSelectOption } from 'ui';
import { getEditRecords } from '@app/components/PropertiesGrid/PropertiesGridTooltip/utils';
import { getProvenanceAsObj } from '@app/components/PropertiesGrid/utils';
import {
  AttributeRow,
  AttributeRowLayoutEnum,
  AttributeRowTypeEnum,
} from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  AttributeDataType,
  CLIENT_ID_KEY,
  CLIENT_ID_LABEL,
  CURRENCY_KEYS,
  DISABLED_MESSAGE,
} from '@app/cx/Stream/AddPropertyFlyout/constants';
import { useAddPropertyFlyoutContext } from '@app/cx/Stream/AddPropertyFlyout/context/AddPropertyFlyout.context';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { AttributeLocks, Property } from '@app/graphql/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { useValidateMPData } from '../hooks/useValidateMPData';
import { DuplicateClientIdCallout } from './DuplicateClientIdCallout';

const convertToLabelValueDataModal = (attribute: IGraphQLAttributeMetadata): MultiSelectOption => ({
  label: attribute.displayName,
  value: attribute.name,
});

const DEFAULT_REQUIRED_KEYS = new Set([...ADDRESS_AND_GEO_INPUT_KEYS, ...CURRENCY_KEYS]);
const HARD_CODED_ADDITIONAL_KEYS = new Set(['locationId']);
export const UNEDITABLE_KEYS = new Set(['geoCodeType', 'buildingReplacementCostPartner']);
const getAdditionalAttributes = (
  attributes: IGraphQLAttributeMetadata[],
  subValueAttributes: string[],
): MultiSelectOption[] =>
  attributes
    .filter(
      (attribute) =>
        !DEFAULT_REQUIRED_KEYS.has(attribute.name) &&
        !defaultAdditionalAttributes.has(attribute.name) &&
        !HARD_CODED_ADDITIONAL_KEYS.has(attribute.name) &&
        !UNEDITABLE_KEYS.has(attribute.name) &&
        // subValues will be set in the required currency fields
        !new Set(subValueAttributes).has(attribute.name) &&
        // filters out all derived keys: these are not editable.
        !attribute.derived,
    )
    .map((attribute) => convertToLabelValueDataModal(attribute));
const defaultAdditionalAttributes = new Set([
  'locationName',
  'specificOccupancy',
  'occupancyDescription',
  'constructionType',
  'constructionDescription',
  'yearBuilt',
  'numberOfStoriesAboveGround',
  'numberOfStoriesBelowGround',
  'floorArea',
  'numberOfBuildings',
  'buildingSprinklered',
  'roofSystem',
  'rmsWindRoofAge',
  'yearLastUpgraded',
  'roofGeometry',
  'roofInstallYear',
  'roofEquipmentAnchorage',
  'windowProtection',
  'wallCladdingSystem',
  'constructionQuality',
  'equipmentEarthquakeBracing',
  'buildingFootprintClass',
  'buildingFoundationType',
]);

export const getAttributeRow = (
  attribute: IGraphQLAttributeMetadata,
  attributeLock?: AttributeLocks,
  property?: Property,
  options?: {
    readOnly: boolean;
    info?: string;
    label?: string;
    layout?: AttributeRowLayoutEnum;
    onChange?: (value: any) => void;
    labelAction: JSX.Element;
  },
) => {
  if (!attribute) {
    return null;
  }

  const layout = options?.layout || AttributeRowLayoutEnum.horizontal;
  const disabled = options?.readOnly
    ? true
    : !!attributeLock?.attributeNames?.includes(attribute.name);

  const disabledContent = disabled ? DISABLED_MESSAGE : undefined;
  const toolTipContent = options?.readOnly ? undefined : disabledContent;

  const { name, displayName } = attribute;

  const label = options?.label || displayName;

  const attributeProvenance = getProvenanceAsObj(property?.attributeProvenance || [])?.[name];
  const editInfo = getEditRecords(property, name);

  const provenance = {
    editInfo,
    provenance: attributeProvenance,
  };

  const key = property ? `${name}-${property?.archipelagoId}` : name;
  if (attribute.enumMetadata && attribute.dataType !== AttributeDataType.Boolean) {
    return (
      <AttributeRow
        key={key}
        id={name}
        label={label}
        inputProps={{
          fullWidth: true,
          disabled,
          onChange: options?.onChange,
          options: attribute.enumMetadata.map((option) => ({
            label: option.value,
            value: option.value,
          })),
        }}
        type={AttributeRowTypeEnum.select}
        tooltipContent={toolTipContent}
        provenance={provenance}
        info={options?.info}
        layout={layout}
        labelAction={options?.labelAction}
      />
    );
  }
  switch (attribute.dataType) {
    case AttributeDataType.Default:
    case AttributeDataType.HazardText:
    case AttributeDataType.WebLink:
    case AttributeDataType.Description:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.text}
          inputProps={{ fullWidth: true, disabled, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.Year:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.year}
          inputProps={{ disabled, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.Integer:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.integer}
          inputProps={{ fullWidth: true, disabled, min: 0, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.Currency:
    case AttributeDataType.CurrencyLocal:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.currency}
          inputProps={{ disabled, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.Number:
    case AttributeDataType.Percentage:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.number}
          inputProps={{
            fullWidth: true,
            disabled,
            min: attribute?.parent === 'geoCodeType' ? null : 0,
            onChange: options?.onChange,
          }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );

    case AttributeDataType.MeasurementDistanceLarge:
    case AttributeDataType.MeasurementDistanceSmall:
    case AttributeDataType.MeasurementArea:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.number}
          inputProps={{
            fullWidth: true,
            append: 'ft',
            disabled,
            min: 0,
            onChange: options?.onChange,
          }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.Boolean:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          inputProps={{
            fullWidth: true,
            disabled,
            onChange: options?.onChange,
          }}
          type={AttributeRowTypeEnum.boolean}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
    case AttributeDataType.SecondaryModifier:
    case AttributeDataType.Construction:
    case AttributeDataType.Occupancy:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.select}
          inputProps={{ fullWidth: true, disabled, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );

    case AttributeDataType.Date:
      return (
        <AttributeRow
          key={key}
          id={name}
          label={label}
          type={AttributeRowTypeEnum.date}
          inputProps={{ disabled, onChange: options?.onChange }}
          tooltipContent={toolTipContent}
          provenance={provenance}
          info={options?.info}
          layout={layout}
          labelAction={options?.labelAction}
        />
      );
  }
  return (
    <AttributeRow
      key={key}
      id={name}
      label={label}
      type={AttributeRowTypeEnum.text}
      inputProps={{ fullWidth: true, disabled, onChange: options?.onChange }}
      tooltipContent={toolTipContent}
      provenance={provenance}
      info={options?.info}
      layout={layout}
      labelAction={options?.labelAction}
    />
  );
};

export const AdditionalAttributes = () => {
  const { propertyAttributeMetadata } = useStreamContext();
  const {
    selectedAttributes,
    setSelectedAttributes,
    isClientIdConfirmed,
    isClientIdDuplicate,
    setIsClientIdConfirmed,
    setIsClientIdDuplicate,
    clientIdState,
    setClientIdState,
    subValueAttributes,
    property,
    propertyAttributeLocks,
  } = useAddPropertyFlyoutContext();

  const additionalAttributes = getAdditionalAttributes(
    propertyAttributeMetadata,
    subValueAttributes,
  );

  const { watch, formState } = useFormContext();
  const { defaultValues, dirtyFields } = formState;
  const clientIdValue: string = watch(CLIENT_ID_KEY);

  const { debounceValidateMPData, clientIdWarning } = useValidateMPData();

  const getInputComponent = (option: MultiSelectOption) => {
    const attribute = propertyAttributeMetadata.find((attr) => attr.name === option.value);
    return getAttributeRow(attribute, propertyAttributeLocks, property);
  };

  useEffect(() => {
    if (
      clientIdValue &&
      (clientIdValue !== clientIdState || !isClientIdConfirmed) &&
      defaultValues.locationId !== clientIdValue &&
      dirtyFields.locationId
    ) {
      debounceValidateMPData(clientIdValue);
      setClientIdState(clientIdValue);
    } else if (!clientIdValue) {
      setClientIdState(undefined);
      setIsClientIdDuplicate(false);
    }
  }, [clientIdValue]);

  useEffect(() => {
    if (clientIdWarning) {
      setIsClientIdDuplicate(true);
      setIsClientIdConfirmed(false);
    } else {
      setIsClientIdDuplicate(false);
    }
  }, [clientIdWarning]);

  const isClientIdDisabled = !!propertyAttributeLocks?.attributeNames?.includes(CLIENT_ID_KEY);
  const clientIdTooltipContent = isClientIdDisabled ? DISABLED_MESSAGE : undefined;

  return (
    <EuiFlexGroup direction="column">
      <MultiSelect
        initialSelected={selectedAttributes}
        options={additionalAttributes}
        onChange={(selected) => {
          setSelectedAttributes(selected);
        }}
        placeholder="Add additional attributes"
        fullWidth
      />
      {selectedAttributes.map((attribute) => getInputComponent(attribute))}
      <AttributeRow
        id={CLIENT_ID_KEY}
        label={CLIENT_ID_LABEL}
        type={AttributeRowTypeEnum.text}
        inputProps={{ disabled: isClientIdDisabled }}
        tooltipContent={clientIdTooltipContent}
      />
      {isClientIdDuplicate && !isClientIdConfirmed && (
        <DuplicateClientIdCallout
          message={clientIdWarning?.message}
          onClick={() => setIsClientIdConfirmed(true)}
        />
      )}
      {propertyAttributeMetadata
        .filter((attribute) => defaultAdditionalAttributes.has(attribute.name))
        .map((attribute) => getInputComponent(convertToLabelValueDataModal(attribute)))}
    </EuiFlexGroup>
  );
};
