import React, { FC } from 'react';
import BenchmarkChart from '@onarchipelago/cx/Reports/Charts/BenchmarkChart/BenchmarkChart';
import { EnrichmentOverallQualityChangeData } from '@onarchipelago/cx/Reports/queries/getEnrichmentOverallQualityChange';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import Stat from '../../ReadinessReport/Stat';
import { formatScore, getNumberWithOrdinal } from '../../ReadinessReport/utils';
import { BenchmarkChartContainer, BenchmarkSubtitle, BenchmarkTitle } from './Benchmark.emotion';

interface Props {
  enrichmentOverallQualityChangeData: EnrichmentOverallQualityChangeData['enrichmentOverallQualityChange'];
}

const Benchmark: FC<Props> = ({ enrichmentOverallQualityChangeData }) => {
  const benchmarkData = {
    snapshotScore: enrichmentOverallQualityChangeData.snapshotScoreAfter,
    totalStreamCount: enrichmentOverallQualityChangeData.totalStreamCount,
    unenriched: enrichmentOverallQualityChangeData.streamCount,
  };

  return (
    <EuiFlexItem data-testid="enrichment-report-benchmark" grow={1}>
      <EuiFlexGroup direction="column" justifyContent="flexStart">
        <EuiFlexItem grow={false}>
          <EuiText>
            <h3>{STREAMS_LABEL} Enrichment Summary</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Stat
                data-testid="benchmark-dqs-before"
                color="subdued"
                size="large"
                title="Data Quality Before"
                value={formatScore(enrichmentOverallQualityChangeData.snapshotScoreBefore)}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <Stat
                data-testid="benchmark-dqs-after"
                size="large"
                title="Data Quality After"
                value={formatScore(enrichmentOverallQualityChangeData.snapshotScoreAfter)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <BenchmarkTitle grow={false}>
              <EuiText>
                <h6>Benchmark</h6>
              </EuiText>
            </BenchmarkTitle>
            <BenchmarkSubtitle grow={false}>
              <EuiText color="subdued">
                <p data-testid="benchmark-percentile-subtitle">
                  You&apos;re now in the{' '}
                  <strong>
                    {`${getNumberWithOrdinal(
                      enrichmentOverallQualityChangeData.snapshotPercentile,
                    )}`}{' '}
                    percentile
                  </strong>{' '}
                  on Archipelago.
                </p>
              </EuiText>
            </BenchmarkSubtitle>
          </EuiFlexGroup>
        </EuiFlexItem>
        <BenchmarkChartContainer grow={false}>
          <BenchmarkChart data={benchmarkData} showKey={false} />
        </BenchmarkChartContainer>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};

export default Benchmark;
