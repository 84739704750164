"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.panelShadowStyles = void 0;
var react_1 = require("@emotion/react");
exports.panelShadowStyles = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),\n    0 1px 5px -2px rgba(152, 162, 179, 0.3);\n  border: 1px solid #e0e6eb;\n"], ["\n  box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),\n    0 1px 5px -2px rgba(152, 162, 179, 0.3);\n  border: 1px solid #e0e6eb;\n"])));
var templateObject_1;
