import { useContext, useEffect, useState } from 'react';
import StreamEditContext from '@onarchipelago/cx/Stream/EditModal/StreamEditContext';
import { getIndustryEnumData } from '@app/cx/utils/utils';

interface Return {
  businessOptions: Array<{ label: string; value: string }>;
  handleIndustryChange: (industry?: string) => void;
  industryOptions: Array<{ label: string; value: string }>;
  loading: boolean;
}

export default (): Return => {
  const { copyOfStream, setFieldValue } = useContext(StreamEditContext);

  const [availableBusinessTypes, setAvailableBusinessTypes] = useState([
    copyOfStream?.businessType,
  ]);

  const [orgEnumData, setOrgEnumData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enumDataLoading, setEnumDataLoading] = useState(true);
  const fetchData = () =>
    getIndustryEnumData().then((data) => {
      setOrgEnumData(data);
      setEnumDataLoading(false);
    });

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  useEffect(
    () => setAvailableBusinessTypes(getBusinessTypes(copyOfStream?.industry)),
    [enumDataLoading],
  );

  const industryOptions = orgEnumData?.map((industry: any) => ({
    label: industry.value,
    value: industry.value,
  }));

  const getBusinessTypes = (industry: string | undefined) => {
    const industryBusinesses = orgEnumData?.filter(
      (industryItem: any) => industryItem.value === industry,
    );

    return industryBusinesses[0]?.list;
  };

  const handleIndustryChange = (industry?: string) => {
    setFieldValue('industry', industry);
    industry !== null && setAvailableBusinessTypes(getBusinessTypes(industry));
  };

  const businessOptions =
    availableBusinessTypes?.map((businessType) => ({
      label: businessType,
      value: businessType,
    })) || [];

  return {
    businessOptions,
    handleIndustryChange,
    industryOptions,
    loading,
  };
};
