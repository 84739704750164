import React from 'react';
import { Button, Checkbox, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiTextArea } from 'ui';
import { useCopilotContext } from '../../context/context';
import { QueryContainer } from '../../Copilot.emotion';
import { AttributeSelect } from '../PropertyTab/AttributeSelect';
import { PropertySelect } from '../PropertyTab/PropertySelect';
import { Container } from './QueryTab.emotion';

export const QueryTab: React.FC = () => {
  const {
    workspaceData: {
      searchFormValues: {
        propertyArchipelagoID,
        attributeName,
        question,
        attributesWithMappingsOnly,
      },
    },
    updateSearchForm,
    searchQuery: { exec, loading },
  } = useCopilotContext();

  const setQuestion = (v: string) => {
    updateSearchForm('question')(v);
  };

  return (
    <Container>
      <EuiForm fullWidth>
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Property" fullWidth>
                  <PropertySelect
                    value={propertyArchipelagoID}
                    handleChange={updateSearchForm('propertyArchipelagoID')}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Attribute" fullWidth>
                  <AttributeSelect />
                </EuiFormRow>
                <Checkbox
                  checked={attributesWithMappingsOnly}
                  label="With mappings only"
                  id="mappings-filter"
                  onChange={(e) => {
                    updateSearchForm('attributesWithMappingsOnly')(e.target.checked);
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <QueryContainer>
            <EuiFormRow label="Query" fullWidth display="row">
              <EuiTextArea
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                compressed={false}
              />
            </EuiFormRow>
          </QueryContainer>
          <EuiFlexItem grow={false}>
            <Button
              loading={loading}
              onClick={() => {
                exec();
              }}
              label="Query"
              disabled={!propertyArchipelagoID || !attributeName}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </Container>
  );
};
