import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubmitTasksMutationVariables = Types.Exact<{
  input: Types.SubmitTasksInput;
}>;


export type SubmitTasksMutation = { __typename?: 'Mutation', submitTasks: { __typename?: 'SubmitTasksResponse', updateCount: number, message: string } };


export const SubmitTasksDocument = gql`
    mutation submitTasks($input: SubmitTasksInput!) {
  submitTasks(input: $input) {
    updateCount
    message
  }
}
    `;
export type SubmitTasksMutationFn = Apollo.MutationFunction<SubmitTasksMutation, SubmitTasksMutationVariables>;

/**
 * __useSubmitTasksMutation__
 *
 * To run a mutation, you first call `useSubmitTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTasksMutation, { data, loading, error }] = useSubmitTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitTasksMutation(baseOptions?: Apollo.MutationHookOptions<SubmitTasksMutation, SubmitTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitTasksMutation, SubmitTasksMutationVariables>(SubmitTasksDocument, options);
      }
export type SubmitTasksMutationHookResult = ReturnType<typeof useSubmitTasksMutation>;
export type SubmitTasksMutationResult = Apollo.MutationResult<SubmitTasksMutation>;
export type SubmitTasksMutationOptions = Apollo.BaseMutationOptions<SubmitTasksMutation, SubmitTasksMutationVariables>;