"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.overrides = void 0;
var dataViz_1 = require("../colors/dataViz");
var eui_1 = require("../colors/eui");
exports.overrides = {
    font: {
        body: {
            scale: 'm', // default for amsterdam theme is 's' (base font size of 14px, we want 16px)
        },
        family: 'Matter, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        title: {
            weight: 'semiBold', // overriding default weight of 'bold'
        },
    },
    colors: {
        LIGHT: __assign({ dataViz: dataViz_1.dataViz }, eui_1.core),
    },
    levels: {
        flyout: 6000, // need to allow popovers in flyout to be visible above mask
    },
};
