import { getAttributeRow } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { AttributeRowLayoutEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import { Property } from '@app/graphql/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ButtonIcon,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  TextField,
} from 'ui';
import { useCopilotContext } from '../../context/context';
import { Feedback } from '../../types';
import { SourceList } from './SourceList';
import { AttributeTableRow } from './types';

interface Props {
  attribute: AttributeTableRow;
  onRating: (attributeName: string, rate: Feedback, finalAnswer: string) => void;
}
export const PropertyDetails: React.FC<Props> = ({ attribute, onRating }) => {
  const { addToProvenanceJob, removeFromProvenanceEnrichment, provenanceJobData, attributesQuery } =
    useCopilotContext();
  const { rating, explanation, sources, copilotValue, suggestedValue, finalValue } = attribute;

  const formMethods = useForm();
  const getValue = () => formMethods.getValues()?.[attribute.name];

  const reportID = sources?.[0]?.document_id;
  let inJob = false;

  const updateFinalValue = () => {
    onRating(attribute.id, rating, getValue());
  };

  const resetFinalValue = () => {
    onRating(attribute.id, rating, suggestedValue);
  };

  const property = provenanceJobData?.provenanceJobData?.properties?.[0];

  useEffect(() => {
    if (!!formMethods) {
      formMethods.setValue(attribute.name, finalValue);
    }
  }, [finalValue, !!formMethods]);

  const renderEnrichmentButton = () => {
    const eigenAttributes = {};
    property?.attributeComments?.forEach((comment) => {
      if (comment?.comments?.find((c) => c.message.toLowerCase().includes('eigen'))) {
        eigenAttributes[comment.attributeName] = true;
      }
    });

    property?.property?.attributeProvenance
      ?.filter((p) => p.attributeName === attribute.name)
      ?.forEach((p) => {
        const docID = p.sourceDocuments?.[0]?.document?.id;
        if (docID === reportID && !!eigenAttributes[p.attributeName]) {
          inJob = true;
        }
      });

    if (!inJob) {
      return (
        <Button
          label="Add to Job"
          iconName="plusCircle"
          onClick={() =>
            addToProvenanceJob([
              {
                attributeName: attribute.name,
                documentID: attribute.sources?.[0]?.document_id,
                value: getValue(),
              },
            ])
          }
        />
      );
    }

    return (
      <Button
        label="Remove from Job"
        iconName="minusCircle"
        onClick={() => removeFromProvenanceEnrichment(attribute.name)}
      />
    );
  };

  const listItems = [
    {
      description: explanation,
      title: 'Explanation',
    },
    {
      description: (
        <FormProvider {...formMethods}>
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false}>
                  <ButtonIcon
                    iconName={'thumbsUp'}
                    border={rating === 'good'}
                    onClick={() =>
                      onRating(attribute.id, rating === 'good' ? null : 'good', finalValue)
                    }
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ButtonIcon
                    iconName="thumbsDown"
                    color="danger"
                    border={rating === 'bad'}
                    onClick={() =>
                      onRating(attribute.id, rating === 'bad' ? null : 'bad', finalValue)
                    }
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Button
                    label="Mark as Unreviewed"
                    onClick={() =>
                      onRating(
                        attribute.id,
                        rating === 'unreviewed' ? null : 'unreviewed',
                        finalValue,
                      )
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="Original Value">
                <TextField value={copilotValue} disabled />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="Suggested Value">
                <TextField value={suggestedValue} disabled />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {getAttributeRow(
                attributesQuery.dict?.[attribute.name]?.metadata as IGraphQLAttributeMetadata,
                null,
                property?.property as Property,
                {
                  layout: AttributeRowLayoutEnum.vertical,
                  readOnly: false,
                  label: 'Final Value',
                  labelAction: null,
                },
              )}
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiFormRow hasEmptyLabelSpace>
                    <Button label="Update" iconName="edit" onClick={updateFinalValue} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow hasEmptyLabelSpace>
                    <Button
                      label="Reset"
                      iconName="clock"
                      color="secondary"
                      onClick={resetFinalValue}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow hasEmptyLabelSpace>{renderEnrichmentButton()}</EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            {sources?.filter((s) => s.sources?.length > 0)?.length > 0 && (
              <EuiFlexItem>
                <SourceList sources={sources} />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </FormProvider>
      ),
      title: 'Feedback',
    },
  ];

  return <EuiDescriptionList listItems={listItems} />;
};
