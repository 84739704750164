import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { NavigationType, UploadDate } from '../../types/commonTypes';
import { DocumentsContext, Filter } from '../../types/contextTypes';
import { QueryFilter } from '../../types/queryTypes';
import { removeQueryFilter } from '../../utils/utils';
import getDocumentTypeOptions from './getDocumentTypeOptions';
import getUploadDateOptions from './getUploadDateOptions';

// Summary: This hook does the following:
//  1. Provide the state for the document type filter
//  2. Provide the state for the property filter
//  3. Provide the state for options for the property filter
//  4. Provide the state for the upload date filter
//  5. Provide the state for the query filters that should be applied to the api query.
//  6. Provide the state for when a user enters text into the search box.
//  7. We need to set a default filter so that when a user switches tabs, the first filter
//  for each tab is already selected for them.
//    a. This means for `documentTypeOptions`, we have to wait until we fetch the
//    classifications data, and then we can declare what the default document type should be.
//    b. This means for `propertyOptions`, when the property data is fetched for the first time when
//    a user selects the property filter tab, we should declare the default property filter and
//    update the `queryFilters` so that the api for the documents table can be refetched.
//  8. Update the `queryFilter` if:
//    a. Update the `queryFilters` if a user changes filters while staying on the same tab.
//    b. Update the `queryFilters` if a user changes tabs (this applied filter will be the default
//    filter mentioned in #7 above).
//  9. If the search is updated, update the `queryFilter` as well.
//  10. When we get the initial document type options, update the state for the final `documentTypeOptions`
//  11. If the filter search is on the document tab, use the entered text value to filter the document type options and
//  update the final `documentTypeOptions` state, to provide a reduced list.

export default (
  classifications: DocumentsContext['data']['classifications'],
  navigation: DocumentsContext['navigation'],
): DocumentsContext['filters'] => {
  const uploadDateOptions = useMemo(() => getUploadDateOptions(), []);
  const initialDocumentTypeOptions = useMemo(
    () => getDocumentTypeOptions(classifications),
    [classifications],
  );

  const { currentTab } = navigation;

  const [documentType, setDocumentType] = useState<Filter | null>(null); // *1
  const [documentTypeOptions, setDocumentTypeOptions] = useState<Filter[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>(null);
  const [propertyFilterSearch, setPropertyFilterSearch] = useState<string>(null);
  const [property, setProperty] = useState<Filter | null>(null); // *2
  const [propertyOptions, setPropertyOptions] = useState<Filter[]>([]); // *3
  const [uploadDate, setUploadDate] = useState<Filter | null>(uploadDateOptions[0]); // *4, *7
  const [queryFilters, setQueryFilters] = useState<QueryFilter[]>([]); // *5
  const [propertyQueryFilters, setPropertyQueryFilters] = useState<QueryFilter[]>([]);
  const [search, setSearch] = useState<string | null>(null); // *6

  const previousCurrentTab = usePrevious<NavigationType>(currentTab);
  const previousDocumentType = usePrevious<Filter | null>(documentType);
  const previousFilterSearch = usePrevious<string>(filterSearch);
  const previousPropertySearch = usePrevious<string>(propertyFilterSearch);
  const previousProperty = usePrevious<Filter | null>(property);
  const previousUploadDate = usePrevious<Filter | null>(uploadDate);

  // *7a
  useEffect(() => {
    if (documentTypeOptions?.length > 0 && !documentType) {
      setDocumentType(documentTypeOptions[0]);
    }
  }, [documentType, documentTypeOptions]);

  // *7b
  useEffect(() => {
    if (propertyOptions.length > 0 && !property) {
      setProperty(propertyOptions[0]);
      setQueryFilters([
        {
          name: 'archipelagoId',
          operator: 'EQUAL',
          values: [propertyOptions[0].id],
        },
      ]);
    }
  }, [property, propertyOptions]);

  useEffect(() => {
    if (
      (documentType && previousDocumentType && currentTab === NavigationType.DOCUMENT_TYPE) || // *8a
      (documentType && // *8b
        currentTab === NavigationType.DOCUMENT_TYPE &&
        previousCurrentTab !== NavigationType.DOCUMENT_TYPE)
    ) {
      setQueryFilters([
        {
          name: 'classification',
          operator: 'EQUAL',
          values: [documentType.id],
        },
      ]);
    }
  }, [currentTab, documentType, previousCurrentTab, previousDocumentType]);

  // *7a
  useEffect(() => {
    if (
      (property && previousProperty && currentTab === NavigationType.PROPERTY) || // *8a
      (property && // *8b
        currentTab === NavigationType.PROPERTY &&
        previousCurrentTab !== NavigationType.PROPERTY)
    ) {
      setQueryFilters([
        {
          name: 'archipelagoId',
          operator: 'EQUAL',
          values: [property.id],
        },
      ]);
    }
  }, [currentTab, previousCurrentTab, property, previousProperty]);

  useEffect(() => {
    if (
      (uploadDate && previousUploadDate && currentTab === NavigationType.UPLOAD_DATE) || // *8a
      (uploadDate && // *8b
        currentTab === NavigationType.UPLOAD_DATE &&
        previousCurrentTab !== NavigationType.UPLOAD_DATE)
    ) {
      if (uploadDate.id === UploadDate.ALL) {
        setQueryFilters([]);
      } else if (uploadDate.id === UploadDate.LAST_MONTH) {
        setQueryFilters([
          {
            name: 'createdAt',
            operator: 'GREATER_OR_EQUAL',
            values: [uploadDate.meta?.startDate],
          },
          {
            name: 'createdAt',
            operator: 'LESS',
            values: [uploadDate.meta?.endDate],
          },
        ]);
      } else {
        setQueryFilters([
          {
            name: 'createdAt',
            operator: 'GREATER_OR_EQUAL',
            values: [uploadDate.meta?.startDate],
          },
        ]);
      }
    }
  }, [currentTab, previousCurrentTab, previousUploadDate, uploadDate]);

  // *9
  useEffect(() => {
    if (search) {
      setQueryFilters((prevState) => {
        const prevStateFinal = removeQueryFilter(prevState, 'filename');
        return prevStateFinal?.concat({
          name: 'filename',
          operator: 'CONTAINS',
          values: [search],
        });
      });
    }
    if (!search) {
      setQueryFilters((prevState) => removeQueryFilter(prevState, 'filename'));
    }
  }, [search]);

  // *10
  useEffect(() => {
    if (
      initialDocumentTypeOptions?.length > 0 &&
      documentTypeOptions?.length === 0 &&
      !filterSearch
    ) {
      setDocumentTypeOptions(initialDocumentTypeOptions);
    }
  }, [initialDocumentTypeOptions, documentTypeOptions]);

  // *11
  useEffect(() => {
    if (
      currentTab === NavigationType.DOCUMENT_TYPE &&
      initialDocumentTypeOptions?.length > 0 &&
      filterSearch !== previousFilterSearch
    ) {
      const filteredDocumentTypeOptions = initialDocumentTypeOptions.filter((option) =>
        option.headerLabel.toLowerCase().includes(filterSearch),
      );
      setDocumentTypeOptions(filteredDocumentTypeOptions);
    }
  }, [currentTab, initialDocumentTypeOptions, filterSearch, previousFilterSearch]);

  useEffect(() => {
    if (currentTab === NavigationType.PROPERTY && propertyFilterSearch !== previousPropertySearch) {
      setPropertyQueryFilters([]);

      setPropertyQueryFilters([
        {
          name: 'locationName',
          operator: 'CONTAINS',
          values: [propertyFilterSearch],
        },
      ]);
    }
  }, [currentTab, initialDocumentTypeOptions, propertyFilterSearch, previousPropertySearch]);

  return {
    documentSearchFilter: {
      setState: setFilterSearch,
      state: filterSearch,
    },
    documentSearchText: {
      setState: setSearch,
      state: search,
    },
    documentType: {
      options: documentTypeOptions,
      setState: setDocumentType,
      state: documentType,
    },
    documentsQuery: {
      setState: setQueryFilters,
      state: queryFilters,
    },
    property: {
      options: propertyOptions,
      setOptions: setPropertyOptions,
      setState: setProperty,
      state: property,
    },
    propertyQuery: {
      setState: setPropertyQueryFilters,
      state: propertyQueryFilters,
    },
    propertySearchFilter: {
      setState: setPropertyFilterSearch,
      state: propertyFilterSearch,
    },
    uploadDate: {
      options: uploadDateOptions,
      setState: setUploadDate,
      state: uploadDate,
    },
  };
};
