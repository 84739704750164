import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'ui';
import Currency from '@app/components/Currency/Currency';
import { Val } from '@app/utils/format';

type LabelAlignment = 'center' | 'left' | 'right';
type Type = 'tooltip' | 'default' | 'years';

export interface IProps {
  label: string | JSX.Element;
  labelAlignment?: LabelAlignment;
  data: string | JSX.Element;
  dataSubtitle?: string;
  change?: {
    amount?: number;
    label?: string;
    since?: string;
  };
  footer?: string;
  dataFormat?: 'currency';
  type?: Type;
  changeAnalysis: boolean;
  years?: string;
}

interface ContentProps {
  type?: Type;
}

const Content = styled.div<ContentProps>`
  label: TextContent;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.005em;
  text-align: center;
  line-height: 156.61%;
  color: #333;
  min-height: 40px;

  small {
    margin-left: 4px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.005em;

    color: #828282;
  }

  ${({ type }) =>
    type === 'tooltip'
      ? `
    margin-bottom: -4px;
    font-size: 18px;
  `
      : ''}
`;

interface IChangeProps {
  type?: Type;
  hasYears?: boolean;
}

const Change = styled.div<IChangeProps>`
  label: TextChange;
  height: 20px;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  font-size: 12px;
  flex-basis: auto;
  line-height: 156.61%;
  /* identical to box height, or 19px */
  letter-spacing: 0.005em;
  margin-top: -6px;

  /* Green 1 */
  color: #828282;

  ${({ type }) =>
    type === 'tooltip'
      ? `
    flex: 1;
    font-size: 12px;
  `
      : ''}
  years && align-items: center;
  justify-content: center;
`;

interface IAmountProps {
  amount: number;
}
const Amount = styled.div<IAmountProps>`
  label: TextChangeAmount;
  margin-right: 4px;
  display: flex;
  flex-basis: auto;
  flex-direction: row;

  ${({ amount }) => {
    if (amount > 0) {
      return `
        color: #219653;

        svg {
          transform: rotate(-45deg);
        }
      `;
    }

    if (amount < 0) {
      return `
        color: #EB5757;

        svg {
          transform: rotate(45deg);
        }
      `;
    }

    return '';
  }}
`;

interface ILabelProps {
  labelAlignment?: LabelAlignment;
  type?: Type;
}

const Label = styled.label<ILabelProps>`
  label: TextContentLabel;
  justify-content: ${({ labelAlignment }) => labelAlignment || 'center'};
  ${({ type }) =>
    type === 'tooltip'
      ? `
    font-size: 10px;
  `
      : ''}
`;

const Footer = styled.label`
  label: TextContentFooter;
  position: relative;
  font-size: 10px;
`;

const TextContent: FC<IProps> = ({
  label,
  labelAlignment,
  data,
  dataSubtitle,
  change,
  footer,
  dataFormat,
  type,
  changeAnalysis,
  years,
}) => {
  const title = useMemo(() => {
    if (!change?.since) {
      return 'Since last bound';
    }
    if (changeAnalysis) {
      return `Since ${change.since}`;
    }
    return `Since previous binding - ${change.since}`;
  }, [change?.since, !!changeAnalysis]);

  const hasYears = years !== undefined;
  return (
    <>
      <Label data-testid="text-content-label" labelAlignment={labelAlignment} type={type}>
        {label}
      </Label>
      <Content data-testid="text-content-content" type={type}>
        {dataFormat === 'currency' ? <Currency value={data as Val} /> : data}
        {dataSubtitle && <small>{dataSubtitle}</small>}
      </Content>
      <Change data-testid="text-content-change" type={type} hasYears={hasYears}>
        {change?.amount !== undefined ? (
          <>
            <Amount amount={change.amount} title={title}>
              {change?.amount !== null && <Icon name="arrowRight" size="l" />}
              {(change.amount * 100).toFixed(2)}%
            </Amount>
            {change.label !== undefined && <>{change.label}</>}
          </>
        ) : (
          <>{change?.label !== undefined && <>{change.label}</>}</>
        )}
        {years && <p>{years}</p>}
      </Change>
      {footer && <Footer>{footer}</Footer>}
    </>
  );
};

export default TextContent;
