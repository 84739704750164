import React from 'react';
import { EuiBasicTable, EuiBasicTableColumn, EuiConfirmModal, Spacer } from 'ui';
import { MetadataMap } from '@app/cx/Stream/StreamProvider';
import { IGraphQLProperty } from '@app/queries/properties/types';

interface IProps {
  metadataMap: MetadataMap;
  closeModal: () => void;
  selectedProperties: IGraphQLProperty[];
  handleStart: (propertyIDs: IGraphQLProperty[]) => void;
  loading: boolean;
  formatTitle: (multiple: boolean) => string;
  confirmButtonLabel: string;
  footNote: string;
}

const attributeNames = ['locationId', 'archipelagoId', 'locationName', 'streetAddress'];

const getColumns = (metadataMap: MetadataMap): Array<EuiBasicTableColumn<IGraphQLProperty>> =>
  attributeNames
    .filter((attr) => attr in metadataMap)
    .map((attr) => ({
      field: attr,
      name: metadataMap[attr].displayName,
    }));

const RestoreDeletedPropertyModal: React.FC<IProps> = ({
  metadataMap,
  closeModal,
  selectedProperties,
  handleStart,
  loading,
  formatTitle,
  confirmButtonLabel = 'Restore',
  footNote,
}) => {
  const columns = getColumns(metadataMap);
  const multiple = selectedProperties.length > 1;
  const title = formatTitle(multiple);
  return (
    <EuiConfirmModal
      title={title}
      onCancel={closeModal}
      onConfirm={() => handleStart(selectedProperties)}
      cancelButtonText="Cancel"
      confirmButtonText={confirmButtonLabel}
      buttonColor="danger"
      isLoading={loading}
    >
      <p>
        This will add {selectedProperties.length} {multiple ? 'properties' : 'property'} back to
        your portfolio.
      </p>
      <EuiBasicTable columns={columns} items={selectedProperties} />
      {footNote && (
        <>
          <Spacer />
          <p>{footNote}</p>
        </>
      )}
    </EuiConfirmModal>
  );
};

export default RestoreDeletedPropertyModal;
