import { useLocation } from 'react-router';
import { MapPosition } from '@app/components/GoogleMap/types';
import { SizeBy } from '@app/components/PropertiesMap/types';
import { getQueryAsObj } from '@app/hooks/useQueryState';

interface MapParams {
  mapPosition: MapPosition | null;
  sizeBy: SizeBy;
}

const getMapPosition = (mapPositionStr: string): MapPosition | null => {
  if (!mapPositionStr) {
    return null;
  }

  const params = (mapPositionStr as string).split(',');
  if (params.length < 3) {
    return null;
  }

  return {
    center: { lat: Number.parseFloat(params[1]), lng: Number.parseFloat(params[2]) },
    zoom: Number.parseInt(params[0]),
  };
};

export const useMapParams = (): MapParams => {
  const location = useLocation();
  const { mapPosition: mapPositionStr, sizeBy } = (getQueryAsObj(
    new URLSearchParams(location.search),
  ) || {}) as any;
  return { mapPosition: getMapPosition(mapPositionStr), sizeBy: sizeBy || SizeBy.TIV };
};
