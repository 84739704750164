import styled from '@emotion/styled';
import colors from '@app/utils/colors';

export const SourceLinkView = styled.a`
  margin: 24px 0;

  label: SourceLinkView;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  label {
    cursor: pointer;
  }
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;

  img {
    width: 250px;
    margin: 0 auto 16px auto;
  }
`;

export const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 24px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    span:not(:first-child) {
      color: ${colors.gray3};
      font-size: 11.85px;
    }
  }
`;
