import { createContext, useContext } from 'react';
import { CarrierAssessment } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { RiskAttribute, ViewType } from './types';

interface Context {
  currentAttribute?: RiskAttribute | null;
  carrierAssessment: CarrierAssessment | null;
  viewType: ViewType;
}

const initialContext: Context = {
  carrierAssessment: null,
  currentAttribute: null,
  viewType: ViewType.Histogram,
};

export const GradingDetailsContext = createContext<Context>(initialContext);

// ----- USE CONTEXT HOOK ----- //

export const useGradingDetailsContext = (): Context => useContext(GradingDetailsContext);
