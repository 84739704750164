import gql from 'graphql-tag';
import { ExposureLevel } from '@app/cx/ExposureIndex/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';

// ----- QUERY ----- //

export const GET_STREAM = gql`
  query GetStream($slug: ID!, $userCode: String!) {
    stream(slug: $slug, userCode: $userCode) {
      id
      name
      orgName
      organizationId
      displayCurrency
      snapshots {
        id
        name
        type
      }
      exposure {
        index
        indexGrade
        attributeScores {
          name
          displayName
          percentile
          TIVPct
          gradeDefinitions {
            indexGrade
            industryName
            minPercentileValue
            maxPercentileValue
            minPercentileThreshold
            maxPercentileThreshold
          }
          indexGrade
        }
        rank {
          industryName
          rank
          streamCount
          grades {
            indexGrade
            streamCount
          }
        }
      }
    }
  }
`;

// ----- TYPES ----- //

export interface GetStreamData {
  stream: {
    id?: string;
    name?: string;
    orgName?: string;
    organizationId?: string;
    displayCurrency?: string;
    snapshots?: Array<IGraphQLSnapshot>;
    exposure: ExposureResponse;
  };
}

// this is the correct interface for streamV2
export interface GetStreamV2Data {
  streamV2: {
    stream: {
      id?: string;
      name?: string;
      orgName?: string;
      organizationId?: string;
      displayCurrency?: string;
      snapshots?: Array<IGraphQLSnapshot>;
      exposure: ExposureResponse;
    };
  };
}

export interface GetStreamVariables {
  slug: string;
  userCode: string;
  snapshot?: string;
  isAdmin: boolean;
}

export interface GradeDefinitions {
  indexGrade: string;
  industryName: string;
  minPercentileValue: number;
  maxPercentileValue: number;
  minPercentileThreshold: number;
  maxPercentileThreshold: number;
}

interface ExposureResponse {
  index: number;
  indexGrade: ExposureLevel;
  attributeScores: {
    groupName?: string;
    name: string;
    displayName: string;
    percentile: number;
    TIVPct: number;
    gradeDefinitions: GradeDefinitions[];
    indexGrade: ExposureLevel;
  }[];
  rank: {
    industryName: string;
    rank: number;
    streamCount: number;
    grades: {
      indexGrade: string;
      streamCount: number;
    }[];
  };
}
