import { IStream } from '@onarchipelago/cx/Stream/types';
import get from 'lodash/get';
import { InterfaceAlias } from '@app/utils/alias';
import {
  validateDate,
  validateEmails,
  ValidateFunction,
  validateJSON,
  validateRequired,
} from '@app/utils/form';

export interface IFieldMeta {
  error?: string;
  tab?: string;
  touched?: boolean;
}
export type StreamFormMeta = InterfaceAlias<IStream, IFieldMeta | undefined>;
export const getDefaultStreamMeta = (): StreamFormMeta => ({
  allowedExports: {
    tab: 'tabAdvanced',
  },
  allowedReports: {
    tab: 'tabAdvanced',
  },
  broker: {
    tab: 'tabBasicInfo',
  },
  configuration: {
    explorersConfig: {
      tab: 'tabExplorersConfig',
    },
    lastUpdateNote: {
      tab: 'tabBasicInfo',
    },
    membershipExpression: {
      tab: 'tabAdvanced',
    },
    notificationEmails: {
      tab: 'tabAdvanced',
    },
    submissionDashboardOrgNames: {
      tab: 'tabAdvanced',
    },
  },
  effectiveDate: {
    // FIX ME
    // @ts-ignore
    tab: 'tabBasicInfo',
  },
  expiryDate: {
    // FIX ME
    // @ts-ignore
    tab: 'tabBasicInfo',
  },
  highlights: {
    tab: 'tabHighlights',
  },

  isPublic: {
    tab: 'tabBasicInfo',
  },

  name: {
    tab: 'tabBasicInfo',
  },
  propertyStatusLabel: {
    tab: 'tabAdvanced',
  },
  reit: {
    tab: 'tabBasicInfo',
  },
  status: {
    tab: 'tabBasicInfo',
  },
});

export const streamValidator: InterfaceAlias<IStream, Array<ValidateFunction>> = {
  broker: [validateRequired],
  configuration: {
    explorersConfig: [validateJSON],
    notificationEmails: [validateEmails()],
  },
  effectiveDate: [validateRequired, validateDate],
  expiryDate: [validateRequired, validateDate],
  highlights: [validateJSON],
  name: [validateRequired],
  reit: [validateRequired],

  status: [validateRequired],
};

export const validate = (field: string, value: any): string => {
  if (!get(streamValidator, field)) {
    return '';
  }

  const fieldValidators = get(streamValidator, field) as Array<ValidateFunction>;

  for (let i = 0; i < fieldValidators.length; i++) {
    const err = fieldValidators[i](value);
    if (err) {
      return err;
    }
  }

  return '';
};

export const hasErrors = (meta: StreamFormMeta): boolean =>
  !!Object.keys(meta.configuration || {}).find((key) => !!meta.configuration[key]?.error) ||
  !!Object.keys(meta).find((key) => !!meta[key]?.error);
