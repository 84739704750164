import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import moment from 'moment';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiPopover } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';

const StyledDate = styled.span`
  font-weight: 600;
`;

const StyledPanel = styled(EuiPanel)`
  margin-bottom: 16px;
  width: 75%;
`;

const StyledGroup = styled(EuiFlexGroup)`
  box-shadow: inset 0 -1px 0 #e0e6eb;
  margin-bottom: 24px;
  padding-bottom: 16px;
`;

const StyledPopoverContent = styled.div`
  padding: 8px;
`;

const StyledPopoverAttribute = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledProperty = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const AttributesEdited = ({ attributes }) => {
  const [isPopoverOpen, setIsOpen] = useState(false);

  if (attributes.length === 1) {
    return <>{attributes[0].displayName}</>;
  }

  return (
    <div>
      <EuiPopover
        ownFocus
        button={
          <EuiLink style={{ fontWeight: 600 }} onClick={() => setIsOpen(!isPopoverOpen)}>
            {attributes.length} attributes edited{' '}
          </EuiLink>
        }
        isOpen={isPopoverOpen}
        closePopover={() => setIsOpen(false)}
        panelPaddingSize="s"
      >
        <StyledPopoverContent>
          {attributes.map((attr, index) => (
            <StyledPopoverAttribute key={index}>{attr.displayName}</StyledPopoverAttribute>
          ))}
        </StyledPopoverContent>
      </EuiPopover>
    </div>
  );
};

const Properties = ({ properties, streamSlugRandomIdentifier, propertiesCount }) => {
  const { selectedOrganization } = useUserSession();
  const history = useHistory();
  const apiTracker = useTracker('api');

  const [isPopoverOpen, setIsOpen] = useState(false);

  const onClick = (property) => {
    apiTracker.track('View Property', {
      event_surface: 'Activity Log',
      organization_id: selectedOrganization?.id,
      property_id: property.id,
    });

    history.push(
      `/streams/${streamSlugRandomIdentifier}/property/${property.id}?propertyPageTab=comparison`,
    );
  };

  if (propertiesCount > 0 && !properties) {
    return <StyledProperty>{propertiesCount} properties</StyledProperty>;
  }

  if (properties?.length === 1) {
    return (
      <StyledProperty>
        <EuiLink onClick={() => onClick(properties[0])} style={{ fontSize: '14px' }}>
          {properties[0].locationName}, {properties[0].city}, {properties[0].state}
        </EuiLink>
      </StyledProperty>
    );
  }

  return (
    <StyledProperty>
      <EuiPopover
        ownFocus
        button={
          <EuiLink style={{ fontSize: '14px' }} onClick={() => setIsOpen(!isPopoverOpen)}>
            {properties.length} properties
          </EuiLink>
        }
        isOpen={isPopoverOpen}
        closePopover={() => setIsOpen(false)}
        panelPaddingSize="s"
      >
        <StyledPopoverContent>
          {properties.map((property, index) => (
            <StyledPopoverAttribute key={index}>
              {' '}
              <EuiLink onClick={() => onClick(property)} style={{ fontSize: '14px' }}>
                {property.locationName}, {property.city}, {property.state}
              </EuiLink>
            </StyledPopoverAttribute>
          ))}
        </StyledPopoverContent>
      </EuiPopover>
    </StyledProperty>
  );
};

const User = ({ event }) => (
  <EuiFlexGroup style={{ fontSize: '14px' }}>
    <EuiFlexItem grow={2}>
      {event.eventName.includes('Edit') ? 'Edited by' : 'Added by'} {event.name}
    </EuiFlexItem>
    <EuiFlexItem grow={6}>{moment(event.timestamp).format('h:mm A')}</EuiFlexItem>
  </EuiFlexGroup>
);

export const EventsByDate = ({ events, date }) => (
  <StyledGroup>
    <EuiFlexItem grow={1}>
      <StyledDate>{date}</StyledDate>
    </EuiFlexItem>
    <EuiFlexItem grow={6}>
      {events.map((event, index) => (
        <StyledPanel data-testid="activity-event-card" key={index} hasShadow={false}>
          {event.eventName.includes('Edit') ? (
            <AttributesEdited attributes={event.attributes} />
          ) : (
            <></>
          )}
          <Properties
            properties={event.properties}
            propertiesCount={event.propertiesCount}
            streamSlugRandomIdentifier={event.streamSlugRandomIdentifier}
          />
          <User event={event} />
        </StyledPanel>
      ))}
    </EuiFlexItem>
  </StyledGroup>
);
