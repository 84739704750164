import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateMpDataQueryVariables = Types.Exact<{
  input: Types.ValidateMpDataInput;
}>;


export type ValidateMpDataQuery = { __typename?: 'Query', validateMPData: { __typename?: 'ValidateMPDataOutput', errors: Array<{ __typename?: 'PropertyDataValidationError', attributeName: string, type: Types.PropertyDataValidationErrorType, message: string }>, warnings: Array<{ __typename?: 'PropertyDataValidationError', attributeName: string, type: Types.PropertyDataValidationErrorType, message: string }> } };


export const ValidateMpDataDocument = gql`
    query ValidateMPData($input: ValidateMPDataInput!) {
  validateMPData(input: $input) {
    errors {
      attributeName
      type
      message
    }
    warnings {
      attributeName
      type
      message
    }
  }
}
    `;

/**
 * __useValidateMpDataQuery__
 *
 * To run a query within a React component, call `useValidateMpDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateMpDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateMpDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateMpDataQuery(baseOptions: Apollo.QueryHookOptions<ValidateMpDataQuery, ValidateMpDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateMpDataQuery, ValidateMpDataQueryVariables>(ValidateMpDataDocument, options);
      }
export function useValidateMpDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateMpDataQuery, ValidateMpDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateMpDataQuery, ValidateMpDataQueryVariables>(ValidateMpDataDocument, options);
        }
export type ValidateMpDataQueryHookResult = ReturnType<typeof useValidateMpDataQuery>;
export type ValidateMpDataLazyQueryHookResult = ReturnType<typeof useValidateMpDataLazyQuery>;
export type ValidateMpDataQueryResult = Apollo.QueryResult<ValidateMpDataQuery, ValidateMpDataQueryVariables>;