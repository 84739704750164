import { ReactElement } from 'react';
import { AttributeEditInfo } from '@app/graphql/types';
import { IProvenanceForTooltip } from '../PropertiesGridTooltip/types';
import { IPropertiesGridColumn, IPropertyWithProvenance } from '../types';

export interface ICell {
  prop: IPropertyWithProvenance;
  column: IPropertiesGridColumn;
  value: any;
}

export interface Cell {
  tooltip?: ITooltip;
  body?: ReactElement;
}

export interface ITooltip {
  title?: string;
  provenance?: IProvenanceForTooltip;
  edits?: Array<AttributeEditInfo>;
  description?: string;
}

export const getProvenance = (property?: IPropertyWithProvenance, columnID?: string) => {
  if (!property || !columnID) {
    return undefined;
  }

  const provenance = property.provenance[columnID];

  if (!provenance) {
    return undefined;
  }

  return provenance;
};

export const getEdits = (property?: IPropertyWithProvenance, columnID?: string) => {
  if (!property || !columnID) {
    return undefined;
  }

  const edits = property.editInfo?.[columnID];
  if (!edits) {
    return undefined;
  }

  return edits;
};
