import styled from '@emotion/styled';
import { EuiFlexItem, EuiFlyout } from 'ui';

export const LogoContainer = styled(EuiFlexItem)`
  svg {
    margin: 6px 0px 0px 0px;
    max-width: 200px;
  }
`;

export const UploadPortalContainer = styled.div`
  ${({
    animationSpeed,
    animationSpeedResistance,
  }: {
    animationSpeed: string;
    animationSpeedResistance: string;
  }) =>
    `
    & .euiPopover__panel .euiFilePicker__prompt {
      pointer-events: auto;
      // transition the shadow
      transition: all ${animationSpeed} ${animationSpeedResistance};

      &:hover,
      &:focus {
        box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.1), 0 6px 12px 0 rgba(152, 162, 179, 0.1),
        0 4px 4px 0 rgba(152, 162, 179, 0.1), 0 2px 2px 0 rgba(152, 162, 179, 0.1),
        inset 0 0 0 1px #21b59a;
        /*@include euiBottomShadow($color: $euiShadowColor);*/
        transform: translateY(-2px);
        cursor: pointer;
      }
    }

    & .showDropZone .euiFilePicker__prompt {
      border: 1px solid #24b59a8c;
      box-shadow: inset 0 0 128px #c8f4ec;
    }

    .fileIcon,
    .euiContextMenuItem.euiSuperSelect__item .euiContextMenuItem__text .fileIcon {
      height: 48px;
      width: 48px;
      border-radius: 4px;

      &.drawing {
        color: #1fb2c6;
        background-color: #1fb2c626;
      }

      &.reports {
        color: #9e28fb;
        background-color: #9e28fb26;
      }

      &.image {
        color: #50687c;
        background-color: #50687c26;
      }

      &.roofing {
        color: #f8934a;
        background-color: #f8934a26;
      }

      &.pca {
        color: #0270d4;
        background-color: #0270d426;
      }

      &.sov {
        color: #21b59a;
        background-color: #21b59a26;
      }

      &.error {
        color: #f84a4a;
        background-color: #f84a4a26;
      }

      &.unknown {
        color: #98a2b3;
        background-color: #98a2b326;
      }

      &.all {
        color: $black;
      }
    }
`}
`;

export const UploadPortalFlyout = UploadPortalContainer.withComponent(EuiFlyout);
