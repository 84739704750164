import styled from '@emotion/styled';
import { EuiFlyoutBody, EuiStepsHorizontal } from 'ui';

// Make the horizontal steps smaller
// 8px was 24px
// 20px was 36px
export const StepsHorizontal = styled(EuiStepsHorizontal)`
  button {
    padding-block: 8px 16px;
  }
  button::after,
  button::before {
    inset-block-start: 20px;
  }
`;

export const FlyoutBody = styled(EuiFlyoutBody)`
  .euiFlyoutBody__overflowContent {
    height: 100%;
  }
`;

// 104px is the height of the horizontal steps. This used to be 120px but we made the stepper smaller.
export const StepBody = styled.div`
  height: calc(100% - 104px);
  overflow-y: auto;
`;
