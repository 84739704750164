import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyDisposePropertiesJobInputMutationVariables = Types.Exact<{
  input: Types.ApplyDisposePropertiesJobInput;
}>;


export type ApplyDisposePropertiesJobInputMutation = { __typename?: 'Mutation', applyDisposePropertiesJob: { __typename?: 'Job', id: string } };


export const ApplyDisposePropertiesJobInputDocument = gql`
    mutation ApplyDisposePropertiesJobInput($input: ApplyDisposePropertiesJobInput!) {
  applyDisposePropertiesJob(input: $input) {
    id
  }
}
    `;
export type ApplyDisposePropertiesJobInputMutationFn = Apollo.MutationFunction<ApplyDisposePropertiesJobInputMutation, ApplyDisposePropertiesJobInputMutationVariables>;

/**
 * __useApplyDisposePropertiesJobInputMutation__
 *
 * To run a mutation, you first call `useApplyDisposePropertiesJobInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDisposePropertiesJobInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDisposePropertiesJobInputMutation, { data, loading, error }] = useApplyDisposePropertiesJobInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyDisposePropertiesJobInputMutation(baseOptions?: Apollo.MutationHookOptions<ApplyDisposePropertiesJobInputMutation, ApplyDisposePropertiesJobInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyDisposePropertiesJobInputMutation, ApplyDisposePropertiesJobInputMutationVariables>(ApplyDisposePropertiesJobInputDocument, options);
      }
export type ApplyDisposePropertiesJobInputMutationHookResult = ReturnType<typeof useApplyDisposePropertiesJobInputMutation>;
export type ApplyDisposePropertiesJobInputMutationResult = Apollo.MutationResult<ApplyDisposePropertiesJobInputMutation>;
export type ApplyDisposePropertiesJobInputMutationOptions = Apollo.BaseMutationOptions<ApplyDisposePropertiesJobInputMutation, ApplyDisposePropertiesJobInputMutationVariables>;