import React, { FC, ReactElement, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { API_BASE_URL } from '@app/config';
import { getIdToken } from '@app/containers/Auth/idToken';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import getLink, { TypePatcher, TypePatchers } from './getLink';
import possibleTypes from './possibleTypes.json';

interface IProps {
  children: ReactElement;
}

const typePatchers: TypePatchers = {};

const cache = new InMemoryCache({
  possibleTypes: possibleTypes as any,
  typePolicies: {
    OptionalFeature: { keyFields: ['name'] },
    Query: {
      fields: {
        // add the type policy on the field that you're trying to paginate
        propertiesPage: relayStylePagination(['groupID', 'cursor']),
      },
    },
  },
});

const Apollo: FC<IProps> = ({ children }) => {
  const token = getIdToken();

  const apolloClient = new ApolloClient({
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
    link: getLink(typePatchers, `${API_BASE_URL}/graphql/query`, useAuth()?.checkSession),
    // this is set to true by default, but is explicitly set here for clarity.
    queryDeduplication: true,
  });

  const [client] = useState(apolloClient);

  if (!client || !token) {
    return <LoadingSpinnerV2 />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;

export function addTypePatcher<D, R>(name: string, typePatcher: TypePatcher<D, R>) {
  typePatchers[name] = typePatcher;
}

export const getCache = () => cache;
