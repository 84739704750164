import React, { memo } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLTIVChart } from '@app/queries/explorers/types';
import { getCurrencyValue } from '@app/utils/format';
import { Explorer } from '../Explorer.emotion';
import { buildTIVChartData, ChartData, chartDataIsEqual, getAgeTickPositions } from './utils';

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const getOptions = (
  queryData: Array<ChartData>,
  average: any,
  scaleAmount: number,
  currencyAbbreviation: string,
  formatCurrency?: FormatCurrency,
  title?: string,
) => ({
  chart: {
    height: '100%',
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  lang: {
    numericSymbols: undefined,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      color: '#2D9CDB',
      data: queryData,
      name: 'Avg. Yrs in Portfolio',
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${formatCurrency?.(this.point.y)}`;
    },
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    labels: {
      formatter() {
        // y axis
        return `${this.value / scaleAmount}`; //eslint-disable-line
      },
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    plotLines: [
      {
        color: '#27AE60',
        label: {
          rotation: 0,
          style: {
            color: '#27AE60',
            fontSize: '10px',
          },
          text: average ? `Average<br/>${average?.toFixed(2)} years` : '',
          x: 80,
          y: 140,
        },
        value: 11,
        width: 0,
        zIndex: 2,
      } as Highcharts.YAxisPlotLinesOptions,
    ],
    tickPositions: getAgeTickPositions(queryData),
    title: {
      text: currencyAbbreviation,
    },
  },
});

const AvgYrInPortfolio: React.FC<IProps> = ({ explorer: e, title }) => {
  const explorer = e as IGraphQLTIVChart;
  // Get the max number from all the TIVs
  const theMax: number = Math.max(...explorer.values.map((o) => o.tiv));

  // Translate the data so it's compatible for Highcharts Map
  const queryData = buildTIVChartData(explorer);

  // Get the average years value from data
  const average = explorer.average;

  const currencyValue = getCurrencyValue(theMax);
  const { formatStreamCurrency } = useStreamContext();
  return (
    <Explorer>
      <HighchartsReact
        highcharts={Highcharts}
        options={getOptions(
          queryData,
          average,
          currencyValue.amount || 1,
          currencyValue.shortAbbreviation,
          formatStreamCurrency,
          title,
        )}
      />
    </Explorer>
  );
};

export default memo(AvgYrInPortfolio, chartDataIsEqual);
