import gql from 'graphql-tag';

// ----- QUERY ----- //

export const GET_ENRICHMENT_OVERALL_QUALITY_CHANGE = gql`
  query EnrichmentOverallQualityChange($input: EnrichmentOverallQualityChangeInput!) {
    enrichmentOverallQualityChange(input: $input) {
      addedAvgScore
      addedProperties
      addedTiv
      disposedAvgScore
      disposedProperties
      disposedTiv
      enrichedAvgImprovement
      enrichedPctOfTiv
      enrichedProperties
      enrichedTiv
      snapshotPercentile
      snapshotScoreAfter
      snapshotScoreBefore
      streamCount {
        count
        score
      }
      totalStreamCount
    }
  }
`;

// ----- TYPES ----- //

export interface EnrichmentOverallQualityChangeData {
  enrichmentOverallQualityChange: {
    addedAvgScore: number;
    addedProperties: number;
    addedTiv: number;
    disposedAvgScore: number;
    disposedProperties: number;
    disposedTiv: number;
    enrichedAvgImprovement: number;
    enrichedPctOfTiv: number;
    enrichedProperties: number;
    enrichedTiv: number;
    snapshotPercentile: number;
    snapshotScoreAfter: number;
    snapshotScoreBefore: number;
    streamCount: {
      count: number;
      score: number;
    }[];
    totalStreamCount: number;
  };
}

export interface EnrichmentOverallQualityChangeVariables {
  input: {
    snapshotNameAfter: string;
    snapshotNameBefore: string;
    streamSlug: string;
  };
}
