import React, { FC } from 'react';
import { motion } from 'framer-motion';
import isBrowserSupported from '@app/utils/isBrowserSupported';

interface Props {
  type: 'primary' | 'secondary';
}

export const AnimatedPanel: FC<Props> = ({ children, type }) => {
  const xDist = type === 'primary' ? -300 : 300;

  const style = {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    top: '0',
    width: '100%',
  } as const;

  if (isBrowserSupported()) {
    return (
      <motion.div
        initial={{ x: xDist }}
        animate={{ x: 0 }}
        exit={{ x: xDist }}
        transition={{ duration: 0.2 }}
        style={style}
        key={type}
      >
        {children}
      </motion.div>
    );
  }

  return <div style={style}>{children}</div>;
};

export default AnimatedPanel;
