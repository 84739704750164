import moment from 'moment';
import {
  Casualty,
  CasualtyInput,
  ExposureBaseInputGl,
  LineOfBusiness,
  PolicyEffectiveYearId,
} from '@app/graphql/types';

export const dateFormat = 'MM/DD/YYYY';

export const humanReadablePolicyEffectiveYear = (
  policyEffectiveYear: PolicyEffectiveYearId,
): string => {
  switch (policyEffectiveYear) {
    case PolicyEffectiveYearId.ProspectiveYear:
      return 'Prospective Year';
    case PolicyEffectiveYearId.CurrentTerm:
      return 'Current Term';
    case PolicyEffectiveYearId.YearPriorFirst:
      return '1st Year Prior';
    case PolicyEffectiveYearId.YearPriorSecond:
      return '2nd Year Prior';
    case PolicyEffectiveYearId.YearPriorThird:
      return '3rd Year Prior';
    case PolicyEffectiveYearId.YearPriorFourth:
      return '4th Year Prior';
    case PolicyEffectiveYearId.YearPriorFifth:
      return '5th Year Prior';
    case PolicyEffectiveYearId.YearPriorSixth:
      return '6th Year Prior';
    case PolicyEffectiveYearId.YearPriorSeventh:
      return '7th Year Prior';
    case PolicyEffectiveYearId.YearPriorEighth:
      return '8th Year Prior';
    default:
      return policyEffectiveYear;
  }
};

export const getDate = (policyEffectiveYear: PolicyEffectiveYearId, renewalDate: Date): Date => {
  const get = (years: number): Date => {
    if (renewalDate) {
      const d = moment.utc(renewalDate);
      return d.add(years * -1, 'years').toDate();
    }

    return undefined;
  };
  switch (policyEffectiveYear) {
    case PolicyEffectiveYearId.ProspectiveYear:
      return get(0);
    case PolicyEffectiveYearId.CurrentTerm:
      return get(1);
    case PolicyEffectiveYearId.YearPriorFirst:
      return get(2);
    case PolicyEffectiveYearId.YearPriorSecond:
      return get(3);
    case PolicyEffectiveYearId.YearPriorThird:
      return get(4);
    case PolicyEffectiveYearId.YearPriorFourth:
      return get(5);
    case PolicyEffectiveYearId.YearPriorFifth:
      return get(6);
    case PolicyEffectiveYearId.YearPriorSixth:
      return get(7);
    case PolicyEffectiveYearId.YearPriorSeventh:
      return get(8);
    case PolicyEffectiveYearId.YearPriorEighth:
      return get(9);
    default:
      return policyEffectiveYear;
  }
};

export const exposureBaseKeyToExposureBaseInputGl = (
  exposureBaseInputGlKey?: string,
): ExposureBaseInputGl => {
  if (!exposureBaseInputGlKey) {
    return undefined;
  }
  switch (exposureBaseInputGlKey.toLowerCase().replace(' ', '')) {
    case ExposureBaseInputGl.Area.toLowerCase().replace(' ', ''):
      return ExposureBaseInputGl.Area;
    case ExposureBaseInputGl.Revenue.toLowerCase().replace(' ', ''):
      return ExposureBaseInputGl.Revenue;
    case ExposureBaseInputGl.GlPayroll.toLowerCase().replace(' ', ''):
      return ExposureBaseInputGl.GlPayroll;
    case ExposureBaseInputGl.WcPayroll.toLowerCase().replace(' ', ''):
      return ExposureBaseInputGl.WcPayroll;
    case ExposureBaseInputGl.Units.toLowerCase().replace(' ', ''):
      return ExposureBaseInputGl.Units;
    default:
      throw new Error(`Unknown exposure base input GL key: ${exposureBaseInputGlKey}`);
  }
};

export const exposureBaseInputGlLabel = (exposureBaseInputGlKey?: string): string => {
  if (!exposureBaseInputGlKey) {
    return undefined;
  }
  switch (exposureBaseInputGlKey.toLowerCase()) {
    case ExposureBaseInputGl.Area.toLowerCase():
      return 'Area';
    case ExposureBaseInputGl.Revenue.toLowerCase():
      return 'Revenue';
    case ExposureBaseInputGl.GlPayroll.toLowerCase():
      return 'GL Payroll';
    case ExposureBaseInputGl.WcPayroll.toLowerCase():
      return 'WC Payroll';
    case ExposureBaseInputGl.Units.toLowerCase():
      return 'Units';
    default:
      return exposureBaseInputGlKey;
  }
};

export type mySectionKey = 'retentions' | 'deductibleAggregateLimit';

export const getTitle = (k: mySectionKey): string => {
  switch (k) {
    case 'retentions':
      return 'Retentions / Deductibles';
    case 'deductibleAggregateLimit':
      return 'Deductible Aggregate Limit';
    default:
      return k;
  }
};

export const casualtyInput = (casualty: Casualty): CasualtyInput => ({
    analysisDate: casualty.analysisDate,
    analysisNotes: casualty.analysisNotes,
    clientName: casualty.clientName,
    clientNetWorth: casualty.clientNetWorth,
    clientProspect: casualty.clientProspect,
    deductibleAggregateLimit: casualty.deductibleAggregateLimit,
    exposureBase: {
      al: casualty.exposureBase.al,
      gl: exposureBaseKeyToExposureBaseInputGl(casualty.exposureBase.gl),
      wc: casualty.exposureBase.wc,
    },
    exposures: casualty.exposures,
    naicsCode: casualty.naicsCode,
    office: casualty.office,
    policyEffectiveDates: casualty.policyEffectiveDates?.map((ped) => ({
      al:
        casualty?.requestedLinesOfBusiness?.find(
          (line) => line.lineOfBusiness === LineOfBusiness.Al,
        ) !== undefined && ped.al
          ? moment.utc(ped.al).format(dateFormat)
          : undefined,
      gl:
        casualty?.requestedLinesOfBusiness?.find(
          (line) => line.lineOfBusiness === LineOfBusiness.Gl,
        ) !== undefined && ped.gl
          ? moment.utc(ped.gl).format(dateFormat)
          : undefined,
      policyEffectiveYear: ped.policyEffectiveYear,
      wc:
        casualty?.requestedLinesOfBusiness?.find(
          (line) => line.lineOfBusiness === LineOfBusiness.Wc,
        ) !== undefined && ped.wc
          ? moment.utc(ped.wc).format(dateFormat)
          : undefined,
    })),
    primaryContactEmail: casualty.primaryContactEmail,
    region: casualty.region,
    requestedLinesOfBusiness: casualty?.requestedLinesOfBusiness?.map((line) => ({
        limit: line.limit,
        lineOfBusiness: line.lineOfBusiness,
        renewalOrRfpEffectiveDate:
          line.renewalOrRfpEffectiveDate !== undefined
            ? moment.utc(line.renewalOrRfpEffectiveDate).format(dateFormat)
            : undefined,
        retention: line.retention,
      })),
    retentions: casualty.retentions,
    secondaryContactEmail: casualty.secondaryContactEmail,
    sicCode: casualty.sicCode,
    typeOfBusiness: casualty.typeOfBusiness,
  });

export const AllPolicyEffectiveYearID = [
  PolicyEffectiveYearId.ProspectiveYear,
  PolicyEffectiveYearId.CurrentTerm,
  PolicyEffectiveYearId.YearPriorFirst,
  PolicyEffectiveYearId.YearPriorSecond,
  PolicyEffectiveYearId.YearPriorThird,
  PolicyEffectiveYearId.YearPriorFourth,
  PolicyEffectiveYearId.YearPriorFifth,
  PolicyEffectiveYearId.YearPriorSixth,
  PolicyEffectiveYearId.YearPriorSeventh,
  PolicyEffectiveYearId.YearPriorEighth,
];
