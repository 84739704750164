import { IGraphQLProperty } from '@app/queries/properties/types';
import {
  IGetPropertyPageData,
  IGetPropertyPageVariables,
  IGraphQLSort,
  RequestType,
} from './types';

type ApolloFetchPolicy =
  | 'cache-first'
  | 'network-only'
  | 'cache-only'
  | 'no-cache'
  | 'standby'
  | 'cache-and-network'
  | undefined;

export function translateProperties<IPropType extends Partial<IGraphQLProperty>>(
  data: IGetPropertyPageData<IPropType>,
  { pageSize }: IGetPropertyPageVariables,
) {
  if (!data?.propertiesPage) {
    return undefined;
  }

  const {
    propertiesPage: { properties, ...rest },
  } = data;

  const hasMore = properties !== null && properties.length === pageSize;

  return {
    propertiesPage: {
      ...rest,
      hasMore,
      properties: properties || [],
    },
  };
}

export const getOptions = (
  groupID: string = '',
  props: {
    pageSize?: number;
    sortBy?: Array<IGraphQLSort>;
  },
) => ({
  fetchPolicy: 'network-only' as ApolloFetchPolicy,
  nextFetchPolicy: 'cache-only' as ApolloFetchPolicy,
  partialRefetch: true,
  returnPartialData: true,
  skip: !groupID,
  variables: {
    cursor: '',
    groupID,
    ...props,
  },
});

export const getRequestType = (
  prev: IGetPropertyPageVariables,
  curr: IGetPropertyPageVariables,
): RequestType => {
  if (
    prev.groupID === curr.groupID &&
    prev.cursor === curr.cursor &&
    prev.pageSize === curr.pageSize &&
    prev.sortBy?.[0] !== curr.sortBy?.[0]
  ) {
    return 'sort';
  }

  return 'unknown';
};
