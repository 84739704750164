import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShareStreamLinkMutationVariables = Types.Exact<{
  input: Types.ShareStreamLinkInput;
}>;


export type ShareStreamLinkMutation = { __typename?: 'Mutation', shareStreamLink: boolean };


export const ShareStreamLinkDocument = gql`
    mutation ShareStreamLink($input: ShareStreamLinkInput!) {
  shareStreamLink(input: $input)
}
    `;
export type ShareStreamLinkMutationFn = Apollo.MutationFunction<ShareStreamLinkMutation, ShareStreamLinkMutationVariables>;

/**
 * __useShareStreamLinkMutation__
 *
 * To run a mutation, you first call `useShareStreamLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareStreamLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareStreamLinkMutation, { data, loading, error }] = useShareStreamLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareStreamLinkMutation(baseOptions?: Apollo.MutationHookOptions<ShareStreamLinkMutation, ShareStreamLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareStreamLinkMutation, ShareStreamLinkMutationVariables>(ShareStreamLinkDocument, options);
      }
export type ShareStreamLinkMutationHookResult = ReturnType<typeof useShareStreamLinkMutation>;
export type ShareStreamLinkMutationResult = Apollo.MutationResult<ShareStreamLinkMutation>;
export type ShareStreamLinkMutationOptions = Apollo.BaseMutationOptions<ShareStreamLinkMutation, ShareStreamLinkMutationVariables>;