import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import StreamRender from '@onarchipelago/cx/Stream/StreamRender/StreamRender';
import { EuiLink, EuiPageSection, EuiText } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { Role } from '@app/graphql/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { displayRoleName } from '@app/platform/AccountUsers/utils';
// FIX ME
// @ts-ignore
import GET_STREAMS from '@app/queries/streams/getStreams.gql';
import { GetStreamsResponse, IGraphQLStream } from '@app/queries/streams/types';
import { StreamProvider } from './StreamProvider';
import { StreamPageOptions } from './types';

export const MyProperties: FC = () => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name;
  const homeOrg = account?.managedOrgs?.find((o) => o?.org?.name === account?.organizationName);
  const history = useHistory();

  const hasAccountsOnHomeOrg = homeOrg?.org?.enableSubmissionDashboard;
  const redirectToHomeOrgAccountsPage = () =>
    history.replace({ pathname: encodeUrl`/organizations/${account.organizationName}/accounts` });

  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProjects || o.editProperties || o.editSubmissions,
  );
  const useOrgInQuery = !!(isAdmin || hasNonViewManagedOrgs);

  // FIXME: Aren't we also calling this in useStreamContext? Can we call that instead?
  const { loading, error, data } = useStandardQuery<
    GetStreamsResponse,
    {
      orgName: string | null;
    }
  >(
    GET_STREAMS,
    {
      fetchPolicy: 'cache-first',
      skip: useOrgInQuery && !organizationName,
      variables: {
        orgName: organizationName,
      },
    },
    {
      errorMessage: (err) => err?.message,
    },
  );

  const getMyPropertiesStream = (streams: Array<IGraphQLStream>): IGraphQLStream | undefined =>
    (streams || []).find((v) => v.isMyProperties);

  const options: StreamPageOptions = {
    enableDocumentsBtn: false,
    enableStreamEditBtn: isAdmin,
    enableStreamInviteBtn: false,
  };

  if (!account) {
    return null;
  }
  if (loading) {
    return <LoadingSpinnerV2 variant="xxl" label="Loading" dataTestId="loading-spinner" />;
  }

  const stream = getMyPropertiesStream(data?.streamsV2?.streams || []);
  if (error || !data || stream === undefined) {
    return (
      <EuiPageSection style={{ maxWidth: '640px', padding: '24px' }} alignment="center">
        <EuiText>
          <h3 style={{ paddingBottom: '16px' }}>
            It looks like you haven&apos;t been added to this account.
          </h3>
          <p style={{ paddingBottom: '24px' }}>
            If you work for {organizationName}, please contact your{' '}
            {displayRoleName(Role.RiskManager)} and ask them to add you.
          </p>
          {hasAccountsOnHomeOrg && (
            <p>
              For now, you can head back to{' '}
              <EuiLink onClick={redirectToHomeOrgAccountsPage}>
                your home organization's accounts page
              </EuiLink>
              .
            </p>
          )}
        </EuiText>
      </EuiPageSection>
    );
  }



  // Using StreamRender instead of containers/Stream/Stream because we can't rely on the useParams
  // since the URL for My Properties does not contain a stream slug.
  return (
    <StreamProvider>
      <StreamRender options={options} />
    </StreamProvider>
  );
};

export default MyProperties;
