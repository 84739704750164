import React from 'react';
import { EuiFlexItem } from 'ui';
import { Row } from './Row';
import { StyledHorizontalLine, Title } from './RowMenu.emotion';

export const RowMenu = ({ rows, title }) => (
    <EuiFlexItem>
      <Title size="s">
        <h5>{title}</h5>
      </Title>
      <StyledHorizontalLine margin="none" />
      {rows.map(({ title, subtitle, loading, id, propertyID }) => (
          <Row
            key={id}
            id={id}
            title={title}
            subtitle={subtitle}
            loading={loading}
            propertyID={propertyID}
          />
        ))}
    </EuiFlexItem>
  );
