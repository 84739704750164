import { useContext } from 'react';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { DocumentsContext } from '../../types/contextTypes';

export default (): DocumentsContext['meta'] => {
  // Get OrgName and OrgId
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const orgName = selectedOrganization?.name || account?.organizationName || '';
  const orgId = selectedOrganization?.id || undefined;

  // Declare page size and preload size for pagination
  const pageSize = 200;

  return {
    orgId,
    orgName,
    pageSize,
  };
};
