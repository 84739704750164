import { ExportCode, IStream, ReportCode } from '@onarchipelago/cx/Stream/types';
import { DateStyle, formatDate } from '@app/utils/format';
import { IGraphQLStream } from './types';

export interface IStreamInput {
  name?: string;
  broker?: string;
  reit?: string;
  status?: string;
  effectiveDate?: string;
  expiryDate?: string;
  isPublic?: boolean;
  industry?: string;
  businessType?: string;
  stockThroughputEnabled?: boolean;
}

export interface IStreamConfigurationInput {
  notificationEmails: string[];
  explorersConfig: string;
  lastUpdateNote: string;
  membershipExpression: string;
  highlights: string;
  allowedExports: Array<ExportCode>;
  allowedReports: Array<ReportCode>;
  propertyStatusLabel: string;
  exportsEnabled: boolean;
  defaultStreamView: string;
  reportsEnabled: boolean;
  shareLossData: boolean;
  submissionDashboardOrgNames: string[];
}
export interface ICreateStreamInput {
  orgName: string;
  stream: IStreamInput;
}

export interface ICreateStreamVariables {
  input: ICreateStreamInput;
}

export interface ICreateStreamData {
  createStream: IGraphQLStream;
}

export interface IUpdateStreamInput {
  id: string;
  stream: IStreamInput;
  configuration: IStreamConfigurationInput;
  message: string;
}

export interface IUpdateStreamVariables {
  input: IUpdateStreamInput;
}

export interface IUpdateStreamData {
  updateStream: IGraphQLStream;
}

export const getUpdateStreamInput = (stream: IStream, message: string): IUpdateStreamInput => ({
  configuration: {
    allowedExports: stream.configuration.allowedExports,
    allowedReports: stream.configuration.allowedReports,
    defaultStreamView: stream.configuration.defaultStreamView,
    explorersConfig: stream.configuration.explorersConfig,
    exportsEnabled: stream.configuration.exportsEnabled,
    highlights: stream.highlights,
    lastUpdateNote: stream.configuration.lastUpdateNote,
    membershipExpression: stream.configuration.membershipExpression,
    notificationEmails: stream.configuration.notificationEmails,
    propertyStatusLabel: stream.propertyStatusLabel,
    reportsEnabled: stream.configuration.reportsEnabled,
    shareLossData: stream.configuration.shareLossData,
    submissionDashboardOrgNames: stream.configuration.submissionDashboardOrgNames,
  },
  id: stream.id,
  message,
  stream: {
    broker: stream.broker,
    businessType: stream.businessType,
    effectiveDate: formatDate(stream.effectiveDate as Date, DateStyle.Server),
    expiryDate: formatDate(stream.expiryDate as Date, DateStyle.Server),
    industry: stream.industry,
    isPublic: !!stream.isPublic,
    name: stream.name,
    reit: stream.reit,
    status: stream.status,
    stockThroughputEnabled: stream.stockThroughputEnabled,
  },
});
