import React, { useCallback, useEffect } from 'react';

export const GoodDataIntegration = ({
  apiToken,
  workspaceID,
  dashboardID,
  setSelectedDashboard,
}) => {
  const setIframeToken = useCallback(
    (e) => {
      if (e.data.gdc?.event.name === 'listeningForApiToken') {
        const postMessageStructure = {
          gdc: {
            event: {
              data: {
                secondsBeforeTokenExpirationToEmitReminder: 60,
                token: apiToken,
                type: 'jwt',
              },
              name: 'setApiToken',
            },
            product: 'dashboard',
          },
        };

        const origin = '*';
        const iframe = (document.getElementById('embedded-app-frame') as HTMLIFrameElement)
          .contentWindow;
        iframe.postMessage(postMessageStructure, origin);
      }

      if (
        e.data.gdc?.event.name === 'loadingStarted' &&
        e.data.gdc.event.data.dashboard !== dashboardID
      ) {
        setSelectedDashboard(e.data.gdc.event.data.dashboard);
      }
    },
    [apiToken],
  );

  useEffect(() => {
    window.addEventListener('message', setIframeToken, false);
    return () => {
      window.removeEventListener('message', setIframeToken, false);
    };
  }, []);

  const src = `https://onarchipelago.cloud.gooddata.com/dashboards/embedded/#/workspace/${workspaceID}/dashboard/${dashboardID}?apiTokenAuthentication=true`;

  if (!apiToken || !workspaceID || !dashboardID) {
    return null;
  }

  return (
    <iframe
      title="My Embedded GoodData Dashboard"
      id="embedded-app-frame"
      src={src}
      height="100%"
      width="100%"
    />
  );
};
