import { useMemo } from 'react';
import { useGetCountryCurrencyCodeQuery } from '@app/graphql/queries/attributeMetadata/__generated__/getCountryCurrencyCode.generated';

export const useRequiredFieldOptions = () => {
  const { data, loading } = useGetCountryCurrencyCodeQuery();

  const countryCurrencyList = data?.countryCurrencyCode || [];

  const countryToCodeMap = useMemo(
    () =>
      countryCurrencyList?.reduce((acc, value) => {
        acc[value.country] = value.countryCode;
        return acc;
      }, {}) || {},
    [countryCurrencyList],
  );

  const countryToCurrencyMap = useMemo(
    () =>
      countryCurrencyList?.reduce((acc, value) => {
        acc[value.country] = value.currencyCode;
        return acc;
      }, {}) || {},
    [countryCurrencyList],
  );

  const getOptions = (key: string) => {
    const optionValueSet = new Set<string>();
    return countryCurrencyList
      ?.map((countryCurrency) => {
        const optionValue = countryCurrency[key];
        if (optionValue && !optionValueSet.has(optionValue)) {
          optionValueSet.add(optionValue);
          return {
            label: optionValue as string,
            value: optionValue as string,
          };
        }
        return null;
      })
      .filter(Boolean);
  };

  const countryOptions = useMemo(() => [
      { label: 'United States', value: 'United States' },
      ...getOptions('country').filter(({ value }) => value !== 'United States'),
    ], [countryCurrencyList]);
  const currencyOptions = useMemo(
    () =>
      getOptions('currencyCode')?.sort((a, b) =>
        a.value > b.value ? 1 : a.value < b.value ? -1 : 0,
      ),
    [countryCurrencyList],
  );

  return {
    countryOptions,
    countryToCodeMap,
    countryToCurrencyMap,
    currencyOptions,
    isLoadingOptions: loading,
  };
};
