const REDIRECT_URI_KEY_OLD = 'archipelago.redirecturi';
const REDIRECT_URI_TIMESTAMP_KEY_OLD = 'archipelago.redirecturi.timestamp';

const REDIRECT_URL_KEY = 'archipelago.redirecturl';
const REDIRECT_URL_TIMESTAMP_KEY = 'archipelago.redirecturl.timestamp';
const REDIRECT_URL_DEBUG_KEY = 'archipelago.redirectdebug';
const FIVE_MIN_IN_MILISEC = 60 * 5 * 1000;
const MAX_DEBUG_ROWS = 50;

/**
 * Empty the Redirect URL in Local Storage.
 */
const clear = () => {
  // Remove the old redirect URI keys (this is just for cleanup)
  localStorage.removeItem(REDIRECT_URI_KEY_OLD);
  localStorage.removeItem(REDIRECT_URI_TIMESTAMP_KEY_OLD);

  // Remove the Redirect URL keys
  localStorage.removeItem(REDIRECT_URL_KEY);
  localStorage.removeItem(REDIRECT_URL_TIMESTAMP_KEY);
};

/**
 * Return the Redirect URL from Local Storage. Always use `exists()` before accessing this function.
 */
const get = (): string | null => {
  const url = localStorage.getItem(REDIRECT_URL_KEY);
  const timestampString = localStorage.getItem(REDIRECT_URL_TIMESTAMP_KEY);

  // if older then 5 mins. delete and return '';
  if (url && timestampString) {
    const storedTimeInMilliseconds = new Date(timestampString).getTime();
    const currentTimeInMilliseconds = new Date().getTime();
    const fiveMinPassed =
      currentTimeInMilliseconds - storedTimeInMilliseconds > FIVE_MIN_IN_MILISEC;

    if (fiveMinPassed) {
      clear();
    }
  }

  return localStorage.getItem(REDIRECT_URL_KEY);
};

/**
 * Check if there's a Redirect URL available in Local Storage.
 */
const exists = (): boolean => get() !== null;

/**
 * Function that decides if the current browser location is an accepted Redirect URL and whether it
 * may be stored in Local Storage. We exclude all paths that are outside the Login wall.
 */
const isPathAllowed = (): boolean => {
  if (window.location.href === `${window.location.origin}/`) {
    return false;
  }
  if (window.location.pathname.startsWith('/login')) {
    return false;
  }
  if (window.location.pathname.startsWith('/callback')) {
    return false;
  }
  if (window.location.pathname.startsWith('/logout')) {
    return false;
  }
  return true;
};

// DebugURLRow is a temporarily interface that helps us debug and track URL Redirect History
interface DebugURLRow {
  timestamp: string;
  url: string;
}

/**
 * Keep a log of all Redirect URLs have been set. We keep a maximum of MAX_DEBUG_ROWS. This might
 * help is debug some of the login issues that people are seeing. We intentionally never clear this.
 */
const trackRedirectURLHistory = (url: string) => {
  const row: DebugURLRow = {
    timestamp: new Date().toISOString(),
    url,
  };
  const stringWithArray = localStorage.getItem(REDIRECT_URL_DEBUG_KEY) || '[]';
  const rows: Array<DebugURLRow> = JSON.parse(stringWithArray);
  rows.push(row);

  // Making sure that the max of MAX_DEBUG_ROWS is not overwritten
  if (rows.length > MAX_DEBUG_ROWS) {
    const numberOfRowsToRemove = rows.length - MAX_DEBUG_ROWS;
    if (numberOfRowsToRemove > 0) {
      for (let i = 0; i < numberOfRowsToRemove; i++) {
        rows.shift();
      }
    }
  }

  localStorage.setItem(REDIRECT_URL_DEBUG_KEY, JSON.stringify(rows));
};

/**
 * Set the current Redirect URL in the Local Storage
 * @param url The redirect URL to be stored.
 */
const set = (url: string) => {
  // for debugging purposes we keep a small log of previous Redirect URLs
  trackRedirectURLHistory(url);

  // Set the current Redirect URL in the Local Storage
  localStorage.setItem(REDIRECT_URL_KEY, url);
  localStorage.setItem(REDIRECT_URL_TIMESTAMP_KEY, new Date().toISOString());
};

/** Helper object to handle Redurect URL functionality */
export const useRedirectURL = {
  clear,
  exists,
  get,
  isPathAllowed,
  set,
};
