import React, { FC } from 'react';
import { useEuiTheme } from 'ui';
import { LineContainer } from './Line.emotion';

interface Props {
  orientation?: 'horizontal' | 'vertical';
}

const Line: FC<Props> = ({ orientation = 'vertical' }) => {
  const { euiTheme } = useEuiTheme();
  const { colors } = euiTheme;
  return (
    <LineContainer
      color={colors.lightShade}
      data-testid="dashboard-report-line"
      orientation={orientation}
      grow={false}
    />
  );
};

export default Line;
