import React, { useEffect,useState } from 'react';
import { builder } from '@builder.io/react';
import { EuiFlexGroup, Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_CMS } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';

export const useCmsNav = () => {
  // Get a list of all pages in Builder
  const [cmsPages, setCmsPages] = useState([]);
  const { selectedOrganization } = useUserSession();

  const isCMSEnabled = isFeatureEnabled(selectedOrganization?.enabledFeatures, FEATURE_TYPE_CMS);
  if (!isCMSEnabled) {return cmsPages;}

  useEffect(() => {
    const getPages = async () => {
      const pages = await builder.getAll('page', {
        // We only need the URL field
        options: { noTargeting: true },
      });

      setCmsPages(pages);
    };

    getPages();
  }, []);

  return cmsPages.map((page) => ({
    icon: (
      <EuiFlexGroup>
        <Icon name={page.data.icon ? page.data.icon : 'layers'} />
      </EuiFlexGroup>
    ),
    id: page.data.title,
    label: page.data.title,
    path: `cms${page.data.url}`,
  }));
};
