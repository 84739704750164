import React, { Component, FC, ReactElement } from 'react';
import { useToast } from 'ui';
import { IS_DEV_ENV } from '@app/config';
import ErrorContainer from './ErrorContainer';

interface IErrorBoundaryProps {
  children: ReactElement;
  onError: (err: Error) => void;
}

interface IErrorBoundaryState {
  error?: Error;
  eventId?: string;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, eventId: undefined };
  }

  componentDidCatch(error: Error, errorInfo: object) {
    const { onError } = this.props;

    this.setState({ error });
    onError(error);
  }

  render() {
    const { children } = this.props;

    const { error, eventId } = this.state;

    if (error && IS_DEV_ENV) {
      return <ErrorContainer error={error} eventId={eventId} />;
    }

    return children;
  }
}

const ErrorBoundaryHook: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const toast = useToast();
  const onError = (error: Error) => toast({ title: error.message, type: 'danger' });

  return <ErrorBoundary onError={onError}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryHook;
