import styled from '@emotion/styled';
import { colors } from 'ui';

interface ExplorerTabProps {
  active: boolean;
}

export const Anchor = styled.div`
  position: absolute;
  margin-top: -40px;
  left: 0;
  top: 0;
`;

export const TabContent = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  color: ${colors.eui.primary};
  height: calc(100vh - 110px);
  // uncomment this if we want a scrollbar for explorers
  // overflow-y: hidden;
  // overflow-x: hidden;
  // &:hover {
  //   overflow-y: overlay;
  // }
  overflow: scroll;
`;

export const ExplorerContainer = styled.div<{ height: number }>`
  label: ExplorerContainer;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: white;
  height: ${({ height }) => height}px;
`;

export const ExplorerTabContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e5e5e5;
`;

export const ExplorerTab = styled.div<ExplorerTabProps>`
  padding: 13px 8px;
  flex: 1;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  > div {
    font-size: 14px !important;
  }

  ${({ active }) =>
    active &&
    `
      border-bottom: 2px solid ${colors.eui.primary};
      p {
        color: ${colors.eui.primary};
      }
    `}
`;
