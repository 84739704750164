import { Column, Columns, Expansions } from './types';
import { getColumnId, getColumnWidth } from './utils';

const styles = {};

type StyleDefinitionType = 'width' | 'column' | 'row';

export function getStyleDefinition(type: StyleDefinitionType, id: string, subId?: string) {
  const key = ([] as Array<string | undefined>)
    .concat([type, id, subId])
    .filter((p) => p !== undefined)
    .join('-');

  if (!styles[key]) {
    styles[key] = `${type.substring(0, 1)}${Object.keys(styles).length}`;
  }

  return styles[key];
}

interface Window {
  MSInputMethodContext?: any;
}

interface Document {
  documentMode?: any;
}

declare const window: Window;
declare const document: Document;

const getWidthStyle = (name: string, width: number) => {
  // Since IE11 doesn't treat flex space the same, we need to do a browser check
  // and subtract 1px from the flex width only!
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  return `
    .${name} {
      width: ${width}px;
      ${isIE11 ? `min-width: ${width}px;` : ''}
    }
  `;
};

const getColumnStyle = (name: string, left: number, borderLeftWidth: number) => `
  .${name} {
    transform: translateX(${left}px);
    border-left-width: ${borderLeftWidth}px;
  }
`;

const getRowStyle = (name: string, top: number, height: number) => `
  .${name} {
    top: ${top}px;
    height: ${height}px;
  }
`;

function getInlineStyles<Row, Col extends Column>(
  rows: Array<Row>,
  columns: Columns<Col>,
  rowHeight: number,
  expansions: Expansions,
) {
  let left = 0;
  let columnsStyle = '';
  let widthsStyle = '';
  for (let index = 0; index < columns.length; index++) {
    const width = getColumnWidth<Col>(columns, index, expansions);
    const column = columns[index];
    const id = getColumnId(column);

    if (Array.isArray(column)) {
      let childLeft = 0;
      for (let childIndex = 0; childIndex < column.length; childIndex++) {
        const child = column[childIndex];

        columnsStyle = `${columnsStyle}
        .${getStyleDefinition('column', id, child.id)} {
          transform: translateX(${childLeft}px);
        }
      `;
        widthsStyle = `${widthsStyle}
        ${getWidthStyle(getStyleDefinition('width', id, child.id), child.width)}
      `;
        childLeft += child.width;
      }
    }

    columnsStyle = `${columnsStyle}
      ${getColumnStyle(getStyleDefinition('column', id), left, index === 0 ? 0 : 1)}
    `;
    widthsStyle = `${widthsStyle}
      ${getWidthStyle(getStyleDefinition('width', id), width)}
    `;

    left += width;
  }

  const rowsStyle = rows
    .map((row, index) => {
      if (!row) {
        return '';
      }
      return getRowStyle(
        getStyleDefinition('row', JSON.stringify(row)),
        index * rowHeight,
        rowHeight,
      );
    })
    .join('\n');

  return [columnsStyle, widthsStyle, rowsStyle].join('\n');
}

export default getInlineStyles;
