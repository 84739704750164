import React, { FC } from 'react';
import { ReportProps } from '@onarchipelago/cx/Reports/types';
import { InsurerInsights } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/InsurerInsights';
import { UnderwritingAssessmentReportProvider } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/underwritingAssessmentReportContext';
import { EuiFlexGroup } from 'ui';
import { ReportContainer } from '@app/components/ReportContainer';
import { reportPageStyle } from '../reports.emotion';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';

function ZurichReport() {
  return (
    <EuiFlexGroup
      direction="column"
      responsive={false}
      style={reportPageStyle}
      data-testid="zurich-report"
    >
      <ReportsHeader header="Zurich Transparency Report" testId="zurich-report-header" />
      <ReportContainer>
        <InsurerInsights
          title="Insurer Insights"
          description={
            <>
              The data, metrics, ratings, grades, and other information on this page are as provided
              by Zurich Insurance Group Ltd. For assistance or any questions (including as related
              to the accuracy or quality of such information), please contact Zurich directly.
            </>
          }
          logo
        />
      </ReportContainer>
    </EuiFlexGroup>
  );
}

const ZurichReportWithContext: FC<ReportProps> = ({ visible }) => (
  <>
    {visible && (
      <UnderwritingAssessmentReportProvider>
        <ZurichReport />
      </UnderwritingAssessmentReportProvider>
    )}
  </>
);

export default ZurichReportWithContext;
