export interface Rule {
  combinator?: Combinator;
  attributeName?: string;
  operator?: BinaryOperator;
  values?: string[];
}

export enum Combinator {
  AND = 'AND',
  OR = 'OR',
}

export enum BinaryOperator {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LESS = 'LESS',
  GREATER = 'GREATER',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  BEGINS_WITH = 'BEGINS_WITH',
  NOT_BEGINS_WITH = 'NOT_BEGINS_WITH',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  ENDS_WITH = 'ENDS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
}
