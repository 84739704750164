import gql from 'graphql-tag';

// ----- QUERY ----- //

export const GET_ENRICHMENT_PROPERTY_IMPROVEMENT = gql`
  query EnrichmentPropertyImprovement($input: EnrichmentPropertyImprovementInput!) {
    enrichmentPropertyImprovement(input: $input) {
      attributes {
        after
        before
        name
      }
      locationName
    }
  }
`;

// ----- TYPES ----- //

export interface Attribute {
  after: string;
  before: string;
  name: string;
}

export interface EnrichmentPropertyImprovementData {
  enrichmentPropertyImprovement: {
    attributes: Attribute[];
    locationName: string;
  }[];
}

export interface EnrichmentPropertyImprovementVariables {
  input: {
    snapshotNameAfter: string;
    snapshotNameBefore: string;
    streamSlug: string;
  };
}
