import styled from '@emotion/styled';
import { transparentize } from 'ui';

export const AttributesTableContainer = styled.div`
  ${({ theme }) => `
  
    overflow-y: auto;
  
    .rated-row-good {
        background-color:  ${transparentize(theme.euiTheme.colors.primary, 0.1)};
    
        :hover {
          background-color: ${transparentize(theme.euiTheme.colors.primary, 0.2)}
        }
      }
    
      .rated-row-bad {
        background-color: ${transparentize(theme.euiTheme.colors.danger, 0.1)};

        :hover {
            background-color: ${transparentize(theme.euiTheme.colors.danger, 0.2)}
          }
      }

      .rated-row-unreviewed {
        background-color:  ${transparentize(theme.euiTheme.colors.darkShade, 0.1)};
    
        :hover {
          background-color: ${transparentize(theme.euiTheme.colors.darkShade, 0.2)}
        }
      }
    `}
`;
