import { ReactElement } from 'react';
import { MetadataMap } from '@app/cx/Stream/StreamProvider';
import { IPropertyWithStream, SizeBy } from './types';

interface TooltipMeta {
  label: string | ReactElement;
  value: string | number;
}
interface markerData {
  value: number | string;
  tooltipMeta: Array<TooltipMeta>;
}

export const getMarkerData = (
  sizeBy: SizeBy,
  property: IPropertyWithStream,
  metadataMap: MetadataMap,
): markerData => {
  if (sizeBy === SizeBy.TotalGrossLoss || sizeBy === SizeBy.LossCountTotal) {
    const totalGrossLoss = property?.lossAttributes?.totalGrossLoss || 0;
    const lossCountTotal = property?.lossAttributes?.lossCountTotal || 0;
    return {
      tooltipMeta: [
        {
          label: metadataMap?.lossCountTotal?.displayName || 'Count of Losses (Total)',
          value: lossCountTotal,
        },
        {
          label: metadataMap?.totalGrossLoss?.displayName || 'Total Gross Loss',
          value: totalGrossLoss,
        },
      ],
      value: sizeBy === SizeBy.TotalGrossLoss ? totalGrossLoss : lossCountTotal,
    };
  }

  const value = property?.totalInsuredValueDisplay || 0;

  return {
    tooltipMeta: [{ label: 'TIV', value }],
    value,
  };
};
