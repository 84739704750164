import { css } from '@emotion/css';

const avatar = css`
  background-color: rgb(27, 107, 99);
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-size: cover;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
  inline-size: 32px;
  block-size: 32px;
  line-height: 36px;
  font-size: calc(14.4px);
  text-transform: uppercase;
`;

const empty = css`
  background-color: transparent;

  svg {
    width: 80px;
    height: 80px;
  }
`;

export default {
  avatar,
  empty,
};
