import gql from 'graphql-tag';

export const GET_ENRICHMENT_ATTRIBUTE_CHANGE = gql`
  query getEnrichmentAttributeChange($input: EnrichmentAttributeChangeInput!) {
    enrichmentAttributeChange(input: $input) {
      specificOccupancy {
        tiv
        tivPctImpact
        attributeCnt
        changeType
      }
      constructionClass {
        tiv
        tivPctImpact
        attributeCnt
        changeType
      }
    }
  }
`;

export enum ChangeType {
  Blank = 'Blank',
  CaseChanged = 'CaseChanged',
  Corrected = 'Corrected',
  Disposed = 'Disposed',
  New = 'New',
  Supplied = 'Supplied',
  Unchanged = 'Unchanged',
}

export interface EnrichmentAttributeChangeResult {
  tiv: number;
  tivPctImpact: number;
  attributeCnt: number;
  changeType: ChangeType;
}

export interface EnrichmentAttributeChangeData {
  enrichmentAttributeChange: {
    constructionClass: EnrichmentAttributeChangeResult[];
    specificOccupancy: EnrichmentAttributeChangeResult[];
  };
}

export interface EnrichmentAttributeChangeVariables {
  input: {
    snapshotNameAfter: string;
    snapshotNameBefore: string;
    streamSlug: string;
  };
}
