import gql from 'graphql-tag';

const _ = gql`
  mutation createRenewalStream($input: CreateRenewalStreamInput!) {
    createRenewalStream(input: $input) {
      id
    }
  }
`;

export default _;
