import { useState } from 'react';
import { DocumentsContext } from '../../types/contextTypes';
import useGetDocumentClassifications from './useGetDocumentClassifications';

export default (
  meta: DocumentsContext['meta'],
  filters: DocumentsContext['filters'],
): DocumentsContext['data'] => {
  const [totalDocumentsCount, setTotalDocumentsCount] = useState<number | null>(null);

  const classifications = useGetDocumentClassifications(meta.orgName);

  return {
    classifications,
    documents: {
      setTotalCount: setTotalDocumentsCount,
      totalCount: totalDocumentsCount,
    },
    properties: null,
  };
};
