import { ExposureLevel } from './types';

const colors = {
  green: '#1A7D6A',
  lightPink: '#E9A39B',
  lime: '#ADCB6C',
  pink: '#C0307A',
  yellow: '#F7DD88',
};

const shades = {
  green: '#B8E0D9',
  lightPink: '#EEC8C4',
  lime: '#E0EFBF',
  pink: '#EBC4D9',
  yellow: '#F9E8AF',
};

export const colorLevels = {
  [ExposureLevel.VeryHigh]: colors.pink,
  [ExposureLevel.High]: colors.lightPink,
  [ExposureLevel.Medium]: colors.yellow,
  [ExposureLevel.Low]: colors.lime,
  [ExposureLevel.VeryLow]: colors.green,
};

export const shadeLevels = {
  [ExposureLevel.VeryHigh]: shades.pink,
  [ExposureLevel.High]: shades.lightPink,
  [ExposureLevel.Medium]: shades.yellow,
  [ExposureLevel.Low]: shades.lime,
  [ExposureLevel.VeryLow]: shades.green,
};
