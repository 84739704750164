import React, { FC } from 'react';
import { Icon } from 'ui';

interface Props {
  extension: string;
  title: string;
}

const DocumentIcon: FC<Props> = ({ extension }) => {
  switch (extension) {
    case 'air':
      return <Icon color="primary" name="airFile" size="l" />;
    case 'csv':
    case 'xls':
    case 'xlsx':
      return <Icon color="success" name="excelFile" size="l" />;
    case 'doc':
    case 'docx':
      return <Icon color="primary" name="documentFile" size="l" />;
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'webp':
      return <Icon color="subdued" name="imageFile" size="l" />;
    case 'pdf':
      return <Icon color="danger" name="pdfFile" size="l" />;
    case 'ppt':
    case 'pptx':
      return <Icon color="danger" name="powerpointFile" size="l" />;
    case 'rms':
      return <Icon color="danger" name="rmsFile" size="l" />;
    case 'zip':
      return <Icon color="black" name="zipFile" size="l" />;
    default:
      return <Icon color="primary" name="documentFile" size="l" />;
  }
};

export default DocumentIcon;
