import React from 'react';
import { Button, EuiFlexGroup, EuiFlexItem, EuiModal, EuiModalBody, EuiText } from 'ui';
import styles from './ConfirmationDialog.emotion';

interface Props {
  confirmBodyText?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  submitLoading?: boolean;
  labelConfirm?: string;
  labelCancel?: string;
  onDecline?: () => void;
}

const ConfirmationDialog: React.FC<Props> = ({
  confirmBodyText,
  isOpen,
  onClose,
  onSubmit,
  submitLoading,
  labelConfirm,
  labelCancel,
  onDecline,
}) => {
  if (isOpen) {
    return (
      <EuiModal data-testid="confirmation-dialog" onClose={onClose}>
        <EuiModalBody className={styles.modalBody}>
          <EuiFlexGroup className={styles.container} direction="column" gutterSize="m">
            <EuiFlexItem grow={false}>
              <EuiText>{confirmBodyText || 'Are you sure?'}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem grow={false}>
                  <Button label={labelCancel || 'No'} onClick={onDecline} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Button
                    fill
                    label={labelConfirm || 'Yes'}
                    loading={submitLoading}
                    onClick={onSubmit}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiModal>
    );
  }

  return null;
};

export default ConfirmationDialog;
