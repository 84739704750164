import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProvenanceJobDataMutationVariables = Types.Exact<{
  input: Types.ProvenanceJobDataInput;
}>;


export type UpdateProvenanceJobDataMutation = { __typename?: 'Mutation', updateProvenanceJobData: string };


export const UpdateProvenanceJobDataDocument = gql`
    mutation UpdateProvenanceJobData($input: ProvenanceJobDataInput!) {
  updateProvenanceJobData(input: $input)
}
    `;
export type UpdateProvenanceJobDataMutationFn = Apollo.MutationFunction<UpdateProvenanceJobDataMutation, UpdateProvenanceJobDataMutationVariables>;

/**
 * __useUpdateProvenanceJobDataMutation__
 *
 * To run a mutation, you first call `useUpdateProvenanceJobDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProvenanceJobDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProvenanceJobDataMutation, { data, loading, error }] = useUpdateProvenanceJobDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProvenanceJobDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProvenanceJobDataMutation, UpdateProvenanceJobDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProvenanceJobDataMutation, UpdateProvenanceJobDataMutationVariables>(UpdateProvenanceJobDataDocument, options);
      }
export type UpdateProvenanceJobDataMutationHookResult = ReturnType<typeof useUpdateProvenanceJobDataMutation>;
export type UpdateProvenanceJobDataMutationResult = Apollo.MutationResult<UpdateProvenanceJobDataMutation>;
export type UpdateProvenanceJobDataMutationOptions = Apollo.BaseMutationOptions<UpdateProvenanceJobDataMutation, UpdateProvenanceJobDataMutationVariables>;