import { useContext, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
// FIX ME
// @ts-ignore
import CREATE_STREAM from '@app/queries/streams/createStream.gql';
import { ICreateStreamData, ICreateStreamVariables } from '@app/queries/streams/inputs';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import onSave from './onSave';
import { getDefaultValues, IForm, isValid, nameToValue } from './utils';

interface Return {
  closeModal: () => void;
  dontRender: boolean;
  handleSave: (values: IForm) => void;
  isSaving: boolean;
  onValueChange: (field: string) => (event: any) => void;
  triggerValueChange: (newValues: Partial<IForm>) => void;
  showSaveButton: boolean;
  values: IForm;
}

export const useAddStreamModal = (): Return => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name;
  const toast = useToast();
  const { closeModal } = useContext(ModalContext);

  const [createStream, createStreamResult] = useMutation<ICreateStreamData, ICreateStreamVariables>(
    CREATE_STREAM,
    {
      onError: (err) => {
        toast({ title: getErrorMessage(err), type: 'danger' });
      },
    },
  );

  const defaultValues = getDefaultValues();
  const [values, setValues] = useState<IForm>(defaultValues);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(isValid(defaultValues));

  const handleSave = (finalValues: IForm) => {
    onSave({
      closeModal,
      createStream,
      organizationName,
      setShowSaveButton,
      values: finalValues,
    });
  };

  const triggerValueChange = (fields) => {
    const newValues = { ...values, ...fields };
    setValues(newValues);
    setShowSaveButton(isValid(newValues));
  };

  const onValueChange = (field: string) => (event: any) => {
    const newValue = nameToValue(field, event);
    const newValues = { ...values, [field]: newValue };
    setValues(newValues);
    setShowSaveButton(isValid(newValues));
  };

  return {
    closeModal,
    dontRender: !account || !organizationName,
    handleSave,
    isSaving: createStreamResult.loading,
    onValueChange,
    showSaveButton,
    triggerValueChange,
    values,
  };
};
