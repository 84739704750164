import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  EnrichmentOverallQualityChangeData,
  EnrichmentOverallQualityChangeVariables,
  GET_ENRICHMENT_OVERALL_QUALITY_CHANGE,
} from '@onarchipelago/cx/Reports/queries/getEnrichmentOverallQualityChange';
import { EuiFlexGroup } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';
import Line from '../../ReadinessReport/Line';
import Benchmark from './Benchmark';
import PropertyMetrics from './PropertyMetrics';

const Summary: FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { afterSnapshot, currentSnapshot } = snapshots;

  const { data, loading, error } = useQuery<
    EnrichmentOverallQualityChangeData,
    EnrichmentOverallQualityChangeVariables
  >(GET_ENRICHMENT_OVERALL_QUALITY_CHANGE, {
    variables: {
      input: {
        snapshotNameAfter: afterSnapshot?.snapshotName || '',
        snapshotNameBefore: currentSnapshot?.snapshotName || '',
        streamSlug: stream.streamSlug,
      },
    },
  });

  if (loading && !error) {
    return <LoadingSpinnerV2 styles={{ height: '470px' }} />;
  }

  if (!loading && error) {
    return <ErrorState />;
  }

  if (data && !loading && !error) {
    return (
      <EuiFlexGroup direction="row">
        <Benchmark enrichmentOverallQualityChangeData={data.enrichmentOverallQualityChange} />
        <Line />
        <PropertyMetrics enrichmentOverallQualityChangeData={data.enrichmentOverallQualityChange} />
      </EuiFlexGroup>
    );
  }

  return null;
};

export default Summary;
