import React from 'react';
import {
  ButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiTitle,
} from 'ui';
import { useCopilotContext } from './context/context';
import { ConfigTab } from './tabs/ConfigTab/ConfigTab';
import { PropertyTab } from './tabs/PropertyTab/PropertyTab';
import { WorkspaceContainer } from './Copilot.emotion';

interface Props {
  id: string;
  configFile: string;
}

const WORKSPACE_LABEL_MAP = {
  workspace1: 'Workspace 1',
  workspace2: 'Workspace 2',
};
export const Workspace: React.FC<Props> = ({ id, configFile }) => {
  const { workspaceData, partialUpdateWorkspaceSettings } = useCopilotContext();

  const renderBody = () => {
    const w = workspaceData;
    switch (w[id].currentTab) {
      case 'config':
        return <ConfigTab configFile={configFile} />;

      case 'property':
        return <PropertyTab workspaceID={id} />;
    }
  };

  const setCurrentTab = (currentTab: string) => {
    partialUpdateWorkspaceSettings(id, { currentTab });
  };

  return (
    <WorkspaceContainer>
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiHeader>
            <EuiHeaderSection grow>
              <EuiHeaderSectionItem>
                <EuiTitle size="xs">
                  <h4>{WORKSPACE_LABEL_MAP[id] || id}</h4>
                </EuiTitle>
              </EuiHeaderSectionItem>
            </EuiHeaderSection>
            <EuiHeaderSection grow={false}>
              <EuiHeaderSectionItem>
                <ButtonIcon
                  color="primary"
                  iconName="property"
                  onClick={() => setCurrentTab('property')}
                />
              </EuiHeaderSectionItem>
              <EuiHeaderSectionItem>
                <ButtonIcon iconName="gear" onClick={() => setCurrentTab('config')} />
              </EuiHeaderSectionItem>
            </EuiHeaderSection>
          </EuiHeader>
        </EuiFlexItem>
        <WorkspaceContainer>{renderBody()}</WorkspaceContainer>
      </EuiFlexGroup>
    </WorkspaceContainer>
  );
};
