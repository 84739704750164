import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyEditPropertyJobMutationVariables = Types.Exact<{
  input: Types.ApplyEditPropertyJobInput;
}>;


export type ApplyEditPropertyJobMutation = { __typename?: 'Mutation', applyEditPropertyJob: { __typename?: 'Job', id: string, name: string, status: Types.JobStatus, propertyIDs?: Array<any> | null, propertyLockGroupID?: string | null, attributeNames?: Array<string> | null } };


export const ApplyEditPropertyJobDocument = gql`
    mutation ApplyEditPropertyJob($input: ApplyEditPropertyJobInput!) {
  applyEditPropertyJob(input: $input) {
    id
    name
    status
    propertyIDs
    propertyLockGroupID
    attributeNames
  }
}
    `;
export type ApplyEditPropertyJobMutationFn = Apollo.MutationFunction<ApplyEditPropertyJobMutation, ApplyEditPropertyJobMutationVariables>;

/**
 * __useApplyEditPropertyJobMutation__
 *
 * To run a mutation, you first call `useApplyEditPropertyJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyEditPropertyJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyEditPropertyJobMutation, { data, loading, error }] = useApplyEditPropertyJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyEditPropertyJobMutation(baseOptions?: Apollo.MutationHookOptions<ApplyEditPropertyJobMutation, ApplyEditPropertyJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyEditPropertyJobMutation, ApplyEditPropertyJobMutationVariables>(ApplyEditPropertyJobDocument, options);
      }
export type ApplyEditPropertyJobMutationHookResult = ReturnType<typeof useApplyEditPropertyJobMutation>;
export type ApplyEditPropertyJobMutationResult = Apollo.MutationResult<ApplyEditPropertyJobMutation>;
export type ApplyEditPropertyJobMutationOptions = Apollo.BaseMutationOptions<ApplyEditPropertyJobMutation, ApplyEditPropertyJobMutationVariables>;