import styled from '@emotion/styled';
import { EuiText } from 'ui';

export const ExplorerTitleContainer = styled.div`
  width: 100%;

  p {
    // to offset the scroll width
    margin-left: -17px;
    display: block;
    color: black;
    text-align: center;
    width: 100%;
  }
`;

export const ExplorerTitleText = styled(EuiText)`
  line-height: 32px !important;
  z-index: 10;
  height: 25px;
  text-align: center;
  width: 100%;
  > div {
    font-size: 14px !important;
  }
`;
