import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateChangeByStreamReportMutationVariables = Types.Exact<{
  input: Types.ValidateChangeByStreamInput;
}>;


export type ValidateChangeByStreamReportMutation = { __typename?: 'Mutation', validateChangeByStreamReport: { __typename?: 'ValidateChangeReportOutput', downloadUrl?: string | null } };


export const ValidateChangeByStreamReportDocument = gql`
    mutation validateChangeByStreamReport($input: ValidateChangeByStreamInput!) {
  validateChangeByStreamReport(input: $input) {
    downloadUrl
  }
}
    `;
export type ValidateChangeByStreamReportMutationFn = Apollo.MutationFunction<ValidateChangeByStreamReportMutation, ValidateChangeByStreamReportMutationVariables>;

/**
 * __useValidateChangeByStreamReportMutation__
 *
 * To run a mutation, you first call `useValidateChangeByStreamReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateChangeByStreamReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateChangeByStreamReportMutation, { data, loading, error }] = useValidateChangeByStreamReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateChangeByStreamReportMutation(baseOptions?: Apollo.MutationHookOptions<ValidateChangeByStreamReportMutation, ValidateChangeByStreamReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateChangeByStreamReportMutation, ValidateChangeByStreamReportMutationVariables>(ValidateChangeByStreamReportDocument, options);
      }
export type ValidateChangeByStreamReportMutationHookResult = ReturnType<typeof useValidateChangeByStreamReportMutation>;
export type ValidateChangeByStreamReportMutationResult = Apollo.MutationResult<ValidateChangeByStreamReportMutation>;
export type ValidateChangeByStreamReportMutationOptions = Apollo.BaseMutationOptions<ValidateChangeByStreamReportMutation, ValidateChangeByStreamReportMutationVariables>;