import React, { FC, ReactNode } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiStat, EuiToolTip, Icon } from 'ui';
import Currency from '@app/components/Currency/Currency';
import { BRCSummary } from '../types';
import {
  OVERALL_DIFFERENCE_DOLLAR_TITLE,
  OVERALL_DIFFERENCE_DOLLAR_TOOLTIP,
  OVERALL_DIFFERENCE_PERCENT_TITLE,
  OVERALL_DIFFERENCE_PERCENT_TOOLTIP,
} from './constants';
import {
  contentContainer,
  statHeaderContainerStyle,
  statHeaderStyle,
  tooltipAnchorStyle,
} from './ProvidedvsE2ValueSummary.emotion';

interface ItemProps {
  title: string;
  value: ReactNode;
  tooltip: string;
}

const BRCComparisonItem: FC<ItemProps> = ({ title, value, tooltip }) => (
    <EuiFlexItem style={{ maxWidth: '250px' }}>
      <EuiFlexGroup direction="row" justifyContent="flexStart" className={contentContainer}>
        <EuiFlexItem>
          <EuiStat
            title={value}
            description={
              <span className={statHeaderContainerStyle}>
                <span className={statHeaderStyle}>{title}</span>
                <EuiToolTip anchorClassName={tooltipAnchorStyle} content={tooltip}>
                  <Icon color="primary" name="info" />
                </EuiToolTip>
              </span>
            }
            titleSize="m"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  );

interface ComparisonProps {
  brcSummary: BRCSummary;
}

export const BRCComparison: FC<ComparisonProps> = ({ brcSummary }) => (
    <>
      <BRCComparisonItem
        value={`${brcSummary?.BRCPercent}% RCV`}
        title={OVERALL_DIFFERENCE_PERCENT_TITLE}
        tooltip={OVERALL_DIFFERENCE_PERCENT_TOOLTIP}
      />
      <BRCComparisonItem
        value={<Currency value={brcSummary.BRCCost} displayPositiveSign></Currency>}
        title={OVERALL_DIFFERENCE_DOLLAR_TITLE}
        tooltip={OVERALL_DIFFERENCE_DOLLAR_TOOLTIP}
      />
    </>
  );
