import React, { FC } from 'react';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import StreamRender from './StreamRender/StreamRender';
import { StreamProvider } from './StreamProvider';
import { StreamPageOptions } from './types';

interface IRouteProps {
  streamSlug: string;
  action: string;
}

export const Stream: FC = () => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  const { action } = useDecodedParams<IRouteProps>();
  const options: StreamPageOptions = {
    enableDocumentsBtn: true,
    enableStreamEditBtn: isAdmin,
    enableStreamInviteBtn: true,
  };

  return (
    <StreamProvider>
      <StreamRender options={options} />
    </StreamProvider>
  );
};

export default Stream;
