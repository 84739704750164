import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserChatInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserChatInfoQuery = { __typename?: 'Query', userChatInfo?: { __typename?: 'UserChatInfo', userID: string, token: string } | null };


export const UserChatInfoDocument = gql`
    query UserChatInfo {
  userChatInfo {
    userID
    token
  }
}
    `;

/**
 * __useUserChatInfoQuery__
 *
 * To run a query within a React component, call `useUserChatInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChatInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChatInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserChatInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserChatInfoQuery, UserChatInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserChatInfoQuery, UserChatInfoQueryVariables>(UserChatInfoDocument, options);
      }
export function useUserChatInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserChatInfoQuery, UserChatInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserChatInfoQuery, UserChatInfoQueryVariables>(UserChatInfoDocument, options);
        }
export type UserChatInfoQueryHookResult = ReturnType<typeof useUserChatInfoQuery>;
export type UserChatInfoLazyQueryHookResult = ReturnType<typeof useUserChatInfoLazyQuery>;
export type UserChatInfoQueryResult = Apollo.QueryResult<UserChatInfoQuery, UserChatInfoQueryVariables>;