import { ReportCode } from '@onarchipelago/cx/Stream/types';
import { DateStyle, formatDate } from '@app/utils/format';

export const localFormatDate = (date: Date | string | undefined) => {
  if (!date) {
    return formatDate(new Date(), DateStyle.Short);
  }
  if (typeof date === 'string') {
    return date.substring(0, 10);
  }
  return formatDate(date, DateStyle.Short);
};

export const makeReportNamesReadable = (report: ReportCode): string => {
  switch (report) {
    case 'ZurichUwAR':
      return 'Underwriter Assessment Report';
    case 'DQD':
      return 'Readiness Report';
    case 'EngagementReport':
      return 'Engagement Report';
    case 'EnrichmentReport':
      return 'Enrichment Report';
    case 'ExposureSummary':
      return 'Exposure Summary';
    case 'RMAssessment':
      return 'Risk Assessment Report (RM Summary)';
    case 'ValuationSummary':
      return 'Valuation Summary';
    default:
      return 'Unknown';
  }
};
