import React, { FC } from 'react';
import { keyBy } from 'lodash';
import { Checkbox, EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import styles from './AttributeItem.emotion';
import { getItemAttributes } from './utils';

interface Props {
  isEnrichmentJob?: boolean;
  item: IGraphQLAttributeMetadata | any;
  selectedAttributes: any;
  updateSelectedAttributes: (selectedAttributes: IGraphQLAttributeMetadata[]) => void;
}

const AttributeGroupItem: FC<Props> = ({
  isEnrichmentJob = false,
  item,
  selectedAttributes,
  updateSelectedAttributes,
}) => {
  const { displayName, name } = item;

  const isSelected = selectedAttributes
    ? selectedAttributes.some(
        (a: IGraphQLAttributeMetadata) => a.name === name || a.parent === name,
      )
    : false;

  const itemAttributes = getItemAttributes(item);
  const itemAttributesMap = keyBy(itemAttributes, 'name');
  const toggleSelected = () => {
    const currentSelectedAttributes = [...(selectedAttributes || [])];
    if (!isSelected) {
      updateSelectedAttributes([...currentSelectedAttributes, ...itemAttributes]);
    } else {
      updateSelectedAttributes(
        currentSelectedAttributes.filter((attr) => !itemAttributesMap[attr.name]),
      );
    }
  };

  return (
    <div
      data-testid="attribute-group-item"
      className={[styles.buttonContainer, styles.groupItemContainer].join(' ')}
    >
      <EuiFlexGroup
        className={styles.groupItemFlexGroupContainer}
        alignItems="center"
        justifyContent="spaceBetween"
      >
        <EuiFlexItem grow={false}>
          <EuiText className={[styles.groupItemText(isEnrichmentJob), styles.text].join(' ')}>
            {displayName}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Checkbox
            id={`attribute-group-item-checkbox-${displayName}`}
            checked={isSelected}
            onChange={toggleSelected}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default AttributeGroupItem;
