const selectInputStyles = `
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  min-width: 100px;
  margin-right: 5px;
`;

export const combinatorSelectStyles = `
  ${selectInputStyles}
  // Hides the combinator button
  display: none;
`;

const addRemoveButtonStyles = `
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;
`;

export const addRuleButtonStyles = `
  bottom: 110px;
  left: 30px;
  position: absolute;
`;

export const fieldsSelectStyles = `
  ${selectInputStyles}
  margin-right: 5px;
`;

export const operatorsSelectStyles = `
  ${selectInputStyles}
  margin-right: 5px;
`;

export const queryBuilderStyles = `
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 500px;
`;

const removeButtonStyles = `
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;

  &:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }
`;

export const removeGroupButtonStyles = `
  ${addRemoveButtonStyles}
  ${removeButtonStyles}
  margin-right: 5px;
`;

export const removeRuleButtonStyles = `
  ${addRemoveButtonStyles}
  ${removeButtonStyles}
  margin-right: 5px;
`;

export const ruleStyles = `
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  width: 100%;
`;

export const ruleGroupStyles = `
  margin-top: 0.5rem;
  width: 100%;
`;

export const valueInputStyles = `
  ${selectInputStyles}
  margin-right: 5px;
`;
