import React from 'react';
import styled from '@emotion/styled';
// FIX ME
// @ts-ignore
import roofCentroidIcon from './icons/geocode-roof-centroid.svg';
// FIX ME
// @ts-ignore
import unknownIcon from './icons/geocode-unknown-res.svg';
import { getProvenance, ICell } from './Cell';
import Img from './Img';

const Container = styled.div`
  label: GeocodeContainer;
  text-align: center;
`;

const getGeocode = (geocode: string) =>
  ({
    Address: unknownIcon, // TODO - update with new icon
    County: unknownIcon, // TODO - update with new icon
    'Postal Code': unknownIcon, // TODO - update with new icon
    'Roof Centroid': roofCentroidIcon,
    State: unknownIcon, // TODO - update with new icon
    Street: unknownIcon, // TODO - update with new icon
  }[geocode] || unknownIcon);

export const formatGeoCode = (geoCodeType: string): string => {
  // making sure that the string starts with a letter.
  // Other then that we should accept everything after that letter.
  const regex = /[a-zA-Z][a-zA-Z0-9_ !@#$%^&*()'",.;:<>]*/g;

  const matches = regex.exec(geoCodeType);
  return matches && matches.length > 0 ? matches[0] : '';
};

const Geocode = (props: ICell) => {
  const id = props?.column?.id;
  const provenance = getProvenance(props.prop, id);
  const geoCode = formatGeoCode(props?.prop?.geoCodeType || 'Unknown');

  const tooltip = {
    description: undefined,
    provenance,
    title: geoCode,
  };

  const body = (
    <Container>
      {/* @ts-ignore */}
      <Img src={getGeocode(geoCode)} alt={geoCode} title="" />
    </Container>
  );

  return { body, tooltip };
};

export default Geocode;
