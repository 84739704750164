// https://www.figma.com/file/LwsZqzn42uDjeEOkFSxsbZAa/Archipelago-Analytics-Colors?node-id=0%3A1
const colors = {
  blue2: '#2D9CDB',
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',
};

export default colors;
