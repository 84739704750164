import React from 'react';
import { formatBoolean } from '@app/utils/format';
import { getEdits, getProvenance, ICell } from './Cell';

const Boolean = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const formatted = formatBoolean(props.value, '-');
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop),
    provenance,
    title: undefined,
  };

  const body = <span>{formatted}</span>;

  return { body, tooltip };
};

export default Boolean;
