import gql from 'graphql-tag';

export const GET_ORGANIZATION_DOCUMENTS_CLASSIFICATIONS_INFO = gql`
  query organizationDocumentsClassificationsInfo($filter: OrgDocumentFilter) {
    organizationDocumentsClassificationsInfo(filter: $filter) {
      classification
      count
    }
  }
`;
