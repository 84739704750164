import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckSovJobWorksheetMutationVariables = Types.Exact<{
  input: Types.CheckSovJobWorksheetInput;
}>;


export type CheckSovJobWorksheetMutation = { __typename?: 'Mutation', checkSOVJobWorksheet: { __typename?: 'Job', id: string, status: Types.JobStatus, type: Types.JobType, orgName: string, details?: string | null } };


export const CheckSovJobWorksheetDocument = gql`
    mutation checkSOVJobWorksheet($input: CheckSOVJobWorksheetInput!) {
  checkSOVJobWorksheet(input: $input) {
    id
    status
    type
    orgName
    details
  }
}
    `;
export type CheckSovJobWorksheetMutationFn = Apollo.MutationFunction<CheckSovJobWorksheetMutation, CheckSovJobWorksheetMutationVariables>;

/**
 * __useCheckSovJobWorksheetMutation__
 *
 * To run a mutation, you first call `useCheckSovJobWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSovJobWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSovJobWorksheetMutation, { data, loading, error }] = useCheckSovJobWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckSovJobWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<CheckSovJobWorksheetMutation, CheckSovJobWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckSovJobWorksheetMutation, CheckSovJobWorksheetMutationVariables>(CheckSovJobWorksheetDocument, options);
      }
export type CheckSovJobWorksheetMutationHookResult = ReturnType<typeof useCheckSovJobWorksheetMutation>;
export type CheckSovJobWorksheetMutationResult = Apollo.MutationResult<CheckSovJobWorksheetMutation>;
export type CheckSovJobWorksheetMutationOptions = Apollo.BaseMutationOptions<CheckSovJobWorksheetMutation, CheckSovJobWorksheetMutationVariables>;