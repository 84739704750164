import { css } from '@emotion/css';

const modal = css`
  max-height: 75vh;
  max-width: 1200px !important;
  min-height: 50vh;
  width: 504px;
`;

const contentForm = css`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const contentFlexGroup = css`
  height: 100%;
  margin-right: 16px;
  padding-top: 16px;
`;

export default {
  contentFlexGroup,
  contentForm,
  modal,
};
