import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrganizationQueryVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  id: Types.Scalars['ID']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', createdBy: string, id: string, name: string, reportingType: Types.OrgReportingType, orgType: Types.OrgType, orgLevel: Types.OrgLevelType, salesChannel?: string | null, salesforceAccountID?: string | null, tags: Array<string>, autoDomains?: Array<string> | null, displayCurrency?: string | null, enableUploadPortal: boolean, enableStreamDashboard: boolean, enableSubValues: boolean, enableCurrencyConversion: boolean, enableSecondaryModifiers: boolean, enableRiskManagerAssessment: boolean, enableCarrierAssessment: boolean, showNonEnrichmentDocs: boolean, enableUnderwriterEdit: boolean, excludeFromReporting: boolean, enableSendEmailOnSOVUpload: boolean, enableRedirectOnSOVUpload: boolean, industry?: string | null, businessType?: string | null, unitOfMeasurement: string }, orgExportConfig: { __typename?: 'OrgExportConfig', carrierExports?: Array<Types.CarrierExportCode> | null }, orgPropertyConfig: { __typename?: 'OrgPropertyConfig', enableLosses: boolean, lossStartYear?: number | null }, orgSubmissionConfig: { __typename?: 'OrgSubmissionConfig', enableDashboard: boolean, templateOrgName?: string | null } };


export const GetOrganizationDocument = gql`
    query GetOrganization($name: String!, $id: ID!) {
  organization(name: $name) {
    createdBy
    id
    name
    reportingType
    orgType
    orgLevel
    salesChannel
    salesforceAccountID
    tags
    autoDomains
    displayCurrency
    enableUploadPortal
    enableStreamDashboard
    enableSubValues
    enableCurrencyConversion
    enableSecondaryModifiers
    enableRiskManagerAssessment
    enableCarrierAssessment
    showNonEnrichmentDocs
    enableUnderwriterEdit
    excludeFromReporting
    enableSendEmailOnSOVUpload
    enableRedirectOnSOVUpload
    industry
    businessType
    unitOfMeasurement
  }
  orgExportConfig(input: {orgId: $id}) {
    carrierExports
  }
  orgPropertyConfig(input: {orgId: $id}) {
    enableLosses
    lossStartYear
  }
  orgSubmissionConfig(input: {orgName: $name}) {
    enableDashboard
    templateOrgName
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;