import { css } from '@emotion/css';

const table = css`
  thead {
    display: none;
  }
  .euiTableRow {
    background: #f5f7fa;
    &:hover {
      background: #f5f7fa;
    }
  }
  .euiTableRow:first-child {
    .euiTableRowCell {
      border-top: none;
    }
  }
  .euiTableRow:last-child {
    .euiTableRowCell {
      border-bottom: none;
    }
  }
`;

export default {
  table,
};
