import { Dispatch, SetStateAction } from 'react';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { PanelItem } from './types';
import useAddLossDataPanel from './useAddLossDataPanel';
import useEditLossDataPanel from './useEditLossDataPanel';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export default ({ inProgressJobs, setOpen, refetch }: Props): PanelItem => {
  const AddLossDataPanel = useAddLossDataPanel({ inProgressJobs, setOpen });
  const EditLossDataPanel = useEditLossDataPanel({ inProgressJobs, refetch, setOpen });

  return {
    'data-testid': 'manage-losses-button',
    iconName: 'trendingDown',
    label: 'Loss data',
    nestedPanel: {
      items: [EditLossDataPanel, AddLossDataPanel],
      title: 'Loss Data',
    },
  };
};
