import { DocumentsContext } from '../../types/contextTypes';
import addToQueryString from './addToQueryString';
import getStateFromQueryString from './getStateFromQueryString';

export default (
  filters: DocumentsContext['filters'],
  navigation: DocumentsContext['navigation'],
) => {
  // analyze what filters have been been applied and update the query string based on those filters
  addToQueryString(filters, navigation);

  // analyze the query string that is in the browser when the user starts their session, and use
  // that query string to update the context state
  getStateFromQueryString(filters, navigation);
};
