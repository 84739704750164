export const titleContainerStyles = {
  marginBottom: '20px',
};

export const buttonContainerStyles = {
  marginLeft: '0px',
  marginRight: '0px',
};

export const buttonLabelStyles = {
  fontSize: '14px',
  fontWeight: 600,
  marginRight: '8px',
};
