import React, { FC, ReactNode } from 'react';
import {
  Button,
  ButtonEmpty,
  EuiFlexItem,
  EuiForm,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from 'ui';
import { ChildrenContainer, Modal } from './ModalWithButtons.emotion';

interface ModalWithButtonsProps {
  onClose: () => void;
  header: string;
  subtext?: string;
  buttonActionLabel?: string;
  children?: ReactNode;
  onClick?: () => void;
  isValid?: boolean;
  buttonActionVariant?: string;
  isLoading?: boolean;
  formId?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  buttonCloseLabel?: string;
}

export const ModalWithButtons: FC<ModalWithButtonsProps> = ({
  onClose,
  header,
  subtext,
  buttonActionLabel,
  children,
  onClick,
  isValid = true,
  buttonActionVariant = 'danger',
  isLoading,
  formId,
  onSubmit,
  buttonCloseLabel,
}) => (
  <EuiForm onSubmit={onSubmit} component={formId ? 'form' : undefined}>
    <Modal data-testId={formId} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{header}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {subtext && (
          <EuiFlexItem>
            <EuiText size="s">{subtext}</EuiText>
          </EuiFlexItem>
        )}
        <ChildrenContainer>{children}</ChildrenContainer>
      </EuiModalBody>
      <EuiModalFooter>
        <ButtonEmpty
          size="m"
          onClick={onClose}
          label={buttonCloseLabel || 'Cancel'}
          loading={isLoading}
        />
        {onClick && (
          <Button
            type={formId ? 'submit' : 'button'}
            loading={isLoading}
            disabled={!isValid}
            size="m"
            color={buttonActionVariant}
            fill
            label={buttonActionLabel}
            onClick={onClick}
          />
        )}
      </EuiModalFooter>
    </Modal>
  </EuiForm>
);
