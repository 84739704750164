import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetAttributeMetadataAutoMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SetAttributeMetadataAutoInput>;
}>;


export type SetAttributeMetadataAutoMutation = { __typename?: 'Mutation', setAttributeMetadataAuto: { __typename?: 'SetAttributeMetadataAutoResult', updated: boolean, enabledAttributes: Array<{ __typename?: 'OrgAttributeMetadata', organizationId: string, streamId: string, filterable?: boolean | null, groupable?: boolean | null, includeInStreams: boolean, metadata: { __typename?: 'AttributeMetadata', maxThreshold?: number | null, decimals?: number | null, dimension?: string | null, subdimension?: string | null, isCustom?: boolean | null, hiddenInGrid: boolean, scope?: string | null, derived: boolean, name: string, displayName: string, columnPosition: number, displayWidth: number, source?: string | null, parent?: string | null, dataType: string } } | null>, disabledAttributes: Array<{ __typename?: 'OrgAttributeMetadata', organizationId: string, streamId: string, filterable?: boolean | null, groupable?: boolean | null, includeInStreams: boolean, metadata: { __typename?: 'AttributeMetadata', maxThreshold?: number | null, decimals?: number | null, dimension?: string | null, subdimension?: string | null, isCustom?: boolean | null, hiddenInGrid: boolean, scope?: string | null, derived: boolean, name: string, displayName: string, columnPosition: number, displayWidth: number, source?: string | null, parent?: string | null, dataType: string } } | null> } };


export const SetAttributeMetadataAutoDocument = gql`
    mutation SetAttributeMetadataAuto($input: SetAttributeMetadataAutoInput) {
  setAttributeMetadataAuto(input: $input) {
    enabledAttributes {
      organizationId
      streamId
      filterable
      groupable
      includeInStreams
      metadata {
        maxThreshold
        decimals
        dimension
        subdimension
        isCustom
        hiddenInGrid
        scope
        derived
        name
        displayName
        columnPosition
        displayWidth
        source
        parent
        dataType
      }
    }
    disabledAttributes {
      organizationId
      streamId
      filterable
      groupable
      includeInStreams
      metadata {
        maxThreshold
        decimals
        dimension
        subdimension
        isCustom
        hiddenInGrid
        scope
        derived
        name
        displayName
        columnPosition
        displayWidth
        source
        parent
        dataType
      }
    }
    updated
  }
}
    `;
export type SetAttributeMetadataAutoMutationFn = Apollo.MutationFunction<SetAttributeMetadataAutoMutation, SetAttributeMetadataAutoMutationVariables>;

/**
 * __useSetAttributeMetadataAutoMutation__
 *
 * To run a mutation, you first call `useSetAttributeMetadataAutoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAttributeMetadataAutoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAttributeMetadataAutoMutation, { data, loading, error }] = useSetAttributeMetadataAutoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAttributeMetadataAutoMutation(baseOptions?: Apollo.MutationHookOptions<SetAttributeMetadataAutoMutation, SetAttributeMetadataAutoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAttributeMetadataAutoMutation, SetAttributeMetadataAutoMutationVariables>(SetAttributeMetadataAutoDocument, options);
      }
export type SetAttributeMetadataAutoMutationHookResult = ReturnType<typeof useSetAttributeMetadataAutoMutation>;
export type SetAttributeMetadataAutoMutationResult = Apollo.MutationResult<SetAttributeMetadataAutoMutation>;
export type SetAttributeMetadataAutoMutationOptions = Apollo.BaseMutationOptions<SetAttributeMetadataAutoMutation, SetAttributeMetadataAutoMutationVariables>;