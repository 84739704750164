import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetHazardsMutationVariables = Types.Exact<{
  input: Types.SetHazardsInput;
}>;


export type SetHazardsMutation = { __typename?: 'Mutation', setHazards?: string | null };


export const SetHazardsDocument = gql`
    mutation SetHazards($input: SetHazardsInput!) {
  setHazards(input: $input)
}
    `;
export type SetHazardsMutationFn = Apollo.MutationFunction<SetHazardsMutation, SetHazardsMutationVariables>;

/**
 * __useSetHazardsMutation__
 *
 * To run a mutation, you first call `useSetHazardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHazardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHazardsMutation, { data, loading, error }] = useSetHazardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetHazardsMutation(baseOptions?: Apollo.MutationHookOptions<SetHazardsMutation, SetHazardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHazardsMutation, SetHazardsMutationVariables>(SetHazardsDocument, options);
      }
export type SetHazardsMutationHookResult = ReturnType<typeof useSetHazardsMutation>;
export type SetHazardsMutationResult = Apollo.MutationResult<SetHazardsMutation>;
export type SetHazardsMutationOptions = Apollo.BaseMutationOptions<SetHazardsMutation, SetHazardsMutationVariables>;