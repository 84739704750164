import styled from '@emotion/styled';
import { EuiFlexItem, EuiText } from 'ui';

export const BenchmarkTitle = styled(EuiFlexItem)`
  margin-bottom: 0 !important;
`;

export const BenchmarkSubtitle = styled(EuiText)`
  margin-top: 6px !important;
`;

export const BenchmarkChartContainer = styled(EuiFlexItem)`
  height: 250px;
`;
