export interface BRCSummary {
  BRCPercent: string;
  BRCCost: string;
}

export interface BRCComparison {
  customerValue: string;
  thirdPartyValue: string;
}

export type FilterOptionsAttributeValueType = {
  attribute: string;
  values: Array<string>;
};

export type QueryFilterInputType = {
  attribute: string;
  value: string;
};

type OptionType = {
  label: string;
  value: string;
};

export type FilterOptionsType = {
  occupancyOptions: Array<OptionType>;
  constructionOptions: Array<OptionType>;
  stateOptions: Array<OptionType>;
};

export enum TableSortOptionsType {
  BRCDiffHighLow = 'BRCPercentDiffHighLow',
  BRCDiffLowHigh = 'BRCPercentDiffLowHigh',
  BRCCurrencyDiffLowHigh = 'BRCCurrencyDiffLowHigh',
  BRCCurrencyDiffHighLow = 'BRCCurrencyDiffHighLow',
}

export enum PropertyValuationSortAttribute {
  BRCPercentOfTotal = 'BRCPercentOfTotal',
  BRC = 'BRC',
  BRCPercentDiff = 'BRCPercentDiff',
  BRCValueDiff = 'BRCValueDiff',
}
