import React, { FC, MouseEvent, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { IPageState } from '@onarchipelago/cx/Stream/types';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
// FIX ME
// @ts-ignore
import GET_STREAM_RISK_SUMMARY from '@app/queries/streams/getStreamRiskSummary.gql';
import {
  IGetStreamRiskSummaryData,
  IGetStreamRiskSummaryVariables,
  RiskSummaryChartData,
} from '@app/queries/streams/types';
import ErrorState from '../../components/ErrorState';
import GeographyHazardsTab from './RiskTabs/GeographyHazardsTab';
import LossesTab from './RiskTabs/LossesTab';
import OccupancyConstructionTab from './RiskTabs/OccupancyConstructionTab';
import { RiskSummaryTab, RiskSummaryTabContainer, RiskSummaryTitle } from './RiskSummary.emotion';

const mockTabs = [
  { group: 'Geography and Hazards' },
  { group: 'Occupancy and Construction' },
  { group: 'Losses' },
];

const geographyHazardTypes = ['TOP_3_COUNTRIES_BY_TIV', 'TOP_3_STATES_BY_TIV', 'HAZARD_GEOGRAPHY'];
const occupancyConstructionTypes = [
  'GENERAL_CONSTRUCTION_TYPES_BY_TIV',
  'SPECIFIC_OCCUPANCIES_BY_TIV',
  'SPRINKLER_PROTECTION_BREAKDOWN_BY_TIV',
];
const lossesTypes = ['LOSS_SEVERITY_OVER_TIME', 'LOSS_FREQUENCY_OVER_TIME', 'LOSS_COST_OVER_TIME'];

type NullOr<T> = null | T;

interface IProps {
  pageState?: IPageState;
  riskSummaryTab?: NullOr<string>;
}

const RiskSummary: FC<IProps> = ({ riskSummaryTab }) => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const { currentSnapshot } = snapshots;
  const snapshotName = currentSnapshot?.snapshotName || '';

  const { data, loading, error } = useQuery<
    IGetStreamRiskSummaryData,
    IGetStreamRiskSummaryVariables
  >(GET_STREAM_RISK_SUMMARY, {
    variables: {
      snapshotName,
      streamSlug,
    },
  });

  const geographyHazardData: RiskSummaryChartData[] = [];
  const occupancyConstructionData: RiskSummaryChartData[] = [];
  const lossesData: RiskSummaryChartData[] = [];

  if (data) {
    data.streamRiskSummary.charts.forEach((chart) => {
      if (geographyHazardTypes.includes(chart.name)) {
        geographyHazardData.push(chart);
      } else if (occupancyConstructionTypes.includes(chart.name)) {
        occupancyConstructionData.push(chart);
      } else if (lossesTypes.includes(chart.name)) {
        lossesData.push(chart);
      }
    });
  }

  const [activeTab, setActiveTab] = useState<string>(riskSummaryTab || 'Geography and Hazards');

  if (loading) {
    return <LoadingSpinnerV2 styles={{ height: '743px' }} />;
  }

  const handleTabSelect = (selectedTab: string) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setActiveTab(selectedTab);
  };

  const Tabs: React.FC<{ tabs: Array<any> }> = ({ tabs }) => (
    <>
      {tabs.map(({ group }) => (
        <RiskSummaryTab
          key={group}
          active={group === activeTab}
          onClick={handleTabSelect(group)}
          data-testid={`${group}-tab`}
        >
          <EuiText>
            <p>{group}</p>
          </EuiText>
        </RiskSummaryTab>
      ))}
    </>
  );

  const tabIndex = mockTabs.map((e) => e.group).indexOf(activeTab);
  const prevTabIdx = tabIndex - 1 >= 0 ? tabIndex - 1 : -1;
  const nextTabIdx = tabIndex + 1 <= mockTabs.length - 1 ? tabIndex + 1 : mockTabs.length;
  const prevTab = prevTabIdx >= 0 ? mockTabs[prevTabIdx].group : activeTab;
  const nextTab = nextTabIdx < mockTabs.length ? mockTabs[nextTabIdx].group : activeTab;

  const RiskSummaryContent: React.FC<{
    selectedTab: string;
  }> = ({ selectedTab }) => {
    if (error) {
      return <ErrorState />;
    }
    switch (selectedTab) {
      case 'Geography and Hazards':
        return <GeographyHazardsTab data={geographyHazardData} />;
      case 'Occupancy and Construction':
        return <OccupancyConstructionTab data={occupancyConstructionData} />;
      case 'Losses':
        return <LossesTab data={lossesData} />;
      default:
        return <> </>;
    }
  };

  return (
    <>
      <EuiFlexItem grow={false} style={{ margin: 0 }}>
        <EuiFlexGroup direction="row" justifyContent="flexStart">
          <EuiFlexItem>
            <EuiText>
              <RiskSummaryTitle data-testid="ua-risk-summary-header">Risk Summary</RiskSummaryTitle>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup direction="column" justifyContent="spaceBetween" gutterSize="none">
          <EuiFlexItem>
            <RiskSummaryTabContainer>
              <Tabs tabs={mockTabs} />
            </RiskSummaryTabContainer>
          </EuiFlexItem>
          <EuiFlexItem style={{ margin: 0, padding: '25px 0px' }}>
            <RiskSummaryContent selectedTab={activeTab} />
          </EuiFlexItem>
          <EuiFlexItem style={{ margin: 0 }}>
            <EuiFlexGroup
              direction="row"
              alignItems="flexEnd"
              justifyContent="spaceBetween"
              gutterSize="xl"
              style={{ borderTop: '1px solid #E5E5E5', height: 48, margin: '0px -24px' }}
            >
              <EuiFlexItem style={{ margin: '0px 24px' }}>
                <EuiText size="s">
                  <EuiLink
                    color="primary"
                    onClick={handleTabSelect(prevTab)}
                    style={{ lineHeight: '24px', textDecorationLine: 'underline' }}
                  >
                    {prevTab === activeTab ? '' : `← Go to ${prevTab}`}
                  </EuiLink>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ margin: '0px 24px' }}>
                <EuiText size="s">
                  <EuiLink
                    color="primary"
                    onClick={handleTabSelect(nextTab)}
                    style={{ lineHeight: '24px', textDecorationLine: 'underline' }}
                  >
                    {nextTab === activeTab ? '' : `Go to ${nextTab} →`}
                  </EuiLink>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};

export default RiskSummary;
