import { TaskDetailsQuery } from '@app/graphql/queries/workflow/__generated__/taskDetails.generated';
import { Property } from '@app/graphql/types';
import { ParsedTask } from '../InboxDetailsContent/InboxDetailsContent';

export const getDescription = (attr: string): string => {
  const definitionLookup: { [key: string]: string } = {
    acquiredOrBuilt:
      'Provides information on whether a property was acquired or built by the customer.  ',
    backupGenerator:
      'Indicates whether a generator is present in the building for emergency power.',
    backupGeneratorDieselStorageCapacity:
      'Details the amount of fuel stored in the building for generator, fire pump and other uses.',
    backupGeneratorLocation:
      'Provides location of back-up generator as to if it is in the building, outside, roof or basement. ',
    baseFloodElevation: 'Computed elevation of the 100 year flood event.',
    buildingCondition:
      'Defines the overall condition of the building.  Buildings that exhibit signs of distress, such as cracking, fatigue and rotten structural members can to experience an increased amount of damage during events.',
    buildingExteriorOpening:
      'This building attribute is only associated with shear wall buildings. The percentage of area of a building\'s exterior wall surface that consists of window and door openings. Buildings with more than 50% of the wall open will like sustain increased damage during an earthquake',
    buildingFootprintClass:
      'Building footprints can be regular or irregular. Irregular buildings tend to twist during an earthquake and can experience significant damage. Severe damage can also occurs at the re-entrant corners between wings of an irregularly shaped building.',
    buildingFoundationType:
      'Define the building\'s foundation type.   Foundations that have not been designed by an engineer or poorly constructed can lead to increased damage from the event.',
    buildingRedundancy:
      'Indicates whether there are multiple lateral load-resisting elements (frames or shear walls) in the building',
    buildingSprinklerType:
      'The building sprinkler systems are typically defined as wet or dry. Wet pipe sprinkler systems, in which the sprinkler piping is filled with water under pressure, are more common than dry pipe sprinkler systems, in which the sprinkler piping is filled with pressurized air until the system is activated',
    buildingSprinklered: 'Defines the presence of sprinkler systems within the building.',
    constructionQuality:
      'Defines the Construction quaility.  Construction quality is characterized by workmanship and/or the use of quality construction materials. Even if a building is designed to be sufficiently earthquake resistant, poor construction practices can lead to excessive damage',
    constructionType:
      'Defines the building construction class  based on the load bearing elements of the structure.',
    contentsVulnerabilityFlood: 'Defines of the vulnerability of the building contents to flood.',
    contentsVulnerabilityVerticalDistributionOfContents:
      'Details how the contents are distributed at different levels throughout the building. This is important for flooding or hurricane-induced storm surge.',
    contentsVulnerabilityWind:
      'Defines of the vulnerability of the building contents to windstorm events.',
    crippleWalls:
      'A cripple wall is a short wall that extends from the top of a building’s foundation to the underside of the first floor. Cripple walls are found almost exclusively in wood frame residential construction. These walls,  have minimal shear resistance and, consequently, perform poorly in earthquakes.',
    distanceToNearestBuilding:
      '‘Pounding’ occurs when there is little or no distance between adjacent buildings, allowing the buildings to impact against each other as they sway during an earthquake.  Fire models also need to know the building separation to understand the potential for fire spread',
    doorAndFrameType:
      'Properly designed and installed windows and doors are key to maintaining the integrity of a building envelope and preventing damage during storms; when windows and doors breech, they allow wind and water to enter the building leading to significant damage.',
    elevationOfGroundOrBasementLevelMEPEquipment:
      'Details the flood protection of the ground-level MEP equipment.',
    emergencyFloodProtectionMeasures:
      'Provides information on whether flood protection measures that require human intervention are present.',
    eqSpecialSystems:
      'Earthquake Protection Systems, primarily base isolation and viscous dampers, reduce the amount of damage sustained by a building during an earthquake by preventing the energy of the earthquake from entering the structure. The Systems are often prohibitively expensive for all but critical facilities and historic buildings.',
    equipmentEarthquakeBracing:
      'Mechanical and electrical equipment should be properly anchored / braced to the floor and vertical elements. Failure to properly anchor and brace the equipment will lead to increased damage and downtime for the property.',
    fireProtectionInspectionProgram:
      'Provides information on the frequency of the inspection of fire protection systems within the building.',
    firstFloorElevation:
      'Defines the elevation of the finished first floor (in feet) of a building with respect to the nationally accepted datums',
    firstFloorHeight:
      'Describes the height of the first story of the building. Single story buildings that are taller than typical one-story structures (e.g., churches, gymnasiums, auditoriums, etc.) have been observed to experience more earthquake damage.',
    floodMissiles:
      'Details the type of projectiles (missiles) that can be generated during a flood. The projectiles can cause significant damage to the building envelope during an event.',
    floorArea: 'Defines the total gross floor area of the building measured in square feet.',
    floorSystem:
      'Details the flooring system in the building. Different floor types sustain different levels of damage during a flooding or water intrusion event.',
    foundationToFrameConnection:
      'Buildings require an engineering connection between the structure and its foundation. If a building is not connected properly to its foundation, the building can shift off its foundation during an earthquake or storm, causing substantial damage.',
    generatorTestingProgram: 'Details the frequency of generator testing.',
    geoCodeType:
      'Indicates the resolution of the geocode level provided.  Especially important in flood zones.',
    glassPercentage:
      'Details the percentage of the exterior wall area that is covered by glass for the building. The greater the percent of glass in a wall, the greater the vulnerability to damage, in general',
    glassType:
      'Describes the type of glass used in the building. Different glass types have varying degrees of resistance to wind loads and debris impact',
    interiorPartitions:
      'The amount damage to internal partition walls depends on the type of materials used to construct them, such as masonry or gypsum board. Heavy, brittle materials, such as unreinforced brick masonry, can have increased damage during an earthquake',
    lastEngineeringVisitDate:
      'Indicates whether a loss engineering study has been performed on the building and the date of the study.',
    lossEngineeringReportPresent:
      'Details whether a loss engineering report is available for the building. ',
    multiTenant:
      'Provides information on whether the property is a single tenant structure or houses multiple tenants.',
    naturalGasAutomaticShutoff:
      'Details the presence of natural gas shut off valves that are activated by earthquake ground mothion',
    naturalGasPipesSeismicallyBraced:
      'Details the presence of natural gas seismic sway bracing, which reduces the risk of ruptured gas lines after an earthquake .  ',
    numberOfBuildings: 'Defines the number of occupied buildings at a given address.',
    numberOfHighPriorityRecommendations:
      'Provides information on the number of high priority recommendations',
    numberOfStoriesAboveGround:
      'Defines the number of stories above ground, including the first (ground) floor.',
    numberOfStoriesBelowGround: 'Defines the number of levels below ground (i.e. basement levels).',
    percentageSprinklered: 'Indicator on percentage of building that is sprinklered. ',
    permanentFloodMeasuresPresent:
      'Flood protection measures, refers to specific measures taken to mitigate flood risk either by allowing water into the building in a controlled manner (wet flood proofing) or preventing the breach of the building (dry flood proofing).',
    primaryTenantNAICS:
      'Provides information on the occupancy of the primary tenant of the building.',
    propertyManager: 'Name of the organization that manages the building.',
    remoteMonitoringOfSprinklerSystem:
      'Indicates whether the sprinkler system is maintained through remote monitoring.',
    replacementCostMethodology:
      'Methodology used by customers to calculate the replacement cost of the building.  ',
    roofChimneysAnchorage:
      'Unbraced masonry chimneys are susceptible to collapse during an earthquake and can cause significant damage if they collapse onto the building.',
    roofCopingAndFlashing:
      'Details the flashing and coping compliance standard. During storms, damage to flat or low slope roofs is often initiated as the edge of the roof where flashing and coping are used to terminate the roofing material.',
    roofDeckAnchorage:
      'Details  the method of attachment between the roof deck and the roof framing.',
    roofDeckType:
      'Roof deck material is structural material that connected to the roof framing system and provides the surface for the roofing material. For residential, it is often plywood or OSB. For larger commercial structures, it is often composite systems (concrete over steel deck) or steel decking.',
    roofDrainageType: 'Details the method of removing water from the roof.',
    roofEquipmentAnchorage:
      'Details the anchorage and bracing of the roof top equipment.  During a storm (or earthquake event), inadequate anchorage can lead to equipment being blown or shaken off the supports, which not only leads to damage of equipment, but also damage to the roof material and roof deck.',
    roofGeometry:
      'Details the shape of the roof.  The roof shape determines the magnitude and extent of the wind forces experienced by the building.  This attribute is more important in low rise buildings than with taller structures.',
    roofHailMitigation:
      'Indicator of any roof hail mitigation measures, including protection of rooftop equipment or the installation of hail-resistant roofing material.  ',
    roofInstallYear:
      'Provides the most recent year of roof installation.  Older roofs have a higher risk of failure in storms due to natural degradation caused by material aging and exposure to the elements.',
    roofParapets:
      'Details the presence of roof parapets and if anchorage is present.  The primary concern is with unbraced unreinforced masonry parapet walls which we collapse during an earthquake',
    roofScreensSignsAnchorageandBracing:
      'Indicates the presence and suitability of the anchoring/bracing of roof screens, signs and enclosures that provide protection for rooftop equipment.',
    roofSolarPanelCoverage:
      'Provides detail on the percentage of the roof that is covered by solar panels.',
    roofStructures:
      'Details the roof structures, such as satellite dishes, solar panels, antennas, billboards and lights, that can be blown off the roof during a storm. While the equipment will be destroyed, it is unlikely that the damage will significantly damage the building envelope.',
    roofSystem: 'Details the type of material used in the outermost layer of the roof.',
    roofSystemAnchorage:
      'Details the connections between the roof framing elements (i.e., rafters, trusses, or joists) to the supporting walls. Buildings with inadequate roof anchorage are susceptible to severe damage associated with the roof (or a portion or the roof) failing during a storm.',
    roofingMaterialAnchorage:
      'Refers to the method of attaching the roof material to the roof system of the property.',
    rooftopSolarPanels: 'Details how solar panels are attached to the roof system.',
    rooftopWaterTanks:
      'Details the presence of rooftop water tanks and if bracing/anchorage is present.  More common in the Northeast US, which is not in a high sesismic zone.',
    seismicDesignQuality:
      'Details the level of structural design with respect to the seismic design requirements of the building.',
    seismicDesignRValue: 'R value details the response modification coefficient for the structure.',
    shortColumnConcrete:
      '‘Short columns’ occur in reinforced concrete moment frames when the columns are shortened in height by the presence of spandrel beams or infill walls. Columns that are shortened in this way experience shear forces that are greater than what they were designed to resist leading to significant damage.',
    softStory:
      'Buildings can have shear walls at the upper floors that are interrupted at the first floor to provide more open space for car parking, lobbies, and retail space. The first floor acts as a “soft story” that will sustain excessive deformations during a major earthquake unless they are specifically designed to take the stiffness discontinuity into account.',
    specificOccupancy: 'Defines the primary occupancy of the building.',
    sprinklerLeakDetectionSystem:
      'Details whether the sprinkler system is equipped with sprinkler leak detection such as a control valve.',
    structuralSystemUpgraded:
      'Defines whether the structural system of the building has been upgraded, typically for seismic mitigation or installation of missle resistant glazing and doors.',
    structuralUpgradeType:
      'Details the type of structural system upgrade, typically for seismic mitigation or installation of missle resistant glazing and doors.',
    suitabilityOfFireProtectionMeasures:
      'Provides information on whether the building is adequately protected by the fire suppression systems.',
    surfaceRoughnessWind:
      'The surface roughness is an indication of the terrain conditions around the building at this location.',
    torsion:
      'Irregular buildings tend to twist during an earthquake and can experience significant damage. This twisting is referred to as torsion and the torsional forces can be computed based on the distance between the center of mass and the center of rigidity.',
    totalInsuredValue:
      'Defines the total insured value (TIV) of the property.  The TIV is a combination of the replacement cost of the building, the contents replacement costs and business interruption costs (time element costs)',
    verticalIrregularity:
      'Setbacks and overhangs, if not specifically accounted for in the seismic design of a building, can create stress concentrations that can cause increased levels of earthquake damage.',
    wallCladdingSystem:
      'Defines the cladding that covers the exterior of the building.  Even though cladding has limited structural value, it can cause significant loss if damaged. Cladding types have different failure rates during seismic events, storms and other attritional/fire events.',
    waterHeaterBracing:
      'Water heaters in buildings are either seismically braced (usually with a steel strap) or unbraced. If the water heater in the building is not properly secured, it can topple during earthquakes and cause damage and increases the risk of fire following the earthquake.',
    waterSupplyFlowRate:
      'The metric that defines the capacity of the water supply to supply the sprinkler system with water at a specific pressure throughout the duration of the fire event.',
    windMissiles:
      'Details the type of projectiles (missiles) that can be generated due to high winds. The projectiles can cause significant damage to the building envelope, especially windows, during an event.',
    windowProtection:
      'Describes the type of wind protection system (e.g. shutters) used for the windows of the building. Protecting the windows can reduce the potential damage to a building.',
    windowType:
      'Properly designed and installed windows and doors are key to maintaining the integrity of a building envelope and preventing damage during storms; when windows and doors breech, they allow wind and water to enter the building leading to significant damage.',
    yearBuilt: 'Defines the year that the building was constructed.',
    yearLastUpgraded:
      'Defines the year that a building\'s structural system was retrofited or upgraded to the current building codes.',
  };
  return definitionLookup[attr];
};

export const getArchipelagoId = (
  taskDetails: TaskDetailsQuery['taskDetails'],
): string | undefined => {
  const details = taskDetails || [];
  for (const detail of details) {
    try {
      const parsedValue = JSON.parse(detail.value);
      return parsedValue.propertyArchipelagoID;
    } catch (e) {
      console.error(e);
    }
  }
  return undefined;
};

export const getPropertyFromDraft = (taskDetails: TaskDetailsQuery['taskDetails']): Property => {
  const details = taskDetails || [];
  for (const detail of details) {
    try {
      if (!detail?.draft?.value) {
        continue;
      }
      const parsedValue = JSON.parse(detail.draft.value);
      // Parse the draft to the Property type
      const property: Property = {
        archipelagoId: '',
        id: '',
        locationId: '',
        orgName: '',
        organizationId: '',
      }; // Create a new instance of the Property object
      Object.assign(property, parsedValue); // Assign the parsed value to the property
      return property;
      // return property;
    } catch (e) {
      console.error(e);
    }
  }
  return undefined;
};
// Get the editable attribute from the selected task
export const getAttributes = (taskDetails: TaskDetailsQuery['taskDetails']) => {
  let taskType = 'individual';
  // Make attrs a key->value array
  let attrs = [];

  const details = taskDetails || [];

  for (const detail of details) {
    try {
      const parsedValue = JSON.parse(detail.value);
      // If parsedValue is an array, then we have a list of tasks which hold the attributes at a deeper level
      if (Array.isArray(parsedValue)) {
        taskType = 'bucket';
        parsedValue.forEach((task) => {
          attrs[task.propertyArchipelagoID] = task.recommendedAttributes;
        });
      } else {
        attrs = parsedValue?.recommendedAttributes;
      }

      if (detail?.draft?.value) {
        const draft = JSON.parse(detail.draft.value);
        // draft.value holds an object. Loop through the keys of the object, and apply the draft value to the attribute
        if (draft) {
          Object.keys(draft).forEach((key) => {
            const attr = attrs.find((a) => a.name === key);
            if (attr) {
              attr.value = draft[key];
            }
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Make sure we only edit unique attributes
  const dedupe = (a, type) => {
    if (!a) {
      return [];
    }
    if (type == 'bucket') {
      // Loop through the array of tasks, then dedupe the attributes
      Object.keys(a).forEach((key) => {
        a[key] = dedupe(a[key], 'individual').slice(0, 10);
      });
      return a;
    }
    const seen = {};
    return a
      .filter(function (item) {
        return seen.hasOwnProperty(item.name) ? false : (seen[item.name] = true);
      })
      .slice(0, 10);
  };

  // Dedupe and only return top 10 attributes.
  // https://app.shortcut.com/onarchipelago/story/143762/ui-limit-the-number-of-attributes-shown-in-the-property-edit-flyout-to-10
  const dedupedAttrs = dedupe(attrs, taskType);
  return dedupedAttrs;
};

export interface EditPropertyFormProps extends ParsedTask {
  propertyInput: {};
}

export const descriptionLine2 = (property: EditPropertyFormProps) => {
  if (!property.property) {
    return '';
  }

  const streetDisplay = property.property.streetAddress
    ? `${property.property.streetAddress}, `
    : '';
  const cityDisplay = property.property.city ? `${property.property.city}, ` : '';
  const stateDisplay = property.property.state ? `${property.property.state}, ` : '';
  const zipDisplay = property.property.postalCode ? property.property.postalCode : '';
  const countryDisplay = property.property.country ? ` ${property.property.country}` : '';
  return `${streetDisplay}${cityDisplay}${stateDisplay}${zipDisplay}${countryDisplay}`;
};

export const descriptionLine1 = (property: EditPropertyFormProps) => {
  if (!property.property) {
    return '';
  }

  return `${property?.property?.locationName}`;
};

export const getAddress = (property: EditPropertyFormProps) => {
  if (!property.property) {
    return '';
  }
  const archipelagoId = `${property.propertyArchipelagoID} - `;
  return `${archipelagoId}${descriptionLine1(property)}`;
};
