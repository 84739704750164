import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSupportTicketQueryVariables = Types.Exact<{
  input: Types.SupportTicketInput;
}>;


export type GetSupportTicketQuery = { __typename?: 'Query', supportTicket: { __typename?: 'SupportTicketOutput', ticket: { __typename?: 'SupportTicket', title: string, status: Types.TicketStatus, priority: Types.TicketPriority, authorEmail: string, assigneeEmails?: Array<string> | null, followerEmails?: Array<string> | null, createdAt: any, updatedAt?: any | null, chatRoomID: string, chatRoomName: string, org?: { __typename?: 'OrgInfo', id: string, name: string } | null } } };


export const GetSupportTicketDocument = gql`
    query GetSupportTicket($input: SupportTicketInput!) {
  supportTicket(input: $input) {
    ticket {
      title
      status
      priority
      authorEmail
      assigneeEmails
      followerEmails
      createdAt
      updatedAt
      chatRoomID
      chatRoomName
      org {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetSupportTicketQuery__
 *
 * To run a query within a React component, call `useGetSupportTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportTicketQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportTicketQuery(baseOptions: Apollo.QueryHookOptions<GetSupportTicketQuery, GetSupportTicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportTicketQuery, GetSupportTicketQueryVariables>(GetSupportTicketDocument, options);
      }
export function useGetSupportTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportTicketQuery, GetSupportTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportTicketQuery, GetSupportTicketQueryVariables>(GetSupportTicketDocument, options);
        }
export type GetSupportTicketQueryHookResult = ReturnType<typeof useGetSupportTicketQuery>;
export type GetSupportTicketLazyQueryHookResult = ReturnType<typeof useGetSupportTicketLazyQuery>;
export type GetSupportTicketQueryResult = Apollo.QueryResult<GetSupportTicketQuery, GetSupportTicketQueryVariables>;