import styled from '@emotion/styled';
import { EuiFlexItem } from 'ui';

export const LineContainer = styled(EuiFlexItem)`
  ${({ color, orientation }: { color: string; orientation: string }) =>
    `
      background: ${color};
      margin: auto 0px;
      ${
        orientation === 'horizontal'
          ? `
            height: 100%;
            & > div {
              height: 100%;
            }
          `
          : `
            height: calc(100% - 24px);
            width: 1px;
          `
      }
    `}
`;
