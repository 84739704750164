import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLPercentageChart } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { buildPercentageChartData, chartDataIsEqual } from './utils';

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const getOptions = (queryData: any, title?: string) => ({
  chart: {
    height: 160,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      // stacking: "percent" as Highcharts.OptionsStackingValue,
      pointWidth: 10,
    },
  },
  series: [
    {
      color: '#2D9CDB',
      data: [
        ['Roof inspect.', queryData.roof_inspected_pct],
        ['Seismic inspect.', queryData.seismic_inspected_pct],
        ['Retrofit', queryData.retrofit_inspected_pct],
        ['Loss control', queryData.loss_control_pct],
        ['Seismic report', queryData.seismic_report_pct],
      ].filter((o: any) => o[1] > 0),
      name: 'Inspections',
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    valueSuffix: '%',
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    endOnTick: true,
    labels: {
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    max: 100,
    min: 0,
    showLastLabel: true,
    tickPositions: [0, 20, 40, 60, 80, 100],
    title: {
      text: '%',
    },
  },
});

const Inspections: React.FC<IProps> = ({ explorer, title }) => {
  const queryData = buildPercentageChartData(explorer as IGraphQLPercentageChart);
  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={getOptions(queryData, title)} />
    </Explorer>
  );
};

export default memo(Inspections, chartDataIsEqual);
