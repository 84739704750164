import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { usePrecheckClient } from './usePrecheckClient';

const PROPERTIES_TO_IMPROVE = gql`
  query propertyRecommendations($input: PropertyRecommendationsInput!) {
    propertyRecommendations(input: $input) {
      grade
      buckets {
        name
        properties {
          property {
            locationName
            city
            totalInsuredValue
            windZone
            floodZone
            archipelagoId
            seismicZone
            convectiveStormZone
            archipelagoWindZone
            archipelagoFloodZone
            archipelagoSeismicZone
            id
          }
          score
          attributes {
            name
            displayName
            definition
          }
        }
      }
    }
  }
`;

export const useRecommendations = (snap: string) => {
  const { streamId } = useParams<{
    streamId: string;
  }>();

  const client = usePrecheckClient();

  const [dismissedBuckets, setDismissedBuckets] = useState([]);

  const { data, loading } = useQuery(PROPERTIES_TO_IMPROVE, {
    client,
    skip: !snap,
    variables: {
      input: {
        snapshotName: snap,
        streamSlug: streamId,
      },
    },
  });

  const buckets: [any] = data?.propertyRecommendations?.buckets || [];

  const filteredBuckets = buckets.filter((bucket) => !dismissedBuckets.includes(bucket.name));

  const recommendationBuckets = buckets.map((bucket) => ({
    ...bucket,
    hidden: dismissedBuckets.includes(bucket.name),
  }));

  return {
    query: {
      data: {
        buckets: filteredBuckets,
        grade: data?.propertyRecommendations?.grade,
      },
      loading: loading,
    },
    recommendationBuckets,
    setDismissedBuckets,
  };
};
