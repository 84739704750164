import { ExposureLevel, TableRow } from '../../types';

export const getBarSegmentPercent = (row: TableRow, exposureLevel: ExposureLevel) => {
  const barLevels = {
    [ExposureLevel.VeryLow]:
      row.gradeDefinitions[0].maxPercentileValue - row.gradeDefinitions[0].minPercentileValue,
    [ExposureLevel.Low]:
      row.gradeDefinitions[1].maxPercentileValue - row.gradeDefinitions[1].minPercentileValue,
    [ExposureLevel.Medium]:
      row.gradeDefinitions[2].maxPercentileValue - row.gradeDefinitions[2].minPercentileValue,
    [ExposureLevel.High]:
      row.gradeDefinitions[3].maxPercentileValue - row.gradeDefinitions[3].minPercentileValue,
    [ExposureLevel.VeryHigh]:
      row.gradeDefinitions[4].maxPercentileValue - row.gradeDefinitions[4].minPercentileValue,
  };

  return barLevels[exposureLevel];
};
