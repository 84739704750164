import React, { forwardRef, RefObject, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { getActiveGroupIndex } from '@app/cx/Stream/groupbyv2';
import { GroupByV2 } from '@app/cx/Stream/types';
import { decodeBase64, KEYS } from '@app/cx/Stream/utils';
import GroupedListRow from './GroupedListRow';
import { Children, HandleClick, IDisclosure, Indices, IRow } from './types';
import { flattenRows, getItemSizeForRow, isRenderable, isRowFocused, isRowVisible } from './utils';

const Container = styled.div`
  flex: 1;
  width: 100%;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
`;

const DEFAULT_DURATION = 200;

export interface IProps<T> {
  rows: Array<IRow<T>>;
  children: Children<T>;
  disclosures: Array<IDisclosure>;
  focusAt?: number;
  duration?: number;
  // How far ahead to prerender
  overscan?: number;
  handleClick?: HandleClick;
}

// TODO: I'm not sure how to specify a generic alongisde
// a forwardRef; leaving this here until I do
type T = any;
const GroupedList = forwardRef(
  (
    { rows, children, overscan, duration, focusAt, handleClick, disclosures }: IProps<T>,
    ref: RefObject<HTMLDivElement>,
  ) => {
    const [recentIndices, setRecentIndices] = useState<Indices>([]);
    const flattenedRows = flattenRows<T>(rows, disclosures);

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const decodedGroupByV2 = decodeBase64(params.get(KEYS.groupByV2) || '');
      const groupByV2: GroupByV2[] = decodedGroupByV2 ? JSON.parse(decodedGroupByV2) : [];
      const activeGroup = getActiveGroupIndex(groupByV2);
      if (activeGroup) {
        setRecentIndices([Number(activeGroup)]);
      }
    }, []);

    return (
      <Container ref={ref}>
        {flattenedRows.map((row, key) => {
          const height = getItemSizeForRow(row);
          const isFocused = isRowFocused<T>(row, disclosures, recentIndices, focusAt);
          const visible = isRowVisible<T>(row) && isFocused;
          const shouldRender = isRenderable<T>(row, overscan !== undefined ? overscan : 1);

          if (shouldRender === false) {
            return null;
          }

          return (
            <GroupedListRow<T>
              key={key}
              height={visible ? height : 0}
              row={row}
              duration={duration || DEFAULT_DURATION}
              handleClick={() => {
                setRecentIndices(row.indices);
                if (handleClick) {
                  handleClick(row.indices, row.props.label);
                }
              }}
            >
              {children({
                indices: row.indices,
                options: row.options,
                parent: row.parent,
                props: row.props,
                visible,
              })}
            </GroupedListRow>
          );
        })}
      </Container>
    );
  },
);

export default GroupedList;
export { getDisclosuresFromRows, modifyDisclosuresForIndices } from './utils';
