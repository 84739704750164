import React, { FC } from 'react';
import { TextColumn } from '../types';
import TextContent from './TextContent';

interface IProps {
  column: TextColumn;
}

const Text: FC<IProps> = ({ column }) => (
  <TextContent
    label={column.label}
    labelAlignment={column.labelAlignment}
    data={column.data}
    dataSubtitle={column.dataSubtitle}
    dataFormat={column.dataFormat}
    change={column.change}
    years={column.years}
    changeAnalysis={column.changeAnalysis}
  />
);

export default Text;
