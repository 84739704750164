import React from 'react';
import { Checkbox } from 'ui';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { sendMixpanelEvent } from '../PropertiesDataGrid/utils';

export const SelectionHeaderCell = ({ data }) => {
  const { selectedRows, updateSelectedRows } = useSelection();
  const selectedRowsCount = Object.values(selectedRows).length;
  const isIndeterminate = selectedRowsCount > 0 && selectedRowsCount < data.length;
  const { stream } = useStreamContext();
  const mixpanel = useTracker('mixpanel');
  return (
    <Checkbox
      id="selection-toggle"
      aria-label="Select all rows"
      indeterminate={isIndeterminate}
      checked={selectedRowsCount > 0}
      onChange={(e) => {
        if (isIndeterminate) {
          updateSelectedRows({ action: 'clear' });
          sendMixpanelEvent(mixpanel, 'Clear selection', stream);
        } else if (e.target.checked) {
            updateSelectedRows({ action: 'selectAll' });
            sendMixpanelEvent(mixpanel, 'Select all', stream);
          } else {
            updateSelectedRows({ action: 'clear' });
            sendMixpanelEvent(mixpanel, 'Clear selection', stream);
          }
      }}
    />
  );
};
