import React, { FC, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  Button,
  ButtonEmpty,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiTitle,
  useToast,
} from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useCheckSovJobWorksheetMutation } from '@app/graphql/jobs/mutations/__generated__/checkSOVJobWorksheet.generated';
import { useGetSovJobWorksheetMutation } from '@app/graphql/jobs/mutations/__generated__/getSOVJobWorksheet.generated';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { GET_JOBS } from '../../queries/organizations/getJobs';
import { SUBMIT_JOB } from '../../queries/organizations/submitJob';

interface IProps {
  jobID: string;
  jobName: string;
  projectName: string;
  orgName: string;
}

const CheckSOVJobButton: FC<IProps> = ({ jobID, jobName, projectName, orgName }) => {
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();

  const [isPopoverOpen, setPopover] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  const togglePopover = () => {
    setPopover(!isPopoverOpen);
  };

  const [checkSOVJobWorksheet, { data, loading: isSubmitting }] = useCheckSovJobWorksheetMutation({
    client: jobsApolloClient,
    onCompleted: () => {
      togglePopover();
    },
    onError: (error: ApolloError) => {
      toast({ title: `Could not download worksheet: ${error.message}`, type: 'danger' });
    },
    variables: {
      input: {
        jobID: jobID,
        worksheet: selectedFile,
      },
    },
  });

  const handleSubmitForReview = async () => {
    checkSOVJobWorksheet();
  };

  const submitJobButton = (
    <Button
      data-testid="job-upload"
      size="s"
      fill
      onClick={togglePopover}
      label="Upload SOV worksheet"
    />
  );

  const onFileSelect = (files: FileList | null) => {
    if (files?.length) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <EuiPopover
      id="singlePanel"
      button={submitJobButton}
      isOpen={isPopoverOpen}
      closePopover={togglePopover}
      panelPaddingSize="l"
      anchorPosition="downLeft"
    >
      <EuiFlexGroup gutterSize="l">
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h4>{`Upload SOV worksheet to ${orgName} - ${projectName} for the job "${jobName}"`}</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" justifyContent="center">
        <EuiFlexItem grow={false}>
          <EuiFilePicker
            data-testid="job-upload-file-picker"
            id="asdf2"
            display="default"
            multiple={false}
            initialPromptText="Select or drag and drop a file"
            onChange={(files) => onFileSelect(files)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <ButtonEmpty onClick={togglePopover} label="Cancel" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            data-testid="job-upload-submit"
            onClick={handleSubmitForReview}
            disabled={!selectedFile || isSubmitting}
            fill
            label={isSubmitting ? 'Submitting...' : 'Check SOV worksheet'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export default CheckSOVJobButton;
