import { Dispatch, SetStateAction, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_SCORED_SNAPSHOTS,
  GetScoredSnapshotsData,
  GetScoredSnapshotsVariables,
} from '@onarchipelago/cx/Reports/queries/getScoredSnapshots';
import moment from 'moment';
import { Snapshot, SnapshotOption } from '../types';

interface Props {
  currentSnapshot?: Snapshot;
  setAfterSnapshot: Dispatch<SetStateAction<Snapshot>>;
  setAfterSnapshotOptions: Dispatch<SetStateAction<SnapshotOption[]>>;
  setCurrentSnapshot: Dispatch<SetStateAction<Snapshot>>;
  streamSlug: string;
}

interface Return {
  loading: boolean;
  snapshots: Snapshot[];
  snapshotOptions: SnapshotOption[];
}

export default ({
  currentSnapshot,
  setAfterSnapshot,
  setAfterSnapshotOptions,
  setCurrentSnapshot,
  streamSlug,
}: Props): Return => {
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [snapshotOptions, setSnapshotOptions] = useState<SnapshotOption[]>([]);

  // Once the GET_SCORED_SNAPSHOTS query is completed, get the snapshot options.
  const handleCompletedData = (data: GetScoredSnapshotsData) => {
    const finalSnapshots = data?.qualityAvailableSnapshots
      ?.map((snapshot) => {
        const { availableHazards, date, snapshotName } = snapshot;

        return {
          availableHazards,
          snapshotDate: moment(date).format('YYYY-MM-DD'),
          snapshotName,
        };
      })
      .sort(
        (a: Snapshot, b: Snapshot) =>
          new Date(b.snapshotDate).valueOf() - new Date(a.snapshotDate).valueOf(),
      );

    const snapshotOptionsFinal = finalSnapshots?.map((snapshot) => ({
      label: snapshot.snapshotName,
      value: snapshot.snapshotName,
    }));

    // Use the first snapshot in the list when populating the dropdown
    if (finalSnapshots?.length && !currentSnapshot) {
      setCurrentSnapshot(finalSnapshots[0]);

      // If there are more than one snapshots, then provide the ability to select an "after"
      // snapshot
      if (finalSnapshots.length > 1) {
        // The "after" snapshot options should not include the "current" snapshot
        const afterSnapshotOptionsFinal = data?.qualityAvailableSnapshots
          ?.filter((snapshot, index) => index !== 0)
          ?.map((snapshot) => ({
            label: snapshot.snapshotName,
            value: snapshot.snapshotName,
          }));

        setAfterSnapshot(finalSnapshots[1]);
        setAfterSnapshotOptions(afterSnapshotOptionsFinal || []);
      }
    }

    setSnapshotOptions(snapshotOptionsFinal || []);
    setSnapshots(finalSnapshots || []);
  };

  const { loading } = useQuery<GetScoredSnapshotsData, GetScoredSnapshotsVariables>(
    GET_SCORED_SNAPSHOTS,
    {
      onCompleted: handleCompletedData,
      skip: !streamSlug,
      variables: {
        input: {
          streamSlug,
        },
      },
    },
  );

  return {
    loading,
    snapshotOptions,
    snapshots,
  };
};
