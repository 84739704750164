import { ExposureLevel } from '../../types';

export const getNonStandardExposureLabels = (level?: ExposureLevel) => {
  switch (level) {
    case ExposureLevel.VeryLow:
      return 'the lowest';
    case ExposureLevel.VeryHigh:
      return 'the highest';
  }
};
