import { css } from '@emotion/css';

const checkboxContainer = css`
  margin-left: 4px;
`;

const inlineCheckboxContainer = css`
  justify-content: center;
  margin-left: 16px !important;
  padding-top: 20px;
`;

const inlineFlexGroup = css`
  height: 86px;
`;

const includeStreamLabel = css`
  justify-content: center;
  margin-left: 16px !important;
`;

const includeStreamDropdownContainer = css`
  justify-content: center;
`;

export default {
  checkboxContainer,
  includeStreamDropdownContainer,
  includeStreamLabel,
  inlineCheckboxContainer,
  inlineFlexGroup,
};
