import React, { memo } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { colors } from 'ui';
import { IGraphQLTIVChart } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { IExplorerProps } from './types';
import { buildTIVChartData, chartDataIsEqual } from './utils';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['K', 'M', 'B'],
  },
});

const getOptions = (
  explorer: IGraphQLTIVChart,
  options: any,
  formatCurrency?: FormatCurrency,
  title?: string,
) => ({
  chart: {
    height: 160,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  lang: {
    numericSymbols: undefined,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      color: colors.dataViz.archipelagoGreen3,
      pointWidth: 10,
    },
  },
  series: [
    {
      data: buildTIVChartData(explorer),
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${this.point.name}<br><b>${formatCurrency?.(this.point.y)}</b>`;
    },
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    endOnTick: true,
    labels: {
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    min: 0,
    showLastLabel: true,
    title: {
      text: undefined,
    },
  },
  ...options,
});

const TivBarChart: React.FC<IExplorerProps> = ({ explorer, options, title }) => {
  const { formatStreamCurrency } = useStreamContext();
  return (
    <Explorer>
      <HighchartsReact
        highcharts={Highcharts}
        options={getOptions(explorer as IGraphQLTIVChart, options, formatStreamCurrency, title)}
      />
    </Explorer>
  );
};

export default memo(TivBarChart, chartDataIsEqual);
