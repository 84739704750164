import gql from 'graphql-tag';

export const GET_ENGAGEMENT_VIEWS_BY_USER = gql`
  query getEngagementViewsByUser($input: EngagementViewsByUserInput!) {
    engagementViewsByUser(input: $input) {
      country
      downloads {
        Archipelago_extract
        Modelling_files
        Stream_docs
        Property_docs
      }
      email
      jobTitle
      lastLogin
      organizationName
      sessions
      userName
      viewedHighlights
    }
  }
`;

export interface UserViews {
  country: string;
  downloads: {
    Archipelago_extract: boolean;
    Modelling_files: boolean;
    Property_docs: number;
    Stream_docs: number;
  };
  email: string;
  jobTitle: string;
  lastLogin: string;
  organizationName: string;
  sessions: number;
  userName: string;
  viewedHighlights: number;
}

export interface EngagementViewsByUserData {
  engagementViewsByUser: UserViews[];
}

export interface EngagementViewsByUserVariables {
  input: {
    fromDate: string;
    streamSlug: string;
  };
}
