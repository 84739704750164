export enum HazardCategory {
  All = 'All',
  Any = 'Any',
  Earthquake = 'Earthquake',
  Fire = 'Fire',
  Wind = 'Wind',
  Flood = 'Flood',
}

export interface GetQualityData {
  qualitySnapshotOverall: {
    score: number;
    scoreChangePct: number;
    locationCount: number;
    locationCountChangePct: number;
    locationCountAdded: number;
    locationCountDisposed: number;
    tiv: number;
    tivChangePct: number;
  };
}

export interface GetQualityVariables {
  input: {
    streamSlug: string;
    snapshotName: string;
    hazard: HazardCategory;
  };
}
