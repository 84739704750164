import React from 'react';
import { Button, EuiFlexGroup, EuiFlexItem, EuiModal, EuiModalBody, EuiText } from 'ui';
import { ApplyMode } from '@app/graphql/types';
import styles from './AutoEnableConfirmDialog.emotion';

interface Props {
  confirmBody?: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (mode: ApplyMode) => void;
  submitLoading?: boolean;
  labelConfirm?: string;
  labelCancel?: string;
}
const AutoEnableConfirmModal: React.FC<Props> = ({
  confirmBody,
  isOpen,
  onClose,
  onSubmit,
  submitLoading,
}) => {
  if (isOpen) {
    return (
      <EuiModal data-testid="confirmation-dialog" onClose={onClose}>
        <EuiModalBody className={styles.modalBody}>
          <EuiFlexGroup className={styles.container} direction="column" gutterSize="m">
            <EuiFlexItem grow={false}>
              <EuiText>{confirmBody}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem grow={false}>
                  <Button color={'danger'} label={'Cancel'} onClick={onClose} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Button
                    label={'Only enable attributes'}
                    loading={submitLoading}
                    onClick={() => {
                      onSubmit(ApplyMode.EnableOnly);
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Button
                    label={'Only disable attributes'}
                    loading={submitLoading}
                    onClick={() => {
                      onSubmit(ApplyMode.DisableOnly);
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Button
                    label={'Apply all'}
                    fill
                    loading={submitLoading}
                    onClick={() => {
                      onSubmit(ApplyMode.All);
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiModal>
    );
  }

  return null;
};

export default AutoEnableConfirmModal;
