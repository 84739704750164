import gql from 'graphql-tag';
import { JOB_WITH_TRANSITION_FRAGMENT } from '../fragments/Job';

export const GET_JOBS_QUERY = gql`
  ${JOB_WITH_TRANSITION_FRAGMENT}
  query GetJobs($jobsFilter: JobsFilter, $pageCursor: String, $pageSize: Int) {
    jobsV2(filters: $jobsFilter, pageCursor: $pageCursor, pageSize: $pageSize) {
      jobs {
        ...jobWithTransition
      }
      pageInfo {
        cursor
      }
    }
  }
`;
