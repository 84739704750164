import React, { useEffect, useState } from 'react';
import { EuiSpacer, Tabs } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useEnterpriseDashboardMetadataQuery } from '@app/graphql/queries/enterpriseDashboardMetadata/metadata/__generated__/getEnterpriseDashboardMetadata.generated';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { useTracker } from '@app/hooks/useTracker';
import loadable from '@loadable/component';

import {
  FEATURE_TYPE_ENTERPRISE_REPORT,
  FEATURE_TYPE_GOODDATA_REACT_SDK,
  FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
} from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { GoodDataIntegration } from '../GoodDataIntegration/GoodDataIntegration';

const GoodDataReactSDKIntegration = loadable(
  () => import('@app/components/GoodDataReactSDKIntegration/GoodDataReactSDKIntegration'),
);

export const Charts = () => {
  const apiTracker = useTracker('api');
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const isEnterpriseReportEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_ENTERPRISE_REPORT,
  );

  const isHubAnalyticsForAdminsEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
  );

  const isGoodDataReactSDKEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_GOODDATA_REACT_SDK,
  );

  const isAdminAndHubAnalyticsEnabled = isAdmin && isHubAnalyticsForAdminsEnabled;

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });
  const { data } = useEnterpriseDashboardMetadataQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const dashboardID = data?.enterpriseDashboardMetadata?.dashboards?.[0]?.id;
  const workspaceID = data?.enterpriseDashboardMetadata?.workspaceID;
  const apiToken = data?.enterpriseDashboardMetadata?.signedJWT;

  useEffect(() => {
    if (dashboardID) {
      setSelectedDashboard(dashboardID);
    }
  }, [data]);

  useEffect(() => {
    apiTracker.track('View Enterprise Reporting', {
      event_surface: 'PreCheck',
      organization_id: selectedOrganization?.id,
    });
  }, []);

  const orgInfoPermissions = orgInfoData?.orgInfo?.permissions.viewEnterpriseReports;

  if (
    (!isEnterpriseReportEnabled && !isAdminAndHubAnalyticsEnabled && !orgInfoPermissions) ||
    !data
  ) {
    return null;
  }

  return (
    <>
      <Tabs
        selectedTab={selectedDashboard}
        tabs={data?.enterpriseDashboardMetadata.dashboards?.map((dash) => ({
          id: dash.id,
          label: dash.title,
          onClick: () => {
            setSelectedDashboard(dash.id);
          },
        }))}
      />
      <EuiSpacer size="m" />
      {isGoodDataReactSDKEnabled ? (
        <GoodDataReactSDKIntegration
          apiToken={apiToken}
          workspaceID={workspaceID}
          dashboardID={selectedDashboard || dashboardID}
        />
      ) : (
        <GoodDataIntegration
          setSelectedDashboard={setSelectedDashboard}
          apiToken={apiToken}
          workspaceID={workspaceID}
          dashboardID={selectedDashboard || dashboardID}
        />
      )}
    </>
  );
};
