import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveDocumentPropertyMutationVariables = Types.Exact<{
  input: Types.DocumentPropertyInput;
}>;


export type RemoveDocumentPropertyMutation = { __typename?: 'Mutation', removeDocumentProperty: string };


export const RemoveDocumentPropertyDocument = gql`
    mutation RemoveDocumentProperty($input: DocumentPropertyInput!) {
  removeDocumentProperty(input: $input)
}
    `;
export type RemoveDocumentPropertyMutationFn = Apollo.MutationFunction<RemoveDocumentPropertyMutation, RemoveDocumentPropertyMutationVariables>;

/**
 * __useRemoveDocumentPropertyMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentPropertyMutation, { data, loading, error }] = useRemoveDocumentPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDocumentPropertyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocumentPropertyMutation, RemoveDocumentPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocumentPropertyMutation, RemoveDocumentPropertyMutationVariables>(RemoveDocumentPropertyDocument, options);
      }
export type RemoveDocumentPropertyMutationHookResult = ReturnType<typeof useRemoveDocumentPropertyMutation>;
export type RemoveDocumentPropertyMutationResult = Apollo.MutationResult<RemoveDocumentPropertyMutation>;
export type RemoveDocumentPropertyMutationOptions = Apollo.BaseMutationOptions<RemoveDocumentPropertyMutation, RemoveDocumentPropertyMutationVariables>;