import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSupportTicketMutationVariables = Types.Exact<{
  input: Types.UpdateSupportTicketInput;
}>;


export type UpdateSupportTicketMutation = { __typename?: 'Mutation', updateSupportTicket: { __typename?: 'UpdateSupportTicketOutput', ticket: { __typename?: 'SupportTicket', title: string, status: Types.TicketStatus, authorEmail: string, assigneeEmails?: Array<string> | null, followerEmails?: Array<string> | null, chatRoomID: string, chatRoomName: string } } };


export const UpdateSupportTicketDocument = gql`
    mutation UpdateSupportTicket($input: UpdateSupportTicketInput!) {
  updateSupportTicket(input: $input) {
    ticket {
      title
      status
      authorEmail
      assigneeEmails
      followerEmails
      chatRoomID
      chatRoomName
    }
  }
}
    `;
export type UpdateSupportTicketMutationFn = Apollo.MutationFunction<UpdateSupportTicketMutation, UpdateSupportTicketMutationVariables>;

/**
 * __useUpdateSupportTicketMutation__
 *
 * To run a mutation, you first call `useUpdateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportTicketMutation, { data, loading, error }] = useUpdateSupportTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupportTicketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupportTicketMutation, UpdateSupportTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupportTicketMutation, UpdateSupportTicketMutationVariables>(UpdateSupportTicketDocument, options);
      }
export type UpdateSupportTicketMutationHookResult = ReturnType<typeof useUpdateSupportTicketMutation>;
export type UpdateSupportTicketMutationResult = Apollo.MutationResult<UpdateSupportTicketMutation>;
export type UpdateSupportTicketMutationOptions = Apollo.BaseMutationOptions<UpdateSupportTicketMutation, UpdateSupportTicketMutationVariables>;