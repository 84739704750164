import { css } from '@emotion/css';

const documentsContainer = css`
  flex-wrap: wrap;
`;

const titleContainer = css`
  width: 100%;
`;

export default {
  documentsContainer,
  titleContainer,
};
