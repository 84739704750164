import { css } from '@emotion/css';
import { colorLevels, shadeLevels } from '../../colors';
import { ExposureLevel } from '../../types';

const container = css`
  display: flex;
`;

const iconContainer = (exposureLevel: ExposureLevel) => css`
  align-items: center;
  background: ${colorLevels[exposureLevel]};
  border-radius: 4px 0 0 4px;
  display: flex;
  height: 104px;
  justify-content: center;
  width: 104px;
`;

const title = css`
  font-weight: bold !important;
`;

const titleContainer = (exposureLevel: ExposureLevel) => css`
  align-items: center;
  background: ${shadeLevels[exposureLevel]};
  border-radius: 0 4px 4px 0;
  display: flex;
  height: 104px;
  justify-content: center;
  padding-left: 16px;
  width: 100%;
`;

export default {
  container,
  iconContainer,
  title,
  titleContainer,
};
