import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
import CustomerAvgLossChart from '../../../charts/CustomerAvgLossChart';
import AnnualLossTable from './AnnualLossTable';
import Stats from './Stats';

const CustomerAvgLoss: React.FC = () => (
  <EuiFlexGroup direction="column">
    <EuiFlexItem>
      <EuiText color="subdued">
        Includes only Gross Paid Indemnity (Zurich Share) on closed non-zero claims.
      </EuiText>
      <Spacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <Stats />
            </EuiFlexItem>
            <EuiFlexItem>
              <CustomerAvgLossChart />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <AnnualLossTable />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  </EuiFlexGroup>
);
export default CustomerAvgLoss;
