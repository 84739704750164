import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCountryCurrencyCodeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCountryCurrencyCodeQuery = { __typename?: 'Query', countryCurrencyCode?: Array<{ __typename?: 'CountryCurrencyCode', country: string, countryCode?: string | null, currencyCode?: string | null }> | null };


export const GetCountryCurrencyCodeDocument = gql`
    query GetCountryCurrencyCode {
  countryCurrencyCode {
    country
    countryCode
    currencyCode
  }
}
    `;

/**
 * __useGetCountryCurrencyCodeQuery__
 *
 * To run a query within a React component, call `useGetCountryCurrencyCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryCurrencyCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryCurrencyCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryCurrencyCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryCurrencyCodeQuery, GetCountryCurrencyCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryCurrencyCodeQuery, GetCountryCurrencyCodeQueryVariables>(GetCountryCurrencyCodeDocument, options);
      }
export function useGetCountryCurrencyCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryCurrencyCodeQuery, GetCountryCurrencyCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryCurrencyCodeQuery, GetCountryCurrencyCodeQueryVariables>(GetCountryCurrencyCodeDocument, options);
        }
export type GetCountryCurrencyCodeQueryHookResult = ReturnType<typeof useGetCountryCurrencyCodeQuery>;
export type GetCountryCurrencyCodeLazyQueryHookResult = ReturnType<typeof useGetCountryCurrencyCodeLazyQuery>;
export type GetCountryCurrencyCodeQueryResult = Apollo.QueryResult<GetCountryCurrencyCodeQuery, GetCountryCurrencyCodeQueryVariables>;