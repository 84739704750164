import React, { FC, useEffect, useMemo, useState } from 'react';
import { IDistillers } from '@onarchipelago/cx/Stream/types';
import { MultiSelect } from 'ui';
import {
  getDisclosuresFromRows,
  modifyDisclosuresForIndices,
} from '@app/components/GroupedList/GroupedList';
import { PropertiesDataGrid } from '@app/components/PropertiesDataGrid/PropertiesDataGrid';
import PropertiesGroupedList from '@app/components/PropertiesGroupedList/PropertiesGroupedList';
import { getGroupingsFromString } from '@app/components/PropertiesGroupedList/utils';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import debounce from '@app/dice/Pages/SmartDocs/components/utils/debounce';
import formatProperties from '@app/dice/Pages/SmartDocs/components/utils/formatProperties';
import { useStreamSearchQuery } from '@app/graphql/queries/streams/__generated__/StreamSearch.generated';
import { AttributeFilter , BinaryOperator } from '@app/graphql/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
// FIX ME
// @ts-ignore
import GET_GROUPS from '@app/queries/streams/PropertyGroupsQuery/getGroups.gql';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import { getPreparedDataAsGroups } from '@app/queries/streams/PropertyGroupsQuery/utils';
import BreadcrumbGroupByV2 from '../BreadcrumbGroupByV2';
import { MODE } from '../Distillers/types';
import { getActiveGroupIndex, getGroupByFromState, resolveFilters } from '../groupbyv2';
import { IPageState } from '../types';
import Gallery from './ReefGallery/ReefGallery';
import MapV2 from './ReefMapV2/ReefMapV2';
import TotalBar from './TotalBar/TotalBar';
import styles, { SearchContainer } from './Reef.emotion';
import { getPropertyCount, getStringIndices } from './utils';

interface Props {
  distillers: IDistillers;
  filters: Array<AttributeFilter>;
  handleActiveGroupChange: (indices: Array<number> | undefined, label: string) => void;
  handleSortChange: (sort: IGraphQLSort) => void;
  handleExpandColumns: (expandedColumns: Array<string>) => void;
  pageState: IPageState;
  onBreadcrumbClick: (index: number) => void;
}

const Reef: FC<Props> = ({
  distillers,
  filters,
  handleActiveGroupChange,
  handleSortChange,
  handleExpandColumns,
  pageState,
  onBreadcrumbClick,
}) => {
  const {
    stream,
    propertyAttributeMetadata,
    setRefetchPropertyGroups,
    selectedProperties,
    setSelectedProperties,
  } = useStreamContext();
  const { mode: currentMode, currentSnapshot, changesSince } = pageState;
  const groupBys = getGroupByFromState(pageState.groupByV2);
  const groups = getGroupingsFromString(`${groupBys || ''}`.split(',').filter(Boolean));
  const previousActiveGroupIndex = getActiveGroupIndex(pageState.groupByV2);
  const activeGroupIndices = previousActiveGroupIndex;

  const {
    refetch: refetchProperties,
    data: propertyData,
    loading: loadingProperties,
  } = useStreamSearchQuery({
    skip: !stream,
    variables: {
      input: {
        limit: 50,
        streamSlug: stream?.slug,
      },
    },
  });

  const modes = {
    [MODE.MAP]: MapV2,
    [MODE.GALLERY]: Gallery,
    [MODE.GRID]: PropertiesDataGrid,
  };

  const Mode = modes[currentMode];

  if (!Mode) {
    throw new Error(`No mode defined for ${currentMode}`);
  }

  const { loading, data, error, refetch } = useStandardQuery<
    IGetPropertyGroupsData,
    IGetPropertyGroupsVariables
  >(
    GET_GROUPS,
    {
      onCompleted: (groupedPropertiesData) => {
        setGroupedProperties(groupedPropertiesData);
      },
      variables: {
        changesSince: changesSince || '',
        compareChanges: !!changesSince,
        currentSnapshot: currentSnapshot || '',
        filter: resolveFilters(
          filters,
          pageState.groupByV2,
          selectedProperties?.map((property) => property.value),
        ),
        // NOTE: The API only supports grouping on 1 single attribute.
        groupBy: getGroupByFromState(pageState.groupByV2),
        streamSlug: stream.slug,
      },
    },
    {
      errorMessage: (err) => err?.message,
    },
  );

  useEffect(() => {
    setRefetchPropertyGroups(() => refetch);
  }, [refetch]);

  const [groupedProperties, setGroupedProperties] = useState(undefined);

  const effectiveGroupedProperties = useMemo(() => getPreparedDataAsGroups(groupedProperties, stream.snapshots, !!changesSince), [JSON.stringify(groupedProperties), groupBys]);

  const disclosures = useMemo(() => {
    if (effectiveGroupedProperties && effectiveGroupedProperties?.length) {
      const disclosuresForRows = getDisclosuresFromRows(effectiveGroupedProperties, 0);
      if (activeGroupIndices !== undefined) {
        return modifyDisclosuresForIndices(disclosuresForRows, [activeGroupIndices]);
      }

      return disclosuresForRows;
    }

    return [];
  }, [activeGroupIndices, effectiveGroupedProperties]);

  if (error) {
    return null;
  }

  const propertyOptions = formatProperties(propertyData?.streamPropertiesPage?.properties);
  const searchProperties = async (val: string | undefined) => {
    refetchProperties({
      input: {
        filter: !!val
          ? [
              {
                name: 'propertySearch',
                operator: BinaryOperator.Equal,
                values: [val],
              },
            ]
          : [],
        streamSlug: stream?.slug,
      },
    });
  };

  return (
    <div className={styles.reefContainer} data-testid="reef">
      <TotalBar changesSince={changesSince} loading={loading} groupData={data} />
      {currentMode === MODE.GRID && (
        <SearchContainer>
          <MultiSelect
            onChange={setSelectedProperties}
            placeholder="Search properties (Client ID, Building Name, Address)"
            onSearchChange={debounce(searchProperties)}
            async
            options={propertyOptions}
            isLoading={loadingProperties}
            fullWidth
          />
        </SearchContainer>
      )}
      <BreadcrumbGroupByV2 pageState={pageState} onBreadcrumbClick={onBreadcrumbClick} />
      <div
        className={styles.listContainer}
        data-tourid="properties-grouped-list-container"
        data-testid="properties-grouped-list-container"
      >
        <PropertiesGroupedList
          mode={currentMode}
          rows={effectiveGroupedProperties}
          groups={groups}
          handleClick={handleActiveGroupChange}
          disclosures={disclosures}
          loading={loading}
        >
          {({ indices, group }) => (
            <Mode
              selectedProperties={selectedProperties}
              stream={stream}
              totalProperties={getPropertyCount(stream, group)}
              propertyAttributeMetadata={propertyAttributeMetadata}
              indices={getStringIndices(indices, groups, effectiveGroupedProperties)}
              groupID={group?.id}
              group={group}
              distillers={distillers}
              pageState={pageState}
              handleSortChange={handleSortChange}
              handleExpandColumns={handleExpandColumns}
              streamSlug={stream.slug}
              filters={filters}
            />
          )}
        </PropertiesGroupedList>
      </div>
    </div>
  );
};

export default Reef;
