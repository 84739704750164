import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { IJob } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPopover,
  EuiTextArea,
  EuiTitle,
  useToast,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { GET_JOBS } from '@app/queries/organizations/getJobs';
// FIX ME
// @ts-ignore
import APPROVE_JOB from '../../queries/organizations/approveJob.gql';
// FIX ME
// @ts-ignore
import REJECT_JOB from '../../queries/organizations/rejectJob.gql';

interface IGetJobVariables {
  jobID: string;
}
interface IGetJobData {
  job: IJob;
}

interface IProps {
  jobID: string;
  jobName: string;
  jobType: string;
  lifecycles: any;
  projectName: string;
  orgName: string;
  userID: string;
  document: any;
}

const jobTypesWithWorksheet = [
  'Enrichment',
  'EnrichmentWithProvenance',
  'Ingest',
  'AddProperties',
  'PreCleanse',
  'LocationIngest',
  'NonLocationIngest',
];

const FinishReview: FC<IProps> = ({
  jobID,
  jobName,
  jobType,
  lifecycles,
  projectName,
  orgName,
  userID,
  document,
}) => {
  const jobsApolloClient = useJobsApolloClient();
  const toast = useToast();

  const [isPopoverOpen, setPopover] = useState(false);
  const [viewErrors, setViewErrors] = useState<boolean>(false);
  const [isApproved, setReviewDecision] = useState<boolean | null>();
  const [changeReason, setChangeReason] = useState<string>('');

  const togglePopover = () => {
    setPopover(!isPopoverOpen);
  };

  const { data } = useQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    client: jobsApolloClient,
    variables: {
      jobID,
    },
  });

  const [approveJob, { loading: approving }] = useMutation(APPROVE_JOB, {
    client: jobsApolloClient,
    onCompleted: () => {
      toast({ title: 'Job has been approved.' });
      togglePopover();
    },
    onError: (err) => {
      toast({ title: err.message, type: 'danger' });
    },
  });

  const [rejectJob, { loading: rejecting }] = useMutation(REJECT_JOB, {
    client: jobsApolloClient,
    onCompleted: () => {
      toast({ title: 'Job has been rejected.' });
      togglePopover();
    },
    onError: (err) => {
      toast({ title: err.message, type: 'danger' });
    },
  });

  const isSubmitting = approving || rejecting;

  const submitReview = () => {
    if (isApproved) {
      approveJob({
        refetchQueries: [
          {
            query: GET_JOBS,
            variables: {
              jobsFilter: {
                activeOnly: true,
                userID,
              },
              pageSize: 50,
            },
          },
        ],
        variables: {
          input: {
            jobID,
          },
        },
      });
    } else {
      rejectJob({
        refetchQueries: [
          {
            query: GET_JOBS,
            variables: {
              jobsFilter: {
                activeOnly: true,
                userID,
              },
              pageSize: 50,
            },
          },
        ],
        variables: {
          input: {
            comment: changeReason,
            jobID,
          },
        },
      });
    }
  };

  const downloadWorksheet = () => {
    const latestLifecycle = data?.job?.lifeCycle[0];
    const currentWorksheetURL = latestLifecycle?.worksheet?.httpURL;

    if (currentWorksheetURL) {
      window.location.href = currentWorksheetURL;
    }
  };

  const approveJobReview = (approveSelected: boolean) => {
    setReviewDecision(approveSelected);
  };

  const updateChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeReason(event.target.value);
  };

  const errorIcon = (
    <Button
      size="s"
      fill
      iconName="help"
      iconSide="right"
      onClick={() => setViewErrors(!viewErrors)}
      label="View Errors"
    />
  );

  const renderError = () => {
    const latestLifecycle = lifecycles[0];

    if (latestLifecycle?.errors?.length > 0) {
      return (
        <div style={{ margin: '8px 0' }}>
          {`${latestLifecycle.errors.length} error${latestLifecycle.errors.length > 1 ? 's' : ''}`}{' '}
          <EuiPopover
            id="singlePanel"
            button={errorIcon}
            isOpen={viewErrors}
            closePopover={() => setViewErrors(!viewErrors)}
            panelPaddingSize="none"
            anchorPosition="downLeft"
          >
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              <code>{JSON.stringify(latestLifecycle.errors, null, 2)}</code>
            </pre>
          </EuiPopover>
        </div>
      );
    }

    return null;
  };

  const renderWorksheetButton = () => {
    const jobWorksheet = !data?.job?.lifeCycle[0]?.worksheet;

    if (jobWorksheet) {
      return null;
    }

    return (
      <EuiFlexItem grow={false}>
        <Button label="Worksheet" onClick={downloadWorksheet} fill />
      </EuiFlexItem>
    );
  };

  const finishReviewButton = (
    <Button data-testid="review-job" size="s" fill onClick={togglePopover} label="Review" />
  );

  return (
    <EuiPopover
      id="singlePanel"
      button={finishReviewButton}
      isOpen={isPopoverOpen}
      closePopover={togglePopover}
      panelPaddingSize="l"
      anchorPosition="downLeft"
    >
      <EuiFlexGroup gutterSize="l" data-testid="review-title">
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h4>{`Reviewing ${orgName} - ${projectName}'s job "${jobName}"`}</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        {jobType === 'ColumnMapping' && (
          <EuiFlexItem grow={false}>
            <Link
              to={{
                pathname: encodeUrl`/jobs/${jobID}/ingest`,
                state: {
                  document,
                  isDisabled: true,
                  orgName,
                },
              }}
            >
              <Button label="Mappings" />
            </Link>
          </EuiFlexItem>
        )}
        {jobType === 'Geo' && (
          <EuiFlexItem grow={false}>
            <Link
              to={{
                pathname: encodeUrl`/jobs/${jobID}/geo`,
                state: {
                  isDisabled: true,
                },
              }}
            >
              <Button label="View Geo" />
            </Link>
          </EuiFlexItem>
        )}
        {jobTypesWithWorksheet.includes(jobType) && renderWorksheetButton()}
        {data?.job?.status !== 'Failed' && (
          <EuiFlexItem grow={false}>
            <EuiFlexGroup justifyContent="flexEnd" direction="row" gutterSize="xl">
              <EuiFlexItem>
                <Button
                  data-testid="review-menu-approve"
                  onClick={() => approveJobReview(true)}
                  fill={!!isApproved}
                  label="Approve"
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <Button
                  color="danger"
                  onClick={() => approveJobReview(false)}
                  fill={isApproved === false}
                  label="Request changes"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <>{renderError()}</>
      {isApproved === false && (
        <EuiFlexGroup gutterSize="xl">
          <EuiFlexItem>
            <EuiForm>
              <EuiFormRow label="Change reason">
                <EuiTextArea
                  placeholder="Enter reason for requested changes"
                  value={changeReason || ''}
                  onChange={(e: any) => updateChangeReason(e)}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <ButtonEmpty onClick={togglePopover} label="Cancel" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            data-testid="review-menu-submit"
            onClick={submitReview}
            loading={isSubmitting}
            disabled={
              // Allow submit only if approved or rejected with a given reason
              isSubmitting ||
              (isApproved === null
                ? true
                : !isApproved && (changeReason === null || changeReason?.trim().length === 0))
            }
            fill
            label={isSubmitting ? 'Submitting' : 'Submit'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export default FinishReview;
