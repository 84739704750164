import { FeatureFlag } from './types';

export const featureFlags: FeatureFlag[] = [
  {
    displayName: 'Geo Enrichment Map v2',
    id: 'geomap-v2',
    inProduction: true,
    refreshRequired: false,
    summary: 'A redesigned, more focused view for geo enrichment jobs.',
  },
];
