import React from 'react';
import { BarsData } from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/types';
import { HazardExposureType } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import QualityScoreKey from '@onarchipelago/cx/Reports/ReadinessReport/QualityScoreKey';
import { EuiFlexGroup, EuiFlexItem, EuiText, Icon, Select } from 'ui';
import HorizontalBarChart from '../../Charts/HorizontalBarChart/HorizontalBarChart';

interface Props {
  charts: {
    data: BarsData;
    title: string;
  }[];
  currentHazard: HazardExposureType;
  handleChange: (value: HazardExposureType) => void;
  hazardOptions: {
    label: string;
    value: HazardExposureType;
  }[];
}

const SecondaryAttributesComponent: React.FC<Props> = ({
  charts,
  currentHazard,
  handleChange,
  hazardOptions,
}) => (
  <EuiFlexGroup direction="column">
    <EuiFlexItem grow={false}>
      <EuiText data-testid="secondary-attributes-title">
        <h3>Secondary Attributes Data Quality Before and After</h3>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <Select onChange={handleChange} options={hazardOptions} value={currentHazard} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <QualityScoreKey />
    </EuiFlexItem>
    {charts.map((chart, index) => (
      <EuiFlexItem key={`${chart.title}-${index}`} grow={false}>
        <HorizontalBarChart
          bars={chart.data}
          showBarLabels={false}
          showKey={false}
          showRule={false}
          title={chart.title}
          titleSize="medium"
        />
      </EuiFlexItem>
    ))}
    <EuiFlexItem grow={false}>
      <EuiText data-testid="secondary-attributes-axis-label">
        <h5>
          {`% of ${currentHazard} Exposed TIV`}
          <Icon name="arrowRight" size="s" />
        </h5>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default SecondaryAttributesComponent;
