import React, { FC, useEffect, useState } from 'react';
import useTimedEffect from '@app/hooks/useTimedEffect';

interface IProps {
  alt: string;
  title: string;
  src: string;
  defaultSrc?: string;
  delay?: number;
  height?: number;
  width?: number;
}

const DEFAULT_DELAY = 10;

const Img: FC<IProps> = ({ alt, title, src, defaultSrc, delay, width, height }) => {
  const [localSrc, setLocalSrc] = useState<string | undefined>(defaultSrc);

  useEffect(() => {
    setLocalSrc(defaultSrc);
  }, [src]);

  useTimedEffect(
    () => {
      setLocalSrc(src);
    },
    delay === undefined ? DEFAULT_DELAY : delay,
    [src],
  );

  if (!localSrc) {
    return null;
  }

  const addDefaultSrc = (event: any) => {
    if (defaultSrc) {
      // eslint-disable-next-line
      event.target.src = defaultSrc;
    }
  };

  return (
    <img
      src={localSrc}
      alt={alt}
      title={title}
      width={width}
      height={height}
      onError={addDefaultSrc}
    />
  );
};

export default Img;
