import React, { FC } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import PollingExportButton from '@app/components/PollingExportButton/PollingExportButtonContainer';

const ExportButton: FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const { afterSnapshot, currentSnapshot } = snapshots;
  const currentSnapshotName = currentSnapshot?.snapshotName || '';
  const afterSnapshotName = afterSnapshot?.snapshotName || '';

  return (
    <PollingExportButton
      exportCode={ExportCode.ENRICHMENT}
      metaData={[
        { key: 'SnapshotNameAfter', value: afterSnapshotName },
        { key: 'SnapshotNameBefore', value: currentSnapshotName },
      ]}
      streamSlug={streamSlug}
    />
  );
};
export default ExportButton;
