import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetHazardsProgressQueryVariables = Types.Exact<{
  input: Types.SetHazardsProgressInput;
}>;


export type SetHazardsProgressQuery = { __typename?: 'Query', setHazardsProgress?: { __typename?: 'SetHazardsProgress', orgName: string, status: Types.HazardsStatus, propertyCheckpoint: number } | null };


export const SetHazardsProgressDocument = gql`
    query SetHazardsProgress($input: SetHazardsProgressInput!) {
  setHazardsProgress(input: $input) {
    orgName
    status
    propertyCheckpoint
  }
}
    `;

/**
 * __useSetHazardsProgressQuery__
 *
 * To run a query within a React component, call `useSetHazardsProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetHazardsProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetHazardsProgressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetHazardsProgressQuery(baseOptions: Apollo.QueryHookOptions<SetHazardsProgressQuery, SetHazardsProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetHazardsProgressQuery, SetHazardsProgressQueryVariables>(SetHazardsProgressDocument, options);
      }
export function useSetHazardsProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetHazardsProgressQuery, SetHazardsProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetHazardsProgressQuery, SetHazardsProgressQueryVariables>(SetHazardsProgressDocument, options);
        }
export type SetHazardsProgressQueryHookResult = ReturnType<typeof useSetHazardsProgressQuery>;
export type SetHazardsProgressLazyQueryHookResult = ReturnType<typeof useSetHazardsProgressLazyQuery>;
export type SetHazardsProgressQueryResult = Apollo.QueryResult<SetHazardsProgressQuery, SetHazardsProgressQueryVariables>;