import gql from 'graphql-tag';

export const GET_SHARED_DOC_PROPERTIES = gql`
  query GetSharedDocProperties($input: PropertiesWithSharedDocumentsInput!) {
    propertiesWithSharedDocuments(input: $input) {
      properties {
        archipelagoId
        locationId
        locationName
        streetAddress
        city
        state
        country
        pictures {
          httpURLs {
            small
          }
        }
        documents {
          filename
          classification
        }
      }
      totalResults
    }
  }
`;
