import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IRenderer } from '../../types';

interface Props extends IRenderer {
  props: RouteComponentProps;
}

export default ({ render, component, props }: Props): ReactElement => {
  if (render) {
    return render(props);
  }

  if (component) {
    const Component = component;

    return <Component {...props} />;
  }

  throw new Error('No render or component function provided');
};
