import { GradeDefinitions } from '../Reports/queries/getStream';

export enum ExposureLevel {
  VeryHigh = 'VERY_HIGH',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  VeryLow = 'VERY_LOW',
}

export const AllExposureLevels = [
  ExposureLevel.VeryLow,
  ExposureLevel.Low,
  ExposureLevel.Medium,
  ExposureLevel.High,
  ExposureLevel.VeryHigh,
];

export type SegmentPercents = Record<ExposureLevel, number>;

export interface TableRow {
  label: string;
  percentScore: number;
  tiv: string | number;
  gradeDefinitions: GradeDefinitions[];
}
