import React from 'react';
import { EuiConfirmModal } from 'ui';
import { Role, RoleAssignment } from '@app/queries/rbac/types';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  currentAssignments: Array<RoleAssignment>;
  currentPMRole: Role;
}
const ConfirmUpdateModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  currentAssignments,
  currentPMRole,
}) => (
  <EuiConfirmModal
    title="You are about to change property manager permissions"
    onCancel={onCancel}
    onConfirm={onConfirm}
    cancelButtonText="No"
    confirmButtonText="Yes, delete assignments"
    buttonColor="warning"
    defaultFocusedButton="cancel"
  >
    <p>
      As a result, <b>{currentAssignments.length}</b> users with role <b>{currentPMRole}</b> will
      lose their assignments. Do you want to continue?
    </p>
  </EuiConfirmModal>
);

export default ConfirmUpdateModal;
