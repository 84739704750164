import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { IGroupedProps } from '@app/components/PropertiesGroupedList/types';
// FIX ME
// @ts-ignore
import GET_PROPERTY_MARKERS from '@app/queries/properties/getPropertyMarkers.gql';
import {
  BBox,
  GetPropertyMarkersData,
  GetPropertyMarkersVariables,
} from '@app/queries/properties/types';
import { getTileSize, TileConfig } from './tiling';
import { Cache } from './useMapCache';
import { getClusterZoom } from './utils';

const initialROI: BBox = {
  northEast: { latitude: 90, longitude: 180 },
  southWest: { latitude: -90, longitude: -180 },
};
const initialZoom: number = 3;
const batchInterval: number = 1000;

type Callback = () => void;

interface Props {
  group: IGroupedProps;
  withLossesOnly: boolean;
  cache: Cache;
  storeInCache: (k: string, v: GetPropertyMarkersData) => void;
}

// useMapPreloader - custom hook to batch propertyMarkers requests for each tile and zoom level containing data
const useMapPreloader = ({ group, withLossesOnly, cache, storeInCache }: Props) => {
  const apolloClient = useApolloClient();
  const [preloadQueue, setPreloadQueue] = useState<Array<GetPropertyMarkersVariables>>([]);
  const preload = () => {
    if (preloadQueue.length === 0) {
      return;
    }

    const currentBatch = preloadQueue.slice(0, 10);
    const remaining = preloadQueue.slice(10, preloadQueue.length);
    setPreloadQueue(remaining);

    const fetch = async (req: GetPropertyMarkersVariables) => {
      let data: GetPropertyMarkersData;
      const cacheKey = JSON.stringify(req);
      if (!cache[cacheKey]) {
        const { data: queryData, error } = await apolloClient.query<
          GetPropertyMarkersData,
          GetPropertyMarkersVariables
        >({
          query: GET_PROPERTY_MARKERS,
          variables: req,
        });

        if (!!error) {
          return;
        }

        data = queryData;
        storeInCache(cacheKey, queryData);
      } else {
        data = cache[cacheKey];
      }

      const {
        propertyMarkers: { markers },
      } = data;

      const {
        input: { roi, zoomLevel },
      } = req;

      // If there is no data for this tile, no need to add higher zoom subtiles to the preloading queue
      if (markers.length === 0 || zoomLevel >= 16) {
        return;
      }

      // Add subtiles from next zoom level to preloading queue
      const tc = new TileConfig(
        roi,
        getTileSize(zoomLevel + 1, group.filteredPropertyCount),
        zoomLevel + 1,
      );

      const preloadQueueMap = {};
      preloadQueue.forEach((r) => {
        preloadQueueMap[JSON.stringify(r)] = true;
      });
      const subtiles = tc
        .getTiles()
        .filter((value, index, array) => array.indexOf(value) === index)
        .map(
          (tileIndex): GetPropertyMarkersVariables => ({
            input: {
              pointSize: 128,
              propertyGroupId: group.id,
              roi: tc.getBBox(tileIndex),
              withLossesOnly,
              zoomLevel: tc.zoomLevel,
            },
          }),
        )
        .filter((r) => !preloadQueueMap[JSON.stringify(r)]);

      setPreloadQueue((q) => [...q.filter((r) => JSON.stringify(r) !== JSON.stringify(req)), ...subtiles]);
    };

    currentBatch.forEach(fetch);
  };

  const preloadRef = useRef<Callback>();
  preloadRef.current = preload;

  useEffect(() => {
    // Initial tile config which covers the full map at minimum zoom level
    const initialTileConfig = new TileConfig(
      initialROI,
      getTileSize(initialZoom, group.filteredPropertyCount),
      getClusterZoom(initialZoom),
    );

    // Initialize preloading queue with the first tiles to query
    const tiles = initialTileConfig.getTiles();
    setPreloadQueue(
      tiles.map(
        (tileIndex): GetPropertyMarkersVariables => ({
          input: {
            pointSize: 128,
            propertyGroupId: group.id,
            roi: initialTileConfig.getBBox(tileIndex),
            withLossesOnly,
            zoomLevel: initialTileConfig.zoomLevel,
          },
        }),
      ),
    );

    // Set (or reset) an interval on requests batching
    function tick() {
      preloadRef.current();
    }

    const id = setInterval(tick, batchInterval);
    return () => {
      // Clear interval and preloading queue when map params are changed
      clearInterval(id);
      setPreloadQueue([]);
    };
  }, [group?.id, withLossesOnly]);
};

export default useMapPreloader;
