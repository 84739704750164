import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportPropertyRecommendationsQueryVariables = Types.Exact<{
  input: Types.ExportPropertyRecommendationsInput;
}>;


export type ExportPropertyRecommendationsQuery = { __typename?: 'Query', exportPropertyRecommendations: { __typename?: 'ExportPropertyRecommendationsResponse', downloadURL: string } };


export const ExportPropertyRecommendationsDocument = gql`
    query ExportPropertyRecommendations($input: ExportPropertyRecommendationsInput!) {
  exportPropertyRecommendations(input: $input) {
    downloadURL
  }
}
    `;

/**
 * __useExportPropertyRecommendationsQuery__
 *
 * To run a query within a React component, call `useExportPropertyRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPropertyRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPropertyRecommendationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportPropertyRecommendationsQuery(baseOptions: Apollo.QueryHookOptions<ExportPropertyRecommendationsQuery, ExportPropertyRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPropertyRecommendationsQuery, ExportPropertyRecommendationsQueryVariables>(ExportPropertyRecommendationsDocument, options);
      }
export function useExportPropertyRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPropertyRecommendationsQuery, ExportPropertyRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPropertyRecommendationsQuery, ExportPropertyRecommendationsQueryVariables>(ExportPropertyRecommendationsDocument, options);
        }
export type ExportPropertyRecommendationsQueryHookResult = ReturnType<typeof useExportPropertyRecommendationsQuery>;
export type ExportPropertyRecommendationsLazyQueryHookResult = ReturnType<typeof useExportPropertyRecommendationsLazyQuery>;
export type ExportPropertyRecommendationsQueryResult = Apollo.QueryResult<ExportPropertyRecommendationsQuery, ExportPropertyRecommendationsQueryVariables>;