import gql from 'graphql-tag';
import { FRAGMENT_GRADE } from './fragments';
import { Grade } from './getCarrierAssessment';

export const GET_CARRIER_PROPERTY_ASSESSMENTS = gql`
  ${FRAGMENT_GRADE}

  query carrierPropertyAssessments($input: CarrierPropertyAssessmentsInput!) {
    carrierPropertyAssessments(input: $input) {
      assessments {
        carrierPropertyId
        rePropertyId
        street
        city
        state
        country
        sicCode
        sicDescription
        assessmentDate
        riskGrade {
          ...grade
        }
        hotWorkRiskFactor {
          ...grade
        }
        housekeepingRiskFactor {
          ...grade
        }
        trainingRiskFactor {
          ...grade
        }
        maintenanceRiskFactor {
          ...grade
        }
        managementRiskFactor {
          ...grade
        }
      }
      totalCount
    }
  }
`;

interface CarrierPropertyAssessment {
  carrierPropertyId: string;
  rePropertyId: string;
  street: string;
  city: string;
  state: string;
  country: string;
  assessmentDate: string;
  sicCode: number;
  sicDescription: string;
  riskGrade: Grade;
  hotWorkRiskFactor: Grade;
  housekeepingRiskFactor: Grade;
  trainingRiskFactor: Grade;
  maintenanceRiskFactor: Grade;
  managementRiskFactor: Grade;
}

export interface CarrierPropertyAssessmentsData {
  carrierPropertyAssessments: {
    totalCount: number;
    assessments: Array<CarrierPropertyAssessment>;
  };
}
