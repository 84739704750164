import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle, useToast } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { REQUEST_PRECHECK_RECOMMENDATIONS } from '@app/graphql/precheck/mutations/requestPrecheckRecommendations';
import { ModalWithButtons } from '../../../components/ModalWithButtons/ModalWithButtons';
import { useUserSession } from '../../../contexts/UserSessionContext';
import { usePrecheckContext } from '../../context/PrecheckContext';
import { usePrecheckClient } from '../../hooks/usePrecheckClient';
import { Bucket, BUCKET_MAPPING } from './Bucket';
import { WorkflowButtons } from './WorkflowButtons';

export const Recommendations = () => {
  // FIX ME
  // @ts-ignore
  const { recommendations, recommendationBuckets, canEditAllProperties } = usePrecheckContext();
  const { selectedOrganization } = useUserSession();
  const [modalType, setModalType] = useState<string | null>(null);
  const client = usePrecheckClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  const [requestPrecheckRecommendations, { loading }] = useMutation(
    REQUEST_PRECHECK_RECOMMENDATIONS,
    {
      client,
      onCompleted: (response) => {
        if (
          response.requestPreCheckRecommendations.supportTicketChatRoomID &&
          response.requestPreCheckRecommendations.supportTicketChatRoomName
        ) {
          setIsModalOpen(true);
        }
      },
      onError: (err) => toast({ title: 'Request error', type: 'danger' }),
    },
  );

  if (!recommendations.query.loading && recommendationBuckets.length === 0) {
    return (
      <div
        style={{
          lineHeight: '20px',
          marginBottom: '24px',
          marginTop: '24px',
          padding: '0 0 0 4px',
        }}
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <h3 id="recommendations">Recommendations</h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        <div style={{ height: '500px', marginTop: '24px' }}>
          {' '}
          there are no recommendations available for your portfolio
        </div>
      </div>
    );
  }

  const uniqueProps = recommendationBuckets.reduce((accumulator, currentValue) => {
    currentValue.properties.map((prop) => (accumulator[prop.property.archipelagoId] = true));

    return accumulator;
  }, {});

  return (
    <div
      style={{
        lineHeight: '20px',
        marginBottom: '24px',
        marginTop: '32px',
        padding: '0',
      }}
    >
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h3 id="recommendations">Recommendations to improve your score</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          Improve your data quality score by adding key data to {
            Object.entries(uniqueProps).length
          }{' '}
          properties{' '}
        </EuiFlexItem>

        <>
          <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
            <Button
              loading={loading && modalType === 'recs'}
              disabled={loading && modalType === 'recs'}
              label={'View Additional Recommendations'}
              onClick={() => {
                setModalType('recs');
                requestPrecheckRecommendations({
                  variables: {
                    input: {
                      orgName: selectedOrganization?.name,
                      requestType: 'ADDITIONAL_RECOMMENDATIONS',
                    },
                  },
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
            <Button
              loading={loading && modalType === 'air'}
              disabled={loading && modalType === 'air'}
              label={'View AIR Model Results'}
              onClick={() => {
                setModalType('air');
                requestPrecheckRecommendations({
                  variables: {
                    input: {
                      orgName: selectedOrganization?.name,
                      requestType: 'AIR_RESULTS',
                    },
                  },
                });
              }}
            />
          </EuiFlexItem>
        </>

        {canEditAllProperties && <WorkflowButtons />}
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {recommendations.query.loading ? (
        <div style={{ height: '800px' }}>
          <LoadingSpinnerV2 />
        </div>
      ) : (
        BUCKET_MAPPING.map((_, index) => {
          // name of the bucket is the index + 1
          const bucket = recommendationBuckets.find((bucket) => bucket.name === `${index + 1}`);

          const effectiveBucket = bucket ? bucket : { name: `${index + 1}`, properties: [] };
          return (
            <>
              <Bucket bucket={effectiveBucket} index={index} />
              <EuiSpacer size="m" />
            </>
          );
        })
      )}
      <EuiSpacer size="l" />
      <EuiSpacer size="l" />
      {isModalOpen && (
        <ModalWithButtons
          buttonCloseLabel="Close"
          onClose={() => {
            setModalType(null);
            setIsModalOpen(false);
          }}
          header={
            modalType === 'air'
              ? 'AIR Model Results Requested'
              : 'Additional Recommendations Requested'
          }
          subtext="Your request has been received. A support ticket has been created and you can view the status on the support tab."
        />
      )}
    </div>
  );
};
