import React from 'react';
import Histogram from '@onarchipelago/cx/Reports/Charts/Histogram/Histogram';
import { Options } from 'highcharts';
import { useGradingDetailsContext } from '../context';

const AverageLossHistogram: React.FC = () => {
  const { carrierAssessment } = useGradingDetailsContext();

  const makeLossLabel = (label: string) => {
    if (label === 'USD_0') {
      return '$0';
    }
    return `>${label
      .split('_')
      .filter((o) => o !== 'USD' && o !== 'TO')
      .map((o) => `$${o}`)
      .join('-')}`;
  };
  const LossHistogramLabels = carrierAssessment.lossFrequencyHistogram.buckets.map((b) =>
    makeLossLabel(b.category),
  );
  const makeLossData = carrierAssessment.lossFrequencyHistogram.buckets.map((b) => b.cnt);

  const histogramOptions = {
    baseSeries: 1,
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        color: '#2167AE',
        groupPadding: 0.1,
        pointPadding: 0,
        shadow: false,
      },
    },
    series: [
      {
        data: makeLossData,
        name: 'Data',
        showInLegend: false,
        type: 'column',
      },
    ],
    subtitle: {
      align: 'left',
      style: {
        fontWeight: 'bold',
      },
      text: 'Claims Count',
      y: 48,
    },
    title: {
      align: 'left',
      margin: 45,
      style: {
        marginBottom: '20px',
      },
      text: `Total claims for ${carrierAssessment.lossFrequencyHistogram.period}`,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      categories: LossHistogramLabels,
      title: {
        align: 'low',
        text: 'Paid Indemnity Range →',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
  };

  return <Histogram options={histogramOptions as Options} />;
};

export default AverageLossHistogram;
