import React from 'react';
import styled from '@emotion/styled';
import { EuiFlyout, EuiFlyoutHeader, EuiFocusTrap, EuiTitle } from 'ui';
import { Casualty } from '@app/graphql/types';
import { CasualtyWizard } from './CasualtyWizard/CasualtyWizard';

export const Flyout = styled(EuiFlyout)`
  .euiFlyout__closeButton {
    display: none;
  }
`;

export const CreateCasualtyFlyout: React.FC<{
  viewCasualty?: Casualty;
  // HACK: to lookup the enterprise account id later.
  casualtyList: Array<Casualty>;
  onClose?: () => void;
}> = ({ viewCasualty, casualtyList, onClose }) => (
    <EuiFocusTrap>
      <Flyout
        ownFocus
        onClose={(e) => {}}
        aria-labelledby="flyoutTitle"
        size="s"
        style={{ width: '1000px' }}
      >
        <EuiFlyoutHeader>
          <EuiTitle size="s">
            <h2>Submit a Casualty Account</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <CasualtyWizard onClose={onClose} viewCasualty={viewCasualty} casualtyList={casualtyList} />
      </Flyout>
    </EuiFocusTrap>
  );
