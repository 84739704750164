import queryString from 'query-string';
import { ITrackerMixpanel } from '@app/components/Tracker/types';
import { IAccountState } from '@app/containers/AuthProvider/types';

export type PageOptions = 'login' | 'signup' | 'code' | 'password';
const TEST_AUTOMATION_JOB_TITLE = 'Archipelago Test Automation';

/**
 * Initializes the user in the 'mixpanel' type and possibibly submits a Login event to MixPanel.
 * Not to be confused with mixpanel.init(). This does not initialize the 'mixpanel' instance.
 * @param mixpanel reference to the MixPanel instance
 * @param user the user to register for the 'mixpanel' instance
 * @param trackLogin whether calling this function shoul trigger a Login event in MixPanel
 */
export const initMixpanel = (
  mixpanel: ITrackerMixpanel,
  user: IAccountState,
  trackLogin: boolean,
) =>
  new Promise((res) => {
    if (user.title === TEST_AUTOMATION_JOB_TITLE && !mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_out_tracking();
      res('1');
      return;
    }

    if (user.title !== TEST_AUTOMATION_JOB_TITLE && mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_in_tracking();
    }

    // Identify a user with a unique ID to track user activity across devices, tie a user to their
    // events, and create a user profile. If you never call this method, unique visitors are tracked
    // using a UUID generated the first time they visit the site.
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelidentify
    mixpanel.identify(user.email);

    // Set properties on a user record.
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelpeopleset
    mixpanel.people.set({
      $last_login: new Date(),
    });

    // Set properties on a user record, only if they do not yet exist. This will not overwrite previous people property values, unlike people.set().
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelpeopleset_once
    mixpanel.people.set_once({
      $created: user.createdAt,
      $email: user.email,
      $name: `${user.givenName} ${user.familyName}`,
      auth0id: user.userId,
      intent: user.intent,
      organization: user.organizationName,
      phone: user.phone,
      title: user.title,
      ucode: user.userCode,
    });

    // Register a set of super properties, which are included with all events. This will overwrite
    // previous super property values.
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelregister
    mixpanel.register({
      auth0id: user.userId,
      email: user.email,
      ucode: user.userCode,
    });

    if (trackLogin) {
      // Track an event. This is the most important and frequently used Mixpanel function.
      // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpaneltrack
      mixpanel.track('Login', undefined, () => res(undefined));
    } else {
      // To prevent waiting forever if trackLogin = false.
      res('1');
    }
  });

export const getUserCodeFromURLString = (urlString: string): string | undefined => {
  if (!urlString.includes('?')) {
    return undefined;
  }

  const index = urlString.indexOf('?');
  const search = urlString.substring(index);

  const parsed = queryString.parse(search);
  const ucode = parsed?.ucode || parsed?.uCode;

  if (ucode) {
    return `${ucode}`;
  }
  return undefined;
};

const getStreamSlugsFromURLString = (urlString: string): Array<string> | undefined => {
  const streamShareUrlRegex = /\/share\?streams=[a-zA-Z0-9]{10}/g;
  const directStreamLinkRegex = /\/streams\/[a-zA-Z0-9]{10}-[a-zA-Z0-9-]+/g;

  if (streamShareUrlRegex.exec(urlString)) {
    // Example url: // Regex for the share link https://domain.website.com/share?streams=525b70acaa
    const index = urlString.indexOf('?');
    const search = urlString.substring(index);

    const parsed = queryString.parse(search);
    if (parsed?.streams) {
      return `${parsed.streams}`.split(',');
    }
  } else if (directStreamLinkRegex.exec(urlString)) {
    // Example url: https://domain.website.com/streams/7y1l1l7o94-prologis-ipt

    // Regex will make sure that the string will atleast contain "/streams/7y1l1l7o94-"
    const str = '/streams/';
    const index = urlString.indexOf(str);

    // The random identifier in the stream slug is always 10 characters long
    const startPos = index + str.length;
    return [urlString.substring(startPos, startPos + 10)];
  }
  return undefined;
};

// will get invitedBy values from localStorage
export const getInvitedByIfPresent = () => getUserCodeFromURLString(window.location.href);

// will get invitedFor values from localStorage
export const getInvitedForIfPresent = () => getStreamSlugsFromURLString(window.location.href);
