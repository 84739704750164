import React, { memo, useEffect } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { chartColors } from 'ui';
import { IGraphQLChart, IGraphQLTIVChart } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { buildTIVChartData, chartDataIsEqual, getSeries } from './utils';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const getOptions = (
  explorer: IGraphQLTIVChart,
  formatCurrency?: FormatCurrency,
  title?: string,
) => ({
  chart: {
    height: 190,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  colors: chartColors,
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      chart: {
        height: 500,
        style: {
          margin: '0 auto',
        },
        width: 265,
      },
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: false,
  },

  navigation: {
    buttonOptions: {
      y: -5,
    },
  },

  series: [getSeries('TIV', buildTIVChartData(explorer))],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      // eslint-disable-next-line
      return `${this.point.name}<br><b>${formatCurrency?.(this.point.y)}</b>`;
    },
  },

  yAxis: {
    title: {
      text: undefined,
    },
  },
});

const TivPieChart: React.FC<IProps> = ({ explorer, title }) => {
  const { formatStreamCurrency } = useStreamContext();

  return (
    <Explorer>
      <HighchartsReact
        highcharts={Highcharts}
        options={getOptions(explorer as IGraphQLTIVChart, formatStreamCurrency, title)}
      />
    </Explorer>
  );
};

export default memo(TivPieChart, chartDataIsEqual);
