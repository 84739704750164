import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import GeoJobButton from '@onarchipelago/dice/EnrichmentProjects/GeoJobButton';
import SovMapperButton from '@onarchipelago/dice/EnrichmentProjects/SovMapperButton';
import { IJob, TransitionStatus } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  EuiBasicTable,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPagination,
  EuiPanel,
  EuiTitle,
  EuiToolTip,
  Select,
  Toggle,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import ApplySOVJobButton from '@app/dice/SovJob/ApplySOVJobButton';
import CheckSOVJobButton from '@app/dice/SovJob/CheckSOVJobButton';
import GetSovWorksheetButton from '@app/dice/SovJob/GetSovWorksheetButton';
import { JobStatus, JobType, Status } from '@app/graphql/jobs/jobs.types';
// FIX ME
// @ts-ignore
import GET_ORGANIZATIONS_FEATURES from '@app/queries/features/features.gql';
// FIX ME
// @ts-ignore
import CHECKOUT_JOB from '@app/queries/organizations/checkoutJob.gql';
import { GET_JOBS } from '@app/queries/organizations/getJobs';
import { EJobType, GetJobsV2Data, GetJobsV2Variables } from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';
import {
  GetOrgAttributeMetadataVariables,
  OrgAttributeMetadataResult,
} from '@app/queries/propertyMetadata/types';
import { formatDate } from '@app/utils/format';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { RETRY_JOB_TYPES } from '../JobDetails/constants';
import { RetryJobButton } from '../JobDetails/RetryJobButton';
import FinishReview from './FinishReview';
import SubmitJob from './SubmitJob';
import {
  formatStatus,
  groupMetadataByName,
  JOB_STATUS_LABELS,
  SORT_OPTIONS,
  sortJobs,
} from './utils';

const renderLabelMore = (head: string, tail: Array<string>, metadataLabels: any) => (
  <EuiToolTip
    position="bottom"
    content={
      <>
        {tail.map((t) => (
          <p key={t}>{metadataLabels[t]}</p>
        ))}
      </>
    }
  >
    <EuiLink>{`${tail.length} more`}</EuiLink>
  </EuiToolTip>
);

export const renderAttributeLabel = (attributes: Array<string>, metadataLabels: any) => {
  if (attributes?.length > 1) {
    const [head, ...tail] = attributes;

    return (
      <div>
        {metadataLabels[head]} & {renderLabelMore(head, tail, metadataLabels)}
      </div>
    );
  }

  return metadataLabels[attributes?.[0]];
};

const MyJobs: FC<{}> = () => {
  const archipelagoOrgId = '371db111-249c-4e0b-946d-d91beb132207';
  const jobsListPageSize = 20;
  const { account } = useAuth();
  const toast = useToast();
  const { selectedOrganization } = useContext(UserSessionContext);
  const history = useHistory();
  const jobsApolloClient = useJobsApolloClient();
  const isAdmin = account?.permissions?.admin;

  if (!account) {
    return null;
  }

  if (!isAdmin) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <p>You dont have permission to access the jobs page.</p>
      </div>
    );
  }

  localStorage.removeItem('provenanceJobData');

  const [filterOption, setFilterOption] = useState(SORT_OPTIONS[0].value);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [currJobLoading, setCurrJobLoading] = useState('');
  const [activeJob, setActiveJob] = useState({ document: {}, jobId: '', orgName: '' });
  const [jobType, setJobType] = useState('');
  const [orgFeatures, setOrgFeatures] = useState<string[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageCursor, setPageCursor] = useState<string>(btoa('{"Offset":0}'));

  const getJobRoute = (type: string) => {
    switch (type) {
      case 'ColumnMapping':
        return 'ingest';

      case 'Geo':
        return 'geo/properties';

      case 'EnrichmentWithProvenance':
        return 'prov-enrichment';

      default:
        return '';
    }
  };

  const [checkoutJob] = useMutation(CHECKOUT_JOB, {
    client: jobsApolloClient,
    onCompleted: (data) => {
      const { httpURL } = data.checkoutJob;
      const { document, jobId, orgName } = activeJob;

      setCurrJobLoading('');

      if (jobType === 'ColumnMapping' || jobType === 'Geo') {
        history.push(
          encodeUrl`/jobs/${jobId}/${jobType === 'ColumnMapping' ? 'ingest' : 'geo/properties'}`,
          {
            document,
            orgName,
          },
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (!window.Cypress) {
        window.location.href = httpURL;
      }
    },
    onError: (err) => {
      toast({ title: getErrorMessage(err), type: 'danger' });
      setCurrJobLoading('');
    },
  });

  const getJobsVariables = {
    jobsFilter: {
      activeOnly: !showAll,
      userID: account?.userId as string,
    },
    pageCursor,
    pageSize: jobsListPageSize,
  };

  const {
    data,
    loading,
    error,
    refetch: refetchJobs,
  } = useQuery<GetJobsV2Data, GetJobsV2Variables>(GET_JOBS, {
    client: jobsApolloClient,
    variables: getJobsVariables,
  });

  const {
    data: orgAttrList,
    loading: metadataLoading,
    error: metadataError,
  } = useQuery<OrgAttributeMetadataResult, GetOrgAttributeMetadataVariables>(
    GET_ORGANIZATION_ATTRIBUTE_METADATA,
    {
      variables: {
        // Hardcoding Archipelago Analytics Inc id until we find a good workflow
        // for fetching metadata outside an organization
        organizationId: archipelagoOrgId,
      },
    },
  );

  const { data: orgFeatureData } = useQuery(GET_ORGANIZATIONS_FEATURES, {
    variables: {
      orgName: selectedOrganization?.name,
    },
  });

  useEffect(() => {
    const totalCount = data?.jobsV2?.pageInfo?.totalCount;
    const jobsPageCount = Math.ceil(totalCount / jobsListPageSize);

    setPageCount(jobsPageCount);
  }, [data?.jobsV2.pageInfo]);

  useEffect(() => {
    refetchJobs(getJobsVariables);
    setCurrJobLoading('');
  }, [pageIndex, pageCursor]);

  useEffect(() => {
    setOrgFeatures(orgFeatureData?.features?.map((feature: any) => feature.name));
  }, [orgFeatureData]);

  useEffect(() => {
    if (error) {
      toast({ title: 'Could not fetch jobs', type: 'danger' });
    }
    if (metadataError) {
      toast({ title: 'Could not fetch metadata', type: 'danger' });
    }
  }, [error, metadataError]);

  if (error) {
    return null;
  }

  if (loading || metadataLoading) {
    return <LoadingSpinnerV2 dataTestId="loading-spinner" />;
  }

  const metadataLabels = orgAttrList ? groupMetadataByName(orgAttrList) : {};

  const updateFilterOption = (value: string) => {
    setFilterOption(value);
  };

  const toggleShowCompleted = () => {
    setShowAll(!showAll);
  };

  const checkoutNewJob = (jobID: string, type?: string) => {
    setCurrJobLoading(jobID);
    setJobType(type);

    const options = {
      refetchQueries: [
        {
          query: GET_JOBS,
          variables: {
            jobsFilter: {
              activeOnly: true,
              userID: account?.userId as string,
            },
            pageSize: jobsListPageSize,
          },
        },
      ],
      variables: {
        input: {
          jobID,
        },
      },
    };

    checkoutJob(options);
  };

  const filteredJobList = sortJobs([...(data?.jobsV2.jobs || [])], showAll, filterOption);

  const buildPageCursor = (index: number) => {
    const offset = index * jobsListPageSize;

    const encodedOffset = `{"Offset":${offset}}`;
    return btoa(encodedOffset);
  };

  const onTableChange = (index) => {
    if (index !== pageIndex) {
      setPageIndex(index);
      setPageCursor(buildPageCursor(index));
    }
  };
  const isPE2Enabled = (version) => version === 2;

  /* eslint-disable */
  const renderActionByStatus = (job: IJob) => {
    const isReviewer = job.reviewerProfile?.userID === account?.userId;
    const isDoer = job.doerProfile?.userID === account?.userId;
    const isSelfAssigned = isReviewer && isDoer;

    const latestLifecycle = job?.lifeCycle[0];

    const prevLifecycleProgress =
      job.lifeCycle.length === 1 ? 'Completed' : job?.lifeCycle[1].progress;

    const documentModel = {
      document: {
        ...latestLifecycle?.worksheet,
        id: job.sovDocId,
      },
    };

    const onClickAction = (job: IJob, inProgress?: boolean) => {
      setJobType(job.type);
      setActiveJob({
        document: documentModel,
        jobId: job.id,
        orgName: job.orgName,
      });

      let url;
      if (job.type === 'Geo') {
        url = encodeUrl`/organizations/${job.orgName}/jobs/${job.id}/geo/properties`;
      } else {
        url = encodeUrl`/organizations/${job.orgName}/jobs/${job.id}/${getJobRoute(job.type)}`;
      }

      if (inProgress) {
        history.push(url, {
          document: documentModel,
          orgName: job.orgName,
        });
      } else if (job.type === 'EnrichmentWithProvenance') {
        history.push(url);
      } else {
        checkoutNewJob(job.id);
      }
    };

    switch (job.status) {
      case 'Ready':
        // Using the String() func as a workaround. Sov Job type has not been added to the API yet?
        if (String(job.type) === 'SOV') {
          // I need to return a sovJobButton here. I'll make it a separate button, since it has specific logic
          return <GetSovWorksheetButton jobID={job.id} />;
        }
        if (
          job.type === EJobType.Ingest &&
          latestLifecycle?.progress === TransitionStatus.Started
        ) {
          return <Button size="s" fill onClick={() => refetchJobs()} label="Refresh" />;
        }
        if (
          job.type === EJobType.Geo &&
          latestLifecycle?.progress === TransitionStatus.Started &&
          latestLifecycle?.to === 'Ready'
        ) {
          return <Button size="s" fill onClick={() => refetchJobs()} label="Refresh" />;
        }

        if (isReviewer && !isSelfAssigned) {
          return null;
        } else {
          if (job.type === 'ColumnMapping' && prevLifecycleProgress === 'Completed') {
            return <SovMapperButton onClick={() => onClickAction(job)} />;
          } else if (job.type === 'Geo' && prevLifecycleProgress !== 'Completed') {
            return (
              <GeoJobButton
                testId={`job-col__action--row${job.id}`}
                onClick={() => onClickAction(job)}
              />
            );
          } else {
            return (
              <Button
                data-testid="start-job"
                size="s"
                fill
                onClick={() => {
                  job.type === 'EnrichmentWithProvenance' && isPE2Enabled(job.version)
                    ? onClickAction(job)
                    : checkoutNewJob(job.id, job.type);
                }}
                label={currJobLoading === job.id ? 'Loading' : 'Start'}
              />
            );
          }
        }

      case 'InProgress':
        if (job.type === 'Geo' && prevLifecycleProgress === 'Completed') {
          return (
            <GeoJobButton
              testId={`job-col__action--row${job.id}`}
              onClick={() => onClickAction(job, true)}
            />
          );
        } else if (String(job.type) === 'SOV') {
          // Using the String() func as a workaround. Sov Job type has not been added to the API yet?
          // I need to return a sovJobButton here. I'll make it a separate button, since it has specific logic
          // TODO: Make the "Upload" button here
          return (
            <CheckSOVJobButton
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          );
        } else if (job.type === 'ColumnMapping' && prevLifecycleProgress === 'Completed') {
          return <SovMapperButton onClick={() => onClickAction(job, true)} />;
        } else if (!isReviewer || isSelfAssigned) {
          return (
            <SubmitJob
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          );
        } else {
          return null;
        }

      case 'ReadyForReview':
        if (String(job.type) === 'SOV') {
          // Apply the job.
          return <ApplySOVJobButton jobID={job.id} />;
        }
        if (latestLifecycle.progress === 'Started') {
          return <Button size="s" fill onClick={() => refetchJobs()} label="Refresh" />;
        }
        if (latestLifecycle.progress === 'Failed') {
          return isDoer ? (
            <SubmitJob
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          ) : null;
        }
        if (latestLifecycle.progress === 'Completed') {
          if (isReviewer && job.type === 'EnrichmentWithProvenance' && job.version === 2) {
            return (
              <Button
                data-testid="review-pe2-job"
                size="s"
                fill
                onClick={() =>
                  history.push({
                    pathname: encodeUrl`/organizations/${job.orgName}/jobs/${job.id}/prov-enrichment/review`,
                  })
                }
                label="Review"
              />
            );
          }
          return isDoer && !isSelfAssigned ? null : (
            <FinishReview
              jobID={job.id}
              jobName={job.name}
              jobType={job.type}
              lifecycles={job.lifeCycle}
              projectName={job.projectName}
              orgName={job.orgName}
              userID={account?.userId as string}
              document={documentModel}
            />
          );
        } else {
          return isReviewer && !isSelfAssigned ? null : (
            <SubmitJob
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          );
        }

      case 'ValidateFailed':
        if (job.type === 'Geo') {
          return (
            <GeoJobButton
              testId={`job-col__action--row${job.id}`}
              onClick={() => onClickAction(job, true)}
            />
          );
        } else if (job.type === 'EnrichmentWithProvenance' && job.version === 2) {
          return (
            <Button
              data-testid="start-job"
              size="s"
              fill
              onClick={() => {
                onClickAction(job);
              }}
              label={currJobLoading === job.id ? 'Loading' : 'Start'}
            />
          );
        } else {
          return isDoer ? (
            <SubmitJob
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          ) : null;
        }

      case 'ChangesRequested':
        // If changes are requested on col map jobs we should always return the sov mapper
        if (isReviewer && !isSelfAssigned) {
          return null;
        } else if (job.type === 'ColumnMapping') {
          return <SovMapperButton onClick={() => onClickAction(job)} />;
        } else if (job.type === 'Geo') {
          return <GeoJobButton onClick={() => onClickAction(job)} />;
        } else if (job.type === 'EnrichmentWithProvenance' && job.version === 2) {
          return (
            <Button
              data-testid="start-job"
              size="s"
              fill
              onClick={() => {
                onClickAction(job);
              }}
              label={currJobLoading === job.id ? 'Loading' : 'Start'}
            />
          );
        } else {
          return isReviewer && !isSelfAssigned ? null : (
            <SubmitJob
              jobID={job.id}
              jobName={job.name}
              projectName={job.projectName}
              orgName={job.orgName}
            />
          );
        }

      case 'Complete':
        if (latestLifecycle.progress === 'Started') {
          return <Button label="Refresh" onClick={() => refetchJobs()} />;
        }

      case 'Failed':
        // FIX ME
        // @ts-ignore
        if (RETRY_JOB_TYPES.includes(job?.type) && isAdmin) {
          return <RetryJobButton jobID={job.id} />;
        }

      case 'Archived':
        const currentLifeCycle = job?.lifeCycle?.[0];
        if (
          // FIX ME
          // @ts-ignore
          RETRY_JOB_TYPES.includes(job?.type) &&
          currentLifeCycle?.to === JobStatus.Complete &&
          currentLifeCycle?.progress === TransitionStatus.Failed &&
          isAdmin
        ) {
          return <RetryJobButton jobID={job.id} />;
        }

      default:
        break;
    }
  };

  const columns = [
    {
      field: 'name',
      name: 'Job name',
      render: (name: string, row: any) => (
        <Link data-testid={`job-col__name--row${row.id}`} to={`/jobs/${row.id}`}>
          {name}
        </Link>
      ),
    },
    {
      field: 'type',
      name: 'Type',
      render: (type: string, row: any) => (
        <span data-testid={`job-col__type--row${row.id}`}>{type}</span>
      ),
    },
    {
      field: 'priority',
      name: 'Priority',
      render: (priority: any, row: any) => (
        <span data-testid={`job-col__priority--row${row.id}`}>{priority}</span>
      ),
    },
    {
      field: 'type',
      name: 'Attributes',
      render: (_: string, row: any) => (
        <span data-testid={`job-col__attrLabels--row${row.id}`}>
          {renderAttributeLabel(row.attributeNames, metadataLabels)}
        </span>
      ),
    },
    {
      field: 'orgName',
      name: 'Organization',
      render: (orgName: string) => (
        <Link to={encodeUrl`/organizations/${orgName}/projects`}>{orgName}</Link>
      ),
    },
    {
      field: 'projectName',
      name: 'Project',
      render: (projectName: string, row: any) => (
        <Link to={encodeUrl`/organizations/${row.orgName}/projects/${projectName}`}>
          {projectName}
        </Link>
      ),
    },
    {
      field: 'dueOn',
      name: 'Due date',
      render: (dueOn: string) => (dueOn !== null ? formatDate(dueOn as string) : ''),
      width: '120px',
    },
    {
      field: 'status',
      name: 'Status',
      render: (status: string, row: any) => (
        <span data-testid={`job-col__status--row${row.id}`}>{formatStatus(row)}</span>
      ),
    },
    {
      field: 'action',
      name: 'Action',
      render: (type: string, row: any) => renderActionByStatus(row),
    },
  ];

  return (
    <div data-testid="my-jobs" style={{ padding: 24, display: 'flex', flex: '1 1 auto' }}>
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup justifyContent="spaceBetween" responsive={false}>
            <EuiFlexItem grow={8}>
              <EuiTitle size="m">
                <h2>My Jobs</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={4}>
              <EuiFlexGroup
                direction="row"
                gutterSize="s"
                alignItems="center"
                justifyContent="spaceBetween"
                responsive={false}
              >
                <EuiFlexItem grow={false}>
                  <Button href="https://schemadocs.onarchipelago.com/" label="View schema docs" />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Toggle
                    data-testid={'my-jobs-completed-archived-switch'}
                    label="Show Completed/Archived"
                    checked={showAll}
                    onChange={toggleShowCompleted}
                    size="s"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Select
                    options={SORT_OPTIONS?.map((option) => {
                      return {
                        label: option.label,
                        value: option.value,
                      };
                    })}
                    value={filterOption}
                    onChange={updateFilterOption}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {!data?.jobsV2.jobs ? (
          <EuiEmptyPrompt title={<h2>No jobs assigned to you yet</h2>} />
        ) : (
          <EuiFlexItem>
            <EuiFlexGroup direction="column" alignItems="center">
              <EuiFlexItem>
                <EuiFlexGroup direction="column" alignItems="flexEnd">
                  <EuiFlexItem grow={false}>
                    <EuiPanel paddingSize="l">
                      <EuiBasicTable
                        rowProps={{ 'data-testid': 'job-row' }}
                        items={filteredJobList}
                        rowHeader="name"
                        columns={columns}
                        tableLayout="auto"
                      />
                    </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiPagination
                      activePage={pageIndex}
                      pageCount={pageCount}
                      onPageClick={onTableChange}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  );
};

export default MyJobs;
