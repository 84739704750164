import React from 'react';
import { Button, Checkbox, EuiFlexItem, EuiFormRow, EuiTextArea, SearchSelect } from 'ui';
import { useQueryState } from '@app/hooks/useQueryState';
import { useCopilotContext } from '../../context/context';
import { QueryContainer } from '../../Copilot.emotion';
import { AttributeSelect } from './AttributeSelect';
import { PropertySelect } from './PropertySelect';
import { ArchipelagoSearchFormatContainer } from './PropertyTab.emotion';

export const ArchipelagoFormatSearch: React.FC = () => {
  const {
    workspaceData: {
      searchFormValues: {
        propertyArchipelagoID,
        attributeName,
        question,
        attributesWithMappingsOnly,
      },
    },
    updateSearchForm,
    searchQuery: { exec, loading },
  } = useCopilotContext();

  const [_, setQueryState] = useQueryState();
  const setQuestion = (v: string) => {
    updateSearchForm('question')(v);
  };

  return (
    <ArchipelagoSearchFormatContainer direction="column">
      <EuiFlexItem grow={false}>
        <EuiFormRow label="Property" fullWidth>
          <PropertySelect
            value={propertyArchipelagoID}
            handleChange={(archipelagoID) => {
              setQueryState({ archipelagoID, attributeName });
            }}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow label="Attribute" fullWidth>
          <AttributeSelect />
        </EuiFormRow>
        <Checkbox
          checked={attributesWithMappingsOnly}
          label="With mappings only"
          id="mappings-filter"
          onChange={(e) => {
            updateSearchForm('attributesWithMappingsOnly')(e.target.checked);
          }}
        />
      </EuiFlexItem>
      <QueryContainer>
        <EuiFormRow label="Query" fullWidth display="row">
          <EuiTextArea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            compressed={false}
          />
        </EuiFormRow>
      </QueryContainer>
      <EuiFlexItem grow={false}>
        <Button
          onClick={exec}
          loading={loading}
          label="Query"
          disabled={!propertyArchipelagoID || !attributeName}
        />
      </EuiFlexItem>
    </ArchipelagoSearchFormatContainer>
  );
};
