import { SelectedOrganizationSummary } from '@app/contexts/UserSessionContext';
import { GetProjectsData } from '@app/queries/organizations/getProjects';
import { UpdateType } from '../types';

interface Props {
  data: GetProjectsData;
  loading: boolean;
  organizationName: string;
  selectedOrganization?: SelectedOrganizationSummary;
}

export default ({ data, loading, organizationName, selectedOrganization }: Props): UpdateType => {
  // If the user has access to a project from the organization, and the organization in the cache is not the
  // currently selected orgdata then we update the selected organization to what is in the url
  if (!!data[organizationName] && organizationName !== selectedOrganization?.name) {
    return 'update';
  }

  if (!loading && Object.keys(data).length === 0) {
    return 'return';
  }

  return 'none';
};
