"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("@emotion/css");
var hideX = (0, css_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .euiBadge__iconButton {\n    display: none;\n  }\n"], ["\n  .euiBadge__iconButton {\n    display: none;\n  }\n"])));
exports.default = {
    hideX: hideX,
};
var templateObject_1;
