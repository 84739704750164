import styled from '@emotion/styled';
import { Button } from 'ui';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isLarge',
})<{ isLarge: boolean }>`
  background-color: white;
  border: solid 1px;
  box-shadow: 0 2px 2px -1px rgba(102, 102, 102, 0.3);
  min-inline-size: 30px;
  ${(props) =>
    props.isLarge &&
    `
    min-inline-size: 250px;
    height: 32px;
    border: none;
    box-shadow: none;
    background-color: #FBFCFD;
    > span {
      justify-content: space-between;
    }
  `};
`;
