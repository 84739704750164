import { css } from '@emotion/css';
import { colorLevels } from '../../../colors';
import { ExposureLevel } from '../../../types';

const circle = css`
  background: #343741;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const square = (exposureLevel: ExposureLevel) => css`
  height: 8px;
  width: 8px;
  background: ${colorLevels[exposureLevel]};
`;

export default {
  circle,
  square,
};
