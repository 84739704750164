import React, { useEffect, useState } from 'react';
import { builder } from '@builder.io/react';
import { AssetName } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_CMS } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';

export const useBuilderWhiteLabel = () => {
  const { selectedOrganization } = useUserSession();
  const isCMSEnabled = isFeatureEnabled(selectedOrganization?.enabledFeatures, FEATURE_TYPE_CMS);
  if (!isCMSEnabled) {return {};}

  const [whiteLabel, setWhiteLabel] = useState({});

  useEffect(() => {
    async function fetchContent() {
      const label = await builder.getAll('white-label', {});

      if (!label[0]) {return;}

      setWhiteLabel({
        assetLink: label[0].data.logo,
        assetName: AssetName.logo,
        themeOverride: {
          colors: {
            LIGHT: { primary: label[0].data.primaryLight },
          },
        },
      });
    }
    fetchContent();
  }, [selectedOrganization?.name]);

  return whiteLabel;
};
