import React from 'react';
import { useTaskContext } from './context/TaskContext';
import { Button, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiSpacer, Icon } from 'ui';

export const ValuationCallout = () => {
  const { taskTotals, filters } = useTaskContext();

  if (taskTotals.valuation.properties === 0) {
    return null;
  }

  return (
    <>
      <EuiSpacer size="l" />
      <EuiCallOut
        size="s"
        title={
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem style={{ justifyContent: 'center' }} grow={false}>
              <Icon name="dollarSign" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              We've found {taskTotals.valuation.properties} properties that might be misvalued
            </EuiFlexItem>
            <EuiFlexItem style={{ alignItems: 'end' }} grow={2}>
              <Button
                onClick={() => {
                  filters.setFilter('reasons', ['Valuation Outlier']);
                }}
                size="s"
                label="View properties"
                style={{ width: '150px', backgroundColor: 'rgb(254, 197, 20)', color: '#000' }}
              ></Button>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        color="warning"
      ></EuiCallOut>
    </>
  );
};
