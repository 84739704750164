import { css } from '@emotion/css';
import { colorLevels } from '../../colors';
import { ExposureLevel } from '../../types';

const square = (exposureLevel: ExposureLevel) => css`
  background: ${colorLevels[exposureLevel]};
  height: 8px;
  width: 8px;
`;

export default {
  square,
};
