import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const StyledPercent = styled.span`
  font-size: 24px;
  margin-right: 8px;
`;

export const StyledDescription = styled.div`
  height: 60px;
`;

export const StyledExamples = styled.div`
  font-size: 12.25px;
  font-weight: 400;
  line-height: 20px;

  color: #69707d;
`;

export const StyledPanel = styled(EuiPanel)<{ active: boolean }>`
  border-color: ${({ active }) => (active ? '#0F766E' : '')};

  background: ${({ active }) => (active ? 'rgba(15, 118, 110, 0.05)' : '')};

  :hover {
    box-shadow: 0px 15px 15px -1px #0000000a !important;
  }
`;

export const StyledLoadContainer = styled.div`
  height: 200px;
`;

export const StyledBadgeContainer = styled.div`
  height: 20px;
`;
