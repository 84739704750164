import gql from 'graphql-tag';

export const GET_VALUATION_SUMMARY_FILTER_VALUES = gql`
  query ValuationSummaryFilterValues($input: ValuationSummaryFilterValuesInput!) {
    valuationSummaryFilterValues(input: $input) {
      attributeValues {
        attribute
        values
      }
    }
  }
`;
