import React, { FC } from 'react';
import { EuiText, EuiToolTip, useEuiTheme } from 'ui';
import { HeadingTooltip, ParagraphTooltip } from './ChartTitle.emotion';

// TODO:
// * Change sizes to 's' | 'm' | 'l' to match EUI's size types
interface Props {
  titleSize?: 'medium' | 'large';
  tooltip?: string;
}

const ChartTitle: FC<Props> = ({ children, titleSize = 'large', tooltip }) => {
  const { euiTheme } = useEuiTheme();
  const { colors } = euiTheme;
  if (tooltip) {
    return (
      <EuiToolTip data-testid="foo" content={tooltip}>
        <EuiText data-testid="chart-title" size="s">
          {/* TODO: */}
          {/* * Change to a switch clause to allow different type of sizes */}
          {titleSize === 'large' ? (
            <HeadingTooltip color={colors.primary} data-testid="h4-title">
              {children}
            </HeadingTooltip>
          ) : (
            <ParagraphTooltip color={colors.primary} data-testid="p-title">
              {children}
            </ParagraphTooltip>
          )}
        </EuiText>
      </EuiToolTip>
    );
  }

  if (titleSize === 'large') {
    return (
      <EuiText data-testid="chart-title" size="s">
        <h4 data-testid="h4-title">{children}</h4>
      </EuiText>
    );
  }

  return (
    <EuiText data-testid="chart-title" size="s">
      <p data-testid="p-title">{children}</p>
    </EuiText>
  );
};

export default ChartTitle;
