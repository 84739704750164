import { getGrandparentAttributeName } from '@onarchipelago/cx/utils/subvalues';
import { Property } from '@app/graphql/types';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

export const isEditMode = (locked: boolean, currentJobs: Array<Lock> | undefined) => {
  if (!locked || !currentJobs || currentJobs.length !== 1) {
    return false;
  }

  const job = currentJobs[0];
  return job.type === EJobType.EditProperty && job.canReuse;
};

export const getValue = (gqlRes: any, cm: IGraphQLAttributeMetadata) => {
  // FIXME: instead of using `consumerAttributes` and `ownerAttributes`
  // we should see if we can make this metadata driven.

  let value = gqlRes[cm.name];
  if (gqlRes?.consumerAttributes?.hasOwnProperty(cm.name)) {
    value = gqlRes?.consumerAttributes[cm.name];
  } else if (gqlRes?.ownerAttributes?.hasOwnProperty(cm.name)) {
    value = gqlRes?.ownerAttributes[cm.name];
  }

  return value;
};

export const buildContent = (
  dimensions: any,
  propertyMetadata: Array<IGraphQLAttributeMetadata>,
  gqlResponse: any,
) => {
  const printableSchema = Object.keys(dimensions).reduce(
    (obj, key) => ({
      ...obj,
      [key]: {
        ...obj[key],
        labeledRows: [],
        unlabeledRows: [],
      },
    }),
    dimensions,
  );

  for (let i = 0; i < propertyMetadata?.length; i++) {
    const currentMetadata = propertyMetadata[i];
    const value = getValue(gqlResponse, currentMetadata);

    // Fill the printable schema with rows full of matched-up key/value pairs
    const grandparentAttributeName = getGrandparentAttributeName(
      propertyMetadata,
      currentMetadata.name,
    );
    if (grandparentAttributeName) {
      printableSchema[grandparentAttributeName].labeledRows.push({
        ...currentMetadata,
        value,
      });
      printableSchema[grandparentAttributeName].unlabeledRows.push({
        ...currentMetadata,
        value,
      });
    } else if (
      currentMetadata?.parent &&
      Object.prototype.hasOwnProperty.call(dimensions, currentMetadata.parent)
    ) {
      printableSchema[currentMetadata.parent].labeledRows.push({
        ...currentMetadata,
        value,
      });
      printableSchema[currentMetadata.parent].unlabeledRows.push({
        ...currentMetadata,
        value,
      });
    } else if (Object.prototype.hasOwnProperty.call(dimensions, currentMetadata.name)) {
      printableSchema[currentMetadata.name].labeledRows.push({
        ...currentMetadata,
        value,
      });
      printableSchema[currentMetadata.name].unlabeledRows.push({
        ...currentMetadata,
        value,
      });
    }

    if (
      !printableSchema[currentMetadata.name] &&
      currentMetadata?.parent &&
      !printableSchema[currentMetadata.parent] &&
      // note: we omit this parent because the values are calculated by us and not exact, therefore we
      // should not display them to clients. See: https://archipelago-analytics.slack.com/archives/CM24GLEKW/p1633449431036500?thread_ts=1633423288.032800&cid=CM24GLEKW
      currentMetadata.parent !== 'acquisitionOrOccupancyDate'
    ) {
      Object.keys(printableSchema).forEach((key) => {
        if (printableSchema[key].dimension === currentMetadata.dimension) {
          printableSchema[key].labeledRows.push({
            ...currentMetadata,
            value,
          });
          printableSchema[key].unlabeledRows.push({
            ...currentMetadata,
            value,
          });
        }
      });
    }
  }
  return printableSchema;
};

// usesCurrencyConversion is TRUE when Currency Pair is populated (i.e. Valuation Currency and
// Display Currency are populated and set to different values) AND Exchange Rate value is populated
// https://app.shortcut.com/onarchipelago/story/82171/property-modal-use-of-local-currency-is-strange-and-no-needed-as-a-standard
export const usesCurrencyConversion = (property: IGraphQLProperty | Property): Boolean =>
  property.currencyPair && property.exchangeRate && property.exchangeRate > 0;
