import getExtensionType from '@app/components/DocumentExtension/getExtensionType';
import { Extension } from '@app/components/DocumentExtension/types';
import { getFileURL } from '@app/utils/download';
import styled from '@emotion/styled';
import React from 'react';
import { EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer, Icon } from 'ui';
import { StyledPanel } from './Documents.emotion';
import { useUploads } from '@app/contexts/UploadsContext';
import moment from 'moment';

const svgFile = {
  [Extension.Image]: 'imageFileColor',
  [Extension.Other]: 'fileColor',
  [Extension.PDF]: 'pdfFileColor',
  [Extension.Spreadsheet]: 'excelFileColor',
  [Extension.Word]: 'wordFileColor',
};

export const Documents = ({ documents }) => {
  if (documents.length === 0) return null;

  return (
    <>
      <EuiSpacer size="l" />
      <EuiAccordion
        id="uploaded-docs"
        buttonProps={{ style: { fontWeight: 600 } }}
        buttonContent={`Uploaded Documents (${documents.length})`}
      >
        {documents.map((doc) => {
          return (
            <StyledPanel color="subdued">
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem>
                  <EuiFlexGroup alignItems="flexEnd" gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <Icon
                        data-testid="document-extension-file-icon"
                        name={svgFile[getExtensionType(doc.extension || 'pdf')]}
                        size="m"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiLink target="_blank" href={getFileURL(doc.id)}>
                        {doc.filename}
                      </EuiLink>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  {moment(doc.createdAt).format('MMM DD, YYYY')}
                </EuiFlexItem>
              </EuiFlexGroup>
            </StyledPanel>
          );
        })}
        <EuiSpacer size="s" />
      </EuiAccordion>
    </>
  );
};
