import { DEFAULT_FILTER_VALUE, FiltersAttributeEnum } from '../constants';
import { FilterOptionsAttributeValueType, FilterOptionsType, QueryFilterInputType } from '../types';

export const extractFilterOptions = (
  attributeValues: Array<FilterOptionsAttributeValueType>,
): FilterOptionsType => {
  const transformedValues = attributeValues.map((attr) => {
    const options = attr?.values?.map((value) => ({ label: value, value })) || [];
    return {
      attribute: attr?.attribute,
      options,
    };
  });

  const occupancyOptions =
    transformedValues?.find((attr) => attr.attribute === FiltersAttributeEnum.OCCUPANCY_TYPE)
      ?.options || [];
  occupancyOptions?.unshift({ label: 'All occupancies', value: DEFAULT_FILTER_VALUE });

  const constructionOptions =
    transformedValues?.find((attr) => attr.attribute === FiltersAttributeEnum.CONSTRUCTION_TYPE)
      ?.options || [];
  constructionOptions?.unshift({ label: 'All construction classes', value: DEFAULT_FILTER_VALUE });

  const stateOptions =
    transformedValues?.find((attr) => attr.attribute === FiltersAttributeEnum.STATE_TYPE)
      ?.options || [];
  stateOptions?.unshift({ label: 'All US states', value: DEFAULT_FILTER_VALUE });

  return {
    constructionOptions,
    occupancyOptions,
    stateOptions,
  };
};

export const formatSelectedFilters = (
  occupancyFilter: string,
  constructionFilter: string,
  stateFilter: string,
): Array<QueryFilterInputType> => {
  const filter = [];
  if (occupancyFilter && occupancyFilter !== DEFAULT_FILTER_VALUE) {
    filter.push({ attribute: FiltersAttributeEnum.OCCUPANCY_TYPE, value: occupancyFilter });
  }

  if (constructionFilter && constructionFilter !== DEFAULT_FILTER_VALUE) {
    filter.push({ attribute: FiltersAttributeEnum.CONSTRUCTION_TYPE, value: constructionFilter });
  }

  if (stateFilter && stateFilter !== DEFAULT_FILTER_VALUE) {
    filter.push({ attribute: FiltersAttributeEnum.STATE_TYPE, value: stateFilter });
  }

  return filter;
};
