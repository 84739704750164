import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import GoogleMapReact from 'google-map-react';
import { useToast } from 'ui';
import { setMapQueryString } from '@app/components/GoogleMap/GoogleMap';
import { IMap, MapPosition } from '@app/components/GoogleMap/types';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { IGroupedProps } from '@app/components/PropertiesGroupedList/types';
import { SizeBy } from '@app/components/PropertiesMap/types';
import { GOOGLE_MAPS_API_KEY } from '@app/config';
import { AttributeFilter } from '@app/graphql/types';
// FIX ME
// @ts-ignore
import GET_PROPERTY_MAP_STATS from '@app/queries/properties/getPropertyMapStats.gql';
import {
  GetPropertyMapStatsData,
  GetPropertyMapStatsVariables,
  GraphqlPropertyCluster,
  GraphqlPropertyMarker,
} from '@app/queries/properties/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { getGroupByFromState, resolveFilters } from '../../groupbyv2';
import { IPageState, IStream } from '../../types';
import { usePageState } from '../../utils';
import styles from './ReefMapV2.emotion';
import { getTileSize, TileConfig } from './tiling';
import { useMapParams } from './useMapParams';
import usePropertyMarkers from './usePropertyMarkers';
import {
  clusterizeVisibleMarkers,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  getClusterZoom,
  getMarkers,
} from './utils';

const normalizeDegrees = (v) => v; //(v < 0 ? 360 + (v % 360) : v % 360);

interface MapProps {
  group: IGroupedProps;
  defaultMapPosition: MapPosition;
  streamSlug: string;
}
const Map: React.FC<MapProps> = ({ group, defaultMapPosition, streamSlug }) => {
  const location = useLocation();
  const { sizeBy } = useMapParams();
  const [map, setMap] = useState<IMap | null>(null);
  const [mapPosition, setMapPosition] = useState<MapPosition>(defaultMapPosition);
  const [tileConfig, setTileConfig] = useState<TileConfig>();
  useEffect(() => {
    if (!!map) {
      const roi = {
        northEast: {
          latitude: map?.getBounds()?.getNorthEast()?.lat(),
          longitude: normalizeDegrees(map?.getBounds()?.getNorthEast()?.lng()),
        },
        southWest: {
          latitude: map?.getBounds()?.getSouthWest()?.lat(),
          longitude: normalizeDegrees(map?.getBounds()?.getSouthWest()?.lng()),
        },
      };

      const clusterZoom = getClusterZoom(mapPosition.zoom);
      setTileConfig(
        new TileConfig(roi, getTileSize(clusterZoom, group?.filteredPropertyCount), clusterZoom),
      );
    }
  }, [mapPosition, map]);

  const toast = useToast();
  const { loading, markers, clusterRanges } = usePropertyMarkers({
    group,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    tileConfig,
    withLossesOnly: sizeBy === SizeBy.LossCountTotal || sizeBy === SizeBy.TotalGrossLoss,
  });

  const handleMarkerClick = (key: string, gqlMarker: GraphqlPropertyMarker) => {
    if (gqlMarker.__typename !== 'PropertyCluster') {
      return;
    }

    const {
      coordinates: { latitude: lat, longitude: lng },
      expansionZoom,
    } = gqlMarker as GraphqlPropertyCluster;
    let newZoom = getClusterZoom(expansionZoom);
    if (newZoom === getClusterZoom(mapPosition.zoom)) {
      newZoom += 2;
    }

    setMapPosition({
      center: {
        lat,
        lng,
      },
      zoom: !!expansionZoom ? newZoom : mapPosition.zoom + 1,
    });
  };

  const [pageState] = usePageState();

  useEffect(() => {
    if (pageState.groupByV2.length === 0) {
      setMapQueryString(location, null);
    }
  }, [mapPosition, pageState]);

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loadingContainer}>
          <LoadingSpinnerV2 />
        </div>
      )}
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        resetBoundsOnResize
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY,
          libraries: ['places'],
        }}
        onGoogleApiLoaded={({ map: loadedMap }: { map: IMap }) => {
          setMap(loadedMap);
        }}
        defaultCenter={mapPosition.center}
        center={mapPosition.center}
        defaultZoom={mapPosition.zoom}
        zoom={mapPosition.zoom}
        onChange={({ center, zoom }) => setMapPosition({ center, zoom })}
        onChildClick={(key, { marker }) => handleMarkerClick(key, marker as GraphqlPropertyMarker)}
        options={{ mapTypeId: 'hybrid' }}
      >
        {!!map &&
          getMarkers(
            markers,
            // TODO - Fix this
            //clusterizeVisibleMarkers(markers, map.getBounds(), mapPosition.zoom),
            clusterRanges,
            sizeBy,
            streamSlug,
          )}
      </GoogleMapReact>
    </div>
  );
};

interface Props {
  group: IGroupedProps;
  pageState: IPageState;
  indices: Array<string>;
  filters: Array<AttributeFilter>;
  streamSlug?: string;
}

const ReefMapV2: React.FC<Props> = ({ group, pageState, indices, filters, streamSlug }) => {
  const { mapPosition: mapPositionParam } = useMapParams();
  const toast = useToast();
  const [mapPosition, setMapPosition] = useState<MapPosition | null>(mapPositionParam);

  // Little hack to translate groupBy array into a single string. Which is always is...
  const groupBys = getGroupByFromState(pageState.groupByV2);
  const groupBy = groupBys?.length > 0 ? groupBys[0] : null;

  const { loading } = useQuery<GetPropertyMapStatsData, GetPropertyMapStatsVariables>(
    GET_PROPERTY_MAP_STATS,
    {
      onCompleted: ({ propertyMapStats: { centroid } }) => {
        setMapPosition({
          center: !!centroid
            ? {
                lat: centroid.latitude,
                lng: centroid.longitude,
              }
            : DEFAULT_CENTER,
          zoom: DEFAULT_ZOOM,
        });
      },
      onError: (err) => {
        toast({ title: getErrorMessage(err), type: 'danger' });
        setMapPosition({ center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM });
      },
      skip: !!mapPositionParam,
      variables: {
        input: {
          changesSince: pageState.changesSince,
          currentSnapshot: pageState.currentSnapshot,
          filter: resolveFilters(filters, pageState.groupByV2, []),
          groupValue: indices[1],
          groupedBy: !pageState.changesSince ? groupBy : null,

          streamSlug: streamSlug,
        },
      },
    },
  );

  if (loading || !mapPosition) {
    return <LoadingSpinnerV2 />;
  }

  return <Map group={group} defaultMapPosition={mapPosition} streamSlug={streamSlug} />;
};

export default ReefMapV2;
