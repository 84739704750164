import React, { FC, useEffect } from 'react';
import { useEvent } from 'react-use';
import { API_BASE_URL } from '../../config';
import { useAuth } from '../AuthProvider/AuthProvider';
import { IS_LOGGED_IN_LOCAL_STORAGE_KEY } from './constants';
import { useRedirectURL } from './RedirectURL';
import { getInvitedByIfPresent, getInvitedForIfPresent } from './utils';

interface IProps {
  children?: JSX.Element;
  doNotRenderLogin?: boolean;
}

const Auth: FC<IProps> = ({ children }) => {
  const { isAuthenticated, checkSession, isExpired, loading, loginWithRedirect, logout } =
    useAuth();

  const login = () => {
    if (loginWithRedirect) {
      // Grabs stream slugs from URL if present
      const invitedFor = getInvitedForIfPresent();
      // Grabs uCode from URL if present
      const invitedBy = getInvitedByIfPresent();

      loginWithRedirect({
        apiURL: API_BASE_URL,
        invitedBy,
        invitedFor,
        scope: 'email',
      });
    }
  };

  useEffect(() => {
    // if isAuthenticated equals false then trigger login page.
    // if isAuthenticated equals undefined then useAuth it's still being loaded.
    if (!loading) {
      // Store RedirectURI in LocalStorage
      const expired = isExpired?.();
      if (isAuthenticated === false || expired) {
        if (useRedirectURL.isPathAllowed()) {
          useRedirectURL.set(window.location.href);
        }
      }

      if (isAuthenticated === false) {
        login();
      } else if (expired) {
        // silent token refresh
        if (checkSession) {
          checkSession(true);
        }
        // force logout after session expired
        if (logout) {
          if (useRedirectURL) {
            useRedirectURL.set(window.location.href);
          }
          localStorage.setItem(IS_LOGGED_IN_LOCAL_STORAGE_KEY, 'false');
          logout({
            returnTo: window.location.origin,
          });
        }
      }
    }
  }, [loading, isAuthenticated, isExpired?.()]);

  const storageEventHandler = () => {
    const storageValue = localStorage.getItem(IS_LOGGED_IN_LOCAL_STORAGE_KEY);
    const isLoggedIn = storageValue ? JSON.parse(storageValue) : false;
    // skips check on initial render after login or new tab
    if (!loading && !isLoggedIn) {
      login();
    }
  };

  useEvent('storage', storageEventHandler);

  if (isAuthenticated && !isExpired?.()) {
    // Render the requested page
    return children || <div />;
  }
  // Render nothing because either you're not authenticated, or the page is still loading.
  return null;
};

export default Auth;
