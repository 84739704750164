import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { formatEarthquakeOptions } from './formatEartquakeOptions';
import { formatFloodOptions } from './formatFloodOptions';
import { formatWindOptions } from './formatWindOptions';

interface TivColumnChartProps {
  mapData: RiskSummaryChartData;
  activeHazardMode: string;
  activeMapMod: string;
}

export function TivColumnChart({ mapData, activeHazardMode, activeMapMod }: TivColumnChartProps) {
  const columnOptions = {
    columnColor: '#21b59a',
  };

  if (activeHazardMode === 'HAZARD_EXPOSURE_WINDZONE' && activeMapMod === 'REGION_UNITED_STATES') {
    const chartOptions = formatWindOptions(mapData);
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }

  if (activeHazardMode === 'HAZARD_EXPOSURE_FLOODZONE' && activeMapMod === 'REGION_UNITED_STATES') {
    const chartOptions = formatFloodOptions(mapData);
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }

  if (activeHazardMode === 'HAZARD_EXPOSURE_QUAKEZONE') {
    const labelUppsercase = activeMapMod === 'REGION_WORLD';
    // FIX ME
    // @ts-ignore
    const chartOptions = formatEarthquakeOptions(mapData, columnOptions, labelUppsercase);
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }
  // FIX ME
  // @ts-ignore
  const chartOptions = formatEarthquakeOptions(mapData, columnOptions, true);
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default { TivColumnChart };
