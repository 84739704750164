export default {
  addRule: {
    label: 'Add Rule',
    title: 'Add rule',
  },
  combinators: {
    title: 'Combinators',
  },
  fields: {
    title: 'Fields',
  },
  operators: {
    title: 'Operators',
  },
  removeGroup: {
    label: 'X',
    title: 'Remove group',
  },
  removeRule: {
    label: 'X',
    title: 'Remove rule',
  },
  value: {
    title: 'Value',
  },
};
