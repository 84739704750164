import React, { useState } from 'react';
import SnapshotDeleteModal from '@onarchipelago/cx/Stream/EditModal/Tabs/SnapshotsTab/SnapshotDeleteModal';
import { ISnapshot, IStream } from '@onarchipelago/cx/Stream/types';
import { Moment } from 'moment';
import {
  Button,
  ButtonEmpty,
  Checkbox,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
  EuiToolTip,
  TextField,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { SnapshotTypeEnum } from '@app/queries/streams/types';
import { localFormatDate } from '../utils';
import { MarketToolTip } from './MarketToolTip';
import styles from './SnapshotEditModal.emotion';
import useSnapshotEditModal from './useSnapshotEditModal';

interface Props {
  onClose: () => void;
  publishedSnapshotId: string | null;
  snapshot: ISnapshot;
  stream: IStream;
}

const SnapshotEditModal: React.FC<Props> = ({ onClose, snapshot, stream }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { account } = useAuth();
  const [snapshotValue, setSnapshotValue] = useState<ISnapshot>({ ...snapshot });
  const { isSaving, onSave } = useSnapshotEditModal({ onClose, snapshot: snapshotValue, stream });
  const isAdmin = account?.permissions?.admin;

  const handleChange = (id: string, value: Moment | boolean | string | null) => {
    if (id === 'marketVisible' && value === true) {
      setSnapshotValue({
        ...snapshotValue,
        // FIX ME
        // @ts-ignore
        [id]: value,
        visible: true,
      });

      return;
    }
    setSnapshotValue({
      ...snapshotValue,
      [id]: value,
    });
  };

  const isValuationSnapshot = snapshot?.type === SnapshotTypeEnum.VALUATION;

  const handleCloseDeleteModal = async () => {
    setIsDeleteModalOpen(false);
    onClose?.();
  };

  return (
    <>
      <EuiModal onClose={onClose}>
        {isSaving && (
          <div className={styles.loadingContainer}>
            <LoadingSpinnerV2 />
          </div>
        )}
        <EuiModalHeader data-testid="stream-edit-modal-snapshot-edit-modal">
          <EuiText>
            <h3>{snapshot.name}</h3>
          </EuiText>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content={isValuationSnapshot ? 'Valuation snapshots cannot be renamed' : null}
                >
                  <EuiFormRow label="Name">
                    <TextField
                      fullWidth
                      disabled={isValuationSnapshot}
                      onChange={(event) => handleChange('name', event.target.value)}
                      value={snapshotValue.name}
                    />
                  </EuiFormRow>
                </EuiToolTip>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFormRow label="Description">
                  <TextField
                    fullWidth
                    onChange={(event) => handleChange('description', event.target.value)}
                    value={snapshotValue.description}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFormRow label="Display Date">
                  <EuiDatePicker
                    fullWidth
                    onChange={(value) => handleChange('displayDate', value)}
                    value={localFormatDate(snapshotValue.displayDate || '')}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFormRow label="Binding">
                  <Checkbox
                    id="binding"
                    checked={!!snapshotValue.bound}
                    disabled={snapshot.type === SnapshotTypeEnum.SOV}
                    onChange={(event) => handleChange('bound', event.target.checked)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {isAdmin && (
                  <EuiFormRow label="Visible">
                    <EuiToolTip
                      content={
                        isValuationSnapshot ? 'Valuation snapshots cannot be visible.' : null
                      }
                    >
                      <Checkbox
                        id="visible"
                        checked={!!snapshotValue.visible}
                        // FIX ME
                        // @ts-ignore
                        disabled={isValuationSnapshot || !!snapshotValue.marketVisible}
                        onChange={(event) => handleChange('visible', event.target.checked)}
                      />
                    </EuiToolTip>
                  </EuiFormRow>
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow
                  label={
                    <>
                      Market Visible <MarketToolTip />
                    </>
                  }
                >
                  <Checkbox
                    id="marketVisible"
                    // FIX ME
                    // @ts-ignore
                    checked={!!snapshotValue.marketVisible}
                    disabled={isValuationSnapshot}
                    onChange={(event) => {
                      handleChange('marketVisible', event.target.checked);
                    }}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              {isAdmin && (
                <EuiFlexItem>
                  <EuiFormRow>
                    <ButtonEmpty
                      color="danger"
                      label="Delete Snapshot"
                      size="s"
                      onClick={() => setIsDeleteModalOpen(true)}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <Button label="Cancel" onClick={onClose} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button fill label="Save" onClick={onSave} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
      {isDeleteModalOpen && (
        <SnapshotDeleteModal
          onClose={handleCloseDeleteModal}
          snapshotId={snapshot?.id}
          streamSlug={stream.slug}
        />
      )}
    </>
  );
};

export default SnapshotEditModal;
