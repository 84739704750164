import Highcharts from 'highcharts';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { addPlaceholdersToChartData, addRegionTotals, findDataByGroupLabel } from './utils';

function formatFloodOptions(mapData: RiskSummaryChartData) {
  const groupLabels = ['FEMA-X', 'FEMA-A and FEMA-V'];
  const dataExtract = mapData?.values.slice(0, 10);

  const femaAVData = findDataByGroupLabel(groupLabels[1], dataExtract);
  const femaXData = findDataByGroupLabel(groupLabels[0], dataExtract);

  const data = [...femaAVData, ...femaXData];
  const regionsForPlaceholders = [...new Set(data.map((dataItem) => dataItem.region))];

  // Placeholders are arrays with dummy data to keep the entire dataset
  // length to 20 (10 for each stack).
  //
  // We need 20 items to keep the columns the same size even when there's
  // not enough data coming from the API.
  //
  // So, if we get an array of 3 elements from the API, we add 7 to keep it 10.
  const femaAandVDataWithPlaceholders = regionsForPlaceholders
    .map((region) => addPlaceholdersToChartData(region, groupLabels[1], femaAVData))
    .filter(Boolean);

  const femaXDataWithPlaceholders = regionsForPlaceholders
    .map((region) => addPlaceholdersToChartData(region, groupLabels[0], femaXData))
    .filter(Boolean);

  // Here we will always have an array of 20 items.
  const dataWithPlaceholders = [...femaAandVDataWithPlaceholders, ...femaXDataWithPlaceholders];

  const dataSortedByRegionTotals = dataWithPlaceholders
    .map((dataItem, index, arr) => addRegionTotals(dataItem, arr))
    .sort((a, b) => Number(b.regionTotal) - Number(a.regionTotal));

  const regionsSorted = dataSortedByRegionTotals.map((dataItem) => dataItem.region);
  const regionsNoDupes = [...new Set(regionsSorted)];

  // Format data for column chart
  const series = groupLabels.map((tier) => {
    const seriesData = dataSortedByRegionTotals
      .filter((seriesItem) => seriesItem.tier === tier)
      .map(({ region, name, pctChange, pctOfTotal, value, properties }) => ({
        name,
        pctChange,
        pctOfTotal,
        properties,
        region,
        value,
        y: pctOfTotal,
      }));
    return {
      data: seriesData,
      name: tier,
    };
  });

  // There's a lot of repeated code here, but won't abstract it because the
  // implementation between charts might change and it's going to be difficult
  // to refactor and meet different chart needs.
  //
  // We will keep chart options separate for each chart.
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },

    colors: ['#A3C3E3', '#1D61A2'],

    legend: {
      enabled: false,
    },

    plotOptions: {
      column: {
        maxPointWidth: 40,
        pointWidth: 90,
      },

      series: {
        dataLabels: {},
        stacking: 'normal',
      },
    },
    // Sigh.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series,
    subtitle: {
      align: 'left',
      style: {
        color: '#000',
        marginBottom: 8,
        marginTop: 8,
      },
      text: '<br/><div style="display: inline-block;margin-right: 5px;width: 10px; height: 10px; background-color: #1D61A2;"></div>FEMA-A and FEMA-V <div style="display: inline-block;margin-left: 8px;margin-right: 5px;width: 10px; height: 10px; background-color: #A3C3E3;"></div>FEMA-X<br/><div style="margin-top: 1rem;margin-bottom: 1rem;">% of TIV</div>',
      useHTML: true,
    },

    title: {
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      text: 'Top Regions by Flood Exposure',
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: '#000',
      borderRadius: 4,
      headerFormat: '',
      pointFormat:
        '<span style="font-size:13px;font-weight: bold;color:#fff;">{point.region} {point.name}</span><br>' +
        '<span style="color:#fff;">{point.value} TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctOfTotal}% of TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctChange}% change since prev. bound</span><br/>' +
        '<span style="color:#fff;">{point.properties} properties</span><br/>',
    },

    xAxis: {
      categories: regionsNoDupes,
      labels: {
        formatter() {
          const { value } = this;

          if (typeof value !== 'number') {
            return value.toString();
          }

          return '';
        },
      },
    },

    yAxis: {
      labels: {
        formatter() {
          return `${this.value}%`;
        },
      },
      title: {
        text: '',
      },
    },
  };

  return chartOptions;
}

export { formatFloodOptions };
