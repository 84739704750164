import React, { useEffect, useState } from 'react';
import { ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTitle } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useTaskContext } from './context/TaskContext';
import { Filters } from './Filters';
import { TaskTable } from './TaskTable';
import { EmptyMessage } from './EmptyMessage';

export const RecommendationsV2 = () => {
  const { loading, error, filters, readyTasksCount } = useTaskContext();

  const [showFilters, setShowFilters] = useState(filters.filtersApplied);

  useEffect(() => {
    setShowFilters(filters.filtersApplied);
  }, [filters.filtersApplied]);

  const headerText = 'Recommendations to improve your submission';

  const Container = ({ children }) => (
    <>
      <EuiSpacer size="l" />

      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3 id="recommendations">{headerText}</h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        {children}
      </EuiPanel>
    </>
  );

  if (error) {
    return <Container>something went wrong please try again</Container>;
  }
  if (loading) {
    return (
      <Container>
        <EuiSpacer size="xl" />
        <LoadingSpinnerV2 />
      </Container>
    );
  }

  if (readyTasksCount === 0) {
    return (
      <Container>
        <EmptyMessage />
      </Container>
    );
  }

  return (
    <>
      <EuiSpacer size="l" />
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3 id="recommendations">{headerText}</h3>
            </EuiTitle>
          </EuiFlexItem>

          {filters.filtersApplied && (
            <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
              <ButtonEmpty
                onClick={() => {
                  filters.clearFilters();
                }}
                iconName={'x'}
                color="text"
                data-testid="clear-filters"
                label="Clear Filters"
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
            <ButtonEmpty
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              color="text"
              data-testid="show-filters"
              iconName={'filter'}
              label={`${showFilters ? 'Hide' : 'Show'} filters`}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="s" />

        {showFilters && (
          <>
            <Filters />
            <EuiSpacer size="l" />
          </>
        )}

        <TaskTable />
      </EuiPanel>
      <EuiSpacer size="xl" />
      <EuiSpacer size="xl" />
    </>
  );
};
