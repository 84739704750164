import React from 'react';
import { ButtonIcon, EuiToolTip, useEuiTheme } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { ExpandedRows, HandleExpand, User } from './types';
import { SessionsTitle } from './UserViewsColumns.emotion';

const SessionsHeader = () => {
  const { euiTheme } = useEuiTheme();
  const { colors } = euiTheme;
  return (
    <EuiToolTip
      content={
        <p>
          A session is a period of continuous activity and expires after 30 mins of inactivity. It
          does not correspond to login counts.
        </p>
      }
    >
      <SessionsTitle color={colors.primary}>Sessions</SessionsTitle>
    </EuiToolTip>
  );
};

interface ExpandButtonProps {
  expandedRows: ExpandedRows;
  handleExpand: HandleExpand;
  user: User;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({ expandedRows, handleExpand, user }) => (
  <ButtonIcon
    data-testid="user-views-expand-button"
    iconName={Object.keys(expandedRows).includes(user.id) ? 'chevronUp' : 'chevronDown'}
    onClick={() => handleExpand(user.id)}
  />
);

export default (expandedRows: ExpandedRows, handleExpand: HandleExpand) => [
  { field: 'userName', name: 'Name' },
  { field: 'email', name: 'Email' },
  { field: 'jobTitle', name: 'Job Title' },
  { field: 'archipelagoExtract', name: 'Archipelago Extract Downloaded' },
  { field: 'modellingFiles', name: 'Modelling Files Downloaded' },
  { field: 'otherStreamDocs', name: `Other ${STREAMS_LABEL} Docs Downloaded` },
  { field: 'propertyDocs', name: 'Property Docs Downloaded' },
  { field: 'viewedHighlights', name: 'Viewed Highlights' },
  { field: 'sessions', name: <SessionsHeader /> },
  {
    isExpander: true,
    render: (user: User) => (
      <ExpandButton expandedRows={expandedRows} handleExpand={handleExpand} user={user} />
    ),
  },
];
