import { detect } from 'detect-browser';

export default (): boolean => {
  const browser = detect();

  switch (browser?.name) {
    case 'chrome':
    case 'firefox':
    case 'safari':
    case 'edge-chromium':
      return true;
    default:
      return false;
  }
};
