import { css } from '@emotion/css';

const indicator = (pixelPosition: number) => css`
  left: ${pixelPosition}px;
  position: absolute;
  top: -44px;
`;

export default {
  indicator,
};
