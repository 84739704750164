import gql from 'graphql-tag';
import { PROPERTY_WITH_LINKED_DOCUMENT_FRAGMENT } from '../fragments/PropertyWithLinkedDocuments';

export const PROVENANCE_JOB_DATA_QUERY = gql`
  ${PROPERTY_WITH_LINKED_DOCUMENT_FRAGMENT}

  query ProvenanceJobData($jobID: ID!) {
    provenanceJobData(jobID: $jobID) {
      properties {
        ...propertyWithLinkedDocuments
      }
      sharedDocuments
    }
  }
`;
