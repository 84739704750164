import React from 'react';
import styled from '@emotion/styled';
import { formatNumber, getNumberFormatOptions } from '../../../utils/format';
import { Cell, getEdits, getProvenance, ICell } from './Cell';
import { StreamOptions } from './GridCell';

const Formatted = styled.div`
  label: MeasurementCell;
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

const measurementCell = (unit: string, props: ICell, format: boolean): Cell => {
  let unitToDisplay = unit;
  const id = props.column && (props.column.id as string);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance: getProvenance(props.prop, id),
    title: undefined,
  };

  const value = props.value as number;

  const decimals = props.column.formattingOptions?.decimals;
  const formatted = format
    ? formatNumber(value, undefined, getNumberFormatOptions(decimals))
    : value;

  if (!formatted || formatted === '-') {
    unitToDisplay = '';
  }

  const body = (
    <Formatted>
      <span data-testid="measurement-cell">
        {formatted}
        {unitToDisplay}
      </span>
    </Formatted>
  );
  return { body, tooltip };
};

export const MeasurementAreaCell = (
  props: ICell,
  format: boolean = true,
  streamOptions?: StreamOptions,
): Cell => {
  let unit = 'ft²';
  if (streamOptions?.unitOfMeasurements === 'metric') {
    unit = 'm²';
  }
  return measurementCell(unit, props, format);
};

export const MeasurementDistanceSmall = (
  props: ICell,
  format: boolean = true,
  streamOptions?: StreamOptions,
): Cell => {
  let unit = 'ft';
  if (streamOptions?.unitOfMeasurements === 'metric') {
    unit = 'm';
  }
  return measurementCell(unit, props, format);
};

export const MeasurementDistanceLarge = (
  props: ICell,
  format: boolean = true,
  streamOptions?: StreamOptions,
): Cell => {
  let unit = 'mi';
  if (streamOptions?.unitOfMeasurements === 'metric') {
    unit = 'km';
  }
  return measurementCell(unit, props, format);
};
