import { useEffect, useRef, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { IJob, JobErrors } from '@onarchipelago/dice/EnrichmentProjects/types';
import moment from 'moment';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
// FIX ME
// @ts-ignore
import CREATE_JOB from '@app/queries/organizations/createJob.gql';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { SUBMIT_JOB } from '@app/queries/organizations/submitJob';
import {
  EJobStatus,
  EJobType,
  ICreateJobData,
  ICreateJobVariables,
  IGetJobData,
  IGetJobVariables,
  ISubmitJobData,
  ISubmitJobVariables,
} from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { checkCurrentTransition } from './utils';

interface Props {
  orgName: string;
  propertyIDs: Array<string>;
  streamSlug: string;
  defaultJob?: IJob;
  onSuccess: (job: IJob) => void;
  onAPIError: (err: ApolloError) => void;
  onJobError: (errors: JobErrors) => void;
  onTimeout: () => void;
}

interface Return {
  loading: boolean;
  start: () => void;
}

const RETRY_LIMIT = 10;
export default ({
  orgName,
  propertyIDs,
  streamSlug,
  onSuccess,
  onAPIError,
  onJobError,
  onTimeout,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobID, setJobID] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;
  const [retryCount, setRetryCount] = useState<number>(0);
  const jobsApolloClient = useJobsApolloClient();

  const retry = async (callback: Function) => {
    if (retryCount + 1 >= RETRY_LIMIT) {
      setIsLoading(false);
      onTimeout();
      return;
    }

    const retryTime = 3000;
    timerRef.current = setTimeout(() => callback(), retryTime);

    setRetryCount(retryCount + 1);
  };

  const [getStreamQuery] = useLazyQuery(GET_STREAM, {
    variables: {
      isAdmin,
      slug: streamSlug,
      userCode: account?.userCode || '',
    },
  });

  const [getJobQuery] = useLazyQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    client: jobsApolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: async ({ job }) => {
      checkCurrentTransition({
        job,
        onCompleted: (currentTransition) => {
          setRetryCount(0);

          if (currentTransition.to === EJobStatus.Complete) {
            setIsLoading(false);
            onSuccess(job);
          }
        },
        onFailed: (currentTransition) => {
          setIsLoading(false);
          setRetryCount(0);
          onJobError({
            dataErrors: currentTransition.dataErrors,
            systemErrors: currentTransition.systemErrors,
          });
        },
        onStarted: () =>
          retry(() => {
            getJobQuery();
          }),
      });
    },
    variables: { jobID },
  });

  const [submitJobMutation] = useMutation<ISubmitJobData, ISubmitJobVariables>(SUBMIT_JOB, {
    client: jobsApolloClient,
    onCompleted: () => {
      clearTimeout(timerRef.current);
      getJobQuery();
      getStreamQuery();
    },
    onError: () => {
      retry(submitJobMutation);
    },
    variables: {
      input: {
        jobID,
      },
    },
  });

  // ----- CREATE JOB MUTATION ----- //

  const [createJobMutation] = useMutation<ICreateJobData, ICreateJobVariables>(CREATE_JOB, {
    client: jobsApolloClient,
    onCompleted: (data) => {
      setJobID(data.createJob.id);
      submitJobMutation();
    },
    onError: (error) => {
      setIsLoading(false);
      onAPIError(error);
    },
  });

  useEffect(() => () => clearTimeout(timerRef.current), []);
  const start = () => {
    setIsLoading(true);
    createJobMutation({
      variables: {
        input: {
          doerUserID: account?.userId,
          expireInHours: 1,
          name: `Dispose Properties - ${moment().format('YYYY-MM-DD')}`,
          orgName,
          propertyIDs,
          reviewerUserID: account?.userId,
          type: EJobType.DisposeProperties,
        },
      },
    });
  };

  return {
    loading: isLoading,
    start,
  };
};
