import { SelectedRows } from '@app/contexts/SelectionContext/SelectionContext';
import { StreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import { Property, PropertyDiscussions } from '@app/graphql/types';
import { TrackGroupSovManager } from '@app/hooks/useTracker';
import { IGraphQLStream } from '@app/queries/streams/types';
import { ARCHIPELAGO_ZONE_ID } from '../PropertiesGrid/GridCell/HazardText';
import { IEffectiveMetaDataValue } from './types';

export const getDiscussionsGridMap = (discussions: Array<PropertyDiscussions>) => {
  const discussionsMap = {};
  discussions?.forEach(({ property: { archipelagoID }, attributeDiscussions }) => {
    attributeDiscussions?.forEach(({ attributeName }) => {
      discussionsMap[archipelagoID] = {
        ...discussionsMap[archipelagoID],
        [attributeName]: true,
      };
    });
  });

  return discussionsMap;
};

export const getTextWidth = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = '14px sans-serif';

  if (text.match(/(city|image|state)/i)) {return context.measureText(text).width * 5;}
  return context.measureText(text).width * 1.75;
};

export const getAdjustedRowIndex = (
  rowIndex: number,
  pagination: { pageIndex: number; pageSize: number },
) => rowIndex - pagination.pageIndex * pagination.pageSize;

export const defaultColumns = new Set([
  'locationId',
  'archipelagoId',
  'enriched',
  'locationName',
  'originalAddress',
  'streetAddress',
  'city',
  'state',
  'postalCode',
  'county',
  'country',
  'geoCodeType',
  'latitude',
  'longitude',
  'hazardSummary',
  'seismicZone',
  'windZone',
  'floodZone',
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_FLOOD_ZONE,
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_SEISMIC_ZONE,
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_WIND_ZONE,
  'totalInsuredValue',
  'totalInsuredValueDisplay',
  'buildingReplacementCost',
  'buildingReplacementCostDisplay',
  'buildingValue',
  'buildingValueDisplay',
  'improvementsValue',
  'improvementsValueDisplay',
  'contentsReplacementCost',
  'contentsReplacementCostDisplay',
  'otherContentsValue',
  'otherContentsValueDisplay',
  'electronicsValue',
  'electronicsValueDisplay',
  'fineArtJewelryValue',
  'fineArtJewelryValueDisplay',
  'inventoryValue',
  'inventoryValueDisplay',
  'machineryValue',
  'machineryValueDisplay',
  'miscContentsValue',
  'miscContentsValueDisplay',
  'stockThroughputFactor',
  'stockThroughputFactorDisplay',
  'stockThroughputInventoryValue',
  'stockThroughputInventoryValueDisplay',
  'stockThroughputExcessInventoryValue',
  'stockThroughputExcessInventoryValueDisplay',
  'businessInterruptionCost',
  'businessInterruptionCostDisplay',
  'businessIncomeValue',
  'businessIncomeValueDisplay',
  'extraExpenseValue',
  'extraExpenseValueDisplay',
  'payrollValue',
  'payrollValueDisplay',
  'miscBusinessInterruptionValue',
  'miscBusinessInterruptionValueDisplay',
  'contingentBusinessInterruptionFlag',
  'contingentBusinessInterruptionFlagDisplay',
  'contingentBusinessInterruptionComments',
  'contingentBusinessInterruptionCommentsDisplay',
  'annualBaseRent',
  'annualBaseRentDisplay',
  'annualEstimatedRent',
  'annualEstimatedRentDisplay',
  'annualServiceCharge',
  'annualServiceChargeDisplay',
  'annualGrossProfit',
  'annualGrossProfitDisplay',
  'annualLossOfRevenue',
  'annualLossOfRevenueDisplay',
  'rentAndServiceIndemnityPeriod',
  'replacementCostPerSquareFootage',
  'replacementCostPerSquareFootageDisplay',
  'buildingReplacementCostPartner',
  'buildingReplacementCostPartnerDisplay',
  'buildingReplacementCostValueDifference',
  'buildingReplacementCostValueDifferenceDisplay',
  'buildingReplacementCostPercentDifference',
  'buildingReplacementCostPercentDifferenceDisplay',
  'buildingReplacementCostPercentDifferenceRange',
  'buildingReplacementCostPercentDifferenceRangeDisplay',
  'replacementCostPerFloorAreaPartner',
  'replacementCostPerFloorAreaPartnerDisplay',
  'replacementCostPerFloorAreaPartnerDifferenceDisplay',
  'replacementCostMethodology',
  'replacementCostMethodologyDisplay',
  'dependencyFlag',
  'dependencyFlagDisplay',
  'dependencyCoveragePercentage',
  'dependencyCoveragePercentageDisplay',
  'dependencyValue',
  'dependencyValueDisplay',
  'dependencyComments',
  'dependencyCommentsDisplay',
  'yearBuilt',
  'floorArea',
  'numberOfStoriesAboveGround',
  'numberOfStoriesBelowGround',
  'numberOfBuildings',
  'constructionType',
  'constructionTypeCluster',
  'constructionDescription',
  'constructionQuality',
  'occupancyType',
  'specificOccupancy',
  'occupancyTypeATC',
  'numberOfUnits',
  'leasedOrOwned',
  'firstFloorHeight',
  'distanceToNearestBuilding',
  'roofSystem',
  'roofDescription',
  'roofInstallYear',
  'wallCladdingSystem',
  'buildingSprinklered',
  'fireProtectionDescription',
  'securityDescription',
  'buildingSprinklerType',
  'permanentFloodMeasuresPresent',
  'firstFloorElevation',
  'emergencyFloodProtectionMeasures',
  'inspections',
  'lossEngineeringReportPresent',
  'lastEngineeringVisitDate',
  'numberOfHighPriorityRecommendations',
  'roofInspectionProgram',
  'fireProtectionInspectionProgram',
  'generatorTestingProgram',
  'hasSeismicInspectionReport',
  'seismicInspectionDate',
]);

export const checkIsOwnerAttribute = (columnId: string) => columnId.startsWith('ownerText') || columnId.startsWith('ownerDecimal');

export const refreshSelectedRows = (
  selectedRows: SelectedRows,
  data: StreamPropertiesPageQuery,
) => {
  const properties = data?.streamPropertiesPage?.properties || [];
  Object.values(selectedRows).forEach((row) => {
    const matchingProperty = properties.find(
      (property) => property.archipelagoId === row?.archipelagoId,
    );
    if (matchingProperty) {
      selectedRows[row.id] = matchingProperty as Property;
    }
  });
};

export const getSortColumnsByVisibleColumnOrder = (columnOrder = []) => (columnA: IEffectiveMetaDataValue, columnB: IEffectiveMetaDataValue) => {
    if (!columnOrder?.length) {
      return 0;
    } 
      let columnAIndex = columnOrder?.findIndex((columnName: string) => columnName === columnA.id);
      columnAIndex = columnAIndex === -1 ? Number.MAX_VALUE : columnAIndex;

      let columnBIndex = columnOrder?.findIndex((columnName: string) => columnName === columnB.id);
      columnBIndex = columnBIndex === -1 ? Number.MAX_VALUE : columnBIndex;
      return columnAIndex - columnBIndex;
    
  };

export const sendMixpanelEvent = (
  mixpanel: any,
  messageSuffix: string,
  stream: IGraphQLStream,
  extraAttributes: any = {},
) => {
  mixpanel.track(`${TrackGroupSovManager.prefix}: ${messageSuffix}`, {
    event_surface: TrackGroupSovManager.event_surface,
    organization_name: stream?.orgName,
    stream_name: stream?.name,
    stream_slug: stream?.slug,
    ...extraAttributes,
  });
};

const swapColumns = (array: string[], currIndex: number, newIndex: number) => {
  [array[currIndex], array[newIndex]] = [array[newIndex], array[currIndex]];
};

export const getMoveOnClick = (
  direction: 'left' | 'right',
  columnName: string,
  stream: IGraphQLStream,
  mixpanel: any,
  visibleColumns: string[],
  setVisibleColumns: any,
) => () => {
    const currIndex = visibleColumns.indexOf(columnName);
    const newIndex = direction === 'left' ? currIndex - 1 : currIndex + 1;

    sendMixpanelEvent(mixpanel, `Column Action - Move column ${direction}`, stream, {
      attribute: columnName,
      current_index: currIndex,
      new_index: newIndex,
    });

    setVisibleColumns((prev: string[]) => {
      const next = [...prev];
      swapColumns(next, currIndex, newIndex);
      return next;
    });
  };
