import React, { useEffect, useState } from 'react';
import { SearchSelect, SearchSelectOption } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { useCopilotContext } from '../../context/context';

interface Props {
  value: string;
  handleChange: (v: any) => void;
}
export const PropertySelect: React.FC<Props> = ({ handleChange, value }) => {
  const eventTracker = useTracker('api');
  const { propertiesQuery } = useCopilotContext();
  const { selectedOrganization } = useUserSession();
  const defaultOptions = propertiesQuery?.items?.map((property) => ({
    label: property.locationName || property.locationId || property.archipelagoId,
    value: property.archipelagoId,
  }));

  const [options, setOptions] = useState<Array<SearchSelectOption>>(defaultOptions);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [JSON.stringify(defaultOptions)]);

  return (
    <SearchSelect
      placeholder="Search property"
      value={value}
      options={options || []}
      onChange={(e) => {
        handleChange(e);
        if (!!e) {
          eventTracker.track('Copilot: Property selected', {
            archipelagoID: e,
            event_surface: 'Copilot',
            orgID: selectedOrganization?.id,
            orgName: selectedOrganization?.name,
          });
        }
      }}
      onCreateOption={(o) => {
        eventTracker.track('Copilot: Property override', {
          archipelagoID: o,
          event_surface: 'Copilot',
          orgID: selectedOrganization?.id,
          orgName: selectedOrganization?.name,
        });
        setOptions([...options, { label: o, value: o }]);
        handleChange(o);
      }}
    />
  );
};
