import { useState } from 'react';

type CBase = (args?: any) => Promise<void>;

export default <C extends CBase>(callback: C, initialState: boolean = false): [C, boolean] => {
  const [flag, setFlag] = useState(initialState);

  type Args = Parameters<C>;

  const callbackWithToggle = async (...args: Args) => {
    setFlag((prev) => !prev);
    await callback(...args);
    setFlag((prev) => !prev);
  };

  return [callbackWithToggle as C, flag];
};
