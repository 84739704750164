import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  path: string;
}

const Redirect: React.FC<Props> = ({ path }) => {
  const history = useHistory();
  history.push(path);

  return null;
};

export default Redirect;
