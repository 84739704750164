import gql from 'graphql-tag';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';

export { GET_ORGANIZATION_ATTRIBUTE_METADATA };
export const GET_EXPORT_CONFIGURATIONS = gql`
  query ExportConfigurations($input: ExportConfigurationsInput!) {
    exportConfigurations(input: $input) {
      id
      streamID
      organizationID
      configurationSlug
      name
      configuration {
        attributeName
        position
      }
    }
  }
`;

export const GET_PROPERTY_ATTRIBUTE_METADATA_V2 = gql`
  query GetPropertyAttributeMetadataV2($input: PropertyAttributeMetadataInput!) {
    propertyAttributeMetadataV2(input: $input) {
      name
      displayName
      displayWidth
      parent
      filterable
      groupable
      dataType
      source
      maxThreshold
      decimals
      derived
      scope
      hiddenInGrid
    }
  }
`;
