import React, { useContext } from 'react';
import StreamEditContext from '@onarchipelago/cx/Stream/EditModal/StreamEditContext';
import get from 'lodash/get';
import moment from 'moment';
import { EuiDatePicker, EuiFlexItem, EuiFormRow, Select, TextField } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { MODE } from '@app/cx/Stream/Distillers/types';
import { TabDefinitions } from '@app/cx/Stream/EditModal/Tabs/Tabs';
import { localFormatDate } from '../utils';
import useBasicInfoTab from './useBasicInfoTab';

const BasicInfoTab: React.FC = () => {
  const { copyOfStream, setFieldValue, touch } = useContext(StreamEditContext);
  const { businessOptions, handleIndustryChange, industryOptions, loading } = useBasicInfoTab();

  if (!copyOfStream || !setFieldValue || !touch || loading) {
    return null;
  }

  const formRowRequiredProps = (value: string) =>
    value === ''
      ? {
          error: ['This field is required'],
          isInvalid: true,
        }
      : {};

  return (
    <>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-name">
        <EuiFormRow
          {...formRowRequiredProps(get(copyOfStream, 'name'))}
          fullWidth
          label="Enter Name"
        >
          <TextField
            disabled={copyOfStream?.isMyProperties}
            fullWidth
            value={get(copyOfStream, 'name') || ''}
            onChange={(event) => setFieldValue('name', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-broker">
        <EuiFormRow
          {...formRowRequiredProps(get(copyOfStream, 'broker'))}
          fullWidth
          label="Enter Broker"
        >
          <TextField
            fullWidth
            value={get(copyOfStream, 'broker') || ''}
            onChange={(event) => setFieldValue('broker', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-reit">
        <EuiFormRow
          {...formRowRequiredProps(get(copyOfStream, 'reit'))}
          fullWidth
          label="Enter REIT"
        >
          <TextField
            fullWidth
            value={get(copyOfStream, 'reit') || ''}
            onChange={(event) => setFieldValue('reit', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-status">
        <EuiFormRow
          {...formRowRequiredProps(get(copyOfStream, 'status'))}
          fullWidth
          label="Enter Status"
        >
          <TextField
            fullWidth
            value={get(copyOfStream, 'status') || ''}
            onChange={(event) => setFieldValue('status', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-effective">
        <EuiFormRow fullWidth label="Effective Date">
          <EuiDatePicker
            fullWidth
            onChange={(date) => {
              // convert to base utc time from timezone time
              const utcDate = moment.utc(date.format('LL'));
              const newExpiryDate = moment.utc(date.format('LL')).add(1, 'year');
              setFieldValue('effectiveDate', utcDate, 'expiryDate', newExpiryDate);
            }}
            value={localFormatDate(get(copyOfStream, 'effectiveDate'))}
            selected={moment(localFormatDate(get(copyOfStream, 'effectiveDate')))}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-expiry">
        <EuiFormRow fullWidth label="Expiry Date">
          <EuiDatePicker
            fullWidth
            onChange={(date) => {
              // convert to base utc time from timezone time
              const utcDate = moment.utc(date.format('LL'));
              setFieldValue('expiryDate', utcDate);
            }}
            value={localFormatDate(get(copyOfStream, 'expiryDate'))}
            selected={moment(localFormatDate(get(copyOfStream, 'expiryDate')))}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-last-update">
        <EuiFormRow fullWidth label="Last Update Note">
          <TextField
            fullWidth
            value={get(copyOfStream, 'configuration.lastUpdateNote') || ''}
            onChange={(event) => setFieldValue('configuration.lastUpdateNote', event.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-industry">
        <EuiFormRow fullWidth label="Industry">
          <Select
            fullWidth
            onClear={() => handleIndustryChange(null)}
            onChange={(value) => handleIndustryChange(value)}
            options={industryOptions}
            value={copyOfStream?.industry}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-business-type">
        <EuiFormRow fullWidth label="Business Type">
          <Select
            fullWidth
            options={businessOptions}
            onChange={(value) => setFieldValue('businessType', value)}
            onClear={() => setFieldValue('businessType', null)}
            value={copyOfStream?.businessType}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-default-view">
        <EuiFormRow fullWidth label={`Default ${STREAMS_LABEL} view`}>
          <Select
            fullWidth
            options={Object.entries(MODE).map(([_, tab]) => ({
              label: tab,
              value: tab,
            }))}
            onChange={(value) => setFieldValue('configuration.defaultStreamView', value)}
            onClear={() => setFieldValue('configuration.defaultStreamView', null)}
            value={copyOfStream?.configuration?.defaultStreamView}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </>
  );
};

export default BasicInfoTab;
