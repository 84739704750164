import { datadogLogs } from '@datadog/browser-logs';
import { IS_DEV_ENV } from '@app/config';
import useWindowDimensions from './useWindowDimensions';

type Log = (message: string, messageContext: object) => void;

interface Return {
  info: Log;
  error: Log;
}

const getViewportContext = () => {
  const { width, height } = useWindowDimensions();

  return { viewport: { height, width } };
};

const datadogLogger = (): Return => {
  const viewportContext = getViewportContext();
  return {
    error: (message, messageContext) =>
      datadogLogs.logger.error(message, { ...messageContext, ...viewportContext }),
    info: (message, messageContext) =>
      datadogLogs.logger.info(message, { ...messageContext, ...viewportContext }),
  };
};

const mockLogger = (): Return => {
  const viewportContext = getViewportContext();
  const datadogAccountContext = datadogLogs.getLoggerGlobalContext();
  return {
    error: (message, messageContext) =>
      console.error('.mockLogger: ' + message, {
        ...messageContext,
        ...viewportContext,
        ...datadogAccountContext,
      }),
    info: (message, messageContext) =>
      console.info('.mockLogger: ' + message, {
        ...messageContext,
        ...viewportContext,
        ...datadogAccountContext,
      }),
  };
};

interface Props {
  mock: boolean;
}
export default (props?: Props): Return =>
  props?.mock || IS_DEV_ENV ? mockLogger() : datadogLogger();
