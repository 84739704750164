export const sortOrder = {
  avg_build_year: 8,
  avg_sq_ft: 5,
  avg_value: 4,
  count_of_losses: 2,
  gross_total_loss: 1,
  loss_cost_over_5_yrs: 3,
  percentage_by_construction: 7,
  percentage_by_occupancy: 6,
  total_properties: 10,
  total_tiv_by_group: 11,
  total_tiv_over_5_yrs: 9,
};
