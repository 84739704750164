import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Flyout from '@app/components/Flyout/Flyout';
import Layout from '@app/components/Layout/Layout';
import Modal from '@app/components/Modal/ModalElastic';
import { IRenderer } from '@app/containers/App/Routes/types';
import { UploadsProvider } from '@app/contexts/UploadsContext';
import getRouteChildren from './getRouteChildren';

interface Props extends IRenderer {
  portal: boolean;
  props: RouteComponentProps;
}

export default ({ component, portal, props, render }: Props) => {
  const children = getRouteChildren({ component, props, render });

  return (
    <UploadsProvider>
      <Modal>
        <Flyout>{portal ? children : <Layout>{children}</Layout>}</Flyout>
      </Modal>
    </UploadsProvider>
  );
};
