import gql from 'graphql-tag';

export const CREATE_EXPORT_CONFIGURATION = gql`
  mutation CreateExportConfiguration($input: CreateExportConfigurationInput!) {
    createExportConfiguration(input: $input) {
      id
      organizationID
      configurationSlug
      streamID
    }
  }
`;

export const UPDATE_EXPORT_CONFIGURATION = gql`
  mutation UpdateExportConfiguration($input: UpdateExportConfigurationInput!) {
    updateExportConfiguration(input: $input) {
      id
      organizationID
      configurationSlug
      streamID
    }
  }
`;
