import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyAddPropertyJobMutationVariables = Types.Exact<{
  input: Types.ApplyAddPropertyJobInput;
}>;


export type ApplyAddPropertyJobMutation = { __typename?: 'Mutation', applyAddPropertyJob: { __typename?: 'Job', id: string, name: string, status: Types.JobStatus, propertyIDs?: Array<any> | null, propertyLockGroupID?: string | null, attributeNames?: Array<string> | null } };


export const ApplyAddPropertyJobDocument = gql`
    mutation ApplyAddPropertyJob($input: ApplyAddPropertyJobInput!) {
  applyAddPropertyJob(input: $input) {
    id
    name
    status
    propertyIDs
    propertyLockGroupID
    attributeNames
  }
}
    `;
export type ApplyAddPropertyJobMutationFn = Apollo.MutationFunction<ApplyAddPropertyJobMutation, ApplyAddPropertyJobMutationVariables>;

/**
 * __useApplyAddPropertyJobMutation__
 *
 * To run a mutation, you first call `useApplyAddPropertyJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyAddPropertyJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyAddPropertyJobMutation, { data, loading, error }] = useApplyAddPropertyJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyAddPropertyJobMutation(baseOptions?: Apollo.MutationHookOptions<ApplyAddPropertyJobMutation, ApplyAddPropertyJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyAddPropertyJobMutation, ApplyAddPropertyJobMutationVariables>(ApplyAddPropertyJobDocument, options);
      }
export type ApplyAddPropertyJobMutationHookResult = ReturnType<typeof useApplyAddPropertyJobMutation>;
export type ApplyAddPropertyJobMutationResult = Apollo.MutationResult<ApplyAddPropertyJobMutation>;
export type ApplyAddPropertyJobMutationOptions = Apollo.BaseMutationOptions<ApplyAddPropertyJobMutation, ApplyAddPropertyJobMutationVariables>;