import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useValidateMpDataLazyQuery } from '@app/graphql/jobs/queries/__generated__/validateMPData.generated';

export const useValidateMPData = () => {
  const jobsApolloClient = useJobsApolloClient();
  const { selectedOrganization } = useUserSession();

  const [validateMPData, { data, loading: isValidating }] = useValidateMpDataLazyQuery({
    client: jobsApolloClient,
  });

  const warnings = data?.validateMPData?.warnings;
  const clientIdWarning = warnings?.find((warning) => warning.type === 'DuplicateClientID');

  const debounceValidateMPData = useCallback(
    debounce((clientId: string) => {
      if (clientId) {
        validateMPData({
          variables: { input: { locationId: clientId, orgName: selectedOrganization?.name } },
        });
      }
    }, 500),
    [],
  );

  return { clientIdWarning, debounceValidateMPData, isValidating, warnings };
};
