import gql from 'graphql-tag';
import { Pillar } from '@app/queries/streams/types';
import { FRAGMENT_GRADE } from './fragments';

export const GET_CARRIER_ASSESSMENT = gql`
  ${FRAGMENT_GRADE}
  fragment propertyAssessmentMetric on PropertyAssessmentMetric {
    grade {
      ...grade
    }
    assessmentCnt
    pctOfAssessments
  }

  fragment propertyGradeMetric on PropertyGradeMetric {
    grade {
      ...grade
    }
    propertyCnt
    pctOfGradedProperties
    pctOfProperties
    tiv
    pctOfTIV
    pctOfGradedTIV
    pctOfGradedTIVPd
    tivPd
  }

  query carrierAssessment($input: CarrierAssessmentInput!) {
    carrierAssessment(input: $input) {
      carrierId
      name
      streamId
      carrierLogo
      headerText
      gradeSchemes {
        grades {
          ...grade
        }
      }
      pillars {
        attribute
        grade {
          ...grade
        }
        description
        factorDescription
        components {
          attribute
          grade {
            ...grade
          }
          description
          propertyGradeMetrics {
            ...propertyGradeMetric
          }
          propertyAssessmentMetrics {
            ...propertyAssessmentMetric
          }
        }
      }
      propertyGradeDetails {
        attribute

        overallGrade {
          ...grade
        }
        totalTIV
        totalGradedTIV
        totalPropertyCnt
        totalGradedPropertyCnt
        totalGradedTIVPd

        summaryMetrics {
          ...propertyGradeMetric
        }
      }
      propertyAssessmentDetails {
        attribute
        overallMetric {
          ...propertyAssessmentMetric
        }
        summaryMetrics {
          ...propertyAssessmentMetric
        }
      }
      lossSummaryMetrics {
        customerName
        policyId
        section
        displayName
        unit
        value
        period
      }

      propertyLossDetails {
        attribute
        overallGrade {
          ...grade
        }
      }

      lossYears {
        uwYear
        claimCnt
        totalPaidIndemnity
        avgPaidIndemnity

        avgPaidIndemnityBenchmark
        lossFrequencyBenchmark
        lossFrequencyBenchmarkDescription
        avgPaidIndemnityBenchmarkRatio
        avgPaidIndemnityGrade {
          ...grade
        }
        lossFrequency
        lossFrequencyBenchmarkRatio
        lossFrequencyGrade {
          ...grade
        }
      }
      lossFrequencyHistogram {
        period
        buckets {
          category
          cnt
          pctOfTotal
        }
      }
      lossFrequencyGeography {
        states {
          name
          lossFrequency
          riaCnt
        }
      }
    }
  }
`;

export interface GetCarrierAssessmentVariables {
  input: {
    name: string;
    streamId: string;
  };
}

export interface CarrierLossYear {
  uwYear: number;
  avgPaidIndemnityBenchmark: number;
  lossFrequencyBenchmark: number;
  lossFrequencyBenchmarkDescription: string;
  claimCnt: number;
  totalPaidIndemnity: number;
  avgPaidIndemnity: number;
  avgPaidIndemnityBenchmarkRatio: number;
  avgPaidIndemnityGrade: Grade;
  lossFrequency: number;
  lossFrequencyBenchmarkRatio: number;
  lossFrequencyGrade: Grade;
}

export interface LossRegion {
  code: string;
  name: string;
  lossFrequency: number;
  riaCnt: number;
}
interface LossGeography {
  states: Array<LossRegion>;
}

export interface CarrierAssessment {
  carrierId: string;
  name: string;
  streamId: string;
  carrierLogo: string;
  headerText: string;
  // gradeSchemes: [GradeScheme!]
  pillars: Array<Pillar>;
  propertyGradeDetails: Array<PropertyGradeDetail>;
  propertyAssessmentDetails: Array<PropertyAssessmentDetail>;
  propertyLossDetails: Array<PropertyLossDetail>;
  lossSummaryMetrics: Array<LossMetric>;
  lossYears: Array<CarrierLossYear>;
  lossFrequencyHistogram: LossFrequencyHistogram;
  lossFrequencyGeography: LossGeography;
}

export interface LossFrequencyHistogram {
  period: string;
  buckets: Bucket[];
  __typename: string;
}

export interface Bucket {
  category: string;
  cnt: number;
  pctOfTotal: number;
  __typename: string;
}

export interface GetCarrierAssessmentData {
  carrierAssessment: CarrierAssessment;
}

export interface Grade {
  category: string;
  color: string;
  value: number;
  code: string;
}
interface CarrierMetric {
  __typename: string;
  grade: Grade;
}

export interface PropertyGradeMetric extends CarrierMetric {
  pctOfGradedProperties: number;
  propertyCnt: number;
  pctOfProperties: number;
  tiv: number;
  tivPd: number;
  pctOfTIV: number;
  pctOfGradedTIV: number;
  pctOfGradedTIVPd: number;
}

export interface PropertyAssessmentMetric extends CarrierMetric {
  assessmentCnt: number;
  pctOfAssessments: number;
}

export interface PropertyDetail {
  attribute: string;
}

export interface PropertyGradeDetail extends PropertyDetail {
  overallGrade: Grade;
  totalTIV: number;
  totalGradedTIV: number;
  totalGradedTIVPd: number;
  totalPropertyCnt: number;
  totalGradedPropertyCnt: number;
  summaryMetrics: Array<PropertyGradeMetric>;
}

export interface PropertyAssessmentDetail extends PropertyDetail {
  overallMetric: PropertyAssessmentMetric;
  summaryMetrics: Array<PropertyAssessmentMetric>;
}

export interface PropertyLossDetail extends PropertyDetail {
  overallGrade: Grade;
}

export interface LossMetric {
  customerName: string;
  policyId: string;
  section: string;
  displayName: string;
  unit: string;
  value: number;
  period: string;
}
