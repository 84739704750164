export interface IUsageData {
  usage: {
    limit: number;
    usage: number;
    enabled: boolean;
  };
}

export interface IUsageVariables {
  input: {
    organizationID: string;
    partnerName: string;
  };
}

export interface IEnableIntegrationVariables {
  input: {
    organizationID: string;
    partnerName: string;
    enableIntegration: boolean;
    limit: number;
  };
}

export interface IEnableIntegrationData {
  enableIntegration: boolean;
}
export interface IIntegrationSummaryData {
  integrationSummary: Array<Run>;
}

export interface IIntegrationSummaryVariables {
  input: {
    organizationID: string;
    streamID: string;
    partnerName: string;
  };
}

export interface Run {
  successfulProperties: number;
  skippedProperties: number;
  erroredProperties: number;
  validationFailedProperties: number;
  propertyCount: number;
  userEmail: string;
  status: string;
  runDate: string;
  remainingLimit: number;
}

export enum ScopeType {
  AllProperties = 'ALL_PROPERTIES',
  SelectedProperties = 'SELECTED_PROPERTIES',
  ErroredProperties = 'ERRORED_PROPERTIES',
}

export interface IStartIntegrationVariables {
  input: {
    organizationID: string;
    streamID: string;
    partnerName: string;
    scope: ScopeType;
    properties: Array<string>;
  };
}

export interface IStartIntegrationData {
  startIntegration: {
    status: string;
  };
}
