import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiStat, EuiText, EuiTextColor } from 'ui';
import { formatCurrency } from '@app/utils/format';
import { BRCComparison } from '../types';
import {
  contentContainer,
  replacementCostFooterStyle,
  replacementCostLeftItemContainerStyle,
  replacementCostRightItemContainerStyle,
  statHeaderStyle,
} from './ProvidedvsE2ValueSummary.emotion';

interface Props {
  comparisonMetrics: BRCComparison;
  displayCurrency: string;
}

const ReplacementCostComparison: FC<Props> = ({ comparisonMetrics, displayCurrency }) => {
  const formattedThirdPartyValue = formatCurrency(comparisonMetrics.thirdPartyValue, {
    currency: displayCurrency,
  });

  const formattedCustomerValue = formatCurrency(comparisonMetrics.customerValue, {
    currency: displayCurrency,
  });

  return (
    <>
      <EuiText className={statHeaderStyle} size="s">
        Avg replacement cost per floor area unit
      </EuiText>
      <EuiFlexGroup direction="row" justifyContent="flexStart" className={contentContainer}>
        <EuiFlexItem style={replacementCostLeftItemContainerStyle}>
          <EuiStat
            description={
              <EuiTextColor color="subdued" style={replacementCostFooterStyle}>
                Third party estimate
              </EuiTextColor>
            }
            title={<b>{formattedThirdPartyValue} / ft²</b>}
            titleSize="m"
            reverse
          />
        </EuiFlexItem>
        <EuiFlexItem style={replacementCostRightItemContainerStyle}>
          <EuiStat
            description={
              <EuiTextColor color="subdued" style={replacementCostFooterStyle}>
                Customer provided
              </EuiTextColor>
            }
            title={<b>{formattedCustomerValue} / ft²</b>}
            titleSize="m"
            reverse
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default ReplacementCostComparison;
