import React, { FC, useContext, useState } from 'react';
import { UploadsContext } from '@app/contexts/UploadsContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';

interface IProps {
  organizationId?: string;
  submissionId?: string;
  propertyID?: string;
  // eventPrefix is used for MixPanel tracking and is use to help indicate to source of the event.
  eventPrefix: string;
}

const DropZone: FC<IProps> = ({
  children,
  organizationId,
  submissionId,
  propertyID,
  eventPrefix,
}) => {
  const { selectedOrganization } = useContext(UserSessionContext);
  const orgId = organizationId || selectedOrganization?.id;
  const { uploadFiles } = useContext(UploadsContext);
  const [showDropZone, setShowDropZone] = useState(false);

  const preventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event: any) => {
    preventDefaults(event);
    setShowDropZone(true);
  };

  const onDragLeave = (event: any) => {
    preventDefaults(event);
    setShowDropZone(false);
  };

  const onDrop = (event: any) => {
    const files = event.dataTransfer.files;
    preventDefaults(event);

    if (files) {
      uploadFiles({ eventPrefix, files, orgId, propertyID, submissionId });
      setShowDropZone(false);
    }
  };

  return (
    <div id="dropZone" onDragEnter={onDragEnter} onDragOver={preventDefaults}>
      <div onDragLeave={onDragLeave} onDrop={onDrop} className={showDropZone ? 'showDropZone' : ''}>
        {children}
      </div>
    </div>
  );
};
export default DropZone;
