import { css } from '@emotion/css';

const buttonContainer = css`
  height: 38px;
  width: 100%;
  .euiButtonEmpty__text {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }
  :hover {
    text-decoration: inherit !important;
  }
`;

const childItemFlexGroupContainer = css`
  height: 100%;
  margin: 0 0 0 16px !important;
`;

const childItemText = (isEnrichmentJob: boolean) => css`
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${isEnrichmentJob ? '270px' : '310px'};
`;

const disabledText = css`
  color: #c2c3c6 !important;
`;

const groupItemContainer = css`
  background: #e0e6eb !important;
`;

const groupItemFlexGroupContainer = css`
  height: 100%;
  margin: 0 !important;
`;

const groupItemText = (isEnrichmentJob: boolean) => css`
  font-weight: bold !important;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${isEnrichmentJob ? '286px' : '326px'};
`;

const iconContainer = css`
  border: 2px solid red;
`;

const text = css`
  color: black !important;
`;

const textContainer = css`
  border: 2px solid green;
`;

export default {
  buttonContainer,
  childItemFlexGroupContainer,
  childItemText,
  disabledText,
  groupItemContainer,
  groupItemFlexGroupContainer,
  groupItemText,
  iconContainer,
  text,
  textContainer,
};
