import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { getCodingData } from '@onarchipelago/cx/DocumentsModal//utils';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import { ButtonProps,useToast } from 'ui';
import ConfirmationDialog from '@app/components/ConfirmationDialog/ConfirmationDialog';
import { archipelagoExportClickHandler, ExportKeyValue } from '@app/utils/export';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import PollingExportButtonComponent from './PollingExportButtonComponent';

interface Props {
  children?: (props: { isLoading: boolean; onClick: () => void }) => React.ReactElement;
  exportCode: ExportCode;
  streamSlug?: string;
  snapshotName?: string;
  metaData?: Array<ExportKeyValue>;
  orgName?: string;
  label?: string;
  buttonProps?: ButtonProps;
}

const PollingExportButtonContainer: FC<Props> = ({
  children,
  exportCode,
  streamSlug,
  snapshotName,
  metaData,
  orgName,
  label,
  buttonProps,
}) => {
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  // anitpattern - needed to tell the download callback that a user navigated away or not
  const isMounted = React.useRef(true);
  const [promptInfo, setPromptInfo] = useState<{
    show: boolean; // Whether to show the prompt
    pathname?: string; // The location to navigate to when user clicks yes
  }>({
    pathname: undefined,
    show: false,
  });

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const shouldBlock = (location: any): false | void => {
    // if promptInfo.show is true then we should allow to navigate away
    if (isLoading && promptInfo.show === false) {
      setPromptInfo({
        pathname: location.pathname,
        show: true,
      });
      return false; // returning false prevents the user from navigating away from the page
    }
  };

  // blocks navigating away when doing exports
  history.block(shouldBlock);

  const handleClick = async () => {
    setIsLoading(true);
    await archipelagoExportClickHandler({
      client: apolloClient,
      currentSnapshot: snapshotName,
      exportedCoding: getCodingData(exportCode),
      metaData,
      options: {
        withTimeOut: exportCode === ExportCode.DOCUMENTS_ARCHIVE,
      },
      orgName,
      streamSlug,
    })
      .then((downloadUrl) => {
        if (isMounted.current) {
          // don't handle the callback when user has navigated away
          window.location.href = downloadUrl;
        }
      })
      .catch((err) =>
        toast({ title: getErrorMessage(err, 'Error occured when exporting.'), type: 'danger' }),
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {promptInfo.show && (
        <ConfirmationDialog
          isOpen={promptInfo.show}
          confirmBodyText="Export is still running. Are you sure you want to leave the page?"
          onSubmit={() => {
            history.push({
              pathname: promptInfo.pathname,
            });
          }}
          onClose={() => {
            setPromptInfo({
              pathname: undefined,
              show: false,
            });
          }}
        />
      )}
      <PollingExportButtonComponent
        dataTestId="polling-export-button"
        isLoading={isLoading}
        onClick={handleClick}
        label={label}
        buttonProps={buttonProps}
      >
        {children}
      </PollingExportButtonComponent>
    </>
  );
};

export default PollingExportButtonContainer;
