import { EuiBasicTableColumn } from 'ui';

export enum GradingDetail {
  Grade = 'grade',
  Assessment = 'assessment',
  AverageLoss = 'averageLoss',
  CustomerLoss = 'customerLoss',
}

export interface RiskAttribute {
  type: GradingDetail;
  value: string;
}

export interface TableData<T> {
  columns: Array<EuiBasicTableColumn<any>>;
  items: Array<T>;
}

export enum ViewType {
  Histogram = 'Histogram View',
  Map = 'Map View',
}
