import styled from '@emotion/styled';
import { EuiModal } from 'ui';

export const Modal = styled(EuiModal)`
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, 0);
  animation: unset !important;
`;
