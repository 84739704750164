import gql from 'graphql-tag';

export const GET_ORGANIZATION_DOCUMENTS_PROPERTIES_PAGE = gql`
  query organizationDocumentsPropertiesPage($cursor: String, $filter: OrgDocumentFilter) {
    organizationDocumentsPropertiesPage(cursor: $cursor, filter: $filter) {
      pageInfo {
        cursor
        hasNextPage
        totalCount
      }
      properties {
        archipelagoId
        city
        clientId
        count
        country
        locationName
        postalCode
        streetAddress
        state
      }
    }
  }
`;
