import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { Refetch, SetData } from '../types/commonTypes';
import { QueryFilter } from '../types/queryTypes';

// Summary: This hook refetches the data from a query and when the query filters have been updated

interface Props {
  orgName: string;
  pageSize: number;
  propertyQueryFilters?: QueryFilter[];
  documentQueryFilters?: QueryFilter[];
  refetch: Refetch | undefined;
  setData: SetData;
}

export default ({
  orgName,
  pageSize,
  propertyQueryFilters = [],
  documentQueryFilters = [],
  refetch,
  setData,
}: Props) => {
  const previousPropertyQueryFilters = usePrevious(propertyQueryFilters);
  const previousDocumentQueryFilters = usePrevious(documentQueryFilters);

  const refetchData = async (queryFilters) => {
    if (refetch) {
      // First, reset the data state
      setData(null);

      // Second, perform the query again to get the updated data
      await refetch({
        cursor: null,
        filter: {
          filters: queryFilters,
          orgName,
          pageSize,
          sortBy: [{ attributeName: 'createdAt', order: 'DESCENDING' }],
        },
      }).then((response) => {
        setData(response.data);
      });
    }
  };

  useEffect(() => {
    if (propertyQueryFilters.length > 0 && previousPropertyQueryFilters !== propertyQueryFilters) {
      refetchData(propertyQueryFilters);
    }
  }, [previousPropertyQueryFilters, propertyQueryFilters]);
  useEffect(() => {
    if (documentQueryFilters.length > 0 && previousDocumentQueryFilters !== documentQueryFilters) {
      refetchData(documentQueryFilters);
    }
  }, [previousDocumentQueryFilters, documentQueryFilters]);
};
