import { useEffect, useRef, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { IJob, JobErrors, JobWarnings } from '@onarchipelago/dice/EnrichmentProjects/types';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import {
  EJobType,
  IGetJobData,
  IGetJobVariables,
  ISubmitJobData,
  SubmitEditPropertiesJobInput,
} from '@app/queries/organizations/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { checkCurrentTransition } from '../utils';
import { getSubmitEditJobQuery } from './utils';

interface Props {
  jobType: EJobType;
  onSuccess: (job: IJob) => void;
  onAPIError: (err: ApolloError) => void;
  onJobError: (errors: JobErrors) => void;
  onTimeout: () => void;
  stream: IGraphQLStream;
}
interface Return {
  loading: boolean;
  submit: (jobID: string, worksheet: File) => void;
  retry: () => void;
}

const RETRY_LIMIT = 10;

export default ({
  jobType,
  onAPIError,
  onSuccess,
  onTimeout,
  onJobError,
  stream,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [jobID, setJobID] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const jobsApolloClient = useJobsApolloClient();

  const mixpanel = useTracker('mixpanel');

  const retry = async (callback: Function) => {
    if (retryCount + 1 >= RETRY_LIMIT) {
      setIsLoading(false);
      onTimeout();
      return;
    }

    clearTimeout(timerRef.current);
    const retryTime = 3000;
    timerRef.current = setTimeout(() => {
      callback();
    }, retryTime);

    setRetryCount(retryCount + 1);
  };

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const [getJobQuery] = useLazyQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    client: jobsApolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: async ({ job }) => {
      checkCurrentTransition({
        job,
        onCompleted: () => {
          setRetryCount(0);
          onSuccess(job);
        },
        onFailed: ({ dataErrors, systemErrors }) => {
          setIsLoading(false);
          setRetryCount(0);
          onJobError({
            dataErrors,
            systemErrors,
          });
        },
        onStarted: () => {
          retry(() => getJobQuery());
        },
      });
    },
    variables: { jobID },
  });

  const getJob = () => {
    getJobQuery();
  };

  const query = getSubmitEditJobQuery(jobType);

  const [submitJobMutation] = useMutation<ISubmitJobData, SubmitEditPropertiesJobInput>(query, {
    ...(jobType !== EJobType.AddLosses &&
      jobType !== EJobType.EditLosses && { client: jobsApolloClient }),
    onCompleted: () => {
      getJob();
    },
    onError: (error) => {
      setIsLoading(false);
      setRetryCount(0);
      if (error.networkError) {
        retry(() => onTimeout());
      }

      onAPIError(error);
    },
  });

  const submit = (jID: string, worksheet: File) => {
    sendMixpanelEvent(
      mixpanel,
      `Submit ${jobType === EJobType.EditLosses ? 'Edit Losses' : 'Edit Properties'} excel file`,
      stream,
    );
    setJobID(jID);
    setIsLoading(true);

    submitJobMutation({
      variables: {
        input: {
          jobID: jID,
          worksheet,
        },
      },
    });
  };

  const retrySubmit = () => {
    sendMixpanelEvent(
      mixpanel,
      `Retry submit ${
        jobType === EJobType.EditLosses ? 'Edit Losses' : 'Edit Properties'
      } excel file`,
      stream,
    );
    setRetryCount(0);
    setIsLoading(true);
    getJob();
  };

  return {
    loading: isLoading,
    retry: retrySubmit,
    submit,
  };
};

const getTrackerEvent = (action: EJobType, stream: IGraphQLStream, jobId: string) => ({
  name: action === EJobType.EditLosses ? 'Edit Losses' : 'Edit Properties',
  payload: {
    jobId,
    stream_id: stream.id,
    stream_name: stream.name,
    stream_slug: stream.slug,
  },
});
