import React, { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import DropZone from '@app/components/Upload/DropZone';
import FilesUploader from '@app/components/Upload/FilesUploader';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { BinaryOperator } from '@app/graphql/types';
// FIX ME
// @ts-ignore
import GET_FILES from '@app/queries/documents/getOrganizationDocumentsPage.gql';
import {
  OrganizationDocumentsPageData,
  OrganizationDocumentsPageVariables,
} from '@app/queries/documents/types';
import FilesTable from './FilesTable';

type IProps = {
  showTitle?: boolean;
  organizationName?: string;
  organizationId?: string;
  submissionId?: string;
  onlyShowNew?: boolean;
  isFlyout?: boolean;
  eventPrefix: string;
};

const UploadPortalContent: FC<IProps> = ({
  showTitle = false,
  organizationName,
  organizationId,
  submissionId,
  onlyShowNew = false,
  isFlyout = false,
  eventPrefix,
}) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const orgName =
    organizationName || selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name || '';

  const sortableColumns = ['filename', 'createdAt'];
  const sortableOrders = ['ASCENDING', 'DESCENDING'];

  const sortBy = {
    attributeName: sortableColumns[1],
    order: sortableOrders[1],
  } as IGraphQLSort;

  const cursor = null;

  const { data, stopPolling } = useQuery<
    OrganizationDocumentsPageData,
    OrganizationDocumentsPageVariables
  >(GET_FILES, {
    pollInterval: 1000,
    skip: orgName === undefined || onlyShowNew,
    variables: {
      cursor,
      filter: {
        filters: [{ name: 'classification', operator: BinaryOperator.Equal, values: ['SOV'] }],
        orgName,
        pageSize: 25,
        sortBy: [sortBy],
      },
    },
  });

  // If we aren't showing all their previous files, assume they just need the generic message
  const hasSOV = !!data?.organizationDocumentsPage?.documents?.length || onlyShowNew || false;

  if (hasSOV) {
    stopPolling();
    if (selectedOrganization?.enableRedirectOnSOVUpload && !isFlyout) {
      window.location.href = 'https://info.onarchipelago.com/sov-upload-thank-you';
    }
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      {showTitle && (
        <EuiFlexItem grow={false}>
          <EuiText>
            <h3>
              {data &&
                (hasSOV
                  ? `Upload your documents ${organizationName ? `to ${organizationName}` : ''}`
                  : 'Upload your SOV')}
            </h3>
          </EuiText>
        </EuiFlexItem>
      )}
      <EuiFlexItem style={{ flex: '0 0 auto', marginBottom: '12px' /* IE11 */ }}>
        <FilesUploader
          organizationId={organizationId}
          submissionId={submissionId}
          eventPrefix={eventPrefix}
        >
          <DropZone
            organizationId={organizationId}
            submissionId={submissionId}
            eventPrefix={eventPrefix}
          >
            <div
              className="euiFilePicker__prompt"
              style={{
                borderRadius: '4px',
                height: '250px',
                margin: '12px 0px 0px 0px',
                padding: '0px',
                width: '100%',
              }}
            >
              <EuiFlexGroup
                direction="column"
                alignItems="center"
                gutterSize="none"
                style={{ height: '100%' }}
              >
                <EuiFlexItem style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  {hasSOV && (
                    <div
                      style={{
                        background: 'linear-gradient(180deg, #F2FDFB 0%, #C3F4EB 100%)',
                        borderRadius: '4px',
                        boxShadow:
                          '0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15)',
                        color: '#21B59A',
                        fontSize: '16px',
                        fontWeight: 600,
                        height: '55px',
                        letterSpacing: '-0.08px',
                        lineHeight: '55px',

                        marginTop: '40px',
                        position: 'relative',
                        right: '-13px',
                        textAlign: 'center',

                        textShadow: '0px 1px 1px rgba(0, 0, 0, 0.19)',
                        top: '5px',
                        transform: 'rotate(-14deg)',
                        width: '43px',
                      }}
                    >
                      IMG
                    </div>
                  )}
                  <div
                    style={{
                      background: 'linear-gradient(180deg, #F2FDFB 0%, #C3F4EB 100%)',
                      borderRadius: '4px',
                      boxShadow:
                        '0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15)',
                      color: '#21B59A',
                      fontSize: '14px',
                      fontWeight: 600,
                      height: '55px',
                      letterSpacing: '-0.08px',
                      lineHeight: '55px',

                      marginTop: '40px',
                      textAlign: 'center',
                      textShadow: '0px 1px 1px rgba(0, 0, 0, 0.19)',
                      width: '43px',
                      zIndex: 100,
                    }}
                  >
                    XLSX
                  </div>
                  {hasSOV && (
                    <div
                      style={{
                        background: 'linear-gradient(180deg, #F2FDFB 0%, #C3F4EB 100%)',
                        borderRadius: '4px',
                        boxShadow:
                          '0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15)',
                        color: '#21B59A',
                        fontSize: '16px',
                        fontWeight: 600,
                        height: '55px',
                        left: '-13px',
                        letterSpacing: '-0.08px',

                        lineHeight: '55px',
                        marginTop: '40px',
                        position: 'relative',
                        textAlign: 'center',
                        textShadow: '0px 1px 1px rgba(0, 0, 0, 0.19)',
                        top: '5px',
                        transform: 'rotate(14deg)',
                        width: '43px',
                      }}
                    >
                      PDF
                    </div>
                  )}
                </EuiFlexItem>
                <EuiFlexItem style={{ justifyContent: 'center' }}>
                  <EuiFlexGroup direction="column" gutterSize="xs">
                    <EuiFlexItem grow={false}>
                      <div style={{ fontSize: '16px', textAlign: 'center' }}>
                        <span style={{ color: '#21B59A', fontWeight: 600 }}>
                          Drag and drop {!hasSOV && 'your SOV'}
                        </span>{' '}
                        or browse files
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <div
                        style={{
                          color: '#98A2B3',
                          lineHeight: '24px' /* $euiColorMediumShade */,
                          textAlign: 'center',
                        }}
                      >
                        We accept files smaller than 50MB
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </DropZone>
        </FilesUploader>
      </EuiFlexItem>
      <EuiFlexItem grow={9} style={{ flex: '0 0 auto' /* IE11 */ }}>
        <FilesTable
          organizationName={organizationName}
          organizationId={organizationId}
          submissionId={submissionId}
          onlyShowNew={onlyShowNew}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default UploadPortalContent;
