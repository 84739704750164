import { createElement, FC } from 'react';
import { featureFlags } from './flags';
import { FeatureFlag } from './types';

const availableFlagsIds = featureFlags.map((flag: FeatureFlag) => flag.id);

export const createFeatureFlagSplit = (
  flagId: string,
  flagComponent: undefined | React.FunctionComponent | React.ComponentClass,
  defaultComponent: undefined | React.FunctionComponent | React.ComponentClass,
) => {
  function FlagSplit(props: any) {
    if (flagId) {
      if (!availableFlagsIds.includes(flagId)) {
        console.error(
          `No flag with ID \`${flagId}\` exists. It may have been removed or replaced with permanent code.`,
        );
      }
      if (localStorage.getItem(flagId) !== null && flagComponent) {
        return createElement(flagComponent, props);
      }

      if (localStorage.getItem(flagId) === null && defaultComponent) {
        return createElement(defaultComponent, props);
      }
    }
    return null;
  }

  return FlagSplit;
};

const FeatureFlagComponent: FC<any> = ({ flagId, FlagComponent, DefaultComponent }) => {
  if (!flagId || !availableFlagsIds.includes(flagId)) {return null;}

  if (localStorage.getItem(flagId) !== null && FlagComponent) {return FlagComponent;}

  if (localStorage.getItem(flagId) === null && DefaultComponent) {return DefaultComponent;}

  return null;
};

export { FeatureFlagComponent };

export const isFeatureEnabled = (features: Array<string> | undefined, feature: string): boolean => {
  if (!features) {
    return false;
  }
  return features.includes(feature);
};
