import { ApolloError } from '@apollo/client';

const streamV2Path = 'streamV2';
export const unauthorizedSnapshotDetail = {
  key: 'reason',
  value: 'unauthorized snapshot',
};
export const isUnauthorizedSnapshotErr = (err: ApolloError | null): boolean =>
  !!(err?.graphQLErrors?.find((e) => e.path?.includes(streamV2Path))?.extensions?.['Details'] || [])
    // FIX ME
    // @ts-ignore
    ?.find(
      ({ key, value }) =>
        key === unauthorizedSnapshotDetail.key && value === unauthorizedSnapshotDetail.value,
    );
