import { useQuery } from '@apollo/client';
import { GetStreamV2Data, GetStreamVariables } from '@onarchipelago/cx/Reports/queries/getStream';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { ExposureIndexContext } from './types';
import { makeExposureRows } from './utils';

const useGetExposureIndex = (
  streamSlug: string,
  isAdmin: boolean,
  userCode: string,
  snapshot?: string,
): ExposureIndexContext['exposure'] => {
  const { data, error, loading } = useQuery<GetStreamV2Data, GetStreamVariables>(GET_STREAM, {
    skip: !streamSlug,
    variables: {
      isAdmin,
      slug: streamSlug,
      snapshot,
      userCode,
    },
  });

  const [mainExposureRows, allExposureRows, rowGroups] = makeExposureRows(data);

  return {
    allExposureRows,
    exposureGrade: data?.streamV2?.stream?.exposure?.indexGrade,
    hasError: !!error,
    loading,
    mainExposureRows,
    noData: !loading && data?.streamV2?.stream?.exposure === null,
    portfolioN: data?.streamV2?.stream?.exposure?.rank?.streamCount,
    rowGroups,
  };
};

export default useGetExposureIndex;
