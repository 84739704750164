import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { DocumentFragmentDoc } from './DocumentFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PropertyDocumentsPageQueryVariables = Types.Exact<{
  propertyID: Types.Scalars['ID']['input'];
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  pageSize: Types.Scalars['Int']['input'];
  filters?: Types.InputMaybe<Array<Types.AttributeFilter> | Types.AttributeFilter>;
  sortBy?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
}>;


export type PropertyDocumentsPageQuery = { __typename?: 'Query', propertyDocumentsPage?: { __typename?: 'DocumentsPage', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, cursor: string, totalCount: number }, documents: Array<{ __typename?: 'Document', id: string, httpURL: string, filename: string, extension?: string | null, contentType?: string | null, fileType?: string | null, tags?: Array<string> | null, classification?: string | null, sizeInBytes: number, createdAt: any, sourcedAttributes?: Array<{ __typename?: 'AttributeMetadata', name: string, displayName: string, source?: string | null, parent?: string | null, dataType: string, filterable: boolean, groupable: boolean }> | null, streamGroups?: Array<{ __typename?: 'StreamGroup', group?: string | null, stream: { __typename?: 'Stream', id: string, name?: string | null } }> | null }> } | null };


export const PropertyDocumentsPageDocument = gql`
    query propertyDocumentsPage($propertyID: ID!, $cursor: String, $pageSize: Int!, $filters: [AttributeFilter!], $sortBy: [Sort!]) {
  propertyDocumentsPage(
    propertyID: $propertyID
    cursor: $cursor
    pageSize: $pageSize
    filters: $filters
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      cursor
      totalCount
    }
    documents {
      ...document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __usePropertyDocumentsPageQuery__
 *
 * To run a query within a React component, call `usePropertyDocumentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyDocumentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyDocumentsPageQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePropertyDocumentsPageQuery(baseOptions: Apollo.QueryHookOptions<PropertyDocumentsPageQuery, PropertyDocumentsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertyDocumentsPageQuery, PropertyDocumentsPageQueryVariables>(PropertyDocumentsPageDocument, options);
      }
export function usePropertyDocumentsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertyDocumentsPageQuery, PropertyDocumentsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertyDocumentsPageQuery, PropertyDocumentsPageQueryVariables>(PropertyDocumentsPageDocument, options);
        }
export type PropertyDocumentsPageQueryHookResult = ReturnType<typeof usePropertyDocumentsPageQuery>;
export type PropertyDocumentsPageLazyQueryHookResult = ReturnType<typeof usePropertyDocumentsPageLazyQuery>;
export type PropertyDocumentsPageQueryResult = Apollo.QueryResult<PropertyDocumentsPageQuery, PropertyDocumentsPageQueryVariables>;