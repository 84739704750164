import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import queryString from 'query-string';
import { NavigationType } from '../../types/commonTypes';
import { DocumentsContext } from '../../types/contextTypes';
import { underscoreString } from '../../utils/utils';

// Summary: The objective of this function is to:
//  1. analyze what filters have been been applied and update the query string based on those
//  filters
//  2. if the navigation tab has been changed, reset the query string
//  3. Conditional logic:
//    a. The user has switched filters while in the same tab
//    b. The user has switched to the selected tab

export default (
  filters: DocumentsContext['filters'],
  navigation: DocumentsContext['navigation'],
) => {
  const { documentType, uploadDate } = filters;
  const { currentTab } = navigation;

  // Router states
  const history = useHistory();

  // Previous states
  const previousDocumentType = usePrevious(documentType);
  const previousTab = usePrevious(currentTab);
  const previousUploadDate = usePrevious(uploadDate);

  // If the user switches to the property tab, reset the query string
  if (currentTab === NavigationType.PROPERTY && previousTab !== NavigationType.PROPERTY) {
    history.push({ search: null });
  }

  // If the document type has changed, add it to the query string
  if (
    (documentType.state?.id && // *3a
      previousDocumentType?.state?.id !== documentType.state.id &&
      currentTab === NavigationType.DOCUMENT_TYPE) ||
    (documentType.state?.id && // *3b
      currentTab === NavigationType.DOCUMENT_TYPE &&
      previousTab !== NavigationType.DOCUMENT_TYPE)
  ) {
    const params = queryString.stringify({
      documentType: underscoreString(documentType.state?.id as string),
    });
    history.push({ search: params });
  }

  // ToDo: will handle pulling property from query string in later pr, see this slack message:
  // https://archipelago-analytics.slack.com/archives/C02D4GSPCF4/p1633189044009500
  // // If the property has changed, add it to the query string
  // if (property.state?.id && previousProperty?.state?.id !== property.state.id) {
  //   const params = queryString.stringify({
  //     property: underscoreString(property.state?.id as string),
  //   });
  //   history.push({ search: params });
  // }

  // If the upload date has changed, add it to the query string
  if (
    (uploadDate.state?.id && // *3a
      previousUploadDate?.state?.id !== uploadDate.state.id &&
      currentTab === NavigationType.UPLOAD_DATE) ||
    (uploadDate.state?.id && // *3b
      currentTab === NavigationType.UPLOAD_DATE &&
      previousTab !== NavigationType.UPLOAD_DATE)
  ) {
    const params = queryString.stringify({
      uploadDate: underscoreString(uploadDate.state?.id as string),
    });
    history.push({ search: params });
  }
};
