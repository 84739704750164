import styled from '@emotion/styled';
import { EuiText } from 'ui';

export const SessionsTitle = styled(EuiText)`
  ${({ color }: { color: string }) =>
    `
      color: ${color};
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
    `}
`;
