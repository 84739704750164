import { createContext } from 'react';
import { IStream } from '@onarchipelago/cx/Stream/types';
import { StreamFormMeta } from './validator';

interface IStreamEditContext {
  initialValues?: IStream;
  copyOfStream?: IStream;
  setFieldValue: (field: string, value: any, secondaryField?: string, secondaryValue?: any) => void;
  message?: string;
  setMessage?: (value: string) => void;
  meta?: StreamFormMeta;
  setFieldError?: (field: string, error: string) => void;
  touch?: (field: string) => void;
  submit?: () => void;
  submitting?: boolean;
  touchedFields?: Partial<IStream>;
  setTouchedFields?: (fields) => void;
}

export const initialStreamEditContext = {
  setFieldValue: () => {},
};

const StreamEditContext = createContext<IStreamEditContext>(initialStreamEditContext);
export default StreamEditContext;
