import React, { FC } from 'react';
import { Button, ButtonProps } from 'ui';

interface Props {
  children?: (props: { isLoading: boolean; onClick: () => void }) => React.ReactElement;
  dataTestId?: string;
  isLoading: boolean;
  onClick: () => Promise<void>;
  label?: string;
  buttonProps?: ButtonProps;
}

const PollingExportButtonComponent: FC<Props> = ({
  children,
  dataTestId,
  isLoading,
  onClick,
  label = 'Export',
  buttonProps,
}) => {
  if (children) {
    return children({ isLoading, onClick });
  }

  return (
    <Button
      data-testid={dataTestId}
      fill
      label={label}
      loading={isLoading}
      onClick={onClick}
      size="s"
      {...buttonProps}
    />
  );
};

export default PollingExportButtonComponent;
