import { ExposureLevel } from '../types';

const exposureLabels = {
  [ExposureLevel.VeryHigh]: 'Very High',
  [ExposureLevel.High]: 'High',
  [ExposureLevel.Medium]: 'Medium',
  [ExposureLevel.Low]: 'Low',
  [ExposureLevel.VeryLow]: 'Very Low',
};

export default exposureLabels;
