import styled from '@emotion/styled';
import { EuiBadge, EuiBasicTable } from 'ui';

export const StyledTaskTable = styled(EuiBasicTable as any)`
  tr.dismissed {
    background-color: #f7f8fc;
  }
`;

export const StyledBadge = styled(EuiBadge)<{ withMargin?: boolean }>`
  color: #fff !important;
  font-weight: 700;
  margin-right: ${({ withMargin }) => (withMargin ? '4px' : '0')};
  :hover {
    cursor: pointer;
  }
`;

export const StyledSummary = styled.div`
  font-size: 14px;
`;

export const StyledStat = styled.span`
  font-weight: 600;
`;
