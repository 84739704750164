import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem, Spacer } from 'ui';
import QualityScoreKey from '../../ReadinessReport/QualityScoreKey';
import { QualityScore } from '../../ReadinessReport/types';
import { qualityScoresForSnapshots } from '../../ReadinessReport/utils';
import ChartTitle from '../ChartTitle/ChartTitle';
import ChartAxisLabel from '../Components/ChartAxisLabel';
import HorizontalRule from '../Components/HorizontalRule';
import HorizontalBar from './HorizontalBar';
import { ChartAxisText, VerticalSpacer } from './HorizontalBarChart.emotion';
import { BarsData } from './types';

interface Props {
  bars: BarsData;
  closeBarLabels?: boolean;
  keyItems?: QualityScore[];
  keyGutterSize?: EuiFlexGroupProps['gutterSize'];
  qualityScoreKey?: any;
  qualityScoreKeyTitle?: string;
  showBarLabels?: boolean;
  showKey?: boolean;
  showRule?: boolean;
  title?: string;
  titleSize?: 'medium' | 'large';
  currency?: string;
}

const HorizontalBarChart: FC<Props> = ({
  bars,
  closeBarLabels = false,
  keyItems = qualityScoresForSnapshots,
  keyGutterSize = 'none',
  qualityScoreKey = qualityScoresForSnapshots,
  qualityScoreKeyTitle,
  showBarLabels = true,
  showKey = true,
  showRule = true,
  title,
  titleSize = 'large',
  currency,
}) => (
  <EuiFlexGroup direction="column" gutterSize="s">
    {title && (
      <EuiFlexItem data-testid="bar-chart-title" grow={false}>
        <ChartTitle titleSize={titleSize}>{title}</ChartTitle>
      </EuiFlexItem>
    )}
    <EuiFlexItem grow={false}>
      <EuiFlexGroup>
        <EuiFlexItem grow={4}>
          {showKey && (
            <>
              <EuiFlexGroup data-testid="bar-chart-key">
                <EuiFlexItem grow={3}>
                  <QualityScoreKey items={keyItems} gutterSize={keyGutterSize} />
                </EuiFlexItem>
                <EuiFlexItem grow={2}>{/* Dead space to constrain the Key */}</EuiFlexItem>
              </EuiFlexGroup>
              <Spacer size="m" />
            </>
          )}
          <EuiFlexGroup direction="column" gutterSize={showBarLabels ? 'none' : 's'}>
            {bars.map((item: any) => (
              <EuiFlexItem key={item.label}>
                <HorizontalBar
                  closeBarLabels={closeBarLabels}
                  scores={item.scores}
                  showBarLabels={showBarLabels}
                  title={item.label}
                  currency={currency}
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
          {showRule && (
            <EuiFlexGroup data-testid="horizontal-rule" direction="row" gutterSize="xs">
              <EuiFlexItem grow={2}>{/* Dead space to constrain the HR */}</EuiFlexItem>
              <EuiFlexItem grow={3}>
                <Spacer size="s" />
                <HorizontalRule />
                <Spacer size="l" />
                <ChartAxisLabel>
                  <ChartAxisText>% of TIV</ChartAxisText>
                </ChartAxisLabel>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
        <VerticalSpacer grow={false} /> {/* Vertical Spacer */}
        {qualityScoreKeyTitle && (
          <EuiFlexItem grow={1}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={false}>
                <Spacer size="l" />
              </EuiFlexItem>
              <EuiFlexItem data-testid="bar-chart-quality-score-key">
                <ChartAxisLabel data-testid="bar-chart-key-title" hideIcon>
                  {qualityScoreKeyTitle}
                </ChartAxisLabel>
                <QualityScoreKey
                  items={qualityScoreKey}
                  direction="column"
                  justifyContent="flexStart"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default HorizontalBarChart;
