import { useEffect } from 'react';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { sendMixpanelEvent } from '../utils';

class ClassWatcher {
  private targetNode;

  private classToWatch;

  private classAddedCallback;

  private classRemovedCallback;

  private observer;

  private lastClassState;

  constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
    this.targetNode = targetNode;
    this.classToWatch = classToWatch;
    this.classAddedCallback = classAddedCallback;
    this.classRemovedCallback = classRemovedCallback;
    this.observer = null;
    this.lastClassState = targetNode.classList.contains(this.classToWatch);

    this.init();
  }

  init() {
    this.observer = new MutationObserver(this.mutationCallback);
    this.observe();
  }

  observe() {
    this.observer.observe(this.targetNode, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  mutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(this.classToWatch);
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState;
          if (currentClassState) {
            this.classAddedCallback();
          } else {
            this.classRemovedCallback();
          }
        }
      }
    }
  };
}

export const useGridDOMTrackers = ({ dataGridRef }) => {
  const mixpanel = useTracker('mixpanel');
  const { stream } = useStreamContext();

  const onSubmenuClick = () => {
    // HACK: wait for lag of select rendering into DOM
    setTimeout(() => {
      // portal div added to dom when select is open
      const attributeSelectPortal = document.querySelector('div[data-euiportal="true"]');

      if (attributeSelectPortal) {
        sendMixpanelEvent(mixpanel, 'Open attribute submenu', stream);

        // when the button is clicked and the select is open attach an observer
        // to the body that will tell us when the select portal has been removed
        // from the dom (the select has been closed), then disconnect observer
        const obs = new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            for (const el of mutation.removedNodes) {
              if (el === attributeSelectPortal) {
                sendMixpanelEvent(mixpanel, 'close attribute submenu', stream);
                obs.disconnect();
              }
            }
          }
        });
        obs.observe(document.body, { childList: true });
      }
    }, 1000);
  };

  const onFullScreenEnter = () => {
    sendMixpanelEvent(mixpanel, 'Enter fullscreen mode', stream);
  };

  const onFullScreenExit = () => {
    sendMixpanelEvent(mixpanel, 'Exit fullscreen mode', stream);
  };
  // tracker for attribute select open and close
  useEffect(() => {
    if (dataGridRef.current) {
      const button = document.querySelector('[data-test-subj=dataGridColumnSelectorButton]');
      button?.addEventListener('click', onSubmenuClick);

      const grid = document.querySelector('.euiDataGrid');

      // watch for a specific class change
      const classWatcher = new ClassWatcher(
        grid,
        'euiDataGrid--fullScreen',
        onFullScreenEnter,
        onFullScreenExit,
      );

      return () => {
        if (dataGridRef.current) {
          const button = document.querySelector('[data-test-subj=dataGridColumnSelectorButton]');
          button?.removeEventListener('click', onSubmenuClick);
          classWatcher?.disconnect();
        }
      };
    }
  }, [dataGridRef.current]);
};
