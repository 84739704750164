import styled from '@emotion/styled';
import { Button } from 'ui';

export const StyledButton = styled(Button)`
  background-color: #ccf2f0;
`;

export const StyledDiv = styled.div`
  width: 350px;
`;

export const StyledDisclaimer = styled.div`
  padding: 12px;
  font-size: 12px;
  font-style: italic;
`;
