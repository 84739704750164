import { css } from '@emotion/css';

const card = css`
  width: 300px !important;
  .euiCard__content {
    margin-left: 8px;
    max-height: 130px;
    overflow: hidden;
  }
  .euiCard__top {
    margin-top: 4px !important;
  }
`;

export default {
  card,
};
