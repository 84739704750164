import React from 'react';
import {
  CarrierAssessment,
  LossRegion,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import HighMaps from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import usAll from '@app/cx/Stream/Aside/Explorers/Charts/usAll.json';
import { GradeColors } from '../../../insurerInsightsTypes';
import { getColor } from '../../../utils';
import { useGradingDetailsContext } from '../context';
import { stateCodeMap } from './usStateCodes';
import { getLossRegionFrequencyRange } from './utils';

HighMaps.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const getOptions = (carrierAssessment: CarrierAssessment): any => {
  const { min, max } = getLossRegionFrequencyRange(carrierAssessment.lossFrequencyGeography.states);

  const data = [];
  const riaCntMap = {};
  carrierAssessment.lossFrequencyGeography.states.forEach((s) => {
    data.push([stateCodeMap[s.name], s.lossFrequency]);
    riaCntMap[s.name] = s.riaCnt;
  });

  const minColor = getColor(GradeColors.BLUE);
  const maxColor = getColor(GradeColors.RED);
  const avgColor = getColor(GradeColors.GREEN);

  return {
    chart: {
      animation: false,
      map: usAll,
      style: {
        margin: '0 auto',
      },
    },

    colorAxis: {
      max,
      maxColor,
      min,
      minColor,
      stops: [
        [0, minColor],
        [0.5, avgColor],
        [1, maxColor],
      ],
      type: 'linear',
    },

    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      backgroundColor: 'rgba(255,255,255,0.85)',
      borderWidth: 0,
      floating: true,
      layout: 'horizontal',
      verticalAlign: 'bottom',
      y: 20,
    },
    mapNavigation: {
      enabled: false,
    },
    series: [
      {
        data,
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        name: 'USA',
      },
    ],
    subtitle: {
      text: null,
    },
    title: {
      align: 'low',
      text: `Loss Frequency by US States for  ${carrierAssessment.lossFrequencyHistogram.period}`,
    },

    tooltip: {
      formatter() {
        //eslint-disable-line
        return `${this.point.name}<br/>Loss Frequency: <b>${this.point.value}</b> <br/>
        Open RIAs: <b>${riaCntMap[this.point.name]}</b>`;
      },
      valuePrefix: '$',
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
  };
};
const AverageLossMap: React.FC = () => {
  const { carrierAssessment } = useGradingDetailsContext();
  const options = getOptions(carrierAssessment);
  return <HighchartsReact highcharts={HighMaps} constructorType="mapChart" options={options} />;
};

export default AverageLossMap;
