import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrganizationAttributeMetadataQueryVariables = Types.Exact<{
  organizationId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orgName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  streamId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  includeSystemAttributes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetOrganizationAttributeMetadataQuery = { __typename?: 'Query', organizationAttributeMetadata: Array<{ __typename?: 'OrgAttributeMetadata', organizationId: string, streamId: string, includeInStreams: boolean, metadata: { __typename?: 'AttributeMetadata', name: string, displayName: string, columnPosition: number, displayWidth: number, source?: string | null, parent?: string | null, dataType: string, filterable: boolean, groupable: boolean, derived: boolean, hiddenInGrid: boolean, maxThreshold?: number | null, decimals?: number | null, dimension?: string | null, subdimension?: string | null, isCustom?: boolean | null, scope?: string | null, enumMetadata?: Array<{ __typename?: 'EnumValueMetadata', valueId: string, value: string, valueDisplay?: string | null, position: number, clusterValue?: string | null, hexColorCode?: string | null, externalMappings?: Array<{ __typename?: 'ExternalMapping', externalCoding: Types.ExternalCoding, valueId: string, value: string }> | null, softMappings?: Array<{ __typename?: 'SoftMapping', modelSchema: Types.SoftMappingModelSchema, secondaryModifier: string, value?: string | null } | null> | null }> | null, secondaryModifier?: { __typename?: 'SecondaryModifier', coding?: string | null, model?: string | null, fieldName?: string | null, codeName?: string | null } | null } }> };


export const GetOrganizationAttributeMetadataDocument = gql`
    query GetOrganizationAttributeMetadata($organizationId: String, $orgName: String, $streamId: String, $includeSystemAttributes: Boolean) {
  organizationAttributeMetadata(
    organizationId: $organizationId
    orgName: $orgName
    streamId: $streamId
    includeSystemAttributes: $includeSystemAttributes
  ) {
    organizationId
    streamId
    includeInStreams
    metadata {
      name
      displayName
      columnPosition
      displayWidth
      source
      parent
      dataType
      filterable
      groupable
      derived
      hiddenInGrid
      enumMetadata {
        valueId
        value
        valueDisplay
        position
        clusterValue
        hexColorCode
        externalMappings {
          externalCoding
          valueId
          value
        }
        softMappings {
          modelSchema
          secondaryModifier
          value
        }
      }
      maxThreshold
      decimals
      secondaryModifier {
        coding
        model
        fieldName
        codeName
      }
      dimension
      subdimension
      isCustom
      scope
    }
  }
}
    `;

/**
 * __useGetOrganizationAttributeMetadataQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAttributeMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAttributeMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAttributeMetadataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      orgName: // value for 'orgName'
 *      streamId: // value for 'streamId'
 *      includeSystemAttributes: // value for 'includeSystemAttributes'
 *   },
 * });
 */
export function useGetOrganizationAttributeMetadataQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationAttributeMetadataQuery, GetOrganizationAttributeMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationAttributeMetadataQuery, GetOrganizationAttributeMetadataQueryVariables>(GetOrganizationAttributeMetadataDocument, options);
      }
export function useGetOrganizationAttributeMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationAttributeMetadataQuery, GetOrganizationAttributeMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationAttributeMetadataQuery, GetOrganizationAttributeMetadataQueryVariables>(GetOrganizationAttributeMetadataDocument, options);
        }
export type GetOrganizationAttributeMetadataQueryHookResult = ReturnType<typeof useGetOrganizationAttributeMetadataQuery>;
export type GetOrganizationAttributeMetadataLazyQueryHookResult = ReturnType<typeof useGetOrganizationAttributeMetadataLazyQuery>;
export type GetOrganizationAttributeMetadataQueryResult = Apollo.QueryResult<GetOrganizationAttributeMetadataQuery, GetOrganizationAttributeMetadataQueryVariables>;