import { css } from '@emotion/css';

const loadingContainer = css`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export default {
  loadingContainer,
};
