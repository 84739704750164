import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { builder, BuilderComponent, useIsPreviewing } from '@builder.io/react';
import { useUserSession } from '@app/contexts/UserSessionContext';

interface Params {
  slug: string;
}

export const Page = () => {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const { selectedOrganization } = useUserSession();

  const { slug } = useParams<Params>();

  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('page', {
          url: `/${slug}`,
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [window.location.pathname]);

  if (notFound && !isPreviewingInBuilder) {
    return <></>;
  }

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent
        data={{
          organization: selectedOrganization.name,
        }}
        model="page"
        content={content}
      />
    </>
  );
};
