import { FC } from 'react';

interface IProps {
  error: Error;
  eventId?: string;
}

const ErrorContainer: FC<IProps> = ({ eventId }) => null;

export default ErrorContainer;
