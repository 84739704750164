import gql from 'graphql-tag';

export const START_EDIT_PROPERTIES_JOB = gql`
  mutation StartEditPropertiesJob($input: StartEditPropertiesJobInput!) {
    startEditPropertiesJob(input: $input) {
      jobID
      worksheet {
        id
        httpURL
        filename
        extension
        sizeInBytes
        contentType
      }
    }
  }
`;
