import React from 'react';
import { useLocation } from 'react-router';
import { EuiHeaderSection } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { OrgSwitcherButton } from '@app/cx/OrgSwitcher/OrgSwitcherButton';
import { OrgSwitcherFlyout } from '@app/cx/OrgSwitcher/OrgSwitcherFlyout/OrgSwitcherFlyout';
import { pathMatches } from '@app/utils/url';
import { GlobalHeader } from '../Layout.emotion';
import { HelpButton } from './HelpButton/HelpButton';
import { RightHeaderItem } from './TopNav.emotion';

const showHelpButton = (pathname: string): boolean =>
  (pathMatches(pathname, '/*/users') ||
    pathMatches(pathname, '/organizations/*/home') ||
    pathMatches(pathname, 'organizations/*/inbox') ||
    pathMatches(pathname, '/organizations/*/properties*') ||
    pathMatches(pathname, '/streams/*')) &&
  !pathMatches(pathname, '/organizations/*/streams') &&
  !pathMatches(pathname, '/streams/*/domains') &&
  !pathMatches(pathname, '/streams/*/viewers');

export const TopNav = () => {
  const { showFlyout } = useFlyout();
  const location = useLocation();

  return (
    <GlobalHeader position="fixed">
      <EuiHeaderSection side="left">
        <OrgSwitcherButton isLarge onClick={() => showFlyout(<OrgSwitcherFlyout side="left" />)} />
      </EuiHeaderSection>
      <EuiHeaderSection side="right">
        {showHelpButton(location.pathname) && (
          <RightHeaderItem border="none">
            <HelpButton />
          </RightHeaderItem>
        )}
      </EuiHeaderSection>
    </GlobalHeader>
  );
};
