import React from 'react';
import { UserViews } from '@onarchipelago/cx/Reports/queries/getEngagementViewsByUser';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import mapUser from './mapUser';
import { User } from './types';
import UserViewsTable from './UserViewsTable';

interface Props {
  data: UserViews[];
}

const UserViewsComponent: React.FC<Props> = ({ data }) => {
  const items: User[] = data.map((datum, index) => mapUser(datum, index));

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem grow={false}>
        <EuiText data-testid="user-views-title">
          <h3>Most Recent Views By User</h3>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <UserViewsTable items={items} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default UserViewsComponent;
