import { useRef } from 'react';
import { useEffectOnce } from 'react-use';

export default () => {
  const isMounted = useRef(false);
  useEffectOnce(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return (callback: Function) => {
    if (isMounted.current === true) {
      callback();
    }
  };
};
