import styled from '@emotion/styled';
import { EuiDescriptionListDescription } from 'ui';
import { Panel } from 'ui/dist/atoms/layout/panel/Panel';

export const StyledDescription = styled(EuiDescriptionListDescription)`
  margin-top: 0px !important;
  flex: 1;
  border-radius: 0;
  padding: 4px;
`;

export const StyledPanel = styled(Panel)`
  padding: 0;
  border-radius: 0;
  border-top: 1px solid #e0e0e0;
  border-right: none;
  border-left: none;
`;
