import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  EuiDataGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { defaultColumns } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import ChartTitle from '@app/cx/Reports/Charts/ChartTitle/ChartTitle';
import { PROPERTY_RANK_ATTRIBUTES } from '../constants';
import { useRanking } from '../hooks/useRanking';

export const PrecheckRank = () => {
  const { account } = useAuth();
  const { selectedOrganization } = useUserSession();
  const isAdmin = account?.permissions?.admin;
  const { streamId } = useParams<{ streamId: string; organizationName: string }>();
  const organizationName = selectedOrganization?.name;
  const [columns, setColumns] = useState(Array.from(defaultColumns));
  const { data, loading, saveLoading, saveRank, refetch } = useRanking();
  const [selectedRows, setSelectedRows] = useState([]);
  const toast = useToast();

  if (!isAdmin) {
    return <Redirect to={`/${organizationName}/precheck/${streamId}`} />;
  }

  const prop = { ...(data?.propertiesToRankByPriority?.properties[0] || {}) };
  delete prop['__typename'];

  const submitRanks = async () => {
    const revisionIds = [];
    try {
      const props = data?.propertiesToRankByPriority?.properties;

      if (selectedRows[0] === 0) {
        revisionIds.push(props[0].revisionId);
        revisionIds.push(props[1].revisionId);
      } else {
        revisionIds.push(props[1].revisionId);
        revisionIds.push(props[0].revisionId);
      }
      saveRank({
        variables: {
          input: { revisionIDs: revisionIds, streamSlug: streamId },
        },
      });

      setSelectedRows([]);

      await refetch();
    } catch (e) {
      toast({ label: e.message, title: 'Error', type: 'danger' });
    }
  };

  return (
    <>
      <div style={{ margin: '24px auto 24px auto', width: '80%' }}>
        <EuiTitle size="xs">
          <h1>
            Choose which property has the highest priority to improve the portfolios data quality
          </h1>
        </EuiTitle>
        <EuiSpacer />
        {loading ? (
          <LoadingSpinnerV2 />
        ) : (
          <EuiFlexGroup>
            {data?.propertiesToRankByPriority?.properties.map((prop, index) => (
                <EuiFlexItem>
                  <EuiFlexGroup style={{ marginBottom: '24px' }} alignItems="center">
                    <EuiFlexItem grow={false}>
                      <EuiTitle size="s">
                        <h2>{prop.locationName}</h2>
                      </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <Button
                        onClick={() => {
                          setSelectedRows([index]);
                          submitRanks();
                        }}
                        loading={saveLoading}
                        disabled={saveLoading}
                        size="s"
                        label="choose property"
                      ></Button>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  {PROPERTY_RANK_ATTRIBUTES.map((att) => {
                    const f = Object.keys(prop).find((k) => k.toLocaleLowerCase() === att.toLocaleLowerCase());

                    if (f) {console.log(f);}

                    return (
                      <EuiFlexGroup style={{ marginBottom: '12px' }}>
                        <EuiFlexItem grow={2}>
                          <ChartTitle titleSize="large"> {att}</ChartTitle>
                        </EuiFlexItem>
                        <EuiFlexItem grow={2}>
                          {prop[att] ? (
                            <EuiText size="s">
                              <EuiTextColor color="subdued">{prop[att]}</EuiTextColor>
                            </EuiText>
                          ) : (
                            <EuiText size="s">
                              <EuiTextColor color="danger">missing</EuiTextColor>
                            </EuiText>
                          )}
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    );
                  })}
                </EuiFlexItem>
              ))}
          </EuiFlexGroup>
        )}
      </div>
    </>
  );
};
