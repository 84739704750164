import React from 'react';
import { EuiPageTemplate, Icon } from 'ui';
import styles from '@app/platform/Inbox/InboxContent/InboxContent.emotion';

export const EmptyMessage = () => {
  return (
    <EuiPageTemplate.EmptyPrompt
      icon={<Icon color="black" name={'checkSquare'} size="xxl" />}
      panelled={false}
      title={<span>Nicely Done!</span>}
      hasShadow={false}
      className={styles.empty}
    >
      <p>No more tasks. Take a minute to recharge.</p>
    </EuiPageTemplate.EmptyPrompt>
  );
};
