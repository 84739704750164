import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { NavigationType } from '../../types/commonTypes';
import { DocumentsContext } from '../../types/contextTypes';

export default (): DocumentsContext['navigation'] => {
  const query = queryString.parse(useLocation().search);
  const queryKey = Object.keys(query)[0];

  // Determine what the default tab should be based on the query string, if there is any params
  // in there at all. Or else, have UPLOAD_DATE be the default tab.
  let defaultTab: NavigationType = NavigationType.UPLOAD_DATE;
  switch (queryKey) {
    case 'documentType':
      defaultTab = NavigationType.DOCUMENT_TYPE;
      break;
    case 'property':
      defaultTab = NavigationType.PROPERTY;
      break;
    case 'uploadDate':
      defaultTab = NavigationType.UPLOAD_DATE;
      break;
    default:
      break;
  }

  const [currentTab, setCurrentTab] = useState<NavigationType>(defaultTab);

  return {
    currentTab,
    setCurrentTab,
  };
};
