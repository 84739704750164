import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EuiSelectableOption } from 'ui';
import {
  CreateExportConfigurationInputType,
  ExportConfigurationResultType,
  ExportConfigurationType,
} from './graphql/types';
import { useManageExportsMutations } from './hooks/useManageExportsMutations';
import { useManageExportsQueries } from './hooks/useManageExportsQueries';
import { DEFAULT_EXPORT_CONFIGURATION_NAME as DEFAULT_EXPORT_CONFIG_NAME, STEP } from './constants';
import { OrderableOptionType,SelectableOptionDataType } from './types';
import {
  formatOrgAttributeMetadata,
  formatPropAttributeMetadata,
  makeExportConfigFromOrderableOptions,
  makeOrderableOptionsFromSelectable,
  makeSelectableAttributeOptions,
} from './utils';

interface IManageExportsContext {
  selectableAttributeOptions?: Array<EuiSelectableOption<SelectableOptionDataType>>;
  setSelectableAttributeOptions?: Dispatch<
    SetStateAction<Array<EuiSelectableOption<SelectableOptionDataType>>>
  >;
  orderableOptions?: Array<OrderableOptionType> | null;
  setOrderableOptions?: Dispatch<SetStateAction<Array<OrderableOptionType> | null>>;
  isLoading?: boolean;
  isSaving?: boolean;
  step?: STEP;
  setStep?: Dispatch<SetStateAction<STEP>>;
  transitionToOrderStep?: () => void;
  saveExportConfig?: () => void;
}

const ManageExportsContext = createContext<IManageExportsContext>({});

interface IManageExportsContextProvider {
  children: ReactNode;
  orgId: string;
  streamId?: string;
  streamSlug?: string;
}

export const ManageExportsContextProvider = ({
  children,
  orgId,
  streamId,
  streamSlug,
}: IManageExportsContextProvider) => {
  const [selectableAttributeOptions, setSelectableAttributeOptions] =
    useState<Array<EuiSelectableOption<SelectableOptionDataType>>>();

  const [orderableOptions, setOrderableOptions] = useState<Array<OrderableOptionType>>();
  const [exportConfigId, setExportConfigId] = useState<string>();
  const [exportConfig, setExportConfig] = useState<Array<ExportConfigurationType>>();
  const [step, setStep] = useState<STEP>(STEP.SELECT);

  const {
    exportConfigsResult,
    exportConfigsLoading,
    orgAttrData,
    orgAttrLoading,
    propAttrData,
    propAttrLoading,
  } = useManageExportsQueries({ orgId, streamId, streamSlug });

  const { createExportConfig, creatingExportConfig, updateExportConfig, updatingExportConfig } =
    useManageExportsMutations();

  useEffect(() => {
    // prioritize the stream level config if it exists and working on stream level
    let existingStreamExportConfig: ExportConfigurationResultType = null;
    if (streamId) {
      existingStreamExportConfig = exportConfigsResult?.exportConfigurations?.find(
        (config) => config.name === DEFAULT_EXPORT_CONFIG_NAME && config.streamID === streamId,
      );
    }

    const existingOrgExportConfig = exportConfigsResult?.exportConfigurations?.find(
      (config) => config.name === DEFAULT_EXPORT_CONFIG_NAME && config.streamID === null,
    );

    const existingExportConfig = existingStreamExportConfig || existingOrgExportConfig;
    if (existingExportConfig) {
      const sortedConfig = [...existingExportConfig?.configuration]?.sort(
        (configA, configB) => configA.position - configB.position,
      );
      // only want to edit the stream config on stream level and org config on org level
      const existingExportConfigId = streamId
        ? existingStreamExportConfig?.id
        : existingOrgExportConfig?.id;

      setExportConfig(sortedConfig || null);
      setExportConfigId(existingExportConfigId || null);
    }
  }, [exportConfigsResult]);

  useEffect(() => {
    // prioritize using property level attribute metadata if it exists
    let formattedAttrData = streamSlug
      ? formatPropAttributeMetadata(propAttrData, exportConfig)
      : [];
    formattedAttrData = formattedAttrData.length
      ? formattedAttrData
      : formatOrgAttributeMetadata(orgAttrData, exportConfig);

    setSelectableAttributeOptions(makeSelectableAttributeOptions(formattedAttrData));
  }, [orgAttrData, propAttrData, exportConfig]);

  const setOrderableOptionsFromSelectable = () => {
    const newOrderableOptions = makeOrderableOptionsFromSelectable(
      selectableAttributeOptions,
      exportConfig,
    );
    setOrderableOptions(newOrderableOptions);
  };

  const transitionToOrderStep = () => {
    setOrderableOptionsFromSelectable();
    setStep(STEP.ORDER);
  };

  const saveExportConfig = () => {
    const newExportConfig = makeExportConfigFromOrderableOptions(orderableOptions);
    const saveExportConfigInput: CreateExportConfigurationInputType = {
      configuration: newExportConfig,
      name: DEFAULT_EXPORT_CONFIG_NAME,
      organizationID: orgId,
      streamID: streamId,
    };

    if (exportConfigId) {
      updateExportConfig({
        variables: {
          input: {
            exportConfiguration: saveExportConfigInput,
            id: exportConfigId,
          },
        },
      });
    } else {
      createExportConfig({ variables: { input: saveExportConfigInput } });
    }
  };

  return (
    <ManageExportsContext.Provider
      value={{
        isLoading: exportConfigsLoading || orgAttrLoading || propAttrLoading,
        isSaving: creatingExportConfig || updatingExportConfig,
        orderableOptions,
        saveExportConfig,
        selectableAttributeOptions,
        setOrderableOptions,
        setSelectableAttributeOptions,
        setStep,
        step,
        transitionToOrderStep,
      }}
    >
      {children}
    </ManageExportsContext.Provider>
  );
};

export const useManageExportsContext = () => useContext(ManageExportsContext);
