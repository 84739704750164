import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnterpriseDashboardMetadataQueryVariables = Types.Exact<{
  input: Types.EnterpriseDashboardMetadataInput;
}>;


export type EnterpriseDashboardMetadataQuery = { __typename?: 'Query', enterpriseDashboardMetadata: { __typename?: 'EnterpriseDashboardMetadataResponse', workspaceID: string, signedJWT: string, dashboards?: Array<{ __typename?: 'Dashboard', id: string, title?: string | null }> | null } };


export const EnterpriseDashboardMetadataDocument = gql`
    query EnterpriseDashboardMetadata($input: EnterpriseDashboardMetadataInput!) {
  enterpriseDashboardMetadata(input: $input) {
    workspaceID
    signedJWT
    dashboards {
      id
      title
    }
  }
}
    `;

/**
 * __useEnterpriseDashboardMetadataQuery__
 *
 * To run a query within a React component, call `useEnterpriseDashboardMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseDashboardMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseDashboardMetadataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnterpriseDashboardMetadataQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseDashboardMetadataQuery, EnterpriseDashboardMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseDashboardMetadataQuery, EnterpriseDashboardMetadataQueryVariables>(EnterpriseDashboardMetadataDocument, options);
      }
export function useEnterpriseDashboardMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseDashboardMetadataQuery, EnterpriseDashboardMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseDashboardMetadataQuery, EnterpriseDashboardMetadataQueryVariables>(EnterpriseDashboardMetadataDocument, options);
        }
export type EnterpriseDashboardMetadataQueryHookResult = ReturnType<typeof useEnterpriseDashboardMetadataQuery>;
export type EnterpriseDashboardMetadataLazyQueryHookResult = ReturnType<typeof useEnterpriseDashboardMetadataLazyQuery>;
export type EnterpriseDashboardMetadataQueryResult = Apollo.QueryResult<EnterpriseDashboardMetadataQuery, EnterpriseDashboardMetadataQueryVariables>;