import { LossRegion } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';

interface Range {
  min: number;
  max: number;
}
export const getLossRegionFrequencyRange = (regions: Array<LossRegion>): Range => {
  let min = -1;
  let max = -1;

  regions.forEach((s) => {
    if (max < 0 || max <= s.lossFrequency) {
      max = s.lossFrequency;
    }

    if (min < 0 || min >= s.lossFrequency) {
      min = s.lossFrequency;
    }
  });

  return { max, min };
};
