import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import queryString from 'query-string';
import { NavigationType } from '../../types/commonTypes';
import { DocumentsContext } from '../../types/contextTypes';
import { getOptionFromQueryString } from '../../utils/utils';

// Summary: The objective of this function is to analyze the query string that is in the
// browser when the user starts their session, and use that query string to update the context
// state
// 1. We do this by when the application is first mounted, if there is a query string, take that
// query string and use the parameter to update the corresponding state.

export default (
  filters: DocumentsContext['filters'],
  navigation: DocumentsContext['navigation'],
) => {
  const { documentType, uploadDate } = filters;
  const { setCurrentTab } = navigation;
  const previousDocumentOptions = usePrevious(documentType.options);
  const query = queryString.parse(useLocation().search);
  const queryKeys = Object.keys(query);

  // *1
  //  Note: We want to do this on first mount, but have to wait for the document type options to
  //  load. This means that we need to perform this action when we get documentType options for
  //  the first time (and when `previousDocumentOptions` = undefined | []).
  useEffect(() => {
    const key = queryKeys[0];
    if (
      key &&
      key === 'documentType' &&
      !previousDocumentOptions?.length &&
      documentType?.options?.length > 0
    ) {
      setCurrentTab(NavigationType.DOCUMENT_TYPE);
      documentType.setState(getOptionFromQueryString(documentType.options, query[key] as string));
    }
  }, [documentType.options, previousDocumentOptions]);

  // ToDo: will handle pulling property from query string in later pr, see this slack message:
  // https://archipelago-analytics.slack.com/archives/C02D4GSPCF4/p1633189044009500
  // // ToDo: add comment or noted comment
  // useEffect(() => {
  //   const key = queryKeys[0];
  //   if (key && key === 'property') {
  //     setCurrentTab(NavigationType.PROPERTY);
  //     property.setState(getOptionFromQueryString(property.options, query[key] as string));
  //   }
  // }, []);

  // *1
  useEffect(() => {
    const key = queryKeys[0];
    if (key && key === 'uploadDate') {
      setCurrentTab(NavigationType.UPLOAD_DATE);
      uploadDate.setState(getOptionFromQueryString(uploadDate.options, query[key] as string));
    }
  }, []);
};
