import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors } from 'ui';
import { IAttributeProvenance } from '@app/queries/properties/types';
import PropertiesGridTooltip from '../PropertiesGrid/PropertiesGridTooltip/PropertiesGridTooltip';
import columnDefinitions from './columns';
import { IColumn } from './types';

const Container = styled.div`
  label: GroupedRowContainer;
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  align-items: center;

  > label {
    flex: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.0025em;

    /* Gray 1 */
    color: #333333;
  }
`;

interface IColumnProps {
  column: IColumn;
}

const Column = styled.div<IColumnProps>`
  label: GroupedRow ${({ column }) => column.type}Column;
  max-width: 140px;
  margin: ${({ column }) => (column.type === 'spark' ? 0 : '0 22px 0 0')};
  width: ${({ column }) => column.width}px;
  padding: 7px 0;
  height: 100%;
  overflow-y: visible;

  > label {
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.004em;

    /* Gray 1 */
    color: #333333;
  }

  ${({ column }) => {
    if (column.type === 'spark') {
      return `
        width: 100px;
        height: 60px;
        margin-top: 15px;
        position: relative;
        padding: 0;

        label {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;

          font-weight: 600;
          font-size: 9px;
          line-height: 130%;
          /* identical to box height, or 12px */
          display: flex;
          align-items: flex-end;
          text-align: right;
          letter-spacing: 0.0025em;

          /* Blue 1 */
          color: ${colors.eui.primary};
        }
      `;
    }

    if (column.type === 'donut') {
      return `
        width: ${column.width || 80}px;
        display: flex;
        min-height: 84px;
        flex-direction: column;
      `;
    }

    return '';
  }}
`;

const Label = styled.label`
  lable: GroupedRowLabel;
  width: 200px;
`;

const Underline = styled.span`
  border-bottom: 2px solid rgb(204, 204, 204);
`;

interface IProps {
  label: string;
  columns: Array<IColumn>;
  provenance?: IAttributeProvenance | undefined;
  isToolbar?: boolean;
}

const GroupedRow: FC<IProps> = ({ label, columns, provenance, isToolbar = false }) => {
  // It may be possible to get a data type for the grouped column but currently
  // that does not seem to exist without querying the attributeMetadata. The replace is
  // probably more performant for now.
  const formattedLabel = label.replace('true', 'Yes').replace('false', 'No');

  return (
    <Container>
      {provenance?.sources ? (
        <Label data-testid="total-bar-label">
          <PropertiesGridTooltip provenance={provenance} handleDocumentDownload={() => {}}>
            <Underline onClick={(e) => e.preventDefault()}>{formattedLabel}</Underline>
          </PropertiesGridTooltip>
        </Label>
      ) : (
        <Label data-testid="total-bar-label">{formattedLabel}</Label>
      )}
      {columns.map((column, key) => {
        if (!columnDefinitions[column.type]) {
          throw new Error(`Invalid column specified: ${column.type}`);
        }
        const ColumnComponent = columnDefinitions[column.type] as FC<any>;

        if (!column.data && isToolbar) {
          return null;
        }

        if (column.type === 'spark') {
          const effectiveData = column.data.filter((d) => d !== null);

          const stopRender = effectiveData.length < 2;

          if (stopRender) {
            return null;
          }
        }

        return (
          <Column key={key} column={column}>
            <ColumnComponent column={column} />
          </Column>
        );
      })}
    </Container>
  );
};

export default GroupedRow;
