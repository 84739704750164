import styled from '@emotion/styled';

export const Container = styled.div`
  .coloredCol {
    padding: 0 !important;
  }

  h4 {
    font-weight: bold;
  }
`;
