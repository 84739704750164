import { number } from 'yup';
import { PropertyRecommendationStatusResult } from '@app/graphql/types';
import { IGraphQLSnapshot } from '../types';

export interface IDeleteSnapshotInput {
  snapshotId: string;
  updateReportingViews: boolean;
}

export interface IUpdateSnapshotConfigInput {
  name: string;
  snapshotId: string;
  streamSlug: string;
  description?: string;
  bound: boolean;
  visible: boolean;
  displayDate?: string;
}

export interface IGetSnapshotConfigVariables {
  streamSlug: string;
}

type IndexCalculationStatus = 'QUEUED' | 'STARTED' | 'RETRYING' | 'COMPLETED' | 'FAILED';
export const isIndexCalculationStatusPending = (i: IndexCalculationStatus): boolean =>
  i === 'QUEUED' || i === 'STARTED' || i === 'RETRYING';

type ExposureGrade = 'VERY_LOW' | 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH';

export interface ExposureIndexStatus {
  status: IndexCalculationStatus;
  index: number | null;
  indexGrade: ExposureGrade | null;
}
export interface SnapshotInfo {
  snapshot: IGraphQLSnapshot;
  exposureStatus: ExposureIndexStatus;
  propertyRecommendationStatus: PropertyRecommendationStatusResult;
}

interface ISnapshotConfig {
  snapshots: Array<IGraphQLSnapshot>;
  snapshotsInfo: Array<SnapshotInfo>;
  publishedSnapshotId: string | null;
}
export interface IGetSnapshotConfigData {
  snapshotConfig: ISnapshotConfig;
}

export interface PublishStreamSnapshotInput {
  streamSlug: string;
  snapshotId: string | null;
}

export interface IMutationVariables<T> {
  input: T;
}
