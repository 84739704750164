"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartColors = exports.dataViz = void 0;
var archipelagoGreen = {
    archipelagoGreen1: '#B8E0D9',
    archipelagoGreen2: '#76CBBB',
    archipelagoGreen3: '#31A791',
    archipelagoGreen4: '#1A7D6A',
    archipelagoGreen5: '#136C5C',
};
var gray = {
    gray1: '#E0E6EB',
    gray2: '#C1CDD7',
    gray3: '#A2B4C3',
    gray4: '#839BAF',
    gray5: '#64829B',
    gray6: '#50687C',
    gray7: '#3C4E5D',
    gray8: '#28343E',
    gray9: '#141A1F',
    gray10: '#000000',
};
var teal = {
    teal1: '#C9E2E9',
    teal2: '#9CCFDE',
    teal3: '#6CBDD5',
    teal4: '#2698BB',
    teal5: '#186F89',
};
var blue = {
    blue1: '#BCC9E6',
    blue2: '#86A0D7',
    blue3: '#4F76C8',
    blue4: '#274E9C',
    blue5: '#153375',
};
var purple = {
    purple1: '#D3C5E1',
    purple2: '#B29ACB',
    purple3: '#916FB5',
    purple4: '#663B92',
    purple5: '#48256E',
};
var pink = {
    pink1: '#EBC4D9',
    pink2: '#E8A6C8',
    pink3: '#DB70A8',
    pink4: '#C0307A',
    pink5: '#8C1251',
};
var red = {
    red1: '#EEC8C4',
    red2: '#E9A39B',
    red3: '#E16E61',
    red4: '#C62D1C',
    red5: '#921D10',
};
var orange = {
    orange1: '#F7D7BA',
    orange2: '#F2BC8C',
    orange3: '#F19B50',
    orange4: '#F1801E',
    orange5: '#BD5D0A',
};
var yellow = {
    yellow1: '#F9E8AF',
    yellow2: '#F7DD88',
    yellow3: '#E2B236',
    yellow4: '#CF9117',
    yellow5: '#AA7409',
};
var brown = {
    brown1: '#E3D7BE',
    brown2: '#CDBB98',
    brown3: '#AD9468',
    brown4: '#8B744B',
    brown5: '#635336',
};
var lime = {
    lime1: '#E0EFBF',
    lime2: '#C7DE95',
    lime3: '#ADCB6C',
    lime4: '#85AE2C',
    lime5: '#648813',
};
exports.dataViz = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, archipelagoGreen), gray), teal), blue), purple), pink), red), orange), yellow), brown), lime);
exports.chartColors = [
    exports.dataViz.archipelagoGreen3,
    exports.dataViz.yellow2,
    exports.dataViz.pink2,
    exports.dataViz.blue3,
    exports.dataViz.orange3,
    exports.dataViz.teal3,
    exports.dataViz.purple3,
    exports.dataViz.lime3,
    exports.dataViz.brown2,
    exports.dataViz.red3,
    exports.dataViz.gray1,
];
