import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { EuiImage } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';

interface Props {
  src: string;
  alt: string;
  size?: string;
  allowFullScreen?: boolean;
}

export const ProtectedImage: React.FC<Props> = ({ src, alt, size, allowFullScreen }) => {
  const [imageURL, setImageURL] = useState<string>(null);
  const { getIdToken } = useAuth();
  const token = getIdToken ? getIdToken() : null;
  useEffect(() => {
    if (!!token && !!src) {
      fetch(src, { headers: [['Authorization', `Bearer ${token}`]], redirect: 'follow' })
        .then((data) => data.blob())
        .then((blob) => setImageURL(URL.createObjectURL(blob)))
        .catch((err) => console.error('.ProtectedImage: error', src, err));
    }
  }, [token, src]);
  return <EuiImage src={imageURL} alt={alt} size={size} allowFullScreen={allowFullScreen} />;
};
