import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CasualtyFragmentDoc } from '../../fragments/__generated__/casualty.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCasualtyMutationVariables = Types.Exact<{
  input: Types.CreateCasualtyInput;
}>;


export type CreateCasualtyMutation = { __typename?: 'Mutation', createCasualty: { __typename?: 'Casualty', id: string, organizationId: string, orgName: string, accountOrgId: string, accountOrgName: string, accountStatus?: string | null, analysisDate?: any | null, clientName?: string | null, clientNetWorth?: number | null, clientProspect?: Types.ClientProspect | null, typeOfBusiness?: string | null, sicCode?: string | null, naicsCode?: string | null, analysisNotes?: string | null, primaryContactEmail?: string | null, office?: string | null, region?: string | null, secondaryContactEmail?: string | null, requestedLinesOfBusiness?: Array<{ __typename?: 'requestedLinesOfBusiness', lineOfBusiness?: Types.LineOfBusiness | null, retention?: number | null, limit?: number | null, renewalOrRfpEffectiveDate?: any | null }> | null, exposures?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, exposureBase?: { __typename?: 'exposureBase', al?: string | null, wc?: string | null, gl?: Types.ExposureBaseInputGl | null } | null, retentions?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, deductibleAggregateLimit?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, policyEffectiveDates?: Array<{ __typename?: 'policyEffectiveDates', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: any | null, wc?: any | null, gl?: any | null }> | null } };


export const CreateCasualtyDocument = gql`
    mutation CreateCasualty($input: CreateCasualtyInput!) {
  createCasualty(input: $input) {
    ...casualty
  }
}
    ${CasualtyFragmentDoc}`;
export type CreateCasualtyMutationFn = Apollo.MutationFunction<CreateCasualtyMutation, CreateCasualtyMutationVariables>;

/**
 * __useCreateCasualtyMutation__
 *
 * To run a mutation, you first call `useCreateCasualtyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCasualtyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCasualtyMutation, { data, loading, error }] = useCreateCasualtyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCasualtyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCasualtyMutation, CreateCasualtyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCasualtyMutation, CreateCasualtyMutationVariables>(CreateCasualtyDocument, options);
      }
export type CreateCasualtyMutationHookResult = ReturnType<typeof useCreateCasualtyMutation>;
export type CreateCasualtyMutationResult = Apollo.MutationResult<CreateCasualtyMutation>;
export type CreateCasualtyMutationOptions = Apollo.BaseMutationOptions<CreateCasualtyMutation, CreateCasualtyMutationVariables>;