import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyDeletePropertiesJobInputMutationVariables = Types.Exact<{
  input: Types.ApplyDeletePropertiesJobInput;
}>;


export type ApplyDeletePropertiesJobInputMutation = { __typename?: 'Mutation', applyDeletePropertiesJob: { __typename?: 'Job', id: string } };


export const ApplyDeletePropertiesJobInputDocument = gql`
    mutation ApplyDeletePropertiesJobInput($input: ApplyDeletePropertiesJobInput!) {
  applyDeletePropertiesJob(input: $input) {
    id
  }
}
    `;
export type ApplyDeletePropertiesJobInputMutationFn = Apollo.MutationFunction<ApplyDeletePropertiesJobInputMutation, ApplyDeletePropertiesJobInputMutationVariables>;

/**
 * __useApplyDeletePropertiesJobInputMutation__
 *
 * To run a mutation, you first call `useApplyDeletePropertiesJobInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDeletePropertiesJobInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDeletePropertiesJobInputMutation, { data, loading, error }] = useApplyDeletePropertiesJobInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyDeletePropertiesJobInputMutation(baseOptions?: Apollo.MutationHookOptions<ApplyDeletePropertiesJobInputMutation, ApplyDeletePropertiesJobInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyDeletePropertiesJobInputMutation, ApplyDeletePropertiesJobInputMutationVariables>(ApplyDeletePropertiesJobInputDocument, options);
      }
export type ApplyDeletePropertiesJobInputMutationHookResult = ReturnType<typeof useApplyDeletePropertiesJobInputMutation>;
export type ApplyDeletePropertiesJobInputMutationResult = Apollo.MutationResult<ApplyDeletePropertiesJobInputMutation>;
export type ApplyDeletePropertiesJobInputMutationOptions = Apollo.BaseMutationOptions<ApplyDeletePropertiesJobInputMutation, ApplyDeletePropertiesJobInputMutationVariables>;