import React from 'react';
import { PropertyTabContainer } from './PropertyTab.emotion';
import { PropertyTabReport } from './PropertyTabReport';

interface Props {
  workspaceID: string;
}

export const PropertyTab: React.FC<Props> = ({ workspaceID }) => (
    <PropertyTabContainer hasShadow>
      <PropertyTabReport workspaceID={workspaceID} />
    </PropertyTabContainer>
  );
