import React, { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  EuiFieldSearch,
  EuiFieldSearchProps,
  EuiFlexItem,
  EuiInputPopover,
  EuiListGroup,
  EuiListGroupItem,
  Icon,
} from 'ui';
import { GOOGLE_MAPS_API_KEY } from '@app/config';
import { GoogleLogoText, PredictionGroup, PredictionText } from './GoogleAutocompleteInput.emotion';
import { AddressObjectType } from './types';
import { makeAddressObject } from './utils';

export interface IGoogleAutocompleteInput
  extends Omit<EuiFieldSearchProps, 'onChange' | 'onSelect'> {
  onChange?: (value: string) => void;
  onSelect: (address: AddressObjectType, placeDetails: google.maps.places.PlaceResult) => void;
  fields?: string[];
  countryRestrictions?: string[];
  predictionTypes?: string[];
}

export const GoogleAutocompleteInput = ({
  name,
  onChange,
  onSelect,
  value,
  fields = [],
  countryRestrictions = [],
  predictionTypes = [],
  placeholder,
  isClearable = false,
  disabled = false,
  fullWidth = false,
  ...rest
}: IGoogleAutocompleteInput) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    refreshSessionToken,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: 500,
    sessionToken: true,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedPrediction, setSelectedPrediction] =
    useState<google.maps.places.AutocompletePrediction>();

  useEffect(() => {
    if (placePredictions?.length) {
      setIsPopoverOpen(true);
    } else {
      setIsPopoverOpen(false);
    }
  }, [placePredictions]);

  useEffect(() => {
    if (selectedPrediction) {
      placesService?.getDetails(
        {
          fields: [
            ...['address_components', 'formatted_address', 'geometry', 'place_id'],
            ...fields,
          ],
          placeId: selectedPrediction.place_id,
        },
        (placeDetails) => {
          const address = makeAddressObject(placeDetails);
          onSelect(address, placeDetails);
          refreshSessionToken();
        },
      );
    }
  }, [selectedPrediction]);

  const searchInput = (
    <EuiFieldSearch
      id="autocomplete-field-search"
      aria-label="Google Autocomplete Text Input"
      fullWidth={fullWidth}
      isClearable={isClearable}
      isLoading={isPlacePredictionsLoading}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        if (value !== event.target.value) {
          getPlacePredictions({
            componentRestrictions: { country: countryRestrictions },
            input: event.target.value,
            types: predictionTypes,
          });
        }
        onChange && onChange(event.target.value);
      }}
      onFocus={() => {
        if (placePredictions?.length) {
          setIsPopoverOpen(true);
        }
      }}
      {...rest}
    />
  );
  return (
    <EuiInputPopover
      input={searchInput}
      isOpen={isPopoverOpen}
      closePopover={() => {
        setIsPopoverOpen(false);
      }}
      disableFocusTrap
      ownFocus={false}
      initialFocus="#autocomplete-field-search"
      fullWidth={fullWidth}
    >
      <EuiListGroup bordered={false} flush>
        {placePredictions.map((prediction) => (
          <EuiListGroupItem
            key={prediction.place_id}
            label={
              <PredictionGroup direction="column" gutterSize="none">
                <EuiFlexItem grow={0}>
                  <PredictionText>{prediction.structured_formatting.main_text}</PredictionText>
                </EuiFlexItem>
                <EuiFlexItem grow={0}>
                  <PredictionText>{prediction.structured_formatting.secondary_text}</PredictionText>
                </EuiFlexItem>
              </PredictionGroup>
            }
            icon={<Icon name="mapPin" />}
            onClick={() => {
              setSelectedPrediction(prediction);
              setIsPopoverOpen(false);
            }}
            toolTipText={prediction.description}
            showToolTip
          />
        ))}
      </EuiListGroup>
      <GoogleLogoText>Google</GoogleLogoText>
    </EuiInputPopover>
  );
};
