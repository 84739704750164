import React from 'react';
import styled from '@emotion/styled';

export const FormHeader = styled.div`
  label: DropdownHeader;
  font-size: 14px;
  width: 385px;
  padding: 0px;
  margin: 0px;
`;

export const FormFooter = styled.div`
  label: DropdownFooter;
  font-size: 14px;
  width: 385px;
  padding: 0px;
  margin: 0px;
  min-height: 40px;
`;

export const OptionImg = styled.div`
  && {
    flex: 0 0 auto;
    margin: 6px 10px 0px 0px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;

const Svg = (p: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

export const ChevronDown = (props: any) => (
  <Svg style={{ marginRight: -6 }} {...props}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);
