import { Dispatch, SetStateAction } from 'react';
import { PanelItem } from '@app/cx/Stream/ManageStreamButtonPanels/types';
import useRestoreDeletedPropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestoreDeletedPropertiesPanel';
import useRestoreDisposalPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestoreDisposedPanel';
import { useStreamContext } from '../StreamProvider';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ setOpen }: Props): PanelItem => {
  const { permissions } = useStreamContext();

  const RestoreDisposalPanel = useRestoreDisposalPanel({ setOpen });

  const restorePanels = [RestoreDisposalPanel];

  if (permissions.canDeleteProperties) {
    const RestoreDeletedPropertiesPanel = useRestoreDeletedPropertiesPanel({
      setOpen,
    });
    restorePanels.push(RestoreDeletedPropertiesPanel);
  }

  return {
    'data-testid': 'restore-properties-button',
    iconName: 'gear',
    label: 'Restore',
    nestedPanel: {
      items: restorePanels,
      title: 'Restore',
    },
  };
};
