import React from 'react';
import moment, { Moment } from 'moment';
import { EuiDatePicker } from 'ui';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const DateField: React.FC<Props> = ({ onChange, value }) => (
  <EuiDatePicker
    fullWidth
    dateFormat="MM/DD/YYYY"
    onChange={(date: Moment) => onChange(moment(date).utc().format('YYYY-MM-DD'))}
    value={moment(value).utc().format('YYYY-MM-DD')}
  />
);

export default DateField;
