import { Extension } from './types';

export default (extension: string) => {
  const extensions = {
    csv: Extension.Spreadsheet,
    doc: Extension.Word,
    docb: Extension.Word,
    docx: Extension.Word,
    dot: Extension.Word,
    dotm: Extension.Word,
    dotx: Extension.Word,
    dwg: Extension.Other,
    jfif: Extension.Image,
    jpeg: Extension.Image,
    jpg: Extension.Image,
    msg: Extension.Other,
    pcp: Extension.Other,
    pdf: Extension.PDF,
    png: Extension.Image,
    pptx: Extension.Other,
    tif: Extension.Image,
    tiff: Extension.Image,
    wbk: Extension.Word,
    webp: Extension.Image,
    xls: Extension.Spreadsheet,
    xlsb: Extension.Spreadsheet,
    xlsm: Extension.Spreadsheet,
    xlsx: Extension.Spreadsheet,
    xlt: Extension.Spreadsheet,
    xltm: Extension.Spreadsheet,
    xltx: Extension.Spreadsheet,
    xml: Extension.Spreadsheet,
    zip: Extension.Other,
  };

  const finalExtension = extension?.toLowerCase().startsWith('pdf')
    ? 'pdf'
    : extension?.toLowerCase();

  return extensions[finalExtension] || Extension.Other;
};
