import React, { Dispatch, SetStateAction, useContext } from 'react';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { PanelItem } from '@app/cx/Stream/ManageStreamButtonPanels/types';
import RestoreDisposedPropertiesFlyout from '@app/cx/Stream/RestoreDisposedPropertiesFlyout';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { getMetadataByType } from '@app/cx/Stream/utils';
import { useTracker } from '@app/hooks/useTracker';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ setOpen }: Props): PanelItem => {
  const mixpanel = useTracker('mixpanel');
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, propertyAttributeMetadata, metadataMap } = useStreamContext();
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');

  const handleClick = () => {
    mixpanel.track('Restore Disposed Properties - Edit', {
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <RestoreDisposedPropertiesFlyout
        metadataMap={metadataMap}
        orgID={stream?.organizationId as string}
        orgName={stream?.orgName as string}
        filterable={filterable}
        streamSlug={stream?.slug || ''}
      />,
    );

    setOpen(false);
  };

  return {
    'data-testid': 'restore-disposed-properties-button',
    iconName: 'trash',
    label: 'Restore disposed properties',
    onClick: handleClick,
  };
};
