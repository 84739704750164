import React from 'react';
import {
  DragDropContextProps,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  Icon,
} from 'ui';
import { useManageExportsContext } from './ManageExportsContext';

export const ManageExportsOrder = () => {
  const { orderableOptions, setOrderableOptions } = useManageExportsContext();

  const onDragEnd: DragDropContextProps['onDragEnd'] = ({ source, destination }) => {
    if (source && destination) {
      const newOrderedOptions = euiDragDropReorder(
        orderableOptions,
        source.index,
        destination.index,
      );
      setOrderableOptions(newOrderedOptions);
    }
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem grow={0}>
        <EuiText>
          <h4>Re-order Attributes</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable droppableId="orderable-drop-area" spacing="s">
          {orderableOptions?.map((attribute, index) => (
            <EuiDraggable spacing="s" key={attribute.id} index={index} draggableId={attribute.id}>
              {(provided, state) => (
                <EuiPanel hasShadow={state.isDragging} hasBorder={false} paddingSize="s">
                  <EuiFlexGroup alignItems="center" gutterSize="s">
                    <Icon name="menu" />
                    <EuiText>{`${index + 1}. ${attribute.label}`}</EuiText>
                  </EuiFlexGroup>
                </EuiPanel>
              )}
            </EuiDraggable>
          ))}
        </EuiDroppable>
      </EuiDragDropContext>
    </EuiFlexGroup>
  );
};
