"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var eui_1 = require("@elastic/eui");
var css_1 = require("@emotion/css");
var colors_1 = require("../../../neutrons/colors/colors");
var buttonEmptyColor = function (color) { return (0, css_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", " !important;\n  &:focus {\n    background-color: ", " !important;\n  }\n"], ["\n  color: ", " !important;\n  &:focus {\n    background-color: ", " !important;\n  }\n"])), (0, eui_1.makeHighContrastColor)(colors_1.colors[color])(colors_1.shadeColors.white), (0, eui_1.transparentize)(colors_1.colors[color], 0.1)); };
exports.default = {
    buttonEmptyColor: buttonEmptyColor,
};
var templateObject_1;
