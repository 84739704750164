import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EuiDatePicker, EuiDatePickerProps, EuiFormRow } from 'ui';

interface IDatePickerFieldProps {
  id: string;
  selected: EuiDatePickerProps['selected'];
  onChange: EuiDatePickerProps['onChange'];
  required: boolean;
  isLoading: boolean;
}

export const DatePickerField = ({
  id,
  selected,
  onChange,
  required,
  isLoading,
}: IDatePickerFieldProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  register(id);
  const errorMessage = errors[id]?.message;

  return (
    <Controller
      control={control}
      name={id}
      rules={required ? { required: 'Date is required' } : {}}
      render={({ field }) => (
        <EuiFormRow isInvalid={Boolean(errorMessage)} error={errorMessage}>
          <EuiDatePicker
            isLoading={isLoading}
            inputRef={field.ref}
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            selected={selected}
            onChange={(date) => {
              field.onChange(date);
              onChange(date);
            }}
            fullWidth
            placeholder="MM/DD/YYYY"
            dateFormat="MM/DD/YYYY"
          />
        </EuiFormRow>
      )}
    />
  );
};
