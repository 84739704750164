import { useHistory } from 'react-router-dom';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { shouldRedirectToUpload } from '../../utils';

interface Props {
  isAdmin: boolean;
  path: string | string[];
}

export default ({ isAdmin, path }: Props) => {
  const { account } = useAuth();
  const history = useHistory();

  // `account?.docUploadOrgs` = an array of all the orgs that the user has some level of permissions on.
  const accountUploadOrgs = account?.managedOrgs || [];

  // If we should redirect the user to the upload portal, do so
  if (shouldRedirectToUpload(accountUploadOrgs, path, isAdmin)) {
    history.replace({
      pathname: '/upload',
      search: null,
    });
  }
};
