import React, { FC } from 'react';
import { AttributeScoresData } from '@onarchipelago/cx/Reports/queries/getAttributeScores';
import ChartTitle from '@app/cx/Reports/Charts/ChartTitle/ChartTitle';
import { attributeMappings } from '../constants';
import { Group } from '../hooks/useDataInsights';
import { AttributeInsightDisplay } from './AttributeInsightDisplay';

interface IProps {
  data: AttributeScoresData;
  group: Group;
  title: string;
}

const getScoresByKey = (key: string, dataSource: any) => Object.keys(attributeMappings[key])
    .map((attrKey) => {
      const item = (dataSource || []).find((item) => item.attributeName === attrKey);

      if (!item) {return undefined;}

      return {
        ...item,
        label: attributeMappings[key][item?.attributeName],
      };
    })
    .filter((item) => item);

export const AttributeInsightContainer: FC<IProps> = ({ title, group, data }) => {
  if (!data?.qualityAttribute || data.qualityAttribute.length === 0)
    {return (
      <div style={{ height: '300px' }}>
        <ChartTitle>There are no U.S. properties exposed to {title.toLocaleLowerCase()}</ChartTitle>
      </div>
    );}

  const atts = getScoresByKey(group, data?.qualityAttribute);

  const scoreReducer = (acc, current) => {
    let quality = current.scoreQuality === 'None' ? 'Incomplete' : 'Complete';
    if (title === 'geocode') {
      quality = current.scoreQuality === 'Precise' ? 'Complete' : 'Incomplete';
    }
    const score = acc[quality] || { locations: 0, tiv: 0, tivPct: 0 };

    score.locations += current.locations;
    score.tiv += current.tiv;
    score.tivPct += current.tivPct;

    acc[quality] = score;

    return { ...acc };
  };

  const attributeBars = (atts || []).map((item: any) => ({
      ...item,
      label: item.label,
      scores: item.scores.reduce(scoreReducer, {}),
    }));

  return <AttributeInsightDisplay title={title} bars={attributeBars} />;
};
