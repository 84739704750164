export const NEGATIVE_LABELS = [
  '< -100%',
  '-100% to -75%',
  '-75% to -50%',
  '-50% to -25%',
  '-25% to 0%',
];

export const POSITIVE_LABELS = ['0% to 25%', '25% to 50%', '50% to 75%', '75% to 100%', '> 100%'];

export const X_AXIS_LABELS = [...NEGATIVE_LABELS, ...POSITIVE_LABELS];
