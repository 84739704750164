import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const StyledPanel = styled(EuiPanel)`
  background-color: #fff;
  margin: 8px 0px 0px;
  height: 50px;
  box-shadow: none;
  font-size: 12px;
`;

export const StyledContainer = styled.div`
  border-radius: 4px;
  border: 1px dashed #d0d1d7;
  display: flex;
  padding: 12px;
  font-size: 12px;
  &:hover {
    background-color: rgba(15, 118, 110, 0.1);
  }
`;

export const StyledDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #9170b8;

  svg {
    margin-right: 4px;
  }
`;
