import EngagementReport from './EngagementReport/EngagementReport';
import EnrichmentReport from './EnrichmentReport/EnrichmentReport';
import ExposureSummaryReport from './ExposureSummaryReport/ExposureSummaryReport';
import UnderwritingAssessmentReport from './UnderwritingAssessmentReport/UnderwritingAssessmentReport';
import ValuationSummaryReport from './ValuationSummaryReport/ValuationSummaryReport';
import ZurichReport from './ZurichReport/ZurichReport';

export const REPORTS = [
  {
    Component: ExposureSummaryReport,
    id: 'ExposureSummary',
    name: 'Exposure Summary',
    order: 1,
    type: 'exposure',
  },
  {
    Component: EnrichmentReport,
    id: 'EnrichmentReport',
    name: 'Enrichment',
    order: 3,
    type: 'enrichment',
  },
  {
    Component: ValuationSummaryReport,
    id: 'ValuationSummary',
    name: 'Valuation Summary',
    order: 4,
    type: 'valuations',
  },
  {
    Component: EngagementReport,
    id: 'EngagementReport',
    name: 'Engagement',
    order: 5,
    type: 'engagement',
  },
  {
    Component: UnderwritingAssessmentReport,
    id: 'RMAssessment',
    name: 'Risk Assessment',
    order: 6,
    type: 'insurer',
  },
  {
    Component: ZurichReport,
    id: 'ZurichUwAR',
    name: 'Zurich Transparency',
    order: 7,
    type: 'zurich',
  },
];

export const REPORT_TOOLTIPS = [
  {
    id: 'ExposureSummary',
    toolTipContent: 'This portfolio’s risk level based on exposure to perils',
  },
  {
    id: 'DQD',
    toolTipContent: 'Overall health of this portfolio’s property data',
  },
  {
    id: 'ValuationSummary',
    toolTipContent: 'How this portfolio’s building values compare to market expectations',
  },
  {
    id: 'RMAssessment',
    toolTipContent: 'Risk characteristics this portfolio presents to underwriters',
  },
  {
    id: 'ZurichUwAR',
    toolTipContent: 'Risk driver information using data provided by Zurich',
  },
];
