import { useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { JobStatus, Status } from '@app/graphql/jobs/jobs.types';
import { useGetJobLazyQuery } from '@app/graphql/jobs/queries/__generated__/getJob.generated';

export type PollJobOptionsType = {
  // Time between polls
  interval?: number;
  // Number of times to retry before stopping
  retryCount?: number;
  // Array of statuses to stop on and call the callback function
  targetStatus?: Array<JobStatus>;
  // callback that runs on graphql error
  onError?: (error: ApolloError) => void;
  // callback that runs when the retryCount is reached
  onTimeout?: () => void;
};

const defaultOptions: PollJobOptionsType = {
  interval: 3000,
  retryCount: 20,
  targetStatus: [JobStatus.Complete],
};

/***
 * Hook to poll for jobs using the Job graphql query.
 * Parameters:
 *   callback: the callback function that is run when a target job status is successfully queried.
 *   options: a list of options for polling that are defined in the typing above.
 *
 * Returns:
 *   isPolling: boolean for if the polling is in progress
 *   startGetJobPoll: function that takes in a jobID string to trigger polling.
 */
export const useJobPoller = (callback: () => void, options?: PollJobOptionsType) => {
  const jobsApolloClient = useJobsApolloClient();
  const [currentRetryCount, setCurrentRetryCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);

  const combinedOptions = { ...defaultOptions, ...(options || {}) };
  const { interval, retryCount, targetStatus, onError, onTimeout } = combinedOptions;

  const [getJob, { startPolling, stopPolling }] = useGetJobLazyQuery({
    client: jobsApolloClient,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const targetStatusCompleted = targetStatus.some((status) =>
        data?.job?.lifeCycle?.some(
          (lifeCycle) => lifeCycle?.to === status && lifeCycle?.progress === Status.Completed,
        ),
      );
      if (targetStatusCompleted) {
        callback();
        stopPolling();
        setIsPolling(false);
      } else if (currentRetryCount >= retryCount) {
        onTimeout && onTimeout();
        stopPolling();
        setIsPolling(false);
      } else {
        startPolling(interval);
        setCurrentRetryCount(currentRetryCount + 1);
      }
    },
    onError,
  });

  const startGetJobPoll = useCallback(
    (jobID: string) => {
      setIsPolling(true);
      setCurrentRetryCount(0);
      getJob({
        variables: {
          jobID,
        },
      });
    },
    [getJob],
  );

  return { isPolling, startGetJobPoll };
};
