import { Role } from '@app/graphql/types';

export const displayRoleName = (role: string): string => {
  switch (role) {
    case Role.EnterpriseAdmin:
      return 'Enterprise Owner';
    case Role.RiskManager:
      return 'Admin';
    case Role.PropertyEditor:
      return 'Contributor';
    case Role.StreamViewer:
      return 'Reviewer';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};

export const internalRoleName = (role: string): string => {
  switch (role) {
    case Role.EnterpriseAdmin:
      return 'EnterpriseOwner';
    case Role.RiskManager:
      return 'Admin';
    case Role.PropertyEditor:
      return 'Contributor';
    case Role.StreamViewer:
      return 'Reviewer';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};
