import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from 'ui';
import EmptyMessage from '@app/components/EmptyMessage/EmptyMessage';
import { ReportProtectedModalRoutes } from '@app/containers/App/Routes/ProtectedModalRoutes';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import {
  IntegrationRunProvider,
  useIntegrationRunProvider,
} from '@app/platform/Integrations/IntegrationRunProvider';
import { filtersStyle, reportPageStyle } from '../reports.emotion';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import { ReportProps } from '../types';
import { Filters } from './Filters/Filters';
import { PropertyDistributionHistogram } from './PropertyDistributionHistogram/PropertyDistributionHistogram';
import ProvidedvsE2VALUESummary from './ProvidedvsE2ValueSummary/ProvidedvsE2ValueSummary';
import { ValuationSummaryTable } from './Table/ValuationSummaryTable';
import {
  useValuationSummaryReportContext,
  ValuationSummaryReportProvider,
} from './ValuationSummaryReportContext';

const ValuationSummaryReport: FC<ReportProps> = ({ visible }) => {
  if (!visible) {
    return null;
  }

  const { tableData, loading } = useValuationSummaryReportContext();

  const { lastRun, enabled } = useIntegrationRunProvider();

  const mixpanel = useTracker('mixpanel');

  useEffect(() => {
    mixpanel.track('Open Report', {
      report_name: 'Valuation Summary',
    });
  }, []);

  let content;

  const noPropertyResultsContent = (
    <EmptyMessage
      header="No properties found."
      subText="There weren't any properties that match the filters you applied."
    />
  );

  const noIntegrationsRunContent = (
    <EmptyMessage
      header="No results yet."
      subText="The Valuation integration hasn't been run for this stream yet."
    />
  );

  const defaultContent = (
    <>
      <EuiFlexItem grow={false}>
        <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <ProvidedvsE2VALUESummary />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <PropertyDistributionHistogram />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={2}>
            <EuiPanel grow={false} paddingSize="l" hasShadow={false} style={{ display: 'flex' }}>
              <ValuationSummaryTable />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        {content}
      </EuiFlexItem>
    </>
  );

  if (loading) {
    content = defaultContent;
  } else if (tableData?.streamPropertyValuations?.properties.length === 0) {
    content = noPropertyResultsContent;
  } else if (!lastRun || !enabled) {
    content = noIntegrationsRunContent;
  } else {
    content = defaultContent;
  }

  let subtitle;
  if (loading) {
    subtitle = null;
  } else if (tableData?.streamPropertyValuations?.properties.length === 0 || !lastRun || !enabled) {
    subtitle = `Data as of ${moment(lastRun?.runDate).format('MMMM D, YYYY')}`;
  } else if (lastRun?.runDate) {
    subtitle = `Valuations integration last ran as of ${moment(lastRun?.runDate).format(
      'MMMM D, YYYY',
    )}`;
  }

  return (
    <EuiFlexGroup
      direction="column"
      responsive={false}
      style={reportPageStyle}
      data-testid="report-valuation-summary"
    >
      <EuiFlexGroup direction="column" data-testid="report-valuation-summary" gutterSize="s">
        <ReportsHeader
          header="Valuation Summary"
          subtitleDataTestId="valuation-report-subtitle"
          subtitle={subtitle}
        />
        <EuiFlexItem style={filtersStyle} grow={false}>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiFlexItem>
              <Filters />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      {content}
    </EuiFlexGroup>
  );
};

const ValuationSummaryReportWithContext: FC<ReportProps> = ({ visible }) => (
  <>
    {visible && (
      <StreamProvider>
        <>
          <ReportProtectedModalRoutes />
          <IntegrationRunProvider>
            <ValuationSummaryReportProvider>
              <ValuationSummaryReport visible={visible} />
            </ValuationSummaryReportProvider>
          </IntegrationRunProvider>
        </>
      </StreamProvider>
    )}
  </>
);
export default ValuationSummaryReportWithContext;
