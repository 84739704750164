import React from 'react';
import { getEdits, getProvenance, ICell } from './Cell';

const isUnitedStates = (country: string = '') =>
  ['US', 'USA', 'United States', 'United States of America'].includes(country);

export const getFormattedLocation = (country?: string, state?: string) => {
  if (isUnitedStates(country)) {
    if (state) {
      return [state, 'US'].join(', ');
    }

    return 'United States';
  }

  return country;
};

const Location = (props: ICell) => {
  const id = props?.column?.id;
  const property = props.prop;
  const provenance = getProvenance(property, id);
  const formatted = getFormattedLocation(property.country, property.state) || '-';

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <p>{formatted}</p>;

  return { body, tooltip };
};

export default Location;
