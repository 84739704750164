import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_DOCUMENTS_CLASSIFICATIONS_INFO } from '../../queries/getOrganizationDocumentsClassificationsInfo';
import { DocumentsContext } from '../../types/contextTypes';
import {
  OrganizationDocumentsClassificationsInfoData,
  OrganizationDocumentsClassificationsInfoVariables,
} from '../../types/queryTypes';

export default (orgName: string): DocumentsContext['data']['classifications'] => {
  const { data } = useQuery<
    OrganizationDocumentsClassificationsInfoData,
    OrganizationDocumentsClassificationsInfoVariables
  >(GET_ORGANIZATION_DOCUMENTS_CLASSIFICATIONS_INFO, {
    variables: {
      filter: {
        orgName,
      },
    },
  });

  if (data?.organizationDocumentsClassificationsInfo === null) {
    return null;
  }

  return data?.organizationDocumentsClassificationsInfo
    ?.map((classification) => classification.classification)
    .filter((classification) => !!classification && classification.length > 0);
};
