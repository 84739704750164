import { GetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';

type StreamType = GetStreamsQuery['streamsV2']['streams'];

const getMyPropertiesStream = (
  streams: StreamType,
  organizationName: string,
): StreamType[0] | undefined =>
  (streams || []).find((v) => v.isMyProperties && v.orgName === organizationName);

export const getSlug = (
  streams: StreamType,
  streamSlug: string,
  organizationName: string,
): string | undefined => {
  if (streamSlug) {
    return streamSlug;
  }
  return getMyPropertiesStream(streams || [], organizationName)?.slug;
};
