import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type StreamFragment = { __typename: 'Stream', broker?: string | null, documentsCount?: number | null, effectiveDate?: number | null, expiryDate?: number | null, highlights?: string | null, id: string, isPublic: number, name?: string | null, organizationId?: string | null, orgName?: string | null, propertiesCount?: number | null, policiesCount?: number | null, reit?: string | null, slug?: string | null, status?: string | null, totalInsuredValue?: number | null, updateStatus?: number | null, allExplorers: Array<string>, allowedExports?: Array<Types.ExportCode> | null, allowedReports?: Array<Types.ReportCode> | null, isMyProperties: boolean, stockThroughputEnabled: boolean, propertyStatusLabel?: string | null, defaultSnapshot?: string | null, displayCurrency?: string | null, industry?: string | null, businessType?: string | null, enabledFeatures?: Array<string> | null, snapshots?: Array<{ __typename?: 'Snapshot', type: Types.SnapshotType, id: string, name: string, date: any, description?: string | null, bound: boolean, displayDate?: any | null }> | null, streamSettings?: { __typename?: 'StreamSettings', unitsOfMeasurement?: string | null } | null, exposure?: { __typename?: 'ExposureMetrics', index: number, indexGrade: Types.ExposureGrade, attributeScores: Array<{ __typename?: 'ExposureAttributeMetrics', name: string, displayName: string, percentile: number, TIVPct: number, groupName: string, indexGrade: Types.ExposureGrade, gradeDefinitions: Array<{ __typename?: 'ExposureGradeDefinition', indexGrade: Types.ExposureGrade, industryName: string, minPercentileValue: number, maxPercentileValue: number, minPercentileThreshold: number, maxPercentileThreshold: number }> }>, rank: { __typename?: 'ExposureIndexRank', industryName: string, rank: number, streamCount: number, grades: Array<{ __typename?: 'ExposureGradeMetrics', indexGrade: Types.ExposureGrade, streamCount: number }> } } | null, quality?: { __typename?: 'QualityMetrics', score: number } | null };

export const StreamFragmentDoc = gql`
    fragment stream on Stream {
  __typename
  broker
  documentsCount
  effectiveDate
  expiryDate
  highlights
  id
  isPublic
  name
  organizationId
  orgName
  propertiesCount
  policiesCount
  reit
  slug
  status
  totalInsuredValue
  updateStatus
  allExplorers
  allowedExports
  allowedReports
  snapshots {
    type
    id
    name
    date
    description
    bound
    displayDate
  }
  isMyProperties
  stockThroughputEnabled
  propertyStatusLabel
  defaultSnapshot
  displayCurrency
  industry
  businessType
  enabledFeatures
  streamSettings {
    unitsOfMeasurement
  }
  exposure {
    index
    indexGrade
    attributeScores {
      name
      displayName
      percentile
      TIVPct
      groupName
      gradeDefinitions {
        indexGrade
        industryName
        minPercentileValue
        maxPercentileValue
        minPercentileThreshold
        maxPercentileThreshold
      }
      indexGrade
    }
    rank {
      industryName
      rank
      streamCount
      grades {
        indexGrade
        streamCount
      }
    }
  }
  quality {
    score
  }
}
    `;