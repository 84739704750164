import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

// Is used to get the attribute object from the `propertyAttributeMetadata` array given the attribute name
export const getAttribute = (
  propertyAttributeMetadata: IGraphQLAttributeMetadata[],
  attributeName: string,
): IGraphQLAttributeMetadata =>
  propertyAttributeMetadata?.filter((attribute) => attribute?.name === attributeName)[0];

// If the parent of an attribute has a parent on its own (the attribute has a grandparent), then the grandparent name
// will be returned. Otherwise, the parent name will be returned.
export const getUltimateParentName = (
  propertyAttributeMetadata: IGraphQLAttributeMetadata[],
  parentName?: string,
): string => {
  if (parentName) {
    const parentAttribute = getAttribute(propertyAttributeMetadata, parentName);

    return parentAttribute?.parent || parentName;
  }

  return parentName;
};

// If the attribute has a grandparent, return the grandparent attribute name, otherwise return null
export const getGrandparentAttributeName = (
  propertyMetadata: Array<IGraphQLAttributeMetadata>,
  attributeName: string,
): string => {
  const attribute = getAttribute(propertyMetadata, attributeName);

  if (!!attribute?.parent) {
    const parentAttribute = getAttribute(propertyMetadata, attribute.parent);

    return parentAttribute?.parent || null;
  }

  return null;
};

// Returns a boolean. Determines if an attribute is a grandchild or not (does it have a grandparent)
export const isGrandchildAttribute = (
  propertyAttributeMetadata: IGraphQLAttributeMetadata[],
  attributeName: string,
): boolean => {
  const attribute = getAttribute(propertyAttributeMetadata, attributeName);

  if (attribute?.parent) {
    const parentAttribute = getAttribute(propertyAttributeMetadata, attribute.parent);

    return !!parentAttribute?.parent;
  }

  return false;
};
