import { css } from '@emotion/css';

const formRow = css`
  height: 100%;
  .euiFormRow__fieldWrapper {
    height: 100%;
  }
`;

const textArea = css`
  height: 100% !important;
`;

export default {
  formRow,
  textArea,
};
