import { getColumnId } from '@app/components/Grid/Grid';
import { getUltimateParentName } from '@app/cx/utils/subvalues';
import { AttributeEditInfo, Provenance } from '@app/graphql/types';
import { IAttributeProvenance } from '@app/queries/properties/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import {
  ColumnsWithMetadata,
  ColumnsWithProvenance,
  ColumnType,
  IPropertiesGridColumn,
  IPropertiesGridColumnWithMetadata,
  IPropertiesGridProperty,
  IPropertiesGridPropertyAttributeProvenance,
  IPropertyWithProvenance,
  Metadata,
} from './types';

type GetId = (
  property: IPropertyWithProvenance | undefined,
  rowIndex: number,
  column: IPropertiesGridColumnWithMetadata | Array<IPropertiesGridColumnWithMetadata>,
) => string;
export const getId: GetId = (property, rowIndex, column) =>
  [
    property ? property.id : undefined,
    rowIndex,
    getColumnId<IPropertiesGridColumnWithMetadata>(column),
  ]
    .filter(Boolean)
    .join('-');

type GetMetadataForColumn = (
  column: IPropertiesGridColumnWithMetadata,
  metadata: Metadata,
) => undefined | IGraphQLAttributeMetadata;

const getMetadataForColumn: GetMetadataForColumn = (column, metadata) => metadata[column.id];

type GetColumnsWithMetadata = (
  columns: ColumnsWithProvenance,
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>,
) => ColumnsWithMetadata;

export const getColumnsWithMetadata: GetColumnsWithMetadata = (
  columns,
  propertyAttributeMetadata,
) => {
  const metadataObj = propertyAttributeMetadata.reduce(
    (obj, fieldMetadata) => ({
      ...obj,
      [fieldMetadata.name]: fieldMetadata,
    }),
    {},
  );

  return columns.reduce((arr: ColumnsWithMetadata, column) => {
    if (Array.isArray(column)) {
      const groupOfColumns = [
        column.map((child) => ({
          ...child,
          metadata: getMetadataForColumn(child, metadataObj),
        })),
      ];
      return arr.concat(groupOfColumns);
    }

    return arr.concat({
      ...column,
      metadata: getMetadataForColumn(column, metadataObj),
    });
  }, [] as ColumnsWithMetadata);
};

export const getProvenanceAsObj = (
  provenances: Array<IPropertiesGridPropertyAttributeProvenance> | Array<Provenance>,
): {
  [index: string]: IAttributeProvenance;
} =>
  // FIX ME
  // @ts-ignore
  provenances.reduce(
    (obj, provenance) => ({
      ...obj,
      [provenance.attributeName]: provenance,
    }),
    {},
  );

export const getEditsAsObj = (edits: Array<AttributeEditInfo>) =>
  edits?.reduce(
    (obj, edit) => ({
      ...obj,
      [edit.attributeName]: [edit],
    }),
    {},
  );

export const getPropertiesWithProvenance = (properties: Array<IPropertiesGridProperty>) =>
  properties.map((property) => ({
    ...property,
    editInfo: getEditsAsObj(property.attributeEdits || []),
    provenance: getProvenanceAsObj(property.attributeProvenance || []),
  }));

const noZoneValues = ['not at risk', 'out of zone'];
export const isZone = (zone: string | undefined) =>
  !!zone && !noZoneValues.includes(zone.toLowerCase().split('-').join(' '));

export const isFloodZone = (zone: string | undefined) => {
  if (!zone) {
    return false;
  }

  const intlFloodZones = [
    'international - moderate',
    'international - high',
    'international - very high',
  ];
  const nationalFloodZones = ['fema-a', 'fema-v'];

  if (intlFloodZones.includes(zone.toLowerCase())) {
    return true;
  }

  if (nationalFloodZones.includes(zone.toLowerCase().slice(0, 6))) {
    return true;
  }

  return false;
};

export const isScsZone = (zone: string | undefined) => {
  if (!zone) {return false;}

  return zone.toLowerCase() === 'high';
};

const isColumnType = (columnType?: string | null): columnType is ColumnType =>
  !!columnType &&
  [
    'boolean',
    'construction',
    'currency',
    'currencyLocal',
    'date',
    'default',
    'description',
    'hazardIcon',
    'hazardText',
    'inspectionsIcon',
    'integer',
    'location',
    'measurementArea',
    'measurementDistanceLarge',
    'measurementDistanceSmall',
    'number',
    'occupancy',
    'percentage',
    'propertyImg',
    'secondaryModifier',
    'twoLines',
    'webLink',
    'year',
  ].includes(columnType);

const getDataType = (dataType?: string | null): ColumnType => {
  if (!isColumnType(dataType || 'default')) {
    throw new Error(`Unexpected column type found: ${dataType}`);
  }

  return (dataType as ColumnType) || 'default';
};

const DEFAULT_WIDTH = 12;

export const prepareColumn = (
  propertyAttributeMetadata: IGraphQLAttributeMetadata,
): IPropertiesGridColumn => {
  const { name, displayName, displayWidth, maxThreshold, decimals } = propertyAttributeMetadata;

  const dataType = getDataType(propertyAttributeMetadata.dataType);

  return {
    formattingOptions: { decimals, maxThreshold },
    id: name,
    th: displayName,
    type: dataType,
    width: 16 * (displayWidth || DEFAULT_WIDTH),
  };
};

export const prepareColumns = (
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>,
): Array<IPropertiesGridColumn | Array<IPropertiesGridColumn>> => {
  interface IExtendedPropertiesGridColumn extends IPropertiesGridColumn {
    columns: Array<IPropertiesGridColumn>;
  }
  const preparedColumns = propertyAttributeMetadata.reduce(
    (compiledColumns, columnToPrepare) => {
      const { name, parent } = columnToPrepare;
      const finalParentName = getUltimateParentName(propertyAttributeMetadata, parent);

      if (columnToPrepare?.hiddenInGrid) {
        return compiledColumns;
      }

      if (parent) {
        return {
          ...compiledColumns,
          [finalParentName]: {
            ...compiledColumns[finalParentName],
            columns: (compiledColumns[finalParentName]?.columns || []).concat(
              prepareColumn(columnToPrepare),
            ),
          },
        };
      }

      return {
        ...compiledColumns,
        [name]: {
          ...compiledColumns[name],
          ...prepareColumn(columnToPrepare),
        },
      };
    },
    {} as {
      [index: string]: IExtendedPropertiesGridColumn;
    },
  );

  return propertyAttributeMetadata.reduce((arr, column) => {
    const { name, parent } = column;

    if (parent || column?.hiddenInGrid) {
      return arr;
    }

    if (preparedColumns[name] === undefined) {
      throw new Error(`No column found for ${column.name}`);
    }

    const { columns, ...preparedColumn } = preparedColumns[name];

    return arr.concat(preparedColumn, columns ? [columns] : []);
  }, [] as Array<IPropertiesGridColumn | Array<IPropertiesGridColumn>>);
};

const DISALLOWED_SORT_FIELDS = ['pictures'];
export function getIsSortable(column: IPropertiesGridColumnWithMetadata) {
  // FIXME - make this metadata driven
  // https://app.clubhouse.io/onarchipelago/story/38817/make-sorting-config-driven

  if (
    column.id.startsWith('consumerText1') || // HACK : This is RAPID Construction for CNA
    column.id.startsWith('ownerAttributes') ||
    column.id.startsWith('consumerAttributes')
  ) {
    return false;
  }
  return !DISALLOWED_SORT_FIELDS.includes(column.id);
}
