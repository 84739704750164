import React, { useState } from 'react';
import { Button, ContextMenu } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useFlyout } from '@app/contexts/FlyoutContext';
import AddDataFlyout from '@app/cx/Stream/AddDataFlyout/AddDataFlyout';
import { AddPropertyFlyout } from '@app/cx/Stream/AddPropertyFlyout/AddPropertyFlyout';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { usePropertiesPageContext } from '../PropertiesPage.context';

export const AddPropertyButton = () => {
  const [open, setOpen] = useState(false);
  const mixpanel = useTracker('mixpanel');
  const { showFlyout } = useFlyout();
  // FIX ME
  // @ts-ignore
  const { stream, refetchPropertiesDataGrid, refetchPropertyGroups, lock } =
    usePropertiesPageContext();

  const inProgressJobs: Partial<Record<EJobType, Lock>> = {};

  (lock?.locks || []).forEach((jobInfo) => {
    if (!!inProgressJobs[jobInfo.type] || !jobInfo.canReuse) {
      return;
    }

    inProgressJobs[jobInfo.type] = jobInfo;
  });

  const inProgressJob = inProgressJobs[EJobType.AddProperties];
  const hasJobInProgress = !!inProgressJob;

  const handleBulkAdd = () => {
    mixpanel.track('Manage Properties - Add', {
      in_progress: hasJobInProgress,
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <AddDataFlyout
        orgName={stream?.orgName as string}
        // FIX ME
        // @ts-ignore
        inProgressJob={inProgressJobs[EJobType.AddProperties]}
        // FIX ME
        // @ts-ignore
        stream={stream as IGraphQLStream}
        jobType={EJobType.AddProperties}
      />,
    );

    setOpen(false);
  };

  const button = (
    <Button
      label="Add Properties"
      onClick={() => setOpen(true)}
      iconName="plus"
      iconSide="left"
      size="m"
    />
  );

  return (
    <ContextMenu
      button={button}
      open={open}
      onClose={() => setOpen(false)}
      panel={{
        items: [
          {
            'data-testid': 'add-single-property-button',
            iconName: 'plus',
            label: 'Add new property',
            onClick: () => {
              // FIX ME
              // @ts-ignore
              sendMixpanelEvent(mixpanel, 'Add a property clicked', stream);
              showFlyout(
                <AddPropertyFlyout
                  refetch={refetchPropertiesDataGrid}
                  refetchGroups={refetchPropertyGroups}
                />,
              );
              setOpen(false);
            },
          },
          {
            'data-testid': 'add-properties-with-excel-button',
            iconName: 'upload',
            label: 'Add properties with an Excel file',
            onClick: () => {
              // FIX ME
              // @ts-ignore
              sendMixpanelEvent(mixpanel, 'Add properties with an Excel file clicked', stream);
              handleBulkAdd();
            },
          },
        ],
      }}
    />
  );
};
