"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetName = exports.assetNames = void 0;
exports.assetNames = [
    "logo",
    "logoAlt",
    "markBlack",
    "markColor",
    "zurichLogo",
];
// export type AssetName = typeof assetNames[number];
var AssetName;
(function (AssetName) {
    AssetName["logo"] = "logo";
    AssetName["logoAlt"] = "logoAlt";
    AssetName["markBlack"] = "markBlack";
    AssetName["markColor"] = "markColor";
    AssetName["zurichLogo"] = "zurichLogo";
    AssetName["aonLogo"] = "aonLogo";
    AssetName["wtwLogo"] = "wtwLogo";
    AssetName["marshLogo"] = "marshLogo";
    AssetName["ajgLogo"] = "ajgLogo";
    AssetName["alliantLogo"] = "alliantLogo";
})(AssetName = exports.AssetName || (exports.AssetName = {}));
