import React, { useState } from 'react';
import { Button, EuiFlexGroup, EuiFlexItem } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckContext } from '../context/PrecheckContext';
import { ShareReportModal } from './ShareReportModal';

export const ShareReport = () => {
  const [showModal, setShowModal] = useState(false);
  const { selectedOrganization } = useUserSession();

  const { precheckUser, stream } = usePrecheckContext();
  const mixpanel = useTracker('mixpanel');

  return (
    <>
      {showModal && <ShareReportModal closeModal={() => setShowModal(false)} />}
      <EuiFlexGroup style={{ flexWrap: 'nowrap' }}>
        <EuiFlexItem grow={false}>
          <Button
            label="Share"
            fullWidth={true}
            onClick={() => {
              mixpanel.track('Pre-Check: Share Opened', {
                event_surface: 'PreCheck',
                organization_id: selectedOrganization.id,
                precheck_user_type: precheckUser.type,
                stream_id: stream.id,
                stream_slug: stream.slug,
              });

              setShowModal(!showModal);
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
