import { SeriesBarOptions } from 'highcharts';
import {
  IGraphQLPercentageChart,
  IGraphQLTIVChart,
  IGraphQLTIVCharts,
} from '@app/queries/explorers/types';
import colors from '@app/utils/colors';
import { ITIVChartsBarMetadata } from './barsMetadata';

type Obj<T> = { [key: string]: T };

export interface ChartData {
  name: string;
  y: number;
}

export const getExplorerData = (explorer: any, queryName: string): any => {
  for (let i = 0; i < explorer.queries.length; i++) {
    const queryData = explorer.queries[i];
    if (queryData.query_name === queryName) {
      return queryData.data;
    }
  }
  return [];
};

// Compare two unknown JSON objects (nested/deep)
// Using string comparison because it is "fast enough" for small objects
// try/catch required because stringify tends to blow up bigly
export const chartDataIsEqual = (prev: any, next: any): boolean => {
  try {
    return JSON.stringify(prev.explorer) === JSON.stringify(next.explorer);
  } catch {
    return false;
  }
};

/* Using any types to stay compatible with Highcharts migrating between 7.x and 8.x */
export const getSeries = (name: string, data: any): any => {
  const series: any = {
    dataLabels: {
      enabled: false,
    },
    innerSize: '80%',
    name,
    showInLegend: true,
    size: '100%',
    type: 'pie',
  };

  if (data.length === 0) {
    data.push({ name: 'Empty', y: 1 });
    series.colors = [colors.gray5];
    series.enableMouseTracking = false;
  }
  series.data = data;

  return series;
};

export const getAgeTickPositions = (queryData: Array<ChartData>) => {
  if (queryData.length > 0) {
    return undefined;
  }
  return [0, 5, 10, 15, 20];
};

export const buildTIVChartData = (explorer: IGraphQLTIVChart): Array<ChartData> => {
  const chartData: Array<ChartData> = [];
  const { values } = explorer;
  for (let i = 0; i < values.length; i++) {
    chartData.push({
      name: values[i].label,
      y: values[i].tiv,
    });
  }

  return chartData;
};

export const buildTIVMapData = (explorer: IGraphQLTIVChart) => {
  const chartData: any[] = [];

  const { values } = explorer;
  for (let i = 0; i < values.length; i++) {
    if (!!values[i].label) {
      chartData.push([values[i].label, values[i].tiv]);
    }
  }

  return chartData;
};

export const buildPercentageChartData = (explorer: IGraphQLPercentageChart) => {
  const chartData = explorer.values.reduce(
    (obj: any, curr: any) => Object.assign(obj, { [curr.name]: curr.value }),
    {},
  );
  return chartData;
};

export const buildTIVChartsData = (explorer: IGraphQLTIVCharts): Obj<IGraphQLTIVChart> =>
  explorer.charts.reduce((accumulator, current) => {
    accumulator[current.name] = current;

    return accumulator;
  }, {});

export const getSeriesForKey = (
  chart: IGraphQLTIVChart,
  meta: ITIVChartsBarMetadata,
  index: number,
): Array<SeriesBarOptions> => {
  const { nameFormatter, colorFormatter, barIndexFormatter } = meta;
  let data = [...barIndexFormatter(chart).values];
  if (!data || data.length === 0) {
    return [
      {
        data: [null, null],
        type: 'bar',
      },
    ];
  }

  const indexOfUnknownSeries = data.map((d: any) => d.label).indexOf('Unknown');

  if (indexOfUnknownSeries > -1) {
    data = data.concat(data.splice(indexOfUnknownSeries, 1));
  }

  const d = data.map(
    (item: any) =>
      ({
        borderWidth: 0,
        color: colorFormatter(nameFormatter(item.label)),
        data:
          index === 0
            ? [
                {
                  name: nameFormatter(item.label),
                  y: item.pct,
                },
              ]
            : [
                null,
                {
                  name: nameFormatter(item.label),
                  y: item.pct,
                },
              ],
        name: nameFormatter(item.label),
        type: 'bar',
      } as Highcharts.SeriesBarOptions),
  );

  return d;
};
