import {
  AccountGlobalPermissions,
  IAccountState,
  UserSystemInfo,
  ViewedHighlight,
} from '@app/containers/AuthProvider/types';
import { SelectedOrganizationSummary } from '@app/contexts/UserSessionContext';
import { IGraphQLOrganization } from '@app/queries/organizations/types';
import { OrgInfo } from '@app/queries/streams/types';

const defaultPermissions: AccountGlobalPermissions = {
  admin: false,
  canViewProjects: false,
  viewOnly: true,
};

const highlightsArrayToObj = (viewedHighlights?: ViewedHighlight[]) =>
  viewedHighlights?.reduce(
    (obj, { slug, label }) => ({
      ...obj,
      [slug]: label,
    }),
    {},
  ) || {};

export const getPermissions = (
  idTokenPayload: any,
  extraPermissions?: any,
  currentUser?: UserSystemInfo,
): AccountGlobalPermissions => {
  if (!idTokenPayload) {
    return defaultPermissions;
  }
  try {
    return {
      ...(currentUser?.globalPermissions || {}),
      canViewProjects: !!currentUser?.globalPermissions?.admin,
      ...extraPermissions,
    };
  } catch (err) {
    // assume default
  }
  return defaultPermissions;
};

export const makeAccountOrgs = (currentUser: UserSystemInfo) =>
  currentUser?.managedOrgs?.reduce(
    (o, c) => ({
      ...o,
      [c.org.name]: {
        canViewDocuments: c.editDocuments,
        org: {
          id: c.org.id,
          name: c.org.name,
        },
      },
    }),
    {},
  );

export const makeIAccountState = (claims: any, currentUser: UserSystemInfo): IAccountState => {
  const docUploadOrgs: Array<OrgInfo> = [];
  const submissionCreateOrgs: Array<OrgInfo> = [];
  const globalPermissions = getPermissions(claims, currentUser?.globalPermissions);
  const orgs = makeAccountOrgs(currentUser);

  (currentUser?.managedOrgs || []).forEach((o) => {
    if (o.editDocuments) {
      docUploadOrgs.push(o.org);
    }

    if (o.editSubmissions) {
      submissionCreateOrgs.push(o.org);
    }

    if (!globalPermissions.canViewProjects && o.editProjects) {
      globalPermissions.canViewProjects = true;
    }
  });

  return {
    createdAt: currentUser?.createdAt,
    docUploadOrgs,
    email: currentUser?.profile?.email,
    emailVerified: currentUser?.emailVerified,
    familyName: currentUser?.profile?.familyName,
    givenName: currentUser?.profile?.givenName,
    intent: currentUser?.intent,
    // Non-admin users that have at least one organization assigned to them
    isNonAdminWithOrgs: !!currentUser?.managedOrgs?.length && !globalPermissions?.admin,
    managedOrgs: currentUser?.managedOrgs,
    organizationName: currentUser?.organization?.name,
    orgs,
    permissions: globalPermissions,
    phone: currentUser?.profile?.phone,
    pictureURL: currentUser?.profile?.pictureURL,
    submissionCreateOrgs,
    title: currentUser?.profile?.title,
    userCode: currentUser?.profile?.userCode || '',
    userId: currentUser?.userId,
    viewedHighlights: highlightsArrayToObj(currentUser?.viewedHighlights),
  };
};

export const getOrgPermissions = (account?: IAccountState, orgId?: string) => {
  const currentOrg = account?.managedOrgs?.find((o) => o?.org?.id === orgId);
  const isAdmin = account?.permissions?.admin;

  const permissions = [
    isAdmin ? 'admin' : null,
    isAdmin || currentOrg?.editDocuments ? 'canManageDocuments' : null,
    isAdmin || currentOrg?.editProjects ? 'canManageProjects' : null,
    isAdmin || currentOrg?.editProperties ? 'canManageProperties' : null,
    isAdmin || currentOrg?.editSubmissions ? 'canManageSubmissions' : null,
    isAdmin || currentOrg?.manageUsers ? 'canManageUsers' : null,
    currentOrg?.viewAccounts ? 'canViewAccounts' : null,
  ].filter(Boolean);

  return permissions;
};

export const canManageUsers = (account: IAccountState, orgID: string, enabledFeatures: string[]) =>
  enabledFeatures?.includes('SelfServiceUserMgmt') &&
  getOrgPermissions(account, orgID).includes('canManageUsers');
