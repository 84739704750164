import React from 'react';
import { useRouteMatch } from 'react-router';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, Spinner } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useGetSupportTicketQuery } from '@app/graphql/queries/support/__generated__/getSupportTicket.generated';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Helmet } from '../../Helmet';
import { RocketChat } from '../../RocketChat/RocketChat';
import { TicketAside } from './TicketAside';
import { TicketList } from './TicketList';
import { TicketPreview } from './TicketPreview';

interface Props {
  ticketId: string;
}
export const SupportTicket: React.FC = () => {
  const {
    params: { ticketId },
  } = useRouteMatch<Props>();

  const {
    account: { email },
  } = useAuth();

  const { data, loading, error } = useGetSupportTicketQuery({
    variables: { input: { id: ticketId } },
  });

  const renderBody = () => {
    if (error) {
      return (
        <EuiFlexItem grow={6}>
          <ErrorMessage
            title="An error occurred"
            message={`Could not get ticket details: ${getErrorMessage(error)}`}
          />
        </EuiFlexItem>
      );
    }

    const ticket = data?.supportTicket?.ticket;

    if (loading || !ticket) {
      return (
        <EuiFlexItem grow={6}>
          <Spinner />
        </EuiFlexItem>
      );
    }

    const isCurrentUserAssigned = ticket.assigneeEmails?.includes(email);
    const isCurrentUserFollowing = ticket.followerEmails?.includes(email);
    if (!isCurrentUserAssigned && !isCurrentUserFollowing && ticket?.authorEmail !== email) {
      return (
        <EuiFlexItem grow={6}>
          {/* @ts-ignore */}
          <TicketPreview ticket={ticket} />
        </EuiFlexItem>
      );
    }

    return (
      <>
        <EuiFlexItem grow={4}>
          {!!ticket && (
            <EuiPanel>
              <RocketChat initialRoom={ticket?.chatRoomName} room={ticket?.chatRoomName} />
            </EuiPanel>
          )}
        </EuiFlexItem>
        {/* @ts-ignore */}
        <EuiFlexItem grow={2}>{!!ticket && <TicketAside ticket={ticket} />}</EuiFlexItem>
      </>
    );
  };

  return (
    <EuiPanel style={{ overflow: 'hidden' }}>
      <Helmet title="Support ticket" />
      <EuiFlexGroup style={{ height: '100%' }}>
        <EuiFlexItem grow={2}>
          <TicketList currentRoomId={ticketId} />
        </EuiFlexItem>
        {renderBody()}
      </EuiFlexGroup>
    </EuiPanel>
  );
};
