import { Coords } from 'google-map-react';
import { MapPosition } from './types';

const DEFAULT_ZOOM = 4;
const DEFAULT_CENTER = {
  lat: 35.47,
  lng: -29.64,
};

type GetMapPosition = (params: { zoom?: number; center?: Coords }) => MapPosition;

const getMapPosition: GetMapPosition = ({ zoom, center }) => {
  if (zoom && center) {
    return {
      center,
      zoom,
    };
  }

  return {
    center: DEFAULT_CENTER,
    zoom: DEFAULT_ZOOM,
  };
};

export default getMapPosition;
