import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CasualtyFragmentDoc } from '../../fragments/__generated__/casualty.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CasualtyPageQueryVariables = Types.Exact<{
  input: Types.CasualtyPageInput;
}>;


export type CasualtyPageQuery = { __typename?: 'Query', casualtyPage: { __typename?: 'CasualtyPage', totalCount: number, casualtyList?: Array<{ __typename?: 'Casualty', id: string, organizationId: string, orgName: string, accountOrgId: string, accountOrgName: string, accountStatus?: string | null, analysisDate?: any | null, clientName?: string | null, clientNetWorth?: number | null, clientProspect?: Types.ClientProspect | null, typeOfBusiness?: string | null, sicCode?: string | null, naicsCode?: string | null, analysisNotes?: string | null, primaryContactEmail?: string | null, office?: string | null, region?: string | null, secondaryContactEmail?: string | null, requestedLinesOfBusiness?: Array<{ __typename?: 'requestedLinesOfBusiness', lineOfBusiness?: Types.LineOfBusiness | null, retention?: number | null, limit?: number | null, renewalOrRfpEffectiveDate?: any | null }> | null, exposures?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, exposureBase?: { __typename?: 'exposureBase', al?: string | null, wc?: string | null, gl?: Types.ExposureBaseInputGl | null } | null, retentions?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, deductibleAggregateLimit?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, policyEffectiveDates?: Array<{ __typename?: 'policyEffectiveDates', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: any | null, wc?: any | null, gl?: any | null }> | null }> | null } };


export const CasualtyPageDocument = gql`
    query CasualtyPage($input: CasualtyPageInput!) {
  casualtyPage(input: $input) {
    casualtyList {
      ...casualty
    }
    totalCount
  }
}
    ${CasualtyFragmentDoc}`;

/**
 * __useCasualtyPageQuery__
 *
 * To run a query within a React component, call `useCasualtyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasualtyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasualtyPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCasualtyPageQuery(baseOptions: Apollo.QueryHookOptions<CasualtyPageQuery, CasualtyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CasualtyPageQuery, CasualtyPageQueryVariables>(CasualtyPageDocument, options);
      }
export function useCasualtyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CasualtyPageQuery, CasualtyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CasualtyPageQuery, CasualtyPageQueryVariables>(CasualtyPageDocument, options);
        }
export type CasualtyPageQueryHookResult = ReturnType<typeof useCasualtyPageQuery>;
export type CasualtyPageLazyQueryHookResult = ReturnType<typeof useCasualtyPageLazyQuery>;
export type CasualtyPageQueryResult = Apollo.QueryResult<CasualtyPageQuery, CasualtyPageQueryVariables>;