import { useState } from 'react';
import { useSetOrgPreCheckConfigMutation } from '@app/graphql/mutations/orgPreCheckConfig/__generated__/setOrgPreCheckConfig.generated';
import { useOrgPreCheckConfigQuery } from '@app/graphql/queries/orgPrecheckConfig/__generated__/orgPrecheckConfig.generated';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';

export const usePrecheckConfig = ({ orgName }) => {
  const [precheckValue, setPrecheckValue] = useState(false);
  const client = usePrecheckClient();

  const { data } = useOrgPreCheckConfigQuery({
    client,
    onCompleted: (data) => {
      setPrecheckValue(data.orgPreCheckConfig.enablePreCheck);
    },
    skip: !orgName,
    variables: {
      input: {
        orgName,
      },
    },
  });

  const [savePrecheck] = useSetOrgPreCheckConfigMutation({ client });

  const savePrecheckValue = () => {
    if (data?.orgPreCheckConfig?.enablePreCheck === precheckValue) {return;}

    savePrecheck({
      variables: {
        input: { enablePreCheck: precheckValue, orgName },
      },
    });
  };

  return { precheckValue, savePrecheckValue, setPrecheckValue };
};
