import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { FilePicker } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { RowMenu } from '@app/components/RowMenu/RowMenu';
import { API_BASE_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { guid } from '@app/contexts/UploadsContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOrganizationDocumentsPageQuery } from '@app/graphql/queries/organizationDocumentsPage/__generated__/organizationDocumentsPage.generated';
import { usePropertyV2Query } from '@app/graphql/queries/property/__generated__/propertyV2.generated';
import { useUploadDocumentMutation } from '@app/graphql/queries/uploadDocument/__generated__/uploadDocument.generated';
import { BinaryOperator, Property, SortOrder } from '@app/graphql/types';
import useLogger from '@app/hooks/useLogger';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
import { DateStyle, formatDate } from '@app/utils/format';
import { useStreamContext } from '../../StreamProvider';
import { useAddPropertyFlyoutContext } from '../context/AddPropertyFlyout.context';

export const DocumentsUpload = ({ id, archipelagoId }) => {
  const { selectedOrganization } = useUserSession();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingForNewProperty, setIsUploadingForNewProperty] = useState(false);
  const { documentIds, setDocumentIds } = useAddPropertyFlyoutContext();
  const [uploads, setUploads] = useState({});
  const organizationId = selectedOrganization.id;
  const { account } = useAuth();
  const logger = useLogger();
  const apiTracker = useTracker('api');

  const { stream } = useStreamContext();
  const { data, loading, refetch } = usePropertyV2Query({
    skip: !id,
    variables: {
      propertyID: id,
    },
  });

  const [, executeUpload] = useAxios(
    {
      method: 'POST',
      url: `${API_BASE_URL}/organizations/${organizationId}/documents`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  );

  const uploadFiles = async (upload: {
    files: FileList | null;
    orgId?: string;
    submissionId?: string;
    propertyID?: string;
    classification?: string;
    eventPrefix: string;
  }) => {
    const { files, orgId, submissionId, propertyID, classification, eventPrefix } = upload;
    trackEvent(data?.propertyV2?.property as Property, files);
    logger.info('Files Uploaded', {
      classification,
      email: account?.email,
      file_count: files?.length || 0,
      files: (function () {
        const list = [];
        for (let i = 0; i < files.length; i++) {
          const item = files[i];
          list.push({
            name: item.name,
            size: item.size,
            type: item.type,
          });
        }
        return list;
      })(),
      orgId,
      propertyID,
      submissionId,
    });

    if (!files) {
      return;
    }
    const destinationRoute = submissionId ? 'submissions' : 'organizations';
    const destinationId = submissionId || orgId || organizationId;
    const notifySupport = !account?.email?.endsWith('@onarchipelago.com');

    // We are using this object to hold guids for each upload
    let keys = {};
    for (let i = 0; i < files.length; i++) {
      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      if (propertyID) {
        uploadData.append('propertyID', propertyID);
      }
      if (classification) {
        uploadData.append('classification', classification);
      }

      // For some reason, when doing `const key = guid();` the process was too
      // fast to assign a value in each loop, so when adding multiple files, they
      // all would have the same key, causing issues. Having an object with its
      // own key for each upload allowed to have separate guids and prevent issues.
      keys[i] = guid();
      // Aliasing for brevity
      const key = keys[i];

      const nextUploads = {};
      nextUploads[key] = {
        classification: 'Pending',
        created_at: formatDate(new Date(), DateStyle.Server),
        filename: files[i].name,
        id: '0',
        organization: {
          id: destinationId,
        },
        progress: 0,
      };
      setUploads((prevUploads) => ({ ...prevUploads, ...nextUploads }));

      executeUpload({
        data: uploadData,
        params: {
          includeInStreams: false,
          notifySupport,
        },

        url: `${API_BASE_URL}/${destinationRoute}/${destinationId}/documents`,
      })
        .then((result: any) => {
          apiTracker.track('Document uploaded', {
            classification: classification,
            documentId: result?.data?.document?.id,
            event_surface: eventPrefix.replace(' ', ''),
            organizationId: destinationId,
            propertyID: propertyID,
            url: window.location.href,
            urlPathname: window.location.pathname,
          });

          if (result?.data?.document) {
            const document = result?.data?.document;
            setUploads((prevUploads) => ({
              ...prevUploads,
              [key]: {
                ...prevUploads[key],
                classification: document?.classification || 'Pending',
                createdAt: formatDate(new Date(), DateStyle.Server),
                id: document.id,
              },
            }));
          }
        })
        .catch((error) => {
          if (error) {
            // TODO address errors and duplicate named documents
            console.log('error', error);
          }
        });
    }
    // Remove values from the obj so it can be garbage collected
    keys = {};
  };

  const documents = data?.propertyV2?.property?.documents;

  const { data: organizationDocumentsResult, refetch: getOrganizationDocuments } =
    useOrganizationDocumentsPageQuery({
      skip: documentIds.length === 0,
      variables: {
        filter: {
          filters: [
            {
              name: 'id',
              operator: BinaryOperator.Equal,
              values: documentIds,
            },
          ],
          orgName: selectedOrganization.name,
          pageSize: 200,
          sortBy: [
            {
              attributeName: 'createdAt',
              order: SortOrder.Descending,
            },
          ],
        },
      },
    });

  const organizationDocuments =
    organizationDocumentsResult?.organizationDocumentsPage?.documents || [];

  useEffect(() => {
    const allUploadsComplete = Object.values(uploads).every((upload) => documents?.find(
        // FIX ME
        // @ts-ignore
        (document) => document.id === upload.id && document.classification !== null,
      ));

    if (Object.keys(uploads).length && !allUploadsComplete) {
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
  }, [uploads, documents]);

  useEffect(() => {
    // if any uploads with classification null, also refetch?
    if (isUploading) {
      const interval = setInterval(() => {
        refetch();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isUploading]);

  useEffect(() => {
    if (isUploadingForNewProperty) {
      const interval = setInterval(() => {
        getOrganizationDocuments();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isUploadingForNewProperty]);

  useEffect(() => {
    const allUploadsComplete = Object.values(organizationDocuments).every((upload) => upload.classification !== null);

    if (documentIds.length && !allUploadsComplete) {
      setIsUploadingForNewProperty(true);
    } else {
      setIsUploadingForNewProperty(false);
    }
  }, [organizationDocuments]);

  const [uploadDocument] = useUploadDocumentMutation();

  const trackEvent = (property: Property, files: FileList) => {
    const payload: any = {
      event_surface: TrackGroupSovManager.event_surface,
      organization_name: stream?.orgName,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    };

    if (!!property) {
      const { archipelagoId, locationId, locationName } = data.propertyV2.property;
      payload.archipelago_id = archipelagoId;
      payload.location_id = locationId;
      payload.location_name = locationName;
    }

    if (!!files) {
      payload.files = [];
      for (let i = 0; i < files.length; i++) {
        payload.files.push({
          file_name: files[i].name,
        });
      }
    }
    apiTracker.track(
      `${TrackGroupSovManager.prefix}: ${!files?.length ? 'Open documents tab' : 'Upload files'}`,
      payload,
    );
  };

  useEffect(() => {
    if (!id) {
      trackEvent(null, null);
    }
  }, [id]);
  useEffect(() => {
    if (!!data?.propertyV2) {
      trackEvent(data.propertyV2.property as Property, null);
    }
  }, [data?.propertyV2?.property?.archipelagoId]);
  const rows =
    (documents || organizationDocuments)?.map((document) => ({
        id: document.id,
        loading: document.classification === null,
        propertyID: id,
        subtitle:
          document.classification !== null
            ? `Document type: ${document.classification}`
            : 'Classifying document type',
        title:
          document.classification === null
            ? `Uploading ${document.filename}.${document.extension}`
            : `${document.filename}.${document.extension}`,
      })) || [];

  return (
    <>
      <FilePicker
        prompt="Select or drag and drop multiple files"
        data-testid="documents-tab"
        onChange={async (files) => {
          if (archipelagoId) {
            uploadFiles({
              
              
              eventPrefix: 'AddPropertyFlyout',
              // FIX ME
// @ts-ignore
files,
              orgId: selectedOrganization.id,
              propertyID: archipelagoId,
            });
          } else {
            const results = await Promise.all(
              files.map((file) =>
                uploadDocument({ variables: { document: file, orgID: selectedOrganization.id } }),
              ),
            );
            setDocumentIds(results.map((result) => result?.data?.upload?.id));
            setIsUploadingForNewProperty(true);
          }
        }}
      />
      {loading ? (
        <LoadingSpinnerV2 />
      ) : (
        rows.length > 0 && <RowMenu title="Documents" rows={rows} />
      )}
    </>
  );
};
