/** @jsx jsx */
/** @jsxFrag jsx */
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { jsx } from '@emotion/react';
import {
  CarrierAssessment,
  CarrierLossYear,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { EuiFlexGroup, EuiFlexItem, EuiStat, Select, Spacer } from 'ui';
import LossHistory from '@app/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/tabs/LossHistory/LossHistory';
import { formatCurrency } from '@app/utils/format';

interface Props {
  carrierAssessment: CarrierAssessment;
  carrierLossYear: CarrierLossYear;
  setCarrierLossYear: Dispatch<SetStateAction<CarrierLossYear>>;
}

const Overview: React.FC<Props> = ({ carrierAssessment, carrierLossYear, setCarrierLossYear }) => {
  const { lossYears } = carrierAssessment;

  const year = carrierLossYear;
  const setYear = setCarrierLossYear;

  const formatCurrencyVals = (val) =>
    formatCurrency(val, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });

  enum LossHistoryOverviewTitles {
    claimCnt = 'Claim Count',
    totalPaidIndemnity = 'Total Paid Indemnity',
    avgPaidIndemnity = 'Avg. Paid Indemnity',
    lossFrequency = 'Loss Frequency',
  }

  const LossHistoryOverviewFields = [
    { description: LossHistoryOverviewTitles.claimCnt, title: year?.claimCnt },
    {
      description: LossHistoryOverviewTitles.totalPaidIndemnity,
      title: formatCurrencyVals(year?.totalPaidIndemnity),
    },
    {
      benchmark: year?.avgPaidIndemnityBenchmarkRatio,
      description: LossHistoryOverviewTitles.avgPaidIndemnity,
      title: formatCurrencyVals(year?.avgPaidIndemnity),
    },
  ];

  return (
    <EuiFlexGroup direction="column" data-testid="loss-history-content">
      <EuiFlexItem>
        <Select
          options={
            lossYears?.map((y) => ({
              label: String(y.uwYear),
              value: String(y.uwYear),
            })) || []
          }
          value={String(year?.uwYear)}
          onChange={(value) => setYear(lossYears?.find((y) => y?.uwYear === parseInt(value, 10)))}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup css={{ maxWidth: '80%' }}>
          <EuiFlexItem>
            <EuiStat title={year?.uwYear} description="UW Year" titleSize="m" />
          </EuiFlexItem>
          {LossHistoryOverviewFields?.map((f, i) => (
            <EuiFlexItem key={i}>
              <EuiStat title={f.title} description={f.description} titleSize="m" />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Overview;
