import styled from '@emotion/styled';
import { EuiText } from 'ui';

export const KeyTitle = styled.div`
  font-size: 10.5px;
`;

export const KeyTitleText = styled(EuiText)`
  font-size: 10.5px;
`;
