import isNil from 'lodash/isNil';
import { number, object, string } from 'yup';
import {
  BIC_INPUT_KEY,
  BIC_LABEL,
  CITY_INPUT_KEY,
  CITY_LABEL,
  COUNTRY_INPUT_KEY,
  COUNTRY_LABEL,
  COUNTY_INPUT_KEY,
  COUNTY_LABEL,
  CRC_INPUT_KEY,
  CRC_LABEL,
  CURRENCY_INPUT_KEY,
  CURRENCY_LABEL,
  LAT_INPUT_KEY,
  LNG_INPUT_KEY,
  RCV_INPUT_KEY,
  RCV_LABEL,
  STATE_INPUT_KEY,
  STATE_LABEL,
  STREET_ADDRESS_INPUT_KEY,
  STREET_ADDRESS_LABEL,
  TIV_INPUT_KEY,
  TIV_LABEL,
} from './constants';

export const makeRequiredMessage = (label: string) => `${label} is required.`;

const fourDecimalDigitRegex = /^-?\d+(\.\d{4,}){1}$/;

export const fourDecimalTest = (val: number) => {
  if (!isNil(val)) {
    return fourDecimalDigitRegex.test(val.toString());
  }
  return true;
};

const getAddressFieldValidation = (label: string) =>
  string().when([LAT_INPUT_KEY, LNG_INPUT_KEY], {
    is: (latitude: number, longitude: number) => isNil(latitude) || isNil(longitude),
    then: string().required(makeRequiredMessage(label)).typeError(makeRequiredMessage(label)),
  });

export const yupSchema = object({
  [CITY_INPUT_KEY]: getAddressFieldValidation(CITY_LABEL),
  [COUNTRY_INPUT_KEY]: getAddressFieldValidation(COUNTRY_LABEL),
  [BIC_INPUT_KEY]: number().required(makeRequiredMessage(BIC_LABEL)),
  [COUNTY_INPUT_KEY]: getAddressFieldValidation(COUNTY_LABEL),
  [CRC_INPUT_KEY]: number().required(makeRequiredMessage(CRC_LABEL)),
  [CURRENCY_INPUT_KEY]: string()
    .required(makeRequiredMessage(CURRENCY_LABEL))
    .typeError(makeRequiredMessage(CURRENCY_LABEL)),
  [LAT_INPUT_KEY]: number()
    .nullable(true)
    .typeError('Latitude must be a number.')
    .min(-90, 'Latitude must be greater than -90.')
    .max(90, 'Latitude must be less than 90')
    .test(
      'lng-has-at-least-4-decimals',
      'Latitude should have at least 4 decimals for precision.',
      fourDecimalTest,
    ),
  [LNG_INPUT_KEY]: number()
    .nullable(true)
    .typeError('Longitude must be a number.')
    .min(-180, 'Longitude must be greater than -180.')
    .max(180, 'Longitude must be less than 180')
    .test(
      'lat-has-at-least-4-decimals',
      'Longitude should have at least 4 decimals for precision.',
      fourDecimalTest,
    ),
  [RCV_INPUT_KEY]: number().required(makeRequiredMessage(RCV_LABEL)),
  [STATE_INPUT_KEY]: string()
    .nullable(true)
    .when(COUNTRY_INPUT_KEY, {
      is: 'United States',
      then: getAddressFieldValidation(STATE_LABEL),
    }),
  [STREET_ADDRESS_INPUT_KEY]: getAddressFieldValidation(STREET_ADDRESS_LABEL),
  [TIV_INPUT_KEY]: number().required(makeRequiredMessage(TIV_LABEL)),
}).required();
