import gql from 'graphql-tag';

export const GET_STREAM_PROPERTY_VALUATIONS = gql`
  query StreamPropertyValuations($input: StreamPropertyValuationsInput!) {
    streamPropertyValuations(input: $input) {
      properties {
        id
        archipelagoID
        locationName
        brcPercentDiff
        brcPercentOfTotal
        brcPercentValueDifference
        streetAddress
        usStateCode
        occupancyType
        constructionType
        brc
      }
    }
  }
`;
