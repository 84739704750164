import styled from '@emotion/styled';
import { EuiFlexItem, EuiModal } from 'ui';

export const Modal = styled(EuiModal)`
  width: 680px;
`;

export const ChildrenContainer = styled(EuiFlexItem)`
  padding-top: 16px;
`;
