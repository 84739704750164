import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { usePageState } from '@onarchipelago/cx/Stream/utils';
import { useToast } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useCreateSnapshotMutation } from '@app/graphql/mutations/snapshot/__generated__/createSnapshot.generated';
import { useAssignTaskMutation } from '@app/graphql/mutations/workflow/__generated__/assignTask.generated';
import { useCompleteTaskMutation } from '@app/graphql/mutations/workflow/__generated__/completeTask.generated';
import { useUnAssignTaskMutation } from '@app/graphql/mutations/workflow/__generated__/unAssignTask.generated';
import { useSearchTasksLazyQuery } from '@app/graphql/queries/workflow/__generated__/searchTasks.generated';
import { useStartWorkflowLazyQuery } from '@app/graphql/queries/workflow/__generated__/startWorkflow.generated';
import { useTaskDetailsLazyQuery } from '@app/graphql/queries/workflow/__generated__/taskDetails.generated';
import { useWorkflowStartedQuery } from '@app/graphql/queries/workflow/__generated__/workflowStarted.generated';
import {
  PropertyDataInput,
  TaskSearchResponse,
  TaskState,
  TaskVariableSearchResponse,
} from '@app/graphql/types';
import { ICamundaProcessInstance } from '@app/platform/Workflow/context/CamundaAdapter';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { GetStreamData, GetStreamV2Variables } from '@app/queries/streams/types';

export interface IWorkflowContext {
  params: {
    streamSlug: string;
    orgName: string;
    orgID: string;
  };
  process?: ICamundaProcessInstance;
  startProcess: () => void;
  startLoading: boolean;
  getProcessTasks: () => void;
  claimTask: (taskId: string, assignee: string) => void;
  unclaimTask: (taskId: string) => void;
  completeTask: (
    taskId: string,
    archipelagoId: string,
    payload: string,
    attributeNames: string[],
    propertyData?: PropertyDataInput,
  ) => void;

  workflowComplete: boolean;

  unassignLoading: boolean;
  selectedTask: TaskSearchResponse;
  setSelectedTask;
  selectedTaskDetails: TaskVariableSearchResponse[];
  taskDetailsLoading: boolean;
  tasksLoading: boolean;
  workflowStarted: any;
  setWorkflowStarted: any;
  workflowStartedLoading: boolean;
}

export const WorkflowContext = createContext({} as IWorkflowContext);

export const WorkflowProvider = ({ children }) => {
  // StreamId holds a slug.
  const { streamId } = useParams<{ streamId: string }>();
  const toast = useToast();

  const { selectedOrganization } = useUserSession();
  const [pageState] = usePageState();
  const userCode = pageState.userCode || '';
  const orgName = selectedOrganization?.name;
  const orgID = selectedOrganization?.id;

  const [currentProcess, setCurrentProcess] = useState<ICamundaProcessInstance>();
  const [workflowComplete, setIsCompleted] = useState<boolean>(false);

  const [workflowStarted, setWorkflowStarted] = useState(false);

  const [tasksLoading, setTasksLoading] = useState<boolean>(false);
  const [startLoading, setStartLoading] = useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<TaskSearchResponse | null>(null);
  const [selectedTaskDetails, setSelectedTaskDetails] =
    useState<Array<TaskVariableSearchResponse> | null>(null);

  const [unclaimLoading, setUnclaimLoading] = useState<boolean>(false);
  const [taskDetailsLoading, setTaskDetailsLoading] = useState<boolean>(false);
  const [getTaskDetailsQuery] = useTaskDetailsLazyQuery({
    onCompleted: (data) => {
      // FIX ME
      // @ts-ignore
      setSelectedTaskDetails(data?.taskDetails);
    },
    onError: (error) => {
      console.log(error);
    },
    variables: {
      input: {
        taskId: selectedTask?.id,
        variableNames: ['task'],
      },
    },
  });

  const {
    loading: workflowStartedLoading,
    startPolling,
    stopPolling,
  } = useWorkflowStartedQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setWorkflowStarted(data.workflowStarted);
    },
    onError: (error) => {
      toast({ title: 'Could not get workflow started', type: 'danger' });
    },
    skip: !orgName,
    variables: {
      input: {
        orgName: '"' + orgName + '"',
      },
    },
  });

  const { data: streamData } = useQuery<GetStreamData, GetStreamV2Variables>(GET_STREAM, {
    variables: {
      isAdmin: false,
      slug: streamId,
      userCode: userCode,
    },
  });

  useEffect(() => {
    if (selectedTask) {
      getTaskDetailsQuery();
    }
  }, [selectedTask]);

  const [completeTaskMutation] = useCompleteTaskMutation({
    onCompleted: (data) => {
      getProcessTasks();
      getTaskDetailsQuery();
    },
    onError: (error) => {},
  });
  const completeTask = (
    taskId: string,
    archipelagoId: string,
    payload: string,
    attributeNames: string[],
    propertyData?: PropertyDataInput,
  ) => {
    completeTaskMutation({
      variables: {
        input: {
          attributeNames,
          completionData: payload,
          customerProvidedGeocode: false,
          // locationId
          orgName: orgName,
          propertyArchipelagoID: archipelagoId,
          propertyData,
          taskId,
        },
      },
    });
  };
  const [assignee, setAssignee] = useState<string>('');

  const [assignTaskMutation] = useAssignTaskMutation({
    onCompleted: (data) => {
      setSelectedTask({ ...selectedTask, assignee });
      getProcessTasks();
    },
    onError: (error) => {},
  });

  const claimTask = (taskId: string, assignee: string) => {
    setAssignee(assignee);
    setTaskDetailsLoading(true);
    assignTaskMutation({
      variables: {
        input: {
          assignee,
          taskId,
        },
      },
    });
  };

  const [unassignTaskMutation] = useUnAssignTaskMutation({
    onCompleted: () => {
      getProcessTasks();
      setUnclaimLoading(false);
    },
    onError: () => {
      setUnclaimLoading(false);
    },
  });

  const unclaimTask = (taskId: string) => {
    setTaskDetailsLoading(true);
    unassignTaskMutation({ variables: { input: { taskId } } });
  };

  const [startWorkflowQuery] = useStartWorkflowLazyQuery({
    onCompleted: (data) => {
      setStartLoading(false);
      getProcessTasks();
    },
    onError: (error) => {
      toast({ title: 'Could not start workflow', type: 'danger' });
      setStartLoading(false);
    },
    variables: {
      input: {
        orgName,
        streamSlug: streamId,
      },
    },
  });
  const startProcess = () => {
    setStartLoading(true);
    startWorkflowQuery();
  };

  const [searchTaskQuery] = useSearchTasksLazyQuery({
    onCompleted: (data) => {
      const updatedProcess = { ...currentProcess };
      // FIX ME
      // @ts-ignore
      updatedProcess.tasks = data.searchTasks;
      setCurrentProcess(updatedProcess);
      setTasksLoading(false);
      setTaskDetailsLoading(false);
    },
    onError: (error) => {
      toast({ title: 'Could not get tasks', type: 'danger' });
      setTasksLoading(false);
    },
    variables: {
      input: {
        orgName: selectedOrganization?.name,
        // Can be nil because the My Tasks screen is not linked to a stream
state: TaskState.Created, 
        streamSlug: '',
      },
    },
  });
  const getProcessTasks = () => {
    if (!currentProcess) {
      setTasksLoading(true);
    }
    searchTaskQuery();
  };

  useEffect(() => {
    if (streamData?.streamV2.permissions?.canEditAllProperties) {
      getProcessTasks();
    }
  }, [streamId, orgID]);

  useEffect(() => {
    // Check if all tasks have an endTime
    const allTasksCompleted =
      currentProcess?.tasks?.length > 0 &&
      currentProcess?.tasks?.every((task) => task.taskState === TaskState.Completed);
    if (allTasksCompleted) {
      setIsCompleted(true);
    }
  }, [currentProcess]);

  const [createSnapshotMutation, { data }] = useCreateSnapshotMutation({
    onCompleted: (data) => {},
    onError: (error) => {},
    variables: {
      input: {
        StreamSlugs: [streamId],
        name: `Post-Precheck for ${streamId}`,
        orgName: orgName,
      },
    },
  });

  useEffect(() => {
    if (workflowComplete) {
      createSnapshotMutation();
    }
  }, [workflowComplete]);

  useEffect(() => {
    if (
      currentProcess?.id != undefined &&
      (currentProcess?.tasks?.length == 0 || currentProcess.tasks == null)
    ) {
      getProcessTasks();
    }
  }, [currentProcess]);

  const workflowContextValue: IWorkflowContext = {
    claimTask,
    getProcessTasks,
    params: {
      orgID,
      orgName,
      streamSlug: streamId,
    },
    process: currentProcess,
    completeTask,
    selectedTask,
    selectedTaskDetails,
    startLoading,
    setSelectedTask,
    startProcess,
    

setWorkflowStarted,
    
    
    

tasksLoading,
    

// FIX ME
// @ts-ignore
startPolling,
    



unclaimTask,
    

stopPolling,
    workflowComplete,
    taskDetailsLoading,
    unassignLoading: unclaimLoading,
    workflowStarted,
    workflowStartedLoading,
  };

  return (
    <WorkflowContext.Provider value={workflowContextValue}>{children}</WorkflowContext.Provider>
  );
};

export const useWorkflowContext = () => useContext(WorkflowContext);
