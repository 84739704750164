import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { useQuery } from '@apollo/client';
import useLoadMoreTableData from '../../pagination/useLoadMoreTableData';
import useRefetchTableData from '../../pagination/useRefetchTableData';
import { GET_ORGANIZATION_DOCUMENTS_PROPERTIES_PAGE } from '../../queries/getOrganizationDocumentsPropertiesPage';
import { DocumentsContext } from '../../types/contextTypes';
import {
  OrganizationDocumentsPropertiesPageData,
  OrganizationDocumentsPropertiesPageVariables,
  QueryFilter,
} from '../../types/queryTypes';

// Summary: This hook does the following:
//  1. Fetches data from the `organizationDocumentsPropertiesPage` query and returns that data
//  2. Gets the `loadMore` function, which, when invoked, fetches the additional page of the
//  paginated data.
//  3. After the initial fetch we keep loading until we hit a target preload number.
//  4. Makes sure data is refetched when the query filters are updated
//  5. Lets the parents know when the query has been fetched (`isLoaded`)

export default (
  meta: DocumentsContext['meta'],
  filters: DocumentsContext['filters'],
): DocumentsContext['data']['properties'] => {
  const [data, setData] = useState<OrganizationDocumentsPropertiesPageData | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { orgName, pageSize } = meta;
  const propertyQueryFilters = filters?.propertyQuery?.state;
  const previousPropertyQueryFilters = usePrevious<QueryFilter[]>(propertyQueryFilters);

  const { fetchMore, refetch, loading } = useQuery<
    OrganizationDocumentsPropertiesPageData,
    OrganizationDocumentsPropertiesPageVariables
  >(GET_ORGANIZATION_DOCUMENTS_PROPERTIES_PAGE, {
    onCompleted: (tableData) => {
      setData(tableData);
      setIsLoaded(true);
    },
    variables: {
      cursor: null,
      filter: {
        filters: propertyQueryFilters,
        orgName,
        pageSize,
      },
    },
  });

  useEffect(() => {
    if (propertyQueryFilters !== previousPropertyQueryFilters) {
      setData(null);
    }
  }, [propertyQueryFilters, previousPropertyQueryFilters]);

  // *2
  const loadMore = useLoadMoreTableData({
    cursor: data?.organizationDocumentsPropertiesPage?.pageInfo?.cursor,
    dataType: 'properties',
    fetchMore,
    setData,
  });

  // *3
  // usePreloadTableData({
  //   cursor: data?.organizationDocumentsPropertiesPage?.pageInfo?.cursor,
  //   data: data?.organizationDocumentsPropertiesPage?.properties,
  //   loadMore,
  //   preloadSize,
  // });

  // *4.
  useRefetchTableData({
    orgName,
    pageSize,
    propertyQueryFilters,
    refetch,
    setData,
  });

  return {
    data,
    isLoaded,
    loadMore,
    loading,
  };
};
