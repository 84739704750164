/** @jsx jsx */
/** @jsxFrag jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/react';
import {
  CarrierAssessment,
  CarrierLossYear,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import ClosedClaimsTable from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/tabs/LossHistory/ClosedClaimsTable';
import { EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import Overview from './Overview';

interface Props {
  carrierAssessment?: CarrierAssessment;
}

// TODO: Move all this logic to the InsurerInsights.tsx component`
const LossHistoryTab: React.FC<Props> = ({ carrierAssessment }) => {
  // TODO: Do this when `useQuery`'s `error` is true
  if (!carrierAssessment) {
    return <ErrorMessage message="Property grade details not available for this stream" />;
  }

  const [carrierLossYear, setCarrierLossYear] = useState<CarrierLossYear | null>(
    carrierAssessment?.lossYears[0],
  );

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <Spacer size="l" />
        <Overview
          carrierAssessment={carrierAssessment}
          carrierLossYear={carrierLossYear}
          setCarrierLossYear={setCarrierLossYear}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText css={{ fontWeight: 'bold' }}>Closed Claims during the UW Year</EuiText>
        <EuiText color="subdued">
          Includes only Gross Paid Indemnity (Zurich Share) on closed claims.
        </EuiText>
        <Spacer />
        <ClosedClaimsTable
          carrierAssessment={carrierAssessment}
          carrierLossYear={carrierLossYear}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default LossHistoryTab;
