import React, { FC, ReactChildren } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  label: TourPanelTitleContainer;

  font-weight: 600;
  font-size: 24px;
  line-height: 156.61%;
  letter-spacing: 0.0125em;
  margin-bottom: 16px;
`;

interface IProps {
  children: ReactChildren | string;
}

const TourPanelTitle: FC<IProps> = ({ children }) => <Container>{children}</Container>;

export default TourPanelTitle;
