import { createContext } from 'react';
import { IDocumentModalStream } from '@onarchipelago/cx/DocumentsModal//types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { IGraphQLStream } from '@app/queries/streams/types';

interface IStreamMeta {
  stream_id?: string;
  stream_name?: string;
  stream_slug?: string;
  property_id?: string;
  location_name?: string;
  location_id?: string;
}

export interface IStreamContext {
  meta?: IStreamMeta;
}

const getStreamMeta = (
  stream: IGraphQLStream | IDocumentModalStream | undefined,
  currentProperty?: IGraphQLProperty,
): IStreamMeta => {
  const meta = {
    stream_id: stream?.id,
    stream_name: stream?.name,
    stream_slug: stream?.slug,
  };
  if (currentProperty) {
    return {
      ...meta,
      location_id: currentProperty?.locationId,
      location_name: currentProperty?.locationName,
      property_id: currentProperty?.id,
    };
  }
  return meta;
};

export const initStreamContext = (
  stream: IGraphQLStream | IDocumentModalStream | undefined,
  currentProperty?: IGraphQLProperty,
): IStreamContext => ({
  meta: getStreamMeta(stream, currentProperty),
});

const StreamMetaStoreContext = createContext<IStreamContext>({});
export default StreamMetaStoreContext;
