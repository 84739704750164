import styled from '@emotion/styled';
import { ButtonGroup,EuiFlexItem, EuiPanel } from 'ui';

export const ContentContainer = styled.div`
  overflow: hidden;
`;
export const MapContainer = styled(EuiFlexItem)<{ full: boolean }>`
  ${({ full }) =>
    full
      ? `
        grid-column: span 3;
        overflow: scroll;
        border: 1px solid #e0e0e0;
        `
      : `
        grid-column: span 2;
        grid-row: span 2;
        `};
`;

export const ViewContainer = styled(EuiFlexItem)<{ fullHeightVh: boolean }>`
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: scroll;
  grid-gap: 8px;
  height: ${({ fullHeightVh }) => (fullHeightVh ? '100vh' : '100%')};
  padding-bottom: ${({ fullHeightVh }) => (fullHeightVh ? '250px' : '0px')};
`;

export const ExploreContainer = styled(EuiFlexItem)`
  display: flex;
  height: 100%;
  padding-bottom: 100px;
`;

export const OptionsPanel = styled(EuiFlexItem)`
  padding-top: 5px;
`;

export const ViewModeOptions = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;

export const MetricsBarContainer = styled(EuiPanel)`
  margin-top: 16px;
`;

export const SearchBarContainer = styled(EuiFlexItem)`
  padding-top: 16px;
`;

export const ExplorerContainer = styled.div`
  label: ExplorerContainer;
`;

export const ChartContainer = styled.div<{ height: number }>`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: ${({ height }) => height}px;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      border-bottom: none;
    }
  }
`;

export const GalleryContainer = styled.div`
  height: 100%;
`;
