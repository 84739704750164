import React from 'react';
import { Button, EuiFlexGroup, EuiFlexItem } from 'ui';
import { NavSettings } from './types';

interface Props {
  navSettings: NavSettings;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onNextHighlight: () => void;
  onPreviousHighlight: () => void;
}

export const SourceNav: React.FC<Props> = ({
  navSettings: { currentPageIndex, pages, highlightIndexes },
  onNextPage,
  onPreviousPage,
  onNextHighlight,
  onPreviousHighlight,
}) => (
    <EuiFlexGroup alignItems="center" justifyContent="center">
      <EuiFlexItem grow={false}>
        <Button
          label="Previous highlight"
          onClick={onPreviousHighlight}
          disabled={currentPageIndex <= highlightIndexes[0]}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Button label="Previous page" onClick={onPreviousPage} disabled={currentPageIndex === 0} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{currentPageIndex + 1}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Button
          label="Next page"
          onClick={onNextPage}
          disabled={currentPageIndex === pages.length - 1}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Button
          label="Next highlight"
          onClick={onNextHighlight}
          disabled={currentPageIndex >= highlightIndexes[highlightIndexes.length - 1]}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
