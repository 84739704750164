import React, { useContext, useState } from 'react';
import PropertyItem from '@onarchipelago/dice/EnrichmentProjects/PropertyItem';
import SelectPropertiesFlyout from '@onarchipelago/dice/EnrichmentProjects/SelectPropertiesFlyout';
import { EPropertySelectType } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutFooter,
  EuiFocusTrap,
  useToast,
} from 'ui';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { ModalContext } from '@app/contexts/ModalContext';
import RestoreDisposedPropertyModal from '@app/cx/Stream/RestoreDisposedPropertyModal';
import { useSetPropertiesStatusMutation } from '@app/graphql/mutations/property/__generated__/setPropertiesStatus.generated';
import { PropertyStatus, PropertyStatusInput } from '@app/graphql/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { MetadataMap } from './StreamProvider';

interface IProps {
  metadataMap: MetadataMap;
  orgID: string;
  orgName: string;
  filterable: Array<PropertyAttributeMetadata>;
  streamSlug: string;
}

const RestoreDisposedPropertiesFlyout: React.FC<IProps> = ({
  metadataMap,
  orgName,
  orgID,
  filterable,
}) => {
  const { closeFlyout } = useContext(FlyoutContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const [selectedProperties, setSelectedProperties] = useState<IGraphQLProperty[]>([]);
  const handleUpdateSelectedProperties = (updatedProperties: IGraphQLProperty[]) => {
    setSelectedProperties([...updatedProperties]);
  };

  const toast = useToast();

  const [setPropertiesStatus, { loading }] = useSetPropertiesStatusMutation({
    onCompleted: () => {
      toast({ title: 'Successfully set property status.', type: 'success' });
      closeModal();
      closeFlyout();
    },
    onError: () => {
      toast({ title: 'Could not set property status', type: 'danger' });
    },
  });

  const handleStart = async (properties: IGraphQLProperty[]) => {
    await setPropertiesStatus({
      variables: {
        input: {
          orgName: orgName,
          properties: properties.map((property): PropertyStatusInput => ({ archipelagoId: property.archipelagoId })),
          status: PropertyStatus.Ongoing,
        },
      },
    });
  };

  const propertyItem = (renderedRow: any) => (
    <PropertyItem
      multiple
      item={renderedRow.item}
      selectedProperties={selectedProperties}
      updateSelectedProperties={handleUpdateSelectedProperties}
    />
  );

  const formatTitle = (multiple) =>
    `Restore ${multiple ? 'these disposed properties' : 'this disposed property'}`;
  return (
    <EuiFocusTrap>
      <EuiFlyout
        data-testid="m-m-restore-disposed-properties-flyout"
        ownFocus
        onClose={closeFlyout}
        aria-labelledby="flyoutTitle"
        size="s"
        style={{ maxWidth: '0px', minWidth: '600px' }}
      >
        <SelectPropertiesFlyout
          multiple
          title="Restore disposed properties"
          orgName={orgName}
          orgID={orgID}
          selectedItems={selectedProperties}
          rowComponent={propertyItem}
          filterable={filterable}
          updateSelectedProperties={handleUpdateSelectedProperties}
          propertySelectType={EPropertySelectType.DisposedProperties}
        />
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <Button
                data-testid="m-m-restore-disposed-properties-submit"
                onClick={() => {
                  showModal(
                    <RestoreDisposedPropertyModal
                      metadataMap={metadataMap}
                      closeModal={closeModal}
                      selectedProperties={selectedProperties}
                      handleStart={handleStart}
                      loading={loading}
                      formatTitle={formatTitle}
                      confirmButtonLabel="Restore"
                      footNote="Property/Properties will be restored as Ongoing in the change analysis. This will not appear as Disposed on platform."
                    />,
                  );
                }}
                fill
                disabled={selectedProperties.length === 0}
                iconSide="right"
                loading={loading}
                label={loading ? 'Restoring' : 'Restore'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiFocusTrap>
  );
};

export default RestoreDisposedPropertiesFlyout;
