import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import {
  GetInboxQuery,
  useGetInboxLazyQuery,
  useGetInboxQuery,
} from '@app/graphql/queries/inbox/__generated__/searchTasks.generated';

export enum InboxNavSelectionEnum {
  all = 'all',
  assigned = 'assigned',
  unassigned = 'unassigned',
  mentions = 'mentions',
}
export enum OnAssignedChangeActionEnum {
  assigned = 'assigned',
  unassigned = 'unassigned',
}

export interface IInboxContext {
  params: {
    orgName: string;
  };
  loading: boolean;
  inbox?: GetInboxQuery['inbox'];
  inboxNavSelection: InboxNavSelectionEnum;
  setInboxNavSelection: Dispatch<SetStateAction<InboxNavSelectionEnum>>;
  refetchInbox?: () => void;
  notifyTaskDeleted?: (taskId: string) => void;
  /**
   * Notify our InboxContext that a task has been assigned or unassigned so that we can update our
   * internal state and force a re-render of the UI. This is essential so that the task moves to the
   * different inbox category.
   * @param taskId The ID of the task to update
   * @param action Whether we're assigning or unassigning the task
   * @param assignee The email address of the assignee, or undefined when we're unasigning
   */
  notifyAssignedChange?: (
    taskId: string,
    action: OnAssignedChangeActionEnum,
    assignee?: string,
  ) => void;
}

export const InboxContext = createContext({} as IInboxContext);

export const InboxProvider = ({ children }) => {
  const toast = useToast();
  const { account } = useAuth();
  const { selectedOrganization } = useUserSession();
  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties =
    permissions?.includes('canManageProperties') || account.permissions.admin;
  const orgName = selectedOrganization?.name;
  const [inbox, setInbox] = useState<GetInboxQuery['inbox']>();
  const [inboxNavSelection, setInboxNavSelection] = useState<InboxNavSelectionEnum>(
    InboxNavSelectionEnum.all,
  );

  const onError = (error) => {
    console.error(error);
    toast({ title: 'Could not get inbox items', type: 'danger' });
    setInbox(undefined);
  };

  const onCompleted = (data) => {
    setInbox({
      ...inbox,
      ...data?.inbox,
    });
  };

  const { loading: inboxLoading, refetch: refetchGetInboxQuery } = useGetInboxQuery({
    // https://github.com/apollographql/apollo-client/issues/11151
notifyOnNetworkStatusChange: true,
    
onCompleted: onCompleted,
    
    onError: onError,
    skip: !orgName || !canManageProperties,
    variables: {
      input: { orgName },
    },
  });

  useEffect(() => {
    setInbox(undefined);
  }, [selectedOrganization?.id]);

  const refetchInbox = () => {
    if (!inboxLoading && !!orgName && canManageProperties) {
      refetchGetInboxQuery({
        input: {
          orgName,
        },
      });
    }
  };

  const notifyTaskDeleted = (taskId: string) => {
    setInbox({
      ...inbox,
      all: inbox?.all?.filter((task) => task.itemID !== taskId),
      mentions: inbox?.mentions?.filter((task) => task.itemID !== taskId),
    });
  };

  const notifyAssignedChange = (
    taskId: string,
    action: OnAssignedChangeActionEnum,
    assignee?: string,
  ) => {
    if (action === OnAssignedChangeActionEnum.assigned) {
      const newer = JSON.parse(JSON.stringify(inbox.all));
      newer.find((task) => task.itemID === taskId).assignee = assignee;
      newer.find((task) => task.itemID === taskId).assigneeProfile = {
        email: assignee,
      };
      setInbox({
        ...inbox,
        all: [...newer],
      });
    } else if (action === OnAssignedChangeActionEnum.unassigned) {
      const newer = JSON.parse(JSON.stringify(inbox.all));
      newer.find((task) => task.itemID === taskId).assignee = assignee;
      newer.find((task) => task.itemID === taskId).assigneeProfile = undefined;
      setInbox({
        ...inbox,
        all: [...newer],
      });
    }
  };

  const inboxContextValue: IInboxContext = {
    inbox,
    inboxNavSelection,
    loading: inboxLoading,
    notifyAssignedChange,
    notifyTaskDeleted,
    params: {
      orgName,
    },
    refetchInbox,
    setInboxNavSelection,
  };

  return <InboxContext.Provider value={inboxContextValue}>{children}</InboxContext.Provider>;
};

export const useInboxContext = () => useContext(InboxContext);
