import { useMutation } from '@apollo/client';
import { useToast } from 'ui';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';
import { IUpdateAttributeMetadataVariable } from '@app/queries/propertyMetadata/types';
// FIX ME
// @ts-ignore
import UPDATE_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/updateAttributeMetadata.gql';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { GroupedMetadata, PropertyMetadata } from '../types';
import { toGraphqlInput } from '../utils';

type Return = (attribute: GroupedMetadata, checked: boolean) => Promise<void>;

export default (parentAttribute?: PropertyMetadata): Return => {
  const { checkboxes, organizationId, setCheckboxes, streamId } =
    useAttributeMetadataEditorContext();
  const [updateAttributeMetadata] = useMutation<Boolean, IUpdateAttributeMetadataVariable>(
    UPDATE_ATTRIBUTE_METADATA,
  );
  const toast = useToast();

  // When `includeInStreams` is checked, update the attribute metadata with the new metadata
  const handleUpdate = async (attribute: GroupedMetadata, checked: boolean) => {
    const updatedMetadata = { ...attribute, includeInStreams: checked };

    setCheckboxes((prevState) => ({ ...prevState, [attribute.name]: checked }));

    await updateAttributeMetadata({
      refetchQueries: [
        {
          query: GET_ORGANIZATION_ATTRIBUTE_METADATA,
          variables: {
            organizationId,
            streamId,
          },
        },
      ],
      variables: {
        input: {
          metadata: toGraphqlInput(updatedMetadata),
          organizationId,
          streamId,
        },
      },
    }).catch(() => {
      toast({ title: 'Could not update metadata', type: 'danger' });
    });
  };

  const updateBoolean = async (attribute: GroupedMetadata, checked: boolean) => {
    const isCheckboxAChildAttribute = !!attribute.parent;
    const hasParentAttribute = !!parentAttribute;

    // If the attribute is a child attribute and we have parentAttribute data, then when
    // `includeInStreams` for the parent is `false` and the child attribute is being checked to
    // true, also check the parent attribute.
    if (isCheckboxAChildAttribute && hasParentAttribute) {
      const parentCheckboxValue = checkboxes[parentAttribute?.name];
      const parentIncludeInStreams = parentAttribute?.includeInStreams;

      // Determine if the parent is currently false. If the parent's `includeInStreams` checkbox has
      // already been updated, then grab that value from the `checkboxes` record. Otherwise,
      // determine the parent's `includeInStreams` value from its metadata.
      const parentIncludeInStreamsFinal =
        parentCheckboxValue !== undefined ? parentCheckboxValue : parentIncludeInStreams;

      // If the parent's `includeInStreams` value is currently false, and the child attribute is
      // being checked to true, then also update the parent's `includeInStreams` value to true.
      if (checked && !parentIncludeInStreamsFinal) {
        await handleUpdate(parentAttribute, true);
      }
    }

    // Update the main attribute
    await handleUpdate(attribute, checked);
  };

  return updateBoolean;
};
