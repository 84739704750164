import gql from 'graphql-tag';
import { FRAGMENT_GRADE } from './fragments';
import { Grade } from './getCarrierAssessment';

export const GET_CARRIER_PROPERTY_GRADE = gql`
  ${FRAGMENT_GRADE}
  query GetCarrierPropertyGrades($input: CarrierPropertyGradesInput!) {
    carrierPropertyGrades(input: $input) {
      properties {
        carrierPropertyId
        street
        city
        state
        country
        sicCode
        sicDescription
        tiv
        tivPd
        reRiskGrade {
          ...grade
        }
        pdRiskGrade {
          ...grade
        }
        biRiskGrade {
          ...grade
        }
        hazardGrade {
          ...grade
        }
        floodGrade {
          ...grade
        }
        windstormGrade {
          ...grade
        }
        earthquakeZoneGrade {
          ...grade
        }
        hailGrade {
          ...grade
        }
        tornadoGrade {
          ...grade
        }
      }
      totalCount
    }
  }
`;

export interface CarrierPropertyGradesVariables {
  input: {
    streamId: string;
    reportName: string;
    filter: {
      attribute: string;
      category?: string;
      categories?: string[];
      offset: number;
      limit: number;
    };
    sortBy?: {
      attributeName: string;
      order: string;
    }[];
  };
}

export interface CarrierPropertyGrade {
  tivPd: number;
  carrierPropertyId: string;
  street: string;
  city: string;
  state: string;
  country: string;
  sicCode: number;
  sicDescription: string;
  tiv: number;
  reRiskGrade: Grade;
  pdRiskGrade: Grade;
  biRiskGrade: Grade;
  hazardGrade: Grade;
  floodGrade: Grade;
  windstormGrade: Grade;
  earthquakeZoneGrade: Grade;
  hailGrade: Grade;
  tornadoGrade: Grade;
}

export interface CarrierPropertyGradesData {
  carrierPropertyGrades: {
    totalCount: number;
    properties: Array<CarrierPropertyGrade>;
  };
}
