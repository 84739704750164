import styled from '@emotion/styled';
import { EuiHorizontalRule, EuiText } from 'ui';

export const Title = styled(EuiText)`
  margin-bottom: 8px;
`;

export const StyledHorizontalLine = styled(EuiHorizontalRule)`
  margin-bottom: 8px;
`;
