import styled from '@emotion/styled';

export const Explorer = styled.div`
  padding: 0 8px 8px 8px;
  border-bottom: 1px solid #e0e0e0;

  * {
    overflow: visible !important;
  }
`;
