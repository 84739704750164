"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("@emotion/css");
var child = (0, css_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 16px;\n"], ["\n  margin-left: 16px;\n"])));
var select = (0, css_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .euiContextMenuItem {\n    border: 1px solid green !important;\n  }\n"], ["\n  .euiContextMenuItem {\n    border: 1px solid green !important;\n  }\n"])));
var parent = function (textBlack) { return (0, css_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), "".concat(textBlack, " !important")); };
exports.default = {
    child: child,
    select: select,
    parent: parent,
};
var templateObject_1, templateObject_2, templateObject_3;
