import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type StatsInfoFragment = { __typename: 'PropertyGroupStats', avgBuildYear?: number | null, avgSquareFeet?: number | null, avgValue?: number | null, tivChart?: { __typename?: 'SparkChart', sum: number, percentageOfTotalTiv: number, tivYoyPctDiff?: number | null, trend?: Array<{ __typename?: 'TotalTivPerYear', sum?: number | null, year: number }> | null } | null, propertiesChart?: { __typename?: 'PropertiesChart', propertyCount?: number | null, propertyYoYPctDiff?: number | null } | null, occupancyType?: { __typename?: 'DonutChart', label: string, data: Array<{ __typename?: 'FloatTupple', name: string, value: number }> } | null, constructionType?: { __typename?: 'DonutChart', label: string, data: Array<{ __typename?: 'FloatTupple', name: string, value: number }> } | null, lossCostTrend?: { __typename?: 'LossCostTrendChart', avgLossCost?: { __typename?: 'YearsFloat', numYears: number, value: number } | null, trend?: Array<{ __typename?: 'TrendValue', value?: number | null }> | null } | null, totalGrossLoss?: { __typename?: 'YearsFloat', value: number, numYears: number } | null, totalLossCount?: { __typename?: 'YearsInt', value: number, numYears: number } | null, avgLossCost?: { __typename?: 'YearsFloat', value: number, numYears: number } | null };

export const StatsInfoFragmentDoc = gql`
    fragment statsInfo on PropertyGroupStats {
  __typename
  tivChart {
    trend {
      sum
      year
    }
    sum
    percentageOfTotalTiv
    tivYoyPctDiff
  }
  propertiesChart {
    propertyCount
    propertyYoYPctDiff
  }
  avgBuildYear
  occupancyType {
    label
    data {
      name
      value
    }
  }
  constructionType {
    label
    data {
      name
      value
    }
  }
  avgSquareFeet
  avgValue
  lossCostTrend {
    avgLossCost {
      numYears
      value
    }
    trend {
      value
    }
  }
  totalGrossLoss {
    value
    numYears
  }
  totalLossCount {
    value
    numYears
  }
  avgLossCost {
    value
    numYears
  }
}
    `;