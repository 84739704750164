import gql from 'graphql-tag';

export const GET_EXPOSURE_SNAPSHOTS = gql`
  query exposureAvailableSnapshots($input: ExposureAvailableSnapshotsInput!) {
    exposureAvailableSnapshots(input: $input) {
      snapshotName
      date
      displayDate
    }
  }
`;
export interface GetExposureSnapshotsData {
  exposureAvailableSnapshots?: ExposureSnapshot[];
}

export interface ExposureSnapshot {
  date: string;
  displayDate?: string;
  snapshotName: string;
  snapshotDate?: string;
}

export interface GetExposureSnapshotsVariables {
  input: {
    streamSlug: string;
  };
}
