import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ModalContext {
  isModalOpen: boolean;
  modalContent: ReactNode;
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
}

export const ModalContext = createContext({} as ModalContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(<div />);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const showModal = (content: ReactNode) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        isModalOpen,
        modalContent,
        showModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalState = () => {
  const context = useContext(ModalContext);
  return context;
};
