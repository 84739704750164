import React from 'react';
import HorizontalBarChart from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/HorizontalBarChart';
import { BarsData } from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/types';
import QualityScoreKey from '@onarchipelago/cx/Reports/ReadinessReport/QualityScoreKey';
import { qualityScoresForAddress } from '@onarchipelago/cx/Reports/ReadinessReport/utils';
import { EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
import CoreAttributesBeforeAfterRow from './CoreAttributesBeforeAfterRow';
import { KeyTitle, KeyTitleText } from './CoreAttributesBeforeAndAfter.emotion';

interface Props {
  charts: {
    data: BarsData;
    title: string;
  }[];
}

const CoreAttributesBeforeAfterComponent: React.FC<Props> = ({ charts }) => (
  <EuiFlexGroup direction="column">
    <EuiFlexItem grow={false}>
      <EuiText>
        <h3>Core Attributes Data Quality Before and After</h3>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <KeyTitle>
        <QualityScoreKey />
      </KeyTitle>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <HorizontalBarChart bars={charts[0].data} showKey={false} title={charts[0].title} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="none">
            <Spacer size="m" />
            <EuiFlexItem grow={false}>
              <KeyTitleText size="s">
                <strong>Address Data Quality:</strong>
              </KeyTitleText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <QualityScoreKey items={qualityScoresForAddress} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size="m" />
      <CoreAttributesBeforeAfterRow charts={[charts[1], charts[2]]} />
      <Spacer size="m" />
      <CoreAttributesBeforeAfterRow charts={[charts[3], charts[4]]} />
      <Spacer size="m" />
      <CoreAttributesBeforeAfterRow charts={[charts[5], charts[6]]} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default CoreAttributesBeforeAfterComponent;
