import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EuiFormRow, EuiFormRowProps, TextField, TextFieldProps } from 'ui';

export interface IFormTextInputProps {
  name: string;
  inputProps?: Omit<TextFieldProps, 'onChange'>;
  formRowProps?: EuiFormRowProps;
  onChange?: (value: string) => void;
}

export const FormTextInput = ({
  name,
  inputProps = {},
  formRowProps = {} as EuiFormRowProps,
  onChange,
}: IFormTextInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const formInputProps = register(name);

  const inputError = errors?.[name];

  const handleChange: TextFieldProps['onChange'] = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
    formInputProps.onChange(event);
  };

  return (
    <EuiFormRow
      id="form-text-input"
      isInvalid={!!inputError}
      error={inputError?.message}
      {...formRowProps}
    >
      <TextField
        aria-label="form-text-input"
        {...inputProps}
        {...formInputProps}
        onChange={handleChange}
        invalid={!!inputError} // TODO: refactor TextField to switch prop to isInvalid to match the underlying EuiFieldText
      />
    </EuiFormRow>
  );
};
