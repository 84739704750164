import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { MODE } from '@onarchipelago/cx/Stream/Distillers/types';
import GroupedList from '@app/components/GroupedList/GroupedList';
import { HandleClick, IDisclosure } from '@app/components/GroupedList/types';
import PropertiesGroupedListRow from './PropertiesGroupedListRow';
import {
  Children,
  Groups,
  IGroupedProps,
  IPropertiesProps,
  IPropertyGroupRow,
  IRow,
  ISize,
} from './types';
import { decorateRows } from './utils';

export enum DIR {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING',
}

interface IProps {
  mode: MODE;
  rows: Array<IPropertyGroupRow<IGroupedProps | IPropertiesProps>>;
  groups: Groups;
  children: Children;
  handleClick?: HandleClick;
  disclosures: Array<IDisclosure>;
  loading: boolean;
}

const DEFAULT_DURATION = 200;

const Row = styled.div`
  label: PropertiesGroupedListRow;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  box-shadow: 0px 1px 0px #e0e0e0;
  color: #333;
  margin-bottom: 1px;
  background: white;
  overflow-x: hidden;

  &:hover {
    overflow-x: auto;
  }
`;

const PropertiesGroupedList: FC<IProps> = ({
  mode,
  rows,
  groups,
  children,
  handleClick,
  disclosures,
  loading,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState<ISize>();

  /* Set up a listener on window resize; we set the properties grid
   * to have a maximum height of window minus any open rows
   * and filters, to enable scrolling of the virtualized window.
   * So we need an accurate measure of its surrounding box.
   */
  useEffect(() => {
    const current = ref.current;
    if (current) {
      const resize = () => {
        setContainerSize({
          height: current.offsetHeight,
          width: current.offsetWidth,
        });
      };
      resize();
      window.addEventListener('resize', resize);
      return () => {
        window.removeEventListener('resize', resize);
      };
    }

    return () => {};
  }, [ref]);

  let decoratedRows: Array<IRow> = [];
  if (containerSize !== undefined) {
    decoratedRows = decorateRows(mode, rows, containerSize, groups);
  }

  // const focusAt = groups.length + 1;
  const focusAt = groups.length;

  return (
    <GroupedList
      ref={ref}
      rows={decoratedRows}
      overscan={1}
      focusAt={focusAt}
      duration={DEFAULT_DURATION}
      handleClick={handleClick}
      disclosures={disclosures}
    >
      {({ options, props, indices, parent, visible }) => (
        <Row
          data-tourid={`properties-grouped-list-row-${indices.join(',')}`}
          data-testid={`properties-grouped-list-row-${indices.join(',')}`}
        >
          <PropertiesGroupedListRow
            props={props}
            options={options}
            indices={indices}
            parent={parent}
            visible={visible}
            loading={loading}
            duration={DEFAULT_DURATION}
          >
            {children}
          </PropertiesGroupedListRow>
        </Row>
      )}
    </GroupedList>
  );
};

export default PropertiesGroupedList;

export { groupProperties } from './utils';
