import React from 'react';
import { Button } from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { GroupedMetadata } from '../types';
import { getMetadataBlob } from '../utils';

interface Props {
  data: Array<GroupedMetadata>;
}

const DownloadButton: React.FC<Props> = ({ data }) => {
  const { organizationId, streamId } = useAttributeMetadataEditorContext();
  const blob = getMetadataBlob(organizationId, data, streamId);
  const fileName = `metadata_${organizationId}.json`;
  const href = URL.createObjectURL(blob);

  return (
    <Button
      data-testid="attribute-metadata-download-button"
      download={fileName}
      href={href}
      iconName="download"
      size="s"
      label="Download"
    />
  );
};

export default DownloadButton;
