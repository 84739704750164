import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import Highcharts from 'highcharts';
import HighchartsChart from 'highcharts-react-official';
import measure from '@app/utils/measure';

interface IProps {
  width: number;
  height: number;
  options: Highcharts.Options;
}

const Container = styled.div`
  label: ChartContainer;
  flex: 1;

  > div:first-of-type {
    overflow: visible !important;
  }

  svg {
    overflow: visible !important;
  }

  .highcharts-background {
    fill: transparent;
  }
`;
const Chart: FC<IProps> = ({ options, width, height }) => {
  const containerProps = useMemo(
    () => ({
      style: {
        height,
        width,
      },
    }),
    [height, width],
  );
  return (
    <Container>
      <HighchartsChart
        containerProps={containerProps}
        highcharts={Highcharts}
        options={{
          ...options,
          exporting: {
            enabled: false,
          },
        }}
      />
    </Container>
  );
};

export default measure(Chart);
