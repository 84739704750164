import React, { FC, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { EuiFlexGroup, EuiFlexItem, Spacer } from 'ui';
import { OrgSwitcherButton } from '@app/cx/OrgSwitcher/OrgSwitcherButton';
import HeaderUserMenuDropDown from './HeaderUserMenuDropDown';

const JOBS_PATH = '/jobs';

const HeaderUserMenu: FC<{ showOrgSwitcher: () => void }> = ({ showOrgSwitcher }) => {
  const jobsURL = useRouteMatch(JOBS_PATH);
  const isMyJobPage = !!jobsURL?.isExact;

  const [isOpen, setIsOpen] = useState(false);

  const onOrgSwitcherClick = () => {
    setIsOpen(false);
    showOrgSwitcher();
  };

  return (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem grow={false}>
        <OrgSwitcherButton onClick={onOrgSwitcherClick} isMyJobPage={isMyJobPage} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <HeaderUserMenuDropDown isOpen={isOpen} setIsOpen={setIsOpen} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Spacer size="s" />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default HeaderUserMenu;
