import React, { useEffect } from 'react';
import { EuiDataGridCellPopoverElementProps, EuiDataGridRefProps } from 'ui';
import { Property } from '@app/graphql/types';
import { usePropertiesDataGridContext } from '../context/PropertiesDataGridContext';
import { EditCellPopoverContent } from './EditCellPopoverContent/EditCellPopoverContent';

interface ICellPopoverContentProps extends EuiDataGridCellPopoverElementProps {
  dataGridRef: React.MutableRefObject<EuiDataGridRefProps>;
  property: Property;
}

export const CellPopoverContent = ({
  dataGridRef,
  property,
  ...dataGridPopoverProps
}: ICellPopoverContentProps) => {
  const { DefaultCellPopover, columnId, setCellPopoverProps } = dataGridPopoverProps;
  const { isEditingCell, setIsEditingCell } = usePropertiesDataGridContext();

  useEffect(() => {
    setCellPopoverProps({
      initialFocus: '#form-text-input',
      panelPaddingSize: 'm',
    });
  }, [setCellPopoverProps]);

  useEffect(() => () => {
      setIsEditingCell(false);
    }, []);

  if (!isEditingCell) {
    return <DefaultCellPopover {...dataGridPopoverProps} />;
  }

  return (
    <EditCellPopoverContent dataGridRef={dataGridRef} columnId={columnId} property={property} />
  );
};
