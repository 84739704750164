import { AttributeFilter } from '@app/graphql/types';
import { IServerFilter } from './types';

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export const buildSparklineData = (
  payload: Array<{
    sum?: number;
    value?: number;
  }>,
  isTIV?: boolean,
) => {
  const dataPoints = [];

  for (let i = 0; i < payload.length; i++) {
    isTIV ? dataPoints.push(payload[i].sum) : dataPoints.push(payload[i].value);
  }
  return dataPoints;
};

export const buildConstructionData = (payload: Array<any>) => {
  const dataPoints = [];

  for (let i = 0; i < payload.length; i++) {
    dataPoints.push({
      label: payload[i].construction_type,
      value: payload[i].percentage_by_construction,
    });
  }

  return dataPoints;
};

export const buildOccupancyData = (payload: Array<any>) => {
  const dataPoints = [];

  for (let i = 0; i < payload.length; i++) {
    dataPoints.push({
      label: payload[i].occupancy_type,
      value: payload[i].percentage_by_occupancy,
    });
  }

  return dataPoints;
};

interface IChangeData {
  amount?: number;
  label?: string;
  since?: string;
}
const defaultHistoricalData: IChangeData = {
  amount: undefined,
  label: 'Prev. bind N/A',
};

export const buildChangeData = (change: number, previousSnapshot?: string): IChangeData => {
  if (change === null) {
    return defaultHistoricalData;
  }

  return {
    amount: change,
    since: previousSnapshot,
  };
};

export const formatPercentage = (value = 0, multiply = true) => {
  const percentage = (multiply ? value * 100 : value).toFixed(2);

  return parseFloat(percentage);
};

type CreateServerFilter = (filters: Array<AttributeFilter>) => Array<IServerFilter>;
const createServerFilter: CreateServerFilter = (filters) =>
  filters
    .map((filter) => ({
      field: filter.name,
      operator: filter.operator,
      value: `${filter.values[0]}`,
    }))
    .filter(notUndefined);

const getFiltersAsString = (filters: Array<AttributeFilter>) =>
  encodeURIComponent(
    JSON.stringify({
      querybuilder: {
        combinator: 'AND',
        rules: createServerFilter(filters),
      },
    }),
  );

type GetQs = (params: {
  name: string;
  snapshot: string;
  filters: Array<AttributeFilter>;
}) => string;

export const getQs: GetQs = ({ name, snapshot, filters }) => {
  const filterArr = Object.values(filters);
  return [
    filterArr.length ? `filter=${getFiltersAsString(filterArr)}` : undefined,
    `name=${name}`,
    `snapshot=${snapshot}`,
  ]
    .filter(notUndefined)
    .join('&');
};
