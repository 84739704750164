import React, { useEffect, useState } from 'react';
import {
  Avatar,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  MultiSelect,
  Toggle,
} from 'ui';
import { useTaskContext } from './context/TaskContext';
import { StyledBadge } from './TaskTable.emotion';

import './pill.css';

export const Filters = () => {
  const { filters } = useTaskContext();

  const searchUsers = (options): void => {
    filters.setFilter(
      'users',
      options.map((option) => option.value),
    );
  };

  const searchDocs = (options): void => {
    filters.setFilter(
      'documents',
      options.map((option) => option.value),
    );
  };

  const searchReasons = (options): void => {
    filters.setFilter(
      'reasons',
      options.map((option) => option.value),
    );
  };

  const onSearch = (value: string) => {
    filters.setFilter('name', value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <EuiFlexGroup gutterSize="m" justifyContent="spaceBetween">
      <EuiFlexItem grow={2}>
        <EuiFieldSearch
          data-testid="task-property-search"
          placeholder="Search properties"
          value={filters.name}
          onChange={onChange}
          onSearch={onSearch}
          isClearable
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <MultiSelect
          key={filters.users.length}
          data-testid="task-hazard-filter-select"
          placeholder="All assignees"
          options={filters.userOptions}
          //@ts-ignore
          renderOption={(option, searchValue) => {
            const { label } = option;

            return (
              <EuiFlexGroup gutterSize="s">
                <EuiFlexItem grow={false}>
                  <Avatar name={label} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }}
          onChange={searchUsers}
          initialSelected={filters.userOptions.filter((option) =>
            filters.users.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={3}>
        <MultiSelect
          key={filters.documents.length}
          data-testid="task-hazard-category-select"
          placeholder="All documents"
          options={filters.documentOptions}
          onChange={searchDocs}
          initialSelected={filters.documentOptions.filter((option) =>
            filters.documents.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>

      <EuiFlexItem grow={3}>
        <MultiSelect
          key={filters.reasons.length}
          data-testid="task-reason-filter"
          //@ts-ignore
          renderOption={(option) => {
            return <StyledBadge color={option.color}>{option.label}</StyledBadge>;
          }}
          placeholder="All reasons"
          options={filters.reasonOptions}
          onChange={searchReasons}
          initialSelected={filters.reasonOptions.filter((option) =>
            filters.reasons.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem style={{ justifyContent: 'center' }} grow={4}>
        <Toggle
          label="Show dismissed"
          checked={filters.dismissed}
          name="show pe docs toggle"
          onChange={(event) => {
            filters.setFilter('dismissed', event.target.checked.toString());
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
