import styled from '@emotion/styled';

export const Container = styled.div`
  label: TourContainer;
  div[data-tour-elem='controls'] {
    justify-content: center;

    button {
      color: white;
      opacity: 0.6;

      &:hover {
        color: inherit;
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0;
      }
    }

    nav[data-tour-elem='navigation'] {
      button {
        &:disabled {
          opacity: 0.2;
        }

        &:nth-child(2n + 1) {
          margin-right: -12px;
          z-index: 2;
          opacity: 0;
        }

        &:nth-child(2n + 2) {
          pointer-events: none;
          z-index: 1;
        }

        &:before {
          display: none;
        }
      }

      button.reactour__dot--is-active {
        opacity: 1;
      }
    }
  }

  #___reactour {
    > div {
      opacity: 1;
      z-index: 2;
    }

    svg {
      defs {
        display: none;
      }
    }
  }
`;
