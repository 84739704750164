import React, { createContext, ReactElement, useContext } from 'react';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import useGetSnapshotsData from './useGetSnapshotsData/useGetSnapshotsData';
import hazardFilterOptions from './hazardFilterOptions';
import { ReportsContext as IReportsContext } from './types';
import useGetStreamData from './useGetStreamData';

// ----- CONTEXT INITIALIZATION ----- //

const initialReportsState: IReportsContext = {
  snapshots: {
    afterSnapshot: undefined,
    afterSnapshotOptions: [],
    currentHazard: hazardFilterOptions[0].value,
    currentSnapshot: undefined,
    hazardOptions: undefined,
    loading: false,
    setAfterSnapshot: () => {},
    setCurrentHazard: () => {},
    setCurrentSnapshot: () => {},
    snapshotOptions: [],
  },
  stream: {
    displayCurrency: undefined,
    hasError: false,
    loading: false,
    orgId: undefined,
    orgName: undefined,
    streamSlug: '',
  },
};

export const ReportsContext = createContext<IReportsContext>(initialReportsState);

// ----- PROVIDER ----- //

export const ReportsContextProvider = (props: object): ReactElement => {
  // FIX ME
  // @ts-ignore
  const { streamSlug } = useDecodedParams();

  // FIX ME
  // @ts-ignore
  const streamData = useGetStreamData(streamSlug);
  const snapshotsData = useGetSnapshotsData(streamSlug);

  return (
    <ReportsContext.Provider
      value={{
        snapshots: { ...snapshotsData },
        stream: { ...streamData },
      }}
      {...props}
    />
  );
};

// ----- USE CONTEXT HOOK ----- //

export const useReportsContext = (): IReportsContext => useContext(ReportsContext);
