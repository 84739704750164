import React, { FC } from 'react';
import { Checkbox, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip, Icon } from 'ui';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import styles from './AttributeItem.emotion';

interface Props {
  isEnrichmentJob?: boolean;
  item: IGraphQLAttributeMetadata;
  selectedAttributes: any;
  updateSelectedAttributes: (selectedAttributes: IGraphQLAttributeMetadata[]) => void;
}

const AttributeItem: FC<Props> = ({ item, selectedAttributes, updateSelectedAttributes }) => {
  const { derived, displayName, name } = item;

  const isSelected = selectedAttributes
    ? selectedAttributes.some((a: IGraphQLAttributeMetadata) => a.name === name)
    : false;

  const toggleSelected = () => {
    if (!isSelected) {
      // eslint-disable-next-line
      selectedAttributes = [...(selectedAttributes || []), item];
      updateSelectedAttributes(selectedAttributes);
    } else {
      // eslint-disable-next-line
      selectedAttributes = selectedAttributes.filter(
        (a: IGraphQLAttributeMetadata) => a.name !== name,
      );
      updateSelectedAttributes(selectedAttributes);
    }
  };

  return (
    <div
      data-testid={`attribute-item-${displayName.split(' ').join('-')}`}
      className={styles.buttonContainer}
    >
      <EuiFlexGroup
        className={styles.childItemFlexGroupContainer}
        alignItems="center"
        justifyContent="spaceBetween"
      >
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiText className={[derived ? styles.disabledText : styles.text].join(' ')}>
                {displayName}
              </EuiText>
            </EuiFlexItem>
            {derived && (
              <EuiFlexItem grow={false}>
                <EuiToolTip content="This attribute is automatically calculated.">
                  <Icon color="primary" name="info" />
                </EuiToolTip>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Checkbox
            data-testid={`attribute-item-checkbox-${displayName}`}
            disabled={derived}
            id={`attribute-item-checkbox-${displayName}`}
            checked={isSelected}
            onChange={toggleSelected}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default AttributeItem;
