export default [
  {
    children: [
      {
        value: 'ID',
      },
    ],
    value: 'Summary',
  },
  {
    children: [
      {
        value: 'Name',
      },
      {
        value: 'Location',
      },
      {
        value: 'Geo-coding',
      },
    ],
    value: 'Building Location',
  },
  {
    children: [],
    value: 'Hazards',
  },
  {
    children: [],
    value: 'Valuations',
  },
  {
    children: [],
    value: 'Occupancy',
  },
  {
    children: [
      {
        value: 'Ownership',
      },
      {
        value: 'Inspection Programs',
      },
    ],
    value: 'Stewardship',
  },
  {
    children: [
      {
        value: 'Year Built',
      },
      {
        value: 'Floor Area',
      },
      {
        value: 'Building Characteristics',
      },
    ],
    value: 'Building Characteristics',
  },
  {
    children: [],
    value: 'Construction',
  },
  {
    children: [
      {
        value: 'Roof',
      },
      {
        value: 'Rooftop Appendages',
      },
      {
        value: 'Roof Lifecycle',
      },
      {
        value: 'Cladding',
      },
      {
        value: 'Fenestration',
      },
    ],
    value: 'Building Envelope',
  },
  {
    children: [],
    value: 'Fire Protection',
  },
  {
    children: [
      {
        value: 'Permanent Flood Measures',
      },
      {
        value: 'Emergency Flood Measures',
      },
    ],
    value: 'Flood Risk',
  },
  {
    children: [],
    value: 'Modelling',
  },
];
