import styled from '@emotion/styled';

export const Container = styled.div`
  label: StreamContainer;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1 1;
  margin: 20px;
  transition: margin-right 100ms;
  // only reason it isn't 0 for margin is to give the toggle button some spacing per mockup
  margin-right: 15px;
  width: calc(100% - 360px);

  p {
    margin: 0;
  }
`;

export const ReefContainer = styled.div`
  label: StreamPageReefContainer;
  flex: 1 1;
  display: flex;
  flex-direction: column;
`;

export const ElasticShim = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 100%;
  max-width: 100%;
  overflow: hidden;
`;
