import gql from 'graphql-tag';

// ----- QUERY ----- //

export const GET_ENRICHMENT_PROPERTY_PRIORITY = gql`
  query EnrichmentPropertyPriority($input: EnrichmentPropertyPriorityInput!) {
    enrichmentPropertyPriority(input: $input) {
      dqsImprovementPct
      propertiesCount
      suggestedProperties {
        clientID
        locationName
      }
    }
  }
`;

// ----- TYPES ----- //

export interface EnrichmentPropertyPriorityData {
  enrichmentPropertyPriority: {
    dqsImprovementPct: number;
    propertiesCount: number;
    suggestedProperties: {
      clientID: string;
      locationName: string;
    }[];
  };
}

export interface EnrichmentPropertyPriorityVariables {
  input: {
    amountOfProperties: number;
    snapshotNameAfter: string;
    snapshotNameBefore: string;
    streamSlug: string;
  };
}
