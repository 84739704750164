import React from 'react';
import useAxios from 'axios-hooks';
import { EuiPanel, EuiTitle } from 'ui';
import { COPILOT_URL } from '@app/config';
import { Property } from '@app/graphql/types';
import { useCopilotContext } from '../../context/context';
import { IWorkspace } from '../../types';
import { AttributesTable } from './AttributesTable';
import { PropertyTabReportContainer } from './PropertyTab.emotion';
import { AttributeTableRow } from './types';

interface Props {
  workspaceID: string;
}

export const PropertyTabReport: React.FC<Props> = ({ workspaceID }) => {
  const {
    workspaceData,
    attributesQuery,
    submitCorrection,
    propertiesByAIDsQuery,
    sovPropertyAttributes,
  } = useCopilotContext();

  const workspaceSettings = workspaceData[workspaceID] as IWorkspace;
  const { attributeName, propertyArchipelagoID } = workspaceData?.searchFormValues;
  const analyzeData = workspaceSettings.analyzeData;

  const attribute = attributesQuery?.dict?.[attributeName];

  const { data, loading, refetch } = propertiesByAIDsQuery;

  const property = data?.propertiesByAiDs?.properties?.[0];

  const [, execRate] = useAxios(
    { method: 'PUT', url: `${COPILOT_URL}/reports/${analyzeData?.humanloopDataID}/rating` },
    { manual: true },
  );

  const getAttributeValues = (): Array<AttributeTableRow> => [
    {
      copilotValue: analyzeData?.response?.value,
      finalValue: analyzeData?.response?.value,
      currentValue: property?.[attributeName] || '(Blank)',
      displayName: attribute?.metadata?.displayName,
      explanation: analyzeData?.response?.explanation,
      id: attributeName,
      name: attributeName,
      sources: analyzeData?.sources,
      sovValue: sovPropertyAttributes?.[attributeName] || '(Blank)',
    },
  ];

  return (
    <PropertyTabReportContainer grow={4}>
      <EuiPanel paddingSize="l">
        <EuiTitle size="s">
          <h2>Property: {loading ? '' : property?.locationName || propertyArchipelagoID}</h2>
        </EuiTitle>
        <EuiTitle size="s">
          <h2>Attribute: {attribute?.metadata?.displayName}</h2>
        </EuiTitle>
        {attributeName ? (
          <AttributesTable
            items={getAttributeValues()}
            onCorrection={(_, value) => submitCorrection(workspaceID, value)}
            property={property as Property}
            onPropertyUpdate={refetch}
            onRating={(_, rating) => execRate({ data: { rate: rating } })}
            expanded={!!analyzeData}
          />
        ) : (
          <span>Select an attribute to get started</span>
        )}
      </EuiPanel>
    </PropertyTabReportContainer>
  );
};
