import {
  PropertyAssessmentDetail,
  PropertyDetail,
  PropertyGradeDetail,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { getPropertyAssessmentData } from './OverallGrade/propertyAssessmentTableData';
import { getPropertyGradeData } from './OverallGrade/propertyGradeTableData';
import { OverallTableData } from './OverallGrade/tableData';
import { RiskAttribute } from './types';

export const getOverallGradeData = (
  currentRiskAttribute: RiskAttribute,
  propertyGradeDetails: Array<PropertyGradeDetail>,
  propertyAssessmentDetails: Array<PropertyAssessmentDetail>,
): OverallTableData | null => {
  let current: PropertyDetail | null = null;

  if (currentRiskAttribute.type === 'grade') {
    current = propertyGradeDetails.find((p) => p.attribute === currentRiskAttribute.value);
    return getPropertyGradeData(current as PropertyGradeDetail);
  }

  current = propertyAssessmentDetails.find((p) => p.attribute === currentRiskAttribute.value);
  return getPropertyAssessmentData(current as PropertyAssessmentDetail);
};
