import React from 'react';
import {
  EuiBadge,
  EuiHighlight,
  EuiSelectable,
  EuiSelectableOption,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';

interface OptionData {
  secondaryContent?: string;
}

export const SharedWithList = ({ users }) => {
  const options = [
    ...users.map(
      (user): EuiSelectableOption => ({
        append: (
          <EuiBadge color={`${user.typeV2 === 'Editor' ? 'primary' : 'lightgrey'}`}>
            {user.typeV2.toUpperCase()}
          </EuiBadge>
        ),
        data: {
          secondaryContent: user.typeV2,
        },

        label: `${user.email.toLowerCase()}`,
        searchableLabel: `${user.email.toLowerCase()} ${user.typeV2}`,
      }),
    ),
  ];

  const renderUserOption = (option: EuiSelectableOption<OptionData>, searchValue: string) => (
      <>
        <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
        <EuiText size="xs" color="subdued" className="eui-displayBlock">
          <small>
            <EuiHighlight search={searchValue}>{option.secondaryContent || ''}</EuiHighlight>
          </small>
        </EuiText>
      </>
    );

  return (
    <>
      <EuiSpacer size="s" />
      <EuiSelectable
        aria-label="Search users"
        searchable
        options={options}
        searchProps={{
          placeholder: 'Search',
        }}
        onChange={() => options}
        listProps={{
          rowHeight: 50,
          showIcons: false,
        }}
        renderOption={renderUserOption}
        height={200}
      >
        {(list, search) => (
          <>
            {search}
            {list}
          </>
        )}
      </EuiSelectable>
    </>
  );
};
