import React, { FC } from 'react';
import {
  CurrencyField,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  Select,
  Spacer,
  TextField,
} from 'ui';
import { ExposureBaseInputGl, LineOfBusiness, PolicyEffectiveYearId } from '@app/graphql/types';
import { useCasualtyData } from '../context/context';
import {
  AllPolicyEffectiveYearID,
  exposureBaseInputGlLabel,
  getDate,
  humanReadablePolicyEffectiveYear,
} from '../utils';

export const StepTwo: FC = () => {
  const { casualty, setCasualty, viewOnly } = useCasualtyData();

  return (
    <>
      <EuiText>
        <h3>Exposures</h3>
      </EuiText>
      <Spacer size="l" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <strong>Policy Year ID</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>AL</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>WC</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>GL</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size="xs" />
      {AllPolicyEffectiveYearID.map((label, index) => {
        const policyEffectiveYear = casualty?.exposures?.find(
          (exposure) => exposure.policyEffectiveYear === label,
        );

        const alDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Al,
        );
        const wcDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Wc,
        );
        const glDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Gl,
        );

        const setCurrencyValue = (
          label: PolicyEffectiveYearId,
          key: 'al' | 'wc' | 'gl',
          value: number,
        ) => {
          if (value < 0) {
            return;
          }
          let c = casualty;

          if (c?.exposures === undefined) {
            c = {
              ...c,
              exposures: [
                {
                  al: key === 'al' ? value : 0,
                  gl: key === 'gl' ? value : 0,
                  policyEffectiveYear: label,
                  wc: key === 'wc' ? value : 0,
                },
              ],
            };
          } else {
            const exists = c?.exposures?.find((e) => e.policyEffectiveYear === label);
            c = {
              ...c,
              exposures: exists
                ? c?.exposures.map((exposure) => {
                    if (exposure.policyEffectiveYear === label) {
                      return {
                        ...exposure,
                        [key]: value,
                      };
                    }
                    return exposure;
                  })
                : c?.exposures.concat(c?.exposures, {
                    al: key === 'al' ? value : 0,
                    gl: key === 'gl' ? value : 0,
                    policyEffectiveYear: label,
                    wc: key === 'wc' ? value : 0,
                  }),
            };
          }
          setCasualty({
            ...c,
          });
        };

        return (
          <div key={index}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="&nbsp;">
                  <>{humanReadablePolicyEffectiveYear(label)}</>
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!alDisabled ? (policyEffectiveYear?.al || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'al', e);
                  }}
                  disabled={alDisabled}
                  // prepend="$" AL is a Power Unit unit of measure. It should not have a dollar sign.
                  readOnly={!alDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!wcDisabled ? (policyEffectiveYear?.wc || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'wc', e);
                  }}
                  disabled={wcDisabled}
                  prepend="$"
                  readOnly={!wcDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!glDisabled ? (policyEffectiveYear?.gl || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'gl', e);
                  }}
                  disabled={glDisabled}
                  prepend="$"
                  readOnly={!glDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <Spacer size="xs" />
          </div>
        );
      })}
      <Spacer size="l" />
      <EuiText>
        <h3>Exposure Base</h3>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="AL">
            <>Exposure Base</>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="AL">
            <TextField
              value={casualty?.exposureBase?.al}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  exposureBase: {
                    ...casualty.exposureBase,
                    al: e.target.value,
                  },
                });
              }}
              readOnly
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="WC">
            <TextField
              value={casualty?.exposureBase?.wc}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  exposureBase: {
                    ...casualty.exposureBase,
                    wc: e.target.value,
                  },
                });
              }}
              readOnly
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="GL">
            {viewOnly ? (
              <TextField
                value={exposureBaseInputGlLabel(casualty?.exposureBase?.gl)}
                readOnly={true}
              />
            ) : (
              <Select
                onChange={(value) => {
                  setCasualty({
                    ...casualty,
                    exposureBase: {
                      ...casualty.exposureBase,
                      gl: value as ExposureBaseInputGl,
                    },
                  });
                }}
                options={Object.keys(ExposureBaseInputGl).map((v) => ({
                  label: exposureBaseInputGlLabel(v),
                  value: v,
                }))}
                value={casualty?.exposureBase?.gl}
              />
            )}
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
