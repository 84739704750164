import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useToast } from 'ui';
import { getErrorMessage } from '@app/utils/getErrorMessage';
// FIX ME
// @ts-ignore
import GET_ROLE_ASSIGNMENTS from './getRoleAssignments.gql';
import { RoleAssignmentsData, RoleAssignmentsVariables } from './types';

interface Return {
  data?: RoleAssignmentsData;
  handleSubmit: (values: Record<string, string>) => Promise<void>;
  isLoading: boolean;
  submittedValues?: Record<string, string>;
  clearData: () => void;
}

export default (): Return => {
  const toast = useToast();
  const [data, setData] = useState<RoleAssignmentsData | undefined>(undefined);

  const [getPropertyData, { loading: isLoading }] = useLazyQuery<
    RoleAssignmentsData,
    RoleAssignmentsVariables
  >(GET_ROLE_ASSIGNMENTS, {
    onCompleted: (d) => setData(d),
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
  });

  const [submittedValues, setSubmittedValues] = useState<Record<string, string>>();

  const handleSubmit = async (formValues: Record<string, string>): Promise<void> => {
    getPropertyData({
      variables: {
        principalId: formValues.principalId,
      },
    });
    setSubmittedValues(formValues);
  };

  const clearData = () => {
    setData(undefined);
  };

  return {
    data,
    handleSubmit,
    isLoading,
    submittedValues,
    clearData,
  };
};
