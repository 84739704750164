import styled from '@emotion/styled';
import { EuiFlexItem } from 'ui';

export const ChartAxisText = styled.span`
  margin-left: -8px;
`;

export const VerticalSpacer = styled(EuiFlexItem)`
  width: 0;
`;
