import React from 'react';
import styled from '@emotion/styled';
import { getEdits, getProvenance, ICell } from './Cell';

const InspectionsContainer = styled.div<{ active: boolean }>`
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  text-align: center;
  vertical-align: middle;

  ${({ active }) => {
    if (active) {
      // return active styles
      return {
        backgroundColor: 'rgb(39,174,96)',
        color: '#FFFFFF',
      };
    }
    // return inactive styles
    return {
      backgroundColor: 'rgb(242, 242, 242)',
      color: '#4F4F4F',
    };
  }}
`;

const isActive = (value: string = 'no'): boolean => {
  if (
    !value ||
    value.toLowerCase() === 'no' ||
    value.toLowerCase() === 'unknown' ||
    value.toLowerCase() === 'false' ||
    value.toLowerCase() === ''
  ) {
    return false;
  }
  return true;
};

const InspectionsIcon = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const property = props.prop;

  const {
    lossEngineeringReportPresent,
    roofInspectionProgram,
    fireProtectionInspectionProgram,
    generatorTestingProgram,
    hasSeismicInspectionReport,
  } = property;
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = (
    <>
      <InspectionsContainer active={isActive(lossEngineeringReportPresent)}>
        LE
      </InspectionsContainer>
      <InspectionsContainer active={isActive(roofInspectionProgram ? 'yes' : 'no')}>
        RI
      </InspectionsContainer>
      <InspectionsContainer active={isActive(fireProtectionInspectionProgram)}>
        FP
      </InspectionsContainer>
      <InspectionsContainer active={isActive(generatorTestingProgram)}>BG</InspectionsContainer>
      <InspectionsContainer active={!!hasSeismicInspectionReport}>SI</InspectionsContainer>
    </>
  );

  return { body, tooltip };
};

export default InspectionsIcon;
