import React, { useContext } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import { FEATURE_TYPE_SIMPLIFIED_SOV } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import EditStreamModal from '../EditModal/EditStreamModal';
import { PanelItem } from './types';

export default (): PanelItem => {
  const { showModal } = useContext(ModalContext);
  const { selectedOrganization } = useUserSession();

  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );
  const { stream } = isSimplifiedSOVEnabled ? usePropertiesPageContext() : useStreamContext();

  return {
    iconName: 'gear',
    label: `${STREAMS_LABEL} settings`,
    onClick: () =>
      showModal(
        <EditStreamModal
          data-testid="manage-stream"
          // FIX ME
          // @ts-ignore
          stream={stream}
        />,
      ),
  };
};
