import React from 'react';
import { ExposureLevel, SegmentPercents } from '../../types';
import { barWidth, dotSize } from './exposureBarConstants';
import styles from './ExposureBarIndicator.emotion';
import Indicator from './Indicator';

interface Props {
  exposureLevel: ExposureLevel;
  segmentPercents: SegmentPercents;
}

const ExposureBarIndicator: React.FC<Props> = ({ exposureLevel, segmentPercents }) => {
  // adding pixels for the additional gaps between segments
  const additionalPixels = {
    [ExposureLevel.VeryLow]: 0,
    [ExposureLevel.Low]: 1,
    [ExposureLevel.Medium]: 2,
    [ExposureLevel.High]: 3,
    [ExposureLevel.VeryHigh]: 4,
  };

  const barPositions = {
    [ExposureLevel.VeryLow]: segmentPercents[ExposureLevel.VeryLow] / 2,
    [ExposureLevel.Low]:
      segmentPercents[ExposureLevel.Low] / 2 + segmentPercents[ExposureLevel.VeryLow],
    [ExposureLevel.Medium]:
      segmentPercents[ExposureLevel.Medium] / 2 +
      segmentPercents[ExposureLevel.Low] +
      segmentPercents[ExposureLevel.VeryLow],
    [ExposureLevel.High]:
      segmentPercents[ExposureLevel.High] / 2 +
      segmentPercents[ExposureLevel.Medium] +
      segmentPercents[ExposureLevel.Low] +
      segmentPercents[ExposureLevel.VeryLow],
    [ExposureLevel.VeryHigh]:
      segmentPercents[ExposureLevel.VeryHigh] / 2 +
      segmentPercents[ExposureLevel.High] +
      segmentPercents[ExposureLevel.Medium] +
      segmentPercents[ExposureLevel.Low] +
      segmentPercents[ExposureLevel.VeryLow],
  };

  const pixelPosition =
    (barPositions[exposureLevel] / 100) * barWidth + additionalPixels[exposureLevel] - dotSize / 2;

  return (
    <div data-testid="exposure-bar-indicator" className={styles.indicator(pixelPosition)}>
      <Indicator />
    </div>
  );
};

export default ExposureBarIndicator;
