import { useEffect, useRef, useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import { keyBy, max,min } from 'lodash';
import { IGroupedProps } from '@app/components/PropertiesGroupedList/types';
// FIX ME
// @ts-ignore
import GET_PROPERTY_MARKERS from '@app/queries/properties/getPropertyMarkers.gql';
import {
  ClusterRange,
  GetPropertyMarkersData,
  GetPropertyMarkersVariables,
  GraphqlPropertyCluster,
  GraphqlPropertyMarker,
  GraphqlPropertyPoint,
} from '@app/queries/properties/types';
import { TileConfig } from './tiling';
import useMapCache from './useMapCache';
import useMapPreloader from './useMapPreloader';
import { getUniqueMarkers } from './utils';

const mergeRanges = (prev: ClusterRange[], next: ClusterRange[]): ClusterRange[] => {
  const prevMap = keyBy(prev, 'attribute');
  const nextMap = keyBy(next, 'attribute');

  if (prev.length === 0) {
    return next;
  }
  const keys = Object.keys(prevMap);
  const merged: ClusterRange[] = [];
  keys.forEach((key) => {
    const arr = [prevMap[key].min, prevMap[key].max, nextMap[key].min, nextMap[key].max];
    merged.push({ __typename: 'ClusterRange', attribute: key, max: max(arr), min: min(arr) });
  });
  return merged;
};

interface Props {
  group: IGroupedProps;
  withLossesOnly: boolean;
  tileConfig: TileConfig;
  onError: (err: ApolloError) => void;
}

interface Return {
  loading: boolean;
  loadingPct: number | null;

  markers: Array<GraphqlPropertyMarker>;
  clusterRanges: Array<ClusterRange>;
}

export default ({ group, onError, tileConfig, withLossesOnly }: Props): Return => {
  const apolloClient = useApolloClient();
  const [markers, setMarkers] = useState<Array<GraphqlPropertyMarker>>([]);
  const [clusterRanges, setClusterRanges] = useState<Array<ClusterRange>>([]);

  const [requestQueue, setRequestQueue] = useState<Array<GetPropertyMarkersVariables>>([]);
  const [pendingRequestsCount, setPendingRequestCount] = useState<number>(0);

  const { cache, store } = useMapCache();
  const currentZoom = useRef(tileConfig?.zoomLevel);
  useMapPreloader({ cache, group, storeInCache: store, withLossesOnly });
  useEffect(() => {
    setMarkers([]);
    setClusterRanges([]);

    if (!tileConfig) {
      return;
    }

    currentZoom.current = tileConfig.zoomLevel;

    const tiles = tileConfig.getTiles();
    setPendingRequestCount(tiles.length);
    setRequestQueue(
      tiles.map(
        (tileIndex): GetPropertyMarkersVariables => ({
          input: {
            pointSize: 128,
            propertyGroupId: group.id,
            roi: tileConfig.getBBox(tileIndex),
            withLossesOnly,
            zoomLevel: tileConfig.zoomLevel,
          },
        }),
      ),
    );
  }, [JSON.stringify(tileConfig), withLossesOnly]);

  useEffect(() => {
    if (requestQueue.length === 0) {
      return;
    }
    const req = requestQueue[requestQueue.length - 1];

    if (req.input.zoomLevel !== currentZoom?.current) {
      setPendingRequestCount((c) => c - 1);
      setRequestQueue(requestQueue.slice(0, requestQueue.length - 1));
      return;
    }

    const reqStr = JSON.stringify(req);

    if (!!cache[reqStr]) {
      const {
        propertyMarkers: { markers: newMarkers, ranges: newRanges },
      } = cache[reqStr];

      setMarkers((m) => getUniqueMarkers([...m, ...newMarkers]));
      setClusterRanges(mergeRanges(clusterRanges, newRanges));
      setRequestQueue(requestQueue.slice(0, requestQueue.length - 1));
      setPendingRequestCount(pendingRequestsCount - 1);
      return;
    }

    const fetch = async () => {
      const { data, error } = await apolloClient.query<
        GetPropertyMarkersData,
        GetPropertyMarkersVariables
      >({
        query: GET_PROPERTY_MARKERS,
        variables: req,
      });

      if (!!error) {
        onError(error);
      }

      const {
        propertyMarkers: { markers: newMarkers, ranges: newRanges },
      } = data;

      if (req.input.zoomLevel !== currentZoom?.current) {
        return;
      }

      setMarkers((m) => getUniqueMarkers([...m, ...newMarkers]));
      setClusterRanges((c) => mergeRanges(c, newRanges));
      store(reqStr, data);
      setPendingRequestCount((c) => c - 1);
    };

    setRequestQueue(requestQueue.slice(0, requestQueue.length - 1));

    fetch();
  }, [JSON.stringify(requestQueue)]);

  const tileCount = tileConfig?.getTiles()?.length;
  return {
    clusterRanges,
    loading: pendingRequestsCount > 0,
    loadingPct: !!tileCount ? (tileCount - pendingRequestsCount) / tileCount : null,
    markers,
  };
};
