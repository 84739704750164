import React from 'react';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { EnterpriseHomePage } from '../EnterpriseHomePage/EnterpriseHomePage';
import { HomePage } from '../HomePage/HomePage';

export const HomePageContainer = () => {
  const { selectedOrganization } = useUserSession();

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';

  return (
    <StreamProvider>{isEnterpriseAccount ? <EnterpriseHomePage /> : <HomePage />}</StreamProvider>
  );
};
