import moment, { Moment } from 'moment';
import { Snapshot } from '@app/graphql/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';
import { IGroupedSnapshots, IStreamSnapshot } from './types';

interface IQuarterGroupedSnapshots {
  [quarter: number]: IStreamSnapshot[];
}
interface IYearGroupedSnapshots {
  [year: number]: IQuarterGroupedSnapshots;
}
export const groupSnapshotsByQuarter = (
  snapshots: Array<IGraphQLSnapshot> | Array<Snapshot>,
  streamEffectiveDate: Moment,
  propertyStatusLabel?: string,
): Array<IGroupedSnapshots> => {
  const groups = [];
  const yearGroupedSnapshots: IYearGroupedSnapshots = {};
  let lastBoundMarked = false;
  for (let i = 0; i < snapshots.length; i++) {
    const snapshot = snapshots[i];
    // the name are random text with a date embedded in them, moment cant handle that
    // so first try to extract date out using regex
    // https://stackoverflow.com/a/29309567
    const dateFromName = snapshot.name.match(new RegExp(/(20\d{2})-(\d{2})-(\d{2})/gm))?.[0];
    const date = moment(snapshot.date, 'YYYY-MM-DD');
    const name = moment(dateFromName);

    let year = date.year();

    const isNextYear = date
      .clone()
      .subtract(date.year(), 'year')
      .isSameOrAfter(streamEffectiveDate.clone().subtract(streamEffectiveDate.year(), 'year'));
    if (isNextYear) {
      year += 1;
    }
    const quarter = date.clone().subtract(streamEffectiveDate.month(), 'month').quarter();

    const shortLabel =
      dateFromName && name.isValid() && !name.isSame(date)
        ? name.format('MMM DD, YYYY')
        : date.format('MMM DD, YYYY');
    // const longLabel = `${shortLabel} at ${date.format('h:mma')}`;
    let label = snapshot.name;

    if (snapshot.bound && !lastBoundMarked) {
      label = `${propertyStatusLabel || 'Last bound'} - ${label}`;
      lastBoundMarked = true;
    }

    // FIX ME
    // @ts-ignore
    yearGroupedSnapshots[year] = {
      ...yearGroupedSnapshots[year],
      [quarter]: [
        ...((yearGroupedSnapshots[year] || {})[quarter] || []),
        { ...snapshot, label, shortLabel },
      ],
    };
  }

  const yearKeys = Object.keys(yearGroupedSnapshots).reverse();
  for (let i = 0; i < yearKeys.length; i++) {
    const year = yearKeys[i];
    const yearGroup = yearGroupedSnapshots[yearKeys[i]];
    const quarterKeys = Object.keys(yearGroup).reverse();
    for (let j = 0; j < quarterKeys.length; j++) {
      const quarter = quarterKeys[j];
      const items = yearGroup[quarter];
      const name = `Q${quarter} ${year}`;
      groups.push({ items, name });
    }
  }

  return groups;
};

export const getSnapshotLabel = (
  snapshots: Array<IGroupedSnapshots>,
  snapshotName: string | null,
) => {
  if (!snapshotName) {
    return null;
  }
  for (let i = 0; i < snapshots.length; i++) {
    const { items } = snapshots[i];
    for (let j = 0; j < items.length; j++) {
      const { name, label } = items[j];
      if (name === snapshotName) {
        return label;
      }
    }
  }
  return null;
};

export const getSnapshot = (snapshots: Array<IGroupedSnapshots>, snapshotName: string | null) => {
  if (!snapshotName) {
    return null;
  }
  for (let i = 0; i < snapshots.length; i++) {
    const { items } = snapshots[i];
    for (let j = 0; j < items.length; j++) {
      const { name } = items[j];
      if (name === snapshotName) {
        return items[j];
      }
    }
  }

  return null;
};
