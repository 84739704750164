import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const Container = styled(EuiPanel)`
  height: 100%;
  overflow: hidden;

  .codeEditor {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 24px;
  }
`;
