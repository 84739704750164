import { useState } from 'react';
import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  NetworkStatus,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useToast } from 'ui';

interface GetErrorMessageResponseObj {
  message: string;
}
type GetErrorMessageResponse = GetErrorMessageResponseObj | string;
type GetErrorMessage = (error: ApolloError) => GetErrorMessageResponse;
interface IUseStandardQueryOptions {
  numberOfAllowableErrors?: number;
  errorMessage?: GetErrorMessage | string;
}

// TODO: Figure out how to map this to the actual
// QueryResult defined in react-common
interface QueryResult<TData, TVariables> {
  client?: ApolloClient<any>;
  data: TData | undefined;
  error?: ApolloError;
  loading: boolean;
  networkStatus: NetworkStatus;
  called: boolean;
  refetch: (variables?: TVariables) => Promise<ApolloQueryResult<TData>>;
  fetchMore: (<K extends keyof TVariables>(
    fetchMoreOptions: FetchMoreQueryOptions<TVariables, K> & FetchMoreOptions<TData, TVariables>,
  ) => Promise<ApolloQueryResult<TData>>) &
    (<TData2, TVariables2, K extends keyof TVariables2>(
      fetchMoreOptions: {
        query?: DocumentNode;
      } & FetchMoreQueryOptions<TVariables2, K> &
        FetchMoreOptions<TData2, TVariables2>,
    ) => Promise<ApolloQueryResult<TData2>>);
}

export default <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
  { numberOfAllowableErrors = 1, errorMessage }: IUseStandardQueryOptions = {},
): QueryResult<TData, TVariables> => {
  const toast = useToast();
  const [encounteredErrors, setEncounteredErrors] = useState(0);
  const exceededErrors =
    numberOfAllowableErrors !== undefined && numberOfAllowableErrors <= encounteredErrors;

  const skip = options.skip || exceededErrors;

  const { error, ...rest } = useQuery<TData, TVariables>(query, {
    ...options,
    skip,
  });

  if (error && !skip) {
    const errorToDisplay = typeof errorMessage === 'function' ? errorMessage(error) : errorMessage;
    if (typeof errorToDisplay === 'object') {
      const { message } = errorToDisplay;
      toast({ title: message, type: 'danger' });
    } else {
      toast({ title: errorToDisplay, type: 'danger' });
    }
    setEncounteredErrors((prev) => prev + 1);

    return {
      error,
      ...rest,
    };
  }

  return {
    error,
    ...rest,
  };
};
