"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSelect = void 0;
var react_1 = __importStar(require("react"));
var eui_1 = require("@elastic/eui");
var SearchSelect_utils_1 = require("./SearchSelect.utils");
var SearchSelect = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.clearable, clearable = _b === void 0 ? true : _b, _c = _a.compressed, compressed = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.invalid, invalid = _f === void 0 ? false : _f, onBlur = _a.onBlur, onChange = _a.onChange, onCreateOption = _a.onCreateOption, autoFocus = _a.autoFocus, options = _a.options, placeholder = _a.placeholder, _g = _a.loading, loading = _g === void 0 ? false : _g, value = _a.value, inputRef = _a.inputRef, prepend = _a.prepend;
    var _h = __read((0, react_1.useState)((0, SearchSelect_utils_1.getOption)(options, value)), 2), selectedOptions = _h[0], setSelectedOptions = _h[1];
    (0, react_1.useEffect)(function () {
        setSelectedOptions((0, SearchSelect_utils_1.getOption)(options, value));
    }, [value, options]);
    var handleChange = function (option) {
        setSelectedOptions(option);
        if (onChange && (option === null || option === void 0 ? void 0 : option.length) > 0) {
            onChange(option[0].value);
        }
        else {
            onChange(null);
        }
    };
    var handleCreateOption = function (value, options) {
        var createdOption = onCreateOption(value, options);
        if (createdOption !== false) {
            setSelectedOptions(function (prevState) { return __spreadArray(__spreadArray([], __read(prevState), false), [
                { label: value, value: value },
            ], false); });
        }
    };
    return (react_1.default.createElement(eui_1.EuiComboBox, { "data-testid": dataTestId || "ui-atom-input-search-select", fullWidth: fullWidth, isClearable: clearable, isDisabled: disabled, isInvalid: invalid, onBlur: onBlur, onChange: handleChange, onCreateOption: onCreateOption ? handleCreateOption : undefined, autoFocus: autoFocus, options: options, isLoading: loading, placeholder: placeholder, selectedOptions: selectedOptions, singleSelection: { asPlainText: true }, compressed: compressed, inputRef: inputRef, prepend: prepend }));
};
exports.SearchSelect = SearchSelect;
