import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default <T extends HTMLElement>(htmlElement: null | T, callback: ResizeObserverCallback) => {
  const [ro] = useState<ResizeObserver>(new ResizeObserver(callback));

  useEffect(() => {
    if (htmlElement) {
      ro.observe(htmlElement);
    }
  }, [htmlElement]);
};
