import constructionSchema from './schemas/construction_schema.json';
import occupancySchema from './schemas/occupancy_schema.json';
import { IPropertySchema } from './types';

export const getSchema = (schema: 'construction' | 'occupancy'): IPropertySchema => {
  if (schema === 'construction') {
    return constructionSchema;
  }
  if (schema === 'occupancy') {
    return occupancySchema;
  }
  throw new Error(`Undefined schema specified ${schema}`);
};
export const getAsSchema = (type: string | undefined, schemaKey: 'construction' | 'occupancy') => {
  if (type === undefined) {
    return undefined;
  }
  const schema = getSchema(schemaKey);
  const readable = schema[type];

  if (readable) {
    return readable.description;
  }

  return type;
};
