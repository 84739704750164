import { useMutation } from '@apollo/client';
import { useToast } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { CREATE_EXPORT_CONFIGURATION, UPDATE_EXPORT_CONFIGURATION } from '../graphql/mutations';
import {
  CreateExportConfigurationVariableType,
  ExportConfigurationResultType,
  UpdateExportConfigurationVariableType,
} from '../graphql/types';

export const useManageExportsMutations = () => {
  const toast = useToast();
  const { closeFlyout } = useFlyout();
  const exportSuccessText =
    'Successfully created export config. Please wait up to 5 minutes for the XLSX Export to reflect these changes.';

  const onCompleted = () => {
    toast({ label: exportSuccessText, title: 'Success', type: 'success' });
    closeFlyout();
  };
  const onError = (err) => toast({ title: getErrorMessage(err), type: 'danger' });

  const [createExportConfig, { loading: creatingExportConfig }] = useMutation<
    ExportConfigurationResultType,
    CreateExportConfigurationVariableType
  >(CREATE_EXPORT_CONFIGURATION, {
    onCompleted,
    onError,
  });

  const [updateExportConfig, { loading: updatingExportConfig }] = useMutation<
    ExportConfigurationResultType,
    UpdateExportConfigurationVariableType
  >(UPDATE_EXPORT_CONFIGURATION, {
    onCompleted,
    onError,
  });

  return {
    createExportConfig,
    creatingExportConfig,
    updateExportConfig,
    updatingExportConfig,
  };
};
