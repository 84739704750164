import React, { FC, useContext, useEffect, useState } from 'react';
import { EuiFilePicker } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UploadsContext } from '@app/contexts/UploadsContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';

interface IProps {
  directory?: boolean;
  organizationId?: string;
  submissionId?: string;
  propertyID?: string;
  classification?: string;
  eventPrefix: string;
}

const FilesUploader: FC<IProps> = ({
  children,
  directory = false,
  organizationId,
  submissionId,
  propertyID,
  classification,
  eventPrefix,
}) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const orgId = organizationId || selectedOrganization?.id || account?.docUploadOrgs?.[0]?.id;

  const { uploadFiles } = useContext(UploadsContext);
  const [pickerKey, setPickerKey] = useState(0);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  if (directory) {
    useEffect(() => {
      const el = document?.getElementById('upload-directory-button');
      el?.setAttribute('directory', '');
      el?.setAttribute('mozdirectory', '');
      el?.setAttribute('msdirectory', '');
      el?.setAttribute('odirectory', '');
      el?.setAttribute('webkitdirectory', '');
    });
  }

  if (!account || !orgId) {
    return <div />;
  }

  const onChange = (f: any) => {
    uploadFiles({ classification, eventPrefix, files: f, orgId, propertyID, submissionId });
    setPickerKey((prev) => prev + 1);
  };

  return (
    <>
      {children && (
        <>
          <div
            onClick={(event) => {
              event.preventDefault();
              hiddenFileInput?.current?.click();
            }}
            style={{ cursor: 'pointer' }}
          >
            {children}
          </div>
          <input
            data-testid="upload-flyout-file-input"
            id={directory ? 'upload-directory-button' : ''}
            type="file"
            multiple
            ref={hiddenFileInput}
            onChange={(event) => onChange(event?.target?.files)}
            style={{ display: 'none' }}
          />
        </>
      )}
      {!children && (
        <EuiFilePicker
          data-testid="upload-flyout-file-input"
          id="filePicker"
          multiple
          initialPromptText="Drag & drop or click to upload"
          onChange={onChange}
          display="large"
          key={pickerKey}
          compressed
          fullWidth
        />
      )}
    </>
  );
};

export default FilesUploader;
