// Format tier data for column chart
import { formatCurrency } from '@app/utils/format';

function formatChartData(chartData) {
  const { value, pctOfTotal, pctChange } = chartData.attributes.find(
    (attr) => attr.displayName === 'TIV',
  );
  const { value: properties } = chartData.attributes.find(
    (attr) => attr.displayName === 'Properties',
  );

  return {
    name: chartData.label,
    pctChange,
    pctOfTotal,
    properties,
    region: chartData.region?.split('-')[1]?.toUpperCase(),
    tier: chartData.label,
    value: formatCurrency(value),
    valueRaw: value,
    y: pctOfTotal,
  };
}

function addPlaceholdersToChartData(region: string, groupLabel: string, chartData) {
  const itemFound = chartData.find((item) => item.region === region);

  if (itemFound) {
    return itemFound;
  }

  return {
    name: groupLabel,
    pctChange: 0,
    pctOfTotal: 0,
    properties: 0,
    region,
    tier: groupLabel,
    value: '',
    valueRaw: 0,
    y: 0,
  };
}

function findDataByGroupLabel(groupLabel: string, chartData) {
  return chartData
    .map((dataItem) => {
      const data = dataItem.groupedBy.find((group) => group.label === groupLabel);

      if (!data) {
        return undefined;
      }

      return formatChartData({
        region: dataItem.label,
        ...data,
      });
    })
    .filter(Boolean);
}

// This function will add a `regionTotal: string` item to a `dataItem`
// object with the sum of all `valueRaw`s matched by `region`, e.g.:
//   const chartData = [
//      {region: 'CA', valueRaw: 5},
//      {region: 'IL', valueRaw: 1},
//      {region: 'CA', valueRaw: 5},
//      {region: 'IL', valueRaw: 2},
//   ];
//  console.log(addRegionTotal('CA', chartData);
//  >> Array(4)
//  >> [
//  >>   {region: 'CA', valueRaw: 5, regionTotal: 10},
//  >>   {region: 'IL', valueRaw: 1, regionTotal: 3},
//  >>   {region: 'CA', valueRaw: 5, regionTotal: 10},
//  >>   {region: 'IL', valueRaw: 2, regionTotal: 3},
//  >> ]
function addRegionTotals(dataItem, dataArr) {
  // Each region has only two items. We find them, then sum their `valueRaw`
  // totals. We save that value as `regionTotals` in its values.
  const matchingRegions = dataArr.filter((item) => item.region === dataItem.region);

  // If we found more than two then it means we have placeholder data without
  // regions.
  if (matchingRegions.length >= 3) {
    return {
      ...dataItem,
      regionTotal: 0,
    };
  }

  const regionTotal = matchingRegions.reduce((a, b) => a.valueRaw + b.valueRaw);

  return {
    ...dataItem,
    regionTotal,
  };
}

export { addPlaceholdersToChartData, addRegionTotals, findDataByGroupLabel, formatChartData };
