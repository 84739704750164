import { DocumentsContext, Filter } from '../../types/contextTypes';

export default (classifications: DocumentsContext['data']['classifications']): Filter[] => {
  if (classifications === undefined) {
    return undefined;
  }

  if (classifications === null) {
    return null;
  }

  if (classifications) {
    return classifications.map((classification) => ({
      headerLabel: classification,
      id: classification,
      rowLabel: classification,
    }));
  }
};
