import React, { FC, MouseEvent } from 'react';
import styled from '@emotion/styled';
import MarkdownJSX, { MarkdownOptions } from 'markdown-to-jsx';
import MarkdownLink from './MarkdownLink';

interface IProps {
  content: string;
  handleClick?: (e: MouseEvent<HTMLElement>) => void;
  handleLinkClick?: (url: string) => void;
}

const Container = styled.div`
  label: MarkdownContainer;

  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const Markdown: FC<IProps> = ({ content, handleClick, handleLinkClick }) => {
  const options: MarkdownOptions = {
    overrides: {
      a: {
        component: MarkdownLink,
        props: {
          handleOpen: handleLinkClick,
        },
      },
    },
  };
  return (
    <Container onClick={handleClick}>
      <MarkdownJSX options={options}>{content}</MarkdownJSX>
    </Container>
  );
};
export default Markdown;
