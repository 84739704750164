import moment from 'moment';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';
import { IJob, IProject } from './types';

export const generateSnapshotName = (snapshots: Array<IGraphQLSnapshot>): string => {
  const defaultName = moment().format('YYYY-MM-DD');

  if (!snapshots || snapshots.length === 0) {
    return defaultName;
  }

  let index = 0;
  const regexp = new RegExp(`${defaultName} \\([0-9]+\\)`);
  for (let i = 0; i < snapshots.length; i++) {
    const { name } = snapshots[i];
    if (name === defaultName || regexp.test(name)) {
      index += 1;
    }
  }

  if (index > 0) {
    return `${defaultName} (${index})`;
  }

  return defaultName;
};

export const getCurrentProjectJobs = (project: IProject, jobs: Array<IJob>): Array<IJob> => {
  if (!project.snapshots || project.snapshots.length === 0) {
    return jobs;
  }

  const latestSnapshot = moment(project.snapshots[0].date);
  return jobs.filter(
    (j) =>
      j.projectName === project.name &&
      moment(j.createdAt).utc().isSameOrAfter(latestSnapshot.utc()),
  );
};

export const mapDataIsEqual = (prev: any, next: any): boolean =>
  JSON.stringify(prev.centroid) === JSON.stringify(next.centroid);

export const getGroupedList = (list: Array<IGraphQLAttributeMetadata>) => {
  // The refactor abstracted each step of separating parents from children and
  // added another one to include grand children.
  // It's a bit more verbose, but it allows for easier debugging.
  // I don't like that we are mutation variables and doing of bunch off
  // loops, but it's the more declarative way to do it.

  // A parent, doesn't have a parent, set `type: "parent"`
  const parents = list
    .filter((item) => !item.parent)
    .map((item) => ({
      ...item,
      children: [{ ...item }],
      type: 'parent',
    }));

  // A children, has a parent of `type: "parent"`, set `type: "children"`
  const children = [];
  list.forEach((listItem) => {
    if (listItem?.type !== 'parent') {
      const parent = parents.find((parentItem) => parentItem.name === listItem.parent);
      if (parent?.type === 'parent') {
        const newObj: IGraphQLAttributeMetadata = {
          ...listItem,
          children: [],
          type: 'children',
        };
        children.push(newObj);
        parent.children.push(newObj);
      }
    }
  });

  // A grand children, has a parent of `type: "children"`, set `type: "grandChildren"`
  // const grandChildren = [];
  list.forEach((listItem) => {
    if (listItem?.type !== 'parent' && listItem?.type !== 'children') {
      const parent = children.find((parentItem) => parentItem.name === listItem.parent);
      if (parent?.type === 'children') {
        const newObj = {
          ...listItem,
          type: 'grandChildren',
        };
        parent.children.push(newObj);
        // grandChildren.push(newObj);
      }
    }
  });

  return parents;
};

export const getItemAttributes = (
  item: IGraphQLAttributeMetadata | any,
): Array<IGraphQLAttributeMetadata> => {
  const children = item?.children || [];
  const filteredChildren = [...children.filter((child) => !child.derived)];
  const grandChildren = [];
  children
    .filter((child) => child?.children?.length > 0)
    .forEach((child) => {
      const filteredGrandChildren = child.children.filter((grandchild) => !grandchild.derived);
      grandChildren.push(...filteredGrandChildren);
    });

  return [...filteredChildren, ...grandChildren];
};
