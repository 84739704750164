import styled from '@emotion/styled';
import { EuiFlexGroup, EuiText } from 'ui';

export const PredictionText = styled(EuiText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const PredictionGroup = styled(EuiFlexGroup)`
  margin-left: 5px;
  max-width: 205px;
`;

export const GoogleLogoText = styled(EuiText)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-top: 10px;
  padding-right: 8px;
  padding-bottom: 8px;
  letter-spacing: 0.69px;
  color: #5f6368;
  text-align: right;
`;
