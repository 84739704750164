import Highcharts from 'highcharts';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { formatCurrency } from '@app/utils/format';

const colors = [
  '#21b59a', // Aggregated TIV
  '#E4A6C7', // A1
  '#FCDC6A', // A2
  '#FF7E62', // B1 and B2
  '#79AAD9', // Other
];

const formatEarthquakeOptions = (mapData: RiskSummaryChartData) => {
  // Get the top 10 of the given values
  const dataExtract = mapData?.values.slice(0, 10) || [];

  // Extract the locations for category use in the chart options
  const locations = dataExtract.map(({ label }) => label);

  // To keep all the columns the same size, we need to keep the chart with ten
  // columns. The datasets not always come with at least 10 values, so we need
  // to add the rest of empty arrays so they can take the space to keep the width
  // of the columns the same. For example, if the API returns California and
  // Pacific Northwest, then we need to add eight empty arrays to make it up for
  // the other 8 spots.
  // We could get away with adding a fixed size in pixels, but proportions work better
  // in this case.
  // To do this, we create a placeholder array with objects `{name: number, y: number}`
  // and spread it into the `dataExtract` array with the real data.
  const placeholderArr = [...Array(9 - dataExtract.length)].map((placeholder, index) => ({
    attributes: [],
    groupedBy: [],
    isPlaceholder: true,
    name: index,
    y: 0,
  }));
  const dataExtractWithPlaceholder = [...dataExtract, ...placeholderArr];
  const labelOrder = ['TIV', 'A1', 'A2', 'B1 and B2', 'Other'];

  const data = labelOrder.map((label) => {
    const isTIV = ({ displayName }) => displayName === 'TIV';
    const isProperties = ({ displayName }) => displayName === 'Properties';
    const formatChartData = ({ name, pctChange, pctOfTotal, properties, value, y }) => ({
      name,
      pctChange,
      pctOfTotal,
      properties,
      value: formatCurrency(value),
      y,
    });

    return {
      // If the data contains groups then we use that data, otherwise we use the aggregated TIV
      // and properties. Everything in the same function.
      // TODO: Abstract into a helper function
      data: dataExtractWithPlaceholder.map((dataItem) => {
        // Show Aggregated TIV only when there aren't groups i.e., `groupBy === null`
        if (!dataItem?.groupedBy && label === 'TIV') {
          const aggregatedTIV = dataItem?.attributes?.find(isTIV);
          const properties = dataItem?.attributes?.find(isProperties);

          return formatChartData({
            ...aggregatedTIV,
            name: 'Aggregated TIV',
            properties: properties?.value,
            y: aggregatedTIV?.pctOfTotal,
          });
        }

        // Show group data
        const dataValue = dataItem?.groupedBy?.filter((grouped) => grouped?.label === label)[0];
        const result = dataValue?.attributes?.find(isTIV);
        const properties = dataValue?.attributes?.find(isProperties);

        return {
          isPlaceholder: false,
          name: label,
          pctChange: result?.pctChange,
          pctOfTotal: result?.pctOfTotal,
          properties: properties?.value,
          value: formatCurrency(result?.value),
          y: result?.pctOfTotal,
        };
      }),
      name: label,
      type: 'column',
    };
  });

  const chartOptions: Highcharts.Options = {
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    chart: {
      type: 'column',
    },
    colors,
    legend: {
      enabled: true,
      labelFormatter() {
        const { name } = this;

        // These labels are named differently in the API but need to be displayed differently to users
        if (name === 'TIV') {
          return 'Aggregated TIV';
        }
        if (name === 'Other') {
          return 'CA Other';
        }

        return name;
      },
    },
    plotOptions: {
      // https://api.highcharts.com/highcharts/plotOptions.column
      column: {
        stacking: 'normal',
      },

      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          // format: '{point.y:.1f}%',
          // eslint-disable-next-line consistent-return
          formatter() {
            if (this.y > 0) {
              return `${this.y}%`;
            }
          },
        },
      },
    },
    // Highcharts complaining about its types. Nothing to worry about.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series: data,
    subtitle: {
      align: 'left',
      style: {
        color: '#000',
        marginBottom: 8,
        marginTop: 8,
      },
      text: '<div style="margin-top: 1rem;margin-bottom: 1rem;">% of TIV</div>',
      useHTML: true,
    },
    title: {
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      text: 'Top Regions by Earthquake Exposure',
    },
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: '#000',
      borderRadius: 4,
      headerFormat: '',
      pointFormat:
        '<span style="font-size:13px;font-weight: bold;color:#fff;">{point.name}</span><br>' +
        '<span style="color:#fff;">{point.value} TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctOfTotal}% of TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctChange}% change since prev. bound</span><br/>' +
        '<span style="color:#fff;">{point.properties} properties</span><br/>',
    },

    xAxis: {
      // width: '46px',
      categories: locations,
      labels: {
        formatter() {
          const { value } = this;
          if (typeof value !== 'number') {
            return value.toString();
          }
          return '';
        },
      },
      type: 'category',
    },

    yAxis: {
      labels: {
        formatter() {
          return `${this.value}%`;
        },
      },
      title: {
        text: '',
      },
    },
  };

  return chartOptions;
};

export { formatEarthquakeOptions };
