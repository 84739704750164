import React from 'react';
import { EuiBasicTable } from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { PropertyMetadata } from '../types';
import getMetadataTableItems from './getMetadataTableItems';
import styles from './MetadataTableChildren.emotion';
import metadataTableColumns from './metadataTableColumns';
import useUpdateBoolean from './useUpdateBoolean';

interface Props {
  data: Array<PropertyMetadata>;
  parentAttribute: PropertyMetadata;
}

const MetadataTableGrandChildren: React.FC<Props> = ({ data, parentAttribute }) => {
  const { setSelectedAttribute } = useAttributeMetadataEditorContext();
  const updateBoolean = useUpdateBoolean(parentAttribute);

  const columns = metadataTableColumns({
    childType: 'grandchild',
    handleEdit: (attribute) => setSelectedAttribute({ attribute, parentAttribute }),
    handleUpdate: updateBoolean,
    itemIdToExpandedRowMap: {},
    toggleDetails: () => {},
  });

  const items = getMetadataTableItems(data);

  return (
    <EuiBasicTable
      data-testid="attribute-metadata-grand-child-table"
      className={styles.table}
      items={items}
      columns={columns}
    />
  );
};

export default MetadataTableGrandChildren;
