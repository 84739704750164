import React from 'react';
import styled from '@emotion/styled';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ReefMapV2 from '@app/cx/Stream/Reef/ReefMapV2/ReefMapV2';
import { usePrecheckContext } from '@app/precheck/context/PrecheckContext';

const StyledDiv = styled.div`
  padding-bottom: 12px;
  height: 100%;
  border-radius: 6px;
  @media (max-width: 900px) {
    height: 250px;
    padding: 0;
  }
`;

export const MapWrapper = () => {
  const { params, streamGroup, snapshot, stream } = usePrecheckContext();

  return (
    <StyledDiv>
      <ReefMapV2
        streamSlug={stream.slug}
        group={streamGroup}
        indices={[]}
        // FIX ME
        // @ts-ignore
        pageState={{ currentSnapshot: snapshot.name }}
      />
    </StyledDiv>
  );
};
