import { EuiSuggestionProps } from 'ui';
import { AddressObjectType } from './types';

export const makeSuggestions = (
  predictions: google.maps.places.AutocompletePrediction[],
): EuiSuggestionProps[] => predictions.map((prediction) => ({
      label: prediction.description,
      type: { color: undefined, iconType: undefined },
    }));

export const makeAddressObject = (
  placeDetails: google.maps.places.PlaceResult,
): AddressObjectType => {
  const streetNumber = getAddressComponentLongName(placeDetails, ['street_number']);
  const streetName = getAddressComponentLongName(placeDetails, ['route']);
  const streetAddress = streetNumber && streetName ? `${streetNumber} ${streetName}` : streetName;
  const city = getAddressComponentLongName(placeDetails, ['locality', 'postal_town']);
  let county = getAddressComponentLongName(placeDetails, ['administrative_area_level_2']);
  const state = getAddressComponentLongName(placeDetails, ['administrative_area_level_1']);
  const country = getAddressComponentLongName(placeDetails, ['country']);
  const postalCode = getAddressComponentLongName(placeDetails, ['postal_code']);
  const latitude = placeDetails?.geometry?.location?.lat?.() || undefined;
  const longitude = placeDetails?.geometry?.location?.lng?.() || undefined;

  // google geocode api started to return LA parishes with suffix Parish, fix sc-161949
  if ((state == "LA" || state == "Louisiana") && county.endsWith(" Parish")) {
    county = county.replace(" Parish", "")
  }

  return {
    city,
    country,
    county,
    latitude,
    longitude,
    postalCode,
    state,
    streetAddress,
  };
};

const getAddressComponentLongName = (
  placeDetails: google.maps.places.PlaceResult,
  matchingTypes: string[],
) => (
    placeDetails?.address_components?.find((component) =>
      component.types.find((componentType) => matchingTypes.includes(componentType)),
    )?.long_name || undefined
  );
