import React from 'react';
import { useUnderwritingAssessmentReportContext } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/underwritingAssessmentReportContext';
import { Asset, EuiFlexGroup, EuiFlexItem, EuiText, Spacer, Tabs } from 'ui';
import { ReportContentProps } from '@app/components/ReportContainer';

function ReportContent({ title, description, logo, tabs, footer }: ReportContentProps) {
  const { insurerInsightsTab } = useUnderwritingAssessmentReportContext();
  const logoStyles = {
    maxWidth: '102px',
  };

  return (
    <EuiFlexItem grow={false} style={{ margin: 0, padding: '12px' }}>
      <EuiFlexGroup direction="row" component="span" alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiText>
            <h3>{title}</h3>
          </EuiText>
        </EuiFlexItem>
        {logo && (
          <>
            <EuiFlexItem grow={false}>
              <EuiText>/</EuiText>
            </EuiFlexItem>
            <EuiFlexItem style={logoStyles} grow={false}>
              {/* FIX ME
                @ts-ignore */}
              <Asset name="zurichLogo" />
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup direction="column" justifyContent="spaceBetween" gutterSize="none">
        {description && (
          <EuiFlexItem>
            <Spacer />
            <EuiText>{description}</EuiText>
            <Spacer />
          </EuiFlexItem>
        )}
        <Tabs initialTabId={insurerInsightsTab} tabs={tabs} />
        {footer}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
}

export { ReportContent };
