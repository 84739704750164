import React, { useCallback, useEffect, useRef } from 'react';
import { ROCKET_CHAT_URL } from '@app/config';
import { useChatContext } from '../Chat/ChatProvider';
import { RocketChatContainer } from './RocketChat.emotion';

interface Props {
  room: string;
  initialRoom: string;
  // Event that is triggered when the user has sent a message
  onUserSentMessage?: () => void;
}
export const RocketChat: React.FC<Props> = ({ room, initialRoom, onUserSentMessage }) => {
  const frame: { current: HTMLIFrameElement } = useRef();
  const { user } = useChatContext();
  const targetOrigin = ROCKET_CHAT_URL;

  const handleChatEvent = useCallback((e) => {
    if (!frame.current?.contentWindow || !e.origin.includes(ROCKET_CHAT_URL)) {
      return;
    }

    if (e.data.eventName === 'new-message') {
      if (e?.data?.data?.u?._id === user?.userID && !!onUserSentMessage) {
        // The user that is logged in is the one that sent the message
        onUserSentMessage();
      }
    }

    if (e.data.eventName === 'startup') {
      onLoad();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleChatEvent);

    return () => {
      window.removeEventListener('message', handleChatEvent);
    };
  }, []);

  useEffect(() => {
    if (frame.current) {
      frame.current.contentWindow.postMessage(
        {
          externalCommand: 'go',
          path: `/group/${room}`,
        },
        targetOrigin,
      );
    }
  }, [room]);

  const onLoad = () => {
    if (frame.current) {
      const contentWindow = frame.current.contentWindow;
      contentWindow.focus();
      contentWindow.postMessage(
        {
          externalCommand: 'login-with-token',
          token: user?.token,
        },
        targetOrigin,
      );
    }
  };

  return (
    <RocketChatContainer
      id="rocket-chat"
      ref={frame}
      src={`${ROCKET_CHAT_URL}/group/${initialRoom}?layout=embedded`}
      allow="camera;microphone"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      onLoad={onLoad}
    />
  );
};
