import React from 'react';
import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import GradeBadge from '../GradeBadge';
import { OverallTableData } from './tableData';

interface Props {
  tableData: OverallTableData;
}

const OverallGrade: React.FC<Props> = ({ tableData }) => {
  const { overallGrade, totalRow, items, columns } = tableData;
  const overallGradeItems = items ? [...items, totalRow] : [totalRow];

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={false}>
                <EuiText size="s">
                  <b>Overall Grade</b>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <GradeBadge grade={overallGrade} filterable={false} isLarge />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false} />
          <EuiFlexItem>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={false}>
                <b>Property Breakdown by Grade</b>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiBasicTable
                  columns={columns}
                  items={overallGradeItems}
                  data-testid="grading-details-table"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default OverallGrade;
