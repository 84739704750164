import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { Permissions } from '../types';

export default (): Permissions => {
  const { account } = useAuth();

  return {
    isAdmin: account?.permissions?.admin,
    isDM: !!account?.permissions?.canViewProjects,
    isNonAdminWithOrgs: account?.isNonAdminWithOrgs,
  };
};
