import { Dispatch, SetStateAction } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import {
  OrganizationDocumentsPageData,
  OrganizationDocumentsPageVariables,
  OrganizationDocumentsPropertiesPageData,
  OrganizationDocumentsPropertiesPageVariables,
} from './queryTypes';

export type CheckedRows = Record<number, boolean>;

export type DataType = 'documents' | 'properties';

export type DataPayloads = OrganizationDocumentsPageData | OrganizationDocumentsPropertiesPageData;

export type LoadMore = () => Promise<ApolloQueryResult<{}>> | undefined;

export interface MetaRow {
  id: string;
  label: string;
}

export enum NavigationType {
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  PROPERTY = 'PROPERTY',
  UPLOAD_DATE = 'UPLOAD_DATE',
}

export type Refetch =
  | ((
      variables?: Partial<OrganizationDocumentsPageVariables> | undefined,
    ) => Promise<ApolloQueryResult<OrganizationDocumentsPageData>>)
  | ((
      variables?: Partial<OrganizationDocumentsPropertiesPageVariables> | undefined,
    ) => Promise<ApolloQueryResult<OrganizationDocumentsPropertiesPageData>>);

export type SetData =
  | Dispatch<SetStateAction<OrganizationDocumentsPageData | null>>
  | Dispatch<SetStateAction<OrganizationDocumentsPropertiesPageData | null>>;

export interface Tab {
  id: NavigationType;
  label: string;
}

export enum UploadDate {
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  LAST_MONTH = 'lastMonth',
  THIS_QUARTER = 'lastQuarter',
  THIS_YEAR = 'thisYear',
  ALL = 'all',
}
