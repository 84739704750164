import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useEuiTheme } from 'ui';
import { propertiesModalPathname, useDecodedParams } from '@app/containers/App/Routes/utils';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { StreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import { getCell } from '../PropertiesGrid/GridCell/utils';
import PropertiesGridTooltipBody from '../PropertiesGrid/PropertiesGridTooltip/PropertiesGridTooltipBody';
import { getEditRecords } from '../PropertiesGrid/PropertiesGridTooltip/utils';
import { IPropertiesGridPropertyAttributeProvenance } from '../PropertiesGrid/types';
import { getProvenanceAsObj } from '../PropertiesGrid/utils';
import { IEffectiveMetaData } from './types';
import { getAdjustedRowIndex } from './utils';

interface Props {
  rowIndex: number;
  columnId: string;
  isDetails: boolean;
  properties: StreamPropertiesPageQuery['streamPropertiesPage']['properties'];
  pagination: { pageSize: number; pageIndex: number };
  effectivePropertyAttributeMetadata: IEffectiveMetaData;
  setCellProps: any;
}

const Cell: FC<Props> = ({
  rowIndex,
  columnId,
  isDetails,
  properties,
  pagination,
  effectivePropertyAttributeMetadata,
}) => {
  const { euiTheme } = useEuiTheme();
  const { stream } = useStreamContext();
  const {
    location: { search },
  } = window;
  const qs = queryString.parse(search as string);
  const params = useDecodedParams();

  const adjustedRowIndex = getAdjustedRowIndex(rowIndex, pagination);

  if (!properties?.hasOwnProperty(adjustedRowIndex)) {return null;}

  const value = properties[adjustedRowIndex][columnId];
  const id = properties[adjustedRowIndex]?.id;
  const columnMeta = effectivePropertyAttributeMetadata[columnId];
  const attributeProvenance = properties[adjustedRowIndex]
    .attributeProvenance as Array<IPropertiesGridPropertyAttributeProvenance>;
  const prov = getProvenanceAsObj(attributeProvenance || []);
  const propertyWithProv = {
    ...properties[adjustedRowIndex],
    provenance: prov,
  };

  const cell = useMemo(
    () =>
      getCell(
        // @ts-ignore
        // need to fix the typing of these and make them more generic
        propertyWithProv,
        {
          id: columnId,
          value,
          ...columnMeta,
          formattingOptions: {
            height: columnMeta.type === 'description' ? '16' : '22',
            textAlign: 'left',
            width: '22',
          },
        },
        {
          displayCurrency: stream?.displayCurrency,
          unitOfMeasurements: stream?.streamSettings?.unitsOfMeasurement,
        },
      ),
    [id],
  );

  // FIX ME
  // @ts-ignore
  const editRecords = getEditRecords(propertyWithProv, columnId);

  if (isDetails) {
    return (
      <PropertiesGridTooltipBody
        provenance={cell.tooltip.provenance}
        editInfo={editRecords}
        handleOpen={() => {}}
        handleDocumentDownload={() => {}}
        description={cell.tooltip.description || cell.body}
        title={cell.tooltip.title}
      />
    );
  }

  const borderBottom =
    cell.tooltip.provenance || editRecords?.length
      ? `2px solid ${euiTheme.colors.mediumShade}`
      : undefined;

  return (
    <Link
      style={{ borderBottom, color: 'inherit' }}
      to={{
        pathname: propertiesModalPathname(stream, params, id),
        search: queryString.stringify({
          ...qs,
        }),
      }}
    >
      {cell.body}
    </Link>
  );
};

export { Cell };
