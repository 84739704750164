import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip, Icon } from 'ui';
import { ChartValues } from '@app/queries/streams/types';
import HorizontalBar from './HorizontalBar';
import { LegendItem } from './types';
import { convertAbbreviation, formatPercent, formatTIV } from './utils';

interface Props {
  testid?: string;
  values: ChartValues;
  secondaryValues?: ChartValues;
  growFull?: boolean;
  showTIV?: boolean;
  showPercentages?: boolean;
  showTooltips?: boolean;
  showBarLabels: boolean;
  title: string;
  includeSecondBar?: boolean;
  keyItems: LegendItem[];
  chartType: string;
}

const BarRow: FC<Props> = ({
  testid: dataTestId,
  values,
  secondaryValues,
  growFull = false,
  showTIV = false,
  showPercentages = false,
  showTooltips = false,
  showBarLabels,
  title,
  includeSecondBar = true,
  keyItems,
  chartType,
}) => {
  // FIX:
  // We're going to fix this in a refactor, see:
  // * https://app.shortcut.com/onarchipelago/story/79782/ui-clean-up-of-the-uwar#activity-82030

  const titleFormatted = chartType === 'GeographyAndHazards' ? convertAbbreviation(title) : title;
  const tiv = formatTIV(values?.attributes[0]?.value);
  const percentage = formatPercent(values?.attributes[0]?.pctOfTotal);
  const tivInfo = showTIV ? `: ${tiv} TIV (${percentage}%)` : null;
  const percentageInfo = showPercentages ? `: ${percentage}%` : null;

  const tooltipInfo = {
    Combustible:
      'Construction Classes which translate to ISO 1, 2, or 7 are considered Combustible for insurance purposes. True combustibility may be based on more factors. Many of these buildings have active fire protection systems which are not included in ISO classifications and passive systems may provide an additional level of fire resistance.',
    'Non-Combustible':
      'Construction Classes which translate to ISO 3, 4, 5 or 6 are considered Non-combustible for insurance purposes.',
  };

  // Don't show tooltip for the Unknown chart
  const showTooltip = showTooltips && title !== 'Unknown';

  return (
    <EuiFlexGroup direction="row" gutterSize="xs">
      <EuiFlexItem grow={3}>
        {showBarLabels && (
          <EuiFlexItem grow={2}>
            <EuiText size="m" data-testid="bar-label-title">
              <small>
                {titleFormatted}
                {tivInfo}
                {percentageInfo}
              </small>
              {showTooltip && (
                <EuiToolTip position="top" content={tooltipInfo[title]}>
                  <Icon color="primary" name="info" />
                </EuiToolTip>
              )}
            </EuiText>
          </EuiFlexItem>
        )}
        <HorizontalBar
          testid={dataTestId}
          values={values}
          growFull={growFull}
          title={title}
          keyItems={keyItems}
          includeSecondBar={false}
        />
        {secondaryValues && (
          <HorizontalBar
            testid={`${dataTestId}-bar2`}
            values={secondaryValues}
            title={title}
            keyItems={keyItems}
            includeSecondBar={includeSecondBar}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default BarRow;
