import React, { useState } from 'react';
import Histogram from '@onarchipelago/cx/Reports/Charts/Histogram/Histogram';
import { Options, TooltipFormatterCallbackFunction } from 'highcharts';
import { ButtonGroup, EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { formatCurrency } from '@app/utils/format';
import { useValuationSummaryReportContext } from '../ValuationSummaryReportContext';
import { NEGATIVE_LABELS, POSITIVE_LABELS, X_AXIS_LABELS } from './constants';
import { buttonLabelStyles, titleContainerStyles } from './PropertyDistributionHistogram.emotion';

enum MODE {
  BRC = 'BRC',
  PROPERTY_COUNT = 'PROPERTY_COUNT',
}

export const PropertyDistributionHistogram = () => {
  const [mode, setMode] = useState<MODE>(MODE.BRC);
  const { euiTheme } = useEuiTheme();

  const { summaryData, loading } = useValuationSummaryReportContext();

  if (loading) {
    return (
      <LoadingSpinnerV2
        dataTestId="property-distribution-histogram-loading-spinner"
        styles={{ height: '528px' }}
      />
    );
  }
  const data = summaryData?.brcDistribution;

  const modeIsBRC = mode === MODE.BRC;

  const extractData = (labels: string[]) => labels.map((label) => {
      const columnData = data?.find((column) => column.displayName === label);
      const x = X_AXIS_LABELS.indexOf(label);
      const dataValue = modeIsBRC ? columnData?.buildingReplacementCost : columnData?.propertyCount;

      return { name: label, x, y: dataValue };
    });

  const negativeData = extractData(NEGATIVE_LABELS);
  const positiveData = extractData(POSITIVE_LABELS);

  const histogramOptions = {
    chart: {
      height: 520,
      marginTop: 90,
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      itemStyle: {
        fontFamily: euiTheme.font.family,
        fontWeight: '400',
      },
      layout: 'horizontal',
      padding: 0,
      symbolRadius: 0,
      verticalAlign: 'top',
      y: -35,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        groupPadding: 0.1,
        grouping: false,
        pointPadding: 0,
        shadow: false,
      },
    },
    series: [
      {
        color: euiTheme.colors.dataViz.gray2,
        data: negativeData,
        name: 'Properties with RCV <b>greater</b> than estimated value',
        type: 'column',
      },
      {
        color: euiTheme.colors.dataViz.orange2,
        data: positiveData,
        name: 'Properties with RCV <b>less</b> than estimated value',
        type: 'column',
      },
    ],
    subtitle: {
      align: 'left',
      style: {
        fontFamily: euiTheme.font.family,
        fontWeight: 'bold',
      },
      text: modeIsBRC ? '$ RCV' : 'Count',
      y: 55,
    },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: '#404040',
      borderRadius: 10,
      borderWidth: 0,
      enabled: true,
      formatter: function (this, tooltip) {
        const columnData = data.find((column) => column.displayName === this.key);
        return `<b>${this.key} difference</b> <br/> ${formatCurrency(
          columnData?.buildingReplacementCost,
        )} RCV <br/> ${columnData?.propertyCount} properties`;
      } as TooltipFormatterCallbackFunction,
      padding: 12,
      shadow: false,
      style: {
        color: 'white',
        fontFamily: euiTheme.font.family,
        fontSize: '14px',
      },
    },
    xAxis: {
      categories: X_AXIS_LABELS,
      labels: {
        style: {
          fontFamily: euiTheme.font.family,
        },
      },
      title: {
        align: 'middle',
        margin: 16,
        style: {
          fontFamily: euiTheme.font.family,
          fontWeight: 'normal',
        },
        text: '<span style="margin-right: 60px">< Third-party estimate less than RCV</span> \
          <b>% Difference between third party estimates and provided building values</b> \
          <span style="margin-left: 60px">Third-party estimate greater than RCV ></span>',
        useHTML: true,
      },
    },
    yAxis: {
      labels: {
        format: modeIsBRC ? '${text}' : '{text}',
        style: {
          fontFamily: euiTheme.font.family,
        },
      },
      title: {
        text: '',
      },
    },
  };

  return (
    <>
      <ChartTitle mode={mode} setMode={setMode} />
      <Histogram options={histogramOptions as Options} />
    </>
  );
};

const ChartTitle = ({ mode, setMode }) => {
  const toggleButtons = [
    {
      id: MODE.BRC,
      label: '$ RCV',
    },
    {
      id: MODE.PROPERTY_COUNT,
      label: 'Property Count',
    },
  ];

  return (
    <EuiFlexGroup
      direction="row"
      justifyContent="flexEnd"
      alignItems="center"
      style={titleContainerStyles}
      gutterSize="none"
    >
      <EuiFlexItem>
        <EuiText>
          <h3>Property distribution</h3>
        </EuiText>
      </EuiFlexItem>
      <EuiText style={buttonLabelStyles}>Aggregate By</EuiText>
      <ButtonGroup
        data-testid="mode-button"
        data-tourid="mode-button"
        size="m"
        idSelected={mode}
        legend="Histogram Mode"
        onChange={(id: MODE) => setMode(id)}
        options={toggleButtons}
      />
    </EuiFlexGroup>
  );
};
