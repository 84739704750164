import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip,useToast } from 'ui';
import { E2VALUE } from '@app/platform/Integrations/consts';
// FIX ME
// @ts-ignore
import GET_USAGE from '@app/queries/integrations/getUsage.gql';
import { IUsageData, IUsageVariables } from '@app/queries/integrations/types';
import { formatDate } from '@app/utils/format';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { integrationsApolloClient } from '../Organizations/Tabs/IntegrationsTab/IntegrationsApolloClient';
import DocumentsButton from './DocumentsButton/DocumentsButton';
import { ReportsButton } from './ReportsButton/ReportsButton';
import ManageStreamButton from './ManageStreamButton';
import ShareStreamButton from './ShareStreamButton';
import { HeaderButtonsContainer, HeaderContainer } from './StreamHeader.emotion';
import { useStreamContext } from './StreamProvider';
import { StreamPageOptions } from './types';
import { showManageStreamButton } from './utils';

interface IProps {
  options: StreamPageOptions;
  streamTour: React.ReactNode;
}

const StreamHeader: FC<IProps> = ({ options, streamTour }) => {
  const toast = useToast();
  const { stream, permissions } = useStreamContext();

  const tour = stream?.highlights !== null ? JSON.parse(stream?.highlights || '') : null;

  // if any of the permissions that allow a menu item in the manage streams button to be shown are true, display the button
  const showManageStreamsButton = showManageStreamButton(permissions, options);

  const { data } = useQuery<IUsageData, IUsageVariables>(GET_USAGE, {
    client: integrationsApolloClient,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        organizationID: stream.organizationId,
        partnerName: E2VALUE,
      },
    },
  });

  return (
    <EuiFlexGroup justifyContent="spaceBetween" data-testid="stream-page-header" gutterSize="s">
      <HeaderButtonsContainer grow={false}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem grow={false}>
            <EuiToolTip content={stream?.name} position="bottom">
              <EuiText>
                <HeaderContainer>{stream?.name}</HeaderContainer>
              </EuiText>
            </EuiToolTip>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {stream?.effectiveDate && stream?.expiryDate && (
              <EuiText color="subdued">
                {formatDate(stream?.effectiveDate)}
                {' - '}
                {formatDate(stream?.expiryDate)}
              </EuiText>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </HeaderButtonsContainer>
      <EuiFlexItem grow={0}>
        <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
          <ShareStreamButton show={options.enableStreamInviteBtn} />
          {showManageStreamsButton && (
            <EuiFlexItem grow={false}>
              <ManageStreamButton
                options={{
                  ...options,
                  enabledRunIntegrationsBtn: data?.usage?.enabled || false,
                }}
              />
            </EuiFlexItem>
          )}
          {tour?.steps && <EuiFlexItem grow={false}>{streamTour}</EuiFlexItem>}
          {permissions?.canViewReports && (
            <EuiFlexItem grow={false}>
              <ReportsButton />
            </EuiFlexItem>
          )}
          {options.enableDocumentsBtn && (
            <EuiFlexItem grow={false}>
              <DocumentsButton />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default StreamHeader;
