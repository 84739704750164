import styled from '@emotion/styled';
import { EuiPageSection } from 'ui';

export const PageSectionContainer = styled(EuiPageSection)`
  display: flex;
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 16px);
  }
`;
