import styled from '@emotion/styled';
import { EuiFlexGroup, EuiPanel } from 'ui';

export const Panel = styled(EuiPanel)`
  display: flex;
`;

export const ReportContainer = styled(EuiFlexGroup)`
  margin: -12px auto 0px auto;
  padding: 0px 32px;
  width: 1252px;
`;
