import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationActivitiesQueryVariables = Types.Exact<{
  input: Types.OrganizationActivitiesInput;
}>;


export type OrganizationActivitiesQuery = { __typename?: 'Query', organizationActivities: { __typename?: 'OrganizationActivitiesResponse', cursor?: string | null, activities: Array<{ __typename?: 'ActivityEvent', organizationID: string, streamSlugRandomIdentifier: string, eventID: string, eventName: string, userID: string, email: string, name?: string | null, timestamp: any, propertiesCount: number, eventDetails: string, properties?: Array<{ __typename?: 'Property', locationName?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, id: string }> | null, attributes?: Array<{ __typename?: 'AttributeMetadata', displayName: string }> | null }> } };


export const OrganizationActivitiesDocument = gql`
    query organizationActivities($input: OrganizationActivitiesInput!) {
  organizationActivities(input: $input) {
    activities {
      organizationID
      streamSlugRandomIdentifier
      eventID
      eventName
      userID
      email
      name
      timestamp
      propertiesCount
      eventDetails
      properties {
        locationName
        streetAddress
        city
        state
        id
      }
      attributes {
        displayName
      }
    }
    cursor
  }
}
    `;

/**
 * __useOrganizationActivitiesQuery__
 *
 * To run a query within a React component, call `useOrganizationActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationActivitiesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>(OrganizationActivitiesDocument, options);
      }
export function useOrganizationActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>(OrganizationActivitiesDocument, options);
        }
export type OrganizationActivitiesQueryHookResult = ReturnType<typeof useOrganizationActivitiesQuery>;
export type OrganizationActivitiesLazyQueryHookResult = ReturnType<typeof useOrganizationActivitiesLazyQuery>;
export type OrganizationActivitiesQueryResult = Apollo.QueryResult<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>;