import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { EuiFormRow, EuiPopover } from 'ui';
import SnapshotButton from '@app/components/Snapshot/SnapshotButton';
import { getSnapshot, groupSnapshotsByQuarter } from '@app/components/Snapshot/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { StyledButton } from '@app/cx/OrgSwitcher/OrgSwitcherButton.emotion';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { usePageState } from '@app/cx/Stream/utils';
import { SnapshotType } from '@app/graphql/types';

export const HeaderSnapshotButton = () => {
  const { account } = useAuth();
  const { stream, marketVisibleToggle } = useStreamContext();
  const [pageState] = usePageState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const getDefaultSnapshotId = () => {
    const defaultSnapshot = stream?.snapshots?.filter(
      (snapshot) => snapshot.name === stream.defaultSnapshot,
    );
    if (defaultSnapshot.length > 0) {
      return defaultSnapshot[0].id;
    }

    return null;
  };

  let snapshots = stream?.snapshots || [];

  if (marketVisibleToggle) {
    // FIX ME
    // @ts-ignore
    snapshots = snapshots.filter((snap) => snap.marketVisible);
  }

  const groupedSnapshotsWithoutValuations = useMemo(
    () =>
      groupSnapshotsByQuarter(
        // FIX ME
        // @ts-ignore
        snapshots.filter((snapshot) => snapshot.type !== SnapshotType.Valuation) || [],
        moment.unix(stream?.effectiveDate || 0),
        stream?.propertyStatusLabel,
      ),
    [
      JSON.stringify(stream?.snapshots),
      stream?.effectiveDate,
      stream?.propertyStatusLabel,
      marketVisibleToggle,
    ],
  );

  const groupedSnapshotsWithoutSOVs = useMemo(
    () =>
      groupSnapshotsByQuarter(
        // FIX ME
        // @ts-ignore
        snapshots.filter((snapshot) => snapshot.type === SnapshotType.Standard) || [],
        moment.unix(stream?.effectiveDate || 0),
        stream?.propertyStatusLabel,
      ),
    [
      JSON.stringify(stream?.snapshots),
      stream?.effectiveDate,
      stream?.propertyStatusLabel,
      marketVisibleToggle,
    ],
  );

  const selectedCurrentSnapshot = getSnapshot(
    groupedSnapshotsWithoutSOVs,
    pageState?.currentSnapshot,
  );

  const selectedChangesSinceSnapshot = getSnapshot(
    groupedSnapshotsWithoutValuations,
    pageState?.changesSince,
  );
  let buttonLabel = selectedCurrentSnapshot?.shortLabel || (marketVisibleToggle ? '' : 'Latest');

  if (selectedChangesSinceSnapshot) {
    buttonLabel = `${buttonLabel} - ${selectedChangesSinceSnapshot?.shortLabel}`;
  }

  const button = (
    // FIX ME
    // @ts-ignore
    <StyledButton
      label={buttonLabel}
      shape="oval"
      iconSide="right"
      iconName="chevronDown"
      size="m"
      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
    />
  );

  return (
    <EuiPopover
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      // FIX ME
      // @ts-ignore
      anchorProps={{ style: { display: 'flex' } }}
    >
      {groupedSnapshotsWithoutSOVs.length > 0 && (
        <EuiFormRow label="View data as of" style={{ minWidth: '300px' }}>
          <SnapshotButton
            isAdmin={account?.permissions?.admin}
            defaultSnapshot={getDefaultSnapshotId()}
            snapshotType="current"
            snapshots={groupedSnapshotsWithoutSOVs}
          />
        </EuiFormRow>
      )}
      {groupedSnapshotsWithoutValuations.length > 0 && (
        <EuiFormRow label="Changes since" style={{ minWidth: '300px' }}>
          <SnapshotButton
            defaultSnapshot={stream.defaultSnapshot}
            snapshotType="changesSince"
            snapshots={groupedSnapshotsWithoutValuations}
          />
        </EuiFormRow>
      )}
    </EuiPopover>
  );
};
