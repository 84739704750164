import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePropertyRecommendationsMutationVariables = Types.Exact<{
  input: Types.CreatePropertyRecommendationsInput;
}>;


export type CreatePropertyRecommendationsMutation = { __typename?: 'Mutation', createPropertyRecommendations: { __typename?: 'CreatePropertyRecommendationsResponse', mlRecommendationsStatus: Types.PropertyRecommendationsStatus } };


export const CreatePropertyRecommendationsDocument = gql`
    mutation createPropertyRecommendations($input: CreatePropertyRecommendationsInput!) {
  createPropertyRecommendations(input: $input) {
    mlRecommendationsStatus
  }
}
    `;
export type CreatePropertyRecommendationsMutationFn = Apollo.MutationFunction<CreatePropertyRecommendationsMutation, CreatePropertyRecommendationsMutationVariables>;

/**
 * __useCreatePropertyRecommendationsMutation__
 *
 * To run a mutation, you first call `useCreatePropertyRecommendationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyRecommendationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyRecommendationsMutation, { data, loading, error }] = useCreatePropertyRecommendationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyRecommendationsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyRecommendationsMutation, CreatePropertyRecommendationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyRecommendationsMutation, CreatePropertyRecommendationsMutationVariables>(CreatePropertyRecommendationsDocument, options);
      }
export type CreatePropertyRecommendationsMutationHookResult = ReturnType<typeof useCreatePropertyRecommendationsMutation>;
export type CreatePropertyRecommendationsMutationResult = Apollo.MutationResult<CreatePropertyRecommendationsMutation>;
export type CreatePropertyRecommendationsMutationOptions = Apollo.BaseMutationOptions<CreatePropertyRecommendationsMutation, CreatePropertyRecommendationsMutationVariables>;