import React from 'react';
import { Assignment } from '@onarchipelago/cx/RoleChecker/types';
import { EuiAccordion, useEuiTheme } from 'ui';
import { stripPx } from '@app/utils/styles';
import { AccordionListItem, ListItem, Panel } from './RoleAssignment.emotion';

interface Props {
  assignment: Assignment;
}

const RoleAssignment: React.FC<Props> = ({ assignment }) => {
  const { euiTheme } = useEuiTheme();
  const { size } = euiTheme;

  return (
    <>
      {assignment.scopes.map((scope) => (
        <ListItem
          style={{ margin: `${size.s} 0` }}
          data-testid="role-checker-role-assignment"
          key={scope.value}
        >
          <span>{`${assignment.role} on ${scope.name}`}</span>
          <EuiAccordion
            id="accordion1"
            buttonContent="Properties:"
            style={{ margin: `${size.s} 0 ${size.s} ${size.l}` }}
            initialIsOpen={scope.filters?.length < 6 || !scope.filters}
          >
            <Panel style={{ maxHeight: `${stripPx(size.base) * 18}px` }} color="subdued">
              {scope.filters?.length > 0 ? (
                <ul>
                  {scope.filters[0].resources.map(({ id, name }, index) => (
                    <AccordionListItem style={{ margin: `${size.s} 0` }} key={`${id}-${index}`}>
                      {name || id}
                    </AccordionListItem>
                  ))}
                </ul>
              ) : (
                <ul>
                  <AccordionListItem style={{ margin: `${size.s} 0` }}>
                    All Properties
                  </AccordionListItem>
                </ul>
              )}
            </Panel>
          </EuiAccordion>
        </ListItem>
      ))}
    </>
  );
};
export default RoleAssignment;
