import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const EnrichmentTabContainer = styled(EuiPanel)`
  height: 100%;

  > .euiFlexGroup,
  .report-body {
    height: 100%;
  }

  .report-body {
    overflow-y: auto;
  }

  .property-select {
    min-width: 256px;
  }

  .euiBasicTable {
    overflow-y: auto;
  }
`;
