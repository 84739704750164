import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IAccountState } from '@app/containers/AuthProvider/types';

const formatStorageKey = (userId: string, key: string) => {
  const href = window?.location?.href;
  // eslint-disable-next-line no-useless-escape
  const domain = /:\/\/([^\/]+)/.exec(href)?.[1] || 'unknown';
  const environment = domain.split('.')[0].split(':')[0];
  return `${environment}::${userId}::${key}`;
};

type Return<Type> = [Type, Dispatch<SetStateAction<Type>>, () => void];
export default <Type>(
  account: IAccountState | undefined,
  keyName: string,
  defaultValue: any,
): Return<Type> => {
  const userId = (account as IAccountState)?.userId || '';
  const key = formatStorageKey(userId, keyName);
  const stored = sessionStorage.getItem(key);
  const initial = stored ? JSON.parse(stored) : defaultValue;
  const [value, setValue] = useState<Type>(initial);

  useEffect(() => {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  const clearValue = () => {
    localStorage.removeItem(key);
  };

  return [value, setValue, clearValue];
};
