import React, { useContext } from 'react';
import get from 'lodash/get';
import { EuiFormRow, EuiTextArea } from 'ui';
import StreamEditContext from '../StreamEditContext';
import { IFieldMeta } from '../validator';

export const prettyJSON = (jsonText: string): string => {
  let value = '';
  if (!jsonText) {return value;}
  try {
    value = JSON.stringify(JSON.parse(jsonText), undefined, 4);
  } catch (e) {
    value = jsonText;
  }

  return value;
};

interface Props {
  className?: string;
  name: string;
  label?: string;
  rowsMax?: number;
}
const JSONField: React.FC<Props> = ({ name, label, rowsMax, className }) => {
  const { copyOfStream, setFieldValue, meta, touch } = useContext(StreamEditContext);
  if (!copyOfStream || !setFieldValue || !meta || !touch) {
    return null;
  }

  const { touched, error }: IFieldMeta = get(meta, name) || {};
  return (
    <EuiFormRow
      className={className}
      error={touched && error}
      label={label}
      fullWidth
      isInvalid={touched && !!error}
    >
      <EuiTextArea
        isInvalid={touched && !!error}
        rows={rowsMax}
        defaultValue={prettyJSON(get(copyOfStream, name))}
        fullWidth
        resize="none"
        onBlur={() => touch(name)}
        onChange={({ target: { value } }) => setFieldValue(name, value)}
        style={{ height: '100%' }}
      />
    </EuiFormRow>
  );
};

export default JSONField;
