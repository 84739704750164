import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { EuiConfirmModal, useToast } from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
// FIX ME
// @ts-ignore
import DELETE_ORG from '@app/queries/organizations/deleteOrg.gql';
// FIX ME
// @ts-ignore
import GET_ORGANIZATIONS from '@app/queries/organizations/getOrganizations.gql';
import { IDeleteOrgVariables, IGraphQLOrganization } from '@app/queries/organizations/types';

interface IProps {
  org?: IGraphQLOrganization;
}

const DeleteButton: React.FC<IProps> = ({ org }) => {
  const toast = useToast();
  const { closeModal } = useContext(ModalContext);

  const [deleteOrgMutation, { called, loading, error }] = useMutation<any, IDeleteOrgVariables>(
    DELETE_ORG,
  );

  const onConfirm = () => {
    deleteOrgMutation({
      refetchQueries: [
        {
          query: GET_ORGANIZATIONS,
        },
      ],
      variables: {
        input: {
          orgName: `${org?.name}`,
        },
      },
    });
  };

  useEffect(() => {
    if (called && !loading) {
      if (error) {
        toast({ title: `Could not delete organization: ${error.message}`, type: 'danger' });
      } else {
        closeModal();
      }
    }
  }, [loading, error]);

  return (
    <EuiConfirmModal
      title="Delete Account"
      onCancel={closeModal}
      onConfirm={onConfirm}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      <p>Are you sure you want to delete {org?.name}?</p>
    </EuiConfirmModal>
  );
};

export default DeleteButton;
