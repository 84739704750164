import React, { ReactElement, useState } from 'react';
import { EuiBasicTable } from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { PropertyMetadata } from '../types';
import getMetadataTableItems from './getMetadataTableItems';
import styles from './MetadataTableChildren.emotion';
import metadataTableColumns from './metadataTableColumns';
import MetadataTableGrandchildren from './MetadataTableGrandChildren';
import useUpdateBoolean from './useUpdateBoolean';

interface Props {
  data: Array<PropertyMetadata>;
  parentAttribute: PropertyMetadata;
  propertyMetadata: Array<PropertyMetadata>;
}

const MetadataTableChildren: React.FC<Props> = ({ data, parentAttribute, propertyMetadata }) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<
    Record<string, ReactElement>
  >({});

  const toggleDetails = (item: any) => {
    if (itemIdToExpandedRowMap[item.id]) {
      setItemIdToExpandedRowMap((prevState) => {
        const finalState = { ...prevState };
        delete finalState[item.id];

        return finalState;
      });
    } else {
      const parentAttributeName = item.name.split(' ')[0];
      const attribute = propertyMetadata.filter((datum) => datum.name === parentAttributeName)[0];

      setItemIdToExpandedRowMap((prevState) => ({
        ...prevState,
        [item.id]: <MetadataTableGrandchildren data={item?.children} parentAttribute={attribute} />,
      }));
    }
  };

  const { setSelectedAttribute } = useAttributeMetadataEditorContext();
  const updateBoolean = useUpdateBoolean(parentAttribute);

  const columns = metadataTableColumns({
    childType: 'child',
    handleEdit: (attribute) => setSelectedAttribute({ attribute, parentAttribute }),
    handleUpdate: updateBoolean,
    itemIdToExpandedRowMap,
    toggleDetails,
  });

  const items = getMetadataTableItems(data);

  return (
    <EuiBasicTable
      data-testid="attribute-metadata-child-table"
      className={styles.table}
      items={items}
      itemID="id"
      itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      columns={columns}
    />
  );
};

export default MetadataTableChildren;
