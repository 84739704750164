import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Currency from '@app/components/Currency/Currency';
import PropertyImg from '@app/components/PropertyImg/PropertyImg';
import { IMAGE_SIZES } from '@app/config';
import { propertiesModalPathname, useDecodedParams } from '@app/containers/App/Routes/utils';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { IPropertiesGalleryProperty } from './types';

export interface IProps {
  stream: IGraphQLStream;
  property: IPropertiesGalleryProperty | undefined;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
}

const StyledLink = styled(Link)`
  border: none;
  display: block;
  width: 172px;
  height: 200px;
`;

const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 8px 12px;

  h3 {
    font-weight: normal;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.0015em;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

const Value = styled.div`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 4px 8px;
  line-height: 19px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
`;

const Container = styled.div`
  color: black;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  width: 172px;
  height: 200px;
  margin: 0 16px 16px 0;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Card: FC<IProps> = ({ stream, property, propertyAttributeMetadata }) => {
  const params = useDecodedParams();
  if (!property) {
    return null;
  }
  return (
    <StyledLink
      role="button"
      to={{
        pathname: propertiesModalPathname(stream, params, property.id),
        search: window.location.search,
        state: { propertyAttributeMetadata },
      }}
    >
      <Container>
        <Value>
          <Currency value={property.totalInsuredValueDisplay} />
        </Value>
        <PropertyImg
          locationName={property.locationName}
          pictures={property.pictures}
          width={IMAGE_SIZES.medium.width}
          showDefault={false}
        />
        <Overlay>
          <h3>{property.locationName}</h3>
        </Overlay>
      </Container>
    </StyledLink>
  );
};

export default Card;
