import React, { FC, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { Button, useToast } from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useApplySovJobMutation } from '@app/graphql/jobs/mutations/__generated__/applySOVJob.generated';

interface IProps {
  jobID: string;
}

const ApplySOVJobButton: FC<IProps> = ({ jobID }) => {
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();

  const [applySOVJobWorksheet, { data, loading: isSubmitting }] = useApplySovJobMutation({
    client: jobsApolloClient,
    onCompleted: () => {
      toast({ title: 'SOV worksheet submitted to be applied', type: 'success' });
    },
    onError: (error: ApolloError) => {
      toast({ title: `Could not download worksheet: ${error.message}`, type: 'danger' });
    },
    variables: {
      input: {
        jobID: jobID,
      },
    },
  });

  const handleSubmitForReview = async () => {
    applySOVJobWorksheet();
  };

  return (
    <Button
      data-testid="job-upload"
      size="s"
      fill
      onClick={handleSubmitForReview}
      label="Apply SOV Job"
    />
  );
};

export default ApplySOVJobButton;
