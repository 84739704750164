import { START_EDIT_LOSSES_JOB } from '@app/queries/organizations/startEditLossesJob';
import { START_EDIT_PROPERTIES_JOB } from '@app/queries/organizations/startEditPropertiesJob';
import { SUBMIT_EDIT_LOSSES_JOB } from '@app/queries/organizations/submitEditLossesJob';
import { SUBMIT_EDIT_PROPERTIES_JOB } from '@app/queries/organizations/submitEditPropertiesJob';
import { EJobType } from '@app/queries/organizations/types';

export const getStartEditJobQuery = (jobType: EJobType) => {
  switch (jobType) {
    case EJobType.Enrichment:
      return START_EDIT_PROPERTIES_JOB;

    case EJobType.EditLosses:
      return START_EDIT_LOSSES_JOB;
  }
};

export const getSubmitEditJobQuery = (jobType: EJobType) => {
  switch (jobType) {
    case EJobType.Enrichment:
      return SUBMIT_EDIT_PROPERTIES_JOB;

    case EJobType.EditLosses:
      return SUBMIT_EDIT_LOSSES_JOB;
  }
};

export const getEditDataObject = (jobType: EJobType, data: any) => {
  switch (jobType) {
    case EJobType.Enrichment:
      return data.startEditPropertiesJob;

    case EJobType.EditLosses:
      return data.startEditLossesJob;
  }
};

export const getUploadFlyoutTitle = (jobType: EJobType) => {
  switch (jobType) {
    case EJobType.Enrichment:
      return 'Edit your property data';

    case EJobType.EditLosses:
      return 'Edit your loss data';
  }
};
