import { UserViews } from '@onarchipelago/cx/Reports/queries/getEngagementViewsByUser';
import { User } from './types';

export default (userViews: UserViews, id: number): User => {
  const {
    country,
    downloads,
    email,
    jobTitle,
    lastLogin,
    organizationName,
    sessions,
    userName,
    viewedHighlights,
  } = userViews;

  return {
    archipelagoExtract: downloads.Archipelago_extract,
    country,
    email,
    id: `${userName}-${id}`,
    jobTitle,
    lastLogin,
    modellingFiles: downloads.Modelling_files,
    organizationName,
    otherStreamDocs: downloads.Stream_docs,
    propertyDocs: downloads.Property_docs,
    sessions,
    userName,
    viewedHighlights,
  };
};
