import React from 'react';
import {
  CarrierAssessment,
  CarrierLossYear,
  Grade,
  LossMetric,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { EuiBasicTableColumn } from 'ui';
import { formatCurrency, formatNumber } from '@app/utils/format';
import { getColor, toTitle } from '../../../utils';
import { RiskAttribute, TableData } from '../types';

export interface BenchmarkRatio {
  ratio: number;
  gradeColor: string;
}

export interface Item extends CarrierLossYear {
  benchmarkRatio: BenchmarkRatio;
}

export interface SummaryItem {
  avgPaidIndemnity: number;
  lossFrequency: number;
  lossFrequencyBenchmarkDescription: string;
  claimCnt: number;
  uwYear: number;
  totalPaidIndemnity: number;
  benchmarkRatio: BenchmarkRatio;
  overallGrade: Grade;
  period: string;
  benchmark: number;
}
interface AnnualLossTableData extends TableData<Item> {
  title: string;
  summaryTitle: string;
  summary: SummaryItem;
  summaryColumns: Array<EuiBasicTableColumn<SummaryItem>>;
}

interface LossMetricMap {
  [riskAttribute: string]: {
    [displayName: string]: LossMetric;
  };
}

type LossRiskAttribute = 'Customer Average Loss' | 'Customer Loss Frequency';
type LossMetricSection =
  | 'Total Paid Indemnity'
  | 'Avg Paid Indemnity'
  | 'Avg Paid Indemnity Benchmark Ratio'
  | 'Total Non-Zero Claims'
  | 'Total claims'
  | 'Loss Frequency'
  | 'Loss Frequency Benchmark Ratio';

const getLossMetricMap = (lossSummaryMetrics: Array<LossMetric>): LossMetricMap => {
  let metricMap: LossMetricMap = {};

  lossSummaryMetrics.forEach((m) => {
    metricMap = {
      ...metricMap,
      [m.section]: {
        ...metricMap[m.section],
        [m.displayName]: m,
      },
    };
  });
  return metricMap;
};

const getMetric = (
  lossMetricMap: LossMetricMap,
  riskAttribute: LossRiskAttribute,
  section: LossMetricSection,
): LossMetric => lossMetricMap[riskAttribute][section];

export const getBenchmarkRatio = (
  lossYear: CarrierLossYear,
  attribute: RiskAttribute,
): BenchmarkRatio | null => {
  switch (attribute.value) {
    case 'CUSTOMER_AVERAGE_LOSS':
      return {
        gradeColor: lossYear.avgPaidIndemnityGrade.color,
        ratio: lossYear.avgPaidIndemnityBenchmarkRatio * 100,
      };

    case 'CUSTOMER_LOSS_FREQUENCY':
      return {
        gradeColor: lossYear.lossFrequencyGrade.color,
        ratio: lossYear.lossFrequencyBenchmarkRatio * 100,
      };
    default:
      return null;
  }
};

const defaultCols: Array<EuiBasicTableColumn<Item>> = [
  {
    field: 'totalPaidIndemnity',
    name: 'Total Paid Indemnity',
    // FIX ME
    // @ts-ignore
    render: formatCurrency,
  },
];

const getTitle = (attribute: RiskAttribute): string =>
  attribute.value === 'CUSTOMER_AVERAGE_LOSS' ? 'Annual Loss Experience' : 'Annual Loss Frequency';

const getSummaryTitle = (attribute: RiskAttribute, lossMetrics: Array<LossMetric>): string =>
  `${lossMetrics[0].period} ${
    attribute.value === 'CUSTOMER_AVERAGE_LOSS'
      ? 'Loss Experience Summary'
      : 'Loss Frequency Summary'
  }`;

const getYearRange = (lossSummaryMetrics: Array<LossMetric>): number => {
  if (lossSummaryMetrics.length === 0) {
    return 0;
  }

  const { period } = lossSummaryMetrics[0];
  const years = period.split('-');

  return parseInt(years[1], 10) - parseInt(years[0], 10) + 1;
};

export const getSummary = (
  attribute: RiskAttribute,
  carrierAssessment: CarrierAssessment,
): SummaryItem => {
  const metricsMap = getLossMetricMap(carrierAssessment.lossSummaryMetrics);
  const currentLoss = carrierAssessment.propertyLossDetails.find(
    (d) => d.attribute === attribute.value,
  );

  const lossYear = carrierAssessment.lossYears[0];

  let benchmark: number;
  let section: LossMetricSection;
  if (attribute.value === 'CUSTOMER_AVERAGE_LOSS') {
    section = 'Avg Paid Indemnity Benchmark Ratio';
    benchmark = lossYear?.avgPaidIndemnityBenchmark;
  } else if (attribute.value === 'CUSTOMER_LOSS_FREQUENCY') {
    section = 'Loss Frequency Benchmark Ratio';
    benchmark = lossYear?.lossFrequencyBenchmark;
  }

  return {
    avgPaidIndemnity: getMetric(metricsMap, 'Customer Average Loss', 'Avg Paid Indemnity')?.value,
    benchmark,
    benchmarkRatio: {
      gradeColor: currentLoss.overallGrade.color,
      ratio: getMetric(metricsMap, toTitle(attribute.value) as LossRiskAttribute, section)?.value,
    },
    claimCnt: getMetric(metricsMap, 'Customer Loss Frequency', 'Total claims')?.value,
    lossFrequency: getMetric(metricsMap, 'Customer Loss Frequency', 'Loss Frequency')?.value,
    lossFrequencyBenchmarkDescription: lossYear?.lossFrequencyBenchmarkDescription,
    overallGrade: currentLoss.overallGrade,
    period: carrierAssessment.lossSummaryMetrics[0]?.period,
    totalPaidIndemnity: getMetric(metricsMap, 'Customer Average Loss', 'Total Paid Indemnity')
      ?.value,
    uwYear: getYearRange(carrierAssessment.lossSummaryMetrics),
  };
};
export const getTableData = (
  attribute: RiskAttribute,
  carrierAssessment: CarrierAssessment,
): AnnualLossTableData => {
  const columns = [...defaultCols];
  if (attribute.value === 'CUSTOMER_AVERAGE_LOSS') {
    columns.push({
      field: 'avgPaidIndemnity',
      name: 'Avg. Paid Indemnity',
      // FIX ME
      // @ts-ignore
      render: formatCurrency,
    });
  } else {
    columns.push({
      field: 'lossFrequency',
      name: (
        <p>
          GWP Normalized <br />
          Claim Freq.
        </p>
      ),
    });
  }

  columns.push({
    className: 'coloredCol',
    field: 'benchmarkRatio',
    name: 'Benchmark Ratio',
    render: ({ ratio, gradeColor }) => (
      <div style={{ backgroundColor: getColor(gradeColor), padding: '8px', width: '100%' }}>
        {formatNumber(ratio, undefined, { maximumFractionDigits: 2 })} %
      </div>
    ),
  });

  return {
    columns: [
      {
        field: 'uwYear',
        name: 'UW Year',
      },
      {
        field: 'claimCnt',
        name: 'Claims',
      },
      ...columns,
    ],
    items: carrierAssessment.lossYears.map((y) => ({
      ...y,
      benchmarkRatio: getBenchmarkRatio(y, attribute),
    })),
    summary: getSummary(attribute, carrierAssessment),
    summaryColumns: [
      {
        field: 'uwYear',
        name: 'UW Years',
      },
      {
        field: 'claimCnt',
        name: 'Total Claims',
      },
      // FIX ME
      // @ts-ignore
      ...(columns as Array<EuiBasicTableColumn<SummaryItem>>),
    ],
    summaryTitle: getSummaryTitle(attribute, carrierAssessment.lossSummaryMetrics),
    title: getTitle(attribute),
  };
};
