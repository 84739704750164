import { EuiSelectableOption } from 'ui';
import {
  GetPropertyAttributeMetadataV2Result,
  OrgAttributeMetadataResult,
} from '@app/queries/propertyMetadata/types';
import { ExportConfigurationType } from './graphql/types';
import {
  FormattedAttributeMetadataType,
  OrderableOptionType,
  SelectableOptionDataType,
} from './types';

const isEnabledAttribute = (
  attributeName: string,
  exportConfig: Array<ExportConfigurationType> | null | undefined,
  defaultEnabled: boolean,
) => {
  if (exportConfig && exportConfig.length) {
    return exportConfig.some((config) => config.attributeName === attributeName);
  }
  return defaultEnabled;
};

export const formatOrgAttributeMetadata = (
  orgAttrData: OrgAttributeMetadataResult,
  exportConfig?: Array<ExportConfigurationType> | null,
): Array<FormattedAttributeMetadataType> => {
  const unformattedData = orgAttrData?.organizationAttributeMetadata;
  if (!unformattedData?.length) {
    return [];
  }
  const sortedUnformattedData = [...unformattedData].sort(
    (fieldA, fieldB) => fieldA.metadata.columnPosition - fieldB.metadata.columnPosition,
  );
  const unformattedParentData = sortedUnformattedData?.filter(
    (field) => field?.metadata && !field.metadata?.parent,
  );

  const formattedParentData = unformattedParentData.map((field) => ({
      children: [],
      enabled: isEnabledAttribute(field.metadata.name, exportConfig, field.includeInStreams),
      id: field.metadata.name,
      label: field.metadata.displayName,
    }));
  sortedUnformattedData.forEach((field) => {
    if (!field?.metadata?.parent) {
      return;
    }
    const parent = formattedParentData.find(
      (parentField) => parentField.id === field.metadata.parent,
    );
    if (!parent) {
      return;
    }

    parent.children.push({
      enabled: isEnabledAttribute(field.metadata.name, exportConfig, field.includeInStreams),
      id: field.metadata.name,
      label: field.metadata.displayName,
    });
  });

  return formattedParentData;
};

export const formatPropAttributeMetadata = (
  propAttrData: GetPropertyAttributeMetadataV2Result,
  exportConfig?: Array<ExportConfigurationType> | null,
): Array<FormattedAttributeMetadataType> => {
  const unformattedData = propAttrData?.propertyAttributeMetadataV2;
  if (!unformattedData?.length) {
    return [];
  }

  const sortedUnformattedData = [...unformattedData].sort(
    (fieldA, fieldB) => fieldA.columnPosition - fieldB.columnPosition,
  );
  const unformattedParentData = sortedUnformattedData?.filter((field) => !field.parent);

  const formattedParentData = unformattedParentData.map((field) => ({
      children: [],
      enabled: isEnabledAttribute(field.name, exportConfig, !field.hiddenInGrid),
      id: field.name,
      label: field.displayName,
    }));
  sortedUnformattedData.forEach((field) => {
    if (!field.parent) {
      return;
    }
    const parent = formattedParentData.find((parentField) => parentField.id === field.parent);
    if (!parent) {
      return;
    }

    parent.children.push({
      enabled: isEnabledAttribute(field.name, exportConfig, !field.hiddenInGrid),
      id: field.name,
      label: field.displayName,
    });
  });

  return formattedParentData;
};

export const makeSelectableAttributeOptions = (
  attrMeta: Array<FormattedAttributeMetadataType>,
): Array<EuiSelectableOption<SelectableOptionDataType>> => attrMeta?.reduce((acc, currentAttr) => {
    if (currentAttr.children.length) {
      const groupKey = `${currentAttr.id}-group`;
      const groupChecked =
        currentAttr.enabled && currentAttr.children.every((child) => child.enabled);
      acc.push({
        checked: groupChecked ? 'on' : undefined,
        data: {
          children: currentAttr.children.map((child) => child.id),
        },
        key: groupKey,
        label: currentAttr.label,
      });
      acc.push({
        checked: currentAttr.enabled ? 'on' : undefined,
        data: {
          groupKey,
        },
        key: currentAttr.id,
        label: currentAttr.label,
      });
      currentAttr.children.forEach((child) => {
        acc.push({
          checked: child.enabled ? 'on' : undefined,
          data: {
            groupKey,
          },
          key: child.id,
          label: child.label,
        });
      });
    } else {
      acc.push({
        checked: currentAttr.enabled ? 'on' : undefined,
        data: {
          groupKey: null,
        },
        key: currentAttr.id,
        label: currentAttr.label,
      });
    }
    return acc;
  }, [] as Array<EuiSelectableOption<SelectableOptionDataType>>);

export const makeOrderableOptionsFromSelectable = (
  selectableAttributeOptions: Array<EuiSelectableOption<SelectableOptionDataType>>,
  exportConfig?: Array<ExportConfigurationType>,
): Array<OrderableOptionType> => {
  let orderableOptions = selectableAttributeOptions
    .map((option) => {
      const isGroupLabel = option.data?.children?.length;
      if (isGroupLabel) {
        return null;
      }
      return option.checked === 'on'
        ? {
            id: option.key,
            label: option.label,
          }
        : null;
    })
    .filter(Boolean);

  if (exportConfig) {
    orderableOptions = orderableOptions.sort((optionA, optionB) => {
      const positionA = exportConfig?.find(
        (config) => config.attributeName === optionA.id,
      )?.position;
      const positionB = exportConfig?.find(
        (config) => config.attributeName === optionB.id,
      )?.position;

      if (positionA && positionB) {
        return positionA - positionB;
      } else if (positionA) {
        return -1;
      } else if (positionB) {return 1;}
      else {
        return 0;
      }
    });
  }
  return orderableOptions;
};

export const makeExportConfigFromOrderableOptions = (
  orderableOptions: Array<OrderableOptionType>,
): Array<ExportConfigurationType> =>
  orderableOptions.map((option, index) => ({
    attributeName: option.id,
    position: index + 1,
  }));
