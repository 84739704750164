import { BinaryOperator } from './types';

const reverseMap = new Map<string, BinaryOperator>();
Object.keys(BinaryOperator).forEach((s: string) => {
  const e = (<any>BinaryOperator)[s];
  reverseMap.set(e.toString(), e);
});

export const valueOfBinaryOperator = (str: string): BinaryOperator => 
  // https://dev.to/unhurried/typescript-implement-valueof-method-in-enums-2gme
   reverseMap.get(str)
;
