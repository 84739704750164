export {};

declare global {
  interface Array<T> {
    findAndPop(o: (t: T) => boolean): T | null;
  }
}

Array.prototype.findAndPop = function (o) {
  const index = this.findIndex(o);
  if (index < 0) {
    return null;
  }

  const item = this[index];
  this.splice(index, 1);

  return item;
};
