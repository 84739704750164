import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { currentAndPrevBound, sprinklers } from './Charts/const';
import HorizontalBarChart from './Charts/HorizontalBarChart';

// Like `EuiHorizontalRule` but Vertical and for this element only, hence
// the marginLeft.
// TODO:
// * Abstract this component into a reusable one.
const VerticalSeparator = () => (
  <EuiFlexItem grow={false} style={{ backgroundColor: '#E0E6EB', width: '1px' }} />
);

interface Props {
  data: RiskSummaryChartData[];
}

const OccupancyConstructionTab: FC<Props> = ({ data }) => {
  const occupancyChartData = data.find((chart) => chart.name === 'SPECIFIC_OCCUPANCIES_BY_TIV');
  const constructionChartData = data.find(
    (chart) => chart.name === 'GENERAL_CONSTRUCTION_TYPES_BY_TIV',
  );
  const sprinklerChartData = data.find(
    (chart) => chart.name === 'SPRINKLER_PROTECTION_BREAKDOWN_BY_TIV',
  );

  return (
    <>
      {occupancyChartData && constructionChartData && sprinklerChartData && (
        <EuiFlexGroup direction="row" justifyContent="spaceBetween" gutterSize="l">
          <EuiFlexItem grow={false}>
            <HorizontalBarChart
              bars={occupancyChartData.values}
              secondaryBars={occupancyChartData.values}
              title="Top Specific Occupancies"
              keyItems={currentAndPrevBound}
              justifyContentLegend="flexStart"
              chartType="OccupancyConstruction"
            />
            <EuiText size="xs">
              <h5>$ TIV→</h5>
            </EuiText>
          </EuiFlexItem>
          <VerticalSeparator />
          <EuiFlexItem grow={false}>
            <HorizontalBarChart
              bars={constructionChartData.values}
              secondaryBars={constructionChartData.values}
              title="Top General Construction Types"
              keyItems={currentAndPrevBound}
              justifyContentLegend="flexStart"
              chartType="OccupancyConstruction"
            />
            <EuiText size="xs">
              <h5>$ TIV→</h5>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <HorizontalBarChart
              bars={sprinklerChartData.values}
              title="Sprinkler Protection Breakdown by TIV"
              includeSecondBar={false}
              keyItems={sprinklers}
              chartType="OccupancyConstruction"
            />
            <EuiText size="xs">
              <h5>% of TIV Sprinklered →</h5>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};

export default OccupancyConstructionTab;
