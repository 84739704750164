import { RiskSummaryChartData } from '@app/queries/streams/types';

export const filterLosses = (
  lossesData: RiskSummaryChartData[],
  lossCategory: string,
  propertyStatus: string,
) =>
  lossesData.filter(
    (chart) => chart.facets.includes(lossCategory) && chart.facets.includes(propertyStatus),
  );

export const findLossChart = (lossesData: RiskSummaryChartData[], chartName: string) =>
  lossesData.find((chart) => chart.name === chartName);
