import React, { FC } from 'react';
import Gallery from '@app/components/Gallery/Gallery';
import { OnResize, OnScroll } from '@app/components/Gallery/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import Card from './Card';
import { IPropertiesGalleryProperty } from './types';

interface IProps {
  properties: Array<IPropertiesGalleryProperty | undefined>;
  height: number;
  stream: IGraphQLStream;
  handleScroll: OnScroll;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
  onResize?: OnResize;
}

const PADDING = 16;
export const PROPERTY_GALLERY_CELL_WIDTH = 172 + PADDING;
export const PROPERTY_GALLERY_CELL_HEIGHT = 200 + PADDING;

const PropertiesGallery: FC<IProps> = ({
  height,
  properties,
  propertyAttributeMetadata,
  stream,
  handleScroll,
  onResize,
}) => (
  <Gallery<IPropertiesGalleryProperty | undefined>
    columnWidth={PROPERTY_GALLERY_CELL_WIDTH}
    rowHeight={PROPERTY_GALLERY_CELL_HEIGHT}
    height={height}
    items={properties}
    onScroll={handleScroll}
    onResize={onResize}
  >
    {({ item }) => (
      <Card stream={stream} property={item} propertyAttributeMetadata={propertyAttributeMetadata} />
    )}
  </Gallery>
);

export default PropertiesGallery;
