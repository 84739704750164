import React, { FC } from 'react';
import { Button } from 'ui';

const SovMapperButton: FC<{
  onClick?: () => void;
}> = ({ onClick }) => (
  <Button data-testid="map-columns" size="s" onClick={onClick} label="Map Columns" />
);

export default SovMapperButton;
