import React from 'react';
import { useQuery } from '@apollo/client';
import HorizontalBarChart from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/HorizontalBarChart';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  EnrichmentAttributeChangeData,
  EnrichmentAttributeChangeResult,
  EnrichmentAttributeChangeVariables,
  GET_ENRICHMENT_ATTRIBUTE_CHANGE,
} from '@onarchipelago/cx/Reports/queries/getEnrichmentAttributeChange';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';

const OccupancyConstructionImprovements: React.FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { afterSnapshot, currentSnapshot } = snapshots;

  const { data, error, loading } = useQuery<
    EnrichmentAttributeChangeData,
    EnrichmentAttributeChangeVariables
  >(GET_ENRICHMENT_ATTRIBUTE_CHANGE, {
    variables: {
      input: {
        snapshotNameAfter: afterSnapshot?.snapshotName || '',
        snapshotNameBefore: currentSnapshot?.snapshotName || '',
        streamSlug: stream.streamSlug,
      },
    },
  });

  if (loading && !error) {
    return <LoadingSpinnerV2 styles={{ height: '302px' }} />;
  }

  if (!loading && error) {
    return <ErrorState />;
  }

  if (data && !loading && !error) {
    const { constructionClass, specificOccupancy } = data.enrichmentAttributeChange;

    const mapAttribute = (attribute: EnrichmentAttributeChangeResult[]) =>
      attribute.map((score) => ({
        label: score.changeType,
        scores: [
          {
            tiv: score.tiv,
            tivPct: score.tivPctImpact,
          },
        ],
      }));

    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiText>
            <h3>Occupancy and Construction Improvements</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <HorizontalBarChart
                bars={mapAttribute(specificOccupancy)}
                showKey={false}
                title="Specific Occupancy Data Quality"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <HorizontalBarChart
                bars={mapAttribute(constructionClass)}
                showKey={false}
                title="Specific Construction Class Data Quality"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return null;
};

export default OccupancyConstructionImprovements;
