import React, { createContext,useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GetQualityData,
  GetQualityVariables,
  HazardCategory,
} from '@onarchipelago/cx/Reports/queries/getQuality';
import { usePageState } from '@onarchipelago/cx/Stream/utils';
import gql from 'graphql-tag';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
// FIX ME
// @ts-ignore
import GET_STREAM_PROPERTY_GROUPS from '@app/queries/explorers/getStreamPropertyGroups.gql';
// FIX ME
// @ts-ignore
import GET_QUALITY_SNAPSHOT_OVERALL from '@app/queries/streams/getQualitySnapshotOverall.gql';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { GetStreamData, GetStreamV2Variables } from '@app/queries/streams/types';
import { usePrecheckUser } from '../hooks/usePrecheckUser';
import { useRecommendations } from '../hooks/useRecommendations';

export const PrecheckContext = createContext({} as IPrecheckContext);

export interface IPrecheckContext {
  params: {
    streamId: string;
    organizationName: string;
  };
  recommendations: {
    setDismissedBuckets: any;
    data: {
      buckets: [any];
      grade: number;
    };
    query: { loading: boolean };
  };

  snapshot: {
    name: string;
  };

  portfolio: {
    tiv: any;
    formattedTiv: any;
    locationCount: any;
    score: any;
  };

  streamGroup: any;

  canSharePreCheck: boolean;
  canEditAllProperties: boolean;

  precheckUser: {
    broker?: boolean;
    hasAccepted?: boolean;
    type: 'Admin' | 'Broker' | 'Customer';
  };

  preCheckServiceEnabled: boolean;
  stream: {
    slug: string;
    id: string;
  };
}

export const PrecheckProvider = ({ children }) => {
  const { streamId } = useParams<{ streamId: string; organizationName: string }>();
  const { selectedOrganization } = useUserSession();
  const organizationName = selectedOrganization?.name;
  const [pageState] = usePageState();
  const userCode = pageState.userCode || '';

  const precheckUser = usePrecheckUser();

  const { data } = useQuery<GetStreamData, GetStreamV2Variables>(GET_STREAM, {
    fetchPolicy: 'no-cache',
    variables: {
      isAdmin: false,
      slug: streamId,
      userCode: userCode,
    },
  });

  const snap = data?.streamV2?.stream?.snapshots?.find((snapshot) => snapshot.visible);
  const { query, recommendationBuckets, setDismissedBuckets } = useRecommendations(snap?.name);

  const streamPermissions = data?.streamV2?.permissions;

  const { data: qs, loading: qsLoading } = useQuery<GetQualityData, GetQualityVariables>(
    GET_QUALITY_SNAPSHOT_OVERALL,
    {
      skip: !snap,
      variables: {
        input: {
          hazard: HazardCategory.All,
          snapshotName: snap?.name,
          streamSlug: streamId,
        },
      },
    },
  );

  const { data: dataPG } = useQuery(GET_STREAM_PROPERTY_GROUPS, {
    variables: {
      groupBy: 'country',
      streamSlug: streamId,
    },
  });

  const group = {
    ...dataPG?.streamPropertyGroups?.groups,
    id: dataPG?.streamPropertyGroups?.groups?.groupID,
  };

  const { tiv, locationCount } = qs?.qualitySnapshotOverall || {};
  const score = Math.ceil(qs?.qualitySnapshotOverall?.score * 100);

  return (
    <PrecheckContext.Provider
      value={{
        canEditAllProperties: streamPermissions?.canEditAllProperties || false,
        canSharePreCheck: streamPermissions?.canSharePreCheck || false,
        params: {
          organizationName,
          streamId,
        },
        portfolio: {
          formattedTiv: tiv ? formatStreamCurrency(tiv) : '-',
          locationCount,
          score,
          tiv,
        },
        // FIX ME
// @ts-ignore
precheckUser,
        

recommendationBuckets,
        

recommendations: {
          // FIX ME
          // @ts-ignore
          data: query.data,
          query: { loading: query.loading || qsLoading },
          setDismissedBuckets,
        },
        

snapshot: {
          name: snap?.name,
        },
        
        
        stream: {
          id: data?.streamV2?.stream?.id,
          slug: streamId,
        },
        streamGroup: group,
      }}
    >
      {children}
    </PrecheckContext.Provider>
  );
};

export const usePrecheckContext = () => useContext(PrecheckContext);
