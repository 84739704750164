import { datadogLogs } from '@datadog/browser-logs';
import { Dictionary } from 'lodash';
import {
  DataTypeInput,
  IGraphlQLOrganizationAttributeMetadata,
  IGraphqlMetadataInput,
} from '@app/queries/propertyMetadata/types';
import { isGroupable } from '@app/utils/metadata';
import { GroupedMetadata, PropertyMetadata } from './types';

interface Grandchild {
  name: string;
  children: Array<PropertyMetadata>;
}

const transformChildren = (children: Array<GroupedMetadata>, grandchild: Grandchild) =>
  children.map((child) => {
    if (child.name === grandchild.name) {
      return {
        ...child,
        children: grandchild.children,
      };
    }

    return child;
  });

export const groupMetadata = (metaData: Array<PropertyMetadata>): Array<GroupedMetadata> => {
  const metaMap: Dictionary<GroupedMetadata> = {};

  // First process all parent attributes
  metaData.forEach((attribute) => {
    if (!attribute.parent) {
      metaMap[attribute.name] = attribute;
    }
  });

  // Then process all attributes that have a parent configured
  metaData.forEach((attribute) => {
    const { parent } = attribute;

    if (parent) {
      metaMap[parent] = {
        ...metaMap[parent],
        children: [...(metaMap[parent]?.children || []), attribute],
      };
    }
  });

  Object.keys(metaMap).forEach((attributeName) => {
    const attributeMeta = metaMap[attributeName];
    const attribute = metaData.filter((attr) => attr.name === attributeName)?.[0];

    // Move the grandchildren to the correct place in the metaMap object
    if (attributeMeta.children && !attributeMeta.name) {
      delete metaMap[attributeName];
      const finalChildren = transformChildren(metaMap[attribute?.parent]?.children || [], {
        children: attributeMeta.children,
        name: attributeName,
      });
      metaMap[attribute?.parent] = {
        ...metaMap[attribute?.parent],
        children: [...finalChildren],
      };
    }
  });

  return Object.keys(metaMap).map((attributeId) => metaMap[attributeId]);
};

export const getMetadataJSON = (orgID: string, meta: Array<GroupedMetadata>, strId?: string) => {
  let json = {};
  for (let i = 0; i < meta.length; i++) {
    const {
      name,
      includeInStreams,
      displayName,
      source,
      children,
      organizationId,
      streamId,
      groupable,
      filterable,
      hiddenInGrid,
      columnPosition,
      dimension,
      subdimension,
      scope,
      parent,
    } = meta[i];
    if ((organizationId === orgID && !strId) || (!!strId && streamId === strId)) {
      json[name] = {
        columnPosition,
        dimension,
        displayName,
        filterable,
        groupable,
        hiddenInGrid,
        includeInStreams,
        parent,
        scope,
        subdimension,
      };
      if (source) {
        json[name].source = source;
      }

      if (children) {
        const childrenJSON = getMetadataJSON(orgID, children, strId);
        if (Object.keys(childrenJSON).length !== 0) {
          json[name].children = childrenJSON;
        }
      }
    } else if (children) {
      json = { ...json, ...getMetadataJSON(orgID, children, strId) };
    }
  }

  return json;
};

export const getMetadataBlob = (
  organizationId: string,
  groupedMetadata: Array<GroupedMetadata>,
  streamId?: string,
): Blob =>
  new Blob([JSON.stringify(getMetadataJSON(organizationId, groupedMetadata, streamId))], {
    type: 'application/json',
  });

export const toGraphqlInput = (metadata: PropertyMetadata): IGraphqlMetadataInput => {
  const metadataInput: IGraphqlMetadataInput = {
    columnPosition: metadata.columnPosition,
    dimension: metadata.dimension,
    displayName: metadata.displayName,
    displayWidth: metadata.displayWidth,
    filterable: metadata.filterable,
    groupable: metadata.groupable,
    hiddenInGrid: metadata.hiddenInGrid,
    includeInStreams: metadata.includeInStreams,
    name: metadata.name,
    parent: metadata.parent,
    scope: metadata.scope,
    source: metadata.source,
    subdimension: metadata.subdimension,
  };

  // Only for owner attributes we want to inlucde the `dataType`.
  const ownerAttribute =
    metadata.name.startsWith('ownerText') || metadata.name.startsWith('ownerDecimal');
  if (ownerAttribute) {
    metadataInput.dataType = toDataTypeInput(metadata);
  }

  return metadataInput;
};

const toDataTypeInput = (metadata: PropertyMetadata): DataTypeInput => {
  switch (metadata.dataType) {
    case 'default':
      return 'DEFAULT';
    case 'date':
      return 'DATE';
    case 'description':
      return 'DESCRIPTION';
    case 'twoLines':
      return 'TWO_LINES';
    case 'boolean':
      return 'BOOLEAN';
    case 'currency':
      return 'CURRENCY';
    case 'currencyLocal':
      return 'CURRENCY_LOCAL';
    case 'integer':
      return 'INTEGER';
    case 'measurementArea':
      return 'MEASUREMENT_AREA';
    case 'measurementDistanceSmall':
      return 'MEASUREMENT_DISTANCE_LARGE';
    case 'measurementDistanceLarge':
      return 'MEASUREMENT_DISTANCE_SMALL';
    case 'number':
      return 'NUMBER';
    case 'percentage':
      return 'PERCENTAGE';
    case 'year':
      return 'YEAR';
  }

  datadogLogs.logger.error('could not convert dataType to DataTypeInput', {
    attributeName: metadata.name,
    dataType: metadata.dataType,
    organizationId: metadata.organizationId,
    streamId: metadata.streamId,
  });

  return 'DEFAULT';
};

export const toGraphqlArray = (metadata: Array<PropertyMetadata>): Array<IGraphqlMetadataInput> =>
  metadata.map((value) => toGraphqlInput(value));

export const getState = (
  values: Array<IGraphlQLOrganizationAttributeMetadata>,
): Array<PropertyMetadata> => {
  const state: Array<PropertyMetadata> = [];

  for (let i = 0; i < values.length; i++) {
    const { metadata, includeInStreams, organizationId, streamId } = values[i];
    state.push({
      ...metadata,
      filterable: metadata.filterable,
      groupable: metadata.groupable && isGroupable(metadata.name),
      hiddenInGrid: metadata.hiddenInGrid,
      includeInStreams,
      organizationId,
      streamId,
    });
  }

  return state;
};

const addMetadata = (fileData: any): Array<PropertyMetadata> => {
  const arr = [];

  const fields = Object.keys(fileData);
  for (let i = 0; i < fields.length; i++) {
    const name = fields[i];
    const {
      includeInStreams,
      displayName,
      source,
      children,
      filterable,
      groupable,
      hiddenInGrid,
      columnPosition,
      dimension,
      subdimension,
      scope,
      parent,
    } = fileData[name];

    const metadata: PropertyMetadata = {
      columnPosition,
      dimension,
      displayName,
      filterable,
      groupable,
      hiddenInGrid,
      includeInStreams,
      name,
      parent,
      scope,
      subdimension,
    };
    if (source) {
      metadata.source = source;
    }
    arr.push(metadata);

    if (children) {
      arr.push(...addMetadata(children));
    }
  }

  return arr;
};

export const getMetadataArray = (file: string): Array<PropertyMetadata> => {
  const json = JSON.parse(file);
  return addMetadata(json);
};
