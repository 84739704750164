/** @jsx jsx */
/** @jsxFrag jsx */
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { jsx } from '@emotion/react';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { ReportContent, ReportContentProps } from '@app/components/ReportContainer';
import { useTracker } from '@app/hooks/useTracker';
import { useReportsContext } from '../../contexts/reportsContext/reportsContext';
import {
  GET_CARRIER_ASSESSMENT,
  GetCarrierAssessmentData,
  GetCarrierAssessmentVariables,
} from '../../queries/getCarrierAssessment';
import { useUnderwritingAssessmentReportContext } from '../underwritingAssessmentReportContext';
import GradingDetails from './tabs/GradingDetails/GradingDetails';
import GradingOverviewTab from './tabs/GradingOverviewTab/GradingOverviewTab';
import LossHistoryTab from './tabs/LossHistory/LossHistory';

// RE Risk Grade and Predictive Risk Grade show unknowns (by design), we want to exclude
// those from calculations, we are handling the logic to include them or excluding them
// in the frontend, so we are saving a reference for these here in the main component.
const gradedMetrics = ['RE_RISK_GRADE', 'PREDICTIVE_RISK_GRADE'];

// Property Grade Metrics we will explicitly ask the API data for
const propertyGradeMetricCategories = ['High', 'Good', 'Medium', 'Fair', 'Low', 'Very Low'];

// Copy the same props as `ReportContentProps` except `content` and `tabs`
interface InsurerInsightsProps extends Omit<ReportContentProps, 'content' | 'tabs'> {}

function InsurerInsights({ title, description, logo }: InsurerInsightsProps) {
  const { setInsurerInsightsTab } = useUnderwritingAssessmentReportContext();
  const handleTabSelection = (id: string) => setInsurerInsightsTab(id);

  const mixpanel = useTracker('mixpanel');
  const { snapshots, stream } = useReportsContext();
  const { currentSnapshot } = snapshots;
  const { streamId, streamName, streamSlug, ...streamRest } = stream;

  useEffect(() => {
    mixpanel.track('Open Report', {
      report_name: 'Insurer Insights Report',
      snapshot: currentSnapshot?.snapshotName,
      stream_id: streamId,
      stream_name: streamName,
      stream_slug: streamSlug,
      ...streamRest,
      ...snapshots,
    });
  }, []);

  const { data, loading } = useQuery<GetCarrierAssessmentData, GetCarrierAssessmentVariables>(
    GET_CARRIER_ASSESSMENT,
    {
      skip: !stream?.streamId,
      variables: {
        input: {
          name: 'ZurichUwAR',
          streamId: stream?.streamId,
        },
      },
    },
  );

  if (loading) {
    return <LoadingSpinnerV2 styles={{ height: '914px' }} />;
  }

  const tabs = [
    {
      content: <GradingOverviewTab />,
      'data-testid': 'insurer-grading-overview-tab',
      id: 'grading-overview',
      label: 'Grading Overview',
      onClick: () => handleTabSelection('grading-overview'),
    },
    {
      content: <GradingDetails carrierAssessment={data?.carrierAssessment} />,
      'data-testid': 'insurer-grading-details-tab',
      id: 'grading-details',
      label: 'Grading Details',
      onClick: () => handleTabSelection('grading-details'),
    },
    {
      content: <LossHistoryTab carrierAssessment={data?.carrierAssessment} />,
      'data-testid': 'insurer-loss-history-details-tab',
      id: 'loss-history-details',
      label: 'Loss History Details',
      onClick: () => handleTabSelection('loss-history-details'),
    },
  ];

  return <ReportContent title={title} logo={logo} description={description} tabs={tabs} />;
}

export { gradedMetrics, InsurerInsights, propertyGradeMetricCategories };
