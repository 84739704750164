import moment, { Moment } from 'moment';

export interface IForm {
  name: string;
  broker: string;
  reit: string;
  status: string;
  effectiveDate: Moment;
  expiryDate: Moment;
  isPublic: boolean;
  isMyProperties: boolean;
  stockThroughputEnabled: boolean;
}

const dateFields = ['effectiveDate', 'expiryDate'];
const checkFields = ['isPublic', 'isMyProperties', 'stockThroughputEnabled'];

export const getDefaultValues = (): IForm => ({
  broker: '',
  effectiveDate: moment().utc(),
  expiryDate: moment().utc(),
  isMyProperties: false,
  isPublic: false,
  name: '',
  reit: '',
  status: '',
  stockThroughputEnabled: false,
});

export const isValid = (formValues: IForm) => {
  const nameIsValid = !!formValues.name || !!formValues.isMyProperties;
  return !!(nameIsValid && formValues.broker && formValues.reit && formValues.status);
};

export const nameToValue = (field: string, e: any) => {
  if (dateFields.includes(field)) {return e;}
  if (checkFields.includes(field)) {return e.target.checked;}
  return e.target.value;
};
