import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { SnapshotOption } from '@app/cx/Reports/contexts/reportsContext/types';
import {
  ExposureSnapshot,
  GET_EXPOSURE_SNAPSHOTS,
  GetExposureSnapshotsData,
  GetExposureSnapshotsVariables,
} from '../queries/getExposureSnapshots';

interface Return {
  loading: boolean;
  snapshots: ExposureSnapshot[];
  snapshotOptions: SnapshotOption[];
  currentSnapshot: string;
  currentSnapshotName: string;
  setCurrentSnapshot: (value: string) => void;
}

const useGetExposureSnapshots = (streamSlug: string): Return => {
  const [currentSnapshot, setCurrentExposureSnapshot] = useState<string>();
  const [currentSnapshotName, setCurrentExposureSnapshotName] = useState<string>();
  const [snapshots, setSnapshots] = useState<ExposureSnapshot[]>([]);
  const [snapshotOptions, setSnapshotOptions] = useState<SnapshotOption[]>([]);

  // Once the GET_SCORED_SNAPSHOTS query is completed, get the snapshot options.
  const handleCompletedData = (data: GetExposureSnapshotsData) => {
    const finalSnapshots = data?.exposureAvailableSnapshots
      ?.map((snapshot) => {
        const { date, snapshotName } = snapshot;
        return {
          date,
          snapshotDate: moment(date).format('YYYY-MM-DD'),
          snapshotName,
        };
      })
      .sort(
        (a: ExposureSnapshot, b: ExposureSnapshot) =>
          new Date(b.snapshotDate).valueOf() - new Date(a.snapshotDate).valueOf(),
      );

    const snapshotOptionsFinal = finalSnapshots?.map((snapshot) => ({
      label: snapshot.snapshotDate,
      value: snapshot.snapshotName,
    }));

    // Use the first snapshot in the list when populating the dropdown
    if (finalSnapshots?.length && !currentSnapshot) {
      setCurrentSnapshot(snapshotOptionsFinal[0]?.value);
    }

    setSnapshotOptions(snapshotOptionsFinal || []);
    setSnapshots(finalSnapshots || []);
  };

  const { loading } = useQuery<GetExposureSnapshotsData, GetExposureSnapshotsVariables>(
    GET_EXPOSURE_SNAPSHOTS,
    {
      onCompleted: handleCompletedData,
      skip: !streamSlug,
      variables: {
        input: {
          streamSlug,
        },
      },
    },
  );
  useEffect(() => {
    const currentSnap = snapshots?.find((s) => s?.snapshotName === currentSnapshot);
    setCurrentExposureSnapshotName(currentSnap?.snapshotName);
  }, [currentSnapshot]);

  const setCurrentSnapshot = (value: string) => setCurrentExposureSnapshot(value);

  return {
    currentSnapshot,
    currentSnapshotName,
    loading,
    setCurrentSnapshot,
    snapshotOptions,
    snapshots,
  };
};

export default useGetExposureSnapshots;
