const getVal = (val: number, min: number, max: number) => {
  if (val > max) {
    console.warn(`Value ${val} is greater than max ${max} value`);
    return max;
  }
  if (val < min) {
    console.warn(`Value ${val} is less than than min ${min} value`);
    return min;
  }
  return val;
};

const squash = (val: number, [oMin, oMax]: [number, number], [min, max]: [number, number]) => {
  const clampedVal = getVal(val, oMin, oMax);
  const divider = oMax - oMin;
  if (divider === 0) {
    return 1;
  }
  const decimal = (clampedVal - oMin) / divider;
  return decimal * (max - min) + min;
};

export default squash;
