import React from 'react';
import { Button, CallOut, EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';

export const DuplicateClientIdCallout = ({ message, onClick }) => (
    <CallOut title="Duplicate Property" type="danger">
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem>
          <EuiText size="s">{message}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div>
            <Button color="danger" label="Confirm Client ID" onClick={onClick} fill />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </CallOut>
  );
