import { useState } from 'react';
import { DocumentsContext } from '../../types/contextTypes';

export default (): DocumentsContext['downloads'] => {
  const [all, setAll] = useState<boolean>(false);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleReset = () => {
    setAll(false);
    setDocumentIds([]);
  };

  const setDocumentIdsFinal = (documentId: string) => {
    // If the `documentId` is already in the `documentsId` array, then remove it
    if (documentIds.includes(documentId)) {
      setDocumentIds((prevState) => {
        const finalState = [...prevState];
        finalState.splice(finalState.indexOf(documentId), 1);

        return finalState;
      });
    } else {
      // Else, add the `documentId` to the `documentsId` array
      setDocumentIds((prevState) => [...prevState].concat(documentId));
    }
  };

  return {
    documentIds: {
      all,
      reset: handleReset,
      setAll,
      setState: setDocumentIdsFinal,
      state: documentIds,
    },
    downloading: {
      setState: setIsDownloading,
      state: isDownloading,
    },
  };
};
