import React from 'react';
import { EuiBasicTableColumn, EuiInMemoryTable } from 'ui';
import { SummaryItem } from './tableData';

interface Props {
  columns: Array<EuiBasicTableColumn<SummaryItem>>;
  summary: SummaryItem;
}

const Summary: React.FC<Props> = ({ columns, summary }) => (
  <EuiInMemoryTable columns={columns} items={[summary]} />
);

export default Summary;
