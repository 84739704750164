let idToken = '';
let expiration = 0;

export const setIdToken = (token: string) => {
  idToken = token;
};

export const getIdToken = () => idToken;

export const setExpiration = (value: number) => {
  expiration = value;
};

export const getExpiration = () => expiration;

export const isExpired = (): boolean => expiration < 0 || expiration - new Date().getTime() < 0;
