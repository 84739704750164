import { css } from '@emotion/css';

const table = css`
  .euiTableHeaderCell {
    white-space: inherit !important;
  }
  .euiTableRow-isExpandedRow {
    .euiTableCellContent:first-child {
      padding: 2px 8px;
    }
  }
`;

export default {
  table,
};
