import { RuleGroupType, RuleType } from 'react-querybuilder';
import { AttributeFilter } from '@app/graphql/types';
import { valueOfBinaryOperator } from '@app/graphql/utils';
import { FilterQuery } from './types';

export const convertRuleGroupToFilters = (ruleGroup: FilterQuery | undefined) => {
  const tempFilters: Array<AttributeFilter> = [];
  if (ruleGroup?.rules && ruleGroup.rules.length > 0) {
    ruleGroup.rules.forEach((rule) => {
      if ((rule as RuleGroupType).rules) {
        console.warn('shouldnt be here: rulegroup ');
      } else {
        const r = rule as RuleType;
        if (typeof r.value === 'string') {
          // If value is a string, wrap it in an array
          r.value = [`${r.value}`];
        }
        const filter: AttributeFilter = {
          name: r.field,
          operator: valueOfBinaryOperator(r.operator),
          values: r.value,
        };
        tempFilters.push(filter);
      }
    });
  }
  return tempFilters;
};
