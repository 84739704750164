import { useEffect, useRef, useState } from 'react';
import { keyBy } from 'lodash';
import { ROCKET_CHAT_DOMAIN } from '@app/config';
import { useChatContext } from './ChatProvider';

const ROCKET_CHAT_MY_MESSAGES_KEY = '__my_messages__';
export interface Subscription {
  rid: string;
  name: string;
  prid?: string;
  unread: number;
  userMentions: number;
}

export interface SubscriptionMap {
  [rid: string]: Subscription;
}

export interface Message {
  messageID: string;
  text: string;
  roomID: string;
  customFields: any;
  author: {
    username: string;
    userID: string;
  };
}
interface Props {
  onNewMessage?: (msg: Message) => void;
}
interface Return {
  roomSubscriptionsMap: SubscriptionMap;
  sendMessage: (roomID: string, msg: string) => void;
  instructorDMRoomID: string;
}

export const useSession = ({ onNewMessage }: Props): Return => {
  const ws = useRef<WebSocket>(null);
  const [instructorDMRoomID, setInstructorDMRoomID] = useState<string>();

  const { user } = useChatContext();
  const chatToken = user?.token;
  const chatUserID = user?.userID;
  const [subscriptions, setSubscriptions] = useState<{
    [rid: string]: Subscription;
  }>({});

  const sendJSON = (payload: any) => {
    ws?.current?.send(JSON.stringify(payload));
  };

  const updateInstructorDMRoomID = (res) => {
    setInstructorDMRoomID(res?.rid);
  };

  const login = () => {
    sendJSON({
      id: 'event-login',
      method: 'login',
      msg: 'method',
      params: [{ resume: chatToken }],
    });
  };

  const handleResult = (payload: any) => {
    switch (payload.id) {
      case 'event-login':
        sendJSON({
          id: 'sub-notify-user',
          msg: 'sub',
          name: 'stream-notify-user',
          params: [`${chatUserID}/subscriptions-changed`, false],
        });

        sendJSON({
          id: 'sub-notify-message',
          msg: 'sub',
          name: 'stream-notify-user',
          params: [`${chatUserID}/message`, false],
        });

        sendJSON({
          id: 'sub-room-messages',
          msg: 'sub',
          name: 'stream-room-messages',
          params: [ROCKET_CHAT_MY_MESSAGES_KEY, false],
        });

        sendJSON({
          id: 'method-get-subscriptions',
          method: 'subscriptions/get',
          msg: 'method',
          params: [],
          // params: [{ $date: '1709772576' }],
        });

        sendJSON({
          id: 'method-create-direct-message',
          method: 'createDirectMessage',
          msg: 'method',
          params: ['instructor'],
        });
        break;

      case 'method-get-subscriptions':
        setSubscriptions(keyBy(payload.result, 'rid'));
        break;
      case 'method-create-direct-message':
        updateInstructorDMRoomID(payload.result);
        break;
    }
  };

  const handleChange = (payload: any) => {
    if (
      payload?.collection !== 'stream-notify-user' &&
      payload.collection !== 'stream-room-messages'
    ) {
      return;
    }

    if (payload.collection === 'stream-room-messages') {
      const {
        fields: {
          args: [
            {
              _id,
              rid,
              msg,
              u: { username, _id: authorId },
              customFields,
            },
          ],
        },
      } = payload;
      if (!!msg && authorId !== chatUserID && onNewMessage) {
        onNewMessage({
          author: { userID: authorId, username },
          customFields,
          messageID: _id,
          roomID: rid,
          text: msg,
        });
      }
      return;
    }

    const {
      fields: { args },
    } = payload;
    if (args?.length < 2) {
      console.error('not enough arguments in subscription response', payload);
      return;
    }
    const [, { rid, prid, name, unread, userMentions }] = args;
    setSubscriptions((sub) => ({
      ...sub,
      [rid]: { ...(subscriptions?.[rid] || {}), name, prid, rid, unread, userMentions },
    }));
  };
  const handleMessage = (payload: any) => {
    if (!payload.msg) {
      return;
    }

    switch (payload.msg) {
      case 'ping':
        sendJSON({ msg: 'pong' });
        break;
      case 'connected':
        login();
        break;

      case 'result':
        handleResult(payload);
        break;

      case 'changed':
        handleChange(payload);
        break;

      default:
        break;
    }
  };
  const openSocket = () => {
    ws.current = new WebSocket(`wss://${ROCKET_CHAT_DOMAIN}/websocket`);

    ws.current.onopen = () => {
      sendJSON({
        msg: 'connect',
        support: ['1'],
        version: '1',
      });
    };

    ws.current.onmessage = (event) => {
      handleMessage(JSON.parse(event.data));
    };
  };

  const closeSocket = () => {
    ws?.current?.close();
  };
  useEffect(() => {
    if (!!user) {
      openSocket();

      return () => {
        closeSocket();
      };
    }
  }, [!!user]);

  const sendMessage = (roomID: string, msg: string) => {
    sendJSON({
      id: '423',
      method: 'sendMessage',
      msg: 'method',
      params: [
        {
          msg,
          rid: roomID,
        },
      ],
    });
  };
  return {
    instructorDMRoomID,
    roomSubscriptionsMap: subscriptions,
    sendMessage,
  };
};
