import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const WITH_COMMENT_CLASS = 'with-comment';

export const Panel = styled(EuiPanel)`
  min-width: 0;
  padding: 0;
  border: none;
  box-shadow: none;

  .${WITH_COMMENT_CLASS}:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: -100%;
    right: -45px;
    transform: rotate(45deg);
    background-color: #fec514;
    overflow: hidden;
  }
`;
