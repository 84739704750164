import styled from '@emotion/styled';
import { EuiFlexItem, EuiStat } from 'ui';

export const ValueContainer = styled(EuiFlexItem)`
  margin: 2px 0;
`;

export const FooterContainer = styled(EuiFlexItem)`
  margin-top: 0 !important;
`;

export const SmallText = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;

export const MediumText = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
`;

export const LargeText = styled.p`
  font-size: 64px;
  font-weight: 600;
  line-height: 72px;
`;

export const StatContent = styled(EuiStat)`
  margin-left: 4px;
`;

export const StatsContainer = styled(EuiFlexItem)`
  margin-left: 6px !important;
`;
