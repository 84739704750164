import { RuleGroupType, RuleType } from 'react-querybuilder';
import { classifications } from '@app/constants';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { BinaryOperator, Combinator, Rule as NewRule } from '@app/queries/types';
import { Filter, FilterEnum, Filters, Operator } from './types';

export const equalOperator: Operator = { label: 'exactly matches', name: 'EQUAL' };
export const notEqualOperator: Operator = { label: 'does not match', name: 'NOT_EQUAL' };
export const lessOperator: Operator = { label: 'less', name: 'LESS' };
export const greaterOperator: Operator = { label: 'greater', name: 'GREATER' };
export const lessOrEqualOperator: Operator = { label: 'less or equal', name: 'LESS_OR_EQUAL' };
export const betweenOperator: Operator = { label: 'between', name: 'BETWEEN' };
export const greaterOrEqualOperator: Operator = {
  label: 'greater or equal',
  name: 'GREATER_OR_EQUAL',
};
export const beginsWithOperator: Operator = { label: 'begins with', name: 'BEGINS_WITH' };
export const notBeginsWithOperator: Operator = {
  label: 'not begins with',
  name: 'NOT_BEGINS_WITH',
};
export const containsOperator: Operator = { label: 'contains', name: 'CONTAINS' };
export const notContainsOperator: Operator = { label: 'does not contain', name: 'NOT_CONTAINS' };
export const endsWithOperator: Operator = { label: 'ends with', name: 'ENDS_WITH' };
export const notEndsWithOperator: Operator = { label: 'not ends with', name: 'NOT_ENDS_WITH' };
export const isEmptyOperator: Operator = { label: 'is empty', name: 'IS_EMPTY' };
export const isNotEmptyOperator: Operator = { label: 'is not empty', name: 'IS_NOT_EMPTY' };
export const isOneOfOperator: Operator = { label: 'includes', name: 'IN' };

export const getFilterByAttribute = ({
  name,
  displayName,
  enumMetadata,
  dataType,
}: IGraphQLAttributeMetadata) => ({
  dataType,
  enumMetadata,
  label: displayName,
  name,
});

const numericFields = ['currency', 'currencyLocal', 'number', 'integer', 'percentage', 'year'];
export type PropertyAttributeMetadata = IGraphQLAttributeMetadata | IGraphQLAttributeMetadata;
type GetFilters = (filterable: Array<PropertyAttributeMetadata>) => Filters;
export const getFilters: GetFilters = (filterable) =>
  filterable.reduce((filters, key) => {
    const filter = getFilterByAttribute(key);
    const isNumericType = numericFields.includes(filter.dataType);
    const isDateType = filter.dataType == 'date';

    const defaultOperator = isNumericType ? lessOrEqualOperator : containsOperator;

    const numericOperators = [
      equalOperator,
      notEqualOperator,
      lessOrEqualOperator,
      greaterOrEqualOperator,
      isEmptyOperator,
      isNotEmptyOperator,
      betweenOperator,
    ];

    const textOperators = [
      containsOperator,
      notContainsOperator,
      equalOperator,
      notEqualOperator,
      isEmptyOperator,
      isNotEmptyOperator,
      isOneOfOperator,
    ];

    const dateOperators = [
      lessOrEqualOperator,
      greaterOrEqualOperator,
      betweenOperator,
      isEmptyOperator,
      isNotEmptyOperator,
    ];

    let operators = textOperators;
    if (isNumericType) {
      operators = numericOperators;
    } else if (isDateType) {
      operators = dateOperators;
    }

    if (!filter) {
      return filters;
    }

    return {
      ...filters,
      [filter.name]: {
        defaultOperator,
        filterEnums: filter.enumMetadata,
        label: typeof filter.label === 'string' ? filter.label : filter.name,
        name: filter.name,
        operators,
        type: filter.dataType,
      },
    };
  }, {} as Filters);

type GetSmartfolderFilters = (tagValues: string[] | undefined) => Filters;
export const getSmartfolderFilters: GetSmartfolderFilters = (tagValues) => {
  const filename = 'filename';
  const tags = 'tags';
  const classification = 'classification';
  const filetype = 'filetype'; // TODO : make this a setting somewhere
  const extension = 'extension';
  const archipelagoId = 'archipelagoId';

  return {
    [archipelagoId]: {
      defaultOperator: equalOperator,
      label: 'Archipelago ID',
      name: archipelagoId,
      operators: [
        equalOperator,
        notEqualOperator,
        containsOperator,
        notContainsOperator,
        beginsWithOperator,
        notBeginsWithOperator,
        endsWithOperator,
        notEndsWithOperator,
      ],
      values: undefined,
    },
    [classification]: {
      defaultOperator: equalOperator,
      label: 'Classification',
      name: 'classification',
      operators: [equalOperator, notEqualOperator],
      values: classifications,
    },
    [extension]: {
      defaultOperator: equalOperator,
      label: 'Extension',
      name: 'extension',
      operators: [equalOperator, notEqualOperator],
      values: undefined,
    },
    [filename]: {
      defaultOperator: containsOperator,
      label: 'Filename',
      name: 'filename',
      operators: [
        equalOperator,
        notEqualOperator,
        containsOperator,
        notContainsOperator,
        beginsWithOperator,
        notBeginsWithOperator,
        endsWithOperator,
        notEndsWithOperator,
        isEmptyOperator,
        isNotEmptyOperator,
      ],
      values: undefined,
    },
    [filetype]: {
      defaultOperator: equalOperator,
      label: 'File Type',
      name: 'filetype',
      operators: [equalOperator, notEqualOperator],
      values: ['Document', 'Image'],
    },
    [tags]: {
      defaultOperator: equalOperator,
      label: 'Tags',
      name: 'tags',
      operators: [equalOperator],
      values: tagValues,
    },
  };
};

type RuleGroupMapping = (rule: RuleGroupType | undefined) => Array<NewRule>;
// ruleGroupMapping maps the RuleGroupType
// object to an array of Rules that can be accepted by our API
export const ruleGroupMapping: RuleGroupMapping = (rule) => {
  const rules: Array<NewRule> = [];
  if (rule) {
    for (let i = 0; i < rule.rules.length; i++) {
      const combinator = Combinator[rule.combinator.toUpperCase()];
      const r = rule.rules[i];
      if ((r as RuleType).field) {
        const queryRule: RuleType = r as RuleType;
        rules.push({
          attributeName: queryRule.field,
          combinator,
          operator: BinaryOperator[queryRule.operator.toUpperCase()],
          values: [queryRule.value],
        });
      }
    }
  }
  return rules;
};

// Redundant once back-end fix is in place
export const handleFilterEnum = (filter: Filter, metadataMap: {}) => {
  const editedFilterEnums: FilterEnum[] | undefined =
    metadataMap[filter.name]?.dataType === 'boolean'
      ? [
          {
            clusterValue: '',
            hexColorCode: '',
            label: 'Yes',
            position: 1,
            value: 'true',
            valueId: '1',
          },
          {
            clusterValue: '',
            hexColorCode: '',
            label: 'No',
            position: 2,
            value: 'false',
            valueId: '2',
          },
        ]
      : filter.filterEnums;

  return editedFilterEnums;
};

export const setZeroIndexOperator = (
  field: string | undefined,
  filters: Filters,
  operators: Operator[],
) => {
  const operator =
    filters[field || '']?.type === 'boolean'
      ? equalOperator
      : filters[field || '']?.defaultOperator;

  operators.sort((first, other) => {
    if (first.name === operator.name) {
      return -1;
    }
    if (other.name === operator.name) {
      return 1;
    }
    return 0;
  });
  return operators;
};
