"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconNameList = exports.colorList = void 0;
exports.colorList = {
    danger: 'danger',
    info: 'primary',
    reminder: 'primary',
    success: 'primary',
    warning: 'warning',
};
exports.iconNameList = {
    danger: 'alertTriangle',
    info: 'info',
    reminder: 'clock',
    success: 'check',
    warning: 'alertTriangle',
};
