import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const Container = styled(EuiPanel)`
  height: 100%;

  > .euiFlexGroup,
  .euiForm,
  .euiForm > .euiFlexGroup {
    height: 100%;
  }
`;
