import { IAttributeProvenance, IPropertyDocument } from '@app/queries/properties/types';
import { IProps as IEvidenceProps } from '../GridCell/SourceLink/SourceLink';

export interface IProvenanceForTooltip {
  sources: IAttributeProvenance['sources'] | string;
  sourceDocuments: IEvidenceProps['sourceDocuments'];
  externalSourceURLs: IEvidenceProps['externalSourceURLs'];
}

export enum ProvenanceType {
  Attribute = 'attribute_provenance',
  Column = 'column_provenance',
}
export type ProvenanceDocument = Pick<IPropertyDocument, 'filename' | 'httpURL'>;
