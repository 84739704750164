import { API_BASE_URL } from '@app/config';

export interface IndustryBusinessOptionValue {
  value: string;
  list?: string[];
}

export const getIndustryEnumData = async (): Promise<IndustryBusinessOptionValue[]> =>
  new Promise<IndustryBusinessOptionValue[]>((resolve, reject) => {
    fetch(`${API_BASE_URL}/org-enum-values`, {
      method: 'GET',
    }).then((response) => {
      response
        .json()
        .then((result) => {
          resolve(result.data.valueList);
        })
        .catch((error) => reject(error));
    });
  });
