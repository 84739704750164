import React, { memo } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import HighMaps from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import { colors } from 'ui';
import { IGraphQLChart, IGraphQLTIVChart } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import usAll from './usAll.json';
import { buildTIVMapData, chartDataIsEqual } from './utils';

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

HighMaps.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const getOptions = (queryData = [], formatCurrency?: FormatCurrency, title?: string): any => ({
  chart: {
    animation: false,
    height: '70%',
    map: usAll,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: colors.dataViz.archipelagoGreen4,
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: false,
  },
  mapNavigation: {
    enabled: false,
  },
  plotOptions: {
    map: {
      margin: 15,
      states: {
        hover: {
          color: '#EEDD66',
        },
      },
    },
  },
  series: [
    {
      data: queryData,
      dataLabels: {
        enabled: false,
        format: '{point.name}',
      },
      name: 'USA',
    },
  ],
  subtitle: {
    text: null,
  },
  title: {
    text: title ? title : null,
  },
  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${this.point.name}<br><b>${formatCurrency?.(this.point.value)}</b>`;
    },
    valuePrefix: '$',
  },
  yAxis: {
    title: {
      enabled: false,
    },
  },
});

const TivByUsState: React.FC<IProps> = ({ explorer, title }) => {
  const { formatStreamCurrency } = useStreamContext();
  const queryData: any = buildTIVMapData(explorer as IGraphQLTIVChart);
  const options = getOptions(queryData, formatStreamCurrency, title);

  return (
    <Explorer>
      <HighchartsReact highcharts={HighMaps} constructorType="mapChart" options={options} />
    </Explorer>
  );
};

export default memo(TivByUsState, chartDataIsEqual);
