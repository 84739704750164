import { css } from '@emotion/css';

const iconContainer = css`
  > svg {
    margin: 0 auto;
    transform: rotate(90deg);
  }
`;

export default {
  iconContainer,
};
