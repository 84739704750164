import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import { useValuationSummaryReportContext } from '../ValuationSummaryReportContext';
import {
  ConstructionFilterStyles,
  FilterLabelStyle,
  OccupancyFilterStyles,
  StateFilterStyles,
} from './Filters.emotion';

export const Filters = () => {
  const {
    summaryData,
    occupancyFilter,
    setOccupancyFilter,
    constructionFilter,
    setConstructionFilter,
    stateFilter,
    setStateFilter,
    filterOptions,
    loading,
  } = useValuationSummaryReportContext();

  return (
    <EuiFlexGroup direction="column">
      {summaryData?.snapshotDisplayDate && (
        <EuiFlexItem>
          <EuiText>{`Valuation integration last ran on ${summaryData?.snapshotDisplayDate}`}</EuiText>
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <EuiFlexGroup alignItems="center" justifyContent="flexStart" gutterSize="none">
          <EuiText style={FilterLabelStyle}>View report for</EuiText>
          <EuiFlexItem style={OccupancyFilterStyles}>
            <Select
              options={filterOptions?.occupancyOptions}
              value={occupancyFilter}
              onChange={(value) => setOccupancyFilter(value)}
              loading={loading}
            />
          </EuiFlexItem>
          <EuiFlexItem style={ConstructionFilterStyles}>
            <Select
              options={filterOptions?.constructionOptions}
              value={constructionFilter}
              onChange={(value) => setConstructionFilter(value)}
              loading={loading}
            />
          </EuiFlexItem>
          <EuiFlexItem style={StateFilterStyles}>
            <Select
              options={filterOptions?.stateOptions}
              value={stateFilter}
              onChange={(value) => setStateFilter(value)}
              loading={loading}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
