import { Column } from '@app/components/Grid/types';
import { IRow as IGroupedListRow } from '@app/components/GroupedList/types';
import { IColumn } from '@app/components/GroupedRow/types';
import { IAttributeProvenance, IGraphQLProperty } from '@app/queries/properties/types';

export const VALID_GROUPINGS: Array<string> = [
  'constructionType',
  'acquiredOrBuilt',
  'propertyManager',
  'region',
  'country',
  'state',
  'seismicZone',
  'windZone',
  'floodZone',
  'occupancyType',
  'primaryTenantNAICS',
];
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer SomeType>
  ? SomeType
  : never;
export type ValidGrouping = ElementType<typeof VALID_GROUPINGS>;
export type Groups = Array<ValidGrouping>;
export interface IPropertyGroupRow<T> {
  props: T;
  rows?: Array<IPropertyGroupRow<T>>;
}

export interface Disclosures {
  [index: string]: Disclosures | boolean;
}

export type Indices = Array<number>;

export type IRowType = 'group' | 'properties';

interface IGenericProps {
  type: IRowType;
}

export interface IGroupedProps extends IGenericProps {
  id: string;
  label: string;
  explorers: Array<IColumn>;
  totalPropertyCount: null | number;
  filteredPropertyCount: null | number;
  provenance?: IAttributeProvenance;
}

export interface IPropertiesProps extends IGenericProps {
  group?: IGroupedProps;
  properties: undefined | Array<IGraphQLProperty>;
}

export type IProps = IGroupedProps | IPropertiesProps;

export type IRow = IGroupedListRow<IProps>;

export type HandleSort = (indices: Array<number>, column: Column) => void;

export interface ISize {
  height: number;
  width: number;
}

export interface IChildrenProps {
  visible: boolean;
  indices: Array<number>;
  group?: IGroupedProps;
}

export type Children = (props: IChildrenProps) => JSX.Element;
