export type ValidateFunction = (v: any) => string;

export const validateRequired = (value: any) => (value ? '' : 'Required field');
export const validateDate = (value: string) => {
  const date = new Date(value);

  return Number.isNaN(date.getTime()) ? 'Invalid date' : '';
};
export const validateJSON = (value: string) => {
  if (!value) {return '';}
  try {
    JSON.parse(value);
  } catch (e) {
    return 'Invalid JSON';
  }
  return '';
};

export const validateArray = (values: Array<string>) =>
  values?.length > 0 ? '' : 'Required field';
const isEmailValid = (value: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const validateEmail = (value: string): string => {
  if (value && !isEmailValid(value)) {
    return 'Email must be valid';
  }
};

export const validateEmails =
  (errorMessage = 'Emails must be valid and separated by a comma') =>
  (values: Array<string>): string => {
    for (let i = 0; i < values?.length || 0; i++) {
      if (values[i] && !isEmailValid(values[i])) {
        return errorMessage;
      }
    }

    return '';
  };
