import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ErrorState from '@onarchipelago/cx/Reports/components/ErrorState';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  Attribute,
  EnrichmentPropertyImprovementData,
  EnrichmentPropertyImprovementVariables,
  GET_ENRICHMENT_PROPERTY_IMPROVEMENT,
} from '@onarchipelago/cx/Reports/queries/getEnrichmentPropertyImprovement';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import MostImprovedTable from './MostImprovedTable';

type Options = {
  label: string;
  value: string;
}[];

const MostImproved: FC = () => {
  const [propertyData, setPropertyData] = useState<Attribute[]>();
  const [propertyList, setPropertyList] = useState<Options>([]);
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const { snapshots, stream } = useReportsContext();
  const { afterSnapshot, currentSnapshot } = snapshots;

  const { data, loading, error } = useQuery<
    EnrichmentPropertyImprovementData,
    EnrichmentPropertyImprovementVariables
  >(GET_ENRICHMENT_PROPERTY_IMPROVEMENT, {
    variables: {
      input: {
        snapshotNameAfter: afterSnapshot?.snapshotName || '',
        snapshotNameBefore: currentSnapshot?.snapshotName || '',
        streamSlug: stream.streamSlug,
      },
    },
  });

  useEffect(() => {
    if (data?.enrichmentPropertyImprovement.length) {
      const finalPropertyList = data?.enrichmentPropertyImprovement.map((property) => ({
        label: property.locationName,
        value: property.locationName,
      }));

      setPropertyData(data?.enrichmentPropertyImprovement[0].attributes);
      setPropertyList(finalPropertyList);
      setSelectedProperty(data?.enrichmentPropertyImprovement[0].locationName);
    }
  }, [data]);

  useEffect(() => {
    if (data?.enrichmentPropertyImprovement && selectedProperty) {
      const finalPropertyData = data?.enrichmentPropertyImprovement.filter(
        (property) => property.locationName === selectedProperty,
      )[0].attributes;

      setPropertyData(finalPropertyData);
    }
  }, [data, selectedProperty]);

  if (loading && !error) {
    return <LoadingSpinnerV2 styles={{ height: '943px' }} />;
  }

  if (!loading && error) {
    return <ErrorState />;
  }

  if (data && propertyData && !loading && !error) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiText data-testid="most-improved-title">
            <h3>Most Improved Properties</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Select
            data-testid="most-improved-select"
            onChange={(value) => setSelectedProperty(value)}
            options={propertyList}
            value={selectedProperty}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <MostImprovedTable tableData={propertyData} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return null;
};

export default MostImproved;
