import React, { ComponentClass, FC } from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';

const PageModal = loadable(() => import('@app/components/PageModal/PageModal'));

interface Props {
  path: string;
  parentPath: string;
  component: ComponentClass | any;
}

const ProtectedModal: FC<Props> = ({ path, component: Component, parentPath }) => (
  <Route
    path={path}
    render={(props) => (
      <PageModal parentPathDefinition={parentPath}>
        {({ onClose }) => <Component {...props} onClose={onClose} />}
      </PageModal>
    )}
  />
);

export default ProtectedModal;
