import styled from '@emotion/styled';
import { EuiFlexItem, EuiPanel } from 'ui';

export const CopilotContainer = styled(EuiPanel)`
  height: 100%;

  > .euiFlexGroup {
    height: 100%;
  }

  .tabsContainer {
    overflow-y: hidden;

    > .euiPanel {
      height: 100%;

      > .euiFlexGroup {
        height: 100%;
      }
    }
  }
`;

export const QueryContainer = styled(EuiFlexItem)`
  .euiFormRow,
  .euiFormRow__fieldWrapper,
  .euiFormControlLayout--euiTextArea,
  .euiFormControlLayout__childrenWrapper,
  .euiTextArea {
    height: 100%;
  }
`;

export const WorkspaceContainer = styled(EuiFlexItem)`
  overflow: hidden;

  > .euiFlexGroup {
    height: 100%;
  }
`;
