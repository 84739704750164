import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOrgPreCheckConfigMutationVariables = Types.Exact<{
  input: Types.SetOrgPreCheckConfigInput;
}>;


export type SetOrgPreCheckConfigMutation = { __typename?: 'Mutation', setOrgPreCheckConfig: { __typename?: 'OrgPreCheckConfig', orgID: string, orgName: string, enablePreCheck: boolean, brandDomain?: string | null } };


export const SetOrgPreCheckConfigDocument = gql`
    mutation SetOrgPreCheckConfig($input: SetOrgPreCheckConfigInput!) {
  setOrgPreCheckConfig(input: $input) {
    orgID
    orgName
    enablePreCheck
    brandDomain
  }
}
    `;
export type SetOrgPreCheckConfigMutationFn = Apollo.MutationFunction<SetOrgPreCheckConfigMutation, SetOrgPreCheckConfigMutationVariables>;

/**
 * __useSetOrgPreCheckConfigMutation__
 *
 * To run a mutation, you first call `useSetOrgPreCheckConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgPreCheckConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgPreCheckConfigMutation, { data, loading, error }] = useSetOrgPreCheckConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrgPreCheckConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetOrgPreCheckConfigMutation, SetOrgPreCheckConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrgPreCheckConfigMutation, SetOrgPreCheckConfigMutationVariables>(SetOrgPreCheckConfigDocument, options);
      }
export type SetOrgPreCheckConfigMutationHookResult = ReturnType<typeof useSetOrgPreCheckConfigMutation>;
export type SetOrgPreCheckConfigMutationResult = Apollo.MutationResult<SetOrgPreCheckConfigMutation>;
export type SetOrgPreCheckConfigMutationOptions = Apollo.BaseMutationOptions<SetOrgPreCheckConfigMutation, SetOrgPreCheckConfigMutationVariables>;