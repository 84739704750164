"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartColors = exports.colors = void 0;
var dataViz_1 = require("./dataViz");
var eui_1 = require("./eui");
var documents_1 = require("./documents");
exports.colors = {
    dataViz: dataViz_1.dataViz,
    eui: eui_1.eui,
    documents: documents_1.documents,
};
var dataViz_2 = require("./dataViz");
Object.defineProperty(exports, "chartColors", { enumerable: true, get: function () { return dataViz_2.chartColors; } });
