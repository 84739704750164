import React, { createContext, ReactElement, useContext, useState } from 'react';
import { IGraphQLStream } from '@app/queries/streams/types';
import {
  AttributeMetadataEditorContext as IAttributeMetadataEditorContext,
  SelectedAttribute,
} from '../types';

// ----- CONTEXT INITIALIZATION ----- //

export const initialAttributeMetadataEditorState: IAttributeMetadataEditorContext = {
  checkboxes: {},
  orgName: undefined,
  organizationId: '',
  selectedAttribute: undefined,
  setCheckboxes: () => {},
  setSelectedAttribute: () => {},
  streamId: '',
};

export const AttributeMetadataEditorContext = createContext<IAttributeMetadataEditorContext>(
  initialAttributeMetadataEditorState,
);

// ----- PROVIDER ----- //

interface Props {
  organizationId: string;
  orgName?: string;
  streamId?: string;
  stream: IGraphQLStream;
}

export const AttributeMetadataEditorContextProvider = (props): ReactElement<Props> => {
  const { organizationId, orgName, streamId, stream } = props;
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({});
  const [selectedAttribute, setSelectedAttribute] = useState<SelectedAttribute>();

  return (
    <AttributeMetadataEditorContext.Provider
      value={{
        checkboxes,
        orgName,
        organizationId,
        selectedAttribute,
        setCheckboxes,
        setSelectedAttribute,
        stream,
        streamId,
      }}
      {...props}
    />
  );
};

// ----- USE CONTEXT HOOK ----- //

export const useAttributeMetadataEditorContext = (): IAttributeMetadataEditorContext =>
  useContext(AttributeMetadataEditorContext);
