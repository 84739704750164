import React, { FC, ReactElement } from 'react';
import { AttributeEditInfo } from '@app/graphql/types';
import { IAttributeProvenance } from '@app/queries/properties/types';
import PropertiesGridPopover from '../PropertiesGridPopover/PropertiesGridPopover';
import PropertiesGridTooltipBody from './PropertiesGridTooltipBody';
import { ProvenanceDocument } from './types';

interface IProps {
  dataTestId?: string;
  provenance: IAttributeProvenance;
  editInfo?: Array<AttributeEditInfo>;
  children: ReactElement;
  handleOpen?: () => void;
  handleDocumentDownload: (doc: ProvenanceDocument) => void;
  description?: ReactElement | string | undefined;
  title?: string;
}

const PropertiesGridTooltip: FC<IProps> = ({
  dataTestId,
  provenance,
  editInfo,
  children,
  handleOpen,
  handleDocumentDownload,
  description,
  title,
}) => (
  <PropertiesGridPopover
    dataTestId={dataTestId}
    content={
      <PropertiesGridTooltipBody
        provenance={provenance}
        editInfo={editInfo}
        handleOpen={handleOpen}
        handleDocumentDownload={handleDocumentDownload}
        description={description}
        title={title}
      />
    }
  >
    {children}
  </PropertiesGridPopover>
);

export default PropertiesGridTooltip;
