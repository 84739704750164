import React from 'react';
import { formatDefault, formatMaxThreshold } from '../../../utils/format';
import { getEdits, getProvenance, ICell } from './Cell';
import Text from './Text';

const Default = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  if (id === 'geoCodeType') {return Text(props);}
  const options = props?.column?.formattingOptions;
  const formatted = options?.maxThreshold
    ? formatMaxThreshold(props.value, options?.maxThreshold)
    : formatDefault(props.value, '-');

  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <span>{formatted}</span>;

  return { body, tooltip };
};

export default Default;
