import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { datadogLogs } from '@datadog/browser-logs';
import { RestLink } from 'apollo-link-rest';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import { API_BASE_URL, APOLLO_TIMEOUT, IS_DEV_ENV } from '@app/config';
import { getIdToken, isExpired } from '@app/containers/Auth/idToken';

export const getAuthLink = (checkSession?: (silent: boolean) => void) =>
  setContext(async (operation, { headers }) => {
    // Always get a new token before calling ExportStart because it is a long
    // running task and uses the user's token. If the user's token expires
    // during the export it fails in the backend and never returns.
    const shouldRenewToken = isExpired() || operation?.operationName === 'ExportStart';
    const token = shouldRenewToken ? await checkSession?.(true) : getIdToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }) as ApolloLink;

const changeAssetsPathLink = new ApolloLink((operation, forward) => {
  // This will modify the paths for assets returned in graphql responses
  if (!IS_DEV_ENV) {
    return forward(operation);
  }

  return forward(operation).map((response) => {
    try {
      const assetsStaging = /https:\/\/assets-staging.onarchipelago.com\//gi;
      const jsonString = JSON.stringify(response);
      const modified = jsonString.replace(assetsStaging, '/assets/');
      const json = JSON.parse(modified);
      return json;
    } catch (err) {
      // Return original if we can't parse the JSON
      console.error('Could not process graphql response in change assets link');
      return response;
    }
  });
});

export interface TypePatchers {
  [index: string]: TypePatcher<any, any>;
}
export type TypePatcher<Data, Response> = (data: Data) => Response;

const getRestLink = (typePatcher: TypePatchers) =>
  new RestLink({
    typePatcher,
    uri: `${API_BASE_URL}/`,
  });

const getRetryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => {
      console.warn(`Error in getRetryLink: (${error}). StatusCode: ${error?.response?.status};`);
      // GraphQL should only return requests with status code in the 200 range. Even when there's
      // an error. GraphQL contains an error array with the errors for that request.

      // 400 Bad Request - returned by AuthorizationHandler. Very unlikly that this will be
      // returned by the server. Will only be returned when the token contains invalid claims
      if (error?.response?.status === 400) {
        return false;
      }
      // 401 Unauthorized error - returned by jwtMiddleWare when invalid token
      if (error?.response?.status === 401) {
        return false;
      }
      // 403 Forbidden error - it is very unlikely that this will ever get returned by the server,
      // but it is possible in one scenario. Which is when casbin (API Authorization Library) has
      // revoked access for this user to use the GraphQL endpoint.
      if (error?.response?.status === 403) {
        return false;
      }
      // 422 Unprocessable Entity - sometimes occurs when a field is queried that is not in the
      // GraphQL schema.
      if (error?.response?.status === 422) {
        return false;
      }

      // All other failurs are caught here. Like for example network errors. If network error
      // occurs then we should retry the request.
      // !! casts to boolean. true when error. false when error empty. false means not retrying
      return !!error;
    },
  },
  delay: {
    initial: 300,
    jitter: true,
    max: Infinity,
  },
});

const getLink = (
  typePatcher: TypePatchers,
  uri: string,
  checkSession?: (silent: boolean) => void,
) =>
  // `from` takes an array of links and combines them all into a single link
  ApolloLink.from([
    // From the docs: An Apollo Link that aborts requests that aren't completed within a specified
    // timeout period. Note that timeouts are enforced for query and mutation operations only
    // (not subscriptions)
    new ApolloLinkTimeout(APOLLO_TIMEOUT) as any, // The types are in conflict with 3.x
    // From the docs: Use this link to do some custom logic when a GraphQL or network error happens.
    // onError is defined before getRetryLink so the error handling is done after retrying
    onError(({ graphQLErrors, networkError, operation }) => {
      // This code only get reached when the retry can't resolve it OR when there's a graphql error,
      // for example system error or not authorized. Note: GraphQL errors are being returned with
      // status code 200, that's why it isn't caught in the retry
      if (graphQLErrors) {
        // Possible errors:
        // SystemError "unexpected system error"
        // ErrNotAuthorized - "requested action not authorized"
        if (IS_DEV_ENV) {
          console.error('Apollo Link GraphQL Error', graphQLErrors);
        } else {
          datadogLogs.logger.error('Apollo Link GraphQL Error', { graphQLErrors, operation });
        }
      }
      if (networkError) {
        // Possible errors: 'Timeout exceeded', 'Failed to fetch', 'Received status code 401'
        if (networkError.message === 'Failed to fetch') {
          // eslint-disable-next-line no-param-reassign
          networkError.message = 'Connection error. Please try again';
        }

        if (IS_DEV_ENV) {
          console.error('Apollo Link Network Error', networkError);
        } else {
          datadogLogs.logger.error('Apollo Link Network Error', { networkError, operation });
        }
      }
    }),
    // From the docs: apollo-link-retry provides exponential backoff, and jitters delays between
    // attempts by default. It does not handle retries for GraphQL errors in the response, only for
    // network errors.
    getRetryLink,
    // getAuthLink adds the auth header from local storage
    getAuthLink(checkSession),

    changeAssetsPathLink,
    // An Apollo Link to use GraphQL without using a GraphQL server. This wraps REST call to GraphQL
    // format. Because we're in a transition from REST to GraphQL
    getRestLink(typePatcher),
    // From the docs: Creates a terminating Apollo Link capable of file uploads. The link matches and
    // extracts files in the GraphQL operation. If there are files it uses a FormData instance as the
    // fetch options.body to make a GraphQL multipart request, otherwise it sends a regular POST request.
    createUploadLink({
      uri,
    }),
  ]) as ApolloLink;

export default getLink;
