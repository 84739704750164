import React from 'react';
import { Button, ButtonEmpty, EuiFlexGroup, EuiFlexItem } from 'ui';

interface Props {
  loading: boolean;
  onCancel: () => void;
  onSave: () => void;
  saving: boolean;
}

const SubmissionSettingsModalFooter: React.FC<Props> = ({ loading, onCancel, onSave, saving }) => (
  <EuiFlexGroup data-testid="submission-settings-modal-footer" justifyContent="flexEnd">
    <EuiFlexItem grow={false}>
      <ButtonEmpty onClick={onCancel} label="Cancel" />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <Button
        data-testid="save-submission-settings-button"
        disabled={loading}
        fill
        loading={saving}
        onClick={onSave}
        label="Save"
      />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default SubmissionSettingsModalFooter;
