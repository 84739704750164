import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import {
  DOCUMENT_PATH,
  MP_DOCUMENT_PATH,
  MP_PROPERTY_PATH,
  MP_STREAM_PATH,
  PROPERTY_PATH,
  REPORT_PATH,
  REPORT_PROPERTY_PATH,
  STREAM_PATH,
} from './constants';
import ProtectedModal from './ProtectedModal';

/*
  These modals are rendered in addition to the stream page. They are rendered prior to the stream
  in the same parent component. They are designed like this so that a link to:
  /streams/:streamId/properties/:propertyId will always render the modal.

  In the future we may want to change this so we are checking the URL on the stream page itself
  and then using "showFlyout" or similar to open the property detail view.
 */

const PropertyModal = loadable(() => import('@onarchipelago/cx/PropertyModal/PropertyModal'));
const DocumentsModal = loadable(() => import('@onarchipelago/cx/DocumentsModal//DocumentsModal'));

const ProtectedModalRoutes: FC = () => (
  <Switch>
    {/* Regular stream property modal and documents modal paths */}
    <ProtectedModal path={PROPERTY_PATH} parentPath={STREAM_PATH} component={PropertyModal} />
    <ProtectedModal path={DOCUMENT_PATH} parentPath={STREAM_PATH} component={DocumentsModal} />
    {/* My Properties stream property modal and documents modal paths */}
    <ProtectedModal path={MP_PROPERTY_PATH} parentPath={MP_STREAM_PATH} component={PropertyModal} />
    <ProtectedModal
      path={MP_DOCUMENT_PATH}
      parentPath={MP_STREAM_PATH}
      component={DocumentsModal}
    />
  </Switch>
);

export const ReportProtectedModalRoutes: FC = () => (
  <Switch>
    {/* Property modal in reports page */}
    <ProtectedModal
      path={REPORT_PROPERTY_PATH}
      parentPath={REPORT_PATH}
      component={PropertyModal}
    />
  </Switch>
);

export default ProtectedModalRoutes;
