import styled from '@emotion/styled';
import { EuiFlyoutBody, EuiSelectable } from 'ui';

export const FlyoutBody = styled(EuiFlyoutBody)`
  .euiFlyoutBody__overflowContent {
    height: 100%;
  }
`;

export const Selectable = styled(EuiSelectable)`
  .euiSelectableList__list li {
    border-bottom: none;
  }
`;
