import gql from 'graphql-tag';

// ----- QUERY ----- //

export const GET_PROJECTS = gql`
  query GetProjects($orgName: String) {
    projects(orgName: $orgName) {
      name
      orgName
      effectiveFrom
      dueOn
    }
  }
`;

// ----- TYPINGS ----- //

export interface GetProjectsData {
  projects: Array<{
    dueOn: string;
    effectiveFrom: string;
    name: string;
    orgName: string;
  }>;
}

export interface GetProjectsVariables {
  orgName?: string;
}
