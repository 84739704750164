"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOption = void 0;
var getOption = function (options, value) {
    if (value === undefined || value === null) {
        return [];
    }
    return options.filter(function (option) { return option.value === value; });
};
exports.getOption = getOption;
