import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors } from 'ui';
import { SparkColumn } from '../types';
import Chart from './Chart';

interface IProps {
  column: SparkColumn;
}

const options = {
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      color: colors.dataViz.archipelagoGreen3,
      enableMouseTracking: true,
      fillColor: {
        linearGradient: [100, 0, 100, 30],
        stops: [
          [0, 'rgba(47, 128, 237, 0.2)'],
          [1, 'rgba(47, 128, 237, 0.0)'],
        ],
      },
      lineWidth: 3,
      marker: {
        radius: 0,
      },
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    minorTicks: true,
    tickLength: -32,
  },
  yAxis: {
    visible: false,
  },
};

const Container = styled.div`
  height: 100%;
  display: flex;
`;

const Spark: FC<IProps> = ({ column }) => {
  const effectiveData = column.data.filter((d) => d !== null);

  const stopRender = effectiveData.length < 2;
  if (stopRender) {
    return null;
  }

  return (
    <Container>
      <label>{column.label}</label>
      <Chart
        options={{
          ...options,
          series: [
            {
              data: column.data,
              type: 'area',
            },
          ],
        }}
      />
    </Container>
  );
};

export default Spark;
