"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zurichColors = exports.shadeColors = exports.colors = void 0;
exports.colors = {
    accent: '#F04E98',
    black: '#000000',
    brandGreen: '#027979',
    danger: '#BD271E',
    primary: '#0F766E',
    success: '#0077CC',
    warning: '#FEC514',
    subdued: '#98A2B3',
    white: '#FFFFFF',
};
exports.shadeColors = {
    white: '#FFFFFF',
    lightestGray: '#F5F7FA',
    lightGray: '#E0E6EB',
    gray: '#98A2B3',
    darkGray: '#50687C',
    darkestGray: '#28343E',
    black: '#000000',
};
exports.zurichColors = {
    zurichBlue: '#4870C6',
    zurichGray: '#E0E6EB',
    zurichGreen: '#19A5B6',
    zurichOrange: '#F7AD0099',
    zurichRed: '#FF7669',
    zurichYellow: '#F7AD00',
};
