import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Comparators,
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  useToast,
} from 'ui';
import { useTaskContext } from './context/TaskContext';
import { ModalWithButtons } from '@app/components/ModalWithButtons/ModalWithButtons';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { useDismissTasksMutation } from '@app/graphql/precheck/mutations/dismissTasks/__generated__/dismissTasks.generated';
import { TaskFlyout } from '../TaskFlyout/TaskFlyout';
import { useUnDismissTasksMutation } from '@app/graphql/precheck/mutations/undismissTasks.ts/__generated__/undismissTasks.generated';
import { Assignment } from './Assignment';
import { TaskStatus } from '@app/graphql/precheck/precheck.types';
import { StyledBadge, StyledStat, StyledSummary, StyledTaskTable } from './TaskTable.emotion';
import { colorMap } from './constants/colorMap';
import { labelMap } from './constants/labelMap';
// @ts-ignore
import GET_PROPERTIES_FOR_ENRICHMENT from '@app/queries/properties/propertiesForEnrichment.gql';
import EditDataFlyout from '@app/cx/Stream/EditDataFlyout/EditDataFlyout';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { EJobType } from '@app/queries/organizations/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { getMetadataByType } from '@app/cx/Stream/utils';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { usePrecheckContext } from '@app/precheck/context/PrecheckContext';
import { useQuery } from '@apollo/client';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IFormattedtask } from './context/types';

export const TaskTable = () => {
  const {
    tasks,
    setSelectedTasks,
    selectedTasks,
    filters,
    loading,
    filteredTiv,
    tivPercent,
    refetch,
  } = useTaskContext();
  const client = usePrecheckClient();
  const jobsApolloClient = useJobsApolloClient();
  const [sortField, setSortField] = useState('priority');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isOpen, setIsOpen] = useState(false);
  const { stream } = usePrecheckContext();
  const { propertyAttributeMetadata } = useStreamContext();
  const { showFlyout } = useContext(FlyoutContext);
  const { selectedOrganization } = useUserSession();

  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const toast = useToast();

  const [dismissTasksMutation, { loading: dismissLoading }] = useDismissTasksMutation({
    client,
    onCompleted: () => {
      setSelectedTasks([]);
      setSelectedTaskId(null);
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        orgName: selectedOrganization?.name,
        taskIDs: selectedTasks.map((task) => task.id),
      },
    },
  });

  const [unDismissTasksMutation, { loading: unDismissLoading }] = useUnDismissTasksMutation({
    client,
    onCompleted: () => {
      setSelectedTasks([]);
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        orgName: selectedOrganization?.name,
        taskIDs: selectedTasks.map((task) => task.id),
      },
    },
  });

  const dismissTxt = filters.dismissed ? `Undismiss` : `Dismiss`;

  const handleDismiss = async () => {
    if (filters.dismissed) {
      await unDismissTasksMutation();
    } else {
      await dismissTasksMutation();
    }

    setIsOpen(false);
  };

  const onSelectionChange = (selectedItems) => {
    setSelectedTasks(selectedItems);
  };

  const selection = {
    onSelectionChange,
    initialSelected: selectedTasks,
  };

  const columns = [
    {
      field: 'priority',
      name: 'Priority',
      sortable: true,
      width: '80px',
    },
    {
      name: 'Property',
      sortable: () => {},

      render: (task) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem style={{ fontWeight: 600 }} grow={false}>
            {task.propertyName}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{task.propertyCityAndState}</EuiFlexItem>
        </EuiFlexGroup>
      ),

      width: '25%',
    },

    {
      field: 'tiv',
      name: 'TIV',
      sortable: true,
      width: '7%',
    },
    {
      field: 'assignees',
      name: 'Assignees',
      render: (assignees) => (
        <>
          {assignees.length === 0 ? (
            <Avatar name={null} />
          ) : (
            <EuiFlexGroup gutterSize="xs">
              {assignees.map((assignee) => (
                <EuiFlexItem key={assignee.email} grow={false}>
                  <Avatar name={assignee.name} />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          )}
        </>
      ),
      width: '7%',
    },
    {
      field: 'reasons',
      name: 'Reason for recommendation',
      width: '21%',
      render: (reasons) => {
        return (
          <EuiFlexGroup wrap gutterSize="xs">
            {reasons.map((reason) => (
              <EuiFlexItem grow={false}>
                <StyledBadge color={colorMap[reason]}>{labelMap[reason]}</StyledBadge>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'recommendedDocuments',
      name: 'Next best action',
      width: '28%',
      render: (docs) => {
        return (
          <EuiFlexGroup wrap gutterSize="xs">
            {docs.map((doc) => (
              <EuiFlexItem grow={false}>
                <EuiBadge> {doc} </EuiBadge>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      },
    },
    {
      actions: [
        {
          color: 'danger',
          description: `${dismissTxt} Task`,
          icon: filters.dismissed ? 'refresh' : 'trash',
          isPrimary: true,
          onClick: (task) => {
            setSelectedTasks([task]);
            setIsOpen(true);
          },
          width: '5%',
        },
      ],
      name: 'Actions',
    },
  ];

  const getRowProps = (task: IFormattedtask) => ({
    className: `customtaskClass ${task.status === TaskStatus.Dismissed ? 'dismissed' : ''}`,
    onClick: (e) => {
      if (task.status === TaskStatus.Dismissed) {
        e.stopPropagation();
        return;
      }
      // if a user clicks anywhere in checkbox or action cells do not
      // selected task and open flyout
      if (
        e.target.closest('td').className === 'euiTableRowCellCheckbox' ||
        e.target.closest('td').className.includes('euiTableRowCell--hasActions')
      ) {
        e.stopPropagation();
        return;
      }

      setSelectedTaskId(task.id);
    },
  });

  const sorting = {
    sort: {
      direction: sortDirection,
      field: sortField,
    },
  };

  const onTableChange = ({ sort }) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  /*  const { loading: propertiesLoading, data: propertyData } = useQuery(
    GET_PROPERTIES_FOR_ENRICHMENT,
    {
      client: jobsApolloClient,
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
      variables: {
        cursor: '',
        pageSize: 100000000,
        orgName: selectedOrganization.name,
        sortBy: [
          {
            attributeName: 'locationName',
            order: 'ASCENDING',
          },
          {
            attributeName: 'streetAddress',
            order: 'ASCENDING',
          },
          {
            attributeName: 'city',
            order: 'ASCENDING',
          },
        ],
      },
    },
  );
  const propertiesForEnrichment = propertyData?.propertiesForEnrichment || [];
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');
  const selectedTaskPropertyIds = selectedTasks.map((t) => t.propertyId);
   const selectedAttributes = selectedTasks
    .map((t) => t.attributes.map((a) => a.attributeMetadata))
    .flat();
  const selectedTaskProperties = propertiesForEnrichment?.filter((p) =>
    selectedTaskPropertyIds.includes(p.archipelagoId),
  );
*/
  /*const handleBulkEdit = () => {
    showFlyout(
      <EditDataFlyout
        filterable={filterable}
        orgName={selectedOrganization.name as string}
        stream={stream as IGraphQLStream}
        jobType={EJobType.Enrichment}
        refetch={refetch}
        attributes={selectedAttributes as unknown as IGraphQLAttributeMetadata[]}
        properties={selectedTaskProperties}
      />,
    );
  };
*/
  let items = [];

  if (sortField) {
    let effectiveSortField = sortField;
    if (effectiveSortField === 'Property') {
      effectiveSortField = 'propertyName';
    }

    if (effectiveSortField === 'tiv') {
      effectiveSortField = 'rawTiv';
    }

    items = tasks
      .slice(0)
      .sort(Comparators.property(effectiveSortField, Comparators.default(sortDirection)));
  } else {
    items = tasks;
  }

  return (
    <>
      {selectedTaskId && (
        <TaskFlyout
          dismissHandler={() => {
            const task = tasks.find((task) => task.id === selectedTaskId);
            setSelectedTasks([task]);
            setIsOpen(true);
          }}
          taskID={selectedTaskId}
          clearSelectedTask={() => setSelectedTaskId(false)}
        />
      )}
      {isOpen && (
        <ModalWithButtons
          header={`Are you sure you want to ${dismissTxt.toLocaleLowerCase()} ${
            selectedTasks.length > 1 ? `${selectedTasks.length} tasks` : '1 task'
          }?`}
          buttonActionLabel={dismissTxt}
          onClose={() => {
            setIsOpen(false);
            setSelectedTasks([]);
          }}
          onClick={handleDismiss}
          buttonActionVariant="danger"
          isLoading={dismissLoading || unDismissLoading}
        ></ModalWithButtons>
      )}
      {selectedTasks.length > 0 && (
        <>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <Assignment
                type="bulkAssign"
                tasks={tasks.filter((task) => selectedTasks.map((t) => t.id).includes(task.id))}
              />
            </EuiFlexItem>
            {/*<EuiFlexItem grow={false}>
              <Button
                fill
                onClick={() => {
                  handleBulkEdit();
                }}
                label={`Edit with Excel file (${selectedTasks.length})`}
              />
            </EuiFlexItem> */}
            <EuiFlexItem grow={false}>
              <Button
                fill
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                label={`${dismissTxt} Tasks (${selectedTasks.length})`}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="l" />
        </>
      )}

      <StyledSummary>
        Showing recommendations for <StyledStat>{tasks.length}</StyledStat> properties, representing{' '}
        <StyledStat>{tivPercent}</StyledStat> ({filteredTiv}) of total TIV
      </StyledSummary>

      <EuiSpacer size="m" />

      <StyledTaskTable
        // hack to get table update when tasks are unselected
        // after assign / unnasign
        key={selectedTasks.length}
        isSelectable={true}
        data-testid="precheck-task-table"
        //   @ts-ignore
        columns={columns}
        items={items}
        loading={loading}
        itemId="id"
        sorting={sorting}
        selection={selection}
        rowProps={getRowProps}
        onChange={onTableChange}
      />
    </>
  );
};
