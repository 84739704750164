import gql from 'graphql-tag';

export const TAKE_SNAPSHOT = gql`
  mutation CreateSnapshot($input: CreateSnapshotInput!) {
    createSnapshot(input: $input) {
      id
      name
      date
      description
      bound
      visible
    }
  }
`;
