import React, { memo, useRef } from 'react';
import { FormatCurrency, useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLTIVChart } from '@app/queries/explorers/types';
import { getCurrencyValue } from '@app/utils/format';
import { Explorer } from '../Explorer.emotion';
import { buildTIVChartData, ChartData, chartDataIsEqual, getAgeTickPositions } from './utils';

require('highcharts/modules/exporting')(Highcharts);

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const getOptions = (
  queryData: Array<ChartData>,
  avgRoofAge: any,
  scaleAmount: number,
  currencyAbbreviation: string,
  formatCurrency?: FormatCurrency,
  title?: string,
) => ({
  chart: {
    height: 160,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  lang: {
    numericSymbols: undefined,
  },
  legend: {
    enabled: false,
  },

  plotOptions: {
    bar: {
      pointWidth: 10,
    },
  },
  series: [
    {
      data: queryData,
      name: 'Inspections',
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],

  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `${this.point.name}<br><b>${formatCurrency?.(this.point.y)}</b>`;
    },
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    labels: {
      formatter() {
        return `${this.value / scaleAmount}`; //eslint-disable-line
      },
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    plotLines: [
      {
        color: '#27AE60',
        label: {
          rotation: 0,
          style: {
            color: '#27AE60',
            fontSize: '10px',
          },
          text: avgRoofAge && `Average<br/>${avgRoofAge} years`,
          x: 80,
          y: 60,
        },
        value: avgRoofAge,
        width: 0,
        zIndex: 2,
      } as Highcharts.YAxisPlotLinesOptions,
    ],
    tickPositions: getAgeTickPositions(queryData),
    title: {
      text: currencyAbbreviation,
    },
  },
});

const RoofYear: React.FC<IProps> = ({ explorer: e, title }) => {
  const chartComponent = useRef(null);
  const explorer = e as IGraphQLTIVChart;
  const queryData = buildTIVChartData(explorer);
  const avgRoofAge = explorer.average;

  // Get the max number from all the TIVs
  const theMax: number = Math.max(...explorer.values.map((o) => o.tiv));

  const currencyValue = getCurrencyValue(theMax);

  const { formatStreamCurrency } = useStreamContext();

  return (
    <Explorer>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={getOptions(
          queryData,
          avgRoofAge,
          currencyValue.amount || 1,
          currencyValue.shortAbbreviation,
          formatStreamCurrency,
          title,
        )}
      />
    </Explorer>
  );
};

export default memo(RoofYear, chartDataIsEqual);
