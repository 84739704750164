"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.eui = exports.forms = exports.core = void 0;
// source: https://www.figma.com/file/E7PudhkOFUK4TupdupreEG/Elastic-UI-(New)?type=design&node-id=10292-221&mode=design&t=QIXV3drKgClkU4FP-0
// NOTE: incomplete, still need to add other colors.
exports.core = {
    emptyShade: '#FFFFFF',
    lightestShade: '#F1F4FA',
    lightShade: '#D3DAE6',
    mediumShade: '#98A2B3',
    darkShade: '#69707D',
    darkestShade: '#343741',
    fullShade: '#000000',
    primary: '#0F766E',
    success: '#0077CC',
    accent: '#F04E98',
    warning: '#FEC514',
    danger: '#BD271E',
};
exports.forms = {
    backgroundColor: '#FBFCFD',
    backgroundDisabled: '#EEF2F7',
    borderOpaqueColor: '#E7E9F1',
    inputGroupLabelBack: '#E9EDF3',
    backgroundFocus: '#FFFFFF',
    disabledCheck: '#E6F0F8',
    checkboxBorder: '#C9CBCD',
};
exports.eui = __assign({ forms: exports.forms }, exports.core);
