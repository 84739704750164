import { AttributeFilter, BinaryOperator } from '@app/graphql/types';
import { GroupByV2 } from './types';

export const addToGroupByV2 = (groupByV2State: null | GroupByV2[], groupByAttributes: string[]) => {
  // if groupByAttributes is empty then there's no grouping and we want to delete everything
  if (groupByAttributes?.length < 1) {
    return [];
  }

  // Make copy
  const groupByV2StateCopy = [...(groupByV2State || [])];

  // Short-circuit; if empty state we'll just create one.
  if (groupByV2StateCopy.length < 1) {
    return groupByAttributes.map((attributeName) => ({
      activeGroupIndex: undefined,
      attributeName,
      value: '',
    }));
  }

  // Find the last attribute and its index
  const lastAttribute = groupByAttributes[groupByAttributes.length - 1];
  const lastAttributeIndex = groupByV2StateCopy.findIndex(
    (groupBy) => groupBy.attributeName === lastAttribute,
  );

  // if found then there's nothing to add
  if (lastAttributeIndex > -1) {
    const toDeleteAttributes = [];

    for (const groupByV2 of groupByV2StateCopy) {
      const found = groupByAttributes?.find(
        (attributeName) => attributeName === groupByV2.attributeName,
      );
      if (!found) {
        toDeleteAttributes.push(groupByV2.attributeName);
      }
    }

    // Delete
    for (const toDelete of toDeleteAttributes) {
      for (let i = 0; i < groupByV2StateCopy.length; i++) {
        if (groupByV2StateCopy[i].attributeName === toDelete) {
          groupByV2StateCopy.splice(i, 1);
        }
      }
    }

    return groupByV2StateCopy;
  }

  // Add if found, then return
  groupByV2StateCopy.push({
    activeGroupIndex: undefined,
    attributeName: lastAttribute,
    value: '',
  });
  return groupByV2StateCopy;
};

export const addExpandValue = (
  groupByV2State: null | GroupByV2[],
  value: string,
  index: number,
) => {
  const groupByV2StateCopy = [...(groupByV2State || [])];
  for (const groupBy of groupByV2StateCopy) {
    if (groupBy.value === '') {
      groupBy.value = value;
      groupBy.activeGroupIndex = index;
      break;
    }
  }
  return groupByV2StateCopy;
};

// Remove the value from the last record
export const removeExpandValue = (groupByV2State: null | GroupByV2[]) => {
  const groupByV2StateCopy = [...(groupByV2State || [])];

  // reverse for loop
  for (let i = groupByV2StateCopy.length - 1; i >= 0; i--) {
    const groupBy = groupByV2StateCopy[i];
    if (groupBy.value !== '') {
      groupBy.value = '';
      groupBy.activeGroupIndex = undefined;
      break;
    }
  }

  return groupByV2StateCopy;
};

// Remove the value from the last record
export const clearValuesAfter = (groupByV2State: null | GroupByV2[], index: number) => {
  const groupByV2StateCopy = [...(groupByV2State || [])];

  groupByV2StateCopy.forEach((groupBy, i) => {
    if (i > index) {
      groupBy.value = '';
      groupBy.activeGroupIndex = undefined;
    }
  });

  return groupByV2StateCopy;
};

export const getActiveGroupIndex = (groupByV2State: null | GroupByV2[]): number => {
  if (groupByV2State?.length < 1) {
    return undefined;
  }

  const lastGroupByStateValue = groupByV2State[groupByV2State.length - 1];
  return lastGroupByStateValue.activeGroupIndex;
};

// Returning string[] doesn't make sense. but it is what the API needs.
export const getGroupByFromState = (groupByV2State: null | GroupByV2[]): null | string[] => {
  if (!groupByV2State || groupByV2State?.length < 1) {
    return undefined;
  }
  for (const groupBy of groupByV2State) {
    if (groupBy.value === '' && groupBy.attributeName !== 'changesSince') {
      return [groupBy.attributeName];
    }
  }
  // return undefined;
  const lastGroupByStateValue = groupByV2State[groupByV2State.length - 1];
  if (lastGroupByStateValue.attributeName === 'changesSince') {
    return undefined;
  }
  return [lastGroupByStateValue.attributeName];
};

export const resolveFilters = (
  filters: AttributeFilter[],
  groupByV2State: null | GroupByV2[],
  searchPropertiesIds: string[],
  gridV2: boolean = false,
): AttributeFilter[] => {
  // Make copy
  const filtersCopy = [...(filters || [])];

  (groupByV2State || [])
    // The last item in the array is the one that is expanded. We don't want to include that in the filter.
    .filter((_, length) => 
      // if gridV2 is true, then we want to include the last item in the filter
       gridV2 ? true : length < groupByV2State.length - 1,
    )
    // We only want to include the ones that have a value in the filter.
    .filter(
      (groupByV2) =>
        groupByV2.attributeName !== 'changesSince' &&
        groupByV2.attributeName !== '' &&
        groupByV2.value !== '' &&
        groupByV2.activeGroupIndex != undefined,
    )
    .forEach((groupByV2) => {
      // When you group by on an attribute and one of the groups has the value of NULL. We then
      // default the value to (Blank) to make it more human readable. But, the API doesn't know
      // (Blank), it only understands NULL. So we need to convert it back to NULL to make the
      // filter work.
      filtersCopy.push(
        groupByV2.value.toLowerCase() === '(blank)'
          ? {
              name: groupByV2.attributeName,
              operator: BinaryOperator.IsEmpty,
              values: [],
            }
          : {
              name: groupByV2.attributeName,
              operator: BinaryOperator.Equal,
              values: [groupByV2.value],
            },
      );
    });

  if (searchPropertiesIds?.length > 0) {
    return [
      ...filters,
      {
        name: 'archipelagoId',
        operator: BinaryOperator.Equal,
        values: searchPropertiesIds,
      },
    ];
  }
  return filtersCopy;
};
