import React from 'react';
import {
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiToolTip,
  Icon,
  IntegerField,
  Select,
} from 'ui';
import { isGroupable } from '@app/utils/metadata';
import { GroupedMetadata, HandleChange, SelectOption } from '../types';
import styles from './EditAttributeModal.emotion';

interface Props {
  attributeValue: GroupedMetadata;
  handleChange: HandleChange;
  parentOptions: SelectOption[];
}

const EditAttributeModalGrid: React.FC<Props> = ({
  attributeValue,
  handleChange,
  parentOptions,
}) => (
    <>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-title" grow={false}>
        <EuiText className={styles.sectionHeader}>
          <h4>Grid</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-filterable" grow={false}>
        <EuiFormRow label="Filterable in grid">
          <Checkbox
            id="filterable-in-grid"
            checked={!!attributeValue.filterable}
            onChange={(event) => handleChange('filterable', event.target.checked)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-groupable" grow={false}>
        <EuiFormRow label="Groupable in grid">
          <Checkbox
            id="groupable-in-grid"
            checked={!!attributeValue.groupable}
            disabled={!isGroupable(attributeValue.name)}
            onChange={(event) => handleChange('groupable', event.target.checked)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-hidden" grow={false}>
        <EuiFormRow label="Hidden in grid">
          <Checkbox
            id="hidden-in-grid"
            checked={!!attributeValue.hiddenInGrid}
            onChange={(event) => handleChange('hiddenInGrid', event.target.checked)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-display-width" grow={false}>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiFormRow label="Display width">
              <IntegerField
                fullWidth
                onChange={(value) => {
                  // So far we only have values range between 5 and 15 in our system. But we've
                  // expanded the range here a little bit, just for edge cases.
                  if (value >= 3 && value <= 20) {
                    handleChange('displayWidth', value);
                  }
                }}
                value={attributeValue.displayWidth}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={styles.icon} grow={false}>
            <EuiToolTip content="This position sets the column width of attributes in the grid. The number will be multiplied by 16 pixels in the grid. It is advised to keep the value between 5 and 15.">
              <Icon name="help" size="l" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-column-position" grow={false}>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiFormRow label="Column position">
              <IntegerField
                fullWidth
                onChange={(value) => handleChange('columnPosition', value)}
                value={attributeValue.columnPosition}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={styles.icon} grow={false}>
            <EuiToolTip content="This position sets the order of attributes in the grid. A lower number will appear more to the left than a higher number. Number must be unique. This only works if attributes have the same parent.">
              <Icon name="help" size="l" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-grid-column-parent" grow={false}>
        <EuiFormRow label="Parent">
          <Select
            fullWidth
            options={parentOptions}
            onClear={() => handleChange('parent', null)}
            onChange={(value) => handleChange('parent', value)}
            value={attributeValue.parent}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </>
  );

export default EditAttributeModalGrid;
