import { Dispatch, SetStateAction,useEffect, useRef, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { DataError, IJob, JobErrors } from '@onarchipelago/dice/EnrichmentProjects/types';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import APPROVE_JOB from '@app/queries/organizations/approveJob.gql';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { SUBMIT_ADD_LOSSES_JOB } from '@app/queries/organizations/submitAddLossesJob';
import { SUBMIT_JOB } from '@app/queries/organizations/submitJob';
import {
  ApproveJobData,
  ApproveJobVariables,
  EJobStatus,
  EJobType,
  IGetJobData,
  IGetJobVariables,
  ISubmitJobData,
  ISubmitJobVariables,
  SubmitAddLossesJobVariables,
} from '@app/queries/organizations/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { checkCurrentTransition } from '../utils';
import { AddJobType } from './types';

export const getSubmitJobMutation = (jobType: AddJobType) =>
  jobType === EJobType.AddLosses ? SUBMIT_ADD_LOSSES_JOB : SUBMIT_JOB;

export const getSubmitJobVariables = (
  jobType: AddJobType,
  jobID: string,
  worksheet: File,
): ISubmitJobVariables | SubmitAddLossesJobVariables => ({
  input: {
    comment: `Submit - job - Add ${
      jobType === EJobType.AddLosses ? 'Losses' : 'Properties'
    } flyout`,
    jobID,
    worksheet,
  },
});

interface Props {
  onSuccess: (job: IJob) => void;
  onAPIError: (err: ApolloError) => void;
  onJobError: (errors: JobErrors) => void;
  onTimeout: (toStatus: EJobStatus) => void;
  stream: IGraphQLStream;
  jobType: AddJobType;
  retryTime?: number;
}
interface Return {
  loading: boolean;
  submit: (jobID: string, worksheet: File) => void;
  submitWithDuplicates: (jobID: string, worksheet: File) => void;
  retry: () => void;
  setWarnings: Dispatch<SetStateAction<DataError[]>>;
  warnings: DataError[] | null;
}

const RETRY_LIMIT = 10;

export default ({
  onSuccess,
  onAPIError,
  onJobError,
  onTimeout,
  stream,
  jobType,
  retryTime = 3000,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [warnings, setWarnings] = useState<DataError[] | null>(null);
  const [confirmedAddDuplicates, setConfirmedAddDuplicates] = useState<boolean>(false);
  const [jobID, setJobID] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const jobsApolloClient = useJobsApolloClient();

  const mixpanel = useTracker('mixpanel');

  const retry = async (callback: Function, toStatus: EJobStatus) => {
    if (retryCount + 1 >= RETRY_LIMIT) {
      setIsLoading(false);
      onTimeout(toStatus);
      return;
    }

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      callback();
    }, retryTime);

    setRetryCount(retryCount + 1);
  };

  const getWarnings = (job: IJob) =>
    job.lifeCycle
      .map((j) => j.warnings)
      .filter((warnings) => !!warnings)
      .flat();

  const setJobWarnings = (job: IJob) => {
    setWarnings(getWarnings(job));
    setIsLoading(false);
  };

  useEffect(() => () => clearTimeout(timerRef.current), []);

  let approveJob: () => void;
  const [getJobQuery] = useLazyQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    client: jobsApolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: async ({ job }) => {
      checkCurrentTransition({
        job,
        onCompleted: (currentTransition) => {
          setRetryCount(0);

          if (currentTransition.to === EJobStatus.ReadyForReview) {
            getWarnings(job)?.length > 0 && !confirmedAddDuplicates
              ? setJobWarnings(job)
              : approveJob();
          } else if (currentTransition.to === EJobStatus.Complete) {
            setIsLoading(false);

            onSuccess(job);
          }
        },
        onFailed: ({ dataErrors, systemErrors }) => {
          setIsLoading(false);
          setRetryCount(0);
          onJobError({
            dataErrors,
            systemErrors,
          });
        },
        onStarted: (currentTransition) =>
          retry(() => {
            getJobQuery();
          }, currentTransition.to as EJobStatus),
      });
    },
    variables: { jobID },
  });

  const [approveJobMutation] = useMutation<ApproveJobData, ApproveJobVariables>(APPROVE_JOB, {
    client: jobsApolloClient,
    onCompleted: () => {
      getJobQuery();
    },
    onError: (error) => {
      setIsLoading(false);
      setRetryCount(0);

      onAPIError(error);
    },
    variables: {
      input: {
        jobID,
      },
    },
  });

  approveJob = () => {
    approveJobMutation();
  };

  const getJob = () => {
    getJobQuery();
  };

  const [submitJobMutation] = useMutation<ISubmitJobData, ISubmitJobVariables>(
    getSubmitJobMutation(jobType),
    {
      ...(jobType !== EJobType.AddLosses && { client: jobsApolloClient }),
      onCompleted: () => {
        getJob();
      },
      onError: (error) => {
        setIsLoading(false);
        setRetryCount(0);

        onAPIError(error);
      },
    },
  );

  const submit = (jID: string, worksheet: File) => {
    setJobID(jID);
    setIsLoading(true);

    submitJobMutation({
      variables: getSubmitJobVariables(jobType, jID, worksheet),
    });
  };

  const retrySubmit = () => {
    const trackerEvent = getTrackerEvent(jobType, stream, jobID);
    mixpanel.track(`Manage Properties - ${trackerEvent.name} - Retry`, trackerEvent.payload);

    setRetryCount(0);
    setIsLoading(true);
    getJob();
  };

  const submitWithDuplicates = (jID: string, worksheet: File) => {
    setJobID(jID);
    setIsLoading(true);
    setConfirmedAddDuplicates(true);

    retrySubmit();
  };
  return {
    loading: isLoading,
    retry: retrySubmit,
    setWarnings,
    submit,
    submitWithDuplicates,
    warnings,
  };
};

const getTrackerEvent = (jobType: AddJobType, stream: IGraphQLStream, jobId: string) => ({
  name: jobType === EJobType.AddLosses ? 'Add Losses' : 'Add Properties',
  payload: {
    jobId,
    stream_id: stream.id,
    stream_name: stream.name,
    stream_slug: stream.slug,
  },
});
