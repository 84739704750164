import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export type QueryStateParams = {
  [index: string]: string | null | undefined;
};

export const makeQueryString = (params: QueryStateParams): string =>
  Object.entries(params)
    .map(([key, value]) => {
      if (value !== undefined) {
        return [key, value ? encodeURIComponent(value) : null].join('=');
      }

      return undefined;
    })
    .filter(Boolean)
    .join('&');

export const getQueryAsObj = (params: URLSearchParams) => {
  const obj = {};
  params.forEach((val, key) => {
    obj[key] = val;
  });
  return obj;
};

type UseQueryStateResponse = [URLSearchParams, (params: QueryStateParams) => void];

/*
By default, next parameters get merged with existing parameters
(aka, by omitting parameters, they won't be overwritten in the URL).
If you want this behavior, you should explicitly pass in matching parameters
with null values.
*/
export const useQueryState = (method: string = 'push'): UseQueryStateResponse => {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams(window.location.search));

  // takes in an incoming payload, merges with the existing params and updates the URL
  const updateQuery = (nextParams: QueryStateParams) => {
    const params = getQueryAsObj(query);
    const combined = Object.entries(nextParams).reduce((obj, [key, val]) => {
      // TODO - change how this works so an undefined will remove the key from the querystring
      // currently using undefined to mean remove will break the streams page
      if (val === 'DEL_QS') {
        delete params[key];
        return obj;
      }

      if (val === undefined) {
        return obj;
      }

      return {
        ...obj,
        [key]: val,
      };
    }, params as QueryStateParams);

    history[method]({
      pathname: location.pathname,
      search: makeQueryString(combined),
    });
  };

  // triggered upon a URL change, this sets the local state to match
  useEffect(() => {
    setQuery(new URLSearchParams(window.location.search));
  }, [window.location.search]);

  return [query, updateQuery];
};
