import { SourceDocument } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';

export interface AnalyzeData {
  humanloopDataID: string;
  response: {
    metadata: {
      [attributeName: string]: any;
    };
    text: string;
    value?: any;
    explanation?: string;
  };

  sources: Array<SourceDocument>;
  config: string;
}

export type AnalyzeFormat = 'Archipelago' | 'ARCHIPELAGO_V2' | 'Default';

export interface AnalyzeVariables {
  organizationID?: string;
  propertyArchipelagoID?: string;
  input: string;
  format: AnalyzeFormat;
  attributes?: Array<string>;
  documentID?: string;
  config: string;
}

interface CopilotForm {
  propertyArchipelagoID?: string;
  attributeName?: string;
  documentID?: string;
  question?: string;
  attributesWithMappingsOnly?: boolean;
}
const defaultCopilotForm: CopilotForm = {
  attributesWithMappingsOnly: true,
  question: 'Describe sprinkler system in the building.',
};

export interface IWorkspace {
  currentTab: string;
  analyzeData: AnalyzeData | null;
}

export interface IWorkspaceConfig {
  tabNavigationStack: Array<string>;
  workspace1: IWorkspace;
  workspace2: IWorkspace;
  searchFormValues: CopilotForm;
}

export const defaultCopilotWorkspace: IWorkspaceConfig = {
  searchFormValues: defaultCopilotForm,
  tabNavigationStack: ['workspace1'],
  workspace1: {
    analyzeData: null,
    currentTab: 'property',
  },
  workspace2: {
    analyzeData: null,
    currentTab: 'property',
  },
};

export interface CopilotMapping {
  attributeName: string;
  question: string;
}

export type Feedback = 'good' | 'bad' | 'unreviewed';
export interface PropertyReportAttribute {
  id: string;
  attributeName: string;
  answer: any;
  finalAnswer: any;
  suggestedAnswer: any;
  explanation: string;
  feedback?: Feedback;

  // internal util attribute, not returned by api
  expandedByDefault?: boolean;
}

export interface PropertyReport {
  id: string;
  filename: string;
  httpURL: string;
}

export interface PropertyReportAnswers {
  document_id: string;
  classification: string;
  filename: string;
  httpURL: string;
  reportHttpURL: string;
  answers: Array<PropertyReportAttribute>;
}

export interface AttributeProvenanceInput {
  attributeName: string;
  value: any;
  documentID: string;
}
