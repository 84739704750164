import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { EuiEmptyPrompt, EuiLink, EuiPageBody, EuiPageSection } from 'ui';
import Layout from '@app/components/Layout/Layout';

const NotFound: FC = () => {
  const history = useHistory();

  return (
    <Layout>
      <EuiPageBody>
        <EuiPageSection
          data-testid="not-found"
          alignment="center"
          paddingSize="none"
          color="subdued"
        >
          <EuiEmptyPrompt
            title={<span>Page not found</span>}
            body={
              <>
                Oops! We couldn&apos;t find the page you were looking for. <br />
                <br />
                <EuiLink
                  onClick={() => {
                    history.replace({
                      pathname: '/',
                    });
                  }}
                >
                  Click here to view your streams
                </EuiLink>
              </>
            }
          />
        </EuiPageSection>
      </EuiPageBody>
    </Layout>
  );
};

export default NotFound;
