import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { API_BASE_URL, INTEGRATIONS_CONFIG_URL } from '@app/config';
import { getIdToken } from '@app/containers/Auth/idToken';

const integrationsConfigUrl = INTEGRATIONS_CONFIG_URL;

const cache = new InMemoryCache();

const integrationsApolloClient = new ApolloClient({
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link: ApolloLink.from([
    setContext((operation, { headers }) => ({
        headers: {
          ...headers,
          authorization: getIdToken() ? `Bearer ${getIdToken()}` : '',
        },
      })) as ApolloLink,
    createUploadLink({
      uri: `${integrationsConfigUrl || API_BASE_URL}`,
    }),
  ]),
  queryDeduplication: true,
});

export { integrationsApolloClient };
