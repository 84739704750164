import React from 'react';
import { Helmet } from '../Helmet';
import { EuiPanel } from 'ui';
import { Charts } from '../Charts/Charts';
import { EnterpriseHomePageHeader } from './EnterpriseHomePage.emotion';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { HUB_LABEL } from '../Layout/SideNav/constants';

export const EnterpriseHomePage = () => {
  const { selectedOrganization } = useUserSession();

  return (
    <EuiPanel paddingSize="l">
      <Helmet title={HUB_LABEL} />
      <EnterpriseHomePageHeader pageTitle={selectedOrganization?.name} />
      <Charts />
    </EuiPanel>
  );
};
