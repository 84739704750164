import React, { useState } from 'react';
import {
  ButtonEmpty,
  ButtonIcon,
  EuiBasicTable,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiText,
  HorizontalAlignment,
} from 'ui';
import { RECOMMENDATION_TYPE_VALUATION_OUTLIER } from '../TaskFlyout/TaskFlyout';
import { AttributeRow, AttributeRowTypeEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import {
  SectionLabel,
  TooltipContainer,
  TooltipIcon,
  ValuationOutlierContainer,
} from './ValuationOutlier.emotion';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useFormContext } from 'react-hook-form';

// editable fields
const subValues = [
  'buildingValue',
  'improvementsValue',
  'miscBuildingReplacementCostValue',
  'buildingReplacementCost',
];

const inputFields = [...subValues, 'floorArea'];

// ordering of line items
const lineItemNameIds = [...subValues, 'floorArea', 'replacementCostPerSquareFootage'];
const boldedLineItems = ['replacementCostPerSquareFootage'];

const getCurrencyValue = ({ currency, value }) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  }).format(value || 0);
};

export const ValuationOutlier = ({ task, defaultValues, formValues }) => {
  const { stream: streamContext } = useStreamContext();
  const [isEditing, setIsEditing] = useState(false);

  const { watch } = useFormContext();

  // recalculate fields
  watch(inputFields);

  const totalBuildingReplacementCost = subValues.reduce((acc, lineItemId) => {
    return acc + (defaultValues[lineItemId] || 0);
  }, 0);

  const buildingReplacementCost = task.property?.buildingReplacementCost;

  const newTotalBuildingReplacementCost = buildingReplacementCost
    ? formValues['buildingReplacementCost']
    : subValues.reduce((acc, lineItemId) => {
        return acc + (formValues[lineItemId] || 0);
      }, 0);

  const newReplacementCostPerFloorAreaUnit = newTotalBuildingReplacementCost / formValues.floorArea;

  const buildingReplacementCostExists = task.attributes
    .filter((attribute) => {
      return attribute.recommendationType === RECOMMENDATION_TYPE_VALUATION_OUTLIER;
    })
    .find((attribute) => attribute.name === 'buildingReplacementCost');

  const columns = [
    {
      field: 'attribute',
      name: (
        <EuiText>
          <h5>Attribute</h5>
        </EuiText>
      ),
      render: (name, data) => {
        if (boldedLineItems.includes(data.name) || data.name === 'buildingReplacementCost') {
          return <b>{name}</b>;
        }
        return name;
      },
    },
    {
      field: 'value',
      name: (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
            {!isEditing && (
              <ButtonIcon
                iconName="edit"
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            )}
            <EuiText>
              <h5>{isEditing ? 'Current Value' : 'Value'}</h5>
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      ),
      render: (value, data) => {
        if (boldedLineItems.includes(data.name) || data.name === 'buildingReplacementCost') {
          return <b>{value}</b>;
        }
        return value;
      },
      align: 'right' as HorizontalAlignment,
    },
  ];

  if (isEditing) {
    columns.push({
      field: 'newValue',
      name: (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
            <EuiText>
              <h5>New Value</h5>
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      ),
      render: (value, data) => {
        if (
          boldedLineItems.includes(data.name) ||
          (data.name === 'buildingReplacementCost' && !buildingReplacementCostExists)
        ) {
          return <b>{data.newValue}</b>;
        }

        return (
          <AttributeRow
            id={data.name}
            inputProps={{
              disabled: false,
              append: data.name === 'floorArea' ? null : <b>{streamContext?.displayCurrency}</b>,
            }}
            type={
              data.name === 'floorArea'
                ? AttributeRowTypeEnum.integer
                : AttributeRowTypeEnum.currency
            }
          />
        );
      },
      align: 'right',
    });
  }

  return (
    <ValuationOutlierContainer direction="column">
      <EuiText size="m">
        <h4>This property has been flagged as potentially overvalued.</h4>
      </EuiText>
      <EuiFlexGroup direction="row">
        {isEditing && (
          <ButtonEmpty
            label="Cancel Edits"
            onClick={() => {
              setIsEditing(false);
            }}
          ></ButtonEmpty>
        )}
      </EuiFlexGroup>
      <EuiForm>
        <EuiBasicTable
          columns={columns}
          items={lineItemNameIds
            .reduce((acc, lineItemId) => {
              const valuationOutlier = task.attributes
                .filter((attribute) => {
                  return attribute.recommendationType === RECOMMENDATION_TYPE_VALUATION_OUTLIER;
                })
                .find((attribute) => attribute.name === lineItemId);

              if (valuationOutlier) {
                return [...acc, valuationOutlier];
              }

              // if it's not returned from the server, we still need to show it
              if (lineItemId === 'buildingReplacementCost') {
                return [
                  ...acc,
                  {
                    name: lineItemId,
                    attributeMetadata: {
                      displayName: 'Replacement Cost Value',
                      name: 'buildingReplacementCost',
                    },
                  },
                ];
              }

              return acc;
            }, [])
            .map((attribute) => {
              if (attribute.name === 'buildingReplacementCost') {
                return {
                  attribute: (
                    <SectionLabel gutterSize="xs">
                      {attribute.attributeMetadata.displayName}
                      <TooltipContainer
                        content={
                          <EuiText>
                            Total building replacement cost = building value + improvements /
                            betterments + misc. building replacement cost
                          </EuiText>
                        }
                      >
                        <TooltipIcon name="help"></TooltipIcon>
                      </TooltipContainer>
                    </SectionLabel>
                  ),
                  value:
                    streamContext?.displayCurrency &&
                    getCurrencyValue({
                      currency: streamContext?.displayCurrency,
                      value: buildingReplacementCost || totalBuildingReplacementCost || 0,
                    }),
                  newValue:
                    streamContext?.displayCurrency &&
                    getCurrencyValue({
                      currency: streamContext?.displayCurrency,
                      value: newTotalBuildingReplacementCost || 0,
                    }),
                  name: attribute.attributeMetadata.name,
                };
              }
              const value = task.property?.[attribute.name];
              return {
                attribute:
                  attribute.attributeMetadata.name === 'replacementCostPerSquareFootage' ? (
                    <SectionLabel gutterSize="xs">
                      {attribute.attributeMetadata.displayName}
                      <TooltipContainer
                        content={
                          <EuiText>
                            Building replacement cost per floor area = Total building replacement
                            cost ÷ floor area
                          </EuiText>
                        }
                      >
                        <TooltipIcon name="help"></TooltipIcon>
                      </TooltipContainer>
                    </SectionLabel>
                  ) : (
                    attribute.attributeMetadata.displayName
                  ),
                value:
                  attribute.attributeMetadata.name === 'floorArea'
                    ? new Intl.NumberFormat('en-US').format(value || 0)
                    : streamContext?.displayCurrency &&
                      getCurrencyValue({
                        currency: streamContext?.displayCurrency,
                        value: value || 0,
                      }),
                newValue:
                  attribute.attributeMetadata.name === 'floorArea'
                    ? new Intl.NumberFormat('en-US').format(value || 0)
                    : streamContext?.displayCurrency &&
                      getCurrencyValue({
                        currency: streamContext?.displayCurrency,
                        value:
                          attribute.attributeMetadata.name === 'replacementCostPerSquareFootage'
                            ? newReplacementCostPerFloorAreaUnit
                            : value || 0,
                      }),

                name: attribute.attributeMetadata.name,
                attributeMetadata: attribute.attributeMetadata,
              };
            })}
        ></EuiBasicTable>
      </EuiForm>
      <EuiCallOut
        title="Please confirm all valuation changes are accurate before proceeding"
        color="warning"
        iconType="warning"
      ></EuiCallOut>
    </ValuationOutlierContainer>
  );
};
