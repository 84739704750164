import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SnapshotFragmentDoc } from '../../../queries/snapshot/__generated__/SnapshotFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSnapshotMutationVariables = Types.Exact<{
  input: Types.CreateSnapshotInput;
}>;


export type CreateSnapshotMutation = { __typename?: 'Mutation', createSnapshot: { __typename?: 'Snapshot', id: string, organizationId: string, name: string, date: any, description?: string | null, bound: boolean, projectID?: string | null, visible: boolean, displayDate?: any | null, type: Types.SnapshotType } };


export const CreateSnapshotDocument = gql`
    mutation CreateSnapshot($input: CreateSnapshotInput!) {
  createSnapshot(input: $input) {
    ...snapshot
  }
}
    ${SnapshotFragmentDoc}`;
export type CreateSnapshotMutationFn = Apollo.MutationFunction<CreateSnapshotMutation, CreateSnapshotMutationVariables>;

/**
 * __useCreateSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnapshotMutation, { data, loading, error }] = useCreateSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<CreateSnapshotMutation, CreateSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSnapshotMutation, CreateSnapshotMutationVariables>(CreateSnapshotDocument, options);
      }
export type CreateSnapshotMutationHookResult = ReturnType<typeof useCreateSnapshotMutation>;
export type CreateSnapshotMutationResult = Apollo.MutationResult<CreateSnapshotMutation>;
export type CreateSnapshotMutationOptions = Apollo.BaseMutationOptions<CreateSnapshotMutation, CreateSnapshotMutationVariables>;