import { ReactElement } from 'react';
import { Component } from '@app/queries/streams/types';

export const GradeBadgeColors = {
  BLUE: 'zurichBlue',
  GRAY: 'zurichGray',
  GREEN: 'zurichGreen',
  RED: 'zurichRed',
  YELLOW: 'zurichYellow',
};

export enum GradeColors {
  BLUE = '#4870C6',
  GREEN = '#19A5B6',
  YELLOW = '#F7AD00',
  RED = '#FF7569',
  GRAY = '#E0E6EB',
}

export enum GradeCategories {
  GOOD = 'Good',
  FAIR = 'Fair',
  HIGH = 'High',
  POOR = 'Poor',
}

// Grading overview tab

type FactorContent = string | ReactElement;

export interface FactorProps {
  title?: FactorContent;
  description?: FactorContent;
  components: Component[];
}
