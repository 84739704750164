import styled from '@emotion/styled';
import { EuiText } from 'ui';

export const NoDataText = styled(EuiText)`
  min-height: 494px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #69707d;
  }
`;
