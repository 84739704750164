import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem } from 'ui';

export const TabContentGroup = styled(EuiFlexGroup)`
  margin-top: 24px;
`;

export const AttributeRowLabelContainer = styled(EuiFlexItem)`
  justify-content: center;
`;
