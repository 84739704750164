import React from 'react';
import tigerFactory, { TigerTokenAuthProvider } from '@gooddata/sdk-backend-tiger';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';
import { BackendProvider, InsightView,WorkspaceProvider } from '@gooddata/sdk-ui-all';
import { useUserSession } from '../../contexts/UserSessionContext';
import { useAnalyticsConfigQuery } from '../../graphql/queries/analyticsConfig/__generated__/analyticsConfig.generated';
import { AnalyticsType } from '../../graphql/types';
import '@gooddata/sdk-ui-ext/styles/css/main.css';

export const DataJourney = () => {
  const { selectedOrganization } = useUserSession();
  const { data } = useAnalyticsConfigQuery({
    variables: {
      input: {
        analyticsType: AnalyticsType.DataJourney,
        orgName: selectedOrganization?.name,
      },
    },
  });

  if (!data) {
    return null;
  }
  const signedJWT = data?.analyticsConfig?.signedJWT;
  const enterpriseReportingHostURL = data?.analyticsConfig?.enterpriseReportingHostURL;
  const analyticsConfig = data?.analyticsConfig?.analyticsConfig;
  const insightId = analyticsConfig?.insights[0]?.id;
  const attributeName = analyticsConfig?.insights[0]?.filters?.[0]?.id;
  const workspaceID = analyticsConfig?.workspaceID;

  const backend = tigerFactory({
    hostname: enterpriseReportingHostURL,
  }).withAuthentication(new TigerTokenAuthProvider(signedJWT));

  const defaultFilter = newPositiveAttributeFilter(attributeName, [selectedOrganization?.id]);

  const style = { height: 400 };

  return (
    <BackendProvider backend={backend}>
      <WorkspaceProvider workspace={workspaceID}>
        <div style={style}>
          <InsightView insight={insightId} filters={[defaultFilter]} />
        </div>
      </WorkspaceProvider>
    </BackendProvider>
  );
};
