import { BBox, Coordinates } from '@app/queries/properties/types';

export const SUBDIVISION_THRESHOLD = 500;
const MIN_TILING_SIZE = 1;
const MIN_ZOOM = 3;

// Returns subdivision factor n based on property count. Map will be subdivided in n x n tiles
// The higher the property count, the higher the subdivision factor
export const getSubdivisionFactor = (propertyCount: number): number =>
  Math.max(Math.floor(Math.log2(propertyCount / SUBDIVISION_THRESHOLD)), 1);

interface tileSizeConfig {
  x: number;
  y: number;
}
export const getTileSize = (zoom: number, propertyCount: number): tileSizeConfig => {
  const maxTileSizeY = 180 / getSubdivisionFactor(propertyCount);
  const maxTileSizeX = maxTileSizeY * 2;

  return {
    x: Math.min(Math.max(maxTileSizeX / (zoom - MIN_ZOOM + 1), MIN_TILING_SIZE), maxTileSizeX),
    y: Math.min(Math.max(maxTileSizeY / (zoom - MIN_ZOOM + 1), MIN_TILING_SIZE), maxTileSizeY),
  };
};

const normalizeDegrees = (v) => (v + 360) % 360;

export const bboxIntersection = (roi: BBox, tile: BBox): boolean => {
  const { northEast: ne1, southWest: sw1 } = roi;
  const { northEast: ne2, southWest: sw2 } = tile;
  // Check for non-intersection conditions
  if (ne1.latitude < sw2.latitude || sw1.latitude > ne2.latitude) {
    // Bounding boxes do not intersect
    return false;
  }

  if (ne1.longitude > ne2.longitude && ne1.longitude < sw1.longitude) {
    return true;
  }

  if (sw1.longitude < ne1.longitude || ne1.longitude > 0) {
    return !(ne1.longitude < sw2.longitude || sw1.longitude > ne2.longitude);
  }

  // Normalize longitudes to handle antimeridian crossing
  const ne1Longitude = normalizeDegrees(ne1.longitude);
  const sw1Longitude = normalizeDegrees(sw1.longitude);
  let ne2Longitude = normalizeDegrees(ne2.longitude);
  if (ne2Longitude === 0) {
    ne2Longitude = 360;
  }
  const sw2Longitude = normalizeDegrees(sw2.longitude);

  // console.log('LONGITUDES', ne1Longitude, sw1Longitude, ne2.longitude, ne2Longitude, sw2Longitude);
  if (ne1Longitude < sw2Longitude || sw1Longitude > ne2Longitude) {
    return false;
  }
  // Bounding boxes intersect
  return true;
};

export class TileConfig {
  tileSizeX: number;
  tileSizeY: number;
  initialIndex: number;
  zoomLevel: number;
  tiles: Array<number>;

  mapTileCountX(): number {
    return 360 / this.tileSizeX;
  }

  mapTileCountY(): number {
    return 180 / this.tileSizeY;
  }

  constructor(roi: BBox, { x: tileSizeX, y: tileSizeY }: tileSizeConfig, zoomLevel: number = 2) {
    this.tileSizeX = tileSizeX;
    this.tileSizeY = tileSizeY;
    this.zoomLevel = zoomLevel;

    if (!roi) {
      return;
    }

    this.tiles = [];
    for (let tileY = 0; tileY < this.mapTileCountY(); tileY++) {
      for (let tileX = 0; tileX < this.mapTileCountX(); tileX++) {
        const tileIndex = tileX + this.mapTileCountX() * tileY;
        const tileBBox = this.getBBox(tileIndex);

        if (bboxIntersection(roi, tileBBox)) {
          this.tiles.push(tileIndex);
        }
      }
    }

    if (!this.tiles.length) {
      return;
    }

    this.initialIndex = this.tiles[0];
  }

  getBBox(index: number): BBox {
    const northEast: Coordinates = {
      latitude: 90 - Math.floor(index / this.mapTileCountX()) * this.tileSizeY,
      longitude: ((index + 1) % this.mapTileCountX()) * this.tileSizeX - 180,
    };

    if (northEast.longitude <= -180) {
      northEast.longitude += 360;
    }

    const southWest: Coordinates = {
      latitude: northEast.latitude - this.tileSizeY,
      longitude: northEast.longitude - this.tileSizeX,
    };

    if (southWest.longitude < -180) {
      southWest.longitude += 360;
    }

    return {
      northEast,
      southWest,
    };
  }

  getTiles(): Array<number> {
    return (this.tiles || []).filter((value, index, array) => array.indexOf(value) === index);
  }
}
