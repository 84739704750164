import styled from '@emotion/styled';

export const AsideContainer = styled.aside`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 320px;
  border: 2px solid ${({ theme }) => theme.euiTheme?.colors?.dataViz?.gray1};
  transition: transform 250ms;
  background: white;
  position: relative;
`;
