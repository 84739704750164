import gql from 'graphql-tag';

export const GET_SCORED_SNAPSHOTS = gql`
  query getQualityAvailableSnapshots($input: QualityAvailableSnapshotsInput!) {
    qualityAvailableSnapshots(input: $input) {
      availableHazards
      date
      displayDate
      snapshotName
    }
  }
`;

export interface GetScoredSnapshotsData {
  qualityAvailableSnapshots?: {
    availableHazards: string[];
    date: string;
    displayDate?: string;
    snapshotName: string;
  }[];
}

export interface GetScoredSnapshotsVariables {
  input: {
    streamSlug: string;
  };
}
