import { Attributes, ChartValues, GroupedAttributes } from '@app/queries/streams/types';
import { formatCurrency, formatNumber, IFormatCurrencyOptions } from '@app/utils/format';
import { countryNames, stateNames } from './const';
import { LossYAxisTypes } from './lineChartTypes';
import { LegendItem } from './types';

export const colorByName = (name: string, keyItems: LegendItem[]) =>
  keyItems.find((item) => item.label.toLowerCase() === name.toLowerCase())?.color || '#21B59A';

export const findTivAttribute = (attrArray: Attributes[]) => {
  const result = attrArray.find((attr) => attr.displayName === 'TIV');
  return result;
};

export const findTivAttributePct = (attrArray: Attributes[], includeSecondBar?: boolean) => {
  const attr = findTivAttribute(attrArray);
  if (attr) {
    const value = includeSecondBar ? attr.priorPctOfTotal : attr.pctOfTotal;
    return value;
  }
  return 0;
};

export const findTivAttributeValue = (attrArray: Attributes[]) => {
  const attr = findTivAttribute(attrArray);
  return attr ? attr.value : 0;
};

export const createFillerBar = (chartValues: ChartValues): GroupedAttributes => {
  const fillObject: GroupedAttributes = {
    attributes: [
      {
        displayName: 'TIV',
        pctChange: 0,
        pctOfTotal: 100 - findTivAttributePct(chartValues.attributes),
        priorPctOfTotal: 100 - findTivAttributePct(chartValues.attributes),
        priorValue: 0,
        value: 0,
      },
    ],
    label: 'None',
  };
  return fillObject;
};

export const formatDateISO = (date: Date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  return `${year}-${month.slice(-2)}-${day.slice(-2)}`;
};

export const convertAbbreviation = (abbr: string) => {
  const abbrs = abbr.split('-');
  if (abbrs.length > 1) {
    return stateNames[abbrs[1].toUpperCase()];
  }

  return countryNames[abbrs[0].toUpperCase()];
};

export const getFormattedValue = (
  value,
  type: LossYAxisTypes,
  options?: IFormatCurrencyOptions,
) => {
  switch (type) {
    case LossYAxisTypes.CURRENCY:
      return formatCurrency(value, options);
    case LossYAxisTypes.COUNT:
      return formatNumber(value);
    case LossYAxisTypes.UNITS:
      return `${formatNumber(value)}‰`;
    default:
      break;
  }
};

export const formatTIV = (TIV: number) => formatCurrency(TIV); // TODO handle non-USD
export const formatPercent = (percent: number) => Number.parseFloat((percent || 0).toFixed(2));
