import React, { useState } from 'react';
import { css } from '@emotion/react';
import {
  Button,
  EuiAccordion,
  EuiBadge,
  EuiBasicTable,
  EuiLink,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiToolTip,
  Icon,
  useEuiTheme,
} from 'ui';
import {
  ChildrenContainer,
  Modal,
} from '@app/components/ModalWithButtons/ModalWithButtons.emotion';
import {
  getIsFlood,
  getIsQuake,
  getIsScs,
  getIsWind,
  Indicator,
} from '@app/components/PropertiesGrid/GridCell/HazardsIcon';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { usePrecheckContext } from '@app/precheck/context/PrecheckContext';
import { useInboxContext } from '@app/platform/Inbox/context/InboxContext';
import { useParams } from 'react-router-dom';

const ExtraAttributeDisplay = ({ attributes = [] }) => {
  if (attributes.length === 0) {
    return <></>;
  }
  const [showModal, setShowModal] = useState(false);
  const { euiTheme } = useEuiTheme();

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
        style={{ color: euiTheme.colors.primary }}
      >
        +{attributes.length} more
      </a>
      {showModal && (
        <Modal
          data-testId={'sdgsdgsd'}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle></EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <ChildrenContainer>
              <div>
                {attributes.map((s) => (
                  <EuiBadge style={{ color: 'black', margin: '3px' }} color="lightgrey">
                    <span style={{ marginRight: '8px' }}>{s.displayName}</span>
                    <EuiToolTip content={s.definition}>
                      <Icon color="primary" name="help" size="s" />
                    </EuiToolTip>
                  </EuiBadge>
                ))}
              </div>
            </ChildrenContainer>
          </EuiModalBody>
          <EuiModalFooter>
            <Button
              type={'button'}
              size="m"
              fill
              label="Close"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </EuiModalFooter>
        </Modal>
      )}
    </>
  );
};

export const Properties = ({ properties, recordExpansion }) => {
  const summary = { earthquake: 0, flood: 0, tiv: 0, wind: 0 };
  const { recommendations } = usePrecheckContext();

  properties.map((prop) => {
    const tiv = prop.property.totalInsuredValue;
    summary.tiv += parseFloat(tiv);
  });

  const { organizationName } = useParams<{
    organizationName: string;
  }>();

  const columns = [
    {
      field: 'property',
      name: 'Property',
      render: (property) => `${property.locationName}, ${property.city}`,
      width: '25%',
    },
    {
      field: 'property',
      name: 'Tiv',
      render: (property) => formatStreamCurrency(parseFloat(property.totalInsuredValue)),

      width: '10%',
    },
    {
      field: 'property',
      name: 'Hazard Exposure',
      render: (property) => (
        <>
          <Indicator show={getIsQuake(property)}>EQ</Indicator>
          <Indicator show={getIsFlood(property)}>Fl</Indicator>
          <Indicator show={getIsWind(property)}>W</Indicator>
          <Indicator show={getIsScs(property)}>SCS</Indicator>
        </>
      ),

      width: '12%',
    },

    {
      css: css`
        flex-wrap: wrap;
      `,
      field: 'attributes',
      mobileOptions: {
        width: '100%',
      },
      name: 'Top missing attributes to complete',
      render: (attributes) => (
        <>
          {attributes.slice(0, 5).map((s) => (
            <EuiBadge style={{ color: 'black', margin: '3px' }} color="lightgrey">
              <span style={{ marginRight: '8px' }}>{s.displayName}</span>
              <EuiToolTip content={s.definition}>
                <Icon color="primary" name="help" size="s" />
              </EuiToolTip>
            </EuiBadge>
          ))}
          <ExtraAttributeDisplay attributes={attributes.slice(5, attributes.length)} />
        </>
      ),

      width: '48%',
    },
  ];

  return (
    // FIX ME
    // @ts-ignore
    <EuiAccordion
      tabIndex={0}
      key={recommendations.data.buckets.length}
      paddingSize="none"
      initialIsOpen={recommendations.data.buckets.length === 1}
      buttonProps={{ color: 'green' }}
      arrowProps={{ color: 'primary' }}
      onToggle={(isOpen: boolean) => {
        if (isOpen) {
          recordExpansion();
        }
      }}
      buttonContent={`Add missing data to ${formatStreamCurrency(
        summary.tiv,
      )} TIV of your portfolio`}
    >
      <EuiSpacer size="m" />
      <EuiBasicTable items={properties} columns={columns} />
    </EuiAccordion>
  );
};
