import { usePageState } from '@onarchipelago/cx/Stream/utils';
import moment from 'moment';
import { IStreamSnapshot } from '@app/components/Snapshot/types';

interface Props {
  changesSinceSnapshot: IStreamSnapshot | null;
  currentSnapshot: IStreamSnapshot | null;
  snapshots: Array<IStreamSnapshot>;
}

interface Return {
  changesSinceHandleChange: (snapshotId: string) => void;
  currentHandleChange: (snapshotId: string) => void;
}

export default ({ changesSinceSnapshot, currentSnapshot, snapshots }: Props): Return => {
  const [, setPageState] = usePageState();

  const currentHandleChange = (snapshotId: string) => {
    if (snapshotId === 'latest-snapshot') {
      setPageState({ currentSnapshot: null });
    } else {
      const selectedSnapshot = snapshots.filter((snapshot) => snapshot.id === snapshotId)[0];

      if (
        !!changesSinceSnapshot &&
        moment(changesSinceSnapshot.date).isAfter(moment(selectedSnapshot?.date))
      ) {
        setPageState({
          changesSince: selectedSnapshot?.name,
          currentSnapshot: changesSinceSnapshot.name,
          groupByV2: [],
        });
      } else {
        setPageState({ currentSnapshot: selectedSnapshot?.name });
      }
    }
  };

  const changesSinceHandleChange = (snapshotId: string) => {
    const selectedSnapshot = snapshots.filter((snapshot) => snapshot.id === snapshotId)[0];

    if (!!currentSnapshot && moment(selectedSnapshot?.date).isAfter(moment(currentSnapshot.date))) {
      setPageState({
        changesSince: currentSnapshot.name,
        currentSnapshot: selectedSnapshot?.name,
        groupByV2: [],
      });
    } else {
      setPageState({ changesSince: selectedSnapshot?.name, groupByV2: [] });
    }
  };

  return {
    changesSinceHandleChange,
    currentHandleChange,
  };
};
