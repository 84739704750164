import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiText,EuiTextColor } from 'ui';

export const TimestampRow = styled.div`
  margin-top: 6px;
`;

export const TimestampText = styled(EuiText)`
  .euiText {
    display: inline-block;
    vertical-align: top;
  }
  margin-top: 6px;
`;

export const Arrows = styled(EuiTextColor)`
  & > svg {
    position: relative;
    top: 3px;
  }
`;

export const FooterContainer = styled(EuiFlexGroup)`
  margin-top: -11px;
`;

export const ContentContainer = styled(EuiFlexGroup)`
  flex-grow: 0;
`;

export const FooterContent = styled(EuiFlexItem)`
  margin-top: 0 !important;
`;

export const NoDataFooter = styled(EuiText)`
  color: #98a2b3;
`;

export const NoData = styled(EuiText)`
  color: #98a2b3;
`;
