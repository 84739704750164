import gql from 'graphql-tag';

export const REQUEST_PRECHECK_RECOMMENDATIONS = gql`
  mutation requestPreCheckRecommendations($input: RequestPreCheckRecommendationsInput!) {
    requestPreCheckRecommendations(input: $input) {
      supportTicketChatRoomID
      supportTicketChatRoomName
    }
  }
`;
