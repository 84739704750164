import gql from 'graphql-tag';

export const START_EDIT_LOSSES_JOB = gql`
  mutation StartEditLossesJob($input: StartEditLossesJobInput!) {
    startEditLossesJob(input: $input) {
      jobID
      worksheet {
        id
        httpURL
        filename
        extension
        sizeInBytes
        contentType
      }
    }
  }
`;
