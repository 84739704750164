import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useHistory } from 'react-router';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { EuiFlexGroup, EuiText } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SOV_MANAGER_8 } from '@app/platform/SystemSettings/Flags/types';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import AddDataFlyout from '../AddDataFlyout/AddDataFlyout';
import { AddPropertyFlyout } from '../AddPropertyFlyout/AddPropertyFlyout';
import { PanelItem } from './types';
import { getReadableDuration } from './utils';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ inProgressJobs, setOpen }: Props): PanelItem => {
  const mixpanel = useTracker('mixpanel');
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, refetchPropertiesDataGrid, refetchPropertyGroups } = useStreamContext();
  const history = useHistory();
  const isSOV8Enabled = isFeatureEnabled(stream?.enabledFeatures, FEATURE_TYPE_SOV_MANAGER_8);

  const inProgressJob = inProgressJobs[EJobType.AddProperties];
  const hasJobInProgress = !!inProgressJob;

  const handleClick = () => {
    mixpanel.track('Manage Properties - Add', {
      in_progress: hasJobInProgress,
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <AddDataFlyout
        orgName={stream?.orgName as string}
        inProgressJob={inProgressJobs[EJobType.AddProperties]}
        stream={stream as IGraphQLStream}
        jobType={EJobType.AddProperties}
      />,
    );

    setOpen(false);
  };

  const handleBulkAdd = () => {
    if (isSOV8Enabled) {
      history.push(encodeUrl`/streams/${stream.slug}/add-properties`);
    } else {
      handleClick();
    }
  };

  let bulkAddLabel: React.ReactNode = 'Add properties with an Excel file';
  if (hasJobInProgress && inProgressJob.expiryTime) {
    const readableDuration = getReadableDuration(inProgressJob?.expiryTime);
    bulkAddLabel = (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiText>Resume add properties with an Excel file</EuiText>
        <EuiText color="subdued" size="s">
          {readableDuration} to resume
        </EuiText>
      </EuiFlexGroup>
    );
  }
  return {
    'data-testid': 'add-properties-button',
    iconName: 'plus',
    label: 'Add',
    nestedPanel: {
      items: [
        {
          'data-testid': 'add-single-property-button',
          label: 'Add a property',
          onClick: () => {
            sendMixpanelEvent(mixpanel, 'Add a property clicked', stream);
            showFlyout(
              <AddPropertyFlyout
                refetch={refetchPropertiesDataGrid}
                refetchGroups={refetchPropertyGroups}
              />,
            );
            setOpen(false);
          },
        },
        {
          'data-testid': 'add-properties-with-excel-button',
          label: bulkAddLabel,
          onClick: () => {
            sendMixpanelEvent(mixpanel, 'Add propreties with an Excel file clicked', stream);
            handleBulkAdd();
          },
        },
      ] as PanelItem[],
      title: 'Add',
    },
    onClick: () => {
      sendMixpanelEvent(mixpanel, 'Open Properties/Add panel', stream);
    },
  };

  return {
    'data-testid': 'add-properties-button',
    iconName: 'plus',
    label: 'Add',
    onClick: handleClick,
  };
};
