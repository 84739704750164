import gql from 'graphql-tag';

export const ORGANIZATION_FRAGMENT = gql`
  fragment organization on Organization {
    id
    name
    reportingType
    orgType
    orgLevel
    salesChannel
    autoDomains
    displayCurrency
    enableUploadPortal
    enableStreamDashboard
    enableSubValues
    enableCurrencyConversion
    enableSecondaryModifiers
    enableUnderwriterEdit
    enableRiskManagerAssessment
    enableCarrierAssessment
    excludeFromReporting
    enableSendEmailOnSOVUpload
    enableRedirectOnSOVUpload
    enabledFeatures
    industry
    businessType
    unitOfMeasurement
    showNonEnrichmentDocs
  }
`;

export const GET_ORGANIZATION_PAGE = gql`
  query GetOrganizationPage(
    $cursor: String
    $filters: [OrgAttributeFilter!]
    $sortBy: [Sort!]
    $pageSize: Int!
  ) {
    organizationsPage(cursor: $cursor, filters: $filters, sortBy: $sortBy, pageSize: $pageSize) {
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
      organizations {
        ...organization
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
