import React, { FC } from 'react';
import { FilePicker } from 'ui';

interface StepSixProps {
  documents: File[];
  setDocuments: React.Dispatch<React.SetStateAction<Array<File>>>;
}

export const StepSix: FC<StepSixProps> = ({ documents, setDocuments }) => (
    <>
      <FilePicker
        prompt="Drag and drop your files"
        data-testid="casualty-documents"
        files={documents}
        multiple
        onChange={(files: Array<File>) => {
          setDocuments(files);
        }}
      />
    </>
  );
