import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

interface Props {
  visible: boolean;
}

const ErrorP = styled.p<Props>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition-duration: 1s;
`;

const ErrorMessage = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ErrorP data-testid="stream-render-error-message" visible={visible}>
      No user code could be found for your account. Please try again later.
    </ErrorP>
  );
};

export default ErrorMessage;
