import * as Types from '../../jobs.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSovJobWorksheetMutationVariables = Types.Exact<{
  input: Types.GetSovJobWorksheetInput;
}>;


export type GetSovJobWorksheetMutation = { __typename?: 'Mutation', getSOVJobWorksheet: { __typename?: 'Worksheet', id: string, filename: string, httpURL: string, extension?: string | null } };


export const GetSovJobWorksheetDocument = gql`
    mutation getSOVJobWorksheet($input: GetSOVJobWorksheetInput!) {
  getSOVJobWorksheet(input: $input) {
    id
    filename
    httpURL
    extension
  }
}
    `;
export type GetSovJobWorksheetMutationFn = Apollo.MutationFunction<GetSovJobWorksheetMutation, GetSovJobWorksheetMutationVariables>;

/**
 * __useGetSovJobWorksheetMutation__
 *
 * To run a mutation, you first call `useGetSovJobWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSovJobWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSovJobWorksheetMutation, { data, loading, error }] = useGetSovJobWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSovJobWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<GetSovJobWorksheetMutation, GetSovJobWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSovJobWorksheetMutation, GetSovJobWorksheetMutationVariables>(GetSovJobWorksheetDocument, options);
      }
export type GetSovJobWorksheetMutationHookResult = ReturnType<typeof useGetSovJobWorksheetMutation>;
export type GetSovJobWorksheetMutationResult = Apollo.MutationResult<GetSovJobWorksheetMutation>;
export type GetSovJobWorksheetMutationOptions = Apollo.BaseMutationOptions<GetSovJobWorksheetMutation, GetSovJobWorksheetMutationVariables>;