import React from 'react';
import { Grade } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { CarrierPropertyAssessmentsData } from '@onarchipelago/cx/Reports/queries/getCarrierPropertyAssessments';
import { CarrierPropertyGradesData } from '@onarchipelago/cx/Reports/queries/getCarrierPropertyGrades';
import moment from 'moment';
import { formatCurrency } from '@app/utils/format';
import GradeBadge from '../GradeBadge';
import { GradingDetail, RiskAttribute, TableData } from '../types';

export interface Item {
  locationId: string;
  address: string;
  city: string;
  state: string;
  country: string;
  grade?: Grade;
  gradeCode?: string;
  gradeValue?: number;
  sicCode: number;
  sicDescription: string;

  // Grade fields
  tiv?: number;
  tivPd?: number;
  biRiskGrade?: string;
  pdRiskGrade?: string;

  // Assessment fields
  assessmentDate?: string;
  reLocationId?: string;
  riskGrade?: number;
}

const locationIdCol = {
  field: 'locationId',
  name: 'Location ID',
};

const reLocationIdCol = {
  field: 'reLocationId',
  name: 'RE Location ID',
};

const sicCodeCol = {
  field: 'sicCode',
  name: 'Zurich SIC Code',
};

const sicDescriptionCol = {
  field: 'sicDescription',
  name: 'Zurich SIC Description',
};

const tivCol = {
  field: 'tiv',
  name: 'TIV',
  render: (tiv: number) => formatCurrency(tiv),
};

const tivPDCol = {
  field: 'tivPd',
  name: 'TIV',
  render: (tivPd: number) => formatCurrency(tivPd),
};

const gradeCol = {
  field: 'grade',
  name: 'Grade',
  render: (grade: Grade | null) => (grade ? <GradeBadge grade={grade} filterable={false} /> : '-'),
};

const pdRiskGradeCol = {
  field: 'pdRiskGrade',
  name: 'Risk Grade PD',
};

export const getColumns = (riskAttribute: RiskAttribute) => {
  const cols = [
    riskAttribute.type === GradingDetail.Assessment ? reLocationIdCol : locationIdCol,
    {
      field: 'address',
      name: 'Address',
    },
    {
      field: 'city',
      name: 'City',
    },
    {
      field: 'state',
      name: 'State / Province',
    },
    {
      field: 'country',
      name: 'Country',
    },
  ];

  if (riskAttribute.type === GradingDetail.Assessment) {
    return [
      ...cols,
      {
        field: 'assessmentDate',
        name: 'Date Assessed',
      },
      gradeCol,
      {
        field: 'gradeCode',
        name: 'Risk Factor',
      },
      {
        field: 'riskGrade',
        name: 'Risk Grade',
      },
      sicCodeCol,
      sicDescriptionCol,
    ];
  }

  if (riskAttribute.value === 'RE_RISK_GRADE') {
    return [
      ...cols,
      gradeCol,
      pdRiskGradeCol,
      {
        field: 'biRiskGrade',
        name: 'Risk Grade BI',
      },
      tivCol,
    ];
  }

  if (riskAttribute.value === 'PREDICTIVE_RISK_GRADE') {
    return [...cols, gradeCol, pdRiskGradeCol, tivPDCol];
  }

  if (riskAttribute.value === 'HAZARD_GRADE') {
    return [
      ...cols,
      gradeCol,
      {
        field: 'gradeValue',
        name: 'Hazard Grade',
      },
      sicCodeCol,
      sicDescriptionCol,
      tivCol,
    ];
  }

  return [...cols, gradeCol, sicCodeCol, sicDescriptionCol, tivCol];
};

const gradeDetails = {
  CAT_EARTHQUAKE: 'earthquakeZoneGrade',
  CAT_FLOOD: 'floodGrade',
  CAT_HAIL: 'hailGrade',
  CAT_TORNADO: 'tornadoGrade',
  CAT_WINDSTORM: 'windstormGrade',
  HAZARD_GRADE: 'hazardGrade',
  HOT_WORK_CONTRACTOR_CONTROLS: 'hotWorkRiskFactor',
  HOUSEKEEPING: 'housekeepingRiskFactor',

  MAINTENANCE_PROCEDURES: 'maintenanceRiskFactor',
  MANAGEMENT_PRACTICES: 'managementRiskFactor',
  PREDICTIVE_RISK_GRADE: 'pdRiskGrade',
  RE_RISK_GRADE: 'reRiskGrade',
  SAFETY_TRAINING: 'trainingRiskFactor',
};

export const getCurrentGrade = (riskAttribute: string): string => gradeDetails[riskAttribute] || '';

interface PropertiesToPrioritizeData extends TableData<Item> {
  totalCount: number;
}

export const getItems = (
  riskAttribute: RiskAttribute,
  data: any,
): PropertiesToPrioritizeData | null => {
  if (!data) {
    return null;
  }

  const tableData: PropertiesToPrioritizeData = {
    columns: getColumns(riskAttribute),
    items: [],
    totalCount: 0,
  };

  const currentGradeField = getCurrentGrade(riskAttribute.value);

  if (riskAttribute.type === GradingDetail.Grade) {
    const gradeData = (data as CarrierPropertyGradesData).carrierPropertyGrades;
    return {
      ...tableData,
      // FIX ME
      // @ts-ignore
      items: gradeData.properties.map((p) => {
        const grade = p[currentGradeField] as Grade;

        return {
          address: p.street,
          biRiskGrade: p.biRiskGrade.value,
          city: p.city,
          country: p.country,
          grade,
          gradeCode: grade?.code,
          gradeValue: grade?.value,
          locationId: p.carrierPropertyId,
          pdRiskGrade: p.pdRiskGrade.value,
          sicCode: p.sicCode,
          sicDescription: p.sicDescription,
          state: p.state,
          tiv: p.tiv,
          tivPd: p.tivPd,
        };
      }),
      totalCount: gradeData.totalCount,
    };
  }

  const assessmentData = (data as CarrierPropertyAssessmentsData).carrierPropertyAssessments;
  return {
    ...tableData,
    items: assessmentData.assessments.map((p) => {
      const grade = p[currentGradeField] as Grade;
      return {
        address: p.street,
        assessmentDate: moment(p.assessmentDate).format('MM-DD-YYYY'),
        city: p.city,
        country: p.country,
        grade,
        gradeCode: grade?.code,
        gradeValue: grade?.value,
        locationId: p.carrierPropertyId,
        reLocationId: p.rePropertyId,
        riskGrade: p.riskGrade?.value,
        sicCode: p.sicCode,
        sicDescription: p.sicDescription,
        state: p.state,
      };
    }),
    totalCount: assessmentData.totalCount,
  };
};
