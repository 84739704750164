import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { EuiText } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { AttributeFilter } from '@app/graphql/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { TrackStreamPage, useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import GET_EXPLORERS from '@app/queries/explorers/getExplorers.gql';
import {
  IGetExplorersData,
  IGetExplorersVariables,
  IGraphQLExplorerGroup,
} from '@app/queries/explorers/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { usePageState } from '../../utils';
import { explorerTitles, getChart, getExplorerTitle } from './chartCatalog';
import {
  Anchor,
  ExplorerContainer,
  ExplorerTab,
  ExplorerTabContainer,
  TabContent,
} from './ExplorersContainer.emotion';
import ExplorerTitle from './ExplorerTitle';
import { getSnapshotLabel } from './utils';

type NullOr<T> = null | T;

interface IExplorersContainerProps {
  stream: IGraphQLStream;
  filters: Array<AttributeFilter>;
  explorerTab: NullOr<string>;
  position: NullOr<string>;
  changeTab: (tab: string) => void;
}

const ExplorersContainer: FC<IExplorersContainerProps> = ({
  stream,
  filters,
  explorerTab,
  position,
  changeTab,
}) => {
  const [activeTab, setActiveTab] = useState<string>(explorerTab || 'Standard');
  const [hasScrolled, setHasScrolled] = useState(false);
  const [{ currentSnapshot, changesSince }] = usePageState();
  const mixpanel = useTracker('mixpanel');

  // Create a static number of refs to be used later
  // Since we are using hooks the refs can't be dynamically created after we get the data
  const tabRefs = {
    0: useRef<HTMLDivElement>(null),
    1: useRef<HTMLDivElement>(null),
    2: useRef<HTMLDivElement>(null),
    3: useRef<HTMLDivElement>(null),
  };

  const chartRefs = Object.entries(explorerTitles).reduce((acc, [key]) => {
    acc[key] = useRef<HTMLDivElement>(null);
    return acc;
  }, {});

  useEffect(() => {
    if (explorerTab) {
      setActiveTab(explorerTab.toLowerCase());
    }
  }, [explorerTab]);

  useEffect(() => {
    setHasScrolled(false);
  }, [position]);

  useEffect(() => {
    const chartId = position ? position.split('-').join('_').toLowerCase() : '';
    const tabRef = tabRefs[activeTab];
    const chartRef = chartRefs[chartId];
    const HEADER_HEIGHT = 100;

    if (!hasScrolled && tabRef?.current && chartRef?.current) {
      setHasScrolled(true);
      tabRef.current.scrollTo(0, chartRef.current.offsetTop - HEADER_HEIGHT);
    }
  }, [position, tabRefs, chartRefs]);

  const handleTabSelect = (selectedTab: string) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    mixpanel.track(`${TrackStreamPage.prefix}: Change Explorer Tab`, {
      event_surface: TrackStreamPage.event_surface,
      from: activeTab,
      organization_name: stream.orgName,
      stream_name: stream.name,
      stream_slug: stream.slug,
      to: selectedTab,
    });

    changeTab(selectedTab);
  };

  const { loading, error, data } = useStandardQuery<IGetExplorersData, IGetExplorersVariables>(
    GET_EXPLORERS,
    {
      variables: {
        filter: filters,
        fromSnapshot: changesSince,
        streamSlug: stream.slug,
        toSnapshot: currentSnapshot,
      },
    },
    {
      errorMessage: (err) => ({
        additionalLoggingInformation: [
          'Error in ExplorersContainer',
          `Query: ${GET_EXPLORERS}`,
          `Error: ${JSON.stringify(err)}`,
        ].join('\n'),
        message: err?.message,
      }),
    },
  );

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  if (error || !data) {
    return null;
  }

  const {
    explorers: { groups, currentSnapshot: snapshot, previousSnapshot },
  } = data;

  const tabData = groups.map((item) => ({ ...item, group: item?.group?.toLowerCase() }));

  const previousSnapshotLabel = getSnapshotLabel(stream.snapshots, previousSnapshot);
  const currentSnapshotLabel = getSnapshotLabel(stream.snapshots, snapshot);
  // Map the tabs to refs
  tabData.forEach((tab, idx) => {
    tabRefs[tab.group] = tabRefs[idx];
    delete tabRefs[idx];
  });

  // Show the first tab by default if we can't match on the querystring or props
  if (!tabData.map((tab) => tab.group).includes(activeTab || '')) {
    setActiveTab(tabData[0]?.group);
  }

  const Tabs: React.FC<{ tabs: Array<IGraphQLExplorerGroup> }> = ({ tabs }) => (
    <>
      {tabs.map(({ group }) => (
        <ExplorerTab key={group} active={group === activeTab} onClick={handleTabSelect(group)}>
          <EuiText>
            <p>{group.toUpperCase()}</p>
          </EuiText>
        </ExplorerTab>
      ))}
    </>
  );

  return (
    <>
      <ExplorerTabContainer>
        <Anchor data-tourid="explorer-anchor" />
        <Tabs tabs={tabData} />
      </ExplorerTabContainer>
      <div data-tourid="explorers" data-testid="explorers">
        {tabData?.map(({ group, charts }) => {
          const groupName = group;
          return (
            <TabContent
              key={groupName}
              data-tourid={`${groupName}-explorers`}
              active={groupName === activeTab}
              ref={tabRefs[groupName]}
            >
              {charts.map((explorer) => {
                const { name } = explorer;
                const chart = getChart(explorer);
                if (!chart) {
                  return null;
                }
                const title = getExplorerTitle(name);

                const Explorer = chart.Explorer;
                const explorerID = title.split(' ').join('-').toLowerCase();
                return (
                  <ExplorerContainer
                    height={chart.height}
                    data-tourid={`${explorerID}-explorer`}
                    data-testid={`${explorerID}-explorer`}
                    ref={chartRefs[name]}
                    key={name}
                  >
                    <ExplorerTitle title={title} />
                    <Explorer
                      explorer={explorer}
                      currentSnapshotLabel={currentSnapshotLabel}
                      previousSnapshotLabel={previousSnapshotLabel}
                      changeAnalysis={!!changesSince}
                    />
                  </ExplorerContainer>
                );
              })}
            </TabContent>
          );
        })}
      </div>
    </>
  );
};

export default ExplorersContainer;
