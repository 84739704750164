import gql from 'graphql-tag';

export const GET_SUBMISSION_ATTRIBUTE_METADATA = gql`
  query GetSubmissionAttributeMetadata($input: SubmissionAttributeMetadataInput!) {
    submissionAttributeMetadata(input: $input) {
      displayName
      enabled
      enumEditable
      enumValues {
        displayName
        enabled
        value
      }
      group
      name
      required
      derived
      type
    }
  }
`;
