import Highcharts from 'highcharts';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { addRegionTotals, findDataByGroupLabel } from './utils';

function addPlaceholderData(data: [], maxLength: number, name: string = 'Tier 1') {
  const dataLength = data.length;
  const placeholderArr =
    dataLength >= maxLength
      ? data
      : [...new Array(maxLength - dataLength)].map((placeholder, index) => ({
          name,
          region: index,
          value: '',
          valueRaw: 0,
        }));

  return [...data, ...placeholderArr];
}

function formatWindOptions(mapData: RiskSummaryChartData) {
  const dataExtractMaxLength = 10;
  const groupLabels = ['Tier 2', 'Tier 1'];
  const dataExtract = mapData?.values.slice(0, dataExtractMaxLength);

  const tier1Data = findDataByGroupLabel(groupLabels[1], dataExtract);
  const tier2Data = findDataByGroupLabel(groupLabels[0], dataExtract);

  const tier1DataWithPlaceholders = addPlaceholderData(tier1Data, 10, groupLabels[1]);
  const tier2DataWithPlaceholders = addPlaceholderData(tier2Data, 10, groupLabels[0]);

  // Here we will always have an array of 20 items.
  const dataWithPlaceholders = [...tier1DataWithPlaceholders, ...tier2DataWithPlaceholders].filter(
    Boolean,
  );

  const dataSortedByRegionTotals = dataWithPlaceholders
    .map((dataItem, index, arr) => addRegionTotals(dataItem, arr))
    .sort((a, b) => Number(b.regionTotal) - Number(a.regionTotal));

  // If we don't have 10 regions we need to add placeholders
  const regionsSorted = dataSortedByRegionTotals.map((dataItem) => dataItem.region);
  const regionsNoDupes = [...new Set(regionsSorted)].slice(0, 10);

  const colors = ['#C1D596', '#008169'];

  const series = groupLabels.map((tier) => {
    const seriesData = dataSortedByRegionTotals
      .filter((seriesItem) => seriesItem.tier === tier)
      .map(({ region, name, pctChange, pctOfTotal, value, properties }) => ({
        name,
        pctChange,
        pctOfTotal,
        properties,
        region,
        value,
        y: pctOfTotal,
      }));

    // Add placeholders when there's not enough data.
    const seriesDataLen = seriesData.length;
    const seriesDataPlaceholders =
      seriesDataLen >= 10
        ? seriesData
        : [...new Array(10 - seriesDataLen)].map((placeholder, index) => ({
            name: '',
            pctOfChange: 0,
            pctOfTotal: 0,
            properties: 0,
            region: index,
            value: '',
            y: 0,
          }));

    const seriesDataWithPlaceholders = [...seriesData, ...seriesDataPlaceholders].filter(Boolean);

    return {
      data: seriesDataWithPlaceholders,
      name: '',
    };
  });

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },

    colors,

    legend: {
      enabled: false,
    },

    plotOptions: {
      column: {
        maxPointWidth: 40,
        pointWidth: 90,
      },

      series: {
        dataLabels: {},
        stacking: 'normal',
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series,
    subtitle: {
      align: 'left',
      style: {
        color: '#000',
        marginBottom: 8,
        marginTop: 8,
      },
      text: '<br/><div style="display: inline-block;margin-right: 5px;width: 10px; height: 10px; background-color: #008169;"></div>Tier 1 <div style="display: inline-block;margin-left: 8px;margin-right: 5px;width: 10px; height: 10px; background-color: #C1D596;"></div>Tier 2<br/><div style="margin-top: 1rem;margin-bottom: 1rem;">% of TIV</div>',
      useHTML: true,
    },

    title: {
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      text: 'Top Regions by Wind Exposure',
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: '#000',
      borderRadius: 4,
      headerFormat: '',
      pointFormat:
        '<span style="font-size:13px;font-weight: bold;color:#fff;">{point.region} {point.name}</span><br>' +
        '<span style="color:#fff;">{point.value} TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctOfTotal}% of TIV</span><br/>' +
        '<span style="color:#fff;">{point.pctChange}% change since prev. bound</span><br/>' +
        '<span style="color:#fff;">{point.properties} properties</span><br/>',
    },

    xAxis: {
      categories: regionsNoDupes,
      labels: {
        formatter() {
          const { value } = this;

          if (typeof value !== 'number') {
            return value.toString();
          }

          return '';
        },
      },
    },

    yAxis: {
      labels: {
        formatter() {
          return `${this.value}%`;
        },
      },
      title: {
        text: '',
      },
    },
  };

  return chartOptions;
}

export { formatWindOptions };
