import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';

const BenchmarkKey: FC = () => {
  const Swatch: FC<{ color: string }> = ({ color }) => (
    <div style={{ backgroundColor: color, height: 9, marginRight: 5, width: 9 }} />
  );

  const items = [
    { color: '#21B59A', label: `Enriched ${STREAMS_LABEL}s` } /* $euiColorSuccess */,
    { color: '#E0E6EB', label: `Other ${STREAMS_LABEL}s` } /* $euiColorLightShade */,
  ];
  return (
    <EuiFlexGroup
      data-testid="benchmark-key"
      gutterSize="none"
      justifyContent="spaceBetween"
      style={{ flexGrow: 0 }}
    >
      {items.map((item) => (
        <EuiFlexItem key={item.label} grow={2}>
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem grow={false}>
              <Swatch color={item.color} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs">
                <small>{item.label}</small>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ))}
      <EuiFlexItem grow={1} />
    </EuiFlexGroup>
  );
};

export default BenchmarkKey;
