import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationDocumentsPageQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OrgDocumentFilter>;
}>;


export type OrganizationDocumentsPageQuery = { __typename?: 'Query', organizationDocumentsPage?: { __typename?: 'DocumentsPage', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, cursor: string, totalCount: number }, documents: Array<{ __typename?: 'Document', id: string, classification?: string | null, createdAt: any, extension?: string | null, fileType?: string | null, filename: string, httpURL: string, createdBy: { __typename?: 'UserProfile', givenName?: string | null, familyName?: string | null }, properties?: Array<{ __typename?: 'Property', id: string, locationName?: string | null }> | null }> } | null };


export const OrganizationDocumentsPageDocument = gql`
    query organizationDocumentsPage($cursor: String, $filter: OrgDocumentFilter) {
  organizationDocumentsPage(cursor: $cursor, filter: $filter) {
    pageInfo {
      hasNextPage
      cursor
      totalCount
    }
    documents {
      id
      classification
      createdAt
      createdBy {
        givenName
        familyName
      }
      extension
      fileType
      filename
      httpURL
      properties {
        id
        locationName
      }
    }
  }
}
    `;

/**
 * __useOrganizationDocumentsPageQuery__
 *
 * To run a query within a React component, call `useOrganizationDocumentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDocumentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDocumentsPageQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationDocumentsPageQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationDocumentsPageQuery, OrganizationDocumentsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationDocumentsPageQuery, OrganizationDocumentsPageQueryVariables>(OrganizationDocumentsPageDocument, options);
      }
export function useOrganizationDocumentsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationDocumentsPageQuery, OrganizationDocumentsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationDocumentsPageQuery, OrganizationDocumentsPageQueryVariables>(OrganizationDocumentsPageDocument, options);
        }
export type OrganizationDocumentsPageQueryHookResult = ReturnType<typeof useOrganizationDocumentsPageQuery>;
export type OrganizationDocumentsPageLazyQueryHookResult = ReturnType<typeof useOrganizationDocumentsPageLazyQuery>;
export type OrganizationDocumentsPageQueryResult = Apollo.QueryResult<OrganizationDocumentsPageQuery, OrganizationDocumentsPageQueryVariables>;