import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const AccordionListItem = styled.li`
  list-style: inside circle;
`;

export const ListItem = styled.li`
  list-style: inside;
`;

export const Panel = styled(EuiPanel)`
  overflow: auto;
  padding: 8px !important;
`;
