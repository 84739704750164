import { useEffect } from 'react';

type UseTimedEffect = (callback: () => void, delay: number, diff?: Array<any>) => void;

export const useTimedEffect: UseTimedEffect = (callback, delay, diff = []) =>
  useEffect(() => {
    const timer = setTimeout(() => callback(), delay);
    return () => clearTimeout(timer);
  }, diff);

export default useTimedEffect;
