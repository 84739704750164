import { AttritionalReason, CATReasons, COPEReason, ValuationReason } from '../context/types';

export const colorMap = {
  [CATReasons.earthquake]: '#AA6556',
  [COPEReason.cope]: '#69707D',
  [CATReasons.wind]: 'primary',
  [CATReasons.flood]: '#0077CC',
  [CATReasons.storm]: '#7C609E',
  [AttritionalReason.attritional]: '#BD271E',
  [ValuationReason.valuation]: '#FEA273',
};
