export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  ArchipelagoID: { input: any; output: any; }
  AttributeName: { input: any; output: any; }
  Date: { input: any; output: any; }
  Time: { input: any; output: any; }
  UInt64: { input: any; output: any; }
  /** The `Upload` scalar type represents a multipart file upload. This is a built-in type in `gqlgen` */
  Upload: { input: any; output: any; }
};

export type AcceptPreCheckRecommendationsInput = {
  orgName: Scalars['String']['input'];
};

export type AcceptPreCheckRecommendationsResponse = {
  __typename?: 'AcceptPreCheckRecommendationsResponse';
  status: PreCheckRecommendationStatus;
};

export type AccessRequest = {
  __typename?: 'AccessRequest';
  createdAt: Scalars['Time']['output'];
  streamSlug: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
};

export type AccessRequestsInput = {
  streamSlug: Scalars['String']['input'];
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export enum AccountExplorersSectionName {
  Exposure = 'EXPOSURE',
  Performance = 'PERFORMANCE',
  Quality = 'QUALITY',
  Top = 'TOP'
}

export enum AccountOrgType {
  Client = 'Client',
  Prospect = 'Prospect'
}

export type AccountRole = {
  __typename?: 'AccountRole';
  /**  The ID of the account  */
  accountID: Scalars['ID']['output'];
  /**  The name of the account  */
  accountName: Scalars['String']['output'];
  /**  If the account belongs to an enterprise then this will contain the ID of the enterprise account. If the account is an enterprise account then this will be empty.  */
  enterpriseAccountID?: Maybe<Scalars['ID']['output']>;
  /**  If the account belongs to an enterprise then this will contain the name of the enterprise account. If the account is an enterprise account then this will be empty.  */
  enterpriseAccountName?: Maybe<Scalars['String']['output']>;
  /**  Boolean indicator whether the role was automatically provisioned through SSO  */
  isSSOProvisioned: Scalars['Boolean']['output'];
  /**  If the role is PropertyEditor then this will contain the list of properties. If the role is not PropertyEditor then this will be empty.  */
  properties: Array<Property>;
  /**  The role of the user in the account  */
  role: Role;
  /**  If the role is SOV scoped then this will contain the name of the SOV. If the role is not SOV scoped then this will be empty.  */
  stream?: Maybe<Stream>;
};

export type AccountUsers = {
  __typename?: 'AccountUsers';
  profile: UserProfile;
  roles?: Maybe<Array<AccountRole>>;
  userID: Scalars['ID']['output'];
};

export type AccountUsersInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orgName: Scalars['String']['input'];
  /** List all users or users belonging to a specific Enterprise Org or Underlying Customer Org. When empty all orgs are considered. */
  orgNameFilter?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search users by their role. When empty all roles are considered. */
  roleFilter?: InputMaybe<Array<RoleFilterEnum>>;
  /** Search for name or email */
  search?: InputMaybe<Scalars['String']['input']>;
  /**
   * sorting on the following fields:
   * - "name" (LastName -> First Name) Nil last with ASC, This is also the default sort when this field is empty.
   * - "email"
   * - "role" (Only supported when this is an non enterprise organization, since within enterprise organizations users can have multiple roles.)
   * - "last_login"
   */
  sortBy?: InputMaybe<Sort>;
};

export type AccountUsersOutput = {
  __typename?: 'AccountUsersOutput';
  totalCount: Scalars['Int']['output'];
  users: Array<AccountUsers>;
};

export type AccountsExplorersInput = {
  filters?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
};

export type AccountsExplorersOutput = {
  __typename?: 'AccountsExplorersOutput';
  filteredAccountsCount: Scalars['Int']['output'];
  filteredInsuredValue: Scalars['Float']['output'];
  filteredLocationsCount: Scalars['Int']['output'];
  sections?: Maybe<Array<AccountsExplorersSection>>;
  totalAccountsCount: Scalars['Int']['output'];
  totalInsuredValue: Scalars['Float']['output'];
  totalLocationsCount: Scalars['Int']['output'];
};

export type AccountsExplorersSection = {
  __typename?: 'AccountsExplorersSection';
  charts: Array<Chart>;
  name: AccountExplorersSectionName;
};

/** Actions listed below are in order of scope. */
export enum Action {
  Create = 'Create',
  Delete = 'Delete',
  Edit = 'Edit',
  List = 'List',
  View = 'View'
}

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  /** Attributes involved in the event. */
  attributes?: Maybe<Array<AttributeMetadata>>;
  /**  Email of the user who performed the activity.  */
  email: Scalars['String']['output'];
  /**  Details in JSON format which are specific to the event. These differ depending on the event name.  */
  eventDetails: Scalars['String']['output'];
  /**  Unique identifier for the event.  */
  eventID: Scalars['String']['output'];
  /**  Name of the activity event.  */
  eventName: Scalars['String']['output'];
  /**  Name of the user who performed the activity.  */
  name?: Maybe<Scalars['String']['output']>;
  organizationID: Scalars['String']['output'];
  /**
   * Returns property details the properties involved in the event.
   * If more than 5 properties were involved, this field will be null.
   */
  properties?: Maybe<Array<Property>>;
  /** Returns the number of properties involved in the event. */
  propertiesCount: Scalars['Int']['output'];
  streamSlugRandomIdentifier: Scalars['String']['output'];
  timestamp: Scalars['Time']['output'];
  /**  ID of the user who performed the activity.  */
  userID: Scalars['String']['output'];
};

export type ActivityFilterInput = {
  /** If provided, restricts the events returned to those with a name in the provided list. */
  eventNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddDocumentToStreamInput = {
  documentID: Scalars['ID']['input'];
  /**  A stream specific grouping for documents. Sample values: 'All docs zip', 'Marketing material', 'Archipelago info', 'Data exports', 'None'  */
  streamGroup: Scalars['String']['input'];
  streamID: Scalars['ID']['input'];
};

export type AddDomainAllowListInput = {
  default: Scalars['Boolean']['input'];
  streamSlug: Scalars['ID']['input'];
  userEmailDomain: Scalars['String']['input'];
};

export type AddEditorCommentInput = {
  attributeName?: InputMaybe<Scalars['String']['input']>;
  propertyArchipelagoID: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
  /**
   * Comment text. Can contain users tagged by email, accepted format is @<email address>.
   *   Any matching editor users in the text will receive email notification about this comment.
   */
  text: Scalars['String']['input'];
};

export type AddEditorCommentResponse = {
  __typename?: 'AddEditorCommentResponse';
  comment: EditorComment;
};

export type AddOrUpdateLossInput = {
  /** Archipelago ID of the property. Mandatory on creation. */
  archipelagoId?: InputMaybe<Scalars['ID']['input']>;
  /** Archipelago ID of the loss. For new losses this will be created by the system. */
  archipelagoLossId?: InputMaybe<Scalars['String']['input']>;
  /**  Job ID creating/updating the loss  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  loss: LossDataInput;
  programId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddOrUpdatePolicyInput = {
  archipelagoPolicyId?: InputMaybe<Scalars['String']['input']>;
  /**  Job ID creating/updating the policy  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  policy: PolicyDataInput;
  programId: Scalars['ID']['input'];
};

export type AddOrgToDomainInput = {
  domain: Scalars['String']['input'];
  orgID: Scalars['ID']['input'];
};

export type AddOrgToDomainInputV2 = {
  domain: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type AddScopeGroupMemberInput = {
  /** Id of the group to which to add members. */
  groupId: Scalars['ID']['input'];
  /** Identifier of the members to be added to the group. */
  memberIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddSubmissionApiClientsInput = {
  /**  Client principals to be granted access to the organization's submission account orgs  */
  ClientPrincipals: Array<Scalars['String']['input']>;
  /**  ID of the dashboard organization  */
  organizationID: Scalars['String']['input'];
};

export type AddSubmissionApiClientsResponse = {
  __typename?: 'AddSubmissionAPIClientsResponse';
  /**  Principals which are granted access to the organization's submission account orgs  */
  clientPrincipals: Array<Scalars['String']['output']>;
  /**  ID of the dashboard organization  */
  organizationID: Scalars['String']['output'];
};

export type AddValuationPartnerDataInput = {
  archipelagoId: Scalars['String']['input'];
  buildingReplacementCostPartner: Scalars['Float']['input'];
};

export type AirModelResultsInput = {
  orgID: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type AirModelResultsResponse = {
  __typename?: 'AirModelResultsResponse';
  changeInGrossAALPercent?: Maybe<Scalars['Int']['output']>;
  changeInGroundUpAALPercent?: Maybe<Scalars['Int']['output']>;
  changeInTIVPercent?: Maybe<Scalars['Int']['output']>;
  currentGrossAALValue?: Maybe<Scalars['Int']['output']>;
  currentGroundUpAALValue?: Maybe<Scalars['Int']['output']>;
  currentTIV?: Maybe<Scalars['Int']['output']>;
  orgID: Scalars['String']['output'];
  sovGrossAALValue?: Maybe<Scalars['Int']['output']>;
  sovGroundUpAALValue?: Maybe<Scalars['Int']['output']>;
  sovTIV?: Maybe<Scalars['Int']['output']>;
  streamSlug: Scalars['String']['output'];
};

export type AnalyticsConfig = {
  __typename?: 'AnalyticsConfig';
  dashboards?: Maybe<Array<Dashboard>>;
  insights?: Maybe<Array<Insight>>;
  name: Scalars['String']['output'];
  workspaceID: Scalars['String']['output'];
};

export type AnalyticsConfigInput = {
  analyticsType: AnalyticsType;
  orgName: Scalars['String']['input'];
};

export type AnalyticsConfigResponse = {
  __typename?: 'AnalyticsConfigResponse';
  analyticsConfig: AnalyticsConfig;
  enterpriseReportingHostURL: Scalars['String']['output'];
  signedJWT: Scalars['String']['output'];
};

export type AnalyticsFilter = {
  __typename?: 'AnalyticsFilter';
  id: Scalars['String']['output'];
};

export enum AnalyticsType {
  CiTest = 'CI_TEST',
  DataJourney = 'DATA_JOURNEY'
}

export type ApplyMappingRulesToOrganizationInput = {
  /**  Boolean to set whether to update changes to the properties or just return the changes that would be made  */
  applyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ValidateFilterByLabel>;
  /**  The stream to apply the mapping rules to  */
  orgId: Scalars['String']['input'];
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ApplyMappingRulesToPropertiesInput = {
  /**  Boolean to set whether to update changes to the properties or just return the changes that would be made  */
  applyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  /**  The properties to apply the mapping rules to  */
  archipelagoIDs: Array<Scalars['String']['input']>;
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ApplyMappingRulesToPropertiesResult = {
  __typename?: 'ApplyMappingRulesToPropertiesResult';
  /**  The properties that the mapping rules were applied to  */
  changes?: Maybe<Array<PropertyChanges>>;
};

export type ApplyMappingRulesToStreamInput = {
  /**  Boolean to set whether to update changes to the properties or just return the changes that would be made  */
  applyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
  /**  The stream to apply the mapping rules to  */
  streamSlug: Scalars['String']['input'];
};

export enum ApplyMode {
  All = 'ALL',
  DisableOnly = 'DISABLE_ONLY',
  EnableOnly = 'ENABLE_ONLY'
}

export type ApproveJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['ID']['input'];
};

export type ArchiveProjectInput = {
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type AssignRoleAllScopeValuesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
};

export type AssignRoleInputV2 = {
  filter?: InputMaybe<RoleFilterInput>;
  principalId: Scalars['String']['input'];
  principalType?: InputMaybe<PrincipalType>;
  role: Role;
  scope: ScopeInput;
};

export type AssignRoleOutput = {
  __typename?: 'AssignRoleOutput';
  /** True if an existing assignment was updated */
  found: Scalars['Boolean']['output'];
  roleAssignment: RoleAssignmentV2;
};

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Value of an attribute and comparison to its prior value in the last bound snapshot. */
export type AttributeChange = {
  __typename?: 'AttributeChange';
  displayName?: Maybe<Scalars['String']['output']>;
  pctChange?: Maybe<Scalars['Float']['output']>;
  pctOfTotal?: Maybe<Scalars['Float']['output']>;
  priorPctOfTotal?: Maybe<Scalars['Float']['output']>;
  priorValue?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export type AttributeChanges = {
  __typename?: 'AttributeChanges';
  attributeName: Scalars['String']['output'];
  new?: Maybe<Scalars['Any']['output']>;
  old?: Maybe<Scalars['Any']['output']>;
};

export type AttributeDiscussion = {
  __typename?: 'AttributeDiscussion';
  attributeDisplayName: Scalars['String']['output'];
  attributeName: Scalars['String']['output'];
  chatRoomID: Scalars['String']['output'];
  chatRoomName: Scalars['String']['output'];
};

export type AttributeEditInfo = {
  __typename?: 'AttributeEditInfo';
  attributeName: Scalars['String']['output'];
  author: Scalars['String']['output'];
  date: Scalars['Time']['output'];
};

/**
 * Standard attribute filter input. Used in the Stream and Property context. Rather than enforcing
 * correct values for 'name' in the GraphQL type we enforce it in the API with the Stream/Property
 * configured metadata.
 */
export type AttributeFilter = {
  name: Scalars['String']['input'];
  operator?: InputMaybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['input']>;
};

export type AttributeFilterOutput = {
  __typename?: 'AttributeFilterOutput';
  name: Scalars['String']['output'];
  operator?: Maybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['output']>;
};

export type AttributeGroup = {
  __typename?: 'AttributeGroup';
  attributes: Array<AttributeChange>;
  /** Stats which apply to the set of data summarized by this stat's label and attributes. */
  groupedBy?: Maybe<Array<AttributeGroup>>;
  label: Scalars['String']['output'];
};

export type AttributeLockConflict = {
  __typename?: 'AttributeLockConflict';
  /**  True if the lock is on all property attributes.  */
  allAttributesLocked: Scalars['Boolean']['output'];
  /**  Names of attributes which conflict with the requested attributes. Empty if allAttributesLocked is true.  */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  groupID: Scalars['String']['output'];
  propertyID: Scalars['ArchipelagoID']['output'];
};

export type AttributeLocks = {
  __typename?: 'AttributeLocks';
  /**  True if the property is locked for all attributes.  */
  allAttributesLocked: Scalars['Boolean']['output'];
  /**  Names of attributes which are locked, if all attributes are not locked.  */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**  Archipelago ID of the property for which these locks apply.  */
  propertyArchipelagoID: Scalars['String']['output'];
};

export type AttributeMetadata = {
  __typename?: 'AttributeMetadata';
  columnPosition: Scalars['Int']['output'];
  dataType: Scalars['String']['output'];
  /** Minimal number of trailing decimals to display in the UI (optional) */
  decimals?: Maybe<Scalars['Int']['output']>;
  derived: Scalars['Boolean']['output'];
  dimension?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  /** Column width multiplied by 16 in the grid UI */
  displayWidth: Scalars['Int']['output'];
  /** List of values for this attribute. */
  enumMetadata?: Maybe<Array<EnumValueMetadata>>;
  filterable: Scalars['Boolean']['output'];
  groupable: Scalars['Boolean']['output'];
  hiddenInGrid: Scalars['Boolean']['output'];
  includeInStreams: Scalars['Boolean']['output'];
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  /** Max threshold to display in the UI if the value crosses it */
  maxThreshold?: Maybe<Scalars['Int']['output']>;
  /** Unique name of this attribute used to reference in other APIs  e.g. 'locationId' */
  name: Scalars['ID']['output'];
  /** Name of parent attribute. Value is null for top level attributes. */
  parent?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  secondaryModifier?: Maybe<SecondaryModifier>;
  source?: Maybe<Scalars['String']['output']>;
  subdimension?: Maybe<Scalars['String']['output']>;
};

export type AttributeMetadataInput = {
  columnPosition?: InputMaybe<Scalars['Int']['input']>;
  dataType?: InputMaybe<DataTypeInput>;
  dimension?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  displayWidth?: InputMaybe<Scalars['Int']['input']>;
  /** Flag that decides if an attribute can be filtered */
  filterable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag that decides if an attribute can be grouped */
  groupable?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenInGrid: Scalars['Boolean']['input'];
  /** Flag to display or hide the attribute in the stream view */
  includeInStreams?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  /** Property source text (optional) */
  source?: InputMaybe<Scalars['String']['input']>;
  subdimension?: InputMaybe<Scalars['String']['input']>;
};

export type AttributeRecommendation = {
  __typename?: 'AttributeRecommendation';
  definition?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AttributeSchema = {
  __typename?: 'AttributeSchema';
  columnPosition: Scalars['Int']['output'];
  dbName?: Maybe<Scalars['String']['output']>;
  metadata: AttributeMetadata;
  priorityInSimplifiedExport?: Maybe<Scalars['Int']['output']>;
  storageType?: Maybe<Scalars['String']['output']>;
};

export type AttributeScoreBenchmark = {
  __typename?: 'AttributeScoreBenchmark';
  scoreQuality: ScoreQuality;
  tivPct: Scalars['Float']['output'];
};

export type AttributeScoreBreakdown = {
  __typename?: 'AttributeScoreBreakdown';
  locations: Scalars['Int']['output'];
  scoreQuality: ScoreQuality;
  tiv: Scalars['Float']['output'];
  tivPct: Scalars['Float']['output'];
};

export type AttributeScoreInputIt = {
  attributeName: Scalars['String']['input'];
  category: Scalars['String']['input'];
  organizationID: Scalars['ID']['input'];
  potential: Scalars['Float']['input'];
  propertyScoreID: Scalars['ID']['input'];
  qualityFlag: Scalars['Float']['input'];
  score: Scalars['Float']['input'];
  scoreMethodID: Scalars['ID']['input'];
};

export type AttributeScoreResultIt = {
  __typename?: 'AttributeScoreResultIT';
  attributeName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organizationID?: Maybe<Scalars['ID']['output']>;
  potential?: Maybe<Scalars['Float']['output']>;
  propertyScoreID?: Maybe<Scalars['ID']['output']>;
  qualityFlag?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreMethodID?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type Authorization = {
  __typename?: 'Authorization';
  authorized: Scalars['Boolean']['output'];
  /** If set, the authorization is subject to this filter. */
  filter?: Maybe<RoleFilter>;
};

export type AuthorizationConfig = {
  __typename?: 'AuthorizationConfig';
  /** DEPRECATED */
  accountAdminToManageAccountAdminDisabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Setting for Enterprise Orgs to automatically configure additional Market Access domains. The
   * values in this setting will be used as an extra way of populating the underlying org's stream
   * market access domain allow list. The other way being the default allowed domains list.
   */
  marketAccessDomains?: Maybe<Array<Scalars['String']['output']>>;
  orgId: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  /**
   * ConfigureAuthorizationInput.ssoConfigName is the name of the SAML connection in Auth0 that is
   * used to configure SSO for customers. Configuring SSO in Auth0 allows customers to use their own
   * identity provider (IdP) to log in to the platform. By configuring
   * ConfigureAuthorizationInput.ssoConfigName in the authorization service the platform automatically
   * grants the user roles to the customer's organization which gives access to organization's stream
   * data. Granting roles, or automatically role provisioning, is based on the metadata that the IdP
   * provides. If no role metadata is provided by the IdP then the user won't get access. Currently
   * only RiskManager is supported.
   *
   * Setting this value won't configure SSO in Auth0.
   */
  ssoConfigName?: Maybe<Scalars['String']['output']>;
};

export type BBox = {
  northEast: InputCoordinates;
  southWest: InputCoordinates;
};

export type BrcRange = {
  __typename?: 'BRCRange';
  /**  Total building replacement cost for properties with a valuation difference within the range  */
  buildingReplacementCost: Scalars['Float']['output'];
  /**  Display name for the building replacement cost range (e.g. -100% to -75%)  */
  displayName: Scalars['String']['output'];
  /**  Number of properties with a valuation difference within the range  */
  propertyCount: Scalars['Int']['output'];
};

export type BasicChart = Chart & {
  __typename?: 'BasicChart';
  aggregates: Array<ChartAggregation>;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  values: Array<BasicChartStat>;
};

export type BasicChartStat = {
  __typename?: 'BasicChartStat';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  pct: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type BasicComparisonChart = Chart & {
  __typename?: 'BasicComparisonChart';
  current: ComparisonStat;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pctDifference?: Maybe<Scalars['Float']['output']>;
  previous?: Maybe<ComparisonStat>;
};

export type BatchProcessRolesInput = {
  grants?: InputMaybe<Array<AssignRoleInputV2>>;
  revokes?: InputMaybe<Array<RemoveScopesInput>>;
};

export enum BinaryOperator {
  BeginsWith = 'BEGINS_WITH',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  In = 'IN',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  Less = 'LESS',
  LessOrEqual = 'LESS_OR_EQUAL',
  NotBeginsWith = 'NOT_BEGINS_WITH',
  NotBetween = 'NOT_BETWEEN',
  NotContains = 'NOT_CONTAINS',
  NotEndsWith = 'NOT_ENDS_WITH',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN'
}

export type CalculateStreamExposureIndexInput = {
  /**
   * For retry integration testing only.
   * If set, induces an artificial index calculation error for the number of times specified.
   */
  failFor?: InputMaybe<Scalars['Int']['input']>;
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type CalculateStreamExposureIndexResult = {
  __typename?: 'CalculateStreamExposureIndexResult';
  /**  Time calculation finished if it is not still in-progress.  */
  completionTime?: Maybe<Scalars['Time']['output']>;
  /**  Time calculation started  */
  startTime?: Maybe<Scalars['Time']['output']>;
  /**
   * Status of the stream exposure index calculation.
   * Returns status for the existing stream exposure index calculation if one already exists.
   */
  status: IndexCalculationStatus;
};

export type CarrierAssessmentInfo = {
  __typename?: 'CarrierAssessmentInfo';
  carrierId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CarrierAssessmentInput = {
  name: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
};

export type CarrierAssessmentListResult = {
  __typename?: 'CarrierAssessmentListResult';
  assessments: Array<CarrierAssessmentInfo>;
};

export type CarrierAssessmentResult = {
  __typename?: 'CarrierAssessmentResult';
  carrierId: Scalars['ID']['output'];
  carrierLogo?: Maybe<Scalars['String']['output']>;
  /**  Mapping of grade categories to colors contained in the report  */
  gradeSchemes?: Maybe<Array<GradeScheme>>;
  headerText?: Maybe<Scalars['String']['output']>;
  /**  Loss frequency grouped by geographic region  */
  lossFrequencyGeography?: Maybe<LossGeography>;
  /**  Histogram of loss counts grouped by value range  */
  lossFrequencyHistogram?: Maybe<Histogram>;
  /**  Key loss metrics  */
  lossSummaryMetrics?: Maybe<Array<LossMetric>>;
  /**  Losses aggregated by year  */
  lossYears?: Maybe<Array<Maybe<CarrierLossYear>>>;
  name: Scalars['String']['output'];
  /**  Grades summarized by pillar  */
  pillars?: Maybe<Array<Pillar>>;
  /**  Property assessment details by attribute  */
  propertyAssessmentDetails?: Maybe<Array<PropertyAssessmentDetail>>;
  /**  Property grade details by attribute   */
  propertyGradeDetails?: Maybe<Array<PropertyGradeDetail>>;
  /**  Property loss details by attribute  */
  propertyLossDetails?: Maybe<Array<PropertyLossDetail>>;
  streamId: Scalars['ID']['output'];
};

/**  Export codes which can be configured as carrier exports  */
export enum CarrierExportCode {
  Rapid = 'RAPID',
  Zorba = 'ZORBA'
}

export type CarrierLossYear = {
  __typename?: 'CarrierLossYear';
  avgPaidIndemnity?: Maybe<Scalars['Float']['output']>;
  avgPaidIndemnityBenchmark?: Maybe<Scalars['Float']['output']>;
  avgPaidIndemnityBenchmarkDescription?: Maybe<Scalars['String']['output']>;
  avgPaidIndemnityBenchmarkRatio?: Maybe<Scalars['Float']['output']>;
  /**  Grade of the paid indemnity  */
  avgPaidIndemnityGrade?: Maybe<Grade>;
  claimCnt?: Maybe<Scalars['Int']['output']>;
  lossFrequency?: Maybe<Scalars['Float']['output']>;
  lossFrequencyBenchmark?: Maybe<Scalars['Float']['output']>;
  lossFrequencyBenchmarkDescription?: Maybe<Scalars['String']['output']>;
  lossFrequencyBenchmarkRatio?: Maybe<Scalars['Float']['output']>;
  /**  Grade of the loss frequency  */
  lossFrequencyGrade?: Maybe<Grade>;
  totalPaidIndemnity?: Maybe<Scalars['Float']['output']>;
  uwYear: Scalars['Int']['output'];
};

export type CarrierMetricDataError = {
  __typename?: 'CarrierMetricDataError';
  /**  Header for the column which contains the error  */
  columnHeader?: Maybe<Scalars['String']['output']>;
  /**  Cell location on the sheet with the error (e.g. E11)  */
  location?: Maybe<Scalars['String']['output']>;
  /**  Detailed error message  */
  message: Scalars['String']['output'];
  /**  Name of the sheet with the error  */
  sheetName?: Maybe<Scalars['String']['output']>;
  /**  Cell value which caused the error  */
  value: Scalars['String']['output'];
};

export enum CarrierMetricImportStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Started = 'STARTED'
}

export type CarrierMetricTaskInput = {
  id: Scalars['ID']['input'];
};

export type CarrierMetricTaskResult = {
  __typename?: 'CarrierMetricTaskResult';
  errors?: Maybe<Array<CarrierMetricDataError>>;
  id: Scalars['ID']['output'];
  reportName: Scalars['String']['output'];
  status: CarrierMetricImportStatus;
  streamId: Scalars['ID']['output'];
};

export type CarrierPropertyAssessment = {
  __typename?: 'CarrierPropertyAssessment';
  assessmentDate?: Maybe<Scalars['String']['output']>;
  /**  Carrier-specific property ID  */
  carrierPropertyId: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  hotWorkRiskFactor?: Maybe<Grade>;
  housekeepingRiskFactor?: Maybe<Grade>;
  maintenanceRiskFactor?: Maybe<Grade>;
  managementRiskFactor?: Maybe<Grade>;
  rePropertyId: Scalars['String']['output'];
  riskGrade?: Maybe<Grade>;
  sicCode?: Maybe<Scalars['Int']['output']>;
  sicDescription?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  trainingRiskFactor?: Maybe<Grade>;
};

export type CarrierPropertyAssessmentFilter = {
  /**  Attribute to filter and sort by  */
  attribute: RiskAttribute;
  /**  Optional filter for attribute category  */
  category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CarrierPropertyAssessmentsInput = {
  filter: CarrierPropertyAssessmentFilter;
  reportName: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
};

export type CarrierPropertyAssessmentsResult = {
  __typename?: 'CarrierPropertyAssessmentsResult';
  assessments?: Maybe<Array<CarrierPropertyAssessment>>;
  totalCount: Scalars['Int']['output'];
};

export type CarrierPropertyClaim = {
  __typename?: 'CarrierPropertyClaim';
  address?: Maybe<Scalars['String']['output']>;
  causeOfLoss?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  firstNotification?: Maybe<Scalars['Date']['output']>;
  lossOccurrence?: Maybe<Scalars['Date']['output']>;
  paidIndemnity?: Maybe<Scalars['Float']['output']>;
  perilType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CarrierPropertyClaimsFilter = {
  /**  Category of the claim. If unspecified, claims from all categories are returned.  */
  category?: InputMaybe<ClaimCategory>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: Sort;
  year: Scalars['Int']['input'];
};

export type CarrierPropertyClaimsInput = {
  filter: CarrierPropertyClaimsFilter;
  reportName: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
};

export type CarrierPropertyClaimsResult = {
  __typename?: 'CarrierPropertyClaimsResult';
  /**  Average paid indemnity for the year  */
  avgPaidIndemnity?: Maybe<Scalars['Float']['output']>;
  /**  Total number of claims closed during the year  */
  claimCnt?: Maybe<Scalars['Int']['output']>;
  claims?: Maybe<Array<CarrierPropertyClaim>>;
  /**  Total paid indemnity for the year  */
  totalPaidIndemnity?: Maybe<Scalars['Float']['output']>;
  uwYear: Scalars['Int']['output'];
};

export type CarrierPropertyGrade = {
  __typename?: 'CarrierPropertyGrade';
  biRiskGrade?: Maybe<Grade>;
  /**  Carrier-specific property ID  */
  carrierPropertyId: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  earthquakeZoneGrade?: Maybe<Grade>;
  floodGrade?: Maybe<Grade>;
  hailGrade?: Maybe<Grade>;
  hazardGrade?: Maybe<Grade>;
  pdRiskGrade?: Maybe<Grade>;
  reRiskGrade?: Maybe<Grade>;
  sicCode?: Maybe<Scalars['Int']['output']>;
  sicDescription?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  tiv?: Maybe<Scalars['Float']['output']>;
  tivBi?: Maybe<Scalars['Float']['output']>;
  tivPd?: Maybe<Scalars['Float']['output']>;
  tornadoGrade?: Maybe<Grade>;
  windstormGrade?: Maybe<Grade>;
};

export type CarrierPropertyGradeFilter = {
  /**  Attribute to use for category filter  */
  attribute: RiskAttribute;
  /**  Optional filter for attribute categories  */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Attributes to sort by. Grade attribute names must be suffixed by '.category' or '.value'.
   * Examples: 'reRiskGrade.category', 'reRiskGrade.value', 'tivPd'
   */
  sortBy?: InputMaybe<Array<Sort>>;
};

export type CarrierPropertyGradesInput = {
  filter: CarrierPropertyGradeFilter;
  reportName: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
};

export type CarrierPropertyGradesResult = {
  __typename?: 'CarrierPropertyGradesResult';
  properties?: Maybe<Array<CarrierPropertyGrade>>;
  totalCount: Scalars['Int']['output'];
};

export type Casualty = {
  __typename?: 'Casualty';
  /**  ID of the underlying organization  */
  accountOrgId: Scalars['String']['output'];
  /**  Name of the underlying organization  */
  accountOrgName: Scalars['String']['output'];
  /** Submitted" or "Processing Complete"  */
  accountStatus?: Maybe<Scalars['String']['output']>;
  analysisDate?: Maybe<Scalars['Date']['output']>;
  analysisNotes?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  clientNetWorth?: Maybe<Scalars['Float']['output']>;
  clientProspect?: Maybe<ClientProspect>;
  deductibleAggregateLimit?: Maybe<Array<HistoricalExposure>>;
  exposureBase?: Maybe<ExposureBase>;
  exposures?: Maybe<Array<HistoricalExposure>>;
  id: Scalars['ID']['output'];
  naicsCode?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  /**  Name of the enterprise organization  */
  orgName: Scalars['String']['output'];
  /**  ID of the enterprise organization  */
  organizationId: Scalars['String']['output'];
  policyEffectiveDate?: Maybe<Scalars['Date']['output']>;
  policyEffectiveDates?: Maybe<Array<PolicyEffectiveDates>>;
  primaryContactEmail?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  requestedLinesOfBusiness?: Maybe<Array<RequestedLinesOfBusiness>>;
  retentions?: Maybe<Array<HistoricalExposure>>;
  secondaryContactEmail?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<Scalars['String']['output']>;
  typeOfBusiness?: Maybe<Scalars['String']['output']>;
};

export type CasualtyInput = {
  analysisDate?: InputMaybe<Scalars['Date']['input']>;
  analysisNotes?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientNetWorth?: InputMaybe<Scalars['Float']['input']>;
  clientProspect?: InputMaybe<ClientProspect>;
  deductibleAggregateLimit?: InputMaybe<Array<HistoricalExposureInput>>;
  exposureBase?: InputMaybe<ExposureBaseInput>;
  exposures?: InputMaybe<Array<HistoricalExposureInput>>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  policyEffectiveDates?: InputMaybe<Array<PolicyEffectiveDatesInput>>;
  primaryContactEmail?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  requestedLinesOfBusiness?: InputMaybe<Array<RequestedLinesOfBusinessInput>>;
  retentions?: InputMaybe<Array<HistoricalExposureInput>>;
  secondaryContactEmail?: InputMaybe<Scalars['String']['input']>;
  sicCode?: InputMaybe<Scalars['String']['input']>;
  typeOfBusiness?: InputMaybe<Scalars['String']['input']>;
};

export type CasualtyPage = {
  __typename?: 'CasualtyPage';
  casualtyList?: Maybe<Array<Casualty>>;
  totalCount: Scalars['Int']['output'];
};

export type CasualtyPageInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  /**  Name of the enterprise organization  */
  orgName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum CasualtyState {
  ProcessingComplete = 'ProcessingComplete',
  Submitted = 'Submitted'
}

/** Chart comparing last bound snapshot data to present. */
export type ChangeChart = ComparisonChart & {
  __typename?: 'ChangeChart';
  /**
   * Facets describe the intersection of attributes to which the chart data applies.
   * For example, a chart with facets REGION_UNITED_STATES and HAZARD_EXPOSURE_QUAKEZONE
   * will contain data filtered for properties in the United States which are exposed to earthquakes.
   */
  facets?: Maybe<Array<ChartFacet>>;
  name: ComparisonChartName;
  summaryStats?: Maybe<Array<FloatTupple>>;
  values: Array<AttributeGroup>;
};

export type ChangeField = {
  __typename?: 'ChangeField';
  currentValue?: Maybe<Scalars['Any']['output']>;
  historicalValue?: Maybe<Scalars['Any']['output']>;
  name: Scalars['String']['output'];
};

export enum ChangeType {
  Blank = 'Blank',
  CaseChanged = 'CaseChanged',
  Corrected = 'Corrected',
  Disposed = 'Disposed',
  New = 'New',
  Supplied = 'Supplied',
  Unchanged = 'Unchanged'
}

export type Chart = {
  name: Scalars['String']['output'];
};

export type ChartAggregation = {
  __typename?: 'ChartAggregation';
  average?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  total?: Maybe<Scalars['Float']['output']>;
};

export enum ChartFacet {
  BuildingSprinklered = 'BUILDING_SPRINKLERED',
  CombustibleConstruction = 'COMBUSTIBLE_CONSTRUCTION',
  HazardExposureFloodzone = 'HAZARD_EXPOSURE_FLOODZONE',
  HazardExposureQuakezone = 'HAZARD_EXPOSURE_QUAKEZONE',
  HazardExposureWindzone = 'HAZARD_EXPOSURE_WINDZONE',
  LossAttritional = 'LOSS_ATTRITIONAL',
  LossCatastrophe = 'LOSS_CATASTROPHE',
  PropertyStatusAny = 'PROPERTY_STATUS_ANY',
  PropertyStatusOngoingOrNew = 'PROPERTY_STATUS_ONGOING_OR_NEW',
  RegionUnitedStates = 'REGION_UNITED_STATES',
  RegionWorld = 'REGION_WORLD'
}

export type ChatRoomInfo = {
  __typename?: 'ChatRoomInfo';
  id: Scalars['String']['output'];
  /**  Check if current user has already joined this chat room  */
  joined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  unread: Scalars['Boolean']['output'];
  userMention: Scalars['Boolean']['output'];
  userMentions: Scalars['Int']['output'];
};

export type CheckoutJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['ID']['input'];
};

export enum ClaimCategory {
  Usd_0 = 'USD_0',
  Usd_0To_500K = 'USD_0_TO_500K',
  Usd_500KTo_1000K = 'USD_500K_TO_1000K',
  Usd_1000KTo_2500K = 'USD_1000K_TO_2500K',
  Usd_2500KOrMore = 'USD_2500K_OR_MORE'
}

export type ClientIDsExistInput = {
  /**  ClientIDs to check for existence within the organization.  */
  clientIDs: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type ClientIDsExistResponse = {
  __typename?: 'ClientIDsExistResponse';
  /**  Subset of requested client IDs which exist within an organization.  */
  existingClientIDs: Array<Scalars['String']['output']>;
};

export enum ClientProspect {
  Client = 'Client',
  Prospect = 'Prospect'
}

export type ClusterParams = {
  /**  Optional batch size when clusterizing large property groups  */
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  /**
   * KMEANS parameter for the number of clusters to compute. If not specified, it will be based on the area
   * of the input ROI.
   */
  clusterCount?: InputMaybe<Scalars['Int']['input']>;
  /**  DBSCAN epsilon parameter, which specifies the radius around each point to clusterize  */
  eps?: InputMaybe<Scalars['Float']['input']>;
  /**  DBSCAN parameter which specifies the minimum number of points per cluster  */
  minPoints?: InputMaybe<Scalars['Int']['input']>;
  strategy?: InputMaybe<ClusterStrategy>;
};

export type ClusterRange = {
  __typename?: 'ClusterRange';
  attribute: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export enum ClusterStrategy {
  Dbscan = 'DBSCAN',
  Kmeans = 'KMEANS',
  /**  Return individual property markers. Recommended after a certain UI zoom level */
  None = 'NONE'
}

export enum Combinator {
  And = 'AND',
  Or = 'OR'
}

export type ComparisonChart = {
  name: ComparisonChartName;
};

export enum ComparisonChartName {
  GeneralConstructionTypesByTiv = 'GENERAL_CONSTRUCTION_TYPES_BY_TIV',
  HazardGeography = 'HAZARD_GEOGRAPHY',
  LossCostOverTime = 'LOSS_COST_OVER_TIME',
  LossFrequencyOverTime = 'LOSS_FREQUENCY_OVER_TIME',
  LossSeverityOverTime = 'LOSS_SEVERITY_OVER_TIME',
  SpecificOccupanciesByTiv = 'SPECIFIC_OCCUPANCIES_BY_TIV',
  SprinklerProtectionBreakdownByTiv = 'SPRINKLER_PROTECTION_BREAKDOWN_BY_TIV',
  Top_3CountriesByTiv = 'TOP_3_COUNTRIES_BY_TIV',
  Top_3StatesByTiv = 'TOP_3_STATES_BY_TIV'
}

export type ComparisonStat = {
  __typename?: 'ComparisonStat';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CompleteJobTransitionInput = {
  comment: Scalars['String']['input'];
  jobID: Scalars['ID']['input'];
  transitionID: Scalars['ID']['input'];
};

export type CompleteTaskV3Output = {
  __typename?: 'CompleteTaskV3Output';
  propertyChanges: Array<CompletedTaskProperty>;
};

export type CompletedTaskProperty = {
  __typename?: 'CompletedTaskProperty';
  changedAttributes: Array<AttributeChanges>;
  property: Property;
};

/**  ClassificationStatus is the status of suggestion */
export enum ConfidenceLevel {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Unknown = 'UNKNOWN'
}

export type ConfigFeatureInput = {
  /**  true to enable, false to disable optional feature for specified orgs  */
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  featureName: Scalars['String']['input'];
  forAllOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  /**  one of the following two can be specified  */
  forOrgNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ConfigureAuthorizationInput = {
  /** DEPRECATED */
  accountAdminToManageAccountAdminDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Setting for Enterprise Orgs to automatically configure additional Market Access domains. The
   * values in this setting will be used as an extra way of populating the underlying org's stream
   * market access domain allow list. The other way being the default allowed domains list.
   */
  marketAccessDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  /**
   * ConfigureAuthorizationInput.ssoConfigName is the name of the SAML connection in Auth0 that is
   * used to configure SSO for customers. Configuring SSO in Auth0 allows customers to use their own
   * identity provider (IdP) to log in to the platform. By configuring
   * ConfigureAuthorizationInput.ssoConfigName in the authorization service the platform automatically
   * grants the user roles to the customer's organization which gives access to organization's stream
   * data. Granting roles, or automatically role provisioning, is based on the metadata that the IdP
   * provides. If no role metadata is provided by the IdP then the user won't get access. Currently
   * only RiskManager is supported.
   *
   * Setting this value won't configure SSO in Auth0.
   */
  ssoConfigName?: InputMaybe<Scalars['String']['input']>;
};

export type ConfigureOrgExportInput = {
  /**  Exports which users with this org as their home org can export  */
  carrierExports?: InputMaybe<Array<CarrierExportCode>>;
  orgId: Scalars['ID']['input'];
};

export type ConfigureSubmissionRegionsInput = {
  orgName: Scalars['String']['input'];
  worksheet: Scalars['Upload']['input'];
};

export type ConfigureSubmissionRegionsResponse = {
  __typename?: 'ConfigureSubmissionRegionsResponse';
  regions: Array<SubmissionRegion>;
};

export type ConfiguredFeature = {
  __typename?: 'ConfiguredFeature';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum Connection {
  Passwordless = 'PASSWORDLESS',
  PasswordWithoutMfa = 'PASSWORD_WITHOUT_MFA',
  PasswordWithMfa = 'PASSWORD_WITH_MFA'
}

export type ConsumerCustomAttributes = {
  __typename?: 'ConsumerCustomAttributes';
  consumerDecimal1?: Maybe<Scalars['Float']['output']>;
  consumerDecimal2?: Maybe<Scalars['Float']['output']>;
  consumerDecimal3?: Maybe<Scalars['Float']['output']>;
  consumerDecimal4?: Maybe<Scalars['Float']['output']>;
  consumerDecimal5?: Maybe<Scalars['Float']['output']>;
  consumerDecimal6?: Maybe<Scalars['Float']['output']>;
  consumerDecimal7?: Maybe<Scalars['Float']['output']>;
  consumerDecimal8?: Maybe<Scalars['Float']['output']>;
  consumerDecimal9?: Maybe<Scalars['Float']['output']>;
  consumerDecimal10?: Maybe<Scalars['Float']['output']>;
  consumerDecimal11?: Maybe<Scalars['Float']['output']>;
  consumerDecimal12?: Maybe<Scalars['Float']['output']>;
  consumerDecimal13?: Maybe<Scalars['Float']['output']>;
  consumerDecimal14?: Maybe<Scalars['Float']['output']>;
  consumerDecimal15?: Maybe<Scalars['Float']['output']>;
  consumerText1?: Maybe<Scalars['String']['output']>;
  consumerText2?: Maybe<Scalars['String']['output']>;
  consumerText3?: Maybe<Scalars['String']['output']>;
  consumerText4?: Maybe<Scalars['String']['output']>;
  consumerText5?: Maybe<Scalars['String']['output']>;
  consumerText6?: Maybe<Scalars['String']['output']>;
  consumerText7?: Maybe<Scalars['String']['output']>;
  consumerText8?: Maybe<Scalars['String']['output']>;
  consumerText9?: Maybe<Scalars['String']['output']>;
  consumerText10?: Maybe<Scalars['String']['output']>;
  consumerText11?: Maybe<Scalars['String']['output']>;
  consumerText12?: Maybe<Scalars['String']['output']>;
  consumerText13?: Maybe<Scalars['String']['output']>;
  consumerText14?: Maybe<Scalars['String']['output']>;
  consumerText15?: Maybe<Scalars['String']['output']>;
};

export type ConvertUserInput = {
  desiredConnection: Connection;
  email: Scalars['ID']['input'];
};

export type ConvertUserResponse = {
  __typename?: 'ConvertUserResponse';
  /** Will be set when ConvertUserInput.desiredConnection is PASSWORD_WITH_MFA. */
  passwordResetLink?: Maybe<Scalars['String']['output']>;
  profile: UserProfile;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CountryCurrencyCode = {
  __typename?: 'CountryCurrencyCode';
  country: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
};

export type CreateAttributeInput = {
  attributeName: Scalars['String']['input'];
  columnName: Scalars['String']['input'];
  columnPosition: Scalars['Int']['input'];
  dataType: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  displayWidth: Scalars['Int']['input'];
  filterable: Scalars['Boolean']['input'];
  groupable: Scalars['Boolean']['input'];
  hiddenInGrid: Scalars['Boolean']['input'];
  includeInStreams: Scalars['Boolean']['input'];
  isSecondaryModifier?: InputMaybe<Scalars['Boolean']['input']>;
  mandatory: Scalars['Boolean']['input'];
  maxThreshold?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  priorityInSimplifiedExport?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCasualtyInput = {
  casualty: CasualtyInput;
  documents?: InputMaybe<Array<Scalars['Upload']['input']>>;
  enterpriseOrgName: Scalars['String']['input'];
};

export type CreateDataQualityScoresInput = {
  snapshotID: Scalars['ID']['input'];
};

export type CreateDataQualityScoresResult = {
  __typename?: 'CreateDataQualityScoresResult';
  /**  True if this request initiated scoring. If scoring is already in-progress or completed this will be false.  */
  initiated: Scalars['Boolean']['output'];
  status: DataQualityScoresStatus;
};

export type CreateDocumentClassificationSuggestionInput = {
  classificationFormat?: InputMaybe<Scalars['String']['input']>;
  classificationMethod?: InputMaybe<Scalars['String']['input']>;
  classificationReason?: InputMaybe<Array<Scalars['String']['input']>>;
  classificationSource?: InputMaybe<Scalars['String']['input']>;
  confidenceLevel: Scalars['Int']['input'];
  documentId: Scalars['ID']['input'];
  orgName: Scalars['String']['input'];
  status: SuggestionStatus;
  suggestedType: Scalars['String']['input'];
};

export type CreateDocumentPropertySuggestionInput = {
  confidenceLevel: Scalars['Int']['input'];
  documentId: Scalars['ID']['input'];
  linkMethod?: InputMaybe<Array<Scalars['String']['input']>>;
  linkReason?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  status: SuggestionStatus;
};

export type CreateExportConfigurationInput = {
  configuration?: InputMaybe<Array<ExportAttributeMetadataInput>>;
  /**  Not yet implemented. Named exports will be supported in the future  */
  name?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  /** either organizationId or orgName" should be set  */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  streamID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJobInput = {
  /**  Attributes to be enriched as part of this job. A non-empty list is required for 'Enrichment' Jobs. */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  doerUserID?: InputMaybe<Scalars['String']['input']>;
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Optional expire in hours. If specified, this job expires in this number of hours after create.
   * All locks are released and status is updated to Expired.
   */
  expireInHours?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  priority?: InputMaybe<JobPriority>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optional list of properties subject of this job. If not provided all properties in the
   *   org are implied.
   */
  propertyIDs?: InputMaybe<Array<Scalars['ArchipelagoID']['input']>>;
  /**
   * List of reference document ids.
   * Optional except for provenance enrichment jobs, which must contain exactly one reference.
   */
  references?: InputMaybe<Array<Scalars['String']['input']>>;
  requesterUserID?: InputMaybe<Scalars['String']['input']>;
  reviewerUserID?: InputMaybe<Scalars['String']['input']>;
  /**  Applicable to col mapping and pre cleanse jobs. system doc id for a previously uploaded SOV  */
  sovDocId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optional stream slug for ingest jobs. If specified, workseet will use the stream's attribute metadata configuration
   * If not it will use the organization's configuration by default
   */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<JobType>;
  /**
   * Only applies to Enrichment jobs. If set to SingleColumn, api will generate
   * the worksheet without .New and .Suggested columns
   */
  worksheetFormat?: InputMaybe<WorksheetFormat>;
};

export type CreateOnboardingProjectsInput = {
  orgID: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type CreateOrUpdateProgramInput = {
  archipelagoProgramId?: InputMaybe<Scalars['String']['input']>;
  /**  Job ID creating/updating the program  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  program: ProgramDataInput;
  /**  Stream slug (or random id) to associate the program with  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdatePropertyInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  matchClientId?: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  property: PropertyDataInput;
  /** JSON representation of the raw SOV data for this property */
  sovDataRow?: InputMaybe<Scalars['String']['input']>;
  sovFileId?: InputMaybe<Scalars['String']['input']>;
  sovRowNumber?: InputMaybe<Scalars['Int']['input']>;
  sovSheetName?: InputMaybe<Scalars['String']['input']>;
  /** Identifies the related SOV snapshot for any value specified in sovDataRow. This must be specified to record that value. */
  sovSnapshotId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrgInput = {
  /**
   * List of domains that are associated to this organization. Users that signup with a domain
   * that matches a domain in this array will automatically have the organization_id configured in
   * their app_metadata.
   */
  autoDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  /**
   * Three character code (ISO_4217) to override the currency formatting per organization. E.g. USD, EUR, ...
   * If ZZZ then currencies are rendered as unit-less.
   */
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  /**
   * Enables org members to view the Carrier Section of the Underwriting Assessment Report within permitted streams.
   * Defaults to false.
   */
  enableCarrierAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to turn the currency conversion on or off on an organization. */
  enableCurrencyConversion?: InputMaybe<Scalars['Boolean']['input']>;
  /** Default false with the exception of prospect orgs registered through hubspot. */
  enableRedirectOnSOVUpload?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Enables org members to view the Risk Manager Section of the Underwriting Assessment Report within permitted streams.
   * Defaults to false.
   */
  enableRiskManagerAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable access to SOV Manager. */
  enableSOVManager?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable secondary modifiers in the property modal. */
  enableSecondaryModifiers?: Scalars['Boolean']['input'];
  /** If set to false, support won't receive emails from sov uploads. Default false. */
  enableSendEmailOnSOVUpload?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable the stream dashboard. */
  enableStreamDashboard?: Scalars['Boolean']['input'];
  /** Flag to enable/disable SubValues for all segments of the organization. */
  enableSubValues?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, createSubmission mutation will assign creator as StreamViewer instead of RiskManager */
  enableUnderwriterEdit?: InputMaybe<Scalars['Boolean']['input']>;
  /**  flag to enable/disable the upload portal  */
  enableUploadPortal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable virtual inspections on properties. */
  enableVirtualInspections?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to exclude this organization from the reporting. When true data from this organization will not be included in the reports. */
  excludeFromReporting: Scalars['Boolean']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  /**  Org Level for the organization  */
  orgLevel?: InputMaybe<OrgLevelType>;
  orgName: Scalars['String']['input'];
  /**
   * System org type. OrgType will be inferred if reportingType is specified, except if reportingType is Internal.
   * If reportingType is Internal then this is required and must be Test, Demo, Template, or SystemDefaults.
   */
  orgType?: InputMaybe<OrgType>;
  /**
   * Reporting type for the organization.
   * If it is Internal then orgType must also be specified. Otherwise the new orgType will be inferred.
   */
  reportingType?: InputMaybe<OrgReportingType>;
  /**  Sales channel name  */
  salesChannel?: InputMaybe<Scalars['String']['input']>;
  /**  ID of the Salesforce account associated with this organization  */
  salesforceAccountID?: InputMaybe<Scalars['String']['input']>;
  /** Flag to show or hide non-enrichment documents */
  showNonEnrichmentDocs?: InputMaybe<Scalars['Boolean']['input']>;
  unitOfMeasurement?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectInput = {
  /**  UTC date on which this project is due. Value format as YYYY-MM-DD  */
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Effective date, in UTC, for all property changes in this project. Value format as YYYY-MM-DD
   *       If not specified, the change timeframe is assumed to be current.
   */
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type CreatePropertyDiscussionInput = {
  attributeName?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  propertyArchipelagoID: Scalars['ID']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type CreatePropertyDiscussionResult = {
  __typename?: 'CreatePropertyDiscussionResult';
  discussion: PropertyDiscussion;
};

export type CreatePropertyInput = {
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  property: PropertyDataInput;
  /** JSON representation of the raw SOV data for this property */
  sovDataRow?: InputMaybe<Scalars['String']['input']>;
  sovFileId?: InputMaybe<Scalars['String']['input']>;
  sovRowNumber?: InputMaybe<Scalars['Int']['input']>;
  sovSheetName?: InputMaybe<Scalars['String']['input']>;
  /** Identifies the related SOV snapshot for any value specified in sovDataRow. This must be specified to record that value. */
  sovSnapshotId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePropertyRecommendationsInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type CreatePropertyRecommendationsResponse = {
  __typename?: 'CreatePropertyRecommendationsResponse';
  mlRecommendationsStatus: PropertyRecommendationsStatus;
};

export type CreateProvenanceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /**  One of externalSourceUrl or documentLink is required  */
  documentLinks?: InputMaybe<Array<DocumentLinkInput>>;
  /**  Effective provenance date. Optional, defaults to current timestamp  */
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  externalSourceURLs?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  propertyAttributeName: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  propertyRevisionId?: InputMaybe<Scalars['String']['input']>;
  /**  Text to describe source of attribute. Any formatting should use markdown syntax.  */
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRenewalStreamInput = {
  orgName: Scalars['String']['input'];
  /**
   * Slug for the stream to base the renewal on.
   * Stream input values are copied from the prior stream unless overridden in the stream input.
   */
  priorStreamSlug: Scalars['ID']['input'];
  sov: Scalars['Upload']['input'];
  /**
   * Stream input values to override from the prior stream.
   * Name and isPublic are required.
   */
  stream: StreamInput;
};

export type CreateScopeGroupInput = {
  /** Identifier of the members to be added to the group. */
  memberIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Human readable name for the scope group. Like "All Underlying Orgs for Enterprise Org" */
  name: Scalars['String']['input'];
  /** This is the ID of the owner of the ScopeGroup. Which, in most cases, is the Enterprise Org ID. */
  scopeGroupExternalId: Scalars['String']['input'];
  /** Type of the Scope Group. When set to Org the system will only allow grouping org scopes. */
  type: ScopeGroupType;
};

export type CreateSnapshotInput = {
  /**
   * Optional parameter to specify which streams the snapshot is bound to. If unspecified, the snapshot will be bound to
   * all streams in the organization.
   */
  StreamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Optional snapshot date. Format must be YYYY-MM-DD.
   * If no date or project is specified, snapshot date will be the current timestamp.
   * If both optional fields are specified, the date field will be used for snapshot date.
   */
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Snapshot name. If not specified, will default to snapshot date
   * formatted like YYYY-MM-DD
   */
  name?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  /**
   * Optional project name. If specified, snapshot will be created at the project's
   * effective date. Current timestamp will be used otherwise
   */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /**
   * If true (default), a data quality score will be calculated for the snapshot.
   * If false, a data quality score will not be calculated, but can be triggered at a later point using 'createDataQualityScores'.
   */
  triggerDQS?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of snapshot */
  type?: InputMaybe<SnapshotType>;
  updateReportingViews?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateStreamInput = {
  orgName: Scalars['String']['input'];
  stream: StreamInput;
};

export type CreateSubmissionInput = {
  carrierOrgName: Scalars['String']['input'];
  /** Customer stream slug. only used for program */
  customerStreamSlug?: InputMaybe<Scalars['String']['input']>;
  /**  Optional list of documents to upload to the submission  */
  documents?: InputMaybe<Array<Scalars['Upload']['input']>>;
  submission: SubmissionInput;
};

export type CurrentChatRoomInput = {
  orgName: Scalars['String']['input'];
};

export type CurrentChatRoomResponse = {
  __typename?: 'CurrentChatRoomResponse';
  enabled: Scalars['Boolean']['output'];
  /**  Deprecated. Use room instead  */
  groupID?: Maybe<Scalars['String']['output']>;
  lastMessageTime?: Maybe<Scalars['Time']['output']>;
  room?: Maybe<ChatRoomInfo>;
  unread: Scalars['Boolean']['output'];
  userMentions: Scalars['Int']['output'];
};

export type CurrentStreamPropertiesInput = {
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Maximum number of properties to return. Default value is 10. A maximum of 200 may be requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** If not specified, latest visible snapshot is used. */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type CustomAttribute = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Any']['input']>;
};

export type CustomAttributeInput = {
  ownerDecimal1?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal2?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal3?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal4?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal5?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal6?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal7?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal8?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal9?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal10?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal11?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal12?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal13?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal14?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal15?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal101?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal102?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal103?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal104?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal105?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal106?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal107?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal108?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal109?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal110?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal111?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal112?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal113?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal114?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal115?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal116?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal117?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal118?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal119?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal120?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal121?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal122?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal123?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal124?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal125?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal126?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal127?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal128?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal129?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal130?: InputMaybe<Scalars['Float']['input']>;
  ownerText1?: InputMaybe<Scalars['String']['input']>;
  ownerText2?: InputMaybe<Scalars['String']['input']>;
  ownerText3?: InputMaybe<Scalars['String']['input']>;
  ownerText4?: InputMaybe<Scalars['String']['input']>;
  ownerText5?: InputMaybe<Scalars['String']['input']>;
  ownerText6?: InputMaybe<Scalars['String']['input']>;
  ownerText7?: InputMaybe<Scalars['String']['input']>;
  ownerText8?: InputMaybe<Scalars['String']['input']>;
  ownerText9?: InputMaybe<Scalars['String']['input']>;
  ownerText10?: InputMaybe<Scalars['String']['input']>;
  ownerText11?: InputMaybe<Scalars['String']['input']>;
  ownerText12?: InputMaybe<Scalars['String']['input']>;
  ownerText13?: InputMaybe<Scalars['String']['input']>;
  ownerText14?: InputMaybe<Scalars['String']['input']>;
  ownerText15?: InputMaybe<Scalars['String']['input']>;
  ownerText101?: InputMaybe<Scalars['String']['input']>;
  ownerText102?: InputMaybe<Scalars['String']['input']>;
  ownerText103?: InputMaybe<Scalars['String']['input']>;
  ownerText104?: InputMaybe<Scalars['String']['input']>;
  ownerText105?: InputMaybe<Scalars['String']['input']>;
  ownerText106?: InputMaybe<Scalars['String']['input']>;
  ownerText107?: InputMaybe<Scalars['String']['input']>;
  ownerText108?: InputMaybe<Scalars['String']['input']>;
  ownerText109?: InputMaybe<Scalars['String']['input']>;
  ownerText110?: InputMaybe<Scalars['String']['input']>;
  ownerText111?: InputMaybe<Scalars['String']['input']>;
  ownerText112?: InputMaybe<Scalars['String']['input']>;
  ownerText113?: InputMaybe<Scalars['String']['input']>;
  ownerText114?: InputMaybe<Scalars['String']['input']>;
  ownerText115?: InputMaybe<Scalars['String']['input']>;
  ownerText116?: InputMaybe<Scalars['String']['input']>;
  ownerText117?: InputMaybe<Scalars['String']['input']>;
  ownerText118?: InputMaybe<Scalars['String']['input']>;
  ownerText119?: InputMaybe<Scalars['String']['input']>;
  ownerText120?: InputMaybe<Scalars['String']['input']>;
  ownerText121?: InputMaybe<Scalars['String']['input']>;
  ownerText122?: InputMaybe<Scalars['String']['input']>;
  ownerText123?: InputMaybe<Scalars['String']['input']>;
  ownerText124?: InputMaybe<Scalars['String']['input']>;
  ownerText125?: InputMaybe<Scalars['String']['input']>;
  ownerText126?: InputMaybe<Scalars['String']['input']>;
  ownerText127?: InputMaybe<Scalars['String']['input']>;
  ownerText128?: InputMaybe<Scalars['String']['input']>;
  ownerText129?: InputMaybe<Scalars['String']['input']>;
  ownerText130?: InputMaybe<Scalars['String']['input']>;
};

export type DqsPropertyRecommendation = {
  __typename?: 'DQSPropertyRecommendation';
  archipelagoID: Scalars['String']['output'];
  buildingName?: Maybe<Scalars['String']['output']>;
  clientID?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  /**  Suggested documents to improve data quality score  */
  recommendedDocumentClassifications?: Maybe<Array<DocClassification>>;
  seismicZone?: Maybe<Scalars['Boolean']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  windZone?: Maybe<Scalars['Boolean']['output']>;
};

export type DqsPropertyRecommendationsFilter = {
  hazard?: InputMaybe<HazardCategory>;
};

export type DqsPropertyRecommendationsInput = {
  filter?: InputMaybe<DqsPropertyRecommendationsFilter>;
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['ID']['input'];
};

export type DqsPropertyRecommendationsResponse = {
  __typename?: 'DQSPropertyRecommendationsResponse';
  properties: Array<DqsPropertyRecommendation>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  filters?: Maybe<Array<AnalyticsFilter>>;
  id: Scalars['String']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DataError = {
  __typename?: 'DataError';
  attributeName?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  columnHeader?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  location?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  /**  For worksheet based input  */
  sheetName?: Maybe<Scalars['String']['output']>;
  /**  Type of data error  */
  type?: Maybe<DataErrorType>;
  value: Scalars['String']['output'];
};

export enum DataErrorType {
  /**  Worksheet contains client IDs which already exist in the portfolio  */
  DuplicateClientId = 'DuplicateClientID',
  /**  Worksheet contains the same header more than once  */
  DuplicateColumnHeader = 'DuplicateColumnHeader',
  /**  Internal server error  */
  InternalServerError = 'InternalServerError',
  /**  Worksheet contains invalid data cells, for example a date or number that's not properly formatted */
  InvalidDataFormat = 'InvalidDataFormat',
  /**  Worksheet contains one or several invalid archipelago property ids that are not part of the current org and stream  */
  InvalidPropertyIDs = 'InvalidPropertyIDs',
  /**  Worksheet contains invalid column headers that are not part of the current org and stream  */
  UnknownColumnHeader = 'UnknownColumnHeader'
}

export type DataQualityScoresStatus = {
  __typename?: 'DataQualityScoresStatus';
  /**  Number of streams which were scored successfully.  */
  completed: Scalars['Int']['output'];
  /**  Number of streams which failed to be scored.  */
  failed: Scalars['Int']['output'];
  /**  Number of streams which have scoring in progress.  */
  inProgress: Scalars['Int']['output'];
};

/** These are the datatypes that we allow to be edited (for owner attributes ONLY!) */
export enum DataTypeInput {
  Boolean = 'BOOLEAN',
  Currency = 'CURRENCY',
  CurrencyLocal = 'CURRENCY_LOCAL',
  Date = 'DATE',
  Default = 'DEFAULT',
  Description = 'DESCRIPTION',
  Integer = 'INTEGER',
  MeasurementArea = 'MEASUREMENT_AREA',
  MeasurementDistanceLarge = 'MEASUREMENT_DISTANCE_LARGE',
  MeasurementDistanceSmall = 'MEASUREMENT_DISTANCE_SMALL',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  TwoLines = 'TWO_LINES',
  Year = 'YEAR'
}

export type DeleteAccount = {
  __typename?: 'DeleteAccount';
  email: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['Time']['output']>;
  system?: Maybe<Array<Scalars['String']['output']>>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type DeleteExportConfigurationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOrRestorePropertiesInput = {
  delete: Scalars['Boolean']['input'];
  /** Archipelago ID's */
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**  Only organizations with the `orgType` set to Test can be deleted.  */
export type DeleteOrgInput = {
  orgName: Scalars['String']['input'];
};

export type DeletePropertyDiscussionInput = {
  attributeName?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  propertyArchipelagoID: Scalars['ID']['input'];
  /**  Optional room id filter. Will only delete one discussion if specified  */
  roomID?: InputMaybe<Scalars['String']['input']>;
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type DeletePropertyDiscussionResult = {
  __typename?: 'DeletePropertyDiscussionResult';
  count: Scalars['Int']['output'];
};

export type DeletePropertyRecommendationsInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type DeletePropertyRecommendationsResponse = {
  __typename?: 'DeletePropertyRecommendationsResponse';
  deletedCount: Scalars['Int']['output'];
  mlRecommendationsStatus: PropertyRecommendationsStatus;
};

export type DeletePropertyRevInput = {
  /** Job ID to be reverted. Delete the property revisions created by this job. */
  jobID: Scalars['ID']['input'];
};

export type DeleteProvenanceByAttributeInput = {
  archipelagoId?: InputMaybe<Scalars['ID']['input']>;
  attributes: Array<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type DeleteScopeGroupInput = {
  /** Id of the group to remove. */
  groupId: Scalars['ID']['input'];
};

export type DeleteSnapshotInput = {
  snapshotId: Scalars['ID']['input'];
  updateReportingViews?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteStreamExposureIndexInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type DeleteStreamExposureIndexResult = {
  __typename?: 'DeleteStreamExposureIndexResult';
  /**  True if an index was deleted. False if no index existed for the requested stream/snapshot.  */
  deleted: Scalars['Boolean']['output'];
};

export type DeleteStreamInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteSubmissionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserV2Input = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteUserV2Output = {
  __typename?: 'DeleteUserV2Output';
  deletedAccounts?: Maybe<Array<DeleteAccount>>;
};

export type DeleteValidateLabelInput = {
  id: Scalars['ID']['input'];
};

export type DeleteValidateRuleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteValidateRuleOverrideInput = {
  overrideId: Scalars['ID']['input'];
};

export type DeletedPropertiesInput = {
  /** Only one of sortBy or cursor can be specified in this query */
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgID: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type DismissTasksInput = {
  allTasks?: InputMaybe<Scalars['Boolean']['input']>;
  orgName: Scalars['String']['input'];
  taskIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This is used by manage stream properties dispose job */
export type DisposePropertiesInput = {
  /**  list of property IDs selected in dispose properties job  */
  archipelagoIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  manage stream properties dispose job id  */
  jobID: Scalars['String']['input'];
  organizationID: Scalars['String']['input'];
  /**  user id of dispose properties job  */
  userID: Scalars['String']['input'];
};

export type DisposePropertiesResponse = {
  __typename?: 'DisposePropertiesResponse';
  /**  number of properties disposed  */
  count: Scalars['Int']['output'];
};

export enum DocClassification {
  ArchitecturalDrawing = 'ARCHITECTURAL_DRAWING',
  FloodElevationCertificate = 'FLOOD_ELEVATION_CERTIFICATE',
  FloodRiskAssessment = 'FLOOD_RISK_ASSESSMENT',
  LossEngineeringReport = 'LOSS_ENGINEERING_REPORT',
  PropertyConditionAssessment = 'PROPERTY_CONDITION_ASSESSMENT',
  RoofInspectionReport = 'ROOF_INSPECTION_REPORT',
  SeismicAssessment = 'SEISMIC_ASSESSMENT',
  StructuralDrawing = 'STRUCTURAL_DRAWING'
}

export type Document = {
  __typename?: 'Document';
  /**  Type of the file. Like Roof Report or PCA Report  */
  classification?: Maybe<Scalars['String']['output']>;
  /**  States to define intermediate state setting a classification, Gets overwritten to false when a suggestion is accepted or a the classification is updated  */
  classificationUnknown?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  /**  UserProfile of the user that created/uploaded the file  */
  createdBy: UserProfile;
  extension?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /**  States to define intermediate state of linking properties, Gets overwritten to false when a suggestion is accepted or a property is linked  */
  linkedToUnknownProperty?: Maybe<Scalars['Boolean']['output']>;
  /**  mlStatus to define if the magic.go / machine-learning functions have been completed  */
  mlStatus?: Maybe<MlStatus>;
  organizationID: Scalars['ID']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  /**  Preview URL of a document which is converted to pdf to preview. Only Set in getDocument  */
  previewURL?: Maybe<Scalars['String']['output']>;
  /**  List of associated Properties  */
  properties?: Maybe<Array<Property>>;
  /** All document links referenced as provenance from property attributes. */
  referencedSections?: Maybe<Array<SectionReference>>;
  sizeInBytes: Scalars['Int']['output'];
  /**  List of source documents. A source document are the original documents to which this file is related.  */
  sourceDocuments?: Maybe<Array<Document>>;
  /** List of any attributes which are linked to this document for provenance sourcing. */
  sourcedAttributes?: Maybe<Array<AttributeMetadata>>;
  /**  List of streams to which this files belongs. Also contains a String to indicate the file's group  */
  streamGroups?: Maybe<Array<StreamGroup>>;
  /**  List of suggested classifications  */
  suggestedClassifications?: Maybe<Array<DocumentClassificationSuggestion>>;
  /**  List of suggested Properties  */
  suggestedProperties?: Maybe<Array<DocumentPropertySuggestion>>;
  /**  Keywords associated with a particular document  */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbnailURLs?: Maybe<ImageUrLs>;
};

/**  Attributes that can be filtered on  */
export enum DocumentAttributeName {
  ArchipelagoId = 'archipelagoId',
  Classification = 'classification',
  CreatedAt = 'createdAt',
  Extension = 'extension',
  Filename = 'filename',
  Filetype = 'filetype',
  Origin = 'origin',
  Size = 'size',
  StreamCount = 'streamCount',
  Tags = 'tags'
}

export type DocumentClassificationInfo = {
  __typename?: 'DocumentClassificationInfo';
  classification: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

/**  DocumentClassificationSuggestion holds the suggestion info for suggested classification for documents  */
export type DocumentClassificationSuggestion = {
  __typename?: 'DocumentClassificationSuggestion';
  classificationFormat?: Maybe<Scalars['String']['output']>;
  classificationMethod?: Maybe<Scalars['String']['output']>;
  classificationReason?: Maybe<Array<Scalars['String']['output']>>;
  classificationSource?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  confidenceLevel: ConfidenceLevel;
  createdAt: Scalars['Time']['output'];
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  suggestedType: Scalars['String']['output'];
};

export type DocumentFact = {
  __typename?: 'DocumentFact';
  /** The links within the document for the locations corresponding to the extracted fact */
  links?: Maybe<Array<DocumentFactLink>>;
  /** The name of the fact */
  name: Scalars['String']['output'];
  /** The JSON value of the extracted fact */
  value: Scalars['String']['output'];
};

export type DocumentFactLink = {
  __typename?: 'DocumentFactLink';
  /** The type of link */
  linkType: Scalars['String']['output'];
  /** The PDF link data for the document fact */
  pdfLinkData: PdfFactLinkData;
};

export type DocumentLink = {
  __typename?: 'DocumentLink';
  /**  The document that contains the evidence  */
  document: Document;
  /**
   *  This stores a string used to describe the specific section of the document which was used
   * to source the provenance.
   * For example, this is used by iPad app to indicate that the provenance contains an in-line reference into a document
   * iPad format: {"x": float, "y": float, "width": float, "height": float, "length": int, "content": string
   * "pageNumber": int, "startIndex": int, "extractorVersion": int}
   */
  documentSection?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DocumentLinkInput = {
  /**  The document that contains the evidence  */
  documentId: Scalars['String']['input'];
  /**  see DocumentLink#documentSection  */
  documentSection?: InputMaybe<Scalars['String']['input']>;
};

/** Return object for the organizationDocumentsProperties call. Partial properties info only when needed. */
export type DocumentOrgProperty = {
  __typename?: 'DocumentOrgProperty';
  archipelagoId: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type DocumentOrgPropertyPage = {
  __typename?: 'DocumentOrgPropertyPage';
  /**  information about this documentOrgProperty page  */
  pageInfo: PageInfo;
  properties: Array<DocumentOrgProperty>;
};

export type DocumentPropertyInput = {
  /**  The properties to be added or removed from the document by using archipelagoIds, can't set both propertyIDs and archipelagoIds  */
  archipelagoIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The document ID for the document to be updated */
  documentID: Scalars['ID']['input'];
  /**
   * TO be deprecated: The property to be added or removed from the document
   *   for now if using the new way add anything to propertyID until this is removed. This is due backwards compatibility.
   */
  propertyID: Scalars['ID']['input'];
  /** The properties to be added or removed from the document, This will take precedence over the propertyID itself. */
  propertyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**  DocumentPropertySuggestion holds the suggested property information.  */
export type DocumentPropertySuggestion = {
  __typename?: 'DocumentPropertySuggestion';
  comment?: Maybe<Scalars['String']['output']>;
  confidenceLevel: ConfidenceLevel;
  createdAt: Scalars['Time']['output'];
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  linkMethod?: Maybe<Array<Scalars['String']['output']>>;
  linkReason?: Maybe<Array<Scalars['String']['output']>>;
  property: Property;
  status: SuggestionStatus;
};

export type DocumentSourceInput = {
  documentID: Scalars['ID']['input'];
  sourceDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum DocumentStatus {
  /** The document has not been uploaded */
  Missing = 'Missing',
  /** The document has been uploaded and processed */
  Processed = 'Processed',
  /** The document has been uploaded and is pending processing */
  Received = 'Received'
}

export type DocumentSummary = {
  __typename?: 'DocumentSummary';
  classification?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
};

export enum DocumentUploadStatus {
  Completed = 'completed',
  Failed = 'failed',
  NotFound = 'not_found',
  PartiallyUploaded = 'partially_uploaded',
  Registered = 'registered'
}

export type DocumentUploadStatusResult = {
  __typename?: 'DocumentUploadStatusResult';
  message: Scalars['String']['output'];
  status: DocumentUploadStatus;
};

export type DocumentsByClassificationInput = {
  /** Maximum number of items to return. Default value is 10. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  originClassifications: Array<OriginClassification>;
};

export type DocumentsByClassificationResponse = {
  __typename?: 'DocumentsByClassificationResponse';
  documents: Array<Document>;
  /**  Total number of rows in the dataset.  */
  totalCount: Scalars['Int']['output'];
};

export type DocumentsPage = {
  __typename?: 'DocumentsPage';
  documents: Array<Document>;
  /**  To be deprecated: information about this documents page  */
  pageInfo: PageInfo;
  /**  total number of rows in the dataset  */
  totalCount: Scalars['Int']['output'];
};

/**  Enum that allows Filtering on Document Status */
export enum DocumentsStatus {
  Archived = 'ARCHIVED',
  Processed = 'PROCESSED',
  Received = 'RECEIVED'
}

export type Domain = {
  __typename?: 'Domain';
  authorized: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
};

export type DomainAllowList = {
  __typename?: 'DomainAllowList';
  /** The date the domain was added to the allow list. */
  createdAt: Scalars['Time']['output'];
  /**
   * The "default domain" is one we assign to a stream upon creation (basically automatically added domains).
   * A non-default domain is one that is added afterwards by the Risk Managers or Brokers in this
   * case. A default domain cannot be changed by Risk Managers or Brokers, only by admins.
   */
  default: Scalars['Boolean']['output'];
  /**
   * Is the domain part of an email address that comes after the @ symbol. E.g. example.com
   * This is used to automatically grant access to a stream.
   */
  domain: Scalars['ID']['output'];
};

export type DonutChart = {
  __typename?: 'DonutChart';
  data: Array<FloatTupple>;
  label: Scalars['String']['output'];
};

export type Draft = {
  __typename?: 'Draft';
  isValueTruncated: Scalars['Boolean']['output'];
  previewValue: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EditorComment = {
  __typename?: 'EditorComment';
  /**  Unique ID for the comment  */
  ID: Scalars['String']['output'];
  /**  Name of the attribute associated with the comment  */
  attributeName?: Maybe<Scalars['String']['output']>;
  /**  Timestamp indicating when the comment was created  */
  createdAt: Scalars['String']['output'];
  /**  User who created the comment  */
  createdBy?: Maybe<UserProfile>;
  /**  Property information associated with the comment  */
  propertyInfo: EditorCommentPropertyInfo;
  /**  Comment status  */
  status: EditorCommentStatus;
  /**
   * Comment text. Can contain users tagged by email, accepted format is @<email address>.
   *   Any matching editor users in the text will receive email notification about this comment.
   */
  text: Scalars['String']['output'];
};

export type EditorCommentFilter = {
  attributeName?: InputMaybe<Scalars['String']['input']>;
  propertyArchipelagoID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EditorCommentStatus>;
};

export type EditorCommentPropertyInfo = {
  __typename?: 'EditorCommentPropertyInfo';
  archipelagoID: Scalars['String']['output'];
  locationID?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
};

export enum EditorCommentStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN'
}

export type EditorCommentsPageInput = {
  filter?: InputMaybe<EditorCommentFilter>;
  /** Maximum number of items to return. Default limit value is 200. A maximum limit value of 2000 is supported. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EditorCommentsPageResponse = {
  __typename?: 'EditorCommentsPageResponse';
  comments: Array<EditorComment>;
  resolvedCommentGroups?: Maybe<Array<ResolvedCommentsGroup>>;
  totalCount: Scalars['Int']['output'];
  yourComments?: Maybe<Array<EditorComment>>;
};

export type EditorCommentsUsersInput = {
  streamSlug: Scalars['String']['input'];
};

export type EditorCommentsUsersResponse = {
  __typename?: 'EditorCommentsUsersResponse';
  users: Array<StreamUserInfo>;
};

/** Let's the user add or remove a mapper for a given organization. */
export type EnableAttributeMapperInput = {
  /** Enable or disable the mapper */
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  /** The type of mapper to enable */
  mapper: Mapper;
  orgName: Scalars['String']['input'];
  /** The attribute into which the value will be outputted */
  outputAttribute: Scalars['String']['input'];
};

export type EnablePreCheckInput = {
  /**
   * Brokers to notify that the pre-check report is ready.
   * Report branding will be based off of the the email domain for the first broker listed.
   */
  brokers?: InputMaybe<Array<PreCheckBrokerInvite>>;
  orgName: Scalars['String']['input'];
};

export type EnablePreCheckResponse = {
  __typename?: 'EnablePreCheckResponse';
  brokerInvitees: Array<PreCheckInvitee>;
};

export type EnabledAttributeMapper = {
  __typename?: 'EnabledAttributeMapper';
  mapper: Mapper;
  outputAttribute: Scalars['String']['output'];
  outputType: MapperOutputType;
};

export type EngagementPropertyViewsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['Date']['input'];
  streamSlug: Scalars['String']['input'];
  userType: EngagementUserType;
};

export type EngagementPropertyViewsResult = {
  __typename?: 'EngagementPropertyViewsResult';
  archipelagoID: Scalars['ID']['output'];
  locationName: Scalars['String']['output'];
  propertyViews?: Maybe<Scalars['Int']['output']>;
};

export type EngagementStreamViewsInput = {
  fromDate: Scalars['Date']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EngagementStreamViewsResult = {
  __typename?: 'EngagementStreamViewsResult';
  orgViews?: Maybe<Scalars['Int']['output']>;
  userType: EngagementUserType;
  userViews?: Maybe<Scalars['Int']['output']>;
};

export type EngagementUserCount = {
  __typename?: 'EngagementUserCount';
  count: Scalars['Int']['output'];
  userType: EngagementUserType;
};

export enum EngagementUserType {
  Broker = 'Broker',
  Insurer = 'Insurer',
  Others = 'Others',
  PropertyTeam = 'PropertyTeam',
  RiskManagementTeam = 'RiskManagementTeam'
}

export type EngagementViewUserDownloads = {
  __typename?: 'EngagementViewUserDownloads';
  Archipelago_extract?: Maybe<Scalars['Boolean']['output']>;
  Modelling_files?: Maybe<Scalars['Boolean']['output']>;
  Property_docs?: Maybe<Scalars['Int']['output']>;
  Stream_docs?: Maybe<Scalars['Int']['output']>;
};

export type EngagementViewsByOrgInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['Date']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EngagementViewsByOrgResult = {
  __typename?: 'EngagementViewsByOrgResult';
  country?: Maybe<Scalars['String']['output']>;
  marketType?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
};

export type EngagementViewsByUserInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['Date']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EngagementViewsByUserResult = {
  __typename?: 'EngagementViewsByUserResult';
  country?: Maybe<Scalars['String']['output']>;
  downloads?: Maybe<EngagementViewUserDownloads>;
  email: Scalars['String']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['Date']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  sessions?: Maybe<Scalars['Int']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  viewedHighlights?: Maybe<Scalars['Int']['output']>;
};

export type EngagementWeeklyUserCount = {
  __typename?: 'EngagementWeeklyUserCount';
  date: Scalars['Date']['output'];
  userCount: Array<Maybe<EngagementUserCount>>;
};

export type EngagementWeeklyUsersInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EngagementWeeklyUsersResult = {
  __typename?: 'EngagementWeeklyUsersResult';
  quoteDueDate?: Maybe<Scalars['Date']['output']>;
  renewalEffectiveDate?: Maybe<Scalars['Date']['output']>;
  submissionDate?: Maybe<Scalars['Date']['output']>;
  weeklyUserCount?: Maybe<Array<Maybe<EngagementWeeklyUserCount>>>;
};

export type EnrichmentAttributeChange = {
  __typename?: 'EnrichmentAttributeChange';
  attributeCnt: Scalars['Int']['output'];
  changeType: ChangeType;
  tiv: Scalars['Float']['output'];
  tivPctImpact: Scalars['Int']['output'];
};

export type EnrichmentAttributeChangeInput = {
  snapshotNameAfter: Scalars['ID']['input'];
  snapshotNameBefore: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EnrichmentAttributeChangeResult = {
  __typename?: 'EnrichmentAttributeChangeResult';
  constructionClass?: Maybe<Array<EnrichmentAttributeChange>>;
  specificOccupancy?: Maybe<Array<EnrichmentAttributeChange>>;
};

export type EnrichmentOverallQualityChangeInput = {
  snapshotNameAfter: Scalars['ID']['input'];
  snapshotNameBefore: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EnrichmentOverallQualityChangeResult = {
  __typename?: 'EnrichmentOverallQualityChangeResult';
  addedAvgScore: Scalars['Float']['output'];
  addedProperties: Scalars['Int']['output'];
  addedTiv: Scalars['Float']['output'];
  disposedAvgScore: Scalars['Float']['output'];
  disposedProperties: Scalars['Int']['output'];
  disposedTiv: Scalars['Float']['output'];
  enrichedAvgImprovement: Scalars['Float']['output'];
  enrichedPctOfTiv: Scalars['Int']['output'];
  enrichedProperties: Scalars['Int']['output'];
  enrichedTiv: Scalars['Float']['output'];
  snapshotPercentile: Scalars['Int']['output'];
  snapshotScoreAfter: Scalars['Float']['output'];
  snapshotScoreBefore: Scalars['Float']['output'];
  streamCount?: Maybe<Array<ScoreCount>>;
  totalStreamCount: Scalars['Int']['output'];
};

export type EnrichmentProperty = {
  __typename?: 'EnrichmentProperty';
  clientID: Scalars['ID']['output'];
  locationName: Scalars['String']['output'];
};

export type EnrichmentPropertyAttribute = {
  __typename?: 'EnrichmentPropertyAttribute';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type EnrichmentPropertyImprovementInput = {
  snapshotNameAfter: Scalars['ID']['input'];
  snapshotNameBefore: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EnrichmentPropertyImprovementResult = {
  __typename?: 'EnrichmentPropertyImprovementResult';
  attributes?: Maybe<Array<EnrichmentPropertyAttribute>>;
  locationName: Scalars['String']['output'];
};

export type EnrichmentPropertyPriorityInput = {
  amountOfProperties: Scalars['Int']['input'];
  snapshotNameAfter: Scalars['ID']['input'];
  snapshotNameBefore: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type EnrichmentPropertyPriorityResult = {
  __typename?: 'EnrichmentPropertyPriorityResult';
  dqsImprovementPct: Scalars['Float']['output'];
  propertiesCount: Scalars['Int']['output'];
  suggestedProperties?: Maybe<Array<EnrichmentProperty>>;
};

export type EnterpriseDashboardMetadataInput = {
  orgName: Scalars['String']['input'];
};

export type EnterpriseDashboardMetadataResponse = {
  __typename?: 'EnterpriseDashboardMetadataResponse';
  dashboards?: Maybe<Array<Dashboard>>;
  enterpriseReportingHostURL: Scalars['String']['output'];
  signedJWT: Scalars['String']['output'];
  workspaceID: Scalars['String']['output'];
};

export type EnumAttributeValues = {
  __typename?: 'EnumAttributeValues';
  attributeName: Scalars['AttributeName']['output'];
  /**  List of enum values for this attribute.  */
  values?: Maybe<Array<EnumValueMetadata>>;
};

export type EnumValueInput = {
  attributeName: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type EnumValueList = {
  __typename?: 'EnumValueList';
  listDisplayName: Scalars['String']['output'];
  /**
   * Enum value list to store a value and corresponding list of values for attributes.
   * Example: Enum values for Industry and Business type used on Org, Stream and Submission.
   *     {
   *         "valueDisplayName": "Industry",
   *         "listDisplayName": "Business Type",
   *         "valueList": [
   *             {
   *                 "value": "Technology",
   *                 "list": [
   *                     "General Technology",
   *                     "Data Centers"
   *                 ]
   *             }
   *         ]
   *     }
   */
  valueDisplayName: Scalars['String']['output'];
  valueList?: Maybe<Array<ValueList>>;
};

export type EnumValueMetadata = {
  __typename?: 'EnumValueMetadata';
  /**  Cluster value of attributes constructionType and occupancyType like [Steel,...]  */
  clusterValue?: Maybe<Scalars['String']['output']>;
  externalMappings?: Maybe<Array<ExternalMapping>>;
  /**  Hex color code of the value used for explorers microchart and donut colors  */
  hexColorCode?: Maybe<Scalars['String']['output']>;
  /**  Order of the attribute value like [1,2,...]  */
  position: Scalars['Int']['output'];
  softMappings?: Maybe<Array<Maybe<SoftMapping>>>;
  /**  System/platform defined attribute value like [Wood frame,Retail trade,...]  */
  value: Scalars['String']['output'];
  /**  The value used for display instead of 'value'  */
  valueDisplay?: Maybe<Scalars['String']['output']>;
  /**  System/platform defined id of attribute value like [0,1,FEMA-A,W1,O1,...]  */
  valueId: Scalars['String']['output'];
};

/**
 *  Archipelago extensions to GraphQL error. This type defines the names of keys returned in GraphQL response
 * map field `errors.extensions` as defined in http://spec.graphql.org/October2021/#sec-Errors
 */
export enum ErrorExtension {
  /**  Denotes type of error. Value of this map key is a string -- see #ErrorType for possible values  */
  Code = 'Code',
  /**  Value of this key describes the error type  */
  Details = 'Details',
  /**
   * Value of this key contains an opaque id of the error instance for reference. Include this value when
   *   reporting any errors.
   */
  Id = 'ID'
}

/**  Possible values for ErrorExtension 'Code'  */
export enum ErrorType {
  /**
   * There was a unexpected error on the server while processing this request. If the request fails, repeatedly
   *   this should be reported including the `ID` field in the error.extensions field.
   */
  InternalServerError = 'InternalServerError',
  /**  Input provided for request is invalid. Caller should review Details field and modify the request.  */
  InvalidUserInput = 'InvalidUserInput',
  /**  Caller does not have permissions for requested resources. */
  UnAuthorized = 'UnAuthorized'
}

export type ExplorerData = {
  __typename?: 'ExplorerData';
  /**  name of current and prior snapshots used by explorers for YoY comparisons  */
  currentSnapshot?: Maybe<Scalars['String']['output']>;
  groups: Array<ExplorerGroups>;
  previousSnapshot?: Maybe<Scalars['String']['output']>;
};

export enum ExplorerGroup {
  DisabledExplorers = 'DISABLED_EXPLORERS',
  PropertyGroup = 'PROPERTY_GROUP',
  Quality = 'QUALITY',
  Recommended = 'RECOMMENDED',
  Standard = 'STANDARD'
}

export type ExplorerGroups = {
  __typename?: 'ExplorerGroups';
  charts: Array<Chart>;
  group: ExplorerGroup;
};

export type ExportAttributeMetadataInput = {
  attributeName: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export enum ExportCode {
  Air = 'AIR',
  Archipelago = 'ARCHIPELAGO',
  ChangeAnalysis = 'CHANGE_ANALYSIS',
  Diagnostic = 'DIAGNOSTIC',
  DocumentsArchive = 'DOCUMENTS_ARCHIVE',
  Dqd = 'DQD',
  DqdInternal = 'DQD_INTERNAL',
  Enrichment = 'ENRICHMENT',
  Losses = 'LOSSES',
  Rapid = 'RAPID',
  Rms = 'RMS',
  Zorba = 'ZORBA'
}

export type ExportConfiguration = {
  __typename?: 'ExportConfiguration';
  configuration?: Maybe<Array<Maybe<ExportConfigurationAttribute>>>;
  configurationSlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizationID: Scalars['String']['output'];
  revisionID: Scalars['ID']['output'];
  streamID?: Maybe<Scalars['String']['output']>;
};

export type ExportConfigurationAttribute = {
  __typename?: 'ExportConfigurationAttribute';
  attributeName: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type ExportConfigurationsInput = {
  orgName?: InputMaybe<Scalars['String']['input']>;
  /** either organizationId or orgName" should be set  */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  streamID?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDataV3Input = {
  consumerOrgID?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  role: Scalars['String']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type ExportDocument = {
  __typename?: 'ExportDocument';
  extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  s3Path: Scalars['String']['output'];
};

export type ExportKeyValue = {
  key: Scalars['String']['input'];
  value: Scalars['Any']['input'];
};

export type ExportMetadataInput = {
  snapshot?: InputMaybe<Scalars['String']['input']>;
  streamID: Scalars['String']['input'];
};

export type ExportMetadataV3Input = {
  consumerOrgID?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamID: Scalars['String']['input'];
};

export type ExportProgressInput = {
  exportJobId: Scalars['String']['input'];
};

export type ExportProgressV3Input = {
  /**  Time at which the download link will expire. 1 hour if unspecified.  */
  expiry?: InputMaybe<Scalars['Time']['input']>;
  exportCode: ExportCode;
  /**
   * exportID is a UUID generated by the event producer and is stored in the export table
   * and also returned by the export services as part of the progress message.
   * for a new export request, this will be generated by the caller
   * for an export request for which there is an existing pre-generated file, exportID will be not available to the caller
   * and it will be returned as part of the exportStatus API response by export-orchestrator.
   */
  exportID: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  snapshotToName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type ExportPropertyRecommendationsInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type ExportPropertyRecommendationsResponse = {
  __typename?: 'ExportPropertyRecommendationsResponse';
  downloadURL: Scalars['String']['output'];
};

export type ExportResult = {
  __typename?: 'ExportResult';
  /**  Signed download URL to the export   */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  exportJobId: Scalars['String']['output'];
  status: ExportStatus;
};

export type ExportResultV3 = {
  __typename?: 'ExportResultV3';
  /**  Signed download URL to the export   */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /**
   * exportID is a UUID generated by the event producer and is stored in the export table
   * and also returned by the export services as part of the progress message.
   * for a new export request, this will be generated by the caller
   * for an export request for which there is an existing pre-generated file, exportID will be not available to the caller
   * and it will be returned as part of the exportStatus API response by export-orchestrator.
   */
  exportID: Scalars['String']['output'];
  status: ExportStatus;
};

export type ExportStartInput = {
  exportCode: ExportCode;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  /** Extra metadata fields for the exports. Details are in the graphql query documentation. */
  metadata?: InputMaybe<Array<ExportKeyValue>>;
  /** organizationName for Documents Archive Export */
  organizationName?: InputMaybe<Scalars['String']['input']>;
  /**  The system will return export data as of this snapshot date  */
  snapshot?: InputMaybe<Scalars['String']['input']>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type ExportStartV3Input = {
  /**  Time at which the download link will expire. 1 hour if unspecified.  */
  expiry?: InputMaybe<Scalars['Time']['input']>;
  exportCode: ExportCode;
  /** Standard attribute filter input */
  filters?: InputMaybe<Array<AttributeFilter>>;
  /** Extra metadata fields for the exports. Details are in the graphql query documentation. */
  metadata?: InputMaybe<Array<ExportKeyValue>>;
  /** organizationName for Documents Archive Export */
  organizationName: Scalars['String']['input'];
  /**  The system will return export data as of this snapshot's date  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
  /**  Optional attribute list to filter and order columns  */
  visibleAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ExportStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  GeneratingIntermediateData = 'GENERATING_INTERMEDIATE_DATA',
  IntermediateDataGenerated = 'INTERMEDIATE_DATA_GENERATED',
  NotFound = 'NOT_FOUND',
  Started = 'STARTED'
}

export type ExposureAttributeMetrics = {
  __typename?: 'ExposureAttributeMetrics';
  /**  Percent of stream TIV with exposure  */
  TIVPct: Scalars['Float']['output'];
  /**  Display name of the attribute  */
  displayName: Scalars['String']['output'];
  /**  Grade definitions for exposure metrics of the Stream's industry type.  */
  gradeDefinitions: Array<ExposureGradeDefinition>;
  /**  Attribute group name  */
  groupName: Scalars['String']['output'];
  /**  Exposure grade for the attribute  */
  indexGrade: ExposureGrade;
  /**  Name of the attribute  */
  name: Scalars['String']['output'];
  /**  Exposure percentile within the industry  */
  percentile: Scalars['Float']['output'];
};

export type ExposureAttributePercentiles = {
  __typename?: 'ExposureAttributePercentiles';
  percentile: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ExposureAvailableSnapshotsInput = {
  streamSlug: Scalars['String']['input'];
};

export type ExposureAvailableSnapshotsResult = {
  __typename?: 'ExposureAvailableSnapshotsResult';
  date: Scalars['Time']['output'];
  displayDate?: Maybe<Scalars['Time']['output']>;
  snapshotName: Scalars['String']['output'];
};

export enum ExposureGrade {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW'
}

export type ExposureGradeDefinition = {
  __typename?: 'ExposureGradeDefinition';
  /**  Exposure grade which corresponds with percentiles between minPercentile and maxPercentile.  */
  indexGrade: ExposureGrade;
  /**  Name of the industry for which the grade definition applies.  */
  industryName: Scalars['String']['output'];
  /**  Maximum percentile for which the grade level applies within the industry.  */
  maxPercentileThreshold: Scalars['Float']['output'];
  /**
   * Maximum attribute value which falls between the min and max percentile thresholds.
   * Only applicable in the context of a specific attribute.
   */
  maxPercentileValue: Scalars['Float']['output'];
  /**  Minimum percentile for which the grade level applies within the industry.  */
  minPercentileThreshold: Scalars['Float']['output'];
  /**
   * Minimum attribute value which falls between the min and max percentile thresholds.
   * Only applicable in the context of a specific attribute.
   */
  minPercentileValue: Scalars['Float']['output'];
};

export type ExposureGradeMetrics = {
  __typename?: 'ExposureGradeMetrics';
  indexGrade: ExposureGrade;
  /**  Number of streams with the grade  */
  streamCount: Scalars['Int']['output'];
};

export type ExposureIndexRank = {
  __typename?: 'ExposureIndexRank';
  /**  Industry metrics by grade  */
  grades: Array<ExposureGradeMetrics>;
  /**  Name of the industry for which the rank applies  */
  industryName: Scalars['String']['output'];
  /**  Stream's exposure rank within the industry  */
  rank: Scalars['Int']['output'];
  /**  Total number of Archipelago streams within the industry  */
  streamCount: Scalars['Int']['output'];
};

export type ExposureIndexStatus = {
  __typename?: 'ExposureIndexStatus';
  /**  Overall exposure index for the snapshot. Null if the exposure index has not been calculated for this snapshot.  */
  index?: Maybe<Scalars['Float']['output']>;
  /**  Overall exposure grade for the snapshot. Null if the exposure index has not been calculated for this snapshot.  */
  indexGrade?: Maybe<ExposureGrade>;
  /**  Status of the exposure index calculation for the snapshot  */
  status: IndexCalculationStatus;
};

export type ExposureIndustryPercentilesInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
};

export type ExposureIndustryPercentilesResult = {
  __typename?: 'ExposureIndustryPercentilesResult';
  attributeName: Scalars['String']['output'];
  percentiles?: Maybe<Array<ExposureAttributePercentiles>>;
};

export type ExposureMetrics = {
  __typename?: 'ExposureMetrics';
  /**  Exposure score metrics by attribute  */
  attributeScores: Array<ExposureAttributeMetrics>;
  /**  Overall exposure index of the stream  */
  index: Scalars['Float']['output'];
  /**  Overall exposure grade of the stream  */
  indexGrade: ExposureGrade;
  /**  Stream's overall rank within the exposure index  */
  rank: ExposureIndexRank;
};

export type ExposureMetricsRefreshStat = {
  __typename?: 'ExposureMetricsRefreshStat';
  endTime: Scalars['Time']['output'];
  error?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['Time']['output'];
  success: Scalars['Boolean']['output'];
};

export type ExposureMetricsRefreshStatsResult = {
  __typename?: 'ExposureMetricsRefreshStatsResult';
  /**  Latest exposure metrics refresh stats. Null if no refresh has occurred.  */
  latestRefresh?: Maybe<ExposureMetricsRefreshStat>;
};

export enum ExternalCoding {
  Air = 'AIR',
  Atc = 'ATC',
  Default = 'DEFAULT',
  Iso = 'ISO',
  Naics = 'NAICS',
  Rms = 'RMS',
  RmsEq = 'RMS_EQ',
  Sic = 'SIC',
  Unknown = 'UNKNOWN'
}

export type ExternalMapping = {
  __typename?: 'ExternalMapping';
  /**  External coding value like [RMS,ATC,AIR,...]  */
  externalCoding: ExternalCoding;
  value: Scalars['String']['output'];
  /**  External value id of attribute like [RMS-3A1,ATC-6,ISO-2,...]  */
  valueId: Scalars['String']['output'];
};

export type ExternalStreamViewersInput = {
  /**
   * Cursor is a string used for cursor-based pagination and is generated by the system.
   * If cursor is specified the other parameters are ignored.
   */
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserStreamFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type Field = {
  __typename?: 'Field';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum FileType {
  Document = 'document',
  Image = 'image'
}

export type FinalizeSubmissionInput = {
  submissionID: Scalars['ID']['input'];
};

export type FinalizeSubmissionOutput = {
  __typename?: 'FinalizeSubmissionOutput';
  submission: Submission;
};

export type FloatTupple = {
  __typename?: 'FloatTupple';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GeoJobData = {
  __typename?: 'GeoJobData';
  properties?: Maybe<Array<GeoJobProperty>>;
};

export type GeoJobDataInput = {
  jobID: Scalars['ID']['input'];
  properties?: InputMaybe<Array<GeoJobPropertyInput>>;
};

export type GeoJobProperty = {
  __typename?: 'GeoJobProperty';
  new?: Maybe<GeoJobPropertyData>;
  original?: Maybe<GeoJobPropertyData>;
  priority?: Maybe<Scalars['Int']['output']>;
  suggested?: Maybe<GeoJobPropertyData>;
};

export type GeoJobPropertyData = {
  __typename?: 'GeoJobPropertyData';
  archipelagoId: Scalars['String']['output'];
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  milesToCoast?: Maybe<Scalars['Float']['output']>;
  originalStreet?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
};

export type GeoJobPropertyInput = {
  archipelagoId: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geoCodeType?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type GetSubmissionInput = {
  id: Scalars['ID']['input'];
};

export type GlobalPermissions = {
  __typename?: 'GlobalPermissions';
  /**
   * Indicates current user can access
   * - all business resources on all organizations.
   * - admin UI controls
   */
  admin: Scalars['Boolean']['output'];
  /** Indicates if current user has edit permissions on at least one managed org. */
  hasOrgSwitcher: Scalars['Boolean']['output'];
  myJobs: Scalars['Boolean']['output'];
  /** Indicates current user has no edit permissions on any business resources */
  viewOnly: Scalars['Boolean']['output'];
};

export type Grade = {
  __typename?: 'Grade';
  /**  Grade category name (e.g. Low Risk, High, Fair)  */
  category?: Maybe<Scalars['String']['output']>;
  /**  String code of the grade, if applicable (e.g. A, B, C, D)  */
  code?: Maybe<Scalars['String']['output']>;
  /**  Category color (e.g. Blue, Green, or Red)  */
  color?: Maybe<Scalars['String']['output']>;
  /**  Numeric value of the grade, if applicable  */
  value?: Maybe<Scalars['Float']['output']>;
};

export type GradeScheme = {
  __typename?: 'GradeScheme';
  grades: Array<Grade>;
};

export enum HazardCategory {
  All = 'All',
  Any = 'Any',
  ConvectiveStorm = 'ConvectiveStorm',
  Earthquake = 'Earthquake',
  Fire = 'Fire',
  Flood = 'Flood',
  Wind = 'Wind'
}

export type Histogram = {
  __typename?: 'Histogram';
  /**  Buckets grouping counts by value ranges  */
  buckets: Array<HistogramBucket>;
  /**  Time period represented in the histogram (e.g. 2016-2020)  */
  period?: Maybe<Scalars['String']['output']>;
};

export type HistogramBucket = {
  __typename?: 'HistogramBucket';
  /**  Category of the bucket  */
  category: ClaimCategory;
  /**  Number which occur within the bounds of the bucket  */
  cnt: Scalars['Int']['output'];
  /**  Percent of the total count for all buckets  */
  pctOfTotal: Scalars['Float']['output'];
};

export enum ImageSize {
  Medium = 'MEDIUM',
  Original = 'ORIGINAL',
  Small = 'SMALL'
}

export type ImageUrLs = {
  __typename?: 'ImageURLs';
  /**  300x300  */
  medium: Scalars['String']['output'];
  /**  original document dimensions  */
  original: Scalars['String']['output'];
  /**  40x40  */
  small: Scalars['String']['output'];
};

export type ImportCarrierMetricsInput = {
  reportName: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
  worksheet: Scalars['Upload']['input'];
};

export type ImportCarrierMetricsResult = {
  __typename?: 'ImportCarrierMetricsResult';
  errors?: Maybe<Array<CarrierMetricDataError>>;
  id: Scalars['ID']['output'];
  status: CarrierMetricImportStatus;
};

export type ImportPropertyRecommendationsInput = {
  document: Scalars['Upload']['input'];
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type ImportPropertyRecommendationsResponse = {
  __typename?: 'ImportPropertyRecommendationsResponse';
  mlRecommendationsStatus: PropertyRecommendationsStatus;
};

export type InboxInput = {
  orgName: Scalars['String']['input'];
};

export type InboxItem = {
  __typename?: 'InboxItem';
  assignee?: Maybe<Scalars['String']['output']>;
  assigneeProfile?: Maybe<UserProfile>;
  chatRoomInfo?: Maybe<ChatRoomInfo>;
  creationDate: Scalars['String']['output'];
  descriptionLine1?: Maybe<Scalars['String']['output']>;
  descriptionLine2?: Maybe<Scalars['String']['output']>;
  itemID: Scalars['String']['output'];
  priority?: Maybe<Scalars['String']['output']>;
  /**  Archipelago IDs for properties linked to the current task or mention */
  propertyArchipelagoIDs?: Maybe<Array<Scalars['String']['output']>>;
  subject: Scalars['String']['output'];
  taskType?: Maybe<TaskType>;
  type: InboxItemType;
};

export enum InboxItemType {
  Mention = 'Mention',
  Task = 'Task'
}

export type InboxResponse = {
  __typename?: 'InboxResponse';
  all?: Maybe<Array<InboxItem>>;
  assigned?: Maybe<Array<InboxItem>>;
  mentions?: Maybe<Array<InboxItem>>;
  unassigned?: Maybe<Array<InboxItem>>;
};

export enum IndexCalculationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Retrying = 'RETRYING',
  Started = 'STARTED'
}

export type InputCoordinates = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type Insight = {
  __typename?: 'Insight';
  filters?: Maybe<Array<AnalyticsFilter>>;
  id: Scalars['String']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InvalidateExportInput = {
  /** type of export */
  exportType: Scalars['String']['input'];
  /** invalidate intermediate data */
  invalidateIntermediateData?: InputMaybe<Scalars['Boolean']['input']>;
  /** organizationID: when provided all streams and snapshots for that org are invalidated */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  /** snapshotID: when provided only this snapshot is invalidated, if it is the latest stream view the value is latest */
  snapshotID?: InputMaybe<Scalars['String']['input']>;
  /** streamID: when provided all snapshots for that org and stream are invalidated */
  streamID?: InputMaybe<Scalars['String']['input']>;
};

export type Job = {
  __typename?: 'Job';
  /**
   * Attributes to be enriched as part of this job. Updates to any other attributes are
   *   not be accepted by the system on submission.
   */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**  Deprecated. Used by type VirtualInspection to return any associated email templates.  */
  details?: Maybe<Scalars['String']['output']>;
  doerProfile?: Maybe<UserProfile>;
  dueOn?: Maybe<Scalars['Date']['output']>;
  /**
   * Time at which this job expires and releases any locks on underlying properties and/or attributes.
   *   Only get and find operation is permitted after this time. Get and find operation will include expired jobs.
   */
  expiryTime?: Maybe<Scalars['Time']['output']>;
  /**  System generated identifier for this job. */
  id: Scalars['ID']['output'];
  /**  List of state transitions, ordered by most recent first. */
  lifeCycle?: Maybe<Array<StateTransition>>;
  name: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  priority?: Maybe<JobPriority>;
  projectName: Scalars['String']['output'];
  /**
   * Optional list of properties subject of this job. If not provided all properties
   *   in the org are implied, and an error will be returned if the org contains more than
   *   10k properties.
   */
  propertyIDs?: Maybe<Array<Scalars['ArchipelagoID']['output']>>;
  /**  Optional list of system documents for reference from this job  */
  references?: Maybe<Array<Document>>;
  requesterProfile?: Maybe<UserProfile>;
  reviewerProfile?: Maybe<UserProfile>;
  /**  list of snapshots that were created on the project of this job  */
  snapshots?: Maybe<Array<Snapshot>>;
  /**
   * Only applicable to col mapping and ingest jobs. system doc id for the customer SOV
   *   which is the data source for this job.
   */
  sovDocId?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  /**  Stream associated with job.  */
  streamSlug?: Maybe<Scalars['String']['output']>;
  type: JobType;
  /**
   * version of job type. It is used for Provenance enrichment job now(3/15/2022).
   *       Default value is 1. Only `jobsV2` and `job` query return it.
   *
   *       value  |  meaning
   *       2         PE2
   */
  version?: Maybe<Scalars['Int']['output']>;
  /**
   * Only applies to Enrichment jobs. If set to SingleColumn, api will generate
   *   the worksheet without .New and .Suggested columns
   */
  worksheetFormat: WorksheetFormat;
};

export enum JobPriority {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export enum JobStatus {
  Archived = 'Archived',
  ChangesRequested = 'ChangesRequested',
  Complete = 'Complete',
  /**  All locks are released and only get, find operations are allowed.  */
  Expired = 'Expired',
  /**  Indicates unexpected failures which require manual review.  */
  Failed = 'Failed',
  InProgress = 'InProgress',
  /**  Initial state. A Job cannot be set to this state.  */
  None = 'None',
  /**  The job failed failed property data validation.  */
  PropertyDataValidateFailed = 'PropertyDataValidateFailed',
  /**  The job's added or edited property data has been validated.  */
  PropertyDataValidated = 'PropertyDataValidated',
  PropertyUpdatesCompleted = 'PropertyUpdatesCompleted',
  Ready = 'Ready',
  ReadyForReview = 'ReadyForReview',
  /**  Indicates the revisions created for the job are removed. The most recent job can be reverted.  */
  Reverted = 'Reverted',
  ValidateFailed = 'ValidateFailed',
  /**  The job failed worksheet validation.  */
  WorksheetValidateFailed = 'WorksheetValidateFailed',
  /**  The job's worksheet has been validated.  */
  WorksheetValidated = 'WorksheetValidated'
}

export enum JobType {
  AddLosses = 'AddLosses',
  AddProperties = 'AddProperties',
  AddProperty = 'AddProperty',
  ColumnMapping = 'ColumnMapping',
  DeleteProperties = 'DeleteProperties',
  DisposeProperties = 'DisposeProperties',
  EditLosses = 'EditLosses',
  EditProperties = 'EditProperties',
  EditProperty = 'EditProperty',
  Enrichment = 'Enrichment',
  EnrichmentWithProvenance = 'EnrichmentWithProvenance',
  Geo = 'Geo',
  Ingest = 'Ingest',
  LocationIngest = 'LocationIngest',
  NonLocationIngest = 'NonLocationIngest',
  PalIngest = 'PALIngest',
  PreCleanse = 'PreCleanse',
  RestoreDeletedProperties = 'RestoreDeletedProperties',
  RestoreDisposedProperties = 'RestoreDisposedProperties'
}

export type JobWorksheetProgressInput = {
  orgName: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
};

export type JobWorksheetProgressResponse = {
  __typename?: 'JobWorksheetProgressResponse';
  docID?: Maybe<Scalars['ID']['output']>;
  status: JobWorksheetStatus;
};

export type JobWorksheetStartInput = {
  /**  Names of attributes which are editable in this worksheet. */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  effectiveFrom has time.RFC3339 format  */
  effectiveFrom?: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
  format: WorksheetFormat;
  jobID: Scalars['String']['input'];
  jobName: Scalars['String']['input'];
  jobType: JobType;
  /**  List of archipelago ids of selectable properties. Only applies to loss worksheets  */
  lossPropertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  principalID?: InputMaybe<Scalars['String']['input']>;
  /**  List of propertyIDs to be included in the worksheet.  */
  propertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  provenanceDocID?: InputMaybe<Scalars['String']['input']>;
  s3Key: Scalars['String']['input'];
  streamID?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['String']['input']>;
};

export type JobWorksheetStartResponse = {
  __typename?: 'JobWorksheetStartResponse';
  docID?: Maybe<Scalars['ID']['output']>;
  /**
   * docID is only set when status is Completed. If status is Started, jobWorksheetProgress should be
   * called to get docID.
   * For JobTypeEnrichmentWithProvenance and JobTypeAddProperties, worksheet creation is synchronous
   * and docID is returned in the response.
   */
  status: JobWorksheetStatus;
};

export enum JobWorksheetStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Started = 'Started'
}

export type JobsFilter = {
  /**  Filter any job status that's not Archived, Complete or Expired  */
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /**  returns jobs with specified jobIDs  */
  jobIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgID?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  propertyID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<JobType>;
  userID?: InputMaybe<Scalars['String']['input']>;
};

export type JobsPage = {
  __typename?: 'JobsPage';
  jobs: Array<Job>;
  /**  information about this jobs page  */
  pageInfo: PageInfo;
};

export type JoinChatRoomInput = {
  roomName: Scalars['String']['input'];
};

export type JoinChatRoomOutput = {
  __typename?: 'JoinChatRoomOutput';
  success: Scalars['Boolean']['output'];
};

export enum LineOfBusiness {
  Al = 'AL',
  Apd = 'APD',
  Cyber = 'Cyber',
  Do = 'DO',
  Eo = 'EO',
  Excess = 'Excess',
  Gl = 'GL',
  NonCatPropertyBuilding = 'NonCatPropertyBuilding',
  NonCatPropertyBusinessInterruption = 'NonCatPropertyBusinessInterruption',
  NonCatPropertyBusinessPersonalProperty = 'NonCatPropertyBusinessPersonalProperty',
  NonCatPropertyPersonalPropertyOfOthers = 'NonCatPropertyPersonalPropertyOfOthers',
  Wc = 'WC'
}

export type Lock = {
  __typename?: 'Lock';
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**  If true, current user can continue using this job instead of creating a new one */
  canReuse: Scalars['Boolean']['output'];
  canStart: Scalars['Boolean']['output'];
  expiryTime?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  propertyIds?: Maybe<Array<Scalars['String']['output']>>;
  type: LockType;
  worksheetURL?: Maybe<Scalars['String']['output']>;
};

export type LockPropertyAttributesInput = {
  /**
   * Only attributes matching the provided list of attributeNames are locked for edits.
   * If no attributeNames are provided, locks will be checked for all attributes.
   */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  Number of hours until the lock expires. A maximum of 336 hours may be specified.  */
  expireInHours?: InputMaybe<Scalars['Int']['input']>;
  orgID: Scalars['ID']['input'];
  /**
   * Only properties matching the propertyFilter are locked for edits. If propertyFilter
   * is not specified, all properties are locked.
   */
  propertyFilter?: InputMaybe<Array<AttributeFilter>>;
  /** If streamSlug is provided, only properties that are members of the stream are locked. */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type LockPropertyAttributesResponse = {
  __typename?: 'LockPropertyAttributesResponse';
  /** Names of attributes which have been locked. If all attributes are locked, no attributeNames are returned. */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Details about existing locks which prevented the requested property attributes from being locked.
   * Conflicts are only returned if locking the property attributes was not successful.
   * Only the first 100 conflicts are returned.
   */
  conflicts?: Maybe<Array<AttributeLockConflict>>;
  /**
   * ID for the group of property attributes locked. This can be used to update the locks for all the property attributes
   * using updatePropertyLocks.
   */
  groupID: Scalars['ID']['output'];
  /** IDs of properties which matched the filter specified in the request and have been locked for editing. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
  /**
   * If an active lock already exists for any of the requested properties/attributes, the success field of the response
   * will be false.
   */
  success: Scalars['Boolean']['output'];
};

export enum LockType {
  AddLosses = 'AddLosses',
  AddProperties = 'AddProperties',
  EditLosses = 'EditLosses',
  EditProperties = 'EditProperties',
  EditProperty = 'EditProperty',
  Enrichment = 'Enrichment'
}

export type Loss = {
  __typename?: 'Loss';
  archipelagoLossId: Scalars['String']['output'];
  archipelagoPropertyId: Scalars['String']['output'];
  catastrophe?: Maybe<Scalars['Boolean']['output']>;
  catastropheEventName?: Maybe<Scalars['String']['output']>;
  clientClaimId?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Scalars['Float']['output']>;
  generalCauseOfLoss?: Maybe<Scalars['String']['output']>;
  grossLossIncurred?: Maybe<Scalars['Float']['output']>;
  grossTotalLoss?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lossCurrency?: Maybe<Scalars['String']['output']>;
  lossDate?: Maybe<Scalars['Date']['output']>;
  lossDescription?: Maybe<Scalars['String']['output']>;
  lossStatus?: Maybe<Scalars['String']['output']>;
  occurrenceNumber?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  paid?: Maybe<Scalars['Float']['output']>;
  program?: Maybe<Program>;
  property: Property;
  reserve?: Maybe<Scalars['Float']['output']>;
  specificCauseOfLoss?: Maybe<Scalars['String']['output']>;
};

export type LossAttributeMetadata = {
  __typename?: 'LossAttributeMetadata';
  dataType: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  displayWidth: Scalars['Int']['output'];
  /** List of values for this attribute. */
  enumMetadata?: Maybe<Array<EnumValueMetadata>>;
  name: Scalars['ID']['output'];
  parent?: Maybe<Scalars['String']['output']>;
};

export type LossAttributeMetadataInput = {
  /**  If true, only return attributes supported by EditLosses jobs */
  editableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type LossAttributes = {
  __typename?: 'LossAttributes';
  largestLoss?: Maybe<Scalars['Float']['output']>;
  lossCountSinceLastBound?: Maybe<Scalars['Int']['output']>;
  lossCountTotal?: Maybe<Scalars['Int']['output']>;
  totalGrossLoss?: Maybe<Scalars['Float']['output']>;
};

export type LossByPropertyInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['String']['input']>;
};

export type LossCostTrendChart = {
  __typename?: 'LossCostTrendChart';
  avgLossCost?: Maybe<YearsFloat>;
  trend?: Maybe<Array<TrendValue>>;
};

export type LossDataInput = {
  archipelagoPropertyId?: InputMaybe<Scalars['String']['input']>;
  catastrophe?: InputMaybe<Scalars['Boolean']['input']>;
  catastropheEventName?: InputMaybe<Scalars['String']['input']>;
  clientClaimId?: InputMaybe<Scalars['String']['input']>;
  customAttributes?: InputMaybe<Array<InputMaybe<CustomAttribute>>>;
  deductible?: InputMaybe<Scalars['Float']['input']>;
  generalCauseOfLoss?: InputMaybe<Scalars['String']['input']>;
  grossLossIncurred?: InputMaybe<Scalars['Float']['input']>;
  grossTotalLoss?: InputMaybe<Scalars['Float']['input']>;
  lossCurrency?: InputMaybe<Scalars['String']['input']>;
  lossDate?: InputMaybe<Scalars['Date']['input']>;
  lossDescription?: InputMaybe<Scalars['String']['input']>;
  lossStatus?: InputMaybe<Scalars['String']['input']>;
  occurrenceNumber?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  /** Used for historical loading, if not specified, the change timeframe is assumed to be current. */
  revisionDate?: InputMaybe<Scalars['Time']['input']>;
  specificCauseOfLoss?: InputMaybe<Scalars['String']['input']>;
};

export type LossFilter = {
  /**  A list of Archipelago Loss IDs.  */
  archipelagoLossIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  A list of Archipelago Property IDs.  */
  archipelagoPropertyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LossGeography = {
  __typename?: 'LossGeography';
  /**  Losses grouped by state  */
  states?: Maybe<Array<LossRegion>>;
};

export type LossMetric = {
  __typename?: 'LossMetric';
  customerName?: Maybe<Scalars['String']['output']>;
  /**  Display name of the metric (e.g. Total Paid Indemnity)  */
  displayName?: Maybe<Scalars['String']['output']>;
  /**  Period the metric applies to (e.g. 2016-2020)  */
  period?: Maybe<Scalars['String']['output']>;
  policyId?: Maybe<Scalars['String']['output']>;
  /**  Metric section (e.g. Customer Average Loss or Customer Loss Frequency)  */
  section?: Maybe<Scalars['String']['output']>;
  /**  Unit for the metric (e.g. USD)  */
  unit?: Maybe<Scalars['String']['output']>;
  /**  Metric value  */
  value?: Maybe<Scalars['Float']['output']>;
};

export type LossRegion = {
  __typename?: 'LossRegion';
  /**  ISO Alpha 2 code for the region  */
  isoAlpha2Code: Scalars['String']['output'];
  /**  Frequency of losses in the region  */
  lossFrequency?: Maybe<Scalars['Float']['output']>;
  /**  Name of the region  */
  name: Scalars['String']['output'];
  /**  Number of open RIAs in the region  */
  riaCnt?: Maybe<Scalars['Int']['output']>;
};

export type LossesByPropertyInput = {
  /**  A list of Property Archipelago IDs.  */
  archipelagoIds: Array<Scalars['String']['input']>;
  /**  If not specified, latest visible snapshot on the stream is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /**  The stream slug in which context to search for the properties.  */
  streamSlug: Scalars['String']['input'];
};

export type LossesByStreamInput = {
  /**  If not specified, latest visible snapshot is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type LossesForEnrichmentInput = {
  jobId: Scalars['String']['input'];
  selectedLossIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LossesInput = {
  lossFilter?: InputMaybe<LossFilter>;
  organizationId: Scalars['String']['input'];
};

export enum MlStatus {
  Completed = 'COMPLETED',
  Na = 'NA',
  Pending = 'PENDING'
}

export type MpAddWorksheetInput = {
  worksheet: MpWorksheetInput;
};

export type MpAddWorksheetResponse = {
  __typename?: 'MPAddWorksheetResponse';
  docID: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
};

export type MpEditWorksheetStartInput = {
  /**
   * Names of editable attributes for edit styling.
   * All enabled, non-derived attributes are included if this list is not provided.
   */
  editableAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Properties matching the included filter will be included in the worksheet. Archipelago IDs matching this filter are
   * returned in the response.
   * If specified filter does not match any property, the response returns an error.
   *
   * If not specified this matches all properties in the stream.
   */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /**
   * Names of attributes which are included in the first columns of the worksheet to identify properties.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  identifierAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  worksheet: MpWorksheetInput;
  /**
   * List of attributes to include in the worksheet.
   * All enabled, attributes are included if this list is not provided.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  worksheetAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MpEditWorksheetStartResponse = {
  __typename?: 'MPEditWorksheetStartResponse';
  /**  IDs of property which matched the filter specified in the request  */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
  status: JobWorksheetStatus;
};

export type MpWorksheetInput = {
  filename: Scalars['String']['input'];
  jobID: Scalars['String']['input'];
  jobName: Scalars['String']['input'];
  jobType: JobType;
  orgId: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  streamID: Scalars['String']['input'];
};

/** Resources which a user can create/edit on an organization. */
export type ManagedOrgResources = {
  __typename?: 'ManagedOrgResources';
  /** true if user has any permission to delete/restore properties on any stream in this org. */
  deleteProperties: Scalars['Boolean']['output'];
  /** permission to view/upload documents */
  editDocuments: Scalars['Boolean']['output'];
  /** true if user has any permission to manage losses on any stream in this org. */
  editLosses: Scalars['Boolean']['output'];
  editProjects: Scalars['Boolean']['output'];
  /** true if user has any permission to manage properties on any stream in this org. */
  editProperties: Scalars['Boolean']['output'];
  /**
   * permission to view/create submissions. if submission dashboard is disabled for org, this
   * is null.
   */
  editSubmissions?: Maybe<Scalars['Boolean']['output']>;
  /** true if org contains streams that are explicitly shared with the current user */
  hasStreamInvites?: Maybe<Scalars['Boolean']['output']>;
  /** true if user has permission to import carrier metrics in this org */
  importCarrierMetrics: Scalars['Boolean']['output'];
  /** Indicates if current user has permissions to manage users in this org. */
  manageUsers: Scalars['Boolean']['output'];
  org: OrgInfo;
  /** Indicates if current user has permissions to view accounts. And ONLY view accounts. When this is true then the user has not edit permissions. */
  viewAccounts: Scalars['Boolean']['output'];
  /**
   * Deprecated. Use allowedExports on stream response instead
   * true if user has permission to view RAPID exports in this org
   */
  viewRAPIDExport: Scalars['Boolean']['output'];
};

export type ManagedOrgsInput = {
  /**
   * Count to limit the number of returned managed orgs. Maximum is 100. If provided value is more than 100,
   * server returns at most 100 results.
   */
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  /**  If provided, filters results to managed orgs whose name starts with this provided prefix.  */
  orgNamePrefix?: InputMaybe<Scalars['String']['input']>;
  /**
   * Start index for requested results. This value in combination with maxResults field can be used to scroll through
   * the total list of managed resources. Valid values are 1 to the total number of results on server.
   * If value is more than totalResults, nothing will be returned.
   */
  resultsStartIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type ManagedOrgsResult = {
  __typename?: 'ManagedOrgsResult';
  managedOrgs?: Maybe<Array<ManagedOrgResources>>;
  /**  Total number of managed orgs on server for provided input.  */
  totalResults: Scalars['Int']['output'];
};

export enum Mapper {
  CnaHailZone = 'CNA_HAIL_ZONE',
  CnaSeismicZone = 'CNA_SEISMIC_ZONE',
  CnaSevereStormZone = 'CNA_SEVERE_STORM_ZONE',
  CnaSprinklers = 'CNA_SPRINKLERS',
  CnaWindZone = 'CNA_WIND_ZONE',
  RapidConstruction = 'RAPID_CONSTRUCTION',
  RapidOccupancy = 'RAPID_OCCUPANCY',
  RapidSprinklers = 'RAPID_SPRINKLERS'
}

export enum MapperOutputType {
  Decimal = 'DECIMAL',
  Text = 'TEXT'
}

export type MappingRuleAppliedChange = {
  __typename?: 'MappingRuleAppliedChange';
  /**  The value that the field was changed to  */
  after?: Maybe<Scalars['Any']['output']>;
  /**  The value that the field was changed from  */
  before?: Maybe<Scalars['Any']['output']>;
  /**  The name of the field that was changed  */
  name: Scalars['String']['output'];
  /**  The rule that caused the change  */
  rule: ValidateRuleV2;
};

export type MergeLossAttributesInput = {
  archipelagoLossId: Scalars['String']['input'];
  /**  Job ID creating/updating the program  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  payload: LossDataInput;
};

export type MergePropertyAttributesInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Deprecated - Use recordEdits instead
   *  Insert an editing provenance for updated attributes.
   * Editing provenance is not created for provenanceAttributes values if specified
   */
  createEditingProvenance?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  payload: PropertyDataInput;
  /** Names of attributes for which provenance update should be updated */
  provenanceAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  provenanceDocumentId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Record each attribute that was edited to a non null value in this operation
   * Only applies if author is non admin
   */
  recordEdits?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MergePropertyAttributesWithMultiProvenancesInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  payload: PropertyDataInput;
  provenance?: InputMaybe<Array<UpdateProvenanceInput>>;
};

export type MetadataExport = {
  __typename?: 'MetadataExport';
  lastUpdateNote?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Array<AttributeSchema>>;
  orgInfoMap?: Maybe<Array<UntypedOrgInfo>>;
};

/** Attribute filter input that can take one of more input attribute names.  */
export type MultipleAttributeFilter = {
  /** Any attribute matching with the input values and operator will match this filter */
  attributes: Array<Scalars['String']['input']>;
  operator?: InputMaybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Marks pre-check recommendations as accepted. If a broker accepts the recommendations, status transitions to BrokerAccepted.
   * If a prospect accepts the recommendations, status transitions to ProspectAccepted. Once the recommendations status is
   * ProspectAccepted, the status cannot be changed.
   * If the requester is a prospect, a notification is sent to all brokers indicating that prospects have accepted the recommendations.
   */
  acceptPreCheckRecommendations: AcceptPreCheckRecommendationsResponse;
  /**  Adds a property to the document properties. Only authorized for admin users. */
  addDocumentProperty: Scalars['ID']['output'];
  /**  Adds a reference to the orginial (source) document. Only authorized for admin users. */
  addDocumentSource: Document;
  /**  Add document to a stream. Returns added document ID. Only authorized for admin users. */
  addDocumentToStream: Scalars['ID']['output'];
  /**
   * Users with this domain will automatically be granted access.
   * Can only be used by User Admin, User Manager or Archipelago Admin.
   * A default domain cannot be added by Risk Managers or Brokers, only by admins.
   */
  addDomainAllowList: DomainAllowList;
  /**  Adds an editor comment for a stream property.  */
  addEditorComment: AddEditorCommentResponse;
  /** Add a loss and add it to a program and property, or update a loss */
  addOrUpdateLoss?: Maybe<Loss>;
  /** Add a policy and add it to a program, or update a policy */
  addOrUpdatePolicy?: Maybe<Policy>;
  /**  Add partner valuation data to a property.  */
  addPropertyPartnerValuationData: Scalars['Boolean']['output'];
  /** Add members to a group. This call is idempotent meaning that adding a user twice would not result in two records, nor will it fail the call. */
  addScopeGroupMember: Scalars['Boolean']['output'];
  /**
   * Configures an API Client principal which is granted access to any accounts submitted using the specified organization's dashboard.
   * Returns all the API Client principals configured for the organization.
   * Only for admins.
   */
  addSubmissionAPIClients: AddSubmissionApiClientsResponse;
  addViewedHighlight?: Maybe<UserSystemInfo>;
  /** Grants the user StreamViewer role on the stream. */
  allowAccess: Scalars['Boolean']['output'];
  applyMappingRulesToOrganization: ApplyMappingRulesToPropertiesResult;
  applyMappingRulesToProperties: ApplyMappingRulesToPropertiesResult;
  /**  Apply mapping rules  */
  applyMappingRulesToStream: ApplyMappingRulesToPropertiesResult;
  /**  Soft-Delete a single document or image in the system. Can only be used by Archipelago Users.  */
  archiveDocuments?: Maybe<Array<Scalars['ID']['output']>>;
  /**
   *  Assigns a role across all scope values for a specified type. Role filters are not supported. Returns if a matching assignment
   * already exists.
   */
  assignRoleAllScopeValues: Scalars['Boolean']['output'];
  /**
   * Returns the created/updated assignment and a boolean value if an existing assignment was updated.
   * Existing assignments for specified role, principal and scope are updated with the specified filter.
   */
  assignRoleV2: AssignRoleOutput;
  assignTask: Scalars['Boolean']['output'];
  /** Can process multiple permission grants and revokes in the same transaction. */
  batchProcessRoles: Scalars['Boolean']['output'];
  /**  Triggers calculation of the exposure index for a stream snapshot. Only allowed for admins.  */
  calculateStreamExposureIndex: CalculateStreamExposureIndexResult;
  /**  Calls the Auth0 Authentication API. If the call is successful, the user will receive an email prompting them to change their password.  */
  changePassword: Scalars['Boolean']['output'];
  completeTask: Scalars['Boolean']['output'];
  completeTaskV2: Scalars['Boolean']['output'];
  completeTaskV3: CompleteTaskV3Output;
  /** Configure settings specific to RBAC service. */
  configureAuthorization: AuthorizationConfig;
  /** configure an optional feature for a specific organization */
  configureFeature: OptionalFeature;
  /**  Configures exports for an organization  */
  configureOrgExport: OrgExportConfig;
  /**
   * Updates the region and team options for the selected submission organization.
   * Only for admins.
   */
  configureSubmissionRegions: ConfigureSubmissionRegionsResponse;
  /**
   * Convert a user from one account type to another. For example convert from passwordless to password account.
   * Users can only convert their own accounts. If called by an admin then you can also convert other accounts.
   * This API also returns a password reset link when the ConvertUserInput.desiredConnection is PASSWORD_WITH_MFA.
   */
  convertUser: ConvertUserResponse;
  /** Add a new attribute in the schema. Can be global or org specific */
  createAttribute: Scalars['Boolean']['output'];
  /**
   * Used by the Casualty Dashboard to create a new casualty in the system. In the background this API
   * also creates the Google Spreadsheet casualty workbook in Google Drive and fills in most of the
   * data. This API will send an  email to Archipelago to notify them that a casualty is ready for
   * processing. Archipelago will then review and add the missing values. UpdateCasualty can then be
   * used to convert the Google Spreadsheet to a XLS file which will then be automaticall be uploaded
   * to the account's Library.
   */
  createCasualty: Casualty;
  /** Triggers asynchronous data quality scoring for each stream present at the time of the snapshot. */
  createDataQualityScores: CreateDataQualityScoresResult;
  /**  Only for IT purpose to get proper testdata within the IT. */
  createDocumentClassificationSuggestion?: Maybe<DocumentClassificationSuggestion>;
  /**  Only for IT purpose to get proper testdata within the IT. */
  createDocumentPropertySuggestion?: Maybe<DocumentPropertySuggestion>;
  /**  Create an export configuration  */
  createExportConfiguration?: Maybe<ExportConfiguration>;
  /** Create or update a new Program */
  createOrUpdateProgram?: Maybe<Program>;
  createOrUpdateProperty?: Maybe<Property>;
  /** Creates an organization in the system. Can only be used by admins. */
  createOrg?: Maybe<Organization>;
  createProperty?: Maybe<Property>;
  createPropertyDiscussion: CreatePropertyDiscussionResult;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Generates pre-check recommendations for a stream snapshot.
   * Only for admins.
   */
  createPropertyRecommendations: CreatePropertyRecommendationsResponse;
  /**
   * Creates a prospect organization and upgrades user to risk manager. The org name will be the
   * company name the user filled in at signup. If org name is blank we will use their full name.
   */
  createProspectOrganization: Scalars['Boolean']['output'];
  /**  Create provenance. Returns added provenance ID  */
  createProvenance: Scalars['ID']['output'];
  /**  Creates a stream for a new renewal based off of an existing stream.  */
  createRenewalStream: Stream;
  /** Creates the Org ScopeGroup. */
  createScopeGroup: ScopeGroup;
  /**  Create a smartfolder with a name and the rules by which to filter  */
  createSmartFolder: SmartFolder;
  /** Create a new organization snapshot at the current date */
  createSnapshot: Snapshot;
  createStream: Stream;
  createSubmission: Submission;
  /**  Create a validate label  */
  createValidateLabel?: Maybe<ValidateLabel>;
  /** Create a rule in the system */
  createValidateRule?: Maybe<ValidateRuleV2>;
  /**  Create a validate rule org override  */
  createValidateRuleOrgOverride?: Maybe<ValidateRuleV2>;
  /**
   * Deletes the exposure aggregation for all stream snapshots. Only allowed for system admins.
   * Only allowed in dev environments.
   */
  deleteAllStreamExposureIndex: Scalars['Boolean']['output'];
  /** Delete an attribute from the schema */
  deleteAttribute: Scalars['Boolean']['output'];
  /**  Will be deprecated in the future, Soft-Delete a single document or image in the system. Can only be used by Archipelago Users.  */
  deleteDocument: Scalars['ID']['output'];
  /**  Permanently Deletes files in the system. Only authorized for admin users.  */
  deleteDocuments?: Maybe<Array<Scalars['ID']['output']>>;
  /**  Delete an export configuration  */
  deleteExportConfiguration: Scalars['Boolean']['output'];
  deleteOrRestoreProperties: Scalars['Boolean']['output'];
  /** Deletes an organization from the system. Can only be used by admins. */
  deleteOrg: Scalars['Boolean']['output'];
  deletePropertyDiscussion: DeletePropertyDiscussionResult;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Deletes pre-check recommendations for a stream snapshot.
   * Only for admins.
   */
  deletePropertyRecommendations: DeletePropertyRecommendationsResponse;
  /**  Soft-Deletes a single row of attribute provenance based on the provenance ID. Can only be used by Archipelago Users.  */
  deleteProvenance: Scalars['ID']['output'];
  deleteProvenanceByAttribute: Scalars['Boolean']['output'];
  /** Delete a scope group */
  deleteScopeGroup: Scalars['Boolean']['output'];
  deleteScoreAndState: Scalars['Boolean']['output'];
  /**  Delete a smartfolder by organization name and smartfolder name. This is not a soft-delete  */
  deleteSmartFolder: Scalars['ID']['output'];
  deleteSnapshot: Scalars['Boolean']['output'];
  /**  Admin only. Soft delete a stream for a given slug  */
  deleteStream: Scalars['Boolean']['output'];
  /**
   * Deletes the exposure aggregation for a stream snapshot. Only allowed for admins.
   * Exposure index may be recalculated using the calculateStreamExposureIndex mutation.
   */
  deleteStreamExposureIndex: DeleteStreamExposureIndexResult;
  /** Soft-deletes the submission. Only for admins. */
  deleteSubmission: Scalars['Boolean']['output'];
  /**  DEPRECATED. For internal use. Accepts system user Id and returns true if the user was found and removed.  */
  deleteUser: Scalars['Boolean']['output'];
  /** For internal use. Accepts a user email address and deletes the user, and all "plus addressing" users, in our system. Systems: DB Cache, Auth0, RocketChat. Returns the list of email addresses that were deleted. If dryRun is true, it will not delete the user, and only returns to list that would have been removed. */
  deleteUserV2: DeleteUserV2Output;
  /**  Delete a validate label  */
  deleteValidateLabel: Scalars['Boolean']['output'];
  /** Delete a rule in the system */
  deleteValidateRule: Scalars['Boolean']['output'];
  deleteValidateRuleOrgOverride: Scalars['Boolean']['output'];
  /** Denies granting the user StreamViewer role on the stream. */
  denyAccess: Scalars['Boolean']['output'];
  dismissTasks: Scalars['Boolean']['output'];
  /**
   * Deprecated. Use setPropertiesStatus instead
   * Disposes properties that are selected in DisposeProperties job.
   * This api is used by jobs service.
   */
  disposeProperties?: Maybe<DisposePropertiesResponse>;
  draftTask: Scalars['Boolean']['output'];
  /** Enables or disables a mapper for an organization */
  enableAttributeMapper: Scalars['Boolean']['output'];
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Enables the pre-check feature flag on an organization.
   * Optionally adds brokers, assigns them as Risk Manager and sends them an email notification containing a link
   * to the organization's pre-check page.
   * Branding is configured on the organization based on the email domain of the first broker specified.
   * Reminder emails will be sent if prospectAttributeRecommendations is not queried by the user before then.
   * There must be a visible and authorized snapshot before enabling pre-check.
   * Only for admins.
   */
  enablePreCheck: EnablePreCheckResponse;
  /**
   * Registers an export request in the system.
   *
   * Metadata settings:
   * 1. Change Analysis
   * - snapshotTo: String
   */
  exportStart: ExportResult;
  exportStartV3: ExportResultV3;
  /**  Automatically prepare the current submission to be submitted (new snapshot, update assignments, add user to pre-check...)  */
  finalizeSubmission: FinalizeSubmissionOutput;
  /**  Notifies the API that the file is uploaded and updates the status */
  finishUploadRequest: Document;
  /**
   * Submit a worksheet to add carrier metrics for a stream/snapshot. Validates synchronously and processes the report asynchronously.
   * Replaces any existing carrier metrics with the same stream, snapshot and carrier.
   */
  importCarrierMetrics: ImportCarrierMetricsResult;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Imports property recommendations for a snapshot, overwriting any existing recommendations.
   * Only for admins.
   */
  importPropertyRecommendations: ImportPropertyRecommendationsResponse;
  invalidateExport: Scalars['Boolean']['output'];
  jobWorksheetStart?: Maybe<JobWorksheetStartResponse>;
  /**  Manually join a chat room if user is authorized but hasn't been added yet  */
  joinChatRoom: JoinChatRoomOutput;
  /**
   * Locks a property attributes matching a set of filters for editing.
   * If an active lock already exists for any of the requested properties/attributes, the success field of the response
   * will be false and details about conflicting locks will be returned.
   * Only allowed for admins.
   */
  lockPropertyAttributes: LockPropertyAttributesResponse;
  /** Perform partial update on an existing loss and return the new value */
  mergeLossAttributes?: Maybe<Loss>;
  mergePropertyAttributes?: Maybe<Property>;
  /**
   * mergePropertyAttributesWithMultiProvenances applies multiple provenance documents to attributes.
   * mergePropertyAttributes can only accept one provenance document.
   */
  mergePropertyAttributesWithMultiProvenances?: Maybe<Property>;
  /**  Synchronous request to create a worksheet for adding properties to a stream.  */
  mpAddWorksheet: MpAddWorksheetResponse;
  /**  Async request to start worksheet generation. Use query jobWorksheetProgress to check for completion status.  */
  mpEditWorksheetStart: MpEditWorksheetStartResponse;
  /** Force the Reporting View service to repopulate data. */
  notifyReportingViewService: Scalars['Boolean']['output'];
  /** Notify the platform that all tasks have been completed. The platform may perform additional actions based on the completion of all tasks, like sending an email. */
  notifyTasksCompleted: Scalars['Boolean']['output'];
  /** Notify the platform that tasks have been created. The platform may perform additional actions based on the creation of tasks, like sending an email. */
  notifyTasksCreated: Scalars['Boolean']['output'];
  /** Notify the platform that all tasks have expired. The platform may perform additional actions based on the expiration of all tasks, like sending an email. */
  notifyTasksExpired: Scalars['Boolean']['output'];
  /** Open a new support ticket and notify Archipelago team */
  openSupportTicket: OpenSupportTicketOutput;
  /**  Rerun the document classification and fact extraction.  */
  processDocument: Scalars['ID']['output'];
  /** Updates link document to properties and document classifcation for the given list of document IDs */
  processDocumentIds: Organization;
  /** Updates link document to properties */
  processDocuments: Organization;
  propertyDerivation: Scalars['Boolean']['output'];
  /**
   * Deprecated. Use updateSnapshotConfig and set marketVisible instead.
   * Set the snapshot which is visible to viewers users for specified stream.
   */
  publishStreamSnapshot: Scalars['Boolean']['output'];
  qualityInsertAttributeScore: AttributeScoreResultIt;
  qualityInsertPropertyPotential: PropertyPotentialResultIt;
  qualityInsertPropertyScore: PropertyScoreResultIt;
  qualityInsertScoreMethod: ScoreMethodResultIt;
  qualityInsertSnapshotState: QualitySnapshotStatus;
  qualityInsertStreamScore: StreamScoreResultIt;
  /**  Recover soft-deleted documents, Can only be used by Archipelago Users.  */
  recoverDocuments?: Maybe<Array<Scalars['ID']['output']>>;
  /**
   * Triggers a refresh of exposure metrics for all industries. Returns true if refresh was successfully enqueued.
   * Only allowed for system admins.
   */
  refreshAllExposureMetrics: Scalars['Boolean']['output'];
  registerPropertyManagers?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * RegisterUser lets you create or convert users.
   * Requires admin privileges.
   */
  registerUser: UserSystemInfo;
  /**
   * Releases locks on property attributes with the specified group ID.
   * Only allowed for admins.
   */
  releasePropertyLocks: ReleasePropertyLocksResponse;
  /**
   * Deletes assignments for all scopes, specific or global, of the requested scope type.
   * Returns true if the assignment(s) were found.
   */
  removeAllScopes: Scalars['Boolean']['output'];
  /**  Removes document from a stream. Returns removed document ID. Only authorized for admin users. */
  removeDocumentFromStream: Scalars['ID']['output'];
  /**  Removes a property from the document properties.  Only authorized for admin users. */
  removeDocumentProperty: Scalars['ID']['output'];
  /**  Removes the reference to the orginial (source) document. Only authorized for admin users. */
  removeDocumentSource: Document;
  /**
   * Users with this domain will no longer be granted access.
   * Can only be used by User Admin, User Manager or Archipelago Admin.
   * A default domain cannot be removed by Risk Managers or Brokers, only by admins.
   */
  removeDomainAllowList: Scalars['Boolean']['output'];
  /** Remove members from a group. This call is idempotent meaning that removing a user twice would not result in an error. */
  removeScopeGroupMember: Scalars['Boolean']['output'];
  /**
   * Deletes assignment for the requested scopes.
   * Returns true if the assignment(s) were found.
   */
  removeScopes: Scalars['Int']['output'];
  /**
   * Updates the expiration time on property attribute locks with the specified group ID.
   * Only allowed for admins.
   */
  renewPropertyLocks: RenewPropertyLocksResponse;
  /** Requests StreamViewer role for a user on the stream. */
  requestAccess: Scalars['Boolean']['output'];
  /**  Requests a single pre-signed url or multiple to upload documents directly to S3 */
  requestUploadUrl?: Maybe<UploadUrl>;
  /**
   * Deprecated. Use setPropertiesStatus instead.
   * Restore disposed properties so that they can be visible in streams again.
   */
  restoreProperties: Scalars['Int']['output'];
  sendWorkflowEmail: Scalars['Boolean']['output'];
  /** Update and/or insert several atribute metadata in a row for a specific organization */
  setAttributeMetadata: Scalars['Boolean']['output'];
  /**
   * Update and/or insert several atribute metadata in a row for a specific organization automatically based on property contents of the my properties or given stream
   * If the streamslug is not provided the attributes will be changed on organization level with the my-properties stream as base to calculate the changes regarding attributes.
   * This will update all the property metadata excluding the custom / owner attributes.
   */
  setAttributeMetadataAuto: SetAttributeMetadataAutoResult;
  /**
   * Enables or disables domain based access on a stream.
   * Returns enabled/disabled. Is false when error.
   * Can only be used by User Admin, User Manager or Archipelago Admin.
   */
  setDomainBasedAccess: Scalars['Boolean']['output'];
  /** Configure whether a feature is enabled or disabled per default during org creation. */
  setFeatureDefault: Scalars['Boolean']['output'];
  /** Link files to policy. Warning: You need to specify all the documents that you want to add or the existing links are being removed. */
  setLinkedFilesForPolicy?: Maybe<Policy>;
  /** Link files to program. Warning: You need to specify all the documents that you want to add or the existing links are being removed. */
  setLinkedFilesForProgram?: Maybe<Program>;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Configure an organization's Pre-Check related settings
   */
  setOrgPreCheckConfig: OrgPreCheckConfig;
  /**  Configure an organization's property related settings  */
  setOrgPropertyConfig: OrgPropertyConfig;
  /** Update an organization's submission dashboard settings */
  setOrgSubmissionConfig: OrgSubmissionConfig;
  setPrimaryOrg: SetPrimaryOrgOutput;
  /** dispose or restore a list of archipelago ids for a given org, with an optional disposition date for each property */
  setPropertiesStatus?: Maybe<SetPropertiesStatusResponse>;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Sets the property recommendations grade for a stream snapshot.
   * Only for admins.
   */
  setPropertyRecommendationsGrade: SetPropertyRecommendationsGradeResponse;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Assigns collaborators (other brokers) and prospects as Risk Manager on the organization and sends them an email
   * notification containing a link to the organization's pre-check page.
   * Reminder emails will be sent to prospects if recommendations are not queried by the user before then.
   */
  sharePreCheck: SharePreCheckResponse;
  /** Share a link to the stream with another user. */
  shareStreamLink: Scalars['Boolean']['output'];
  /** Starts an add losses job. Job will expire in 48 hours. The createJob mutation is not required to use this. */
  startAddLossesJob: StartAddLossesJobOutput;
  /**  Delete property revisions related to given job. Used for reverting the most recent completed job.  */
  startDeletePropertyRevisions: Scalars['Int']['output'];
  /** Starts an edit losses job. Job will expire in 48 hours. The createJob mutation is not required to use this. */
  startEditLossesJob: StartEditLossesJobOutput;
  startOrgChat: StartOrgChatResponse;
  startTaskChat: StartTaskChatOutput;
  /** Submits an add losses job for validation and update. */
  submitAddLossesJob: SubmitAddLossesJobOutput;
  /** Submits an edit losses job for validation and update. */
  submitEditLossesJob: SubmitEditLossesJobOutput;
  syncSecondaryModifiers: Scalars['Boolean']['output'];
  /** Run your rule against a test dataset. Endpoint also tests whether your validation rule can be compiled by the expression parser. */
  testValidateRule?: Maybe<Array<ValidateError>>;
  trackEvent: Scalars['Boolean']['output'];
  unAssignTask: Scalars['Boolean']['output'];
  /**
   * Update an attribute metadata for a specific organization.
   * If a stream is specified, insert or update an override
   */
  updateAttributeMetadata: Scalars['Boolean']['output'];
  /**
   * Admin only. This API allows to update the state of a casualty from "Submitted" to "ProcessingComplete"
   * , which will automatically create the XLS worksheet from the Google Spreadsheet. It will
   * then upload that to the Library and notify the Account Admin on the account the the workbook is
   * ready to be downloaded.
   */
  updateCasualty: Casualty;
  /**  Update a document in the system. Can only be used by Archipelago Users.  */
  updateDocument: Document;
  /**
   * Update document stream group like ['All docs zip','Marketing material','Archipelago info','None',...]
   *   Note: the orgID parameter is not used, and needs to be removed. streamGroup param should be required.
   */
  updateDocumentGroup: Scalars['ID']['output'];
  updateDocumentSection?: Maybe<Provenance>;
  /**  Updates an editor comment with provided values.  */
  updateEditorComment: UpdateEditorCommentResponse;
  /**  Update an export configuration  */
  updateExportConfiguration?: Maybe<ExportConfiguration>;
  /** Updates the attributes of an organization. Can only be used by admins. */
  updateOrg: Organization;
  /**  Update stream membership based on the stream membership expression for the specified org and property.  */
  updatePropertyMembership: Scalars['Boolean']['output'];
  /**  Update all previous property revisions regarding history data and snapshots  */
  updatePropertySubValuesData: Scalars['Boolean']['output'];
  /**  Updates stream and provenance reporting views  */
  updateReportingViews: Scalars['Boolean']['output'];
  /** Update schema with values that are applied to all organizations and streams */
  updateSchema: Scalars['Boolean']['output'];
  updateSnapshotConfig: Scalars['Boolean']['output'];
  /**  Update stock throughput calculations for all property revisions  */
  updateStockThroughputCalculationData: Scalars['Boolean']['output'];
  updateStream: Stream;
  /**
   * Change specified stream(s) to include all properties in the specified org.
   *   If no organization is specified then all existing streams are updated.
   *   If only organization name is specified then all streams for that organization are updated.
   *   If both are specified then only specified stream is updated.
   */
  updateStreamMembership: Scalars['Int']['output'];
  updateStreamMembershipAndReportingView?: Maybe<Scalars['Boolean']['output']>;
  updateSubmission: Submission;
  /** Change submission attribute visibility for selected carrier org  */
  updateSubmissionAttributeMetadata: Scalars['Int']['output'];
  /** Update the stream that is associated to a submission. Only for admins. */
  updateSubmissionStream: Submission;
  /**  updateSuggestion allows accept / reject suggestions with comment possibility */
  updateSuggestion: Scalars['Boolean']['output'];
  /** Updates a support ticket, change status and/or assignees */
  updateSupportTicket: UpdateSupportTicketOutput;
  /**  Update a validate label  */
  updateValidateLabel?: Maybe<ValidateLabel>;
  /** Update a rule in the system */
  updateValidateRule?: Maybe<ValidateRuleV2>;
  /**  Update a validate rule org override  */
  updateValidateRuleOrgOverride?: Maybe<ValidateRuleV2>;
  /**
   * Upload a single document
   *   suggestions: if true it will instead of directly link the properties and set the classification set suggestions from ML
   */
  upload: Document;
  /**
   * Upload a single document related to a job.
   * The document will be stored on S3 in the specified path prefixed with {org_id}/workflow.
   * This api is used by jobs service.
   */
  uploadJobDocument: Document;
  /** Runs change validation against the provided property */
  validateChangeByProperty: ValidateChangeResult;
  /** Runs change validation against the provided stream */
  validateChangeByStream?: Maybe<Array<Maybe<ValidateChangeResult>>>;
  validateChangeByStreamReport: ValidateChangeReportOutput;
  /** Runs validation rules against all properties in the provided org. */
  validateOrganizationV2?: Maybe<Array<ValidateError>>;
  /** Runs validation rules against the provided property. */
  validatePropertiesByIDsV2?: Maybe<Array<ValidateError>>;
  /** Runs validation rules against the provided property. */
  validatePropertyByIDV2?: Maybe<Array<ValidateError>>;
  /** Runs validation rules against all properties in the provided stream. */
  validateStreamV2?: Maybe<ValidateStream>;
  /** Runs validation rules against a subset of attributes. */
  validateValueV2?: Maybe<Array<ValidateError>>;
  writeLinkProperties?: Maybe<WriteLinkPropertiesResponse>;
};


export type MutationAcceptPreCheckRecommendationsArgs = {
  input: AcceptPreCheckRecommendationsInput;
};


export type MutationAddDocumentPropertyArgs = {
  input: DocumentPropertyInput;
};


export type MutationAddDocumentSourceArgs = {
  input: DocumentSourceInput;
};


export type MutationAddDocumentToStreamArgs = {
  input: AddDocumentToStreamInput;
};


export type MutationAddDomainAllowListArgs = {
  input: AddDomainAllowListInput;
};


export type MutationAddEditorCommentArgs = {
  input: AddEditorCommentInput;
};


export type MutationAddOrUpdateLossArgs = {
  input: AddOrUpdateLossInput;
};


export type MutationAddOrUpdatePolicyArgs = {
  input: AddOrUpdatePolicyInput;
};


export type MutationAddPropertyPartnerValuationDataArgs = {
  input: AddValuationPartnerDataInput;
};


export type MutationAddScopeGroupMemberArgs = {
  input: AddScopeGroupMemberInput;
};


export type MutationAddSubmissionApiClientsArgs = {
  input: AddSubmissionApiClientsInput;
};


export type MutationAddViewedHighlightArgs = {
  label: Scalars['ID']['input'];
  streamSlug: Scalars['ID']['input'];
};


export type MutationAllowAccessArgs = {
  input: RequestAccessInput;
};


export type MutationApplyMappingRulesToOrganizationArgs = {
  input: ApplyMappingRulesToOrganizationInput;
};


export type MutationApplyMappingRulesToPropertiesArgs = {
  input: ApplyMappingRulesToPropertiesInput;
};


export type MutationApplyMappingRulesToStreamArgs = {
  input: ApplyMappingRulesToStreamInput;
};


export type MutationArchiveDocumentsArgs = {
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationAssignRoleAllScopeValuesArgs = {
  input: AssignRoleAllScopeValuesInput;
};


export type MutationAssignRoleV2Args = {
  input: AssignRoleInputV2;
};


export type MutationAssignTaskArgs = {
  input: TaskAssignInput;
};


export type MutationBatchProcessRolesArgs = {
  input: BatchProcessRolesInput;
};


export type MutationCalculateStreamExposureIndexArgs = {
  input: CalculateStreamExposureIndexInput;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationCompleteTaskArgs = {
  input: TaskCompleteInput;
};


export type MutationCompleteTaskV2Args = {
  input: TaskCompleteV2Input;
};


export type MutationCompleteTaskV3Args = {
  input: TaskCompleteV3Input;
};


export type MutationConfigureAuthorizationArgs = {
  input: ConfigureAuthorizationInput;
};


export type MutationConfigureFeatureArgs = {
  input: ConfigFeatureInput;
};


export type MutationConfigureOrgExportArgs = {
  input: ConfigureOrgExportInput;
};


export type MutationConfigureSubmissionRegionsArgs = {
  input: ConfigureSubmissionRegionsInput;
};


export type MutationConvertUserArgs = {
  input: ConvertUserInput;
};


export type MutationCreateAttributeArgs = {
  input: CreateAttributeInput;
};


export type MutationCreateCasualtyArgs = {
  input: CreateCasualtyInput;
};


export type MutationCreateDataQualityScoresArgs = {
  input: CreateDataQualityScoresInput;
};


export type MutationCreateDocumentClassificationSuggestionArgs = {
  input: CreateDocumentClassificationSuggestionInput;
};


export type MutationCreateDocumentPropertySuggestionArgs = {
  input: CreateDocumentPropertySuggestionInput;
};


export type MutationCreateExportConfigurationArgs = {
  input: CreateExportConfigurationInput;
};


export type MutationCreateOrUpdateProgramArgs = {
  input: CreateOrUpdateProgramInput;
};


export type MutationCreateOrUpdatePropertyArgs = {
  input: CreateOrUpdatePropertyInput;
};


export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};


export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};


export type MutationCreatePropertyDiscussionArgs = {
  input: CreatePropertyDiscussionInput;
};


export type MutationCreatePropertyRecommendationsArgs = {
  input: CreatePropertyRecommendationsInput;
};


export type MutationCreateProvenanceArgs = {
  input: CreateProvenanceInput;
};


export type MutationCreateRenewalStreamArgs = {
  input: CreateRenewalStreamInput;
};


export type MutationCreateScopeGroupArgs = {
  input: CreateScopeGroupInput;
};


export type MutationCreateSmartFolderArgs = {
  name: Scalars['ID']['input'];
  orgName: Scalars['ID']['input'];
  rules?: InputMaybe<Array<InputMaybe<Rule>>>;
};


export type MutationCreateSnapshotArgs = {
  input: CreateSnapshotInput;
};


export type MutationCreateStreamArgs = {
  input: CreateStreamInput;
};


export type MutationCreateSubmissionArgs = {
  input: CreateSubmissionInput;
};


export type MutationCreateValidateLabelArgs = {
  input: ValidateLabelInput;
};


export type MutationCreateValidateRuleArgs = {
  input: ValidateRuleInput;
};


export type MutationCreateValidateRuleOrgOverrideArgs = {
  input: ValidateRuleOverrideInput;
};


export type MutationDeleteAttributeArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteDocumentArgs = {
  documentID: Scalars['ID']['input'];
};


export type MutationDeleteDocumentsArgs = {
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationDeleteExportConfigurationArgs = {
  input: DeleteExportConfigurationInput;
};


export type MutationDeleteOrRestorePropertiesArgs = {
  input: DeleteOrRestorePropertiesInput;
};


export type MutationDeleteOrgArgs = {
  input: DeleteOrgInput;
};


export type MutationDeletePropertyDiscussionArgs = {
  input: DeletePropertyDiscussionInput;
};


export type MutationDeletePropertyRecommendationsArgs = {
  input: DeletePropertyRecommendationsInput;
};


export type MutationDeleteProvenanceArgs = {
  provenanceID: Scalars['ID']['input'];
};


export type MutationDeleteProvenanceByAttributeArgs = {
  input: DeleteProvenanceByAttributeInput;
};


export type MutationDeleteScopeGroupArgs = {
  input: DeleteScopeGroupInput;
};


export type MutationDeleteScoreAndStateArgs = {
  snapshotID: Scalars['ID']['input'];
};


export type MutationDeleteSmartFolderArgs = {
  name: Scalars['ID']['input'];
  orgName: Scalars['ID']['input'];
};


export type MutationDeleteSnapshotArgs = {
  input?: InputMaybe<DeleteSnapshotInput>;
};


export type MutationDeleteStreamArgs = {
  input?: InputMaybe<DeleteStreamInput>;
};


export type MutationDeleteStreamExposureIndexArgs = {
  input: DeleteStreamExposureIndexInput;
};


export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteUserV2Args = {
  input: DeleteUserV2Input;
};


export type MutationDeleteValidateLabelArgs = {
  input: DeleteValidateLabelInput;
};


export type MutationDeleteValidateRuleArgs = {
  input: DeleteValidateRuleInput;
};


export type MutationDeleteValidateRuleOrgOverrideArgs = {
  input: DeleteValidateRuleOverrideInput;
};


export type MutationDenyAccessArgs = {
  input: RequestAccessInput;
};


export type MutationDismissTasksArgs = {
  input: DismissTasksInput;
};


export type MutationDisposePropertiesArgs = {
  input: DisposePropertiesInput;
};


export type MutationDraftTaskArgs = {
  input: TaskCompleteInput;
};


export type MutationEnableAttributeMapperArgs = {
  input: EnableAttributeMapperInput;
};


export type MutationEnablePreCheckArgs = {
  input: EnablePreCheckInput;
};


export type MutationExportStartArgs = {
  input: ExportStartInput;
};


export type MutationExportStartV3Args = {
  input: ExportStartV3Input;
};


export type MutationFinalizeSubmissionArgs = {
  input?: InputMaybe<FinalizeSubmissionInput>;
};


export type MutationFinishUploadRequestArgs = {
  input: UploadFinishedInput;
};


export type MutationImportCarrierMetricsArgs = {
  input: ImportCarrierMetricsInput;
};


export type MutationImportPropertyRecommendationsArgs = {
  input: ImportPropertyRecommendationsInput;
};


export type MutationInvalidateExportArgs = {
  input: InvalidateExportInput;
};


export type MutationJobWorksheetStartArgs = {
  input: JobWorksheetStartInput;
};


export type MutationJoinChatRoomArgs = {
  input: JoinChatRoomInput;
};


export type MutationLockPropertyAttributesArgs = {
  input: LockPropertyAttributesInput;
};


export type MutationMergeLossAttributesArgs = {
  input: MergeLossAttributesInput;
};


export type MutationMergePropertyAttributesArgs = {
  input: MergePropertyAttributesInput;
};


export type MutationMergePropertyAttributesWithMultiProvenancesArgs = {
  input: MergePropertyAttributesWithMultiProvenancesInput;
};


export type MutationMpAddWorksheetArgs = {
  input: MpAddWorksheetInput;
};


export type MutationMpEditWorksheetStartArgs = {
  input: MpEditWorksheetStartInput;
};


export type MutationNotifyReportingViewServiceArgs = {
  input: NotifyReportingViewServiceInput;
};


export type MutationNotifyTasksCompletedArgs = {
  input: NotifyTasksCompletedInput;
};


export type MutationNotifyTasksCreatedArgs = {
  input: NotifyTasksCreatedInput;
};


export type MutationNotifyTasksExpiredArgs = {
  input: NotifyTasksExpiredInput;
};


export type MutationOpenSupportTicketArgs = {
  input: OpenSupportTicketInput;
};


export type MutationProcessDocumentArgs = {
  documentID: Scalars['ID']['input'];
};


export type MutationProcessDocumentIdsArgs = {
  documentIds: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};


export type MutationProcessDocumentsArgs = {
  name: Scalars['String']['input'];
};


export type MutationPropertyDerivationArgs = {
  input?: InputMaybe<PropertyDerivationInput>;
};


export type MutationPublishStreamSnapshotArgs = {
  input: PublishStreamSnapshotInput;
};


export type MutationQualityInsertAttributeScoreArgs = {
  input: AttributeScoreInputIt;
};


export type MutationQualityInsertPropertyPotentialArgs = {
  input: PropertyPotentialInputIt;
};


export type MutationQualityInsertPropertyScoreArgs = {
  input: PropertyScoreInputIt;
};


export type MutationQualityInsertScoreMethodArgs = {
  input: ScoreMethodInputIt;
};


export type MutationQualityInsertSnapshotStateArgs = {
  input: SnapshotStateInputIt;
};


export type MutationQualityInsertStreamScoreArgs = {
  input: StreamScoreInputIt;
};


export type MutationRecoverDocumentsArgs = {
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationRegisterPropertyManagersArgs = {
  input: RegisterPropertyManagersInput;
};


export type MutationRegisterUserArgs = {
  input?: InputMaybe<RegisterUserInput>;
};


export type MutationReleasePropertyLocksArgs = {
  input: ReleasePropertyLocksInput;
};


export type MutationRemoveAllScopesArgs = {
  input?: InputMaybe<RemoveAllScopesInput>;
};


export type MutationRemoveDocumentFromStreamArgs = {
  input: RemoveDocumentFromStreamInput;
};


export type MutationRemoveDocumentPropertyArgs = {
  input: DocumentPropertyInput;
};


export type MutationRemoveDocumentSourceArgs = {
  input: DocumentSourceInput;
};


export type MutationRemoveDomainAllowListArgs = {
  input: RemoveDomainAllowListInput;
};


export type MutationRemoveScopeGroupMemberArgs = {
  input: RemoveScopeGroupMemberInput;
};


export type MutationRemoveScopesArgs = {
  input?: InputMaybe<RemoveScopesInput>;
};


export type MutationRenewPropertyLocksArgs = {
  input: RenewPropertyLocksInput;
};


export type MutationRequestAccessArgs = {
  input: RequestAccessInput;
};


export type MutationRequestUploadUrlArgs = {
  input: UploadUrlInput;
};


export type MutationRestorePropertiesArgs = {
  input: RestorePropertiesInput;
};


export type MutationSendWorkflowEmailArgs = {
  input: SendWorkflowEmailInput;
};


export type MutationSetAttributeMetadataArgs = {
  input?: InputMaybe<SetAttributeMetadataInput>;
};


export type MutationSetAttributeMetadataAutoArgs = {
  input?: InputMaybe<SetAttributeMetadataAutoInput>;
};


export type MutationSetDomainBasedAccessArgs = {
  enabled: Scalars['Boolean']['input'];
  streamSlug: Scalars['String']['input'];
};


export type MutationSetFeatureDefaultArgs = {
  input: SetFeatureDefaultInput;
};


export type MutationSetLinkedFilesForPolicyArgs = {
  input: SetLinkedFilesForPolicyInput;
};


export type MutationSetLinkedFilesForProgramArgs = {
  input: SetLinkedFilesForProgramInput;
};


export type MutationSetOrgPreCheckConfigArgs = {
  input: SetOrgPreCheckConfigInput;
};


export type MutationSetOrgPropertyConfigArgs = {
  input: SetOrgPropertyConfigInput;
};


export type MutationSetOrgSubmissionConfigArgs = {
  input: SetOrgSubmissionConfigInput;
};


export type MutationSetPrimaryOrgArgs = {
  input: SetPrimaryOrgInput;
};


export type MutationSetPropertiesStatusArgs = {
  input: SetPropertiesStatusInput;
};


export type MutationSetPropertyRecommendationsGradeArgs = {
  input: SetPropertyRecommendationsGradeInput;
};


export type MutationSharePreCheckArgs = {
  input: SharePreCheckInput;
};


export type MutationShareStreamLinkArgs = {
  input: ShareStreamLinkInput;
};


export type MutationStartAddLossesJobArgs = {
  input: StartAddLossesJobInput;
};


export type MutationStartDeletePropertyRevisionsArgs = {
  input: DeletePropertyRevInput;
};


export type MutationStartEditLossesJobArgs = {
  input: StartEditLossesJobInput;
};


export type MutationStartOrgChatArgs = {
  input: StartOrgChatInput;
};


export type MutationStartTaskChatArgs = {
  input: StartTaskChatInput;
};


export type MutationSubmitAddLossesJobArgs = {
  input: SubmitAddLossesJobInput;
};


export type MutationSubmitEditLossesJobArgs = {
  input: SubmitEditLossesJobInput;
};


export type MutationSyncSecondaryModifiersArgs = {
  input: SyncSecondaryModifierInput;
};


export type MutationTestValidateRuleArgs = {
  input: ValidateRuleInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationUnAssignTaskArgs = {
  input: TaskUnAssignInput;
};


export type MutationUpdateAttributeMetadataArgs = {
  input?: InputMaybe<UpdateAttributeMetadataInput>;
};


export type MutationUpdateCasualtyArgs = {
  input: UpdateCasualtyInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentGroupArgs = {
  documentID: Scalars['ID']['input'];
  orgID: Scalars['ID']['input'];
  streamGroup?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDocumentSectionArgs = {
  input: UpdateDocumentSectionInput;
};


export type MutationUpdateEditorCommentArgs = {
  input: UpdateEditorCommentInput;
};


export type MutationUpdateExportConfigurationArgs = {
  input: UpdateExportConfigurationInput;
};


export type MutationUpdateOrgArgs = {
  input: UpdateOrgInput;
};


export type MutationUpdatePropertyMembershipArgs = {
  input: UpdatePropertyMembershipInput;
};


export type MutationUpdatePropertySubValuesDataArgs = {
  input: UpdatePropertySubValuesDataInput;
};


export type MutationUpdateSchemaArgs = {
  input: UpdateSchemaInput;
};


export type MutationUpdateSnapshotConfigArgs = {
  input: UpdateSnapshotConfigInput;
};


export type MutationUpdateStockThroughputCalculationDataArgs = {
  input: UpdateStockThroughputCalculationDataInput;
};


export type MutationUpdateStreamArgs = {
  input: UpdateStreamInput;
};


export type MutationUpdateStreamMembershipArgs = {
  orgName?: InputMaybe<Scalars['ID']['input']>;
  streamSlug?: InputMaybe<Scalars['ID']['input']>;
  updateReportingViews?: Scalars['Boolean']['input'];
};


export type MutationUpdateStreamMembershipAndReportingViewArgs = {
  input: UpdateStreamMembershipAndReportingViewInput;
};


export type MutationUpdateSubmissionArgs = {
  input: UpdateSubmissionInput;
};


export type MutationUpdateSubmissionAttributeMetadataArgs = {
  input: UpdateSubmissionAttributeMetadataInput;
};


export type MutationUpdateSubmissionStreamArgs = {
  input: UpdateSubmissionStreamInput;
};


export type MutationUpdateSuggestionArgs = {
  input: SuggestionUpdateInput;
};


export type MutationUpdateSupportTicketArgs = {
  input: UpdateSupportTicketInput;
};


export type MutationUpdateValidateLabelArgs = {
  input: UpdateValidateLabelInput;
};


export type MutationUpdateValidateRuleArgs = {
  input: UpdateValidateRuleInput;
};


export type MutationUpdateValidateRuleOrgOverrideArgs = {
  input: UpdateValidateRuleOverrideInput;
};


export type MutationUploadArgs = {
  archipelagoIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  document: Scalars['Upload']['input'];
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  orgID: Scalars['ID']['input'];
  streamID?: InputMaybe<Scalars['String']['input']>;
  suggestions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadJobDocumentArgs = {
  input: UploadJobDocumentInput;
};


export type MutationValidateChangeByPropertyArgs = {
  input: ValidateChangeByPropertyInput;
};


export type MutationValidateChangeByStreamArgs = {
  input: ValidateChangeByStreamInput;
};


export type MutationValidateChangeByStreamReportArgs = {
  input: ValidateChangeByStreamInput;
};


export type MutationValidateOrganizationV2Args = {
  input: ValidateOrganizationInput;
};


export type MutationValidatePropertiesByIDsV2Args = {
  input: ValidatePropertiesByIDsInput;
};


export type MutationValidatePropertyByIdv2Args = {
  input: ValidatePropertyByIdInput;
};


export type MutationValidateStreamV2Args = {
  input: ValidateStreamInput;
};


export type MutationValidateValueV2Args = {
  input: ValidateValueInput;
};


export type MutationWriteLinkPropertiesArgs = {
  input: WriteLinkPropertiesInput;
};

export type MyAuthorizedScopesInput = {
  action: Action;
  resourceType: ResourceType;
  scopeType: ScopeType;
};

export type NotifyLoginInput = {
  /** The company the user works for. */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user. */
  email: Scalars['ID']['input'];
  /** The user's last name. */
  familyName?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name. */
  givenName?: InputMaybe<Scalars['String']['input']>;
  /** The user's job title in the company he/she works for. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp from the last time the user has logged in. */
  lastLogin?: InputMaybe<Scalars['Time']['input']>;
  /** The home org / primary org ID. */
  primaryOrgId?: InputMaybe<Scalars['String']['input']>;
  /** Auth0 internal ID */
  userId: Scalars['String']['input'];
};

export type NotifyReportingViewServiceInput = {
  orgName: Scalars['String']['input'];
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type NotifySignupInput = {
  /** The company the user works for. */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user. */
  email: Scalars['ID']['input'];
  /** The user's last name. */
  familyName?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name. */
  givenName?: InputMaybe<Scalars['String']['input']>;
  /** The user's job title in the company he/she works for. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The home org / primary org ID. */
  primaryOrgId?: InputMaybe<Scalars['String']['input']>;
  /** Auth0 internal ID */
  userId: Scalars['String']['input'];
};

export type NotifyTasksCompletedInput = {
  accountName: Scalars['String']['input'];
};

export type NotifyTasksCreatedInput = {
  accountName: Scalars['String']['input'];
};

export type NotifyTasksExpiredInput = {
  accountName: Scalars['String']['input'];
};

export type OnboardingDocument = {
  __typename?: 'OnboardingDocument';
  /** The uploaded document */
  document?: Maybe<Document>;
  /** The facts extracted from the document */
  facts?: Maybe<Array<DocumentFact>>;
  /** The IDs of properties linked to the document */
  propertyIDs?: Maybe<Array<Scalars['ID']['output']>>;
  /** The document is required for enrichment if set to true */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The current status of the document */
  status: DocumentStatus;
};

export type OnboardingDocumentProgress = {
  __typename?: 'OnboardingDocumentProgress';
  /** The total number of documents that are optional */
  optionalTotal?: Maybe<Scalars['Int']['output']>;
  /** The number of optional documents that have been uploaded */
  optionalUploaded?: Maybe<Scalars['Int']['output']>;
  /** The total number of documents that are required */
  requiredTotal?: Maybe<Scalars['Int']['output']>;
  /** The number of required documents that have been uploaded */
  requiredUploaded?: Maybe<Scalars['Int']['output']>;
};

export type OpenSupportTicketInput = {
  assigneeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  followerEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type OpenSupportTicketOutput = {
  __typename?: 'OpenSupportTicketOutput';
  ticket: SupportTicket;
};

export type OptionalFeature = {
  __typename?: 'OptionalFeature';
  enable: Scalars['Boolean']['output'];
  forAllOrgs: Scalars['Boolean']['output'];
  forOrgNames?: Maybe<Array<OrgInfo>>;
  name: Scalars['String']['output'];
};

/** Organization specific filter input. */
export type OrgAttributeFilter = {
  name: OrgFilterFields;
  operator?: InputMaybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter. */
  values: Array<Scalars['String']['input']>;
};

export type OrgAttributeMetadata = {
  __typename?: 'OrgAttributeMetadata';
  /** Flag that decides if an attribute can be filtered */
  filterable?: Maybe<Scalars['Boolean']['output']>;
  /** Flag that decides if an attribute can be grouped */
  groupable?: Maybe<Scalars['Boolean']['output']>;
  hiddenInGrid: Scalars['Boolean']['output'];
  includeInStreams: Scalars['Boolean']['output'];
  /** Flag that decides if an attribute is secondary modifier */
  isSecondaryModifier: Scalars['Boolean']['output'];
  metadata: AttributeMetadata;
  organizationId: Scalars['String']['output'];
  streamId: Scalars['String']['output'];
};

export type OrgDocumentFilter = {
  filters?: InputMaybe<Array<AttributeFilter>>;
  /**  name of the organization  */
  orgName: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};

/**  Input for the export endpoint used by documents-archive. */
export type OrgDocumentInfoInput = {
  /**
   * SnapshotID to download all documents a stream linked to a snapshot
   * TODO: Unused for now since documents dont know the existence of snapshots currently. Implementing it here will currently give a different results than the users sees. Should be implemented for the Pre-generated Exports.
   */
  SnapshotID?: InputMaybe<Scalars['String']['input']>;
  /**  Classifications for the documents to be archived  */
  classifications?: InputMaybe<Array<Scalars['String']['input']>>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  /**  Document IDs for the documents to be archived  */
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**  Flag to download all documents from an organization */
  downloadAll: Scalars['Boolean']['input'];
  orgID: Scalars['ID']['input'];
  /**  defaulted to 20  */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  /**  Property IDs (ArchipelagoIDs) for the documents to be archived  */
  propertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  StreamID to download all documents from a stream  */
  streamID?: InputMaybe<Scalars['String']['input']>;
};

export type OrgDocumentInfoPage = {
  __typename?: 'OrgDocumentInfoPage';
  cursor?: Maybe<Scalars['String']['output']>;
  documents: Array<ExportDocument>;
};

/**  Input that specifies input for documents from an organization. */
export type OrgDocumentInput = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orgName: Scalars['String']['input'];
  provenanceEnriched?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
  status?: InputMaybe<DocumentsStatus>;
};

export type OrgExportConfig = {
  __typename?: 'OrgExportConfig';
  /**  Exports which users with this org as their home org can export  */
  carrierExports?: Maybe<Array<CarrierExportCode>>;
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
};

export type OrgExportConfigInput = {
  orgId: Scalars['ID']['input'];
};

/** Set of fields to be used for filtering and sorting the collection of organizations. */
export enum OrgFilterFields {
  CreatedBy = 'CREATED_BY',
  EnableSubmissionDashboard = 'ENABLE_SUBMISSION_DASHBOARD',
  Name = 'NAME',
  OrgLevel = 'ORG_LEVEL',
  OrgType = 'ORG_TYPE'
}

export type OrgInfo = {
  __typename?: 'OrgInfo';
  businessType?: Maybe<Scalars['String']['output']>;
  /** If set to true, enables org members to access Pre-Check. */
  enablePreCheck: Scalars['Boolean']['output'];
  /** DEPRECATED. */
  enablePropertyManagerEdit?: Maybe<Scalars['Boolean']['output']>;
  /** Default false with the exception of prospect orgs registered through hubspot. */
  enableRedirectOnSOVUpload: Scalars['Boolean']['output'];
  /** If set to true, SOV Manager is enabled for the organization. */
  enableSOVManager: Scalars['Boolean']['output'];
  /** If set to true, SubValues are enabled for all segments of the organization. */
  enableSubValues: Scalars['Boolean']['output'];
  /**  Flag to enable/disable the submission dashboard. If true, user should be redirected to accounts page when selecting the org  */
  enableSubmissionDashboard: Scalars['Boolean']['output'];
  /** If set to false, createSubmission mutation will assign creator as StreamViewer instead of RiskManager */
  enableUnderwriterEdit: Scalars['Boolean']['output'];
  enableUploadPortal: Scalars['Boolean']['output'];
  /** List of features enabled for this org */
  enabledFeatures?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Org Level for the organization */
  orgLevel: OrgLevelType;
  type: Scalars['String']['output'];
};

export type OrgInfoInput = {
  orgName: Scalars['String']['input'];
};

export type OrgInfoResponse = {
  __typename?: 'OrgInfoResponse';
  orgInfo: OrgInfo;
  permissions: OrgPermissions;
  /** The permissions that are granted to the user in this account. */
  permissionsV2?: Maybe<Array<PermissionV2>>;
};

export enum OrgLevelType {
  /**  Org that has a direct contract with Archipelago, is not associated with an Enterprise org on  */
  Direct = 'Direct',
  /**  typically a broker / insurer customer that has underlying customers on platform as submission orgs  */
  Enterprise = 'Enterprise',
  /**  Org that has data on Archipelago, but whose data came to Platform indirectly via a broker / insurer. AKA Submission Org  */
  Underlying = 'Underlying'
}

export type OrgPermissions = {
  __typename?: 'OrgPermissions';
  /** Indicates which roles the user can manage using the user management feature for this organization. */
  canManageRoles?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicates if current user has permission to view insights. */
  canViewInsights: Scalars['Boolean']['output'];
  /** Indicates if user has access to that organization's chat rooms */
  chat: Scalars['Boolean']['output'];
  /** true if user has any permission to delete/restore properties on any stream in this org. */
  deleteProperties: Scalars['Boolean']['output'];
  /** permission to view/upload documents */
  editDocuments: Scalars['Boolean']['output'];
  /** true if user has any permission to manage losses on any stream in this org. */
  editLosses: Scalars['Boolean']['output'];
  editProjects: Scalars['Boolean']['output'];
  /** true if user has any permission to manage properties on any stream in this org. */
  editProperties: Scalars['Boolean']['output'];
  /**
   * permission to view/create submissions. if submission dashboard is disabled for org, this
   * is null.
   */
  editSubmissions?: Maybe<Scalars['Boolean']['output']>;
  /** true if org contains streams that are explicitly shared with the current user */
  hasStreamInvites?: Maybe<Scalars['Boolean']['output']>;
  /** true if user has permission to import carrier metrics in this org */
  importCarrierMetrics: Scalars['Boolean']['output'];
  /** Indicates if current user has permissions to manage users in this org. */
  manageUsers: Scalars['Boolean']['output'];
  /** Indicates if current user has permissions to view accounts. And ONLY view accounts. When this is true then the user has not edit permissions. */
  viewAccounts: Scalars['Boolean']['output'];
  /** Indicates if current user has permission to view enterprise-level reports. */
  viewEnterpriseReports: Scalars['Boolean']['output'];
  /**
   * Deprecated. Use allowedExports on stream response instead
   * true if user has permission to view RAPID exports in this org
   */
  viewRAPIDExport: Scalars['Boolean']['output'];
};

export type OrgPreCheckConfig = {
  __typename?: 'OrgPreCheckConfig';
  /** Email domain which determines branding in Pre-Check for the org. */
  brandDomain?: Maybe<Scalars['String']['output']>;
  /** Enables org members to access Pre-Check. */
  enablePreCheck: Scalars['Boolean']['output'];
  orgID: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
};

export type OrgPreCheckConfigInput = {
  orgName: Scalars['String']['input'];
};

export type OrgPropertiesPageInput = {
  /**
   * Can be used to fetch active properties only. If not specified or value is false, returns all properties.
   * If specified value is true, excludes properties with status disposed or superceded.
   * Default value is false.
   */
  activePropertiesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Can be used to fetch current or historical properties. If not specified, returns current properties.
   * If specified, returns historical properties for the specified date.
   */
  effectiveFrom?: InputMaybe<Scalars['String']['input']>;
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Maximum number of properties to return. Default value is 10. A maximum of 200 may be requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  /** If not specified, latest visible snapshot is used. */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /** Sort order of results. */
  sortBy?: InputMaybe<Array<Sort>>;
};

export type OrgPropertyConfig = {
  __typename?: 'OrgPropertyConfig';
  /** Enables org members with edit property permissions to manage losses. */
  enableLosses: Scalars['Boolean']['output'];
  /** The first year losses are recorded for the organization. Used for aggregating losses over time. */
  lossStartYear?: Maybe<Scalars['Int']['output']>;
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
};

export type OrgPropertyConfigInput = {
  orgId: Scalars['ID']['input'];
};

export enum OrgReportingType {
  /**  A customer who is a broker.  */
  BrokerCustomer = 'BrokerCustomer',
  /**  An account coming from a broker.  */
  BrokerLogo = 'BrokerLogo',
  /**  A direct owner who is an Archipelago Customer.  */
  Customer = 'Customer',
  /**  A customer who is an insurer.  */
  InsurerCustomer = 'InsurerCustomer',
  /**  An account coming from an insurer.  */
  InsurerLogo = 'InsurerLogo',
  /**  An org created for SoV provided by an insurer without intention to contact the insured.  */
  InsurerOther = 'InsurerOther',
  /**  An org used for internal test, demo and system orgs.  */
  Internal = 'Internal',
  /** Org containing data bound in a prior year. Used by underwriters to see what they are bound to in the prior year. */
  PriorYear = 'PriorYear',
  /**  A potential client.  */
  Prospect = 'Prospect',
  /**  An org which does not yet have a reporting type assigned.  */
  Unknown = 'Unknown'
}

export type OrgSubmissionConfig = {
  __typename?: 'OrgSubmissionConfig';
  /**   Flag to enable/disable the submission dashboard.  */
  enableDashboard: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  /**  Name of the organization which is going to be the template of this organization  */
  templateOrgName?: Maybe<Scalars['String']['output']>;
};

export type OrgSubmissionConfigInput = {
  orgName: Scalars['String']['input'];
};

export type OrgSummaryInput = {
  orgName: Scalars['String']['input'];
};

export type OrgSummaryResponse = {
  __typename?: 'OrgSummaryResponse';
  propertyRecommendationsGrade?: Maybe<Scalars['Float']['output']>;
  sovCount: Scalars['Int']['output'];
};

export enum OrgType {
  /**  Org with data for demos. Can not be deleted. Excluded from reporting/benchmarking.  */
  Demo = 'Demo',
  /**
   * Org containing data bound in a prior year. Used by underwriters to see what they are bound to in the prior year.
   * Can not be deleted. Excluded from reporting/benchmarking.
   */
  PriorYear = 'PriorYear',
  /**  Org with customer data. Included in reporting/benchmarking and can not be deleted.  */
  Production = 'Production',
  /**
   * Org created for a prospect customer. These can be changed to Production when a customer signs up.
   * Requires no manual geo-coding. Uses a light-weight Geo-job which returns only 4 hazard attributes (Seismic Zone, Wind Zone, Flood Zone, Geo-type).
   * Included in reporting/benchmarking and can not be deleted.
   */
  Prospect = 'Prospect',
  /**  Org which contains default metadata. Can not be created via the API. Excluded from reporting/benchmarking.  */
  SystemDefaults = 'SystemDefaults',
  /**  Org used as a template for new submissions. Can not be deleted. Excluded from reporting/benchmarking.  */
  Template = 'Template',
  /**  Org with test data. Test orgs can be deleted. Excluded from reporting/benchmarking.  */
  Test = 'Test'
}

export type Organization = {
  __typename?: 'Organization';
  /**
   * List of domains that are associated to this organization. Users that signup with a domain
   * that matches a domain in this array will automatically have the organization_id configured in
   * their app_metadata.
   */
  autoDomains?: Maybe<Array<Scalars['String']['output']>>;
  businessType?: Maybe<Scalars['String']['output']>;
  /** List of configured features. */
  configuredFeatures?: Maybe<Array<ConfiguredFeature>>;
  createdBy: Scalars['String']['output'];
  /**
   * Three character code (ISO_4217) to override the currency formatting per organization. E.g. USD, EUR, ...
   * If ZZZ then currencies are rendered as unit-less.
   */
  displayCurrency?: Maybe<Scalars['String']['output']>;
  /** Enables org members to view the Carrier Section of the Underwriting Assessment Report within permitted streams. */
  enableCarrierAssessment: Scalars['Boolean']['output'];
  /** Flag to turn the currency conversion on or off on an organization. */
  enableCurrencyConversion: Scalars['Boolean']['output'];
  /** DEPRECATED. */
  enablePropertyManagerEdit?: Maybe<Scalars['Boolean']['output']>;
  /** Default false with the exception of prospect orgs registered through hubspot. */
  enableRedirectOnSOVUpload: Scalars['Boolean']['output'];
  /** Enables org members to view the Risk Manager Section of the Underwriting Assessment Report within permitted streams. */
  enableRiskManagerAssessment: Scalars['Boolean']['output'];
  /** Flag to enable/disable access to SOV Manager. */
  enableSOVManager: Scalars['Boolean']['output'];
  /** Flag to enable/disable secondary modifiers in the property modal. */
  enableSecondaryModifiers: Scalars['Boolean']['output'];
  /** If set to false, support won't receive emails from sov uploads. Default false. */
  enableSendEmailOnSOVUpload: Scalars['Boolean']['output'];
  /** Flag to enable/disable the stream dashboard. */
  enableStreamDashboard: Scalars['Boolean']['output'];
  /** Flag to enable/disable SubValues for all segments of the organization. */
  enableSubValues: Scalars['Boolean']['output'];
  /** If set to false, createSubmission mutation will assign creator as StreamViewer instead of RiskManager */
  enableUnderwriterEdit: Scalars['Boolean']['output'];
  /**  flag to enable/disable the upload portal  */
  enableUploadPortal: Scalars['Boolean']['output'];
  /** Flag to enable/disable virtual inspections on properties. */
  enableVirtualInspections: Scalars['Boolean']['output'];
  /** Deprecated. Use configuredFeatures instead. List of features enabled for this org */
  enabledFeatures?: Maybe<Array<Scalars['String']['output']>>;
  /** Flag to exclude this organization from the reporting. When true data from this organization will not be included in the reports. */
  excludeFromReporting: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /**  Returns progress for onboarding document uploads if state is SOV_PROCESSED  */
  onboardingDocumentProgress?: Maybe<OnboardingDocumentProgress>;
  /**  Returns the denormalized set of documents for all properties if state is SOV_PROCESSED  */
  onboardingDocuments?: Maybe<Array<Maybe<OnboardingDocument>>>;
  /**  The set of properties from the SOV transformed during onboarding if state is SOV_PROCESSED  */
  onboardingProperties?: Maybe<Array<Maybe<PropertyData>>>;
  orgLevel: OrgLevelType;
  /**  System org type  */
  orgType: OrgType;
  /**  List of projects sorted by due dates, most recent first. */
  projects?: Maybe<Array<Project>>;
  /**  List of enum attribute values.  */
  propertyEnumMetadata?: Maybe<Array<EnumAttributeValues>>;
  reportingType: OrgReportingType;
  /**  Sales channel name  */
  salesChannel?: Maybe<Scalars['String']['output']>;
  /**  ID of the Salesforce account associated with this organization  */
  salesforceAccountID?: Maybe<Scalars['String']['output']>;
  /** Flag to show or hide non-enrichment documents on properties */
  showNonEnrichmentDocs: Scalars['Boolean']['output'];
  /**  The SOVs for the organization  */
  sovs?: Maybe<Array<Sov>>;
  tags: Array<Scalars['String']['output']>;
  /** Unit System that the org should use. Defaults to 'imperial' */
  unitOfMeasurement: Scalars['String']['output'];
};

export type OrganizationActivitiesInput = {
  /** Cursor to fetch subsequent pages of results. */
  cursor?: InputMaybe<Scalars['String']['input']>;
  /** Filter to apply to the activities. */
  filter?: InputMaybe<ActivityFilterInput>;
  /** The number of activities to evaluate. If a filter is provided, the limit is applied before the filter. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** The name of the organization. */
  organizationName: Scalars['String']['input'];
};

export type OrganizationActivitiesResponse = {
  __typename?: 'OrganizationActivitiesResponse';
  /** The activities which have occurred within the organization. */
  activities: Array<ActivityEvent>;
  /**
   * Cursor is used for pagination. The API will return the next page of results if the cursor is passed.
   * If the cursor is null, then this is the last page of activities.
   */
  cursor?: Maybe<Scalars['String']['output']>;
};

export type OrganizationTagsInput = {
  orgID: Scalars['ID']['input'];
};

export type OrganizationTagsResult = {
  __typename?: 'OrganizationTagsResult';
  tags: Array<Scalars['String']['output']>;
};

export type OrganizationsPage = {
  __typename?: 'OrganizationsPage';
  organizations: Array<Organization>;
  /**  information about this organizations page  */
  pageInfo: PageInfo;
};

export type OriginClassification = {
  classifications: Array<Scalars['String']['input']>;
  origin: Scalars['String']['input'];
};

export type OwnerCustomAttributes = {
  __typename?: 'OwnerCustomAttributes';
  ownerDecimal1?: Maybe<Scalars['Float']['output']>;
  ownerDecimal2?: Maybe<Scalars['Float']['output']>;
  ownerDecimal3?: Maybe<Scalars['Float']['output']>;
  ownerDecimal4?: Maybe<Scalars['Float']['output']>;
  ownerDecimal5?: Maybe<Scalars['Float']['output']>;
  ownerDecimal6?: Maybe<Scalars['Float']['output']>;
  ownerDecimal7?: Maybe<Scalars['Float']['output']>;
  ownerDecimal8?: Maybe<Scalars['Float']['output']>;
  ownerDecimal9?: Maybe<Scalars['Float']['output']>;
  ownerDecimal10?: Maybe<Scalars['Float']['output']>;
  ownerDecimal11?: Maybe<Scalars['Float']['output']>;
  ownerDecimal12?: Maybe<Scalars['Float']['output']>;
  ownerDecimal13?: Maybe<Scalars['Float']['output']>;
  ownerDecimal14?: Maybe<Scalars['Float']['output']>;
  ownerDecimal15?: Maybe<Scalars['Float']['output']>;
  ownerDecimal101?: Maybe<Scalars['Float']['output']>;
  ownerDecimal102?: Maybe<Scalars['Float']['output']>;
  ownerDecimal103?: Maybe<Scalars['Float']['output']>;
  ownerDecimal104?: Maybe<Scalars['Float']['output']>;
  ownerDecimal105?: Maybe<Scalars['Float']['output']>;
  ownerDecimal106?: Maybe<Scalars['Float']['output']>;
  ownerDecimal107?: Maybe<Scalars['Float']['output']>;
  ownerDecimal108?: Maybe<Scalars['Float']['output']>;
  ownerDecimal109?: Maybe<Scalars['Float']['output']>;
  ownerDecimal110?: Maybe<Scalars['Float']['output']>;
  ownerDecimal111?: Maybe<Scalars['Float']['output']>;
  ownerDecimal112?: Maybe<Scalars['Float']['output']>;
  ownerDecimal113?: Maybe<Scalars['Float']['output']>;
  ownerDecimal114?: Maybe<Scalars['Float']['output']>;
  ownerDecimal115?: Maybe<Scalars['Float']['output']>;
  ownerDecimal116?: Maybe<Scalars['Float']['output']>;
  ownerDecimal117?: Maybe<Scalars['Float']['output']>;
  ownerDecimal118?: Maybe<Scalars['Float']['output']>;
  ownerDecimal119?: Maybe<Scalars['Float']['output']>;
  ownerDecimal120?: Maybe<Scalars['Float']['output']>;
  ownerDecimal121?: Maybe<Scalars['Float']['output']>;
  ownerDecimal122?: Maybe<Scalars['Float']['output']>;
  ownerDecimal123?: Maybe<Scalars['Float']['output']>;
  ownerDecimal124?: Maybe<Scalars['Float']['output']>;
  ownerDecimal125?: Maybe<Scalars['Float']['output']>;
  ownerDecimal126?: Maybe<Scalars['Float']['output']>;
  ownerDecimal127?: Maybe<Scalars['Float']['output']>;
  ownerDecimal128?: Maybe<Scalars['Float']['output']>;
  ownerDecimal129?: Maybe<Scalars['Float']['output']>;
  ownerDecimal130?: Maybe<Scalars['Float']['output']>;
  ownerText1?: Maybe<Scalars['String']['output']>;
  ownerText2?: Maybe<Scalars['String']['output']>;
  ownerText3?: Maybe<Scalars['String']['output']>;
  ownerText4?: Maybe<Scalars['String']['output']>;
  ownerText5?: Maybe<Scalars['String']['output']>;
  ownerText6?: Maybe<Scalars['String']['output']>;
  ownerText7?: Maybe<Scalars['String']['output']>;
  ownerText8?: Maybe<Scalars['String']['output']>;
  ownerText9?: Maybe<Scalars['String']['output']>;
  ownerText10?: Maybe<Scalars['String']['output']>;
  ownerText11?: Maybe<Scalars['String']['output']>;
  ownerText12?: Maybe<Scalars['String']['output']>;
  ownerText13?: Maybe<Scalars['String']['output']>;
  ownerText14?: Maybe<Scalars['String']['output']>;
  ownerText15?: Maybe<Scalars['String']['output']>;
  ownerText101?: Maybe<Scalars['String']['output']>;
  ownerText102?: Maybe<Scalars['String']['output']>;
  ownerText103?: Maybe<Scalars['String']['output']>;
  ownerText104?: Maybe<Scalars['String']['output']>;
  ownerText105?: Maybe<Scalars['String']['output']>;
  ownerText106?: Maybe<Scalars['String']['output']>;
  ownerText107?: Maybe<Scalars['String']['output']>;
  ownerText108?: Maybe<Scalars['String']['output']>;
  ownerText109?: Maybe<Scalars['String']['output']>;
  ownerText110?: Maybe<Scalars['String']['output']>;
  ownerText111?: Maybe<Scalars['String']['output']>;
  ownerText112?: Maybe<Scalars['String']['output']>;
  ownerText113?: Maybe<Scalars['String']['output']>;
  ownerText114?: Maybe<Scalars['String']['output']>;
  ownerText115?: Maybe<Scalars['String']['output']>;
  ownerText116?: Maybe<Scalars['String']['output']>;
  ownerText117?: Maybe<Scalars['String']['output']>;
  ownerText118?: Maybe<Scalars['String']['output']>;
  ownerText119?: Maybe<Scalars['String']['output']>;
  ownerText120?: Maybe<Scalars['String']['output']>;
  ownerText121?: Maybe<Scalars['String']['output']>;
  ownerText122?: Maybe<Scalars['String']['output']>;
  ownerText123?: Maybe<Scalars['String']['output']>;
  ownerText124?: Maybe<Scalars['String']['output']>;
  ownerText125?: Maybe<Scalars['String']['output']>;
  ownerText126?: Maybe<Scalars['String']['output']>;
  ownerText127?: Maybe<Scalars['String']['output']>;
  ownerText128?: Maybe<Scalars['String']['output']>;
  ownerText129?: Maybe<Scalars['String']['output']>;
  ownerText130?: Maybe<Scalars['String']['output']>;
};

export type PdfFactLinkData = {
  __typename?: 'PDFFactLinkData';
  /** The height in pixels for the link bounding box */
  height?: Maybe<Scalars['Float']['output']>;
  /** The characted length of the extracted fact */
  length?: Maybe<Scalars['Int']['output']>;
  /** The document page number from which the fact was extracted */
  pageNumber: Scalars['Int']['output'];
  /** The character start index from which the fact was extracted */
  startIndex?: Maybe<Scalars['Int']['output']>;
  /** The width in pixels for the link bounding box */
  width?: Maybe<Scalars['Float']['output']>;
  /** The x value in pixels for the top left corner of the link bounding box */
  x?: Maybe<Scalars['Float']['output']>;
  /** The y value in pixels for the top left corner of the link bounding box */
  y?: Maybe<Scalars['Float']['output']>;
};

/**  additional information about pages  */
export type PageInfo = {
  __typename?: 'PageInfo';
  /**  cursor to the next page  */
  cursor: Scalars['String']['output'];
  /**  whether the page has a next page with information available   */
  hasNextPage: Scalars['Boolean']['output'];
  /**  total number of rows in the dataset  */
  totalCount: Scalars['Int']['output'];
};

/**  Array of percentage tupples  */
export type PercentageChart = Chart & {
  __typename?: 'PercentageChart';
  name: Scalars['String']['output'];
  values: Array<FloatTupple>;
};

/**  Chart containing multiple percentage charts  */
export type PercentageCharts = Chart & {
  __typename?: 'PercentageCharts';
  charts: Array<PercentageChart>;
  name: Scalars['String']['output'];
};

/** Chart with data values over a series of time periods. */
export type PeriodChart = ComparisonChart & {
  __typename?: 'PeriodChart';
  /**
   * Facets describe the intersection of attributes to which the chart data applies.
   * For example, a chart with facets LOSS_CATASTROPHE and PROPERTY_STATUS_ONGOING_OR_NEW
   * will contain data filtered for properties with catastrophic losses and which exist in
   * the snapshot.
   */
  facets?: Maybe<Array<ChartFacet>>;
  name: ComparisonChartName;
  summaryStats?: Maybe<Array<FloatTupple>>;
  values: Array<PeriodStat>;
};

export type PeriodStat = {
  __typename?: 'PeriodStat';
  end: Scalars['Date']['output'];
  endValue: Scalars['Float']['output'];
  start?: Maybe<Scalars['Date']['output']>;
  startValue?: Maybe<Scalars['Float']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  action: Action;
  resource: ResourceType;
};

export type PermissionV2 = {
  __typename?: 'PermissionV2';
  action: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

export type Pictures = {
  __typename?: 'Pictures';
  contentType?: Maybe<Scalars['String']['output']>;
  extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  httpURLs: ImageUrLs;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type Pillar = {
  __typename?: 'Pillar';
  /**  Attribute for the pillar  */
  attribute: PillarAttribute;
  /**  Granular components of the pillar  */
  components?: Maybe<Array<PillarComponent>>;
  /**  Description of pillar  */
  description?: Maybe<Scalars['String']['output']>;
  /**  Description of pillar factor  */
  factorDescription?: Maybe<Scalars['String']['output']>;
  /**  Overall grade for the pillar  */
  grade?: Maybe<Grade>;
};

export enum PillarAttribute {
  CatFootprint = 'CAT_FOOTPRINT',
  LossHistory = 'LOSS_HISTORY',
  MgmtPrograms = 'MGMT_PROGRAMS',
  RiskQuality = 'RISK_QUALITY'
}

export type PillarComponent = {
  __typename?: 'PillarComponent';
  /**  Attribute for the component  */
  attribute: PillarComponentAttribute;
  /**  Description of component  */
  description?: Maybe<Scalars['String']['output']>;
  /**  Overall grade for the component  */
  grade?: Maybe<Grade>;
  /**
   * Aggregated assessment-level metrics for the component by category.
   * Only available for HOT_WORK_CONTRACTOR_CONTROLS, HOUSEKEEPING, SAFETY_TRAINING, MAINTENANCE_PROCEDURES and
   * MANAGEMENT_PRACTICES attributes.
   */
  propertyAssessmentMetrics?: Maybe<Array<PropertyAssessmentMetric>>;
  /**
   * Aggregated property-level metrics for the component by category.
   * Only available for RE_RISK_GRADE, PREDICTIVE_RISK_GRADE and HAZARD_GRADE attributes.
   */
  propertyGradeMetrics?: Maybe<Array<PropertyGradeMetric>>;
};

export enum PillarComponentAttribute {
  CaeqAggregation = 'CAEQ_AGGREGATION',
  Cat = 'CAT',
  CustomerAverageLoss = 'CUSTOMER_AVERAGE_LOSS',
  CustomerLossFrequency = 'CUSTOMER_LOSS_FREQUENCY',
  HazardGrade = 'HAZARD_GRADE',
  HotWorkContractorControls = 'HOT_WORK_CONTRACTOR_CONTROLS',
  Housekeeping = 'HOUSEKEEPING',
  IndustryLossExperience = 'INDUSTRY_LOSS_EXPERIENCE',
  MaintenanceProcedures = 'MAINTENANCE_PROCEDURES',
  ManagementPractices = 'MANAGEMENT_PRACTICES',
  PredictiveRiskGrade = 'PREDICTIVE_RISK_GRADE',
  ReRiskGrade = 'RE_RISK_GRADE',
  SafetyTraining = 'SAFETY_TRAINING',
  UswsAggregation = 'USWS_AGGREGATION'
}

export type PoliciesByStreamInput = {
  /**  If not specified, latest visible snapshot is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type Policy = {
  __typename?: 'Policy';
  archipelagoPolicyId: Scalars['String']['output'];
  clientPolicyId?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['Float']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  /** Link to all the policy documents */
  documents: Array<Document>;
  fees?: Maybe<Scalars['Float']['output']>;
  grossPremium?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  layerAttachmentPoint?: Maybe<Scalars['Float']['output']>;
  layerLimit?: Maybe<Scalars['Float']['output']>;
  layerName?: Maybe<Scalars['String']['output']>;
  layerShare?: Maybe<Scalars['Float']['output']>;
  /**  This value is the 'Client Policy ID' of the master policy. We use the Client Policy ID because the Archipelago Policy ID is not known on load.  */
  masterPolicyId?: Maybe<Scalars['String']['output']>;
  netPremium?: Maybe<Scalars['Float']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  otherPolicyCosts?: Maybe<Scalars['Float']['output']>;
  policyBroker?: Maybe<Scalars['String']['output']>;
  policyCurrency?: Maybe<Scalars['String']['output']>;
  policyInsurer?: Maybe<Scalars['String']['output']>;
  policyType?: Maybe<Scalars['String']['output']>;
  /** Link to the program */
  program: Program;
  taxes?: Maybe<Scalars['Float']['output']>;
  totalPolicyCost?: Maybe<Scalars['Float']['output']>;
};

export type PolicyDataInput = {
  clientPolicyId?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customAttributes?: InputMaybe<Array<InputMaybe<CustomAttribute>>>;
  /**
   * In update cases, the value should be considered a replace, i.e if an id is not currently linked
   * in the db but listed in the cell then it should be added, if a id is linked in the db but not in
   * the cell it should be removed.
   */
  documentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fees?: InputMaybe<Scalars['Float']['input']>;
  grossPremium?: InputMaybe<Scalars['Float']['input']>;
  layerAttachmentPoint?: InputMaybe<Scalars['Float']['input']>;
  layerLimit?: InputMaybe<Scalars['Float']['input']>;
  layerName?: InputMaybe<Scalars['String']['input']>;
  layerShare?: InputMaybe<Scalars['Float']['input']>;
  /**  This value is the 'Client Policy ID' of the master policy. We use the Client Policy ID because the Archipelago Policy ID is not known on load.  */
  masterPolicyId?: InputMaybe<Scalars['String']['input']>;
  netPremium?: InputMaybe<Scalars['Float']['input']>;
  otherPolicyCosts?: InputMaybe<Scalars['Float']['input']>;
  policyBroker?: InputMaybe<Scalars['String']['input']>;
  policyCurrency?: InputMaybe<Scalars['String']['input']>;
  policyInsurer?: InputMaybe<Scalars['String']['input']>;
  policyType?: InputMaybe<Scalars['String']['input']>;
  /** Used for historical loading, if not specified, the change timeframe is assumed to be current. */
  revisionDate?: InputMaybe<Scalars['Time']['input']>;
  taxes?: InputMaybe<Scalars['Float']['input']>;
  totalPolicyCost?: InputMaybe<Scalars['Float']['input']>;
};

export enum PolicyEffectiveYearId {
  CurrentTerm = 'CurrentTerm',
  ProspectiveYear = 'ProspectiveYear',
  YearPriorEighth = 'YearPriorEighth',
  YearPriorFifth = 'YearPriorFifth',
  YearPriorFirst = 'YearPriorFirst',
  YearPriorFourth = 'YearPriorFourth',
  YearPriorSecond = 'YearPriorSecond',
  YearPriorSeventh = 'YearPriorSeventh',
  YearPriorSixth = 'YearPriorSixth',
  YearPriorThird = 'YearPriorThird'
}

export type PreCheckBrokerInvite = {
  email: Scalars['String']['input'];
  /**  Name is included in emails to collaborators and prospects when the broker shares the pre-check report.  */
  name: Scalars['String']['input'];
};

export type PreCheckInvitee = {
  __typename?: 'PreCheckInvitee';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: PreCheckShareType;
};

export type PreCheckPermissions = {
  __typename?: 'PreCheckPermissions';
  canShareWithCollaborators: Scalars['Boolean']['output'];
  canShareWithProspects: Scalars['Boolean']['output'];
};

export enum PreCheckRecommendationStatus {
  BrokerAccepted = 'BROKER_ACCEPTED',
  New = 'NEW',
  ProspectAccepted = 'PROSPECT_ACCEPTED'
}

export type PreCheckRecommendationSummaryInput = {
  orgName: Scalars['String']['input'];
  /**  If snapshotName is not provided, the latest authorized snapshot with recommendations will be used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /**  If streamSlug is not provided, the My Properties stream will be used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PreCheckRecommendationSummaryResponse = {
  __typename?: 'PreCheckRecommendationSummaryResponse';
  mlRecommendationsStatus: PropertyRecommendationsStatus;
  permissions: PreCheckPermissions;
  status: PreCheckRecommendationStatus;
};

export type PreCheckShareInvite = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /**  Type affects the notifications that the invitee receives.  */
  type: PreCheckShareType;
};

export enum PreCheckShareType {
  Broker = 'BROKER',
  Collaborator = 'COLLABORATOR',
  Prospect = 'PROSPECT'
}

/** A principal is a user, service or group requesting an action on a protected resource. The PrincipalType tells which possible values are supported by the system. */
export enum PrincipalType {
  /**
   * Principal of type Organization and is identified by it's UUID. This allows the authorization
   * service to make authorization decisions on a resource for all users that have this org as their
   * primary org. All roles granted to this org will also implicitly grant the user the same roles.
   */
  Org = 'Org',
  /**
   * Principal of type User and is identified by the user's email address. This allows the
   * authorization service to make authorization decisions on a resource for a single user.
   */
  User = 'User'
}

export type Program = {
  __typename?: 'Program';
  archipelagoProgramId: Scalars['ID']['output'];
  broker?: Maybe<Scalars['String']['output']>;
  clientProgramId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Document>>;
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  expiryDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Float']['output']>;
  limitType?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Array<Loss>>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  policies?: Maybe<Array<Policy>>;
  policyTrigger?: Maybe<Scalars['String']['output']>;
  specificStreamLineOfCoverage?: Maybe<Scalars['String']['output']>;
  stream?: Maybe<Stream>;
};

export type ProgramDataInput = {
  broker?: InputMaybe<Scalars['String']['input']>;
  clientProgramId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customAttributes?: InputMaybe<Array<InputMaybe<CustomAttribute>>>;
  deductible?: InputMaybe<Scalars['Float']['input']>;
  /**
   * In update cases, the value should be considered a replace, i.e if an id is not currently linked
   * in the db but listed in the cell then it should be added, if a id is linked in the db but not in
   * the cell it should be removed.
   */
  documentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  limitType?: InputMaybe<Scalars['String']['input']>;
  policyTrigger?: InputMaybe<Scalars['String']['input']>;
  /** In RFC3339 format. Used for historical loading, if not specified, the change timeframe is assumed to be current. */
  revisionDate?: InputMaybe<Scalars['Time']['input']>;
  specificStreamLineOfCoverage?: InputMaybe<Scalars['String']['input']>;
};

export type Project = {
  __typename?: 'Project';
  dueOn?: Maybe<Scalars['Date']['output']>;
  effectiveFrom?: Maybe<Scalars['Date']['output']>;
  /**  User specified unique project name, within the specified org.  */
  id: Scalars['String']['output'];
  jobs?: Maybe<Array<Job>>;
  /**  most recent ingest job id of the project  */
  lastIngestJobID?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  /**
   * Snapshot effective time is used for snapshot creation on this project.
   * This is calculated by going 1 quarter prior to the project effective date + historical rev counter.
   */
  snapshotEffectiveTime?: Maybe<Scalars['Time']['output']>;
  /**  list of snapshots that were created for this project  */
  snapshots?: Maybe<Array<Snapshot>>;
};

export type PropertiesByAiDsInput = {
  archipelagoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * This is used in conjunction with includeProvenance which must be set to true.
   * If specifed value is true, includes property documents.
   * Default value is false.
   */
  includeDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If specifed value is true, includes property provenance.
   * Default value is false.
   */
  includeProvenance?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertiesByAiDsResponse = {
  __typename?: 'PropertiesByAiDsResponse';
  properties?: Maybe<Array<Property>>;
};

export type PropertiesByJobInput = {
  jobID: Scalars['String']['input'];
};

export type PropertiesChart = {
  __typename?: 'PropertiesChart';
  propertyCount?: Maybe<Scalars['Int']['output']>;
  propertyYoYPctDiff?: Maybe<Scalars['Float']['output']>;
};

export type PropertiesForLossesInput = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};

export type PropertiesGroup = {
  __typename?: 'PropertiesGroup';
  properties: Array<PropertySummary>;
  /**  Total number of shared properties on server.  */
  totalResults: Scalars['Int']['output'];
};

export type PropertiesPage = {
  __typename?: 'PropertiesPage';
  pageInfo: PageInfo;
  properties?: Maybe<Array<Property>>;
};

export type PropertiesPageResponse = {
  __typename?: 'PropertiesPageResponse';
  properties: Array<Property>;
};

export type PropertiesWithLossesInput = {
  cursor: Scalars['String']['input'];
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type PropertiesWithSharedDocumentsInput = {
  /**
   * The maximum number of returned properties. Internal limit is 100. If provided value is
   * more than 100, server returns at most 100 properties
   */
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  propertyID: Scalars['ArchipelagoID']['input'];
  /**
   * Start index for requested results. This value in combination with maxResults field can be used to scroll through
   * the total list of results. Valid values are 1 to the total number of results on server.
   * If value is more than totalResults, nothing will be returned.
   */
  resultsStartIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type Property = {
  __typename?: 'Property';
  accessoryStructures?: Maybe<Scalars['String']['output']>;
  acquiredOrBuilt?: Maybe<Scalars['String']['output']>;
  acquisitionOrOccupancyDate?: Maybe<Scalars['Date']['output']>;
  airEqBrickVeneer?: Maybe<Scalars['String']['output']>;
  airEqBuildingCondition?: Maybe<Scalars['String']['output']>;
  airEqBuildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  airEqBuildingShape?: Maybe<Scalars['String']['output']>;
  airEqChimney?: Maybe<Scalars['String']['output']>;
  airEqColdFormedTub?: Maybe<Scalars['String']['output']>;
  airEqColumnBasementType?: Maybe<Scalars['String']['output']>;
  airEqEquipment?: Maybe<Scalars['String']['output']>;
  airEqFoundationConnection?: Maybe<Scalars['String']['output']>;
  airEqFoundationType?: Maybe<Scalars['String']['output']>;
  airEqInternalPartitions?: Maybe<Scalars['String']['output']>;
  airEqIsFireSprinklerAvailable?: Maybe<Scalars['String']['output']>;
  airEqIsValueType?: Maybe<Scalars['String']['output']>;
  airEqLatticeType?: Maybe<Scalars['String']['output']>;
  airEqMultiStoryHallType?: Maybe<Scalars['String']['output']>;
  airEqOrnamentation?: Maybe<Scalars['String']['output']>;
  airEqPounding?: Maybe<Scalars['String']['output']>;
  airEqRedundancy?: Maybe<Scalars['String']['output']>;
  airEqRetrofitMeasures?: Maybe<Scalars['String']['output']>;
  airEqShortColumn?: Maybe<Scalars['String']['output']>;
  airEqSoftStory?: Maybe<Scalars['String']['output']>;
  airEqSpecialEQResistiveSystems?: Maybe<Scalars['String']['output']>;
  airEqStructuralIrregularity?: Maybe<Scalars['String']['output']>;
  airEqTallOneStory?: Maybe<Scalars['String']['output']>;
  airEqTank?: Maybe<Scalars['String']['output']>;
  airEqTorsion?: Maybe<Scalars['String']['output']>;
  airEqTransitionInSRCType?: Maybe<Scalars['String']['output']>;
  airEqWallSiding?: Maybe<Scalars['String']['output']>;
  airEqWallType?: Maybe<Scalars['String']['output']>;
  airEqWaterHeater?: Maybe<Scalars['String']['output']>;
  airEqWeldingDetail?: Maybe<Scalars['String']['output']>;
  airModifiers?: Maybe<Scalars['String']['output']>;
  airWindAdjacentBuildingHeight?: Maybe<Scalars['String']['output']>;
  airWindAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevation?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindBasementFinishType?: Maybe<Scalars['String']['output']>;
  airWindBasementLevelCount?: Maybe<Scalars['String']['output']>;
  airWindCertifiedStructuresIbhs?: Maybe<Scalars['String']['output']>;
  airWindContentVulnerability?: Maybe<Scalars['String']['output']>;
  airWindCustomElevation?: Maybe<Scalars['String']['output']>;
  airWindCustomElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindCustomFloodStandard?: Maybe<Scalars['Float']['output']>;
  airWindCustomFloodZone?: Maybe<Scalars['String']['output']>;
  airWindExteriorDoors?: Maybe<Scalars['String']['output']>;
  airWindFirmCompliance?: Maybe<Scalars['String']['output']>;
  airWindFirstFloorHeight?: Maybe<Scalars['Float']['output']>;
  airWindFirstFloorHeightUnit?: Maybe<Scalars['String']['output']>;
  airWindFloorOfInterest?: Maybe<Scalars['String']['output']>;
  airWindGlassPercentage?: Maybe<Scalars['String']['output']>;
  airWindGlassType?: Maybe<Scalars['String']['output']>;
  airWindLargeMissiles?: Maybe<Scalars['String']['output']>;
  airWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  airWindRoofAttachedStructures?: Maybe<Scalars['String']['output']>;
  airWindRoofCovering?: Maybe<Scalars['String']['output']>;
  airWindRoofCoveringAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofDeck?: Maybe<Scalars['String']['output']>;
  airWindRoofDeckAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  airWindRoofPitch?: Maybe<Scalars['String']['output']>;
  airWindRoofYearBuilt?: Maybe<Scalars['Int']['output']>;
  airWindSealOfApproval?: Maybe<Scalars['String']['output']>;
  airWindServiceEquipmentProtection?: Maybe<Scalars['String']['output']>;
  airWindSmallDebris?: Maybe<Scalars['String']['output']>;
  airWindTerrainRoughness?: Maybe<Scalars['String']['output']>;
  airWindTreeExposure?: Maybe<Scalars['String']['output']>;
  airWindWallAttachedStructure?: Maybe<Scalars['String']['output']>;
  airWindWindowProtection?: Maybe<Scalars['String']['output']>;
  annualBaseRent?: Maybe<Scalars['Float']['output']>;
  annualBaseRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRent?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualGrossProfit?: Maybe<Scalars['Float']['output']>;
  annualGrossProfitDisplay?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenue?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenueDisplay?: Maybe<Scalars['Float']['output']>;
  annualServiceCharge?: Maybe<Scalars['Float']['output']>;
  annualServiceChargeDisplay?: Maybe<Scalars['Float']['output']>;
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  attributeEdits?: Maybe<Array<AttributeEditInfo>>;
  attributeProvenance?: Maybe<Array<Provenance>>;
  backupGenerator?: Maybe<Scalars['String']['output']>;
  backupGeneratorDieselStorageCapacity?: Maybe<Scalars['String']['output']>;
  backupGeneratorLocation?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  basementFinishType?: Maybe<Scalars['String']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingDescription?: Maybe<Scalars['String']['output']>;
  buildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  buildingFoundationType?: Maybe<Scalars['String']['output']>;
  buildingRedundancy?: Maybe<Scalars['String']['output']>;
  buildingReplacementCost?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPartner?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifference?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifferenceRange?: Maybe<Scalars['String']['output']>;
  buildingReplacementCostValueDifference?: Maybe<Scalars['Float']['output']>;
  buildingSprinklerType?: Maybe<Scalars['String']['output']>;
  buildingSprinklered?: Maybe<Scalars['String']['output']>;
  buildingValue?: Maybe<Scalars['Float']['output']>;
  buildingValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessContinuityPlans?: Maybe<Scalars['String']['output']>;
  businessIncomeValue?: Maybe<Scalars['Float']['output']>;
  businessIncomeValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCost?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCostDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionPreparedness?: Maybe<Scalars['String']['output']>;
  businessInterruptionRedundancy?: Maybe<Scalars['String']['output']>;
  chimneys?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commodityHazardClass?: Maybe<Scalars['String']['output']>;
  constructionDescription?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  constructionTypeAIR?: Maybe<Scalars['String']['output']>;
  constructionTypeATC?: Maybe<Scalars['String']['output']>;
  constructionTypeArchipelago?: Maybe<Scalars['String']['output']>;
  constructionTypeCluster?: Maybe<Scalars['String']['output']>;
  constructionTypeISO?: Maybe<Scalars['String']['output']>;
  constructionTypeRMS?: Maybe<Scalars['String']['output']>;
  consumerAttributes?: Maybe<ConsumerCustomAttributes>;
  contentsReplacementCost?: Maybe<Scalars['Float']['output']>;
  contentsReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  contentsVulnerabilityFlood?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityVerticalDistributionOfContents?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityWind?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionComments?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionFlag?: Maybe<Scalars['Boolean']['output']>;
  controlOfCombustiblesProgram?: Maybe<Scalars['String']['output']>;
  convectiveStormZone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  crippleWalls?: Maybe<Scalars['String']['output']>;
  currencyPair?: Maybe<Scalars['String']['output']>;
  customerProvidedGeocode?: Maybe<Scalars['Boolean']['output']>;
  dependencyComments?: Maybe<Scalars['String']['output']>;
  dependencyCoveragePercentage?: Maybe<Scalars['Float']['output']>;
  dependencyFlag?: Maybe<Scalars['Boolean']['output']>;
  dependencyValue?: Maybe<Scalars['Float']['output']>;
  dependencyValueDisplay?: Maybe<Scalars['Float']['output']>;
  dispositionDate?: Maybe<Scalars['Date']['output']>;
  distanceToCoast?: Maybe<Scalars['Float']['output']>;
  distanceToNearestBuilding?: Maybe<Scalars['Float']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Document>>;
  doorAndFrameType?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['Time']['output']>;
  electronicsValue?: Maybe<Scalars['Float']['output']>;
  electronicsValueDisplay?: Maybe<Scalars['Float']['output']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: Maybe<Scalars['String']['output']>;
  emergencyFloodProtectionMeasures?: Maybe<Scalars['String']['output']>;
  enriched?: Maybe<Scalars['Boolean']['output']>;
  eqSpecialSystems?: Maybe<Scalars['String']['output']>;
  equipmentEarthquakeBracing?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  exchangeRateDate?: Maybe<Scalars['Date']['output']>;
  exclusions?: Maybe<Scalars['String']['output']>;
  extraExpenseValue?: Maybe<Scalars['Float']['output']>;
  extraExpenseValueDisplay?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValue?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValueDisplay?: Maybe<Scalars['Float']['output']>;
  fireDetectionSystems?: Maybe<Scalars['String']['output']>;
  fireEstimatedMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProbableMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProtectionDescription?: Maybe<Scalars['String']['output']>;
  fireProtectionInspectionProgram?: Maybe<Scalars['String']['output']>;
  firePumpBackupPeriod?: Maybe<Scalars['Float']['output']>;
  firePumpChurnRate?: Maybe<Scalars['Float']['output']>;
  firePumpExcessCapacity?: Maybe<Scalars['String']['output']>;
  firePumpFlowRate?: Maybe<Scalars['String']['output']>;
  firePumpPowerSupply?: Maybe<Scalars['String']['output']>;
  firePumpTestResults?: Maybe<Scalars['String']['output']>;
  firstFloorElevation?: Maybe<Scalars['Float']['output']>;
  firstFloorHeight?: Maybe<Scalars['Float']['output']>;
  flammableLiquidCapacity?: Maybe<Scalars['Float']['output']>;
  flammableLiquidDescription?: Maybe<Scalars['String']['output']>;
  flammableLiquidGasLineManagement?: Maybe<Scalars['String']['output']>;
  flammableLiquidProgram?: Maybe<Scalars['String']['output']>;
  flammableLiquidStorageLocation?: Maybe<Scalars['String']['output']>;
  flammableLiquidsOnsite?: Maybe<Scalars['String']['output']>;
  floodMissiles?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  floorOfInterest?: Maybe<Scalars['Int']['output']>;
  floorSystem?: Maybe<Scalars['String']['output']>;
  foundationToFrameConnection?: Maybe<Scalars['String']['output']>;
  generatorTestingProgram?: Maybe<Scalars['String']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  glassPercentage?: Maybe<Scalars['String']['output']>;
  glassType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  hasSeismicInspectionReport?: Maybe<Scalars['Boolean']['output']>;
  hazardSummary?: Maybe<Scalars['Boolean']['output']>;
  heightOfRackStorage?: Maybe<Scalars['Float']['output']>;
  highHazardAreaSprinklerType?: Maybe<Scalars['String']['output']>;
  hotWorkProgram?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsResidual?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsStatic?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  improvementsValue?: Maybe<Scalars['Float']['output']>;
  improvementsValueDisplay?: Maybe<Scalars['Float']['output']>;
  inRackSprinklerType?: Maybe<Scalars['String']['output']>;
  interiorPartitions?: Maybe<Scalars['String']['output']>;
  inventoryValue?: Maybe<Scalars['Float']['output']>;
  inventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  lastEngineeringVisitDate?: Maybe<Scalars['Date']['output']>;
  lastValuationDate?: Maybe<Scalars['String']['output']>;
  latestRevisionId?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  leasedOrOwned?: Maybe<Scalars['String']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  lossAttributes?: Maybe<LossAttributes>;
  lossEngineeringReportPresent?: Maybe<Scalars['String']['output']>;
  machineryValue?: Maybe<Scalars['Float']['output']>;
  machineryValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValue?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValue?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscContentsValue?: Maybe<Scalars['Float']['output']>;
  miscContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  modelingAvailable?: Maybe<Scalars['Boolean']['output']>;
  multiTenant?: Maybe<Scalars['Boolean']['output']>;
  naturalGasAutomaticShutoff?: Maybe<Scalars['String']['output']>;
  naturalGasPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  numberOfBuildings?: Maybe<Scalars['Int']['output']>;
  numberOfFireHazardAreas?: Maybe<Scalars['Int']['output']>;
  numberOfHighPriorityRecommendations?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesAboveGround?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesBelowGround?: Maybe<Scalars['Int']['output']>;
  numberOfUnits?: Maybe<Scalars['Int']['output']>;
  occupancyDescription?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  occupancyTypeATC?: Maybe<Scalars['String']['output']>;
  occupancyTypeNAICS?: Maybe<Scalars['String']['output']>;
  occupancyTypeSIC?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationLevelFour?: Maybe<Scalars['String']['output']>;
  organizationLevelOne?: Maybe<Scalars['String']['output']>;
  organizationLevelThree?: Maybe<Scalars['String']['output']>;
  organizationLevelTwo?: Maybe<Scalars['String']['output']>;
  originalAddress?: Maybe<Scalars['String']['output']>;
  ornamentation?: Maybe<Scalars['String']['output']>;
  otherContentsValue?: Maybe<Scalars['Float']['output']>;
  otherContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  ownerAttributes?: Maybe<OwnerCustomAttributes>;
  payrollValue?: Maybe<Scalars['Float']['output']>;
  payrollValueDisplay?: Maybe<Scalars['Float']['output']>;
  percentageSprinklered?: Maybe<Scalars['Float']['output']>;
  permanentFloodMeasuresPresent?: Maybe<Scalars['String']['output']>;
  pictures?: Maybe<Array<Pictures>>;
  portableFireExtinguisherProgram?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryTenantNAICS?: Maybe<Scalars['String']['output']>;
  propertyManager?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<Scalars['String']['output']>;
  quakeScenarioEstimatedLoss?: Maybe<Scalars['Float']['output']>;
  quakeScenarioUpperLoss?: Maybe<Scalars['Float']['output']>;
  rackStoragePresent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  remoteMonitoringOfSprinklerSystem?: Maybe<Scalars['Boolean']['output']>;
  rentAndServiceIndemnityPeriod?: Maybe<Scalars['Int']['output']>;
  replacementCostMethodology?: Maybe<Scalars['String']['output']>;
  replacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  replacementCostPerFloorAreaPartnerDifference?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootage?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootageDisplay?: Maybe<Scalars['Float']['output']>;
  residentialGarage?: Maybe<Scalars['String']['output']>;
  revisionId?: Maybe<Scalars['String']['output']>;
  rmsEqAppendagesAndOrnamentation?: Maybe<Scalars['String']['output']>;
  rmsEqBaseIsolation?: Maybe<Scalars['String']['output']>;
  rmsEqConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsEqContentsRateGradeEQ?: Maybe<Scalars['String']['output']>;
  rmsEqCrippleWalls?: Maybe<Scalars['String']['output']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: Maybe<Scalars['String']['output']>;
  rmsEqEngineeredFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqEnvelopeOpening?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentEQBracing?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentSupportMaintenance?: Maybe<Scalars['String']['output']>;
  rmsEqExteriorWallsCladding?: Maybe<Scalars['String']['output']>;
  rmsEqFrameBoltedtoFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqPounding?: Maybe<Scalars['String']['output']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqRedundancy?: Maybe<Scalars['String']['output']>;
  rmsEqShapeConfiguration?: Maybe<Scalars['String']['output']>;
  rmsEqShortColumnCondition?: Maybe<Scalars['String']['output']>;
  rmsEqSoftStory?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerLeakageSusceptibility?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerType?: Maybe<Scalars['String']['output']>;
  rmsEqStructuralUpgradeNonURM?: Maybe<Scalars['String']['output']>;
  rmsEqTank?: Maybe<Scalars['String']['output']>;
  rmsEqTorsion?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryPartition?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: Maybe<Scalars['String']['output']>;
  rmsModifiers?: Maybe<Scalars['String']['output']>;
  rmsOtherBIPreparedness?: Maybe<Scalars['String']['output']>;
  rmsOtherBIRedundancy?: Maybe<Scalars['String']['output']>;
  rmsOtherPercentageContentsBelowGrade?: Maybe<Scalars['Float']['output']>;
  rmsRoofSheathingAttachment?: Maybe<Scalars['String']['output']>;
  rmsWindBIDependency?: Maybe<Scalars['String']['output']>;
  rmsWindBasementProtection?: Maybe<Scalars['String']['output']>;
  rmsWindCauseOfLossFlag?: Maybe<Scalars['String']['output']>;
  rmsWindCladding?: Maybe<Scalars['String']['output']>;
  rmsWindCommercialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsWindContentsRateGradeWind?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToFlood?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToWind?: Maybe<Scalars['String']['output']>;
  rmsWindCopingAndFlashingQuality?: Maybe<Scalars['String']['output']>;
  rmsWindFinishedFloorHeightAboveGround?: Maybe<Scalars['Float']['output']>;
  rmsWindFinishedFloorHeightMatchLevel?: Maybe<Scalars['Float']['output']>;
  rmsWindFirstFloorElevation?: Maybe<Scalars['Float']['output']>;
  rmsWindFloodMissiles?: Maybe<Scalars['String']['output']>;
  rmsWindFloodProtection?: Maybe<Scalars['String']['output']>;
  rmsWindFloorType?: Maybe<Scalars['String']['output']>;
  rmsWindFrameFoundationConnection?: Maybe<Scalars['String']['output']>;
  rmsWindGroundLevelEquipment?: Maybe<Scalars['String']['output']>;
  rmsWindOpeningProtection?: Maybe<Scalars['String']['output']>;
  rmsWindResidentialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindResistanceDoors?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAge?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  rmsWindRoofEquipmentWindBracing?: Maybe<Scalars['String']['output']>;
  rmsWindRoofFramingType?: Maybe<Scalars['String']['output']>;
  rmsWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  rmsWindRoofMaintenance?: Maybe<Scalars['String']['output']>;
  rmsWindRoofParapetsChimneys?: Maybe<Scalars['String']['output']>;
  rmsWindRoofSystemCovering?: Maybe<Scalars['String']['output']>;
  rmsWindVerticalExposureDistribution?: Maybe<Scalars['String']['output']>;
  rmsWindWindMissiles?: Maybe<Scalars['String']['output']>;
  roofChimneysAnchorage?: Maybe<Scalars['String']['output']>;
  roofCopingAndFlashing?: Maybe<Scalars['String']['output']>;
  roofDeckAnchorage?: Maybe<Scalars['String']['output']>;
  roofDeckType?: Maybe<Scalars['String']['output']>;
  roofDescription?: Maybe<Scalars['String']['output']>;
  roofDrainageType?: Maybe<Scalars['String']['output']>;
  roofEquipmentAnchorage?: Maybe<Scalars['String']['output']>;
  roofEstimatedReplacementYear?: Maybe<Scalars['Int']['output']>;
  roofGeometry?: Maybe<Scalars['String']['output']>;
  roofHailMitigation?: Maybe<Scalars['String']['output']>;
  roofImage?: Maybe<Scalars['String']['output']>;
  roofInspectionProgram?: Maybe<Scalars['String']['output']>;
  roofInstallYear?: Maybe<Scalars['Int']['output']>;
  roofParapets?: Maybe<Scalars['String']['output']>;
  roofScreensSignsAnchorageandBracing?: Maybe<Scalars['String']['output']>;
  roofSolarPanelCoverage?: Maybe<Scalars['String']['output']>;
  roofSolarPanelDescription?: Maybe<Scalars['String']['output']>;
  roofSolarPanelOwnership?: Maybe<Scalars['Float']['output']>;
  roofStructures?: Maybe<Scalars['String']['output']>;
  roofSystem?: Maybe<Scalars['String']['output']>;
  roofSystemAnchorage?: Maybe<Scalars['String']['output']>;
  roofingMaterialAnchorage?: Maybe<Scalars['String']['output']>;
  rooftopSolarPanels?: Maybe<Scalars['String']['output']>;
  rooftopWaterTanks?: Maybe<Scalars['String']['output']>;
  securityDescription?: Maybe<Scalars['String']['output']>;
  seismicDesignQuality?: Maybe<Scalars['String']['output']>;
  seismicDesignRValue?: Maybe<Scalars['Float']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicInspectionDate?: Maybe<Scalars['Date']['output']>;
  seismicStatus?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  shortColumnConcrete?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  smokingControlsProgram?: Maybe<Scalars['String']['output']>;
  softStory?: Maybe<Scalars['String']['output']>;
  sovSortOrder?: Maybe<Scalars['Int']['output']>;
  specificOccupancy?: Maybe<Scalars['String']['output']>;
  sprinklerHeadSize?: Maybe<Scalars['Float']['output']>;
  sprinklerHeadTemperatureRating?: Maybe<Scalars['String']['output']>;
  sprinklerLeakDetectionSystem?: Maybe<Scalars['Boolean']['output']>;
  sprinklerPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stockThroughputExcessInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputExcessInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  stockThroughputFactor?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  storageArrangementDescription?: Maybe<Scalars['String']['output']>;
  streamMembership?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  structuralDescription?: Maybe<Scalars['String']['output']>;
  structuralSystemUpgraded?: Maybe<Scalars['String']['output']>;
  structuralUpgradeType?: Maybe<Scalars['String']['output']>;
  suitabilityOfFireProtectionMeasures?: Maybe<Scalars['String']['output']>;
  surfaceRoughnessWind?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  torsion?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  totalInsuredValueDisplay?: Maybe<Scalars['Float']['output']>;
  treeExposure?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  /**  Profile of the user that last made changes to the property.  */
  updatedBy?: Maybe<UserProfile>;
  valuationCurrency?: Maybe<Scalars['String']['output']>;
  valueInspectionProgram?: Maybe<Scalars['String']['output']>;
  verticalIrregularity?: Maybe<Scalars['String']['output']>;
  wallCladdingSystem?: Maybe<Scalars['String']['output']>;
  waterHeaterBracing?: Maybe<Scalars['String']['output']>;
  waterSupplyFlowRate?: Maybe<Scalars['String']['output']>;
  waterSupplyTime?: Maybe<Scalars['Float']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireZone?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windMissiles?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
  windowProtection?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  yearLastUpgraded?: Maybe<Scalars['Int']['output']>;
  yearsInPortfolio?: Maybe<Scalars['Int']['output']>;
};

export type PropertyAssessmentDetail = {
  __typename?: 'PropertyAssessmentDetail';
  /**  Attribute to which the detail applies  */
  attribute: RiskAttribute;
  /**  Overall metric for the attribute  */
  overallMetric: PropertyAssessmentMetric;
  /**  Metrics broken down by grade category  */
  summaryMetrics?: Maybe<Array<PropertyAssessmentMetric>>;
};

export type PropertyAssessmentMetric = {
  __typename?: 'PropertyAssessmentMetric';
  /**  Number of assessments in the metric grade's category  */
  assessmentCnt: Scalars['Int']['output'];
  /**  Grade for the metric  */
  grade: Grade;
  /**  Percent of assessments in the metric grade's category  */
  pctOfAssessments: Scalars['Float']['output'];
};

export type PropertyAttributeMetadataInput = {
  snapshot?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type PropertyAttributeScore = {
  __typename?: 'PropertyAttributeScore';
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  potential?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreFlag?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Any']['output']>;
};

export type PropertyChanges = {
  __typename?: 'PropertyChanges';
  changes?: Maybe<Array<MappingRuleAppliedChange>>;
  propertyArchipelagoID: Scalars['String']['output'];
};

export type PropertyCluster = PropertyMarker & {
  __typename?: 'PropertyCluster';
  coordinates: Coordinates;
  /**  Next zoom level to de-cluster the marker and render its children  */
  expansionZoom?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  lossCountTotal: Scalars['Int']['output'];
  /** Cluster fields calculated from current subset of properties */
  propertyCount: Scalars['Int']['output'];
  totalGrossLoss: Scalars['Float']['output'];
  totalInsuredValue: Scalars['Float']['output'];
  totalInsuredValueDisplay: Scalars['Float']['output'];
};

export type PropertyCount = {
  __typename?: 'PropertyCount';
  failed: Scalars['Int']['output'];
  invalid: Scalars['Int']['output'];
  processed: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PropertyData = {
  __typename?: 'PropertyData';
  accessoryStructures?: Maybe<Scalars['String']['output']>;
  acquiredOrBuilt?: Maybe<Scalars['String']['output']>;
  acquisitionOrOccupancyDate?: Maybe<Scalars['Date']['output']>;
  airEqBrickVeneer?: Maybe<Scalars['String']['output']>;
  airEqBuildingCondition?: Maybe<Scalars['String']['output']>;
  airEqBuildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  airEqBuildingShape?: Maybe<Scalars['String']['output']>;
  airEqChimney?: Maybe<Scalars['String']['output']>;
  airEqColdFormedTub?: Maybe<Scalars['String']['output']>;
  airEqColumnBasementType?: Maybe<Scalars['String']['output']>;
  airEqEquipment?: Maybe<Scalars['String']['output']>;
  airEqFoundationConnection?: Maybe<Scalars['String']['output']>;
  airEqFoundationType?: Maybe<Scalars['String']['output']>;
  airEqInternalPartitions?: Maybe<Scalars['String']['output']>;
  airEqIsFireSprinklerAvailable?: Maybe<Scalars['String']['output']>;
  airEqIsValueType?: Maybe<Scalars['String']['output']>;
  airEqLatticeType?: Maybe<Scalars['String']['output']>;
  airEqMultiStoryHallType?: Maybe<Scalars['String']['output']>;
  airEqOrnamentation?: Maybe<Scalars['String']['output']>;
  airEqPounding?: Maybe<Scalars['String']['output']>;
  airEqRedundancy?: Maybe<Scalars['String']['output']>;
  airEqRetrofitMeasures?: Maybe<Scalars['String']['output']>;
  airEqShortColumn?: Maybe<Scalars['String']['output']>;
  airEqSoftStory?: Maybe<Scalars['String']['output']>;
  airEqSpecialEQResistiveSystems?: Maybe<Scalars['String']['output']>;
  airEqStructuralIrregularity?: Maybe<Scalars['String']['output']>;
  airEqTallOneStory?: Maybe<Scalars['String']['output']>;
  airEqTank?: Maybe<Scalars['String']['output']>;
  airEqTorsion?: Maybe<Scalars['String']['output']>;
  airEqTransitionInSRCType?: Maybe<Scalars['String']['output']>;
  airEqWallSiding?: Maybe<Scalars['String']['output']>;
  airEqWallType?: Maybe<Scalars['String']['output']>;
  airEqWaterHeater?: Maybe<Scalars['String']['output']>;
  airEqWeldingDetail?: Maybe<Scalars['String']['output']>;
  airModifiers?: Maybe<Scalars['String']['output']>;
  airWindAdjacentBuildingHeight?: Maybe<Scalars['String']['output']>;
  airWindAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevation?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindBasementFinishType?: Maybe<Scalars['String']['output']>;
  airWindBasementLevelCount?: Maybe<Scalars['String']['output']>;
  airWindCertifiedStructuresIbhs?: Maybe<Scalars['String']['output']>;
  airWindContentVulnerability?: Maybe<Scalars['String']['output']>;
  airWindCustomElevation?: Maybe<Scalars['String']['output']>;
  airWindCustomElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindCustomFloodStandard?: Maybe<Scalars['Float']['output']>;
  airWindCustomFloodZone?: Maybe<Scalars['String']['output']>;
  airWindExteriorDoors?: Maybe<Scalars['String']['output']>;
  airWindFirmCompliance?: Maybe<Scalars['String']['output']>;
  airWindFirstFloorHeight?: Maybe<Scalars['Float']['output']>;
  airWindFirstFloorHeightUnit?: Maybe<Scalars['String']['output']>;
  airWindFloorOfInterest?: Maybe<Scalars['String']['output']>;
  airWindGlassPercentage?: Maybe<Scalars['String']['output']>;
  airWindGlassType?: Maybe<Scalars['String']['output']>;
  airWindLargeMissiles?: Maybe<Scalars['String']['output']>;
  airWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  airWindRoofAttachedStructures?: Maybe<Scalars['String']['output']>;
  airWindRoofCovering?: Maybe<Scalars['String']['output']>;
  airWindRoofCoveringAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofDeck?: Maybe<Scalars['String']['output']>;
  airWindRoofDeckAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  airWindRoofPitch?: Maybe<Scalars['String']['output']>;
  airWindRoofYearBuilt?: Maybe<Scalars['Int']['output']>;
  airWindSealOfApproval?: Maybe<Scalars['String']['output']>;
  airWindServiceEquipmentProtection?: Maybe<Scalars['String']['output']>;
  airWindSmallDebris?: Maybe<Scalars['String']['output']>;
  airWindTerrainRoughness?: Maybe<Scalars['String']['output']>;
  airWindTreeExposure?: Maybe<Scalars['String']['output']>;
  airWindWallAttachedStructure?: Maybe<Scalars['String']['output']>;
  airWindWindowProtection?: Maybe<Scalars['String']['output']>;
  annualBaseRent?: Maybe<Scalars['Float']['output']>;
  annualBaseRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRent?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualGrossProfit?: Maybe<Scalars['Float']['output']>;
  annualGrossProfitDisplay?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenue?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenueDisplay?: Maybe<Scalars['Float']['output']>;
  annualServiceCharge?: Maybe<Scalars['Float']['output']>;
  annualServiceChargeDisplay?: Maybe<Scalars['Float']['output']>;
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  backupGenerator?: Maybe<Scalars['String']['output']>;
  backupGeneratorDieselStorageCapacity?: Maybe<Scalars['String']['output']>;
  backupGeneratorLocation?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  basementFinishType?: Maybe<Scalars['String']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingDescription?: Maybe<Scalars['String']['output']>;
  buildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  buildingFoundationType?: Maybe<Scalars['String']['output']>;
  buildingRedundancy?: Maybe<Scalars['String']['output']>;
  buildingReplacementCost?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPartner?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifference?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifferenceRange?: Maybe<Scalars['String']['output']>;
  buildingReplacementCostValueDifference?: Maybe<Scalars['Float']['output']>;
  buildingSprinklerType?: Maybe<Scalars['String']['output']>;
  buildingSprinklered?: Maybe<Scalars['String']['output']>;
  buildingValue?: Maybe<Scalars['Float']['output']>;
  buildingValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessContinuityPlans?: Maybe<Scalars['String']['output']>;
  businessIncomeValue?: Maybe<Scalars['Float']['output']>;
  businessIncomeValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCost?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCostDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionPreparedness?: Maybe<Scalars['String']['output']>;
  businessInterruptionRedundancy?: Maybe<Scalars['String']['output']>;
  chimneys?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commodityHazardClass?: Maybe<Scalars['String']['output']>;
  constructionDescription?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  constructionTypeAIR?: Maybe<Scalars['String']['output']>;
  constructionTypeATC?: Maybe<Scalars['String']['output']>;
  constructionTypeArchipelago?: Maybe<Scalars['String']['output']>;
  constructionTypeCluster?: Maybe<Scalars['String']['output']>;
  constructionTypeISO?: Maybe<Scalars['String']['output']>;
  constructionTypeRMS?: Maybe<Scalars['String']['output']>;
  consumerAttributes?: Maybe<ConsumerCustomAttributes>;
  contentsReplacementCost?: Maybe<Scalars['Float']['output']>;
  contentsReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  contentsVulnerabilityFlood?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityVerticalDistributionOfContents?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityWind?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionComments?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionFlag?: Maybe<Scalars['Boolean']['output']>;
  controlOfCombustiblesProgram?: Maybe<Scalars['String']['output']>;
  convectiveStormZone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  crippleWalls?: Maybe<Scalars['String']['output']>;
  currencyPair?: Maybe<Scalars['String']['output']>;
  customerProvidedGeocode?: Maybe<Scalars['Boolean']['output']>;
  dependencyComments?: Maybe<Scalars['String']['output']>;
  dependencyCoveragePercentage?: Maybe<Scalars['Float']['output']>;
  dependencyFlag?: Maybe<Scalars['Boolean']['output']>;
  dependencyValue?: Maybe<Scalars['Float']['output']>;
  dependencyValueDisplay?: Maybe<Scalars['Float']['output']>;
  dispositionDate?: Maybe<Scalars['Date']['output']>;
  distanceToCoast?: Maybe<Scalars['Float']['output']>;
  distanceToNearestBuilding?: Maybe<Scalars['Float']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  doorAndFrameType?: Maybe<Scalars['String']['output']>;
  electronicsValue?: Maybe<Scalars['Float']['output']>;
  electronicsValueDisplay?: Maybe<Scalars['Float']['output']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: Maybe<Scalars['String']['output']>;
  emergencyFloodProtectionMeasures?: Maybe<Scalars['String']['output']>;
  /**  This attribute is automatically computed whenever provenance is updated. Should be true as long as current property has attribute provenance with a sourced document.  */
  enriched?: Maybe<Scalars['Boolean']['output']>;
  eqSpecialSystems?: Maybe<Scalars['String']['output']>;
  equipmentEarthquakeBracing?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  exchangeRateDate?: Maybe<Scalars['Date']['output']>;
  exclusions?: Maybe<Scalars['String']['output']>;
  extraExpenseValue?: Maybe<Scalars['Float']['output']>;
  extraExpenseValueDisplay?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValue?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValueDisplay?: Maybe<Scalars['Float']['output']>;
  fireDetectionSystems?: Maybe<Scalars['String']['output']>;
  fireEstimatedMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProbableMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProtectionDescription?: Maybe<Scalars['String']['output']>;
  fireProtectionInspectionProgram?: Maybe<Scalars['String']['output']>;
  firePumpBackupPeriod?: Maybe<Scalars['Float']['output']>;
  firePumpChurnRate?: Maybe<Scalars['Float']['output']>;
  firePumpExcessCapacity?: Maybe<Scalars['String']['output']>;
  firePumpFlowRate?: Maybe<Scalars['String']['output']>;
  firePumpPowerSupply?: Maybe<Scalars['String']['output']>;
  firePumpTestResults?: Maybe<Scalars['String']['output']>;
  firstFloorElevation?: Maybe<Scalars['Float']['output']>;
  firstFloorHeight?: Maybe<Scalars['Float']['output']>;
  flammableLiquidCapacity?: Maybe<Scalars['Float']['output']>;
  flammableLiquidDescription?: Maybe<Scalars['String']['output']>;
  flammableLiquidGasLineManagement?: Maybe<Scalars['String']['output']>;
  flammableLiquidProgram?: Maybe<Scalars['String']['output']>;
  flammableLiquidStorageLocation?: Maybe<Scalars['String']['output']>;
  flammableLiquidsOnsite?: Maybe<Scalars['String']['output']>;
  floodMissiles?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  floorOfInterest?: Maybe<Scalars['Int']['output']>;
  floorSystem?: Maybe<Scalars['String']['output']>;
  foundationToFrameConnection?: Maybe<Scalars['String']['output']>;
  generatorTestingProgram?: Maybe<Scalars['String']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  glassPercentage?: Maybe<Scalars['String']['output']>;
  glassType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  hasSeismicInspectionReport?: Maybe<Scalars['Boolean']['output']>;
  hazardSummary?: Maybe<Scalars['Boolean']['output']>;
  heightOfRackStorage?: Maybe<Scalars['Float']['output']>;
  highHazardAreaSprinklerType?: Maybe<Scalars['String']['output']>;
  hotWorkProgram?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsResidual?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsStatic?: Maybe<Scalars['String']['output']>;
  improvementsValue?: Maybe<Scalars['Float']['output']>;
  improvementsValueDisplay?: Maybe<Scalars['Float']['output']>;
  inRackSprinklerType?: Maybe<Scalars['String']['output']>;
  interiorPartitions?: Maybe<Scalars['String']['output']>;
  inventoryValue?: Maybe<Scalars['Float']['output']>;
  inventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  lastEngineeringVisitDate?: Maybe<Scalars['Date']['output']>;
  lastValuationDate?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  leasedOrOwned?: Maybe<Scalars['String']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  lossAttributes?: Maybe<LossAttributes>;
  lossEngineeringReportPresent?: Maybe<Scalars['String']['output']>;
  machineryValue?: Maybe<Scalars['Float']['output']>;
  machineryValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValue?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValue?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscContentsValue?: Maybe<Scalars['Float']['output']>;
  miscContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  modelingAvailable?: Maybe<Scalars['Boolean']['output']>;
  multiTenant?: Maybe<Scalars['Boolean']['output']>;
  naturalGasAutomaticShutoff?: Maybe<Scalars['String']['output']>;
  naturalGasPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  numberOfBuildings?: Maybe<Scalars['Int']['output']>;
  numberOfFireHazardAreas?: Maybe<Scalars['Int']['output']>;
  numberOfHighPriorityRecommendations?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesAboveGround?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesBelowGround?: Maybe<Scalars['Int']['output']>;
  numberOfUnits?: Maybe<Scalars['Int']['output']>;
  occupancyDescription?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  occupancyTypeATC?: Maybe<Scalars['String']['output']>;
  occupancyTypeNAICS?: Maybe<Scalars['String']['output']>;
  occupancyTypeSIC?: Maybe<Scalars['String']['output']>;
  organizationLevelFour?: Maybe<Scalars['String']['output']>;
  organizationLevelOne?: Maybe<Scalars['String']['output']>;
  organizationLevelThree?: Maybe<Scalars['String']['output']>;
  organizationLevelTwo?: Maybe<Scalars['String']['output']>;
  originalAddress?: Maybe<Scalars['String']['output']>;
  ornamentation?: Maybe<Scalars['String']['output']>;
  otherContentsValue?: Maybe<Scalars['Float']['output']>;
  otherContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  ownerAttributes?: Maybe<OwnerCustomAttributes>;
  payrollValue?: Maybe<Scalars['Float']['output']>;
  payrollValueDisplay?: Maybe<Scalars['Float']['output']>;
  percentageSprinklered?: Maybe<Scalars['Float']['output']>;
  permanentFloodMeasuresPresent?: Maybe<Scalars['String']['output']>;
  portableFireExtinguisherProgram?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryTenantNAICS?: Maybe<Scalars['String']['output']>;
  propertyManager?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<Scalars['String']['output']>;
  quakeScenarioEstimatedLoss?: Maybe<Scalars['Float']['output']>;
  quakeScenarioUpperLoss?: Maybe<Scalars['Float']['output']>;
  rackStoragePresent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  remoteMonitoringOfSprinklerSystem?: Maybe<Scalars['Boolean']['output']>;
  rentAndServiceIndemnityPeriod?: Maybe<Scalars['Int']['output']>;
  replacementCostMethodology?: Maybe<Scalars['String']['output']>;
  replacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  replacementCostPerFloorAreaPartnerDifference?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootage?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootageDisplay?: Maybe<Scalars['Float']['output']>;
  residentialGarage?: Maybe<Scalars['String']['output']>;
  rmsEqAppendagesAndOrnamentation?: Maybe<Scalars['String']['output']>;
  rmsEqBaseIsolation?: Maybe<Scalars['String']['output']>;
  rmsEqConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsEqContentsRateGradeEQ?: Maybe<Scalars['String']['output']>;
  rmsEqCrippleWalls?: Maybe<Scalars['String']['output']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: Maybe<Scalars['String']['output']>;
  rmsEqEngineeredFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqEnvelopeOpening?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentEQBracing?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentSupportMaintenance?: Maybe<Scalars['String']['output']>;
  rmsEqExteriorWallsCladding?: Maybe<Scalars['String']['output']>;
  rmsEqFrameBoltedtoFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqPounding?: Maybe<Scalars['String']['output']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqRedundancy?: Maybe<Scalars['String']['output']>;
  rmsEqShapeConfiguration?: Maybe<Scalars['String']['output']>;
  rmsEqShortColumnCondition?: Maybe<Scalars['String']['output']>;
  rmsEqSoftStory?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerLeakageSusceptibility?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerType?: Maybe<Scalars['String']['output']>;
  rmsEqStructuralUpgradeNonURM?: Maybe<Scalars['String']['output']>;
  rmsEqTank?: Maybe<Scalars['String']['output']>;
  rmsEqTorsion?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryPartition?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: Maybe<Scalars['String']['output']>;
  rmsModifiers?: Maybe<Scalars['String']['output']>;
  rmsOtherBIPreparedness?: Maybe<Scalars['String']['output']>;
  rmsOtherBIRedundancy?: Maybe<Scalars['String']['output']>;
  rmsOtherPercentageContentsBelowGrade?: Maybe<Scalars['Float']['output']>;
  rmsRoofSheathingAttachment?: Maybe<Scalars['String']['output']>;
  rmsWindBIDependency?: Maybe<Scalars['String']['output']>;
  rmsWindBasementProtection?: Maybe<Scalars['String']['output']>;
  rmsWindCauseOfLossFlag?: Maybe<Scalars['String']['output']>;
  rmsWindCladding?: Maybe<Scalars['String']['output']>;
  rmsWindCommercialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsWindContentsRateGradeWind?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToFlood?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToWind?: Maybe<Scalars['String']['output']>;
  rmsWindCopingAndFlashingQuality?: Maybe<Scalars['String']['output']>;
  rmsWindFinishedFloorHeightAboveGround?: Maybe<Scalars['Float']['output']>;
  rmsWindFinishedFloorHeightMatchLevel?: Maybe<Scalars['Float']['output']>;
  rmsWindFirstFloorElevation?: Maybe<Scalars['Float']['output']>;
  rmsWindFloodMissiles?: Maybe<Scalars['String']['output']>;
  rmsWindFloodProtection?: Maybe<Scalars['String']['output']>;
  rmsWindFloorType?: Maybe<Scalars['String']['output']>;
  rmsWindFrameFoundationConnection?: Maybe<Scalars['String']['output']>;
  rmsWindGroundLevelEquipment?: Maybe<Scalars['String']['output']>;
  rmsWindOpeningProtection?: Maybe<Scalars['String']['output']>;
  rmsWindResidentialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindResistanceDoors?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAge?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  rmsWindRoofEquipmentWindBracing?: Maybe<Scalars['String']['output']>;
  rmsWindRoofFramingType?: Maybe<Scalars['String']['output']>;
  rmsWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  rmsWindRoofMaintenance?: Maybe<Scalars['String']['output']>;
  rmsWindRoofParapetsChimneys?: Maybe<Scalars['String']['output']>;
  rmsWindRoofSystemCovering?: Maybe<Scalars['String']['output']>;
  rmsWindVerticalExposureDistribution?: Maybe<Scalars['String']['output']>;
  rmsWindWindMissiles?: Maybe<Scalars['String']['output']>;
  roofChimneysAnchorage?: Maybe<Scalars['String']['output']>;
  roofCopingAndFlashing?: Maybe<Scalars['String']['output']>;
  roofDeckAnchorage?: Maybe<Scalars['String']['output']>;
  roofDeckType?: Maybe<Scalars['String']['output']>;
  roofDescription?: Maybe<Scalars['String']['output']>;
  roofDrainageType?: Maybe<Scalars['String']['output']>;
  roofEquipmentAnchorage?: Maybe<Scalars['String']['output']>;
  roofEstimatedReplacementYear?: Maybe<Scalars['Int']['output']>;
  roofGeometry?: Maybe<Scalars['String']['output']>;
  roofHailMitigation?: Maybe<Scalars['String']['output']>;
  roofImage?: Maybe<Scalars['String']['output']>;
  roofInspectionProgram?: Maybe<Scalars['String']['output']>;
  roofInstallYear?: Maybe<Scalars['Int']['output']>;
  roofParapets?: Maybe<Scalars['String']['output']>;
  roofScreensSignsAnchorageandBracing?: Maybe<Scalars['String']['output']>;
  roofSolarPanelCoverage?: Maybe<Scalars['String']['output']>;
  roofSolarPanelDescription?: Maybe<Scalars['String']['output']>;
  roofSolarPanelOwnership?: Maybe<Scalars['Float']['output']>;
  roofStructures?: Maybe<Scalars['String']['output']>;
  roofSystem?: Maybe<Scalars['String']['output']>;
  roofSystemAnchorage?: Maybe<Scalars['String']['output']>;
  roofingMaterialAnchorage?: Maybe<Scalars['String']['output']>;
  rooftopSolarPanels?: Maybe<Scalars['String']['output']>;
  rooftopWaterTanks?: Maybe<Scalars['String']['output']>;
  securityDescription?: Maybe<Scalars['String']['output']>;
  seismicDesignQuality?: Maybe<Scalars['String']['output']>;
  seismicDesignRValue?: Maybe<Scalars['Float']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicInspectionDate?: Maybe<Scalars['Date']['output']>;
  seismicStatus?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  shortColumnConcrete?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  smokingControlsProgram?: Maybe<Scalars['String']['output']>;
  softStory?: Maybe<Scalars['String']['output']>;
  sovSortOrder?: Maybe<Scalars['Int']['output']>;
  specificOccupancy?: Maybe<Scalars['String']['output']>;
  sprinklerHeadSize?: Maybe<Scalars['Float']['output']>;
  sprinklerHeadTemperatureRating?: Maybe<Scalars['String']['output']>;
  sprinklerLeakDetectionSystem?: Maybe<Scalars['Boolean']['output']>;
  sprinklerPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stockThroughputExcessInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputExcessInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  stockThroughputFactor?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  storageArrangementDescription?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  structuralDescription?: Maybe<Scalars['String']['output']>;
  structuralSystemUpgraded?: Maybe<Scalars['String']['output']>;
  structuralUpgradeType?: Maybe<Scalars['String']['output']>;
  suitabilityOfFireProtectionMeasures?: Maybe<Scalars['String']['output']>;
  surfaceRoughnessWind?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  torsion?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  totalInsuredValueDisplay?: Maybe<Scalars['Float']['output']>;
  treeExposure?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  valuationCurrency?: Maybe<Scalars['String']['output']>;
  valueInspectionProgram?: Maybe<Scalars['String']['output']>;
  verticalIrregularity?: Maybe<Scalars['String']['output']>;
  wallCladdingSystem?: Maybe<Scalars['String']['output']>;
  waterHeaterBracing?: Maybe<Scalars['String']['output']>;
  waterSupplyFlowRate?: Maybe<Scalars['String']['output']>;
  waterSupplyTime?: Maybe<Scalars['Float']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireZone?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windMissiles?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
  windowProtection?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  yearLastUpgraded?: Maybe<Scalars['Int']['output']>;
  yearsInPortfolio?: Maybe<Scalars['Int']['output']>;
};

export type PropertyDataInput = {
  accessoryStructures?: InputMaybe<Scalars['String']['input']>;
  acquiredOrBuilt?: InputMaybe<Scalars['String']['input']>;
  acquisitionOrOccupancyDate?: InputMaybe<Scalars['Date']['input']>;
  airEqBrickVeneer?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingCondition?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingShape?: InputMaybe<Scalars['String']['input']>;
  airEqChimney?: InputMaybe<Scalars['String']['input']>;
  airEqColdFormedTub?: InputMaybe<Scalars['String']['input']>;
  airEqColumnBasementType?: InputMaybe<Scalars['String']['input']>;
  airEqEquipment?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationType?: InputMaybe<Scalars['String']['input']>;
  airEqInternalPartitions?: InputMaybe<Scalars['String']['input']>;
  airEqIsFireSprinklerAvailable?: InputMaybe<Scalars['String']['input']>;
  airEqIsValueType?: InputMaybe<Scalars['String']['input']>;
  airEqLatticeType?: InputMaybe<Scalars['String']['input']>;
  airEqMultiStoryHallType?: InputMaybe<Scalars['String']['input']>;
  airEqOrnamentation?: InputMaybe<Scalars['String']['input']>;
  airEqPounding?: InputMaybe<Scalars['String']['input']>;
  airEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  airEqRetrofitMeasures?: InputMaybe<Scalars['String']['input']>;
  airEqShortColumn?: InputMaybe<Scalars['String']['input']>;
  airEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  airEqSpecialEQResistiveSystems?: InputMaybe<Scalars['String']['input']>;
  airEqStructuralIrregularity?: InputMaybe<Scalars['String']['input']>;
  airEqTallOneStory?: InputMaybe<Scalars['String']['input']>;
  airEqTank?: InputMaybe<Scalars['String']['input']>;
  airEqTorsion?: InputMaybe<Scalars['String']['input']>;
  airEqTransitionInSRCType?: InputMaybe<Scalars['String']['input']>;
  airEqWallSiding?: InputMaybe<Scalars['String']['input']>;
  airEqWallType?: InputMaybe<Scalars['String']['input']>;
  airEqWaterHeater?: InputMaybe<Scalars['String']['input']>;
  airEqWeldingDetail?: InputMaybe<Scalars['String']['input']>;
  airModifiers?: InputMaybe<Scalars['String']['input']>;
  airWindAdjacentBuildingHeight?: InputMaybe<Scalars['String']['input']>;
  airWindAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevation?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindBasementFinishType?: InputMaybe<Scalars['String']['input']>;
  airWindBasementLevelCount?: InputMaybe<Scalars['String']['input']>;
  airWindCertifiedStructuresIbhs?: InputMaybe<Scalars['String']['input']>;
  airWindContentVulnerability?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevation?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindCustomFloodStandard?: InputMaybe<Scalars['Float']['input']>;
  airWindCustomFloodZone?: InputMaybe<Scalars['String']['input']>;
  airWindExteriorDoors?: InputMaybe<Scalars['String']['input']>;
  airWindFirmCompliance?: InputMaybe<Scalars['String']['input']>;
  airWindFirstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  airWindFirstFloorHeightUnit?: InputMaybe<Scalars['String']['input']>;
  airWindFloorOfInterest?: InputMaybe<Scalars['String']['input']>;
  airWindGlassPercentage?: InputMaybe<Scalars['String']['input']>;
  airWindGlassType?: InputMaybe<Scalars['String']['input']>;
  airWindLargeMissiles?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAttachedStructures?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCovering?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCoveringAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeck?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeckAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  airWindRoofPitch?: InputMaybe<Scalars['String']['input']>;
  airWindRoofYearBuilt?: InputMaybe<Scalars['Int']['input']>;
  airWindSealOfApproval?: InputMaybe<Scalars['String']['input']>;
  airWindServiceEquipmentProtection?: InputMaybe<Scalars['String']['input']>;
  airWindSmallDebris?: InputMaybe<Scalars['String']['input']>;
  airWindTerrainRoughness?: InputMaybe<Scalars['String']['input']>;
  airWindTreeExposure?: InputMaybe<Scalars['String']['input']>;
  airWindWallAttachedStructure?: InputMaybe<Scalars['String']['input']>;
  airWindWindowProtection?: InputMaybe<Scalars['String']['input']>;
  annualBaseRent?: InputMaybe<Scalars['Float']['input']>;
  annualEstimatedRent?: InputMaybe<Scalars['Float']['input']>;
  annualGrossProfit?: InputMaybe<Scalars['Float']['input']>;
  annualLossOfRevenue?: InputMaybe<Scalars['Float']['input']>;
  annualServiceCharge?: InputMaybe<Scalars['Float']['input']>;
  archipelagoFloodZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoSeismicZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoWindZone?: InputMaybe<Scalars['String']['input']>;
  backupGenerator?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorDieselStorageCapacity?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorLocation?: InputMaybe<Scalars['String']['input']>;
  baseFloodElevation?: InputMaybe<Scalars['Float']['input']>;
  basementFinishType?: InputMaybe<Scalars['String']['input']>;
  buildingCondition?: InputMaybe<Scalars['String']['input']>;
  buildingDescription?: InputMaybe<Scalars['String']['input']>;
  buildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  buildingFootprintClass?: InputMaybe<Scalars['String']['input']>;
  buildingFoundationType?: InputMaybe<Scalars['String']['input']>;
  buildingRedundancy?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPartner?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifferenceRange?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCostValueDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingSprinklerType?: InputMaybe<Scalars['String']['input']>;
  buildingSprinklered?: InputMaybe<Scalars['String']['input']>;
  buildingValue?: InputMaybe<Scalars['Float']['input']>;
  businessContinuityPlans?: InputMaybe<Scalars['String']['input']>;
  businessIncomeValue?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionCost?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionPreparedness?: InputMaybe<Scalars['String']['input']>;
  businessInterruptionRedundancy?: InputMaybe<Scalars['String']['input']>;
  chimneys?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commodityHazardClass?: InputMaybe<Scalars['String']['input']>;
  constructionDescription?: InputMaybe<Scalars['String']['input']>;
  constructionQuality?: InputMaybe<Scalars['String']['input']>;
  constructionType?: InputMaybe<Scalars['String']['input']>;
  constructionTypeAIR?: InputMaybe<Scalars['String']['input']>;
  constructionTypeATC?: InputMaybe<Scalars['String']['input']>;
  constructionTypeArchipelago?: InputMaybe<Scalars['String']['input']>;
  constructionTypeCluster?: InputMaybe<Scalars['String']['input']>;
  constructionTypeISO?: InputMaybe<Scalars['String']['input']>;
  constructionTypeRMS?: InputMaybe<Scalars['String']['input']>;
  contentsReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  contentsVulnerabilityFlood?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityVerticalDistributionOfContents?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityWind?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionComments?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionFlag?: InputMaybe<Scalars['Boolean']['input']>;
  controlOfCombustiblesProgram?: InputMaybe<Scalars['String']['input']>;
  convectiveStormZone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  crippleWalls?: InputMaybe<Scalars['String']['input']>;
  /** Risk Manager/Owner Custom Attributes */
  customAttributes?: InputMaybe<CustomAttributeInput>;
  customerProvidedGeocode?: InputMaybe<Scalars['Boolean']['input']>;
  dependencyComments?: InputMaybe<Scalars['String']['input']>;
  dependencyCoveragePercentage?: InputMaybe<Scalars['Float']['input']>;
  dependencyFlag?: InputMaybe<Scalars['Boolean']['input']>;
  dispositionDate?: InputMaybe<Scalars['Date']['input']>;
  distanceToCoast?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestBuilding?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestFault?: InputMaybe<Scalars['Float']['input']>;
  doorAndFrameType?: InputMaybe<Scalars['String']['input']>;
  effectiveFrom?: InputMaybe<Scalars['Time']['input']>;
  electronicsValue?: InputMaybe<Scalars['Float']['input']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: InputMaybe<Scalars['String']['input']>;
  emergencyFloodProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  enriched?: InputMaybe<Scalars['Boolean']['input']>;
  eqSpecialSystems?: InputMaybe<Scalars['String']['input']>;
  equipmentEarthquakeBracing?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']['input']>;
  exclusions?: InputMaybe<Scalars['String']['input']>;
  extraExpenseValue?: InputMaybe<Scalars['Float']['input']>;
  fineArtJewelryValue?: InputMaybe<Scalars['Float']['input']>;
  fireDetectionSystems?: InputMaybe<Scalars['String']['input']>;
  fireEstimatedMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProbableMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProtectionDescription?: InputMaybe<Scalars['String']['input']>;
  fireProtectionInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  firePumpBackupPeriod?: InputMaybe<Scalars['Float']['input']>;
  firePumpChurnRate?: InputMaybe<Scalars['Float']['input']>;
  firePumpExcessCapacity?: InputMaybe<Scalars['String']['input']>;
  firePumpFlowRate?: InputMaybe<Scalars['String']['input']>;
  firePumpPowerSupply?: InputMaybe<Scalars['String']['input']>;
  firePumpTestResults?: InputMaybe<Scalars['String']['input']>;
  firstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  firstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidCapacity?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidDescription?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidGasLineManagement?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidProgram?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidStorageLocation?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidsOnsite?: InputMaybe<Scalars['String']['input']>;
  floodMissiles?: InputMaybe<Scalars['String']['input']>;
  floodZone?: InputMaybe<Scalars['String']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  floorOfInterest?: InputMaybe<Scalars['Int']['input']>;
  floorSystem?: InputMaybe<Scalars['String']['input']>;
  foundationToFrameConnection?: InputMaybe<Scalars['String']['input']>;
  generatorTestingProgram?: InputMaybe<Scalars['String']['input']>;
  geoCodeType?: InputMaybe<Scalars['String']['input']>;
  glassPercentage?: InputMaybe<Scalars['String']['input']>;
  glassType?: InputMaybe<Scalars['String']['input']>;
  hailHazardClass?: InputMaybe<Scalars['String']['input']>;
  hasSeismicInspectionReport?: InputMaybe<Scalars['Boolean']['input']>;
  hazardSummary?: InputMaybe<Scalars['Boolean']['input']>;
  heightOfRackStorage?: InputMaybe<Scalars['Float']['input']>;
  highHazardAreaSprinklerType?: InputMaybe<Scalars['String']['input']>;
  hotWorkProgram?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsResidual?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsStatic?: InputMaybe<Scalars['String']['input']>;
  improvementsValue?: InputMaybe<Scalars['Float']['input']>;
  inRackSprinklerType?: InputMaybe<Scalars['String']['input']>;
  interiorPartitions?: InputMaybe<Scalars['String']['input']>;
  inventoryValue?: InputMaybe<Scalars['Float']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  landslideSusceptibility?: InputMaybe<Scalars['String']['input']>;
  lastEngineeringVisitDate?: InputMaybe<Scalars['Date']['input']>;
  lastValuationDate?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leasedOrOwned?: InputMaybe<Scalars['String']['input']>;
  lightningHazardClass?: InputMaybe<Scalars['String']['input']>;
  liquefactionSusceptibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  lossEngineeringReportPresent?: InputMaybe<Scalars['String']['input']>;
  machineryValue?: InputMaybe<Scalars['Float']['input']>;
  miscBuildingReplacementCostValue?: InputMaybe<Scalars['Float']['input']>;
  miscBusinessInterruptionValue?: InputMaybe<Scalars['Float']['input']>;
  miscContentsValue?: InputMaybe<Scalars['Float']['input']>;
  modelingAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  multiTenant?: InputMaybe<Scalars['Boolean']['input']>;
  naturalGasAutomaticShutoff?: InputMaybe<Scalars['String']['input']>;
  naturalGasPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  numberOfBuildings?: InputMaybe<Scalars['Int']['input']>;
  numberOfFireHazardAreas?: InputMaybe<Scalars['Int']['input']>;
  numberOfHighPriorityRecommendations?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesAboveGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesBelowGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfUnits?: InputMaybe<Scalars['Int']['input']>;
  occupancyDescription?: InputMaybe<Scalars['String']['input']>;
  occupancyType?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeATC?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeNAICS?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeSIC?: InputMaybe<Scalars['String']['input']>;
  organizationLevelFour?: InputMaybe<Scalars['String']['input']>;
  organizationLevelOne?: InputMaybe<Scalars['String']['input']>;
  organizationLevelThree?: InputMaybe<Scalars['String']['input']>;
  organizationLevelTwo?: InputMaybe<Scalars['String']['input']>;
  originalAddress?: InputMaybe<Scalars['String']['input']>;
  ornamentation?: InputMaybe<Scalars['String']['input']>;
  otherContentsValue?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  payrollValue?: InputMaybe<Scalars['Float']['input']>;
  percentageSprinklered?: InputMaybe<Scalars['Float']['input']>;
  permanentFloodMeasuresPresent?: InputMaybe<Scalars['String']['input']>;
  portableFireExtinguisherProgram?: InputMaybe<Scalars['String']['input']>;
  portfolio?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryTenantNAICS?: InputMaybe<Scalars['String']['input']>;
  propertyManager?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  propertyStatus?: InputMaybe<Scalars['String']['input']>;
  quakeScenarioEstimatedLoss?: InputMaybe<Scalars['Float']['input']>;
  quakeScenarioUpperLoss?: InputMaybe<Scalars['Float']['input']>;
  rackStoragePresent?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  remoteMonitoringOfSprinklerSystem?: InputMaybe<Scalars['Boolean']['input']>;
  rentAndServiceIndemnityPeriod?: InputMaybe<Scalars['Int']['input']>;
  replacementCostMethodology?: InputMaybe<Scalars['String']['input']>;
  replacementCostPerFloorAreaPartner?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerFloorAreaPartnerDifference?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerSquareFootage?: InputMaybe<Scalars['Float']['input']>;
  residentialGarage?: InputMaybe<Scalars['String']['input']>;
  rmsEqAppendagesAndOrnamentation?: InputMaybe<Scalars['String']['input']>;
  rmsEqBaseIsolation?: InputMaybe<Scalars['String']['input']>;
  rmsEqConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsEqContentsRateGradeEQ?: InputMaybe<Scalars['String']['input']>;
  rmsEqCrippleWalls?: InputMaybe<Scalars['String']['input']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: InputMaybe<Scalars['String']['input']>;
  rmsEqEngineeredFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqEnvelopeOpening?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentEQBracing?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentSupportMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsEqExteriorWallsCladding?: InputMaybe<Scalars['String']['input']>;
  rmsEqFrameBoltedtoFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqPounding?: InputMaybe<Scalars['String']['input']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsEqShapeConfiguration?: InputMaybe<Scalars['String']['input']>;
  rmsEqShortColumnCondition?: InputMaybe<Scalars['String']['input']>;
  rmsEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerLeakageSusceptibility?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerType?: InputMaybe<Scalars['String']['input']>;
  rmsEqStructuralUpgradeNonURM?: InputMaybe<Scalars['String']['input']>;
  rmsEqTank?: InputMaybe<Scalars['String']['input']>;
  rmsEqTorsion?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryPartition?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: InputMaybe<Scalars['String']['input']>;
  rmsModifiers?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIPreparedness?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsOtherPercentageContentsBelowGrade?: InputMaybe<Scalars['Float']['input']>;
  rmsRoofSheathingAttachment?: InputMaybe<Scalars['String']['input']>;
  rmsWindBIDependency?: InputMaybe<Scalars['String']['input']>;
  rmsWindBasementProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindCauseOfLossFlag?: InputMaybe<Scalars['String']['input']>;
  rmsWindCladding?: InputMaybe<Scalars['String']['input']>;
  rmsWindCommercialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsRateGradeWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToFlood?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindCopingAndFlashingQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindFinishedFloorHeightAboveGround?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFinishedFloorHeightMatchLevel?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFirstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFloodMissiles?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloodProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloorType?: InputMaybe<Scalars['String']['input']>;
  rmsWindFrameFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  rmsWindGroundLevelEquipment?: InputMaybe<Scalars['String']['input']>;
  rmsWindOpeningProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindResidentialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindResistanceDoors?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAge?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofEquipmentWindBracing?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofFramingType?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofParapetsChimneys?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofSystemCovering?: InputMaybe<Scalars['String']['input']>;
  rmsWindVerticalExposureDistribution?: InputMaybe<Scalars['String']['input']>;
  rmsWindWindMissiles?: InputMaybe<Scalars['String']['input']>;
  roofChimneysAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofCopingAndFlashing?: InputMaybe<Scalars['String']['input']>;
  roofDeckAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofDeckType?: InputMaybe<Scalars['String']['input']>;
  roofDescription?: InputMaybe<Scalars['String']['input']>;
  roofDrainageType?: InputMaybe<Scalars['String']['input']>;
  roofEquipmentAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofEstimatedReplacementYear?: InputMaybe<Scalars['Int']['input']>;
  roofGeometry?: InputMaybe<Scalars['String']['input']>;
  roofHailMitigation?: InputMaybe<Scalars['String']['input']>;
  roofImage?: InputMaybe<Scalars['String']['input']>;
  roofInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  roofInstallYear?: InputMaybe<Scalars['Int']['input']>;
  roofParapets?: InputMaybe<Scalars['String']['input']>;
  roofScreensSignsAnchorageandBracing?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelCoverage?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelDescription?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelOwnership?: InputMaybe<Scalars['Float']['input']>;
  roofStructures?: InputMaybe<Scalars['String']['input']>;
  roofSystem?: InputMaybe<Scalars['String']['input']>;
  roofSystemAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofingMaterialAnchorage?: InputMaybe<Scalars['String']['input']>;
  rooftopSolarPanels?: InputMaybe<Scalars['String']['input']>;
  rooftopWaterTanks?: InputMaybe<Scalars['String']['input']>;
  securityDescription?: InputMaybe<Scalars['String']['input']>;
  seismicDesignQuality?: InputMaybe<Scalars['String']['input']>;
  seismicDesignRValue?: InputMaybe<Scalars['Float']['input']>;
  seismicHazardClass?: InputMaybe<Scalars['String']['input']>;
  seismicInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  seismicStatus?: InputMaybe<Scalars['String']['input']>;
  seismicZone?: InputMaybe<Scalars['String']['input']>;
  shortColumnConcrete?: InputMaybe<Scalars['String']['input']>;
  siteSoilClassification?: InputMaybe<Scalars['String']['input']>;
  smokingControlsProgram?: InputMaybe<Scalars['String']['input']>;
  softStory?: InputMaybe<Scalars['String']['input']>;
  sovSortOrder?: InputMaybe<Scalars['Int']['input']>;
  specificOccupancy?: InputMaybe<Scalars['String']['input']>;
  sprinklerHeadSize?: InputMaybe<Scalars['Float']['input']>;
  sprinklerHeadTemperatureRating?: InputMaybe<Scalars['String']['input']>;
  sprinklerLeakDetectionSystem?: InputMaybe<Scalars['Boolean']['input']>;
  sprinklerPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stockThroughputFactor?: InputMaybe<Scalars['Float']['input']>;
  storageArrangementDescription?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  structuralDescription?: InputMaybe<Scalars['String']['input']>;
  structuralSystemUpgraded?: InputMaybe<Scalars['String']['input']>;
  structuralUpgradeType?: InputMaybe<Scalars['String']['input']>;
  suitabilityOfFireProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  surfaceRoughnessWind?: InputMaybe<Scalars['String']['input']>;
  tornadoHazardClass?: InputMaybe<Scalars['String']['input']>;
  torsion?: InputMaybe<Scalars['String']['input']>;
  totalInsuredValue?: InputMaybe<Scalars['Float']['input']>;
  treeExposure?: InputMaybe<Scalars['String']['input']>;
  tsunamiHazardClass?: InputMaybe<Scalars['String']['input']>;
  valuationCurrency?: InputMaybe<Scalars['String']['input']>;
  valueInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  verticalIrregularity?: InputMaybe<Scalars['String']['input']>;
  wallCladdingSystem?: InputMaybe<Scalars['String']['input']>;
  waterHeaterBracing?: InputMaybe<Scalars['String']['input']>;
  waterSupplyFlowRate?: InputMaybe<Scalars['String']['input']>;
  waterSupplyTime?: InputMaybe<Scalars['Float']['input']>;
  wildfireHazardClass?: InputMaybe<Scalars['String']['input']>;
  wildfireZone?: InputMaybe<Scalars['String']['input']>;
  windHazardClass?: InputMaybe<Scalars['String']['input']>;
  windMissiles?: InputMaybe<Scalars['String']['input']>;
  windZone?: InputMaybe<Scalars['String']['input']>;
  windowProtection?: InputMaybe<Scalars['String']['input']>;
  windowType?: InputMaybe<Scalars['String']['input']>;
  yearBuilt?: InputMaybe<Scalars['Int']['input']>;
  yearLastUpgraded?: InputMaybe<Scalars['Int']['input']>;
  yearsInPortfolio?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyDerivationInput = {
  linkType: PropertyDerivationType;
  /** Array with location IDs */
  locationIds: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type PropertyDerivationResponse = {
  __typename?: 'PropertyDerivationResponse';
  linkType: PropertyDerivationType;
  sourceArchipelagoID: Scalars['String']['output'];
  sourceClientID?: Maybe<Scalars['String']['output']>;
  targetArchipelagoID: Scalars['String']['output'];
  targetClientID?: Maybe<Scalars['String']['output']>;
};

export enum PropertyDerivationType {
  Bundle = 'bundle',
  Unbundle = 'unbundle'
}

export type PropertyDiscussion = {
  __typename?: 'PropertyDiscussion';
  attributeName?: Maybe<Scalars['String']['output']>;
  attributeRoomID?: Maybe<Scalars['String']['output']>;
  attributeRoomName?: Maybe<Scalars['String']['output']>;
  property: PropertyInfo;
  roomID: Scalars['String']['output'];
  roomName: Scalars['String']['output'];
  snapshotName?: Maybe<Scalars['String']['output']>;
};

export type PropertyDiscussions = {
  __typename?: 'PropertyDiscussions';
  attributeDiscussions?: Maybe<Array<AttributeDiscussion>>;
  chatRoomID?: Maybe<Scalars['String']['output']>;
  chatRoomName?: Maybe<Scalars['String']['output']>;
  lastMessageTime?: Maybe<Scalars['Time']['output']>;
  property: PropertyInfo;
};

export type PropertyDiscussionsInput = {
  orgName: Scalars['String']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type PropertyDiscussionsResult = {
  __typename?: 'PropertyDiscussionsResult';
  discussions: Array<Maybe<PropertyDiscussions>>;
};

export type PropertyDqsPage = {
  __typename?: 'PropertyDqsPage';
  /**  Opaque cursor to fetch the next page of properties in a subsequent call. */
  cursor?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Array<PropertyDqsRow>>;
  streamDqs?: Maybe<Scalars['Float']['output']>;
};

export type PropertyDqsPageInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['ID']['input'];
};

export type PropertyDqsRow = {
  __typename?: 'PropertyDqsRow';
  attributes?: Maybe<Array<PropertyAttributeScore>>;
  enrichmentLevels?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['Boolean']['output']>;
  propertyArchipelagoId: Scalars['ArchipelagoID']['output'];
  propertyContribution?: Maybe<Scalars['Float']['output']>;
  propertyDqs?: Maybe<Scalars['Float']['output']>;
  propertyId: Scalars['ID']['output'];
  /**  Refers to enrichment_prioritization  */
  propertyPotential?: Maybe<Scalars['Float']['output']>;
  propertyRevisionId: Scalars['ID']['output'];
  /**  Suggested documents to improve data quality score  */
  recommendedDocumentClassifications?: Maybe<Array<DocClassification>>;
  seismicZone?: Maybe<Scalars['Boolean']['output']>;
  tivPercentage?: Maybe<Scalars['Float']['output']>;
  windZone?: Maybe<Scalars['Boolean']['output']>;
};

export type PropertyEnrichmentPage = {
  __typename?: 'PropertyEnrichmentPage';
  /**  Opaque cursor to fetch the next page of properties in a subsequent call. */
  cursor?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Array<PropertyEnrichmentRow>>;
  streamDqsAfter?: Maybe<Scalars['Float']['output']>;
  streamDqsBefore?: Maybe<Scalars['Float']['output']>;
};

export type PropertyEnrichmentPageInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  snapshotNameAfter: Scalars['ID']['input'];
  snapshotNameBefore: Scalars['ID']['input'];
  streamSlug: Scalars['ID']['input'];
};

export type PropertyEnrichmentRow = {
  __typename?: 'PropertyEnrichmentRow';
  currentBuildingName?: Maybe<Scalars['String']['output']>;
  currentClientID?: Maybe<Scalars['String']['output']>;
  currentContribution?: Maybe<Scalars['Float']['output']>;
  currentDQS?: Maybe<Scalars['Float']['output']>;
  currentFloodZone?: Maybe<Scalars['Boolean']['output']>;
  currentIsEnriched?: Maybe<Scalars['Boolean']['output']>;
  currentOrgAddress?: Maybe<Scalars['String']['output']>;
  currentPotential?: Maybe<Scalars['Float']['output']>;
  currentSeismicZone?: Maybe<Scalars['Boolean']['output']>;
  currentSiteName?: Maybe<Scalars['String']['output']>;
  currentTIV?: Maybe<Scalars['Float']['output']>;
  currentWindZone?: Maybe<Scalars['Boolean']['output']>;
  previousBuildingName?: Maybe<Scalars['String']['output']>;
  previousClientID?: Maybe<Scalars['String']['output']>;
  previousContribution?: Maybe<Scalars['Float']['output']>;
  previousDQS?: Maybe<Scalars['Float']['output']>;
  previousFloodZone?: Maybe<Scalars['Boolean']['output']>;
  previousIsEnriched?: Maybe<Scalars['Boolean']['output']>;
  previousOrgAddress?: Maybe<Scalars['String']['output']>;
  previousPotential?: Maybe<Scalars['Float']['output']>;
  previousSeismicZone?: Maybe<Scalars['Boolean']['output']>;
  previousSiteName?: Maybe<Scalars['String']['output']>;
  previousTIV?: Maybe<Scalars['Float']['output']>;
  previousWindZone?: Maybe<Scalars['Boolean']['output']>;
  propertyId: Scalars['ID']['output'];
  status: PropertyStatusType;
};

export type PropertyGradeDetail = {
  __typename?: 'PropertyGradeDetail';
  /**  Attribute to which the detail applies  */
  attribute: RiskAttribute;
  /**  Overall property grade  */
  overallGrade: Grade;
  /**  Metrics broken down by grade category  */
  summaryMetrics?: Maybe<Array<PropertyGradeMetric>>;
  /**  Number of properties with a grade  */
  totalGradedPropertyCnt: Scalars['Int']['output'];
  /**  TIV of properties with a grade  */
  totalGradedTIV: Scalars['Float']['output'];
  /**  Predictive TIV of properties with a grade  */
  totalGradedTIVPd: Scalars['Float']['output'];
  /**  Number of properties  */
  totalPropertyCnt: Scalars['Int']['output'];
  /**  Total TIV of properties  */
  totalTIV: Scalars['Float']['output'];
  /**  Total Predictive TIV of properties  */
  totalTIVPd: Scalars['Float']['output'];
};

export type PropertyGradeMetric = {
  __typename?: 'PropertyGradeMetric';
  /**  Grade for the metric  */
  grade: Grade;
  /**
   * Percent of properties in the metric grade's category out of all properties with a grade for the metric.
   * Null for unknown category.
   */
  pctOfGradedProperties?: Maybe<Scalars['Float']['output']>;
  /**
   * Percent of TIV in the metric grade's category out of all TIV with a grade for the metric.
   * Null for unknown category.
   */
  pctOfGradedTIV?: Maybe<Scalars['Float']['output']>;
  /**
   * Percent of Predictive TIV in the metric grade's category out of all Predictive TIV with a grade for the metric.
   * Null for unknown category.
   */
  pctOfGradedTIVPd?: Maybe<Scalars['Float']['output']>;
  /**  Percent of properties in the metric grade's category  */
  pctOfProperties: Scalars['Float']['output'];
  /**  Percent of TIV in the metric grade's category  */
  pctOfTIV: Scalars['Float']['output'];
  /**  Percent of Predictive TIV in the metric grade's category  */
  pctOfTIVPd: Scalars['Float']['output'];
  /**  Number of properties in the metric grade's category  */
  propertyCnt: Scalars['Int']['output'];
  /**  Total TIV of properties in the metric grade's category  */
  tiv: Scalars['Float']['output'];
  /**  Total Predictive TIV of properties in the metric grade's category */
  tivPd: Scalars['Float']['output'];
};

export type PropertyGroup = {
  __typename?: 'PropertyGroup';
  filteredPropertyCount?: Maybe<Scalars['Int']['output']>;
  /**
   * Deprecated. Used to query propertiesPage, which has been deprecated.
   * streamPropertiesPage or orgPropertiesPage should be used to query properties instead of propertiesPage.
   */
  groupID: Scalars['ID']['output'];
  /** Name and value of attribute for this property group. */
  groupingAttribute?: Maybe<Attribute>;
  stats: PropertyGroupStats;
  subGroups?: Maybe<Array<PropertyGroup>>;
  totalPropertyCount?: Maybe<Scalars['Int']['output']>;
};

export type PropertyGroupStats = {
  __typename?: 'PropertyGroupStats';
  avgBuildYear?: Maybe<Scalars['Int']['output']>;
  avgLossCost?: Maybe<YearsFloat>;
  avgSquareFeet?: Maybe<Scalars['Float']['output']>;
  avgValue?: Maybe<Scalars['Float']['output']>;
  constructionType?: Maybe<DonutChart>;
  lossCostTrend?: Maybe<LossCostTrendChart>;
  occupancyType?: Maybe<DonutChart>;
  propertiesChart?: Maybe<PropertiesChart>;
  tivChart?: Maybe<SparkChart>;
  totalGrossLoss?: Maybe<YearsFloat>;
  totalLossCount?: Maybe<YearsInt>;
};

export type PropertyInfo = {
  __typename?: 'PropertyInfo';
  archipelagoID: Scalars['String']['output'];
  locationID?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
};

export enum PropertyKeyAttributeName {
  ArchipelagoId = 'archipelagoId',
  LocationId = 'locationId'
}

export type PropertyLock = {
  __typename?: 'PropertyLock';
  /**  Deprecated - use jobInfo field instead  */
  jobs?: Maybe<Array<Job>>;
  /**  Returns true if enclosing property or stream is not available for edits for current user. */
  locked: Scalars['Boolean']['output'];
  /**  Details on locks blocking edits on this property.  */
  locks?: Maybe<Array<Lock>>;
};

export type PropertyLossDetail = {
  __typename?: 'PropertyLossDetail';
  /**  Attribute to which the detail applies  */
  attribute: RiskAttribute;
  /**  Overall grade for the attribute  */
  overallGrade?: Maybe<Grade>;
};

export type PropertyMapStats = {
  __typename?: 'PropertyMapStats';
  centroid?: Maybe<Coordinates>;
};

export type PropertyMapStatsInput = {
  /** Name of a prior snapshot if grouping by New, Ongoing or Disposed for change analysis. */
  changesSince?: InputMaybe<Scalars['String']['input']>;
  /** Name of snapshot. If not specified, current state is returned. */
  currentSnapshot?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Current grouping value for selected attribute or snapshot to compare changes (New, Ongoing or Disposed) */
  groupValue?: InputMaybe<Scalars['String']['input']>;
  /** Optional attribute name for grouping or snapshot to compare changes. Only one of them can be used */
  groupedBy?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type PropertyMarker = {
  coordinates: Coordinates;
};

export type PropertyMarkersInput = {
  /** Deprecated - Optional pagination parameters for properties to clusterize. Process the first 2000 properties by default */
  cursor?: InputMaybe<Scalars['String']['input']>;
  pointSize?: InputMaybe<Scalars['Int']['input']>;
  /** Property group ID obtained from streamPropertyGroups query */
  propertyGroupId: Scalars['ID']['input'];
  /** Optional input region of interest. If not specified, api will returned markers for the whole map */
  roi?: InputMaybe<BBox>;
  /** Optional loss filter. Used by UI when grouping by loss attributes in order to avoid empty clusters */
  withLossesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Current zoom level on map. Must be between 1 and 20 */
  zoomLevel: Scalars['Int']['input'];
};

export type PropertyMarkersOutput = {
  __typename?: 'PropertyMarkersOutput';
  markers?: Maybe<Array<PropertyMarker>>;
  /** Deprecated - Current page information */
  pageInfo: PageInfo;
  ranges?: Maybe<Array<ClusterRange>>;
};

export type PropertyMarkersV2Input = {
  /** Name of snapshot. If not specified, current state is returned. */
  currentSnapshot?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  /**  Cluster algorithm and parameters  */
  params: ClusterParams;
  /** Optional input region of interest. If not specified, api will returned markers for the whole map */
  roi?: InputMaybe<BBox>;
  selectedGroup?: InputMaybe<SelectedGroup>;
  streamSlug: Scalars['ID']['input'];
  withLossesOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyMarkersV2Output = {
  __typename?: 'PropertyMarkersV2Output';
  markers?: Maybe<Array<PropertyMarker>>;
  ranges?: Maybe<Array<ClusterRange>>;
};

export type PropertyPermissionInput = {
  action: Action;
  propertyAIds: Array<Scalars['ArchipelagoID']['input']>;
};

/** Deprecated. Permissions returned also take into account if VI & Edit is enabled for the property's Org */
export type PropertyPermissions = {
  __typename?: 'PropertyPermissions';
  /** Current usr can start, submit or cancel VI */
  canConductVI: Scalars['Boolean']['output'];
  /** User has permission to directly edit property attributes. */
  canEdit: Scalars['Boolean']['output'];
  /** User can request or cancel a VI. */
  canRequestVI: Scalars['Boolean']['output'];
};

export type PropertyPoint = PropertyMarker & {
  __typename?: 'PropertyPoint';
  coordinates: Coordinates;
  property?: Maybe<Property>;
};

export type PropertyPotentialInputIt = {
  enrichmentPrioritization: Scalars['Float']['input'];
  fireModifiers: Scalars['Float']['input'];
  floodModifiers: Scalars['Float']['input'];
  other: Scalars['Float']['input'];
  primaryCope: Scalars['Float']['input'];
  propertyScoreID: Scalars['ID']['input'];
  quakeModifiers: Scalars['Float']['input'];
  scoreContribution: Scalars['Float']['input'];
  streamScoreID: Scalars['ID']['input'];
  windModifiers: Scalars['Float']['input'];
};

export type PropertyPotentialResultIt = {
  __typename?: 'PropertyPotentialResultIT';
  enrichmentPrioritization?: Maybe<Scalars['Float']['output']>;
  fireModifiers?: Maybe<Scalars['Float']['output']>;
  floodModifiers?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  other?: Maybe<Scalars['Float']['output']>;
  primaryCope?: Maybe<Scalars['Float']['output']>;
  propertyScoreID: Scalars['ID']['output'];
  quakeModifiers?: Maybe<Scalars['Float']['output']>;
  scoreContribution?: Maybe<Scalars['Float']['output']>;
  windModifiers?: Maybe<Scalars['Float']['output']>;
};

export type PropertyRecommendation = {
  __typename?: 'PropertyRecommendation';
  attributes: Array<AttributeRecommendation>;
  property: Property;
  /**  A higher score indicates a higher priority for improvement.  */
  score?: Maybe<Scalars['Float']['output']>;
};

export type PropertyRecommendationBucket = {
  __typename?: 'PropertyRecommendationBucket';
  name: Scalars['String']['output'];
  properties: Array<PropertyRecommendation>;
};

export type PropertyRecommendationStatusResult = {
  __typename?: 'PropertyRecommendationStatusResult';
  /**  Status of property ML-based recommendations generated for the snapshot.  */
  mlRecommendationsStatus: PropertyRecommendationsStatus;
};

export type PropertyRecommendationsInput = {
  /**  If not provided, the latest snapshot with recommendations generated will be used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type PropertyRecommendationsPageInput = {
  /**
   * Count to limit the number of returned properties. Maximum is 100. If provided value is more than 100,
   * server returns at most 100 results.
   */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /**  If not provided, the latest snapshot with recommendations generated will be used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type PropertyRecommendationsPageResponse = {
  __typename?: 'PropertyRecommendationsPageResponse';
  properties: Array<PropertyRecommendation>;
};

export type PropertyRecommendationsResponse = {
  __typename?: 'PropertyRecommendationsResponse';
  buckets: Array<PropertyRecommendationBucket>;
  grade?: Maybe<Scalars['Float']['output']>;
};

export enum PropertyRecommendationsStatus {
  InProgress = 'IN_PROGRESS',
  NotFound = 'NOT_FOUND',
  Ready = 'READY'
}

export type PropertyResponse = {
  __typename?: 'PropertyResponse';
  lock: PropertyLock;
  /**
   *  Permissions for current user on this property.  Caller should also
   * check 'lock' field for any ongoing Edits.
   */
  permissions: PropertyPermissions;
  property: Property;
  /**
   *  Deprecated. Use 'permissions' instead, which takes into account current configuration
   * and current user's permissions.
   */
  vi?: Maybe<VirtualInspectionInfo>;
};

export type PropertyScoreInputIt = {
  floodZone: Scalars['Boolean']['input'];
  isEnriched: Scalars['Boolean']['input'];
  organizationID: Scalars['ID']['input'];
  propertyArchipelagoID: Scalars['ID']['input'];
  propertyRevisionID: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['Float']['input']>;
  scoreMethodID: Scalars['ID']['input'];
  seismicZone: Scalars['Boolean']['input'];
  streamID: Scalars['ID']['input'];
  streamScoreID: Scalars['ID']['input'];
  totalInsuredValue: Scalars['Float']['input'];
  windZone: Scalars['Boolean']['input'];
};

export type PropertyScoreResultIt = {
  __typename?: 'PropertyScoreResultIT';
  floodZone?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isEnriched?: Maybe<Scalars['Boolean']['output']>;
  organizationID?: Maybe<Scalars['ID']['output']>;
  propertyRevisionID?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreMethodID?: Maybe<Scalars['ID']['output']>;
  seismicZone?: Maybe<Scalars['Boolean']['output']>;
  streamID?: Maybe<Scalars['ID']['output']>;
  streamScoreID?: Maybe<Scalars['ID']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  windZone?: Maybe<Scalars['Boolean']['output']>;
};

export enum PropertyStatus {
  /**
   * Active properties can be ongoing or disposed, but still visible to users
   *   with edit permissions
   */
  Active = 'Active',
  /**
   * Deleted properties are only visible to admins and risk managers if explicitely
   *   requested, for example to restore them
   */
  Deleted = 'Deleted',
  /**
   * Disposed properties are not visible in any stream configuration,
   *   but still visible to users with edit permissions
   */
  Disposed = 'Disposed',
  /**  Ongoing properties are visible in default stream configuration  */
  Ongoing = 'Ongoing'
}

export type PropertyStatusInput = {
  archipelagoId: Scalars['String']['input'];
  /**
   * Optional disposition date. Only applies when input status is Disposed
   *   Format must be YYYY-MM-DD
   */
  dispositionDate?: InputMaybe<Scalars['String']['input']>;
};

export enum PropertyStatusType {
  Disposed = 'Disposed',
  New = 'New',
  Ongoing = 'Ongoing'
}

export type PropertySummary = {
  __typename?: 'PropertySummary';
  archipelagoId: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<DocumentSummary>>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  pictures?: Maybe<Array<Pictures>>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type PropertyValuation = {
  __typename?: 'PropertyValuation';
  archipelagoID: Scalars['String']['output'];
  brc?: Maybe<Scalars['Float']['output']>;
  brcPercentDiff?: Maybe<Scalars['Float']['output']>;
  brcPercentOfTotal?: Maybe<Scalars['Float']['output']>;
  brcPercentValueDifference?: Maybe<Scalars['Float']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  usStateCode?: Maybe<Scalars['String']['output']>;
};

export enum PropertyValuationSortAttribute {
  Brc = 'BRC',
  BrcPercentDiff = 'BRCPercentDiff',
  BrcPercentOfTotal = 'BRCPercentOfTotal',
  BrcValueDiff = 'BRCValueDiff'
}

export type ProspectRecommendationsInput = {
  orgName: Scalars['String']['input'];
  snapshotName: Scalars['String']['input'];
  /**  Optional stream slug. If not provided, the My Properties stream is used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

/**  Provenance describes the source of an attribute's value. Holds a collection of links to documents that refer to this attribute  */
export type Provenance = {
  __typename?: 'Provenance';
  /**  Name of the attribute for which this evidence applies. */
  attributeName: Scalars['String']['output'];
  /**  URL for if the source is not a document but a website  */
  externalSourceURLs?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  organizationID: Scalars['ID']['output'];
  /**  Archipelago ID of the property  */
  propertyID: Scalars['ID']['output'];
  /**  Collection of documents that belong to this property and attributeName  */
  sourceDocuments?: Maybe<Array<DocumentLink>>;
  /**  Text to describe source of attribute. Any formatting should use markdown syntax.  */
  sources?: Maybe<Array<Scalars['String']['output']>>;
};

export type PublishStreamSnapshotInput = {
  snapshotId?: InputMaybe<Scalars['ID']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type QualityAttributeBenchmarkInput = {
  hazard: HazardCategory;
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualityAttributeBenchmarkResult = {
  __typename?: 'QualityAttributeBenchmarkResult';
  addressBenchmark?: Maybe<Array<AttributeScoreBenchmark>>;
  primaryCOPEBenchmark?: Maybe<Array<AttributeScoreBenchmark>>;
};

export type QualityAttributeInput = {
  hazard: HazardCategory;
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualityAttributeResult = {
  __typename?: 'QualityAttributeResult';
  attributeName: Scalars['String']['output'];
  scores?: Maybe<Array<AttributeScoreBreakdown>>;
};

export type QualityAvailableSnapshotsInput = {
  streamSlug: Scalars['String']['input'];
};

export type QualityAvailableSnapshotsResult = {
  __typename?: 'QualityAvailableSnapshotsResult';
  availableHazards?: Maybe<Array<HazardCategory>>;
  date?: Maybe<Scalars['Time']['output']>;
  displayDate?: Maybe<Scalars['Time']['output']>;
  snapshotName: Scalars['String']['output'];
};

export type QualityCricitalProperties = {
  __typename?: 'QualityCricitalProperties';
  propertyCount: Scalars['Int']['output'];
  propertyPct: Scalars['Float']['output'];
  /**  returns the requested tiv impact. as in Input */
  tivPctImpact: Scalars['Int']['output'];
};

export type QualityCriticalPropertiesInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
  /**  Request the tiv pct eg. 10,30,50  */
  tivPctImpact?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QualityCriticalPropertiesResult = {
  __typename?: 'QualityCriticalPropertiesResult';
  criticalProperties?: Maybe<Array<QualityCricitalProperties>>;
  totalProperties: Scalars['Int']['output'];
};

export type QualityMetrics = {
  __typename?: 'QualityMetrics';
  /**  Overall quality score of the stream  */
  score: Scalars['Float']['output'];
};

export type QualityPropertiesInput = {
  hazard: HazardCategory;
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualityPropertiesResults = {
  __typename?: 'QualityPropertiesResults';
  /** An array of values for each range. Where score is the starting number in the range (in this case for items with scores between 0 - 10). */
  propertyPct?: Maybe<Array<QualityPropertyPct>>;
  /** An array of values for each range. Where score is the starting number in the range (in this case for items with scores between 0 - 10). */
  tivPct?: Maybe<Array<QualityTivPct>>;
};

export type QualityPropertyPct = {
  __typename?: 'QualityPropertyPct';
  count: Scalars['Int']['output'];
  percent: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
};

export type QualitySnapshotBenchmarkInput = {
  hazard: HazardCategory;
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualitySnapshotBenchmarkResult = {
  __typename?: 'QualitySnapshotBenchmarkResult';
  avgEnrichedScore: Scalars['Float']['output'];
  avgUnenrichedScore: Scalars['Float']['output'];
  enriched?: Maybe<Array<ScoreCount>>;
  snapshotPercentile: Scalars['Int']['output'];
  snapshotScore: Scalars['Float']['output'];
  totalStreamCount: Scalars['Int']['output'];
  unenriched?: Maybe<Array<ScoreCount>>;
};

export type QualitySnapshotExposureSummaryInput = {
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualitySnapshotExposureSummaryResult = {
  __typename?: 'QualitySnapshotExposureSummaryResult';
  hazard: HazardCategory;
  /**  Number between 0 and 100  */
  locations: Scalars['Int']['output'];
  tiv: Scalars['Float']['output'];
  tivPct: Scalars['Float']['output'];
};

export type QualitySnapshotOverallInput = {
  hazard: HazardCategory;
  snapshotName: Scalars['ID']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualitySnapshotOverallResult = {
  __typename?: 'QualitySnapshotOverallResult';
  locationCount: Scalars['Int']['output'];
  locationCountAdded?: Maybe<Scalars['Int']['output']>;
  locationCountChangePct?: Maybe<Scalars['Float']['output']>;
  locationCountDisposed?: Maybe<Scalars['Int']['output']>;
  locationCountIncompleteCOPEData?: Maybe<Scalars['Int']['output']>;
  /**  Number between 0 and 100  */
  score: Scalars['Float']['output'];
  /**  Change percentage */
  scoreChangePct?: Maybe<Scalars['Float']['output']>;
  tiv: Scalars['Float']['output'];
  tivChangePct?: Maybe<Scalars['Float']['output']>;
};

export type QualitySnapshotScoreStatusInput = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type QualitySnapshotScoreStatusResult = {
  __typename?: 'QualitySnapshotScoreStatusResult';
  /**  Number between 0 and 100  */
  score: Scalars['Float']['output'];
  status: QualitySnapshotStatus;
};

export enum QualitySnapshotStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  InRetries = 'in_retries',
  Initiated = 'initiated',
  NoStatus = 'no_status'
}

export type QualityTivPct = {
  __typename?: 'QualityTivPct';
  percent: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
  tiv: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Returns an array of stream access requests. The function has two arguments.
   * - userEmail: will return all stream access requests for this user
   * - streamSlug: will return all stream access requests for the stream
   * All users can use the `userEmail` argument.
   * Only Archipelago Admin, User Admin and User Manager can use the `streamSlug` argument.
   * If both arguments are provided then the request is considered a bad request and an error will be returned.
   */
  accessRequests?: Maybe<Array<AccessRequest>>;
  /**
   * Deprecated - User accountUsersV2 instead
   * This API will list users belonging to an Enterprise Org, Direct Customer Org, or
   * Underlying Customer Org and will list them based on the user's role and type of org.
   */
  accountUsers?: Maybe<Array<AccountUsers>>;
  /**
   * This API will list users belonging to an Enterprise Org, Direct Customer Org, or
   * Underlying Customer Org and will list them based on the user's role and type of org.
   */
  accountUsersV2?: Maybe<AccountUsersOutput>;
  /**  Returns enterprise level charts  */
  accountsExplorers: AccountsExplorersOutput;
  airModelResults: AirModelResultsResponse;
  analyticsConfig: AnalyticsConfigResponse;
  /** Returns an array of attribute mappers that are available in the system. */
  attributeMappers?: Maybe<Array<SystemAttributeMapper>>;
  /** Returns an array of attribute names */
  attributeNames: Array<Scalars['String']['output']>;
  authorization: Authorization;
  /** Get the organization configuration specific to the RBAC service. */
  authorizationConfig: AuthorizationConfig;
  /**  Returns a carrier assessment report with aggregated grade information  */
  carrierAssessment: CarrierAssessmentResult;
  /**  Returns all available carrier assessment reports  */
  carrierAssessmentList: CarrierAssessmentListResult;
  /**  Returns the status of a carrier metric import task  */
  carrierMetricImportTask: CarrierMetricTaskResult;
  /**  Returns property assessment data  */
  carrierPropertyAssessments: CarrierPropertyAssessmentsResult;
  /**  Returns property claims  */
  carrierPropertyClaims: CarrierPropertyClaimsResult;
  /**  Returns property-specific carrier data  */
  carrierPropertyGrades: CarrierPropertyGradesResult;
  casualtyPage: CasualtyPage;
  /**  Returns the subset of requested client IDs which exist within an organization.  */
  clientIDsExist: ClientIDsExistResponse;
  compareChanges?: Maybe<StreamPropertyGroupsResponse>;
  /** Returns an array of country to currency code mapping that are available in the system. */
  countryCurrencyCode?: Maybe<Array<CountryCurrencyCode>>;
  currentChatRoom: CurrentChatRoomResponse;
  /**
   * Returns a page of properties where the attributes are typed. Includes derived and inactive attributes.
   * Queries "live" data and is only appropriate for asynchronous processing.
   * Use streamPropertiesPage in performance-sensitive use cases where cached data is acceptable.
   */
  currentStreamProperties: PropertiesPageResponse;
  /**  Returns current users' profile, capabilities and registration information.  */
  currentUser?: Maybe<UserSystemInfo>;
  /**
   * Returns current users' profile. Values are cached. Any changes in identity server may not reflect here
   * for upto eight hours.
   */
  currentUserProfile?: Maybe<UserProfile>;
  /**  Returns soft-deleted property page for a stream  */
  deletedProperties?: Maybe<PropertiesPage>;
  /**  Returns disposed property page for a stream  */
  disposedProperties?: Maybe<PropertiesPage>;
  document: Document;
  /** documentClassificationSuggestion returns suggestions based on the input. Unused in the frontend, ML Usage only */
  documentClassificationSuggestion?: Maybe<Array<DocumentClassificationSuggestion>>;
  /** documentPropertySuggestion returns suggestions based on the input. Unused in the frontend. ML Usage only */
  documentPropertySuggestion?: Maybe<Array<DocumentPropertySuggestion>>;
  documentUploadStatus: DocumentUploadStatusResult;
  /**
   * Returns documents based on orgName, classification and origin.
   * Used by jobs service for SOVs. Only authorized for admin users.
   */
  documentsByClassification: DocumentsByClassificationResponse;
  /**
   * Returns documents for the provided list of document ids.
   * Can only be used by admins.
   */
  documentsByIDs?: Maybe<Array<Document>>;
  /**
   * Return the domain allow lists for a stream by its stream slug.
   * Can only be used by User Admin, User Manager or Archipelago Admin.
   */
  domainAllowLists?: Maybe<Array<DomainAllowList>>;
  /**
   * Returns for a stream whether domain based access is enabled or disabled.
   * Can only be used by User Admin, User Manager or Archipelago Admin.
   */
  domainBasedAccessEnabled: Scalars['Boolean']['output'];
  /**  Returns a URL path to a download for a zip file containing the requested documents  */
  downloadZip: Scalars['String']['output'];
  /** Returns a page with 10 of the highest enrichment priority properties with provenance recommendations to improve the quality score. */
  dqsPropertyRecommendations: DqsPropertyRecommendationsResponse;
  /**  Returns a page of editor comments within a stream.  */
  editorCommentsPage: EditorCommentsPageResponse;
  /**  Returns a list of available users to assign to comments within a stream.  */
  editorCommentsUsers: EditorCommentsUsersResponse;
  /** returns true if the feature is enabled for the given organization */
  enabled: Scalars['Boolean']['output'];
  /** Returns an array of attribute mappers that are enabled for a given organization. */
  enabledAttributeMappers?: Maybe<Array<EnabledAttributeMapper>>;
  /** Engagement report. Returns the most viewed properties for a specified stream. */
  engagementPropertyViews?: Maybe<Array<EngagementPropertyViewsResult>>;
  /** Engagement report. Returns the stream engagement summary per type for users */
  engagementStreamViews?: Maybe<Array<EngagementStreamViewsResult>>;
  /** Engagement report. Returns the stream engagement per org */
  engagementViewsByOrg?: Maybe<Array<EngagementViewsByOrgResult>>;
  /** Engagement report. Returns the stream engagement per user with details */
  engagementViewsByUser?: Maybe<Array<EngagementViewsByUserResult>>;
  /** Engagement report. Returns the user engagement for this stream per type and per week */
  engagementWeeklyUsers: EngagementWeeklyUsersResult;
  /** This call will provide the data to populate the changes made between the 2 snapshots for specific_occupancy and construction_class_specific */
  enrichmentAttributeChange: EnrichmentAttributeChangeResult;
  /** This call will provide the data to populate which properties improved the most on the dashboard. Sorted by Property Score Change. Attributes unsorted. */
  enrichmentOverallQualityChange: EnrichmentOverallQualityChangeResult;
  /** This call will provide the data to populate which properties improved the most on the dashboard. Sorted by Property Score Change. Attributes unsorted. */
  enrichmentPropertyImprovement?: Maybe<Array<EnrichmentPropertyImprovementResult>>;
  /** This call will provide the data to populate the property prioritisation on the dashboard. Based on enrichment prio. */
  enrichmentPropertyPriority: EnrichmentPropertyPriorityResult;
  enterpriseDashboardMetadata: EnterpriseDashboardMetadataResponse;
  explorers: ExplorerData;
  /**  Returns all export configurations for an organization or stream  */
  exportConfigurations?: Maybe<Array<Maybe<ExportConfiguration>>>;
  exportDataTypedV3?: Maybe<PropertiesPage>;
  exportDataV3?: Maybe<UntypedPropertyRowsPage>;
  exportMetadata?: Maybe<MetadataExport>;
  /** export service calls */
  exportMetadataV3?: Maybe<MetadataExport>;
  /**
   * Can be called to fetch the progress for the export. When export is
   * completed the result will contain a link to the exported file.
   */
  exportProgress: ExportResult;
  exportProgressV3: ExportResultV3;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Exports property recommendations for a snapshot in csv format.
   * Edit the csv and import them with importPropertyRecommendations to alter them.
   * Only for admins.
   */
  exportPropertyRecommendations: ExportPropertyRecommendationsResponse;
  /** Returns the snapshots which have the exposure index calculated and are authorized for the requester */
  exposureAvailableSnapshots?: Maybe<Array<Maybe<ExposureAvailableSnapshotsResult>>>;
  /** Returns the attribute percentiles for a given industry */
  exposureIndustryPercentiles?: Maybe<Array<ExposureIndustryPercentilesResult>>;
  /**  Returns stats for exposure metrics refreshes. Only allowed for admins.  */
  exposureMetricsRefreshStats: ExposureMetricsRefreshStatsResult;
  /**
   * Returns all users in the system that have access to the stream with the exception of Archipelago Admins and DataManager and DataEngineers.
   * Can be used by Admin, User Admin and User Managers.
   */
  externalStreamViewers?: Maybe<StreamUsersPage>;
  /**
   * Deprecated. Use featuresV2 instead
   * return a list of optional features for the given organization
   */
  features?: Maybe<Array<OptionalFeature>>;
  /** returns all optional features along with a flag to indicate whether they are enabled */
  featuresV2?: Maybe<Array<ConfiguredFeature>>;
  /**  properties must belong to the same org  */
  hasPropertyPermission: Scalars['Boolean']['output'];
  inbox: InboxResponse;
  /**  returns worksheet document id if it is created; otherwise, returns nil  */
  jobWorksheetProgress?: Maybe<JobWorksheetProgressResponse>;
  /**  Returns pages of documents for linked properties. If cursor is specified all the other arguments will be ignored.  */
  linkedPropertiesDocumentsPage?: Maybe<DocumentsPage>;
  /** Returns loss attribute metadata for users to select for edit jobs */
  lossAttributeMetadata: Array<LossAttributeMetadata>;
  /** Retrieve all latest loss data by org and optional list of Archipelago Property IDs and Loss IDs */
  losses: Array<Loss>;
  /** Returns an array of attribute names related to losses */
  lossesAttributeNames: Array<Scalars['String']['output']>;
  /** Retrieve all latest loss data by list of Property Archipelago IDs */
  lossesByProperty: Array<Loss>;
  /** Retrieve all latest loss data by stream */
  lossesByStream: Array<Loss>;
  /**
   * Returns upto 100 ManagedOrgResources for current user, based on input.
   * If provided orgNamePrefix does not match any org which user has access to,
   * an empty list is returned.
   */
  managedOrgs: ManagedOrgsResult;
  /**
   * API to check authorization for principal represented by token in the request.
   * This is similar to "authorization" query, expect it requires a token for the principal
   * whose authorization needs to be checked.
   */
  myAuthorization: Authorization;
  /** list authorized scopes by scope type, resource type and action for currently authenticated user */
  myAuthorizedScopes?: Maybe<Array<Scope>>;
  /**
   * Only to be called by Auth0, and can only be called with a M2M token!
   *
   * Notify the Archipelago platform that a user has logged in. The metadata fields are optional and
   * can be used to update the user's metadata in our database user caching tables. The primaryOrgId
   * is optional and can be used to set the user's primary organization.
   */
  notifyLogin: Scalars['Boolean']['output'];
  /**
   * Only to be called by Auth0, and can only be called with a M2M token!
   *
   * Notify the Archipelago platform that a user has signed up. This will trigger a email
   * notification to be sent to the user. The metadata fields are optional and can be used to
   * provide additional information about the user, these values will be cached and used in at
   * various places in the platform. The primaryOrgId is optional and can be used to set the user's
   * primary organization.
   */
  notifySignup: Scalars['Boolean']['output'];
  /**
   * Organization document export page. This endpoint is used within the lambda function: export-documents-archive
   *         Same permissions as organizationDocumentsPage
   */
  orgDocumentInfoPage?: Maybe<OrgDocumentInfoPage>;
  orgExportConfig: OrgExportConfig;
  /**
   * Returns organization metadata and user permissions within the org.
   * Allowed for users with any access to the organization or one of its SOVs.
   */
  orgInfo: OrgInfoResponse;
  /** Deprecated. Use the PreCheck Service instead. */
  orgPreCheckConfig: OrgPreCheckConfig;
  /**  Returns a page of properties in an org.  */
  orgPropertiesPage: PropertiesPageResponse;
  orgPropertyConfig: OrgPropertyConfig;
  /** Organization's settings related to submission dashboard */
  orgSubmissionConfig: OrgSubmissionConfig;
  /**
   * Returns summary information for an organization.
   * Only allowed for users with org-scoped permissions.
   */
  orgSummary: OrgSummaryResponse;
  /**
   * Queries a single organization by name.
   * Can only be used by admins.
   */
  organization: Organization;
  /** Returns activities which have occurred within the organization. */
  organizationActivities: OrganizationActivitiesResponse;
  /**
   * Returns attribute metadata for a specific organization and (optional) stream
   * One of organizationId or orgName must be provided. If both are specified, orgId is used
   */
  organizationAttributeMetadata: Array<OrgAttributeMetadata>;
  /**  Returns document classifications from an organization. with the count of the documents */
  organizationDocumentsClassificationsInfo?: Maybe<Array<Maybe<DocumentClassificationInfo>>>;
  /**  Returns pages of documents from an organization. If cursor is specified then input argument will be ignored.  */
  organizationDocumentsPage?: Maybe<DocumentsPage>;
  /**  Returns document partial properties from an organization. with the count of the documents, If cursor is specified then input argument will be ignored. */
  organizationDocumentsPropertiesPage?: Maybe<DocumentOrgPropertyPage>;
  organizationPropertyGroups?: Maybe<PropertyGroup>;
  /** Returns an organization's tags. */
  organizationTags: OrganizationTagsResult;
  /** Will return a list of organizations. Can only be used by admins. */
  organizations: Array<Organization>;
  /**
   * Returns pages of organizations. If cursor is specified all the other arguments will be ignored.
   * Can only be used by admins.
   */
  organizationsPage?: Maybe<OrganizationsPage>;
  /**
   * Returns org info for the provided list of org ids or optional name.
   * Can only be used by admins.
   */
  orgsByIDs?: Maybe<Array<OrgInfo>>;
  /** Retrieve the policies by stream and optionally a snapshot */
  policiesByStream: Array<Policy>;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Returns a summary of pre-check recommendations.
   */
  preCheckRecommendationSummary: PreCheckRecommendationSummaryResponse;
  /**  Returns list of properties for the given archipelago ids.  */
  propertiesByAiDs?: Maybe<PropertiesByAiDsResponse>;
  /**  Deprecated. Please use propertiesByJobV2, instead. Currently used by diagnostic export.  */
  propertiesByJob?: Maybe<Array<Maybe<Property>>>;
  /**  Returns list of properties for a given job.  */
  propertiesByJobV2?: Maybe<Array<Maybe<Property>>>;
  /**  Deprecated. Please use streamPropertiesPage or orgPropertiesPage instead.  */
  propertiesPage?: Maybe<PropertiesPage>;
  /**
   * Return current properties with existing loss for a given organization.
   * Only meant to be used when selecting properties for loss edit jobs
   */
  propertiesWithLosses?: Maybe<PropertiesPage>;
  /**
   * Returns properties which share documents of the specified propertyID. The response doesn't
   *       include the property with the specified propertyID of the input.
   */
  propertiesWithSharedDocuments?: Maybe<PropertiesGroup>;
  /**
   * Returns a page of properties where the attributes are untyped.
   * SnapshotName is required. If cursor is specified then all the other arguments are ignored.
   * Queries "live" data and is only appropriate for asynchronous processing.
   * Deprecated. Use currentStreamProperties.
   */
  propertiesWithUntypedAttributes?: Maybe<UntypedPropertyRowsPage>;
  /**  Deprecated. Please use propertyV2, instead.  */
  property?: Maybe<Property>;
  /** Returns Stream specific and active metadata for property attributes */
  propertyAttributeMetadata: Array<AttributeMetadata>;
  /**
   * Returns Stream specific and active metadata for property attributes.
   * If there's no prior binding snapshots for the selected input date, some aggregation attributes
   * may be excluded.
   */
  propertyAttributeMetadataV2: Array<AttributeMetadata>;
  /**  Returns single property, given a property key attribute  */
  propertyByKey?: Maybe<Property>;
  propertyDerivation?: Maybe<Array<PropertyDerivationResponse>>;
  propertyDiscussions: PropertyDiscussionsResult;
  /**  Returns pages of documents from a property. If cursor is specified all the other arguments will be ignored.  */
  propertyDocumentsPage?: Maybe<DocumentsPage>;
  /**
   * This call provides all data required for DQD export.
   * Can only be used by admins.
   */
  propertyDqsPage?: Maybe<PropertyDqsPage>;
  /** This call provides all data required for Enrichment export */
  propertyEnrichmentPage?: Maybe<PropertyEnrichmentPage>;
  /** Returns default values like centroid to initialize map UI depending on selected stream, snapshot, filters etc... */
  propertyMapStats: PropertyMapStats;
  /** Used to display property markers on an interactive map, with individual markers and clusters depending on zoom level */
  propertyMarkers: PropertyMarkersOutput;
  /** Used to display property markers on an interactive map, with individual markers and clusters depending on zoom level */
  propertyMarkersV2: PropertyMarkersV2Output;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Returns recommendations to improve the data quality of stream properties, grouped and ordered by priority.
   */
  propertyRecommendations: PropertyRecommendationsResponse;
  /**
   * Deprecated. Use the PreCheck Service instead.
   * Returns a page of properties recommended for data quality improvement in order of priority rank.
   */
  propertyRecommendationsPage: PropertyRecommendationsPageResponse;
  /**  Returns the number of property revisions related to given job.  */
  propertyRevisionCount: Scalars['Int']['output'];
  propertyV2?: Maybe<PropertyResponse>;
  /** Provides the data quality score breakdown at the snapshot attribute level. */
  qualityAttribute?: Maybe<Array<QualityAttributeResult>>;
  /** Provides the data quality score breakdown at platform level. only returning tivPct for data sharing purposes. */
  qualityAttributeBenchmark: QualityAttributeBenchmarkResult;
  /** This call will provide the data to populate the two filtering dropdowns on the dashboard. Snapshots and available hazards. */
  qualityAvailableSnapshots?: Maybe<Array<Maybe<QualityAvailableSnapshotsResult>>>;
  /** This call will provide the data to populate the Critical Properties by tiv impact filtered by tiv pct. */
  qualityCriticalProperties: QualityCriticalPropertiesResult;
  /** This call will provide the data for a property score histogram. property count/tiv based on score */
  qualityProperties: QualityPropertiesResults;
  /** This call will provide the data for a histogram of stream scores. In comparison to other streams on the platform. */
  qualitySnapshotBenchmark: QualitySnapshotBenchmarkResult;
  /** Provides the snapshot exposure summary for the data quality dashboard at TIV per hazard. */
  qualitySnapshotExposureSummary?: Maybe<Array<QualitySnapshotExposureSummaryResult>>;
  /** Overall quality score on the snapshot level. Returns the score over all attributes. */
  qualitySnapshotOverall: QualitySnapshotOverallResult;
  /** This call will provide the status and score of a snapshot */
  qualitySnapshotScoreStatus: QualitySnapshotScoreStatusResult;
  /** Return the tasks that the Reporting View service is processing and has processed */
  reportingViewTasks?: Maybe<Array<ReportingViewTask>>;
  /**
   * List role assignments based on principal id or scope type and value or role.
   * One of principalID and scopeInput must be specified. Principal id is case-insensitive.
   * All selectors must match when more than one is specified.
   */
  roleAssignmentsV2: RoleAssignmentsResponse;
  rolePermissions: Array<Permission>;
  roles?: Maybe<Array<Role>>;
  /** Returns system default attribute metadata. (For internal use.) */
  schema: Array<AttributeSchema>;
  /** ScopeGroups returns a list of scope groups filtered by scope ids. This is useful for example for getting a list of scope groups that are managed for a certain organization. */
  scopeGroups?: Maybe<Array<ScopeGroup>>;
  searchTasks?: Maybe<Array<TaskSearchResponse>>;
  /**  Returns single smartfolder by organization and smartfolder name  */
  smartFolder: SmartFolder;
  /**  Returns pages of documents from a smartfolder. If cursor is specified all the other arguments will be ignored. When orgName and name are provided this action will return the first page.  */
  smartFolderDocumentsPage?: Maybe<DocumentsPage>;
  /**  Returns a list of smartfolders per organization. If cursor is specified all the other arguments will be ignored.  */
  smartFoldersPage?: Maybe<SmartFolderPage>;
  /** Return snapshot by snapshot name and org ID */
  snapshotByName?: Maybe<Snapshot>;
  snapshotConfig: SnapshotConfig;
  sovProperty?: Maybe<UntypedPropertyRow>;
  startWorkflow: Scalars['Boolean']['output'];
  /**  Deprecated. Use streamV2 instead.  */
  stream?: Maybe<Stream>;
  /**  Returns pages of documents from a stream. If cursor is specified all the other arguments will be ignored.  */
  streamDocumentsPage?: Maybe<DocumentsPage>;
  /**  Returns a page of properties in a stream looked up by id.  */
  streamPropertiesByID: PropertiesPageResponse;
  /**  Returns a page of properties in a stream looked up by slug.  */
  streamPropertiesPage: StreamPropertiesPageResponse;
  /** Returns grouping of properties with stats. */
  streamPropertyGroups?: Maybe<StreamPropertyGroupsResponse>;
  /**
   * Returns a page of property valuations for a stream. Returns null if there are no stream snapshots.
   * All values are as of the most recent valuation snapshot bound to the stream.
   */
  streamPropertyValuations?: Maybe<StreamPropertyValuationsResponse>;
  /**
   * Returns insurer explorers for a snapshot in a stream.
   * All change comparisons and prior values refer to the last bound snapshot.
   */
  streamRiskSummary: SnapshotComparison;
  /**
   * Returns stream information with permission details based on optional selected snapshot
   *   If no snapshot is selected, check permissions on latest authorized snapshot
   */
  streamV2?: Maybe<StreamResponse>;
  /**
   * Returns a summary of property valuations for a stream. Returns null if there are no stream snapshots.
   * All values are as of the most recent valuation snapshot bound to the stream.
   */
  streamValuationSummary?: Maybe<StreamValuationSummaryResponse>;
  /**
   * Returns all stream for a given organization that a user has access to.
   * Public streams in the org are included, only if the user has already accessed them, after receiving a link.
   */
  streams: Array<Stream>;
  streamsByOrganizationID: Array<Stream>;
  /**
   * Returns all stream for a given organization that a user has access to.
   * Public streams in the org are included, only if the user has already accessed them, after receiving a link.
   */
  streamsV2?: Maybe<StreamsResponse>;
  /**  Returns an individual submission  */
  submission: Submission;
  /**  Returns the current attribute visibility configuration for selected submission organization  */
  submissionAttributeMetadata?: Maybe<Array<SubmissionAttributeMetadata>>;
  /**  Returns the region and team options for the selected submission organization  */
  submissionRegions: SubmissionRegionsResponse;
  /**
   * Returns a worksheet for configuring the region and team options for the selected submission organization.
   * The worksheet contains the current options for regions and teams.
   * Only for admins.
   */
  submissionRegionsWorksheet: SubmissionRegionsWorksheetResponse;
  /**  Returns submission page information and an array of submissions  */
  submissionsPageV2?: Maybe<SubmissionsPage>;
  /**  Return an individual ticket by ID  */
  supportTicket: SupportTicketOutput;
  /** Returns list of support tickets. */
  supportTicketsPage: SupportTicketsPageOutput;
  taskDetails?: Maybe<Array<TaskVariableSearchResponse>>;
  /** Returns all streams for a given organization that a user has access to. If the organization is an enterprise then we return all the streams in the underlying organizations. */
  underlyingOrgStreams: UnderlyingOrgStreamsResponse;
  /** Returns the underlying organizations for an enterprise org as a list, ordered by the organization name. Can only be used by the Enterprise Admins. */
  underlyingOrgs: UnderlyingOrgsResult;
  /** Returns the user profile for a given user. UserID can be either the Auth0 ID or the user's email address. */
  userByID?: Maybe<UserProfile>;
  /**  Returns current users' id and token needed to access platform chat  */
  userChatInfo?: Maybe<UserChatInfo>;
  /**
   * Admin only.
   * Returns list of users and their roles for a given organization.
   */
  userRoles?: Maybe<Array<UserRolesResponse>>;
  users?: Maybe<UserPage>;
  /**  Returns list of users from auth0 for a given organization  */
  usersByOrganization: Array<UserProfile>;
  /** Returns list of users for the given roles. Requires admin privilege. */
  usersByRoles?: Maybe<UsersByRolesResponse>;
  /**  validate enum values  */
  validateEnumValues?: Maybe<ValidateEnumValuesResponse>;
  validateJob?: Maybe<Array<ValidateError>>;
  /** Returns all validation labels in the system */
  validateLabels?: Maybe<Array<ValidateLabel>>;
  /** Runs validation rules against the provided property */
  validateProperty?: Maybe<Array<ValidateError>>;
  validateRecord?: Maybe<Array<ValidateError>>;
  /** Returns all validation rules in the system - ignores org overrides */
  validateRules?: Maybe<Array<ValidateRuleV2>>;
  /** Returns all validation rules in the system. Can filter by validateLabels */
  validateRulesV2?: Maybe<Array<ValidateRuleV2>>;
  /** Runs validation rules against all properties in the provided stream */
  validateStream?: Maybe<ValidateStream>;
  /** Runs the provided validation rule against the provided stream */
  validateUserRule?: Maybe<Array<ValidateError>>;
  /**
   * Returns the distinct values for attributes filterable in streamValuationSummary and streamPropertyValuations.
   * Distinct values are as of the most recent valuation snapshot bound to the stream.
   */
  valuationSummaryFilterValues?: Maybe<ValuationSummaryFilterValuesResponse>;
  workflowStarted: Scalars['Boolean']['output'];
};


export type QueryAccessRequestsArgs = {
  input: AccessRequestsInput;
};


export type QueryAccountUsersArgs = {
  input: AccountUsersInput;
};


export type QueryAccountUsersV2Args = {
  input: AccountUsersInput;
};


export type QueryAccountsExplorersArgs = {
  input: AccountsExplorersInput;
};


export type QueryAirModelResultsArgs = {
  input: AirModelResultsInput;
};


export type QueryAnalyticsConfigArgs = {
  input: AnalyticsConfigInput;
};


export type QueryAuthorizationArgs = {
  action: Action;
  principalId: Scalars['String']['input'];
  resource: ResourceInput;
  scope: ScopeInput;
};


export type QueryAuthorizationConfigArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryCarrierAssessmentArgs = {
  input: CarrierAssessmentInput;
};


export type QueryCarrierMetricImportTaskArgs = {
  input: CarrierMetricTaskInput;
};


export type QueryCarrierPropertyAssessmentsArgs = {
  input: CarrierPropertyAssessmentsInput;
};


export type QueryCarrierPropertyClaimsArgs = {
  input: CarrierPropertyClaimsInput;
};


export type QueryCarrierPropertyGradesArgs = {
  input: CarrierPropertyGradesInput;
};


export type QueryCasualtyPageArgs = {
  input: CasualtyPageInput;
};


export type QueryClientIDsExistArgs = {
  input: ClientIDsExistInput;
};


export type QueryCompareChangesArgs = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  fromSnapshot: Scalars['String']['input'];
  streamSlug: Scalars['ID']['input'];
  toSnapshot?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrentChatRoomArgs = {
  input: CurrentChatRoomInput;
};


export type QueryCurrentStreamPropertiesArgs = {
  input: CurrentStreamPropertiesInput;
};


export type QueryDeletedPropertiesArgs = {
  input?: InputMaybe<DeletedPropertiesInput>;
};


export type QueryDisposedPropertiesArgs = {
  input?: InputMaybe<DeletedPropertiesInput>;
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentClassificationSuggestionArgs = {
  input: SuggestionInput;
};


export type QueryDocumentPropertySuggestionArgs = {
  input: SuggestionInput;
};


export type QueryDocumentUploadStatusArgs = {
  documentID: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
};


export type QueryDocumentsByClassificationArgs = {
  input: DocumentsByClassificationInput;
};


export type QueryDocumentsByIDsArgs = {
  docIDs: Array<Scalars['ID']['input']>;
};


export type QueryDomainAllowListsArgs = {
  streamId: Scalars['ID']['input'];
};


export type QueryDomainBasedAccessEnabledArgs = {
  streamSlug: Scalars['String']['input'];
};


export type QueryDownloadZipArgs = {
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgID: Scalars['ID']['input'];
};


export type QueryDqsPropertyRecommendationsArgs = {
  input: DqsPropertyRecommendationsInput;
};


export type QueryEditorCommentsPageArgs = {
  input: EditorCommentsPageInput;
};


export type QueryEditorCommentsUsersArgs = {
  input: EditorCommentsUsersInput;
};


export type QueryEnabledArgs = {
  featureName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type QueryEnabledAttributeMappersArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryEngagementPropertyViewsArgs = {
  input: EngagementPropertyViewsInput;
};


export type QueryEngagementStreamViewsArgs = {
  input: EngagementStreamViewsInput;
};


export type QueryEngagementViewsByOrgArgs = {
  input: EngagementViewsByOrgInput;
};


export type QueryEngagementViewsByUserArgs = {
  input: EngagementViewsByUserInput;
};


export type QueryEngagementWeeklyUsersArgs = {
  input: EngagementWeeklyUsersInput;
};


export type QueryEnrichmentAttributeChangeArgs = {
  input: EnrichmentAttributeChangeInput;
};


export type QueryEnrichmentOverallQualityChangeArgs = {
  input: EnrichmentOverallQualityChangeInput;
};


export type QueryEnrichmentPropertyImprovementArgs = {
  input: EnrichmentPropertyImprovementInput;
};


export type QueryEnrichmentPropertyPriorityArgs = {
  input: EnrichmentPropertyPriorityInput;
};


export type QueryEnterpriseDashboardMetadataArgs = {
  input: EnterpriseDashboardMetadataInput;
};


export type QueryExplorersArgs = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  fromSnapshot?: InputMaybe<Scalars['String']['input']>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  streamSlug: Scalars['ID']['input'];
  toSnapshot?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExportConfigurationsArgs = {
  input: ExportConfigurationsInput;
};


export type QueryExportDataTypedV3Args = {
  input: ExportDataV3Input;
};


export type QueryExportDataV3Args = {
  input: ExportDataV3Input;
};


export type QueryExportMetadataArgs = {
  input: ExportMetadataInput;
};


export type QueryExportMetadataV3Args = {
  input: ExportMetadataV3Input;
};


export type QueryExportProgressArgs = {
  input: ExportProgressInput;
};


export type QueryExportProgressV3Args = {
  input: ExportProgressV3Input;
};


export type QueryExportPropertyRecommendationsArgs = {
  input: ExportPropertyRecommendationsInput;
};


export type QueryExposureAvailableSnapshotsArgs = {
  input: ExposureAvailableSnapshotsInput;
};


export type QueryExposureIndustryPercentilesArgs = {
  input: ExposureIndustryPercentilesInput;
};


export type QueryExternalStreamViewersArgs = {
  input: ExternalStreamViewersInput;
};


export type QueryFeaturesArgs = {
  featureName?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFeaturesV2Args = {
  featureName?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};


export type QueryHasPropertyPermissionArgs = {
  input: PropertyPermissionInput;
};


export type QueryInboxArgs = {
  input: InboxInput;
};


export type QueryJobWorksheetProgressArgs = {
  input: JobWorksheetProgressInput;
};


export type QueryLinkedPropertiesDocumentsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<AttributeFilter>>;
  linkedToAllProperties?: InputMaybe<Scalars['Boolean']['input']>;
  orgName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  propertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  provenanceEnrichment?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QueryLossAttributeMetadataArgs = {
  input: LossAttributeMetadataInput;
};


export type QueryLossesArgs = {
  input: LossesInput;
};


export type QueryLossesByPropertyArgs = {
  input: LossesByPropertyInput;
};


export type QueryLossesByStreamArgs = {
  input: Array<LossesByStreamInput>;
};


export type QueryManagedOrgsArgs = {
  input?: InputMaybe<ManagedOrgsInput>;
};


export type QueryMyAuthorizationArgs = {
  action: Action;
  resource: ResourceInput;
  scope: ScopeInput;
};


export type QueryMyAuthorizedScopesArgs = {
  input: MyAuthorizedScopesInput;
};


export type QueryNotifyLoginArgs = {
  input: NotifyLoginInput;
};


export type QueryNotifySignupArgs = {
  input: NotifySignupInput;
};


export type QueryOrgDocumentInfoPageArgs = {
  input: OrgDocumentInfoInput;
};


export type QueryOrgExportConfigArgs = {
  input: OrgExportConfigInput;
};


export type QueryOrgInfoArgs = {
  input: OrgInfoInput;
};


export type QueryOrgPreCheckConfigArgs = {
  input: OrgPreCheckConfigInput;
};


export type QueryOrgPropertiesPageArgs = {
  input: OrgPropertiesPageInput;
};


export type QueryOrgPropertyConfigArgs = {
  input: OrgPropertyConfigInput;
};


export type QueryOrgSubmissionConfigArgs = {
  input: OrgSubmissionConfigInput;
};


export type QueryOrgSummaryArgs = {
  input: OrgSummaryInput;
};


export type QueryOrganizationArgs = {
  name: Scalars['String']['input'];
};


export type QueryOrganizationActivitiesArgs = {
  input: OrganizationActivitiesInput;
};


export type QueryOrganizationAttributeMetadataArgs = {
  includeSystemAttributes?: InputMaybe<Scalars['Boolean']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  streamId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationDocumentsClassificationsInfoArgs = {
  filter?: InputMaybe<OrgDocumentFilter>;
};


export type QueryOrganizationDocumentsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrgDocumentFilter>;
  input?: InputMaybe<OrgDocumentInput>;
};


export type QueryOrganizationDocumentsPropertiesPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrgDocumentFilter>;
};


export type QueryOrganizationPropertyGroupsArgs = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationTagsArgs = {
  input: OrganizationTagsInput;
};


export type QueryOrganizationsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<OrgAttributeFilter>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QueryOrgsByIDsArgs = {
  orgIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPoliciesByStreamArgs = {
  input: Array<PoliciesByStreamInput>;
};


export type QueryPreCheckRecommendationSummaryArgs = {
  input: PreCheckRecommendationSummaryInput;
};


export type QueryPropertiesByAiDsArgs = {
  input: PropertiesByAiDsInput;
};


export type QueryPropertiesByJobArgs = {
  jobID: Scalars['ID']['input'];
};


export type QueryPropertiesByJobV2Args = {
  input: PropertiesByJobInput;
};


export type QueryPropertiesPageArgs = {
  activePropertiesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  cursor: Scalars['String']['input'];
  effectiveFrom?: InputMaybe<Scalars['String']['input']>;
  groupID: Scalars['ID']['input'];
  imageSize?: InputMaybe<ImageSize>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QueryPropertiesWithLossesArgs = {
  input: PropertiesWithLossesInput;
};


export type QueryPropertiesWithSharedDocumentsArgs = {
  input: PropertiesWithSharedDocumentsInput;
};


export type QueryPropertiesWithUntypedAttributesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['ID']['input'];
};


export type QueryPropertyArgs = {
  propertyID: Scalars['ID']['input'];
  slug?: InputMaybe<Scalars['ID']['input']>;
  snapshot?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPropertyAttributeMetadataArgs = {
  streamSlug: Scalars['ID']['input'];
};


export type QueryPropertyAttributeMetadataV2Args = {
  input: PropertyAttributeMetadataInput;
};


export type QueryPropertyByKeyArgs = {
  keyName: PropertyKeyAttributeName;
  keyValue: Scalars['String']['input'];
  orgID: Scalars['String']['input'];
};


export type QueryPropertyDerivationArgs = {
  orgID: Scalars['ID']['input'];
};


export type QueryPropertyDiscussionsArgs = {
  input: PropertyDiscussionsInput;
};


export type QueryPropertyDocumentsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<AttributeFilter>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  propertyID?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QueryPropertyDqsPageArgs = {
  input: PropertyDqsPageInput;
};


export type QueryPropertyEnrichmentPageArgs = {
  input: PropertyEnrichmentPageInput;
};


export type QueryPropertyMapStatsArgs = {
  input: PropertyMapStatsInput;
};


export type QueryPropertyMarkersArgs = {
  input: PropertyMarkersInput;
};


export type QueryPropertyMarkersV2Args = {
  input: PropertyMarkersV2Input;
};


export type QueryPropertyRecommendationsArgs = {
  input: PropertyRecommendationsInput;
};


export type QueryPropertyRecommendationsPageArgs = {
  input: PropertyRecommendationsPageInput;
};


export type QueryPropertyRevisionCountArgs = {
  input: DeletePropertyRevInput;
};


export type QueryPropertyV2Args = {
  propertyID: Scalars['ID']['input'];
  slug?: InputMaybe<Scalars['ID']['input']>;
  snapshot?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQualityAttributeArgs = {
  input: QualityAttributeInput;
};


export type QueryQualityAttributeBenchmarkArgs = {
  input: QualityAttributeBenchmarkInput;
};


export type QueryQualityAvailableSnapshotsArgs = {
  input: QualityAvailableSnapshotsInput;
};


export type QueryQualityCriticalPropertiesArgs = {
  input: QualityCriticalPropertiesInput;
};


export type QueryQualityPropertiesArgs = {
  input: QualityPropertiesInput;
};


export type QueryQualitySnapshotBenchmarkArgs = {
  input: QualitySnapshotBenchmarkInput;
};


export type QueryQualitySnapshotExposureSummaryArgs = {
  input: QualitySnapshotExposureSummaryInput;
};


export type QueryQualitySnapshotOverallArgs = {
  input: QualitySnapshotOverallInput;
};


export type QueryQualitySnapshotScoreStatusArgs = {
  input: QualitySnapshotScoreStatusInput;
};


export type QueryReportingViewTasksArgs = {
  input: ReportingViewTasksInput;
};


export type QueryRoleAssignmentsV2Args = {
  principalId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  scope?: InputMaybe<ScopeInput>;
};


export type QueryRolePermissionsArgs = {
  role: Role;
};


export type QueryScopeGroupsArgs = {
  input: ScopeGroupsInput;
};


export type QuerySearchTasksArgs = {
  input: TaskSearchInput;
};


export type QuerySmartFolderArgs = {
  name: Scalars['ID']['input'];
  orgName: Scalars['ID']['input'];
};


export type QuerySmartFolderDocumentsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<AttributeFilter>>;
  name?: InputMaybe<Scalars['ID']['input']>;
  orgName?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QuerySmartFoldersPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<AttributeFilter>>;
  orgName?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QuerySnapshotByNameArgs = {
  name: Scalars['String']['input'];
  orgID: Scalars['ID']['input'];
};


export type QuerySnapshotConfigArgs = {
  streamSlug: Scalars['ID']['input'];
};


export type QuerySovPropertyArgs = {
  propertyID: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};


export type QueryStartWorkflowArgs = {
  input: StartWorkflowInput;
};


export type QueryStreamArgs = {
  slug: Scalars['ID']['input'];
  userCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStreamDocumentsPageArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<AttributeFilter>>;
  orgName?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};


export type QueryStreamPropertiesByIdArgs = {
  input: StreamPropertiesByIdInput;
};


export type QueryStreamPropertiesPageArgs = {
  input: StreamPropertiesPageInput;
};


export type QueryStreamPropertyGroupsArgs = {
  currentSnapshotName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  streamSlug: Scalars['ID']['input'];
};


export type QueryStreamPropertyValuationsArgs = {
  input: StreamPropertyValuationsInput;
};


export type QueryStreamRiskSummaryArgs = {
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['ID']['input'];
};


export type QueryStreamV2Args = {
  slug: Scalars['ID']['input'];
  snapshot?: InputMaybe<Scalars['String']['input']>;
  userCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStreamValuationSummaryArgs = {
  input: StreamValuationSummaryInput;
};


export type QueryStreamsArgs = {
  orgName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStreamsByOrganizationIdArgs = {
  organizationID: Scalars['ID']['input'];
};


export type QueryStreamsV2Args = {
  orgName?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySubmissionArgs = {
  input: GetSubmissionInput;
};


export type QuerySubmissionAttributeMetadataArgs = {
  input: SubmissionAttributeMetadataInput;
};


export type QuerySubmissionRegionsArgs = {
  input: SubmissionRegionsInput;
};


export type QuerySubmissionRegionsWorksheetArgs = {
  input: SubmissionRegionsWorksheetInput;
};


export type QuerySubmissionsPageV2Args = {
  input: SubmissionsPageInput;
};


export type QuerySupportTicketArgs = {
  input: SupportTicketInput;
};


export type QuerySupportTicketsPageArgs = {
  input: SupportTicketsPageInput;
};


export type QueryTaskDetailsArgs = {
  input: TaskVariableSearchInput;
};


export type QueryUnderlyingOrgStreamsArgs = {
  input: UnderlyingOrgStreamsInput;
};


export type QueryUnderlyingOrgsArgs = {
  input?: InputMaybe<UnderlyingOrgsInput>;
};


export type QueryUserByIdArgs = {
  userID: Scalars['String']['input'];
};


export type QueryUserRolesArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Sort>;
};


export type QueryUsersByOrganizationArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryUsersByRolesArgs = {
  input: UsersByRolesInput;
};


export type QueryValidateEnumValuesArgs = {
  input: ValidateEnumValuesInput;
};


export type QueryValidateJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidateLabelsArgs = {
  filter?: InputMaybe<ValidateLabelFilter>;
};


export type QueryValidatePropertyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidateRecordArgs = {
  property: PropertyDataInput;
};


export type QueryValidateRulesV2Args = {
  filter?: InputMaybe<ValidateFilterByLabel>;
};


export type QueryValidateStreamArgs = {
  slug: Scalars['ID']['input'];
};


export type QueryValidateUserRuleArgs = {
  rule: ValidateRule;
  streamID: Scalars['ID']['input'];
};


export type QueryValuationSummaryFilterValuesArgs = {
  input: ValuationSummaryFilterValuesInput;
};


export type QueryWorkflowStartedArgs = {
  input: WorkflowStartedInput;
};

/**  Raw explorer data  */
export type RawJsonChart = Chart & {
  __typename?: 'RawJSONChart';
  json: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RegisterPropertyManagersInput = {
  document: Scalars['Upload']['input'];
  orgName: Scalars['String']['input'];
};

export type RegisterUserInput = {
  /** Free-form name of the company the user works for */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** The type of user account to create */
  connection: Connection;
  email: Scalars['String']['input'];
  intent?: InputMaybe<Scalars['String']['input']>;
  /** Full name of the user */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * Name of the api organization the user account should be associated with. Specifying this value
   * means that the account will be a risk manager for the org.
   */
  orgName?: InputMaybe<Scalars['String']['input']>;
  /** Delete passwordless account with the same email, before new account is created */
  overwriteUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required when connection PASSWORD_WITH_MFA or PASSWORD_WITHOUT_MFA is used */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Free-form phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Set to true if an password reset email must be sent. No email will be sent when connection is passwordless */
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of full stream slugs. The user will be added to all the streams that are specified in this array */
  streamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Free-form job title in the company the user works for */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RejectJobInput = {
  comment: Scalars['String']['input'];
  jobID: Scalars['ID']['input'];
};

export type ReleasePropertyLocksInput = {
  /**  Only locks with this group ID will be released.  */
  groupID: Scalars['String']['input'];
  /**  Only locks with this organization ID will be released.  */
  orgID: Scalars['ID']['input'];
};

export type ReleasePropertyLocksResponse = {
  __typename?: 'ReleasePropertyLocksResponse';
  /** IDs of properties which have had attribute locks released. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
};

export type RemoveAllScopesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
};

export type RemoveDocumentFromStreamInput = {
  documentID: Scalars['ID']['input'];
  streamID: Scalars['ID']['input'];
};

export type RemoveDomainAllowListInput = {
  streamSlug: Scalars['ID']['input'];
  userEmailDomain: Scalars['String']['input'];
};

export type RemoveOrgFromDomainInput = {
  domain: Scalars['String']['input'];
  orgID: Scalars['ID']['input'];
};

export type RemoveOrgFromDomainInputV2 = {
  domain: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type RemoveScopeGroupMemberInput = {
  /** Id of the group from which to remove members. */
  groupId: Scalars['ID']['input'];
  /** Identifier of the members to be removed from the group. */
  memberIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveScopesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
  /**  Empty values are not allowed  */
  scopeValues: Array<Scalars['String']['input']>;
};

export type RenewPropertyLocksInput = {
  /**
   * The number of hours from the current time at which the locks will expire.
   * A maximum of 336 hours may be specified.
   */
  expireInHours: Scalars['Int']['input'];
  /**  Only locks with this group ID will be updated.  */
  groupID: Scalars['String']['input'];
  /**  Only locks with this organization ID will be updated.  */
  orgID: Scalars['ID']['input'];
};

export type RenewPropertyLocksResponse = {
  __typename?: 'RenewPropertyLocksResponse';
  /** IDs of properties which have had attribute locks updated. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
};

export enum ReportCode {
  Dqd = 'DQD',
  EngagementReport = 'EngagementReport',
  EnrichmentReport = 'EnrichmentReport',
  ExposureSummary = 'ExposureSummary',
  RmAssessment = 'RMAssessment',
  ValuationSummary = 'ValuationSummary',
  ZurichUwAr = 'ZurichUwAR'
}

export type ReportingViewTask = {
  __typename?: 'ReportingViewTask';
  createdAt: Scalars['Time']['output'];
  createdBy?: Maybe<UserProfile>;
  doneAt?: Maybe<Scalars['Time']['output']>;
  errorAt?: Maybe<Scalars['Time']['output']>;
  errorDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  propertyIDs?: Maybe<Array<Scalars['String']['output']>>;
  readableDuration?: Maybe<Scalars['String']['output']>;
  snapshotId?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Time']['output']>;
  stats?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  streamId?: Maybe<Scalars['ID']['output']>;
};

export type ReportingViewTasksInput = {
  orgName: Scalars['String']['input'];
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type RequestAccessInput = {
  streamSlug: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type ResolvedCommentsGroup = {
  __typename?: 'ResolvedCommentsGroup';
  comments: Array<EditorComment>;
  resolvedBy?: Maybe<UserProfile>;
  timeOfResolution?: Maybe<Scalars['Time']['output']>;
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ResourceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type: ResourceType;
};

export enum ResourceType {
  /**
   * Represents documents owned by current principal within assigned scopes.
   *     * Create Action on either this or Document
   *     resource type, grants user permission to upload documents.
   *     * View/List/Edit action on this resource gives permission to a user to only view/list documents uploaded by them.
   */
  AssignedDocument = 'AssignedDocument',
  /** Access to reports which are available to broker leader with information across assigned underlying accounts. */
  AssignedEnterpriseReports = 'AssignedEnterpriseReports',
  /** A data job, as defined by the Jobs service, assigned to the current user. */
  AssignedJob = 'AssignedJob',
  /** Underwriting Assessment Report - Carrier Section */
  CarrierAssessment = 'CarrierAssessment',
  Casualty = 'Casualty',
  ConsumerAttributes = 'ConsumerAttributes',
  /**  Represents all documents in assigned scopes.  */
  Document = 'Document',
  /** Access to reports which are available to enterprise admins/coordinators with information across all underlying accounts. */
  EnterpriseReports = 'EnterpriseReports',
  /** A data job as defined by the Jobs service. */
  Job = 'Job',
  Loss = 'Loss',
  /** My Properties stream. */
  MpStream = 'MPStream',
  Org = 'Org',
  Policy = 'Policy',
  /** A project as defined by the Jobs service. */
  Project = 'Project',
  Property = 'Property',
  /** Underwriting Assessment Report - Risk Manager Section */
  RiskManagerAssessment = 'RiskManagerAssessment',
  /** Represents all snapshots in assigned scopes. */
  Snapshot = 'Snapshot',
  Stream = 'Stream',
  /**
   * Access to reports which are available for Stream Owners as part of their work on the Stream/Org,
   * e.g. Data Quality and Enrichment.
   */
  StreamOwnerReports = 'StreamOwnerReports',
  /**
   * Access to reports which are available for Stream Viewers as part of their work on the Stream/Org,
   * e.g. Exposure Summary.
   */
  StreamViewerReports = 'StreamViewerReports',
  Submission = 'Submission',
  /** Permissions to see PII data related to users (from any Org) who accessed/viewed the Stream */
  UserAccessData = 'UserAccessData',
  VirtualInspection = 'VirtualInspection'
}

export type RestorePropertiesInput = {
  archipelagoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
};

export type RevertJobInput = {
  jobID: Scalars['ID']['input'];
};

export enum RiskAttribute {
  CatEarthquake = 'CAT_EARTHQUAKE',
  CatFlood = 'CAT_FLOOD',
  CatHail = 'CAT_HAIL',
  CatTornado = 'CAT_TORNADO',
  CatWindstorm = 'CAT_WINDSTORM',
  CustomerAverageLoss = 'CUSTOMER_AVERAGE_LOSS',
  CustomerLossFrequency = 'CUSTOMER_LOSS_FREQUENCY',
  HazardGrade = 'HAZARD_GRADE',
  HotWorkContractorControls = 'HOT_WORK_CONTRACTOR_CONTROLS',
  Housekeeping = 'HOUSEKEEPING',
  IndustryLossExperience = 'INDUSTRY_LOSS_EXPERIENCE',
  MaintenanceProcedures = 'MAINTENANCE_PROCEDURES',
  ManagementPractices = 'MANAGEMENT_PRACTICES',
  PredictiveRiskGrade = 'PREDICTIVE_RISK_GRADE',
  ReRiskGrade = 'RE_RISK_GRADE',
  SafetyTraining = 'SAFETY_TRAINING'
}

export enum Role {
  /** DEPRECATED */
  BrokerLeader = 'BrokerLeader',
  /** A user who has access to all the jobs and can see the jobs assigned to all users. */
  DataManager = 'DataManager',
  /** User with read and write permissions on documents only. */
  DocUploader = 'DocUploader',
  /** TODO */
  Editor = 'Editor',
  /**
   * Grants permission on the Enterprise Org to manage RiskManagers and DocUploaders on the Underlying Orgs.
   * Grants StreamViewer permission on all the underlying orgs.
   */
  EnterpriseAdmin = 'EnterpriseAdmin',
  /**
   * Grants permission on the Enterprise Org to manage DocUploaders on the Underlying Orgs.
   * Grants StreamViewer permission on all the underlying orgs.
   */
  EnterpriseCoordinator = 'EnterpriseCoordinator',
  /** This role inherits the permissions of a Risk Manager and allows view access to user data */
  LeadRiskManager = 'LeadRiskManager',
  /** TODO */
  Leader = 'Leader',
  /**
   * PropertyViewer + ability to view property data.
   * The subset of properites allowed is specified using ResourceIds in the assignment filter.
   */
  PropertyEditor = 'PropertyEditor',
  /**
   * A user who can view and manage documents for specific set of properties in an org. This user
   * cannot edit property data.
   */
  PropertyViewer = 'PropertyViewer',
  /**
   *  This role represents a admin user for a Risk Manager organization. Currently allows document view, updates & uploads to an org.
   * In future this will allow read/write accesss to all streams, properties in the assigned organizations
   */
  RiskManager = 'RiskManager',
  /**
   * A user who has permission to view a stream and its constituent properties and documents.
   * This role can only be assigned at with scope type StreamSlug
   */
  StreamViewer = 'StreamViewer',
  /**
   *  A user who has access to read and update all submissions to a carrier organization.
   * Assigned at scope OrgId scopes and no filters.
   */
  Underwriter = 'Underwriter',
  /** Grants permissions to administer user managers and users who can view streams. */
  UserAdmin = 'UserAdmin',
  /** Grants permissions to manage which users can view streams. */
  UserManager = 'UserManager'
}

export type RoleAssignmentV2 = {
  __typename?: 'RoleAssignmentV2';
  principalId: Scalars['String']['output'];
  principalType: PrincipalType;
  role: Role;
  scopes: Array<Scope>;
};

export type RoleAssignmentsResponse = {
  __typename?: 'RoleAssignmentsResponse';
  assignments?: Maybe<Array<RoleAssignmentV2>>;
  /** Any assigned global legacy role */
  legacyRole?: Maybe<Scalars['String']['output']>;
  /** Org name for this user's primary organization, if assigned. */
  primaryOrgName?: Maybe<Scalars['String']['output']>;
};

export type RoleFilter = {
  __typename?: 'RoleFilter';
  description: Scalars['String']['output'];
  predicates?: Maybe<Array<AttributeFilterOutput>>;
  resourceType: ResourceType;
  resources?: Maybe<Array<Resource>>;
};

export enum RoleFilterEnum {
  DocUploader = 'DocUploader',
  Editor = 'Editor',
  EnterpriseAdmin = 'EnterpriseAdmin',
  EnterpriseCoordinator = 'EnterpriseCoordinator',
  Leader = 'Leader',
  PropertyEditor = 'PropertyEditor',
  RiskManager = 'RiskManager',
  StreamViewer = 'StreamViewer'
}

export type RoleFilterInput = {
  description: Scalars['String']['input'];
  predicates?: InputMaybe<Array<AttributeFilter>>;
  resource: ResourceType;
  /** Support for predicates and/or filters is dependent on the role being assigned. See `#Role` */
  resourceIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**  Rule is used to define one or more conditions to qualify files which belong to a SmartFolder  */
export type Rule = {
  attributeName?: InputMaybe<DocumentAttributeName>;
  /**  This value is ignored for the first rule in a list of rules. */
  combinator?: InputMaybe<Combinator>;
  operator?: InputMaybe<BinaryOperator>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum RuleTypeEnum {
  ChangeRule = 'ChangeRule',
  MappingRule = 'MappingRule',
  ValidateRule = 'ValidateRule'
}

export type Sov = {
  __typename?: 'SOV';
  /** The document ID for the SOV */
  documentID: Scalars['String']['output'];
  /** The state of the SOV ingestion */
  state: SovState;
  /** The last timestamp at which the SOV was updated */
  updatedAt: Scalars['Time']['output'];
  /** The year of the SOV */
  year?: Maybe<Scalars['Int']['output']>;
};

export type SovMapping = {
  __typename?: 'SOVMapping';
  /** The schema attribute name for the mappings */
  attributeName: Scalars['String']['output'];
  /** The columns in the raw SOV that are mapped to the schema attribute */
  sourceColumns?: Maybe<Array<SovSourceColumn>>;
};

export type SovMappingInput = {
  /** The schema attribute name for the mappings */
  attributeName: Scalars['String']['input'];
  /** The columns in the raw SOV that are mapped to the schema attribute */
  sourceColumns?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SovMappingsResponse = {
  __typename?: 'SOVMappingsResponse';
  /** The set of column names in the raw SOV that are empty */
  emptyColumns?: Maybe<Array<SovSourceColumn>>;
  /** The currently applied mappings for the SOV */
  mappings?: Maybe<Array<SovMapping>>;
  /** The set of column names in the raw SOV that we were unable to determine mappings for */
  unmappedColumns?: Maybe<Array<SovSourceColumn>>;
};

export type SovSourceColumn = {
  __typename?: 'SOVSourceColumn';
  /** The alphabetical index of the cell column - for example 'A', 'AB', etc */
  excelColumn: Scalars['String']['output'];
  /** Text in the first header row for the column */
  name: Scalars['String']['output'];
};

export enum SovState {
  /** The SOV has been uploaded, but we were unable to transform it */
  Failed = 'Failed',
  /** The SOV has been uploaded, processed, and properties have been placed in the default stream */
  Ingested = 'Ingested',
  /** The SOV has not yet been uploaded */
  Missing = 'Missing',
  /** The SOV has been uploaded and processed */
  Processed = 'Processed',
  /** The SOV has been received */
  Received = 'Received'
}

/**
 * Assignment scope with resource filters.
 * Example: {"type": "OrgId", "value": "fea8300e-045a-11ec-be21-679a2eb8eb54", "name": "Acme Corp."}
 */
export type Scope = {
  __typename?: 'Scope';
  /**  If true, assignment applies to all values of this scope type & `value` attribute should be ignored.  */
  allValues: Scalars['Boolean']['output'];
  filters?: Maybe<Array<RoleFilter>>;
  /** This value will only be set when ScopeType is OrgGroup. Will contain the IDs of all the members in the group. */
  members?: Maybe<Array<Scalars['String']['output']>>;
  /** Human readable name of the scope. The values would be the name of the org, name of the stream or the name of the group. */
  name?: Maybe<Scalars['String']['output']>;
  type: ScopeType;
  value: Scalars['String']['output'];
};

export type ScopeGroup = {
  __typename?: 'ScopeGroup';
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  members?: Maybe<Array<ScopeGroupMember>>;
  name: Scalars['String']['output'];
  type: ScopeGroupType;
};

export type ScopeGroupMember = {
  __typename?: 'ScopeGroupMember';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ScopeGroupType {
  Org = 'Org'
}

export type ScopeGroupsInput = {
  /** Allows for filtering the list of scope groups. This is the ID of the owner of the ScopeGroup. Which, in most cases, is the Enterprise Org ID. */
  scopeGroupExternalId: Scalars['ID']['input'];
};

export type ScopeInput = {
  type: ScopeType;
  /**  Empty values are not allowed  */
  value: Scalars['String']['input'];
};

export enum ScopeType {
  /** The OrgGroup scope allows granting a role for a principal on a group of org scopes instead of on a single org scope (OrgId). */
  OrgGroup = 'OrgGroup',
  OrgId = 'OrgId',
  StreamSlug = 'StreamSlug'
}

export type Scopes = {
  __typename?: 'Scopes';
  /**
   * Names of scope values at the respective indices in values array.
   * Any orgs or streams which are not found are returned with empty names.
   */
  names: Array<Scalars['String']['output']>;
  type: ScopeType;
  values: Array<Scalars['String']['output']>;
};

export type ScoreCount = {
  __typename?: 'ScoreCount';
  count: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
};

export type ScoreMethodInputIt = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ScoreMethodResultIt = {
  __typename?: 'ScoreMethodResultIT';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum ScoreQuality {
  Ambiguous = 'Ambiguous',
  Evidenced = 'Evidenced',
  Invalid = 'Invalid',
  None = 'None',
  Precise = 'Precise'
}

export type SecondaryModifier = {
  __typename?: 'SecondaryModifier';
  codeName?: Maybe<Scalars['String']['output']>;
  coding?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
};

export type SectionReference = {
  __typename?: 'SectionReference';
  /**
   *  This stores a string used to describe the specific section of the document which was used
   * to source the provenance.
   * For example, this is used by iPad app to indicate that the provenance contains an in-line reference into a document
   * iPad format: {"x": float, "y": float, "width": float, "height": float, "length": int, "content": string
   * "pageNumber": int, "startIndex": int, "extractorVersion": int}
   */
  documentSection?: Maybe<Scalars['String']['output']>;
  provenanceID: Scalars['ID']['output'];
};

export type SelectedGroup = {
  /** Name of a prior snapshot if grouping by New, Ongoing or Disposed for change analysis. */
  changesSince?: InputMaybe<Scalars['String']['input']>;
  /** Current grouping value for selected attribute or snapshot to compare changes (New, Ongoing or Disposed) */
  groupValue: Scalars['String']['input'];
  /** Optional attribute name for grouping or snapshot to compare changes. Only one of them can be used */
  groupedBy?: InputMaybe<Scalars['String']['input']>;
};

export type SendWorkflowEmailInput = {
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  emailType: WorkflowEmailType;
  orgName: Scalars['String']['input'];
};

export type SetAttributeMetadataAutoInput = {
  applyChanges: Scalars['Boolean']['input'];
  applyMode?: InputMaybe<ApplyMode>;
  organizationName: Scalars['String']['input'];
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type SetAttributeMetadataAutoResult = {
  __typename?: 'SetAttributeMetadataAutoResult';
  disabledAttributes: Array<Maybe<OrgAttributeMetadata>>;
  enabledAttributes: Array<Maybe<OrgAttributeMetadata>>;
  updated: Scalars['Boolean']['output'];
};

export type SetAttributeMetadataInput = {
  metadata?: InputMaybe<Array<AttributeMetadataInput>>;
  organizationId: Scalars['String']['input'];
  streamId?: InputMaybe<Scalars['String']['input']>;
};

export type SetFeatureDefaultInput = {
  enable: Scalars['Boolean']['input'];
  featureName: Scalars['String']['input'];
};

export type SetLinkedFilesForPolicyInput = {
  archipelagoPolicyId: Scalars['ID']['input'];
  /**  A list of Document IDs.  */
  docIDs: Array<Scalars['String']['input']>;
};

export type SetLinkedFilesForProgramInput = {
  archipelagoProgramId: Scalars['ID']['input'];
  /**  A list of Document IDs.  */
  docIDs: Array<Scalars['String']['input']>;
};

export type SetOrgPreCheckConfigInput = {
  /** Email domain which determines branding in Pre-Check for the org. */
  brandDomain?: InputMaybe<Scalars['String']['input']>;
  /** Enables org members to access Pre-Check. */
  enablePreCheck: Scalars['Boolean']['input'];
  orgName: Scalars['String']['input'];
};

export type SetOrgPropertyConfigInput = {
  /** Enables org members with edit property permissions to manage losses. */
  enableLosses?: InputMaybe<Scalars['Boolean']['input']>;
  /** The first year losses are recorded for the organization. Used for aggregating losses over time. */
  lossStartYear?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type SetOrgSubmissionConfigInput = {
  /**   Flag to enable/disable the submission dashboard.  */
  enableDashboard: Scalars['Boolean']['input'];
  orgName: Scalars['String']['input'];
  /**  Optional parameter to refresh stream and attribute metadata with based on new template org  */
  refreshFromTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Name of the organization which is going to be the template of this organization  */
  templateOrgName?: InputMaybe<Scalars['String']['input']>;
};

export type SetPrimaryOrgInput = {
  orgId: Scalars['String']['input'];
  principalId: Scalars['String']['input'];
};

export type SetPrimaryOrgOutput = {
  __typename?: 'SetPrimaryOrgOutput';
  ok: Scalars['Boolean']['output'];
};

export type SetPropertiesStatusInput = {
  /**  Optional job id used as a reference for property updates  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  properties: Array<PropertyStatusInput>;
  status: PropertyStatus;
  /**  Optional user id to perform this action on behalf of. If not provided, the current user is used.  */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SetPropertiesStatusResponse = {
  __typename?: 'SetPropertiesStatusResponse';
  /**  Number of properties updated  */
  count: Scalars['Int']['output'];
};

export type SetPropertyRecommendationsGradeInput = {
  grade?: InputMaybe<Scalars['Float']['input']>;
  snapshotName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type SetPropertyRecommendationsGradeResponse = {
  __typename?: 'SetPropertyRecommendationsGradeResponse';
  grade?: Maybe<Scalars['Float']['output']>;
};

export type SharePreCheckInput = {
  invitees: Array<PreCheckShareInvite>;
  orgName: Scalars['String']['input'];
  /**  If streamSlug is not provided, the My Properties stream will be used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type SharePreCheckResponse = {
  __typename?: 'SharePreCheckResponse';
  invitees: Array<PreCheckInvitee>;
};

export type ShareStreamLinkInput = {
  /** The email address of the user to share the stream with. */
  invitedUserId: Scalars['String']['input'];
  /** The slug of the stream to share. */
  streamSlug: Scalars['ID']['input'];
};

export type SimpleStream = {
  __typename?: 'SimpleStream';
  id: Scalars['ID']['output'];
  isMyProperties: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationID: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/**  a smartfolder is a folder whose contents are determined by a set of rules. The rules of a smartfolder are determined on the moment when a smartfolder is created  */
export type SmartFolder = {
  __typename?: 'SmartFolder';
  createdAt: Scalars['Time']['output'];
  /**  name of the user  */
  createdBy?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<DocumentsPage>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  removable: Scalars['Boolean']['output'];
};


/**  a smartfolder is a folder whose contents are determined by a set of rules. The rules of a smartfolder are determined on the moment when a smartfolder is created  */
export type SmartFolderDocumentsArgs = {
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/**  a SmartFolderPage contains a subset of all the smartfolders, and page information to query the next subset of smartfolders  */
export type SmartFolderPage = {
  __typename?: 'SmartFolderPage';
  /**  information about this smartfolders page  */
  pageInfo: PageInfo;
  smartFolders: Array<SmartFolder>;
};

export type Snapshot = {
  __typename?: 'Snapshot';
  bound: Scalars['Boolean']['output'];
  date: Scalars['Time']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Optional date to display instead of the actual date in the UI */
  displayDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  /** If set to false, stream viewers should never have access to the snapshot */
  marketVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  /** ID of a project associated with the snapshot */
  projectID?: Maybe<Scalars['ID']['output']>;
  /** Type of snapshot */
  type: SnapshotType;
  /** If set to false, non admin users should never have access to the snapshot */
  visible: Scalars['Boolean']['output'];
};

export type SnapshotComparator = {
  /** Name of snapshot for comparison. */
  fromSnapshotName: Scalars['String']['input'];
  /** Type of properties needed in result. */
  type: PropertyStatusType;
};

/** Charts comparing a snapshot to the prior bound snapshot. */
export type SnapshotComparison = {
  __typename?: 'SnapshotComparison';
  charts: Array<ComparisonChart>;
  /** Name of prior bound snapshot for comparison */
  priorSnapshotName?: Maybe<Scalars['String']['output']>;
  /** Name of current snapshot used for comparison */
  snapshotName?: Maybe<Scalars['String']['output']>;
};

export type SnapshotConfig = {
  __typename?: 'SnapshotConfig';
  /**  Deprecated. Use snapshot.marketVisible instead. ID of the latest market visible snapshot.  */
  publishedSnapshotId?: Maybe<Scalars['ID']['output']>;
  /**  Deprecated. Use snapshotsInfo instead.  */
  snapshots: Array<Snapshot>;
  snapshotsInfo: Array<SnapshotInfo>;
};

export type SnapshotInfo = {
  __typename?: 'SnapshotInfo';
  /**  Exposure index calculation status and score for the snapshot. Null if the index calculation has not been started.  */
  exposureStatus?: Maybe<ExposureIndexStatus>;
  /**  Property recommendation status for the snapshot  */
  propertyRecommendationStatus?: Maybe<PropertyRecommendationStatusResult>;
  /**  Data quality calculation status and score for the snapshot  */
  qualityStatus: QualitySnapshotScoreStatusResult;
  snapshot: Snapshot;
};

export type SnapshotStateInputIt = {
  propertyCount: Scalars['Int']['input'];
  snapshotID: Scalars['ID']['input'];
  state: QualitySnapshotStatus;
  streamID: Scalars['ID']['input'];
};

export enum SnapshotType {
  /**  Indicates snapshot was created by SOV  */
  Sov = 'SOV',
  /**  Standard snapshot  */
  Standard = 'STANDARD',
  /**  Indicates snapshot was created following a valuation integration run  */
  Valuation = 'VALUATION'
}

export type SoftMapping = {
  __typename?: 'SoftMapping';
  modelSchema: SoftMappingModelSchema;
  secondaryModifier: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum SoftMappingModelSchema {
  Air = 'AIR',
  Arch = 'ARCH',
  Rms = 'RMS'
}

export type Sort = {
  attributeName: Scalars['String']['input'];
  order: SortOrder;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SparkChart = {
  __typename?: 'SparkChart';
  /** Percentage of the total TIV. Total TIV is a SUM of TIV without filters. */
  percentageOfTotalTiv: Scalars['Float']['output'];
  /** Sum of TIV */
  sum: Scalars['Float']['output'];
  /** TIV percentage difference relative to last year */
  tivYoyPctDiff?: Maybe<Scalars['Float']['output']>;
  /** Trend data about total TIV per year */
  trend?: Maybe<Array<TotalTivPerYear>>;
};

export type StartAddLossesJobInput = {
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
};

export type StartAddLossesJobOutput = {
  __typename?: 'StartAddLossesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartEditLossesJobInput = {
  /** List of loss attributes to lock for editing. If not specified, the entire worksheet is editable */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** List of property ArchipelagoIDs which will have attributes locked for editing by this job. */
  propertyArchipelagoIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type StartEditLossesJobOutput = {
  __typename?: 'StartEditLossesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartEditPropertiesJobInput = {
  /**  List of attributes to lock for editing.  */
  attributeNames: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  /**  List of property ArchipelagoIDs which will have attributes locked for editing by this job.  */
  propertyIDs: Array<Scalars['ArchipelagoID']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type StartEditPropertiesJobOutput = {
  __typename?: 'StartEditPropertiesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartOrgChatInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type StartOrgChatResponse = {
  __typename?: 'StartOrgChatResponse';
  roomID: Scalars['String']['output'];
  roomName: Scalars['String']['output'];
};

export type StartTaskChatInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
};

export type StartTaskChatOutput = {
  __typename?: 'StartTaskChatOutput';
  roomID: Scalars['String']['output'];
  roomName: Scalars['String']['output'];
};

export type StartWorkflowInput = {
  /** The priority buckets to enable for the workflow. If not specified, all buckets will be enabled. */
  enabledPriorityBuckets?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
  taskType?: TaskType;
};

export type StateTransition = {
  __typename?: 'StateTransition';
  /**  Any user comments related to this state change.  */
  comment?: Maybe<Scalars['String']['output']>;
  /**  Errors in data input for current job. Contains one item per property attribute.  */
  dataErrors?: Maybe<Array<DataError>>;
  /**  This field is empty until the transition completes  */
  endTime?: Maybe<Scalars['Time']['output']>;
  /** Deprecated. use dataErrors instead  */
  errors?: Maybe<Array<DataError>>;
  /**  This field is calculated based on the number of properties  */
  estimatedCompletionTime?: Maybe<Scalars['Time']['output']>;
  from: JobStatus;
  id: Scalars['ID']['output'];
  /**  Progress state of this transition.  */
  progress: Status;
  startTime: Scalars['Time']['output'];
  summary?: Maybe<PropertyCount>;
  /**  Unexpected system errors while processing a job step. Contains one item per property.  */
  systemErrors?: Maybe<Array<SystemError>>;
  to: JobStatus;
  /**  Indicates property data which might be incorrect for review and updates. Updates are not required for the job to be applied.  */
  warnings?: Maybe<Array<DataError>>;
  /**  Job worksheet document associated with this transition.  */
  worksheet?: Maybe<Worksheet>;
};

export enum Status {
  Completed = 'Completed',
  Failed = 'Failed',
  Started = 'Started'
}

export type Stream = {
  __typename?: 'Stream';
  /** List of all explorer names for this stream. */
  allExplorers: Array<Scalars['String']['output']>;
  /** List of enabled exports. */
  allowedExports?: Maybe<Array<ExportCode>>;
  /** List of enabled reports. */
  allowedReports?: Maybe<Array<ReportCode>>;
  broker?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<Scalars['String']['output']>;
  /** Type with configuration for this stream. Can only be queried and edited by admins. */
  configuration?: Maybe<StreamConfiguration>;
  /** List of configured features. */
  configuredFeatures?: Maybe<Array<ConfiguredFeature>>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  /**
   * Name of the default snapshot the stream will load, depending on the user permission.
   * If the user is an admin, this field should be empty, if not, it should be the latest snapshot
   * that user is allowed to see.
   */
  defaultSnapshot?: Maybe<Scalars['String']['output']>;
  /**
   * Three character code (ISO_4217) to override the currency formatting per organization. E.g. USD, EUR, ...
   * If ZZZ then currencies are rendered as unit-less.
   */
  displayCurrency?: Maybe<Scalars['String']['output']>;
  documentsCount?: Maybe<Scalars['Int']['output']>;
  effectiveDate?: Maybe<Scalars['Int']['output']>;
  /** Deprecated. Use configuredFeatures instead. List of enabled features */
  enabledFeatures?: Maybe<Array<Scalars['String']['output']>>;
  expiryDate?: Maybe<Scalars['Int']['output']>;
  /**  Exposure metrics for the stream. Null if exposure metrics have not been calculated for the stream.  */
  exposure?: Maybe<ExposureMetrics>;
  /** Contains Stream Highlight information in stringified JSON format. */
  highlights?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  /** Indicates if a stream is a My Properties stream. */
  isMyProperties: Scalars['Boolean']['output'];
  isPublic: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  policiesCount?: Maybe<Scalars['Int']['output']>;
  /**  Number of properties in the stream  */
  propertiesCount?: Maybe<Scalars['Int']['output']>;
  /** Customized value for "Last Bound" property status label for this stream. */
  propertyStatusLabel?: Maybe<Scalars['String']['output']>;
  /**  Data quality metrics for the stream. Null if data quality metrics have not been calculated for the stream.  */
  quality?: Maybe<QualityMetrics>;
  reit?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  snapshots?: Maybe<Array<Snapshot>>;
  status?: Maybe<Scalars['String']['output']>;
  /** The “Stock Throughput” flag cannot be checked when My Properties is enabled on a stream and is only available for Placement streams. */
  stockThroughputEnabled: Scalars['Boolean']['output'];
  /** Configuration for this stream that can be queried by all users with access to the stream. */
  streamSettings?: Maybe<StreamSettings>;
  /**  Total insured value of the stream  */
  totalInsuredValue?: Maybe<Scalars['Int']['output']>;
  updateStatus?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
};

export type StreamConfiguration = {
  __typename?: 'StreamConfiguration';
  /** List of reports which can be enabled for this stream */
  allowableReports?: Maybe<Array<ReportCode>>;
  /** List of enabled exports. */
  allowedExports?: Maybe<Array<ExportCode>>;
  /** List of enabled reports. */
  allowedReports?: Maybe<Array<ReportCode>>;
  /** Which view to be opened when opening the Stream page */
  defaultStreamView?: Maybe<Scalars['String']['output']>;
  explorersConfig?: Maybe<Scalars['String']['output']>;
  exportsEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastUpdateNote?: Maybe<Scalars['String']['output']>;
  membershipExpression?: Maybe<Scalars['String']['output']>;
  /** List of emails to send notifications to. */
  notificationEmails?: Maybe<Array<Scalars['String']['output']>>;
  /** Flag that indicates whether or not reports have been enabled for this stream */
  reportsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Allow any user with stream access to see losses if true, otherwise only users with expliciy Loss/View permissions */
  shareLossData?: Maybe<Scalars['Boolean']['output']>;
  /** Organizations with a submission dashboard which this stream is included in */
  submissionDashboardOrgs: Array<OrgInfo>;
};

export type StreamConfigurationInput = {
  allowedExports?: InputMaybe<Array<ExportCode>>;
  allowedReports?: InputMaybe<Array<ReportCode>>;
  defaultStreamView?: InputMaybe<Scalars['String']['input']>;
  explorersConfig?: InputMaybe<Scalars['String']['input']>;
  exportsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  highlights?: InputMaybe<Scalars['String']['input']>;
  lastUpdateNote?: InputMaybe<Scalars['String']['input']>;
  membershipExpression?: InputMaybe<Scalars['String']['input']>;
  notificationEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  propertyStatusLabel?: InputMaybe<Scalars['String']['input']>;
  reportsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Allow any user with stream access to see losses if true, otherwise only users with expliciy Loss/View permissions */
  shareLossData?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Names of dashboard orgs for which a submission should be created for the stream.
   * If an active submission for the stream already exists in a requested org, no new submission is created in that org. The updated list of submission dashboard orgs is returned in StreamConfiguration.submissionDashboardOrgs field.
   */
  submissionDashboardOrgNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StreamGroup = {
  __typename?: 'StreamGroup';
  group?: Maybe<Scalars['String']['output']>;
  stream: Stream;
};

export type StreamInput = {
  broker?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if a stream is a My Properties stream. */
  isMyProperties?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  reit?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  /** The “Stock Throughput” flag cannot be checked when My Properties is enabled on a stream and is only available for Placement streams. */
  stockThroughputEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StreamPermissions = {
  __typename?: 'StreamPermissions';
  /** Returns true if current user has permission to add losses on this stream. */
  canAddLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to add or dispose properties on this stream. */
  canAddProperties: Scalars['Boolean']['output'];
  /** Returns true if user has access to that stream's organization chat room */
  canChat: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to delete any properties on this stream. */
  canDeleteProperties: Scalars['Boolean']['output'];
  /** Returns true if current user as permission to edit all properties on this stream. */
  canEditAllProperties: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to edit any losses on this stream. */
  canEditLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to edit any properties on this stream. */
  canEditProperties: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to export DQD on this stream */
  canExportDQD: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to export losses on this stream */
  canExportLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to export policies on this stream */
  canExportPolicies: Scalars['Boolean']['output'];
  /** DEPRECATED */
  canManageDomainAllowList?: Maybe<Scalars['Boolean']['output']>;
  /** Returns true if user has access to manage snapshots */
  canManageSnapshots: Scalars['Boolean']['output'];
  /** DEPRECATED */
  canManageUserManagers?: Maybe<Scalars['Boolean']['output']>;
  /** DEPRECATED */
  canManageViewers?: Maybe<Scalars['Boolean']['output']>;
  /** Returns true if user has access to share PreCheck */
  canSharePreCheck: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to add documents on this stream */
  canUpload: Scalars['Boolean']['output'];
  /** Returns true if the current user has permission to view losses on this stream */
  canViewLosses: Scalars['Boolean']['output'];
};

export type StreamPropertiesByIdInput = {
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Maximum number of properties to return. Default value is 1000. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Sort order of results. */
  sortBy?: InputMaybe<Array<Sort>>;
  streamID: Scalars['ID']['input'];
};

export type StreamPropertiesPageInput = {
  /** If specified, query will return properties which are new, ongoing or disposed since a snapshot. */
  compare?: InputMaybe<SnapshotComparator>;
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Maximum number of properties to return. Default value is 10. A maximum of 200 may be requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** If not specified, latest visible snapshot is used. */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /** Sort order of results. */
  sortBy?: InputMaybe<Array<Sort>>;
  streamSlug: Scalars['ID']['input'];
};

export type StreamPropertiesPageResponse = {
  __typename?: 'StreamPropertiesPageResponse';
  /**  Attribute locks on properties in the page. Only returned for requester with property edit permissions.  */
  attributeLocks?: Maybe<Array<AttributeLocks>>;
  /**  Comments on properties in the page. Only returned for requester with property edit permissions.  */
  editorComments?: Maybe<Array<EditorComment>>;
  /**  Properties in the page  */
  properties?: Maybe<Array<Property>>;
  totalCount: Scalars['Int']['output'];
};

export type StreamPropertyGroups = {
  __typename?: 'StreamPropertyGroups';
  propertyGroups: Array<PropertyGroup>;
  /** This value is null when there is only one top level element in propertyGroups. */
  stats?: Maybe<PropertyGroupStats>;
};

export type StreamPropertyGroupsResponse = {
  __typename?: 'StreamPropertyGroupsResponse';
  explorers: Array<Scalars['String']['output']>;
  groups: PropertyGroup;
  previousSnapshot?: Maybe<Scalars['String']['output']>;
};

export type StreamPropertyValuationsInput = {
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<ValuationSummaryFilter>>;
  /** Maximum number of properties to return. Default value is 10. A maximum of 200 may be requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Sort order of results. */
  sortBy?: InputMaybe<Array<StreamPropertyValuationsSort>>;
  streamSlug: Scalars['ID']['input'];
};

export type StreamPropertyValuationsResponse = {
  __typename?: 'StreamPropertyValuationsResponse';
  properties: Array<PropertyValuation>;
};

export type StreamPropertyValuationsSort = {
  attribute: PropertyValuationSortAttribute;
  order: SortOrder;
};

export type StreamResponse = {
  __typename?: 'StreamResponse';
  /** Edit in progress by current or other user. */
  lock?: Maybe<PropertyLock>;
  permissions: StreamPermissions;
  stream: Stream;
};

export type StreamScoreInputIt = {
  floodZone: Scalars['Boolean']['input'];
  includeInBenchmarking: Scalars['Boolean']['input'];
  isEnriched: Scalars['Boolean']['input'];
  isMyPropertiesSnapshot: Scalars['Boolean']['input'];
  organizationID: Scalars['ID']['input'];
  score: Scalars['Float']['input'];
  scoreMethodID: Scalars['ID']['input'];
  seismicZone: Scalars['Boolean']['input'];
  snapshotDate: Scalars['String']['input'];
  snapshotID: Scalars['ID']['input'];
  streamID: Scalars['ID']['input'];
  windZone: Scalars['Boolean']['input'];
};

export type StreamScoreResultIt = {
  __typename?: 'StreamScoreResultIT';
  createdAt?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  includeInBenchmarking?: Maybe<Scalars['Boolean']['output']>;
  isEnriched?: Maybe<Scalars['Boolean']['output']>;
  isMyPropertiesSnapshot?: Maybe<Scalars['Boolean']['output']>;
  organizationID?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreMethodID?: Maybe<Scalars['ID']['output']>;
  seismicZone?: Maybe<Scalars['Boolean']['output']>;
  snapshotDate?: Maybe<Scalars['String']['output']>;
  snapshotID?: Maybe<Scalars['ID']['output']>;
  streamID?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['Boolean']['output']>;
};

export type StreamSettings = {
  __typename?: 'StreamSettings';
  /**  The unit of measure setting. imperial or metric.  */
  unitsOfMeasurement?: Maybe<Scalars['String']['output']>;
};

export enum StreamStatus {
  Demo = 'DEMO',
  Effective = 'EFFECTIVE'
}

export type StreamUser = {
  __typename?: 'StreamUser';
  profile?: Maybe<UserProfile>;
  roles?: Maybe<Array<StreamUserRoleAssignment>>;
};

export type StreamUserInfo = {
  __typename?: 'StreamUserInfo';
  /**  True if assigned user has edit permission on stream properties  */
  canEdit: Scalars['Boolean']['output'];
  profile: UserProfile;
  /**  Optional list of property archipelago ids the user can view and edit if a role filter is applied  */
  propertyIDs?: Maybe<Array<Scalars['String']['output']>>;
  /**  Optional list of property archipelago ids the user can view but not edit if a role filter is applied  */
  propertyIDsViewOnly?: Maybe<Array<Scalars['String']['output']>>;
};

export type StreamUserRoleAssignment = {
  __typename?: 'StreamUserRoleAssignment';
  createdAt: Scalars['Time']['output'];
  principalId: Scalars['String']['output'];
  principalType: PrincipalType;
  role: Role;
};

export type StreamUsersPage = {
  __typename?: 'StreamUsersPage';
  /** information about this users page */
  pageInfo: PageInfo;
  streamUsers: Array<Maybe<StreamUser>>;
};

export type StreamValuationSummaryInput = {
  filter?: InputMaybe<Array<ValuationSummaryFilter>>;
  /**  If true, current stream data is returned. Otherwise, data from the most recent valuation snapshot is returned.  */
  latest?: InputMaybe<Scalars['Boolean']['input']>;
  streamSlug: Scalars['ID']['input'];
};

export type StreamValuationSummaryResponse = {
  __typename?: 'StreamValuationSummaryResponse';
  /**  Average replacement cost per floor area  */
  avgReplacementCostPerFloorArea?: Maybe<Scalars['Float']['output']>;
  /**  Average replacement cost per floor area from partner values  */
  avgReplacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  /**
   * Distribution of building replacement cost valuation difference in the stream.
   * Null if partner valuations are not available for the stream.
   */
  brcDistribution?: Maybe<Array<BrcRange>>;
  /**  Total percent difference between partner calculated building replacement cost and the customer provided values  */
  brcPercentDiff?: Maybe<Scalars['Float']['output']>;
  /**  Total difference between partner calculated building replacement cost and the customer provided values  */
  brcValueDiff?: Maybe<Scalars['Float']['output']>;
  /**  Percent difference between partner calculated building replacement cost and customer provided values for properties with a negative difference  */
  negativeBRCPercentDiff?: Maybe<Scalars['Float']['output']>;
  /**  Difference between partner calculated building replacement cost and customer provided values for properties with a negative difference  */
  negativeBRCValueDiff?: Maybe<Scalars['Float']['output']>;
  /**
   * Percent of customer provided BRC which has a partner calculated BRC.
   * Only this percentage of BRC is included in difference aggregations.
   */
  percentOfBRCIncluded: Scalars['Float']['output'];
  /**
   * Percent of properties with a customer provided BRC and a partner calculated BRC.
   * Only this percentage of properties is included in difference aggregations.
   */
  percentOfPropertiesIncluded: Scalars['Float']['output'];
  /**  Percent difference between partner calculated building replacement cost and customer provided values for properties with a positive difference  */
  positiveBRCPercentDiff?: Maybe<Scalars['Float']['output']>;
  /**  Difference between partner calculated building replacement cost and customer provided values for properties with a positive difference  */
  positiveBRCValueDiff?: Maybe<Scalars['Float']['output']>;
  /**  Display date of the latest snapshot  */
  snapshotDisplayDate: Scalars['String']['output'];
  /**  Name of the snapshot  */
  snapshotName: Scalars['String']['output'];
};

export type StreamsResponse = {
  __typename?: 'StreamsResponse';
  lossExportOrgs?: Maybe<Array<OrgInfo>>;
  policyExportOrgs?: Maybe<Array<OrgInfo>>;
  streams?: Maybe<Array<Stream>>;
};

export type Submission = {
  __typename?: 'Submission';
  /**  ID of the submission's account organization  */
  accountOrgId: Scalars['String']['output'];
  /**  Name of the submission's account organization  */
  accountOrgName: Scalars['String']['output'];
  /**  Submission's account org type  */
  accountOrgType?: Maybe<AccountOrgType>;
  accountStatus?: Maybe<Scalars['String']['output']>;
  /** Adjusted number of SLA days. */
  adjustedSLA?: Maybe<Scalars['Int']['output']>;
  /**  Flag indicating if new properties added to this account have attribute transformations (geo, construction...)  */
  archipelagoTransformations: Scalars['Boolean']['output'];
  assumptions?: Maybe<Scalars['String']['output']>;
  broker?: Maybe<Scalars['String']['output']>;
  /**  Primary Producer or Broker Email  */
  brokerEmail?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<Scalars['String']['output']>;
  /**  Internal client ID  */
  clientId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  /** System timestamp of when this submission was created */
  createdAt?: Maybe<Scalars['Time']['output']>;
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  expiryDate?: Maybe<Scalars['Date']['output']>;
  /**  Current step of the finalization process if it's ongoing  */
  finalizationCheckpoint?: Maybe<SubmissionFinalizationCheckpoint>;
  /**  Current finalization checkpoint status if it's ongoing  */
  finalizationStatus?: Maybe<SubmissionFinalizationStatus>;
  /**  Flag indicating if the submission can be safely updated to Submitted or Processing Complete  */
  finalized: Scalars['Boolean']['output'];
  /** Ground Up / Shared & Layered value */
  gusl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** SIC Code value of the industry */
  industry?: Maybe<Scalars['String']['output']>;
  /**  Internal Archipelago notes  */
  internalNotes?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /**  Name of the submission dashboard organization  */
  orgName: Scalars['String']['output'];
  /**  ID of the submission dashboard organization  */
  organizationId: Scalars['String']['output'];
  /** Properties count of the stream, as of the last state change to Submitted. */
  propertiesCount?: Maybe<Scalars['Int']['output']>;
  /** Number of external QA issues (found by CNA UWs/UTs). */
  qaIssuesExternalCount?: Maybe<Scalars['Int']['output']>;
  quoteDueDate?: Maybe<Scalars['Date']['output']>;
  /** Reason for SLA day adjustment. */
  reasonForAdjustment?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  /** Original number of SLA days. */
  sla?: Maybe<Scalars['Int']['output']>;
  stream?: Maybe<Stream>;
  submissionType?: Maybe<SubmissionType>;
  /**  Email of user who created the submission  */
  submitterEmail?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  /**  Account team member emails. Members are automatically converted to account admins  */
  teamMemberEmails?: Maybe<Array<Scalars['String']['output']>>;
  /** TIV of the stream, as of the last state change to Submitted. */
  totalInsuredValue?: Maybe<Scalars['Int']['output']>;
  underwriter?: Maybe<Scalars['String']['output']>;
  underwriterEmail?: Maybe<Scalars['String']['output']>;
  underwriterTechnician?: Maybe<Scalars['String']['output']>;
  underwriterTechnicianEmail?: Maybe<Scalars['String']['output']>;
  /** Customer feedback request */
  uwFeedbackRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type SubmissionAttributeEnum = {
  __typename?: 'SubmissionAttributeEnum';
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type SubmissionAttributeMetadata = {
  __typename?: 'SubmissionAttributeMetadata';
  /**  True if the attribute is derived from another one. If so, it cannot be required in account settings  */
  derived: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  /**  If true, attribute will be visible in submission dashboard  */
  enabled: Scalars['Boolean']['output'];
  /**  If true, enum values can be configured  */
  enumEditable: Scalars['Boolean']['output'];
  /**  List of possible submission enum values. Only relevant if type is Enum  */
  enumValues?: Maybe<Array<SubmissionAttributeEnum>>;
  /**  Optional group name to use for configuration UI  */
  group?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /**  If true, attribute will render as a mandatory field in new submission forms   */
  required: Scalars['Boolean']['output'];
  type: SubmissionAttributeType;
};

export type SubmissionAttributeMetadataConfig = {
  /**  If true, attribute will be visible in submission dashboard  */
  enabled: Scalars['Boolean']['input'];
  /**  List of possible submission enum values. Only relevant if type is Enum  */
  enumValues?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  /**  If true, attribute will render as a mandatory field in new submission forms   */
  required: Scalars['Boolean']['input'];
};

export type SubmissionAttributeMetadataInput = {
  /**  If true, all attributes and enum values will be returned, including the ones that are disabled */
  configMode?: Scalars['Boolean']['input'];
  orgName: Scalars['String']['input'];
};

export enum SubmissionAttributeType {
  /**  Numbers representing an ammount of money, like total insured value   */
  Currency = 'Currency',
  /**  Numbers with decimals  */
  Decimal = 'Decimal',
  /**  Attributes that have a specific set of possible values  */
  Enum = 'Enum',
  /**  Basic text values like names, descriptions...  */
  Text = 'Text'
}

export enum SubmissionFinalizationCheckpoint {
  Complete = 'Complete',
  CreateSnapshot = 'CreateSnapshot',
  None = 'None',
  UpdateReportingViews = 'UpdateReportingViews',
  UpdateSubmissionStatus = 'UpdateSubmissionStatus',
  UpdateSubmitterPermissions = 'UpdateSubmitterPermissions'
}

export enum SubmissionFinalizationStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  Timeout = 'Timeout'
}

export type SubmissionInput = {
  accountOrgType?: InputMaybe<AccountOrgType>;
  accountStatus?: InputMaybe<Scalars['String']['input']>;
  /** Adjusted number of SLA days. */
  adjustedSLA?: InputMaybe<Scalars['Int']['input']>;
  /**  Flag indicating if new properties added to this account have attribute transformations (geo, construction...)  */
  archipelagoTransformations: Scalars['Boolean']['input'];
  assumptions?: InputMaybe<Scalars['String']['input']>;
  broker?: InputMaybe<Scalars['String']['input']>;
  brokerEmail?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  /**  Internal client ID  */
  clientId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  gusl?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  /**  Internal Archipelago notes  */
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  /** The first year losses are recorded for the organization. Used for aggregating losses over time. */
  lossStartYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** To track the number of external QA issues (found by CNA UWs/UTs). */
  qaIssuesExternalCount?: InputMaybe<Scalars['Int']['input']>;
  quoteDueDate?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for SLA day adjustment. */
  reasonForAdjustment?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  /** To track the number SLA days. */
  sla?: InputMaybe<Scalars['Int']['input']>;
  submissionType?: InputMaybe<SubmissionType>;
  team?: InputMaybe<Scalars['String']['input']>;
  /**  Account team member emails. Members are automatically converted to account admins  */
  teamMemberEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  underwriter?: InputMaybe<Scalars['String']['input']>;
  underwriterEmail?: InputMaybe<Scalars['String']['input']>;
  underwriterTechnician?: InputMaybe<Scalars['String']['input']>;
  underwriterTechnicianEmail?: InputMaybe<Scalars['String']['input']>;
  /** Customer feedback request */
  uwFeedbackRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubmissionRegion = {
  __typename?: 'SubmissionRegion';
  name: Scalars['String']['output'];
  teams: Array<Scalars['String']['output']>;
};

export type SubmissionRegionsInput = {
  orgName: Scalars['String']['input'];
};

export type SubmissionRegionsResponse = {
  __typename?: 'SubmissionRegionsResponse';
  regions: Array<SubmissionRegion>;
};

export type SubmissionRegionsWorksheetInput = {
  orgName: Scalars['String']['input'];
};

export type SubmissionRegionsWorksheetResponse = {
  __typename?: 'SubmissionRegionsWorksheetResponse';
  worksheet: Worksheet;
};

export enum SubmissionType {
  New = 'New',
  Renewal = 'Renewal'
}

export type SubmissionsPage = {
  __typename?: 'SubmissionsPage';
  pageInfo: PageInfo;
  submissions?: Maybe<Array<Submission>>;
};

export type SubmissionsPageInput = {
  /**
   * Deprecated. Use offset and pageSize.
   * Cursor is used for pagination. Will return the next page with results.
   * If left empty we start at the beginning.
   */
  cursor?: InputMaybe<Scalars['String']['input']>;
  /** Filter criteria. Will be ignored when a cursor is provided. */
  filters?: InputMaybe<Array<MultipleAttributeFilter>>;
  /**
   * If true, only the latest submission for each account organization is returned.
   * Otherwise all submissions are returned.
   */
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** For admins only. Filters submissions for the specified organization. */
  orgName?: InputMaybe<Scalars['String']['input']>;
  /** Number of submissions to fetch. Will be ignored when a cursor is provided. */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Sorting criteria.  Will be ignored when a cursor is provided.
   * Results are sorted by the latest submission in each account org according to the criteria provided.
   * If no sorting criteria is provided, results are sorted by effective date.
   */
  sortBy?: InputMaybe<Array<Sort>>;
};

export type SubmitAddLossesJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  /** Worksheet containing new losses. */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitAddLossesJobOutput = {
  __typename?: 'SubmitAddLossesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitEditLossesJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  /** Worksheet containing edits. */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitEditLossesJobOutput = {
  __typename?: 'SubmitEditLossesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitEditPropertiesJobInput = {
  jobID: Scalars['String']['input'];
  /**  Worksheet containing edits.  */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitEditPropertiesJobOutput = {
  __typename?: 'SubmitEditPropertiesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /**  Deprecated. Used by VI jobs to indicate list of doc types to include in followup email template.   */
  followUpDocClassifications?: InputMaybe<Array<Scalars['String']['input']>>;
  jobID: Scalars['String']['input'];
  /**  Required for edit property jobs. New values for partial property update  */
  propertyData?: InputMaybe<PropertyDataInput>;
  /**
   * Required for Enrichment & Ingest jobs. For ColumnMapping job, if not specified the service
   *   confirms that skeletonSov from updated mapping is already created for review.
   */
  worksheet?: InputMaybe<Scalars['Upload']['input']>;
};

export type SuggestionInput = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  orgName: Scalars['String']['input'];
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SuggestionStatus>;
};

/**  SuggestionStatus is the status of suggestion */
export enum SuggestionStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Suggested = 'SUGGESTED'
}

export type SuggestionUpdateInput = {
  /**  Optional comment that can be used to tell why its rejected  */
  comment?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  /**  Sets the status of the suggestion. If ACCEPTED it will update the document */
  status: SuggestionStatus;
  suggestionId: Scalars['ID']['input'];
};

export type SupportTicket = {
  __typename?: 'SupportTicket';
  assigneeEmails?: Maybe<Array<Scalars['String']['output']>>;
  authorEmail: Scalars['String']['output'];
  chatRoomID: Scalars['String']['output'];
  chatRoomName: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  followerEmails?: Maybe<Array<Scalars['String']['output']>>;
  org?: Maybe<OrgInfo>;
  priority: TicketPriority;
  status: TicketStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Time']['output']>;
};

export type SupportTicketInput = {
  id: Scalars['String']['input'];
};

export type SupportTicketOutput = {
  __typename?: 'SupportTicketOutput';
  ticket: SupportTicket;
};

export type SupportTicketsPageInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  /**  Deprecated. Use filter instead  */
  includeClosedTickets?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type SupportTicketsPageOutput = {
  __typename?: 'SupportTicketsPageOutput';
  pageInfo: PageInfo;
  tickets?: Maybe<Array<SupportTicket>>;
};

export type SyncSecondaryModifierInput = {
  attributes?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
};

export type SystemAttributeMapper = {
  __typename?: 'SystemAttributeMapper';
  mapper: Mapper;
  outputType: MapperOutputType;
};

export type SystemError = {
  __typename?: 'SystemError';
  /**  Archipelago ID when available  */
  archipelagoID?: Maybe<Scalars['String']['output']>;
  /**  location/client ID when available  */
  locationID?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  /**  Opaque reference id for Archipelago support.  */
  referenceID?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  rowNumber?: Maybe<Scalars['Int']['output']>;
  /**  For worksheet based input  */
  sheetName?: Maybe<Scalars['String']['output']>;
};

/**  Array of TIV values with an optional average  */
export type TivChart = Chart & {
  __typename?: 'TIVChart';
  average?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  values: Array<TivStat>;
};

/**  Chart containing multiple TIV charts  */
export type TivCharts = Chart & {
  __typename?: 'TIVCharts';
  charts: Array<TivChart>;
  name: Scalars['String']['output'];
};

export type TivStat = {
  __typename?: 'TIVStat';
  label: Scalars['String']['output'];
  pct: Scalars['Float']['output'];
  tiv: Scalars['Float']['output'];
};

export type TaskAssignInput = {
  assignee: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

export type TaskCompleteInput = {
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  completionData: Scalars['String']['input'];
  customerProvidedGeocode?: InputMaybe<Scalars['Boolean']['input']>;
  locationID?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  propertyArchipelagoID?: InputMaybe<Scalars['String']['input']>;
  propertyData?: InputMaybe<PropertyDataInput>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
};

export type TaskCompleteV2Input = {
  orgName: Scalars['String']['input'];
  propertiesData?: InputMaybe<Array<TaskPropertiesDataInput>>;
  taskId: Scalars['String']['input'];
};

export type TaskCompleteV3Input = {
  orgName: Scalars['String']['input'];
  propertiesData?: InputMaybe<Array<TaskPropertiesDataInput>>;
  taskId: Scalars['String']['input'];
};

export type TaskPropertiesDataInput = {
  archipelagoId: Scalars['String']['input'];
  customerProvidedGeocode?: InputMaybe<Scalars['Boolean']['input']>;
  propertyData: PropertyDataInput;
};

export type TaskSearchInput = {
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  assignee?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  processInstanceKey?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<TaskState>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
  taskVariables?: InputMaybe<Array<TaskVariable>>;
};

export type TaskSearchResponse = {
  __typename?: 'TaskSearchResponse';
  archipelagoIDs?: Maybe<Array<Scalars['String']['output']>>;
  assignee?: Maybe<Scalars['String']['output']>;
  completionDate?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  followUpDate?: Maybe<Scalars['String']['output']>;
  formKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFirst: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<UserProfile>;
  property?: Maybe<Property>;
  sortValues?: Maybe<Array<Scalars['String']['output']>>;
  taskState: TaskState;
  taskType?: Maybe<TaskType>;
};

export enum TaskState {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED'
}

export enum TaskType {
  /** Workflow started by Pre-Check where each task allows the user to edit multiple (bucketed) properties. The properties are grouped (bucketed) by the Pre-Check recommendations engine, by their priority. */
  PrecheckRecommendations = 'PRECHECK_RECOMMENDATIONS',
  /** Starts a workflow where each task allows the user to edit a single property. */
  SinglePropertyEdit = 'SINGLE_PROPERTY_EDIT'
}

export type TaskUnAssignInput = {
  taskId: Scalars['String']['input'];
};

export type TaskVariable = {
  name: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TaskVariableSearchInput = {
  taskId: Scalars['String']['input'];
  variableNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TaskVariableSearchResponse = {
  __typename?: 'TaskVariableSearchResponse';
  /**  Deprecated. Use chatRoomInfo instead  */
  chatRoomID?: Maybe<Scalars['String']['output']>;
  /**  chat room information if available  */
  chatRoomInfo?: Maybe<ChatRoomInfo>;
  chatRoomName?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<Draft>;
  id: Scalars['ID']['output'];
  isValueTruncated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  previewValue: Scalars['String']['output'];
  properties?: Maybe<Array<Property>>;
  taskType?: Maybe<TaskType>;
  value: Scalars['String']['output'];
};

export enum TicketPriority {
  High = 'High',
  Highest = 'Highest',
  Low = 'Low',
  Lowest = 'Lowest',
  Medium = 'Medium',
  None = 'None'
}

export enum TicketStatus {
  Closed = 'Closed',
  Open = 'Open',
  Resolved = 'Resolved'
}

export type TotalTivPerYear = {
  __typename?: 'TotalTivPerYear';
  sum?: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type TrackEventInput = {
  name: Scalars['String']['input'];
  payload: Scalars['String']['input'];
};

export type TrendValue = {
  __typename?: 'TrendValue';
  value?: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type UnderlyingOrgStreamsInput = {
  organizationID: Scalars['ID']['input'];
};

export type UnderlyingOrgStreamsResponse = {
  __typename?: 'UnderlyingOrgStreamsResponse';
  streams: Array<SimpleStream>;
};

export type UnderlyingOrgsInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orgName: Scalars['ID']['input'];
  /** Search for account name. Default sorted ASCENDING by account name. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UnderlyingOrgsResult = {
  __typename?: 'UnderlyingOrgsResult';
  organizations?: Maybe<Array<Organization>>;
  totalCount: Scalars['Int']['output'];
};

export type UntypedOrgInfo = {
  __typename?: 'UntypedOrgInfo';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Any']['output']>;
};

export type UntypedPropertyAttribute = {
  __typename?: 'UntypedPropertyAttribute';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['Any']['output']>;
};

export type UntypedPropertyRow = {
  __typename?: 'UntypedPropertyRow';
  archipelagoId?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<UntypedPropertyAttribute>>;
  propertyId: Scalars['ID']['output'];
  provenance?: Maybe<Array<Provenance>>;
  revisionId?: Maybe<Scalars['String']['output']>;
  sovFileId?: Maybe<Scalars['String']['output']>;
  sovRowNumber?: Maybe<Scalars['Int']['output']>;
  sovSheetName?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<UserProfile>;
};

export type UntypedPropertyRowsPage = {
  __typename?: 'UntypedPropertyRowsPage';
  /** Opaque cursor to fetch the next page of properties in a subsequent call. */
  cursor?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Array<UntypedPropertyRow>>;
};

export type UpdateAttributeMetadataInput = {
  metadata: AttributeMetadataInput;
  organizationId: Scalars['String']['input'];
  streamId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCasualtyInput = {
  id: Scalars['ID']['input'];
  /** Allow switching from "Submitted" to "ProcessingComplete". Only for admins. */
  state: CasualtyState;
};

export type UpdateDocumentInput = {
  classification?: InputMaybe<Scalars['String']['input']>;
  /**  Set document to unknown classification state  */
  classificationUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  documentID: Scalars['ID']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<FileType>;
  /**  Set document to linked to unknown property state  */
  linkedToUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDocumentSectionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  documentLinkId: Scalars['ID']['input'];
  /**  see DocumentLink#documentSection  */
  documentSection?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEditorCommentInput = {
  /**  Comment ID  */
  id: Scalars['String']['input'];
  propertyArchipelagoID: Scalars['String']['input'];
  /**  New status. Can only be updated from OPEN to ARCHIVED.  */
  status?: InputMaybe<EditorCommentStatus>;
  streamSlug: Scalars['String']['input'];
};

export type UpdateEditorCommentResponse = {
  __typename?: 'UpdateEditorCommentResponse';
  comment: EditorComment;
};

export type UpdateExportConfigurationInput = {
  exportConfiguration?: InputMaybe<CreateExportConfigurationInput>;
  id: Scalars['ID']['input'];
};

export type UpdateJobInput = {
  /**  Attributes to be enriched as part of this job.  */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  doerUserID?: InputMaybe<Scalars['String']['input']>;
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Optional increment expire in hours. If specified, system adds the specified number of hours,
   * to job's current expiry time (see CreateJobInput).
   */
  incrementExpireInHours?: InputMaybe<Scalars['Int']['input']>;
  jobID: Scalars['ID']['input'];
  priority?: InputMaybe<JobPriority>;
  references?: InputMaybe<Array<Scalars['String']['input']>>;
  requesterUserID?: InputMaybe<Scalars['String']['input']>;
  reviewerUserID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
};

export type UpdateOrgInput = {
  /**
   * List of domains that are associated to this organization. Users that signup with a domain
   * that matches a domain in this array will automatically have the organization_id configured in
   * their app_metadata.
   */
  autoDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  /**
   * Three character code (ISO_4217) to override the currency formatting per organization. E.g. USD, EUR, ...
   * If ZZZ then currencies are rendered as unit-less.
   */
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  /** Enables org members to view the Carrier Section of the Underwriting Assessment Report within permitted streams. */
  enableCarrierAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to turn the currency conversion on or off on an organization. */
  enableCurrencyConversion?: InputMaybe<Scalars['Boolean']['input']>;
  /** DEPRECATED. */
  enablePropertyManagerEdit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Default false with the exception of prospect orgs registered through hubspot. */
  enableRedirectOnSOVUpload?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enables org members to view the Risk Manager Section of the Underwriting Assessment Report within permitted streams. */
  enableRiskManagerAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable access to SOV Manager. */
  enableSOVManager?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable secondary modifiers in the property modal. */
  enableSecondaryModifiers?: Scalars['Boolean']['input'];
  /** If set to false, support won't receive emails from sov uploads. Default false with the exception of prospect orgs registered through hubspot. */
  enableSendEmailOnSOVUpload?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable the stream dashboard. */
  enableStreamDashboard?: Scalars['Boolean']['input'];
  /** Flag to enable/disable SubValues for all segments of the organization. */
  enableSubValues?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, createSubmission mutation will assign creator as StreamViewer instead of RiskManager */
  enableUnderwriterEdit?: InputMaybe<Scalars['Boolean']['input']>;
  /**  flag to enable/disable the upload portal  */
  enableUploadPortal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to enable/disable virtual inspections on properties. */
  enableVirtualInspections?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to exclude this organization from the reporting. When true data from this organization will not be included in the reports. */
  excludeFromReporting: Scalars['Boolean']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  orgID: Scalars['ID']['input'];
  orgName: Scalars['ID']['input'];
  /**
   * System org type. OrgType will be inferred if reportingType is specified, except if reportingType is Internal.
   * If reportingType is Internal then this is required and must be Test, Demo, Template, or SystemDefaults.
   */
  orgType?: InputMaybe<OrgType>;
  /**
   * Reporting type for the organization.
   * If it is changed to Internal then orgType must also be specified. Otherwise the new orgType will be inferred.
   */
  reportingType?: InputMaybe<OrgReportingType>;
  /**  Sales channel name  */
  salesChannel?: InputMaybe<Scalars['String']['input']>;
  /**  ID of the Salesforce account associated with this organization  */
  salesforceAccountID?: InputMaybe<Scalars['String']['input']>;
  /** Flag to show or hide non-enrichment documents */
  showNonEnrichmentDocs?: InputMaybe<Scalars['Boolean']['input']>;
  unitOfMeasurement?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInput = {
  currentName: Scalars['String']['input'];
  /**  UTC date on which this project is due. Value format as YYYY-MM-DD  */
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Effective date, in UTC, for all property changes in this project. Value format as YYYY-MM-DD
   *       If not specified, the change timeframe is assumed to be current.
   */
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  newName?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type UpdatePropertyMembershipInput = {
  orgID: Scalars['ID']['input'];
  propertyID: Scalars['ID']['input'];
};

export type UpdatePropertySubValuesDataInput = {
  orgName: Scalars['String']['input'];
  /**
   * Boolean to define mapping between All Other Contents & inventory
   * setAllOtherContents = true, the value will go to setAllOtherContents.
   * Otherwise Contents Replacement Cost will go to Inventory
   */
  setAllOtherContents: Scalars['Boolean']['input'];
};

export type UpdateProvenanceInput = {
  /** Names of attributes for which provenance update should be updated */
  provenanceAttributes: Array<Scalars['String']['input']>;
  provenanceDocumentId: Scalars['String']['input'];
};

export type UpdateSovMappingsInput = {
  /** The document ID for the SOV to be updated */
  documentID: Scalars['ID']['input'];
  /** The new column mappings for the SOV */
  mappings?: InputMaybe<Array<SovMappingInput>>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The organization for the SOV to be updated. At least one of organizationID or orgName must be specified
   *   If both are, organizationID will be used
   */
  organizationID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSchemaInput = {
  attributeName: Scalars['String']['input'];
  /** Deprecated */
  dataType?: InputMaybe<Scalars['String']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  /** Deprecated */
  displayWidth?: InputMaybe<Scalars['Int']['input']>;
  maxThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSnapshotConfigInput = {
  bound: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  displayDate?: InputMaybe<Scalars['Time']['input']>;
  /**  Will not be changed if not set  */
  marketVisible?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  snapshotId: Scalars['ID']['input'];
  streamSlug: Scalars['ID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type UpdateStockThroughputCalculationDataInput = {
  orgName: Scalars['String']['input'];
};

export type UpdateStreamInput = {
  configuration: StreamConfigurationInput;
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  stream: StreamInput;
};

export type UpdateStreamMembershipAndReportingViewInput = {
  /**  execute asynchronously if async is true  */
  async: Scalars['Boolean']['input'];
  jobID: Scalars['ID']['input'];
  orgName: Scalars['ID']['input'];
  updateReportingViews: Scalars['Boolean']['input'];
};

export type UpdateStreamMembershipReportDataInput = {
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  updatedStreamCount: Scalars['Int']['input'];
};

export type UpdateSubmissionAttributeMetadataInput = {
  attributes: Array<SubmissionAttributeMetadataConfig>;
  orgName: Scalars['String']['input'];
};

export type UpdateSubmissionInput = {
  id: Scalars['ID']['input'];
  submission: SubmissionInput;
};

export type UpdateSubmissionStreamInput = {
  /** If empty then the stream is removed from the submission */
  customerStreamSlug?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateSupportTicketInput = {
  assigneeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  chatRoomID: Scalars['String']['input'];
  followerEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  priority: TicketPriority;
  status: TicketStatus;
};

export type UpdateSupportTicketOutput = {
  __typename?: 'UpdateSupportTicketOutput';
  ticket: SupportTicket;
};

export type UpdateValidateLabelInput = {
  id: Scalars['ID']['input'];
  label: ValidateLabelInput;
};

export type UpdateValidateRuleInput = {
  id: Scalars['ID']['input'];
  rule: ValidateRuleInput;
};

export type UpdateValidateRuleOverrideInput = {
  overrideId: Scalars['ID']['input'];
  rule: ValidateRuleInput;
};

/** The `UploadDocument` type, represents the request for uploading a document with certain payload. */
export type UploadDocument = {
  document: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type UploadFinishedInput = {
  /**  Set the classification directly. This will allow you to directly set the classification, EG Library  */
  classification?: InputMaybe<Scalars['String']['input']>;
  documentID: Scalars['ID']['input'];
  /**  Boolean specific to uploading a picture directly to a property from the property modal since this also generates provenance */
  isPropertyImage?: Scalars['Boolean']['input'];
  /**  Link to property based on archipelagoID, This will allow you to directly link it to 1 property, EG Library.  */
  propertyID?: InputMaybe<Scalars['ID']['input']>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type UploadJobDocumentInput = {
  classification?: InputMaybe<Scalars['String']['input']>;
  document: Scalars['Upload']['input'];
  orgID: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  documentID: Scalars['ID']['output'];
  filename: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type UploadUrlInput = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type UserChatInfo = {
  __typename?: 'UserChatInfo';
  /**  auth token to use for client chat  */
  token: Scalars['String']['output'];
  /**  chat user id used for login with token  */
  userID: Scalars['String']['output'];
};

export type UserFilter = {
  emailSubstring?: InputMaybe<Scalars['String']['input']>;
  familyNameSubstring?: InputMaybe<Scalars['String']['input']>;
  firstNameSubstring?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

export type UserPage = {
  __typename?: 'UserPage';
  /** information about this users page */
  pageInfo: PageInfo;
  users: Array<Maybe<UserSystemInfo>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  active: Scalars['Boolean']['output'];
  /** Free-form name of the company the user works for */
  companyName?: Maybe<Scalars['String']['output']>;
  email: Scalars['ID']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['Time']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pictureURL?: Maybe<Scalars['String']['output']>;
  /** Free-form job title in the company the user works for */
  title?: Maybe<Scalars['String']['output']>;
  userCode?: Maybe<Scalars['String']['output']>;
  userID: Scalars['ID']['output'];
};

export type UserRole = {
  __typename?: 'UserRole';
  profile: UserProfile;
  role: Role;
};

export type UserRolesResponse = {
  __typename?: 'UserRolesResponse';
  /** Will be true when the lastLogin was within 30 days ago. */
  active: Scalars['Boolean']['output'];
  /**
   * Timestamp from the last time the user has logged in. Will be empty if the user has never logged
   * in before.
   */
  lastLogin?: Maybe<Scalars['Time']['output']>;
  roles?: Maybe<Array<RoleAssignmentV2>>;
  /** The ID of the user in Auth0. Will be empty when the user has never logged in. */
  userId: Scalars['String']['output'];
};

export type UserStreamFilter = {
  emailSubstring?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

export type UserSystemInfo = {
  __typename?: 'UserSystemInfo';
  /**
   * Deprecated. Use userChatInfo instead.
   * auth token to use for client chat
   */
  chatToken?: Maybe<Scalars['String']['output']>;
  /**
   * Deprecated. Use userChatInfo instead.
   * chat user id used for login with token
   */
  chatUserID?: Maybe<Scalars['String']['output']>;
  consentGiven: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  /** user permissions across all organizations */
  globalPermissions: GlobalPermissions;
  intent?: Maybe<Scalars['String']['output']>;
  /**
   * Deprecated. Use permissions on orgInfo response instead.
   * Resources which current user has permission to manage. Only applicable to non-admin users.
   * For admin users (globalPermissions.admin), this returns an empty array. Admin users are implied to have all permissions on all orgs.
   */
  managedOrgs?: Maybe<Array<ManagedOrgResources>>;
  organization?: Maybe<Organization>;
  profile: UserProfile;
  userId: Scalars['String']['output'];
  viewedHighlights?: Maybe<Array<Maybe<ViewedHighlight>>>;
};

export type UsersByRolesInput = {
  /** Set to true to include admins. */
  includeAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  organizationID: Scalars['ID']['input'];
  roles: Array<Role>;
};

export type UsersByRolesResponse = {
  __typename?: 'UsersByRolesResponse';
  adminUsers?: Maybe<Array<UserProfile>>;
  nonAdminUsers?: Maybe<Array<UserRole>>;
};

export type ValidEnumValues = {
  __typename?: 'ValidEnumValues';
  attributeName: Scalars['String']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type ValidateAggregateError = {
  __typename?: 'ValidateAggregateError';
  /**  Actual score of the aggregate check  */
  actualScore?: Maybe<Scalars['Int']['output']>;
  /** The human readable description of the validation rule for presentation to the user */
  desc?: Maybe<Scalars['String']['output']>;
  /**  Desired score of the aggregate check  */
  desiredScore?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<ValidateError>>;
  /** The name of the check  */
  name?: Maybe<Scalars['String']['output']>;
  /** The severity of the validation error */
  severity?: Maybe<ValidateErrorSeverity>;
  /** The type of validation error */
  type?: Maybe<ValidateErrorType>;
};

export type ValidateChangeByPropertyInput = {
  /**  Archipelago ID  */
  archipelagoID: Scalars['ID']['input'];
  /** The "from" snapshot name to compare the property against. Leave empty to use last bound  */
  fromSnapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
  /**  The snapshot name to compare the property against. Leave empty to use latest  */
  toSnapshotName?: InputMaybe<Scalars['String']['input']>;
  triggeredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateChangeByStreamInput = {
  /** The "from" snapshot name to compare the property against. Leave empty to use last bound  */
  fromSnapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
  /**  The snapshot name to compare the property against. Leave empty to use latest  */
  toSnapshotName?: InputMaybe<Scalars['String']['input']>;
  triggeredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateChangeOutput = {
  __typename?: 'ValidateChangeOutput';
  /** If the outputValue is the result of a mathematical expression, it can be compared to a threshold */
  aboveThreshold?: Maybe<Scalars['Boolean']['output']>;
  assert: Scalars['String']['output'];
  /** The human readable description of the validation rule for presentation to the user */
  desc?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<ChangeField>>;
  outputValue?: Maybe<Scalars['Any']['output']>;
  ruleId: Scalars['Int']['output'];
  scope?: Maybe<ValidateRuleScope>;
  /** The severity of the validation error */
  severity?: Maybe<ValidateErrorSeverity>;
  /** The type of validation error */
  type?: Maybe<ValidateErrorType>;
};

export type ValidateChangeReportOutput = {
  __typename?: 'ValidateChangeReportOutput';
  /**  Signed URL to download the report  */
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

export type ValidateChangeResult = {
  __typename?: 'ValidateChangeResult';
  archipelagoID: Scalars['ID']['output'];
  outputs?: Maybe<Array<ValidateChangeOutput>>;
  snapshotFrom: Scalars['String']['output'];
  snapshotTo: Scalars['String']['output'];
};

export type ValidateEnumValuesInput = {
  enumValues: Array<EnumValueInput>;
  orgName: Scalars['String']['input'];
  /** for invalid enum value, if returnValidEnumValues is true, returns valid enum values */
  returnValidEnumValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateEnumValuesResponse = {
  __typename?: 'ValidateEnumValuesResponse';
  /** errorMessage is nil if input enum values are valid */
  errorMessage?: Maybe<Scalars['String']['output']>;
  validEnumValues?: Maybe<Array<ValidEnumValues>>;
};

export type ValidateError = {
  __typename?: 'ValidateError';
  /** The assert expression that raised the error */
  assert: Scalars['String']['output'];
  /** The category of the rule */
  category?: Maybe<ValidateRuleCategory>;
  /** The human readable description of the validation rule for presentation to the user */
  desc?: Maybe<Scalars['String']['output']>;
  /** The list of fields used by the validation rule that raised the error */
  fields?: Maybe<Array<Field>>;
  /** The filter expression for the rule that raised the error */
  filter?: Maybe<Scalars['String']['output']>;
  /** The label attached to the rule, if it has one */
  labels?: Maybe<Array<Maybe<ValidateLabel>>>;
  /** DEPRECATED: USE propertyArchipelagoId INSTEAD- The property on which the validation rule failed */
  property?: Maybe<Property>;
  /** The Archipelago ID of the property for the validation rule that failed */
  propertyArchipelagoId?: Maybe<Scalars['ID']['output']>;
  /** The locationId of the property for the validation rule that failed */
  propertyLocationId?: Maybe<Scalars['String']['output']>;
  /** The integer id for the rule that failed */
  ruleId: Scalars['Int']['output'];
  /** The rule revision ID, if it has one */
  ruleRevisionId: Scalars['ID']['output'];
  /** The value that determines if the rule is a mapping rule or a validation rul */
  ruleType?: Maybe<RuleTypeEnum>;
  /** The severity of the validation error */
  severity?: Maybe<ValidateErrorSeverity>;
  /** The type of validation error */
  type?: Maybe<ValidateErrorType>;
};

export enum ValidateErrorSeverity {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export enum ValidateErrorType {
  Error = 'Error',
  Warning = 'Warning'
}

export type ValidateFieldInput = {
  name: Scalars['String']['input'];
  value: Scalars['Any']['input'];
};

export type ValidateFilterByLabel = {
  /**  List of label IDs to filter by */
  labelIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**  List of label Names to filter by */
  labelNames?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**  Organization ID for which to get org specific rules  */
  orgId?: InputMaybe<Scalars['ID']['input']>;
  /**  List of label UUIDs to filter by */
  ruleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**  RuleType to filter by. If left blank, mapping rules are excluded  */
  ruleType?: InputMaybe<Array<InputMaybe<RuleTypeEnum>>>;
};

export type ValidateLabel = {
  __typename?: 'ValidateLabel';
  id: Scalars['ID']['output'];
  /**  Tells whether a rule is internal or not  */
  internal: Scalars['Boolean']['output'];
  /**  Name of the label  */
  name: Scalars['String']['output'];
};

export type ValidateLabelFilter = {
  showInternal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateLabelIDs = {
  /**  List of label IDs to link to rule  */
  labelIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ValidateLabelInput = {
  /**  Tells whether a rule is internal or not  */
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Name of the label  */
  name: Scalars['String']['input'];
};

export type ValidateOrganizationInput = {
  filter?: InputMaybe<ValidateFilterByLabel>;
  orgId: Scalars['ID']['input'];
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ValidateOverride = {
  __typename?: 'ValidateOverride';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  orgID: Scalars['ID']['output'];
  rule: ValidateRuleV2;
};

export type ValidatePropertiesByIDsInput = {
  /**  Archipelago ID  */
  archipelagoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ValidatePropertyByIdInput = {
  /**  Archipelago ID  */
  archipelagoID: Scalars['ID']['input'];
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ValidateRule = {
  /** The Severity of validation error the rule will raise if a property fails the rule assertion */
  Severity?: InputMaybe<ValidateErrorSeverity>;
  /** The Type of the validation error the rule will raise if a property fails the assertion (derived from Severity) */
  Type?: InputMaybe<ValidateErrorType>;
  /** The CEL expression that will be asserted against properties matching the rule filter */
  assert: Scalars['String']['input'];
  /** The human readable description of the rule */
  desc?: InputMaybe<Scalars['String']['input']>;
  /** The CEL expression that will filter properties on which to apply the rule assertion */
  filter?: InputMaybe<Scalars['String']['input']>;
};

export enum ValidateRuleCategory {
  Code = 'Code',
  DesignConditions = 'DesignConditions',
  ObservedData = 'ObservedData'
}

export type ValidateRuleInput = {
  /** Adds a rule category to the rule */
  category?: InputMaybe<ValidateRuleCategory>;
  /** The human readable description of the rule */
  desc?: InputMaybe<Scalars['String']['input']>;
  /** Turns a rule on or off. */
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The error message that the user would see when this rule is shown */
  errorMessage: Scalars['String']['input'];
  /** The CEL expression that will filter properties on which to apply the rule assertion */
  filter?: InputMaybe<Scalars['String']['input']>;
  /** Adds labels to the rule */
  labels?: InputMaybe<ValidateLabelIDs>;
  /** The CEL expression that will be asserted against properties matching the rule filter */
  rule: Scalars['String']['input'];
  /** Decides whether the rule is a mapping rule or a validation rule */
  ruleType?: InputMaybe<RuleTypeEnum>;
  /** The severity of validation error the rule will raise if a property fails the rule assertion */
  severity?: InputMaybe<ValidateErrorSeverity>;
  /** Suggestion on how the error can be resolved */
  solution: Scalars['String']['input'];
};

export type ValidateRuleOverrideInput = {
  /**  Rule ID for which to create an override  */
  id: Scalars['ID']['input'];
  /**  organization for which to create an override  */
  orgID: Scalars['ID']['input'];
  rule: ValidateRuleInput;
};

export enum ValidateRuleScope {
  Account = 'Account',
  System = 'System'
}

export type ValidateRuleV2 = {
  __typename?: 'ValidateRuleV2';
  /** Adds a rule category to the rule */
  category?: Maybe<ValidateRuleCategory>;
  /** whether this rule is deleted */
  deleted: Scalars['Boolean']['output'];
  /** The human readable description of the rule */
  desc?: Maybe<Scalars['String']['output']>;
  /** Tells whether a rule is turned on or off. Rules that are turned off are not being executed when validating */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The error message that the user would see when this rule is shown */
  errorMessage: Scalars['String']['output'];
  /** The CEL expression that will filter properties on which to apply the rule assertion */
  filter?: Maybe<Scalars['String']['output']>;
  /** System generated identifier of the rule */
  id: Scalars['ID']['output'];
  /** Adds labels to the rule */
  labels?: Maybe<Array<Maybe<ValidateLabel>>>;
  /** When the rule implementation was last changed */
  lastUpdatedAt: Scalars['Time']['output'];
  /** The CEL expression that will be asserted against properties matching the rule filter */
  rule: Scalars['String']['output'];
  /**  Human readable identifier of the rule. Cannot be used as an identifier to perform API operations.  */
  ruleId: Scalars['Int']['output'];
  /** The rule's revision ID (if it has one) */
  ruleRevisionId: Scalars['ID']['output'];
  /** Determines whether the rule is a mapping rule or a validation rule. */
  ruleType?: Maybe<RuleTypeEnum>;
  /** Scope to indicate whether this is a system-wide or account-specific rule */
  scope?: Maybe<ValidateRuleScope>;
  /** The severity of validation error the rule will raise if a property fails the rule assertion */
  severity?: Maybe<ValidateErrorSeverity>;
  /** Suggestion on how the error can be resolved */
  solution: Scalars['String']['output'];
  /** The type of the validation error the rule will raise if a property fails the rule assertion */
  type?: Maybe<ValidateErrorType>;
};

export type ValidateStream = {
  __typename?: 'ValidateStream';
  /** AggregateErrors. If requested, also run aggregate checks on stream.  */
  aggregateErrors?: Maybe<Array<ValidateAggregateError>>;
  errors?: Maybe<Array<ValidateError>>;
};

export type ValidateStreamInput = {
  filter?: InputMaybe<ValidateFilterByLabel>;
  slug: Scalars['ID']['input'];
};

export type ValidateValueInput = {
  /** The list of attributes and values to assert against the validation service */
  attributes?: InputMaybe<Array<ValidateFieldInput>>;
  filter?: InputMaybe<ValidateFilterByLabel>;
};

export type ValuationSummaryFilter = {
  attribute: ValuationSummaryFilterAttribute;
  value: Scalars['String']['input'];
};

export enum ValuationSummaryFilterAttribute {
  ConstructionType = 'ConstructionType',
  OccupancyType = 'OccupancyType',
  UsStateCode = 'USStateCode'
}

export type ValuationSummaryFilterValues = {
  __typename?: 'ValuationSummaryFilterValues';
  attribute: ValuationSummaryFilterAttribute;
  values: Array<Scalars['String']['output']>;
};

export type ValuationSummaryFilterValuesInput = {
  streamSlug: Scalars['ID']['input'];
};

export type ValuationSummaryFilterValuesResponse = {
  __typename?: 'ValuationSummaryFilterValuesResponse';
  attributeValues: Array<ValuationSummaryFilterValues>;
};

export type ValueList = {
  __typename?: 'ValueList';
  list?: Maybe<Array<Scalars['String']['output']>>;
  /**  Generic type to maintain value and list of values  */
  value: Scalars['String']['output'];
};

export type VirtualInspectionInfo = {
  __typename?: 'VirtualInspectionInfo';
  /** If set to false, registerPropertyManagers mutation will assign users as PropertyEditor instead of PropertyViewer */
  propertyManagerEditEnabled: Scalars['Boolean']['output'];
  /** Returns value of VI setting on this properties Organization. */
  virtualInspectionsEnabled: Scalars['Boolean']['output'];
};

export enum WorkflowEmailType {
  AllTasksComplete = 'AllTasksComplete',
  TasksReadyForReview = 'TasksReadyForReview'
}

export type WorkflowStartedInput = {
  orgName: Scalars['String']['input'];
};

export type Worksheet = {
  __typename?: 'Worksheet';
  contentType?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
  /**  Id for this worksheet document, registered in the document service. */
  id: Scalars['ID']['output'];
  sizeInBytes: Scalars['Int']['output'];
};

export enum WorksheetFormat {
  /**  Default worksheet format. Displays attributes with additional .Suggested and .New columns  */
  MultiColumn = 'MultiColumn',
  SingleColumn = 'SingleColumn'
}

export type YearsFloat = {
  __typename?: 'YearsFloat';
  numYears: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type YearsInt = {
  __typename?: 'YearsInt';
  numYears: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type ExposureBase = {
  __typename?: 'exposureBase';
  al?: Maybe<Scalars['String']['output']>;
  gl?: Maybe<ExposureBaseInputGl>;
  wc?: Maybe<Scalars['String']['output']>;
};

export type ExposureBaseInput = {
  al?: InputMaybe<Scalars['String']['input']>;
  gl?: InputMaybe<ExposureBaseInputGl>;
  wc?: InputMaybe<Scalars['String']['input']>;
};

export enum ExposureBaseInputGl {
  Area = 'Area',
  GlPayroll = 'GLPayroll',
  Revenue = 'Revenue',
  Units = 'Units',
  WcPayroll = 'WCPayroll'
}

export type HistoricalExposure = {
  __typename?: 'historicalExposure';
  al?: Maybe<Scalars['Float']['output']>;
  gl?: Maybe<Scalars['Float']['output']>;
  policyEffectiveYear?: Maybe<PolicyEffectiveYearId>;
  wc?: Maybe<Scalars['Float']['output']>;
};

export type HistoricalExposureInput = {
  al?: InputMaybe<Scalars['Float']['input']>;
  gl?: InputMaybe<Scalars['Float']['input']>;
  policyEffectiveYear?: InputMaybe<PolicyEffectiveYearId>;
  wc?: InputMaybe<Scalars['Float']['input']>;
};

export type PolicyEffectiveDates = {
  __typename?: 'policyEffectiveDates';
  al?: Maybe<Scalars['Date']['output']>;
  gl?: Maybe<Scalars['Date']['output']>;
  policyEffectiveYear?: Maybe<PolicyEffectiveYearId>;
  wc?: Maybe<Scalars['Date']['output']>;
};

export type PolicyEffectiveDatesInput = {
  al?: InputMaybe<Scalars['Date']['input']>;
  gl?: InputMaybe<Scalars['Date']['input']>;
  policyEffectiveYear?: InputMaybe<PolicyEffectiveYearId>;
  wc?: InputMaybe<Scalars['Date']['input']>;
};

export type RequestedLinesOfBusiness = {
  __typename?: 'requestedLinesOfBusiness';
  limit?: Maybe<Scalars['Float']['output']>;
  lineOfBusiness?: Maybe<LineOfBusiness>;
  renewalOrRfpEffectiveDate?: Maybe<Scalars['Date']['output']>;
  retention?: Maybe<Scalars['Float']['output']>;
};

export type RequestedLinesOfBusinessInput = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  lineOfBusiness?: InputMaybe<LineOfBusiness>;
  renewalOrRfpEffectiveDate?: InputMaybe<Scalars['Date']['input']>;
  retention?: InputMaybe<Scalars['Float']['input']>;
};

export type ViewedHighlight = {
  __typename?: 'viewedHighlight';
  label?: Maybe<Array<Scalars['String']['output']>>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type WriteLinkPropertiesInput = {
  orgName: Scalars['String']['input'];
};

export type WriteLinkPropertiesResponse = {
  __typename?: 'writeLinkPropertiesResponse';
  propertiesNum: Scalars['Int']['output'];
};
