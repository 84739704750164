import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Button, ButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';

interface Props {
  streamSlug: string;
  snapshotName: string;
  snapshotId: string;
}

export const GET_SNAPSHOT_SCORE = gql`
  query qualitySnapshotScoreStatus($input: QualitySnapshotScoreStatusInput!) {
    qualitySnapshotScoreStatus(input: $input) {
      score
      status
    }
  }
`;

export const DELETE_SCORE = gql`
  mutation deleteScoreAndState($snapshotID: ID!) {
    deleteScoreAndState(snapshotID: $snapshotID)
  }
`;

export const CREATE_SNAPSHOT_SCORE = gql`
  mutation createDataQualityScores($input: CreateDataQualityScoresInput!) {
    createDataQualityScores(input: $input) {
      initiated
      status {
        completed
        inProgress
        failed
      }
    }
  }
`;

const DataQualityScoreButton: FC<Props> = ({ streamSlug, snapshotName, snapshotId }) => {
  const { data, loading, refetch } = useQuery(GET_SNAPSHOT_SCORE, {
    variables: {
      input: {
        snapshotName,
        streamSlug,
      },
    },
  });

  const [createScoresMutation, { loading: creating }] = useMutation(CREATE_SNAPSHOT_SCORE);

  const [deleteScoreMutation] = useMutation(DELETE_SCORE);

  const [isQualityScoreLoading, setIsQualityScoreLoading] = useState(false);

  useEffect(() => {
    if (isQualityScoreLoading) {
      const interval = setInterval(() => {
        refetch();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isQualityScoreLoading]);

  useEffect(() => {
    if (data?.qualitySnapshotScoreStatus.status === 'completed') {
      setIsQualityScoreLoading(false);
    }
  }, [data?.qualitySnapshotScoreStatus.status]);

  const deleteScore = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    deleteScoreMutation({
      refetchQueries: [
        {
          query: GET_SNAPSHOT_SCORE,
          variables: {
            input: {
              snapshotName,
              streamSlug,
            },
          },
        },
      ],
      variables: {
        snapshotID: snapshotId,
      },
    });
  };

  const scoreSnapshot = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    createScoresMutation({
      variables: { input: { snapshotID: snapshotId } },
    });
    setIsQualityScoreLoading(true);
  };

  let score;
  if (data?.qualitySnapshotScoreStatus.score === 0) {
    score = 0;
  } else {
    score =
      Math.round(
        100 * Number.parseFloat((data?.qualitySnapshotScoreStatus.score || 0).toFixed(2)),
      ) || false;
  }

  if (
    data?.qualitySnapshotScoreStatus.status === 'initiated' ||
    data?.qualitySnapshotScoreStatus.status === 'in_progress' ||
    isQualityScoreLoading
  ) {
    return <LoadingSpinnerV2 variant="l" />;
  }

  if (
    data?.qualitySnapshotScoreStatus.status !== 'no_status' &&
    data?.qualitySnapshotScoreStatus.status
  ) {
    return (
      <EuiFlexGroup direction="row" alignItems="center">
        <EuiFlexItem>
          <EuiToolTip title="Raw DQS" content={score}>
            <EuiText size="s">
              <h2>{score}</h2>
            </EuiText>
          </EuiToolTip>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiToolTip content="Clear Quality Score">
            <ButtonIcon
              iconName="trash"
              onClick={deleteScore}
              aria-label="Clear Score"
              color="danger"
              data-testid="delete-score"
              border
            />
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return (
    <EuiFlexGroup direction="row" alignItems="center">
      <Button
        size="s"
        onClick={scoreSnapshot}
        loading={loading || creating}
        data-testid="score-snapshot"
        iconName="barChart"
        label="Score"
      />
    </EuiFlexGroup>
  );
};
export default DataQualityScoreButton;
