import { NavigationType } from '../types/commonTypes';
import { DocumentsContext } from '../types/contextTypes';

const initialDocumentsState: DocumentsContext = {
  data: {
    classifications: [],
    documents: {
      setTotalCount: () => {},
      totalCount: null,
    },
    properties: {
      data: null,
      // @ts-expect-error: not concerned that function does not return `ApolloQueryResult`
      loadMore: () => {},
    },
  },
  downloads: {
    documentIds: {
      all: false,
      reset: () => {},
      setAll: () => {},
      setState: () => {},
      state: [],
    },
    downloading: {
      setState: () => {},
      state: false,
    },
  },
  filters: {
    documentSearchFilter: {
      setState: () => {},
      state: null,
    },
    documentSearchText: {
      setState: () => {},
      state: null,
    },
    documentType: {
      options: [],
      setState: () => {},
      state: null,
    },

    documentsQuery: {
      setState: () => {},
      state: [],
    },
    property: {
      options: [],
      setOptions: () => {},
      setState: () => {},
      state: null,
    },
    propertyQuery: {
      setState: () => {},
      state: [],
    },
    propertySearchFilter: {
      setState: () => {},
      state: null,
    },
    uploadDate: {
      options: [],
      setState: () => {},
      state: null,
    },
  },
  meta: {
    orgId: 'sample-org-id',
    orgName: 'sample-org-name',
    pageSize: 200,
  },
  navigation: {
    currentTab: NavigationType.UPLOAD_DATE,
    setCurrentTab: () => {},
  },
  preview: {
    setState: () => {},
    state: null,
    type: null,
  },
};

export default initialDocumentsState;
