import React, { useContext,useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useToast,
} from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { SUBMISSION_ATTRIBUTE_METADATA } from '@app/cx/Dashboard/Submissions/Queries';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { GET_SUBMISSION_ATTRIBUTE_METADATA } from './getSubmissionAttributeMetadata';
import SubmissionSettingsModalBody from './SubmissionSettingsModalBody';
import SubmissionSettingsModalFooter from './SubmissionSettingsModalFooter';
import {
  GetSubmissionAttributeMetadataData,
  GetSubmissionAttributeMetadataVariables,
  SettingsDisplay,
  SettingsState,
  UpdateSubmissionAttributeMetadataVariables,
} from './types';
import { UPDATE_SUBMISSION_ATTRIBUTE_METADATA } from './updateSubmissionAttributeMetadata';
import {
  getDerivedFields,
  getFilteredSubmissionData,
  getSettingsDisplay,
  getSettingsState,
  getUpdateSubmission,
} from './utils';

interface Props {
  orgName: string;
}

const SubmissionSettingsModal: React.FC<Props> = ({ orgName }) => {
  const { closeModal } = useContext(ModalContext);
  const [attributeData, setAttributeData] =
    useState<GetSubmissionAttributeMetadataData['submissionAttributeMetadata']>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const toast = useToast();
  const [display, setDisplay] = useState<SettingsDisplay[]>();
  const [settingsState, setSettingsState] = useState<SettingsState>({});
  const { selectedOrganization } = useUserSession();

  useQuery<GetSubmissionAttributeMetadataData, GetSubmissionAttributeMetadataVariables>(
    GET_SUBMISSION_ATTRIBUTE_METADATA,
    {
      onCompleted: (data) => {
        const filteredData = getFilteredSubmissionData(data.submissionAttributeMetadata);
        setAttributeData(filteredData);
        setDisplay(getSettingsDisplay(filteredData));
        setIsLoading(false);
        // TODO fix this on the API side where labels are being returned as "Stream"
        setSettingsState(getSettingsState(filteredData));
      },
      onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
      variables: {
        input: {
          configMode: true,
          orgName,
        },
      },
    },
  );

  const [updateSubmissionAttributes] = useMutation<
    null,
    UpdateSubmissionAttributeMetadataVariables
  >(UPDATE_SUBMISSION_ATTRIBUTE_METADATA, {
    onCompleted: () => closeModal(),
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
    refetchQueries: [
      {
        query: SUBMISSION_ATTRIBUTE_METADATA,
        variables: { input: { orgName: selectedOrganization?.name } },
      },
    ],
    variables: {
      input: {
        attributes: getUpdateSubmission(attributeData, settingsState),
        orgName,
      },
    },
  });

  const handleSave = async () => {
    setIsSaving(true);
    await updateSubmissionAttributes();
    setIsSaving(false);
  };

  const derivedFields = getDerivedFields(attributeData);

  return (
    <EuiModal data-testid="submission-settings-modal" onClose={() => closeModal()}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Configure Accounts</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <SubmissionSettingsModalBody
          display={display}
          loading={isLoading}
          settingsState={settingsState}
          setSettingsState={setSettingsState}
          derivedFields={derivedFields}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <SubmissionSettingsModalFooter
          loading={isLoading}
          onCancel={() => closeModal()}
          onSave={handleSave}
          saving={isSaving}
        />
      </EuiModalFooter>
    </EuiModal>
  );
};

export default SubmissionSettingsModal;
