// FIX ME
// @ts-ignore
import { ICluster, IMarker } from '@app/components/GoogleMap/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { IGraphQLStream } from '@app/queries/streams/types';

export enum SizeBy {
  TIV = 'tiv',
  PropertyCount = 'num',
  LossCountTotal = 'lossCountTotal',
  TotalGrossLoss = 'totalGrossLoss',
}

type Attributes =
  | 'id'
  | 'pictures'
  | 'locationName'
  | 'latitude'
  | 'longitude'
  | 'totalInsuredValue'
  | 'totalInsuredValueDisplay'
  | 'lossAttributes';
export type IPropertyMapProperty = Pick<IGraphQLProperty, Attributes>;

export interface IPropertyWithStream extends IPropertyMapProperty {
  stream: IGraphQLStream;
}

export type IPropertyCluster = ICluster<IPropertyWithStream>;
export type IPropertyMarker = IMarker<IPropertyWithStream>;
