import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Avatar, Badge, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip, useEuiTheme } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { SelectedOrganizationSummary, useUserSession } from '@app/contexts/UserSessionContext';
import { userHasSubmissionAccess } from '@app/cx/Dashboard/helpers';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckUser } from '@app/precheck/hooks/usePrecheckUser';
import { IGraphQLOrganization, RestrictedOrg } from '@app/queries/organizations/types';
import { OrgInfo } from '@app/queries/streams/types';
import { OrgOptions } from './OrgOptions';
import { AvatarContainer, OrgNameContainer, OrgSwitcherItem } from './OrgSwitcherFlyout.emotion';

export const OrgItemContent: FC<{ org: IGraphQLOrganization | RestrictedOrg | OrgInfo }> = ({
  org,
}) => {
  const history = useHistory();
  const mixpanel = useTracker('mixpanel');
  const { account } = useAuth();
  const { selectedOrganization, setOrgByName } = useUserSession();
  const { euiTheme } = useEuiTheme();
  const { size } = euiTheme;

  const { closeFlyout } = useFlyout();

  const isAdmin = account?.permissions?.admin;
  const isUpload = window.location.pathname.split('/')?.[1] === 'upload';

  const onOrgUpdated = (newSelectedOrg: SelectedOrganizationSummary) => {
    const hasSubmissionAccess = userHasSubmissionAccess(
      account.submissionCreateOrgs,
      newSelectedOrg,
    );

    // TODO - Move this to a separate useEffect
    const willRedirectToSubmissions =
      isAdmin || hasSubmissionAccess ? newSelectedOrg?.enableAccountDashboard : false;

    const defaultPath = willRedirectToSubmissions ? 'submissions' : 'streams';
    const precheckUser = usePrecheckUser();
    const isBroker = precheckUser.broker;

    const isRMOnNewOrg = account?.managedOrgs?.some((o) => o?.org?.name === newSelectedOrg.name);

    const doesNewOrgHaveSOVExperience = newSelectedOrg.enabledFeatures?.includes(
      'Simplified SOV User Experience',
    );

    const defaultSOVExperiencePath = isAdmin || isBroker || isRMOnNewOrg ? 'home' : 'properties';

    const pathname = encodeUrl`/organizations/${newSelectedOrg.name}/${
      doesNewOrgHaveSOVExperience ? defaultSOVExperiencePath : defaultPath
    }`;

    if (!isUpload) {
      history.replace({
        pathname,
        search: null,
      });
    }
    closeFlyout();
  };

  const onClick = async (org: IGraphQLOrganization) => {
    mixpanel.track('Switched account', {
      fromAccountId: selectedOrganization?.id,
      fromAccountName: selectedOrganization?.name,
      toAccountId: org.id,
      toAccountName: org.name,
    });

    // This will refetch org data, org info from managed orgs isn't sufficient (missing enabledFeature and account dashboard config)
    setOrgByName(org.name, {
      onCompleted: onOrgUpdated,
    });
  };
  return (
    <OrgSwitcherItem
      alignItems="center"
      style={{ padding: `${size.m}` }}
      onClick={() => onClick(org as IGraphQLOrganization)}
      onKeyDown={(e: any) => {
        // Listen for keyboard navigation

        // Up arrow key
        if (e?.key === 'ArrowUp') {
          // Go to the previous item in the list
          if (e?.target?.parentElement?.previousSibling?.firstChild) {
            e.target.parentElement.previousSibling.firstChild.focus();
          } else {
            // Or if we are at the top of the list go back up to the search
            const item = document.querySelector('[name=orgSwitcherSearch]');
            (item as HTMLElement)?.focus();
          }

          e.preventDefault();
        }

        // Down arrow key
        else if (e?.key === 'ArrowDown') {
          if (e?.target?.parentElement?.nextSibling?.firstChild) {
            e.target.parentElement.nextSibling.firstChild.focus();
          }
          e.preventDefault();
        }

        // Enter key
        else if (e?.key === 'Enter') {
          onClick(org as IGraphQLOrganization);
        }
      }}
      tabIndex={0}
    >
      <AvatarContainer grow={false}>
        <Avatar name={org.name} shape="square" />
      </AvatarContainer>
      <OrgNameContainer grow>
        <EuiToolTip position="top" content={org.name}>
          <EuiFlexGroup alignItems="flexStart" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiText>{org.name}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              {/* @ts-ignore */}
              {org.orgLevel === 'Enterprise' && (
                <EuiText>
                  <Badge label="Enterprise" />
                </EuiText>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiToolTip>
      </OrgNameContainer>
      {isAdmin && (
        <EuiFlexItem grow={false}>
          <OrgOptions org={org as IGraphQLOrganization} />
        </EuiFlexItem>
      )}
    </OrgSwitcherItem>
  );
};
