import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkflowStartedQueryVariables = Types.Exact<{
  input: Types.WorkflowStartedInput;
}>;


export type WorkflowStartedQuery = { __typename?: 'Query', workflowStarted: boolean };


export const WorkflowStartedDocument = gql`
    query WorkflowStarted($input: WorkflowStartedInput!) {
  workflowStarted(input: $input)
}
    `;

/**
 * __useWorkflowStartedQuery__
 *
 * To run a query within a React component, call `useWorkflowStartedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowStartedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowStartedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkflowStartedQuery(baseOptions: Apollo.QueryHookOptions<WorkflowStartedQuery, WorkflowStartedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowStartedQuery, WorkflowStartedQueryVariables>(WorkflowStartedDocument, options);
      }
export function useWorkflowStartedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowStartedQuery, WorkflowStartedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowStartedQuery, WorkflowStartedQueryVariables>(WorkflowStartedDocument, options);
        }
export type WorkflowStartedQueryHookResult = ReturnType<typeof useWorkflowStartedQuery>;
export type WorkflowStartedLazyQueryHookResult = ReturnType<typeof useWorkflowStartedLazyQuery>;
export type WorkflowStartedQueryResult = Apollo.QueryResult<WorkflowStartedQuery, WorkflowStartedQueryVariables>;