import { IAccountState } from '@app/containers/AuthProvider/types';
import { SelectedOrganizationSummary } from '@app/contexts/UserSessionContext';
import { UpdateType } from '../types';

interface Props {
  account: IAccountState;
  isAdmin: boolean;
  organizationName: string;
  selectedOrganization?: SelectedOrganizationSummary;
}

export default ({
  account,
  isAdmin,
  organizationName,
  selectedOrganization,
}: Props): UpdateType => {
  // Does the user have access to the organization name that is currently in the url?
  // * Yes = if the user is an admin
  // * Yes = if the user is not an admin but has that organization as one of their managed orgs
  const hasAccessToSelectedOrg =
    isAdmin ||
    account?.managedOrgs?.some((organization) => organization.org.name === organizationName);

  if (
    hasAccessToSelectedOrg &&
    organizationName &&
    organizationName !== selectedOrganization?.name
  ) {
    return 'update';
  }

  if (!isAdmin && !hasAccessToSelectedOrg) {
    return 'return';
  }

  return 'none';
};
