import { FC } from 'react';
import { IColumn } from '../types';
import DonutColumn from './DonutColumn';
import SparkColumn from './SparkColumn';
import TextColumn from './TextColumn';

const columnDefinitions: {
  [index: string]: FC<{
    column: IColumn;
  }>;
} = {
  donut: DonutColumn,
  spark: SparkColumn,
  text: TextColumn,
};

export default columnDefinitions;
