import { css } from '@emotion/css';

const container = css`
  margin-right: 4px !important;
`;

const modalBody = css`
  .euiModalBody__overflow {
    padding: 16px 24px !important;
  }
`;

export default {
  container,
  modalBody,
};
