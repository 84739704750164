import { ApolloError } from '@apollo/client';

export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred.';

export const getErrorMessage = (err: ApolloError, replacement_message?: string): string => {
  const MESSAGE = replacement_message || DEFAULT_ERROR_MESSAGE;

  if (err.message?.includes('GraphQL error:')) {
    return err.message.split('GraphQL error:')[1]?.trim() || MESSAGE;
  }
  if (!err.message || err.message.trim() === '') {
    return MESSAGE;
  }
  return err.message;
};
