import gql from 'graphql-tag';

export const JOB_WITH_TRANSITION_FRAGMENT = gql`
  fragment jobWithTransition on Job {
    id
    name
    type
    description
    sovDocId
    attributeNames
    propertyIDs
    status
    projectName
    createdAt
    orgName
    expiryTime
    priority
    streamSlug

    requesterProfile {
      userID
      givenName
      familyName
    }
    doerProfile {
      userID
      email
      givenName
      familyName
    }
    reviewerProfile {
      userID
      email
      givenName
      familyName
    }

    details
    lifeCycle {
      id
      from
      to
      action
      progress
      startTime
      endTime
      estimatedCompletionTime
      summary {
        total
        invalid
        processed
        success
        failed
      }
      warnings {
        type
        attributeName
        sheetName
        location
        columnHeader
        value
        message
      }
      systemErrors {
        archipelagoID
        message
        referenceID
        rowNumber
        sheetName
      }
      dataErrors {
        type
        attributeName
        columnHeader
        location
        value
        message
      }
      worksheet {
        id
        httpURL
        filename
      }
    }
    version
    worksheetFormat
  }
`;
