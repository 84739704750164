import React, { FC } from 'react';
import Currency from '@app/components/Currency/Currency';
import { SizeBy } from './types';
import styles from './ValueMarker.emotion';

interface Props {
  sizeBy: SizeBy;
  value: number | string;
}

const ValueMarker: FC<Props> = ({ sizeBy, value }) => {
  if (sizeBy === SizeBy.PropertyCount) {
    return (
      <div data-testid="value-marker" className={styles.container}>
        1
      </div>
    );
  }

  if (sizeBy === SizeBy.LossCountTotal) {
    return (
      <div data-testid="value-marker" className={styles.container}>
        {value}
      </div>
    );
  }

  return (
    <div data-testid="value-marker" className={styles.container}>
      <Currency value={value} />
    </div>
  );
};

export default ValueMarker;
