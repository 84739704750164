import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EuiFlexGroup, useToast } from 'ui';
import { object,string } from 'yup';
import { classifications } from '@app/constants';
import { AttributeRow, AttributeRowTypeEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import { PROPERTYV2 } from '@app/graphql/queries/property/propertyV2';
import { useUpdateDocumentMutation } from '@app/graphql/queries/updateDocument/__generated__/updateDocument.generated';
import { ModalWithButtons } from '../ModalWithButtons/ModalWithButtons';

const DOCUMENT_NAME_KEY = 'name';
const DOCUMENT_TYPE_KEY = 'classification';

export const EditUploadModal = ({ id, onClose, documentName, documentType }) => {
  const toast = useToast();

  const validationRules = {
    [DOCUMENT_NAME_KEY]: string().required(),
    [DOCUMENT_TYPE_KEY]: string().required(),
  };

  // no errors in the form context for some reason, so we can't disable the submit button
  // however, if there's an error, the API will catch it
  const formMethods = useForm({
    defaultValues: {
      classification: documentType,
      name: documentName,
    },
    mode: 'all',
    resolver: yupResolver(object(validationRules)),
  });

  const { handleSubmit } = formMethods;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateDocument] = useUpdateDocumentMutation({
    refetchQueries: [{ query: PROPERTYV2 }],
  });

  const submitHandler = async (data) => {
    setIsSubmitting(true);
    try {
      await updateDocument({
        variables: {
          input: { classification: data.classification, documentID: id, fileName: data.name },
        },
      });
      toast({ title: 'Document edited successfully' });
    } catch (error) {
      toast({ title: 'Failed to edit document', type: 'danger' });
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <FormProvider {...formMethods}>
      <ModalWithButtons
        formId="edit-upload-form"
        header="Edit document"
        buttonActionLabel="Save"
        onClose={onClose}
        onSubmit={handleSubmit(submitHandler)}
        buttonActionVariant="primary"
        isLoading={isSubmitting}
      >
        <EuiFlexGroup direction="column">
          <AttributeRow id="name" label="Document name" type={AttributeRowTypeEnum.text} />
          <AttributeRow
            id="classification"
            label="Document type"
            type={AttributeRowTypeEnum.select}
            inputProps={{
              options: classifications.map((classification) => ({
                  label: classification,
                  value: classification,
                })),
            }}
          />
        </EuiFlexGroup>
      </ModalWithButtons>
    </FormProvider>
  );
};
