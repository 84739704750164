import React, { CSSProperties, FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';

const border = '1px solid #343741';
const lineStyle = (showAbove: boolean) =>
  ({
    borderBottom: showAbove ? border : null,
    borderLeft: border,
    borderTop: showAbove ? null : border,
    height: 4,
  } as any);
const tickStyle = { borderRight: border, position: 'relative' } as CSSProperties;
const textStyle = (showAbove: boolean) =>
  ({ left: -7, position: 'absolute', top: showAbove ? -15 : 0 } as any);
const textStyle0 = (showPercent: boolean, omitNumbers: boolean) => {
  let leftValue = -4;
  if (showPercent) {
    leftValue = -7;
  }
  if (omitNumbers) {
    leftValue = 0;
  }
  return { left: leftValue, position: 'absolute' } as any;
};
const textStyle100 = (showPercent: boolean, showAbove: boolean, omitNumbers: boolean) => {
  let leftValue = 8;
  if (showPercent) {
    leftValue = 17;
  }
  if (omitNumbers) {
    leftValue = 68;
  }
  return {
    left: leftValue,
    position: 'absolute',
    textAlign: 'right',
    top: showAbove ? -15 : 0,
  } as any;
};

interface Props {
  omitNumbers?: boolean;
  showPercent?: boolean;
  showAbove?: boolean;
  showXLabels?: boolean;
}

const HorizontalRule: FC<Props> = ({
  showPercent = true,
  showAbove = false,
  omitNumbers = false,
}) => {
  const percent = showPercent ? '%' : '';
  return (
    <EuiFlexGroup style={lineStyle(showAbove)} gutterSize="none">
      <EuiFlexItem grow={1} style={tickStyle}>
        <EuiText
          size="xs"
          style={{ ...textStyle(showAbove), ...textStyle0(showPercent, omitNumbers) }}
        >
          {!omitNumbers && <small>0{percent}</small>}
        </EuiText>
      </EuiFlexItem>
      {['20', '40', '60', '80'].map((item) => (
        <React.Fragment key={item}>
          <EuiFlexItem grow={1} style={tickStyle} />
          <EuiFlexItem grow={1} style={tickStyle}>
            {!omitNumbers && (
              <EuiText size="xs" style={{ ...textStyle(showAbove) }}>
                <small>
                  {item}
                  {percent}
                </small>
              </EuiText>
            )}
          </EuiFlexItem>
        </React.Fragment>
      ))}
      <EuiFlexItem grow={1} style={tickStyle}>
        <EuiText size="xs" style={{ ...textStyle100(showPercent, showAbove, omitNumbers) }}>
          {!omitNumbers && <small>100{percent}</small>}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default HorizontalRule;
