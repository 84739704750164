"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("@emotion/css");
var badge = function (size, hidden) { return (0, css_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  svg {\n    margin-left: ", ";\n  }\n"], ["\n  ", "\n  svg {\n    margin-left: ", ";\n  }\n"])), hidden && "visibility: hidden;", size === "l" ? "8px" : "4px"); };
exports.default = {
    badge: badge,
};
var templateObject_1;
