import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiSpacer, Select, TextField, useToast } from 'ui';
import { ModalWithButtons } from '@app/components/ModalWithButtons/ModalWithButtons';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { usePrecheckContext } from '../context/PrecheckContext';
import { usePrecheckClient } from '../hooks/usePrecheckClient';
import { SharedWith } from './SharedWith';

const SHARE_PRECHECK = gql`
  mutation sharePreCheck($input: SharePreCheckInput!) {
    sharePreCheck(input: $input) {
      invitees {
        email
        type
        name
      }
    }
  }
`;

const UserRow = ({ user, updateUser, index, removeUser }) => (
  <EuiFlexGroup gutterSize="s">
    <EuiFlexItem grow={6}>
      <TextField
        value={user.email}
        onChange={(e) => {
          updateUser({ attribute: 'email', index, value: e.target.value });
        }}
        placeholder="email"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={3}>
      <TextField
        value={user.name}
        onChange={(e) => {
          updateUser({ attribute: 'name', index, value: e.target.value });
        }}
        placeholder="name"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={3}>
      {user.isBroker && (
        <Select
          fullWidth={true}
          onChange={(value) => {
            updateUser({ attribute: 'type', index, value });
          }}
          value={user.type}
          placeholder="role"
          options={[{ label: 'Editor', value: 'EDITOR' }]}
        />
      )}
    </EuiFlexItem>
    <EuiFlexItem>
      <ButtonEmpty iconName="trash" color="danger" label="" onClick={() => removeUser(user)} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export const ShareReportModal = ({ closeModal }) => {
  const { selectedOrganization } = useUserSession();
  const { precheckUser } = usePrecheckContext();
  const client = usePrecheckClient();

  const isBroker = precheckUser.broker;

  const newUser = { email: '', isBroker, name: '', type: 'EDITOR' };

  const [users, updateUsers] = useState([newUser]);

  const [sharePreCheck, { loading }] = useMutation(SHARE_PRECHECK, {
    client,
  });
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sharePreCheck({
        variables: {
          input: {
            invitees: users.map((user) => ({
              email: user.email,
              name: user.name,
              type: user.type,
            })),
            orgName: selectedOrganization.name,
          },
        },
      });

      toast({ label: 'Invites Sent', title: 'Success' });

      closeModal();
    } catch (error) {
      toast({ label: `Error adding user: ${error.message}`, title: 'Error', type: 'danger' });
    }
  };
  const addUsers = () => {
    updateUsers((prevState) => {
      const newUsers = [...prevState];
      newUsers.push(newUser);

      return newUsers;
    });
  };

  const updateUser = ({ index, attribute, value }) => {
    updateUsers((prevState) => {
      const newUsers = [...prevState];
      newUsers[index][attribute] = value;

      return newUsers;
    });
  };

  const removeUser = (removedUser) => {
    updateUsers((prevState) => prevState.filter((user) => user !== removedUser));
  };

  return (
    <ModalWithButtons
      // FIX ME
      // @ts-ignore
      header={
        <EuiFlexGroup>
          <EuiFlexItem>Share Recommendations</EuiFlexItem>
          <EuiFlexItem grow={false}>
            <ButtonEmpty label="Add Invite" iconName="plus" onClick={addUsers} />
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      buttonActionLabel="Share"
      buttonActionVariant="primary"
      formId="precheck-invite"
      onSubmit={handleSubmit}
      isLoading={loading}
      // FIX ME
      // @ts-ignore
      onClick={handleSubmit}
      isValid={
        !loading &&
        !users.some((user) => user.email.length < 1 || user.name.length < 1 || !user.type)
      }
      onClose={() => closeModal()}
    >
      {users.map((user, index) => (
        <>
          <UserRow
            key={index}
            user={user}
            updateUser={updateUser}
            index={index}
            removeUser={removeUser}
          />
          <EuiSpacer size="l" />
        </>
      ))}
      <SharedWith />
    </ModalWithButtons>
  );
};
