import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { GetProjectsData } from '@app/queries/organizations/getProjects';
import { MP_STREAM_PATH, PROJECTS_PATH } from '../../constants';
import { Permissions, UpdateType } from '../types';
import updateOrganization from './updateOrganization';
import updateOrganizationIfDM from './updateOrganizationIfDM';

interface Props {
  data: GetProjectsData;
  loading: boolean;
  permissions: Permissions;
}

export default ({ data, loading, permissions }: Props) => {
  const { account } = useAuth();
  const { isAdmin, isDM, isNonAdminWithOrgs } = permissions;
  const history = useHistory();
  const { selectedOrganization, setOrgByName } = useContext(UserSessionContext);

  const myPropertiesMatch = useRouteMatch(MP_STREAM_PATH); // Is the user on the "My Properties" page
  const projectsMatch = useRouteMatch(PROJECTS_PATH); // Is the user on the "Projects" tab

  // If the user is currently on an organizations route, we will use the organization name that is in the url to then
  // set the org in the cache.
  const organizationMatch = useRouteMatch<{ organizationName: string }>(
    '/organizations/:organizationName',
  );

  // In order to update the user's organization, we must have an organization name set in the url and we must not be on
  // "My Properties"
  const canUpdateOrganization = organizationMatch?.params?.organizationName && !myPropertiesMatch;

  if (canUpdateOrganization) {
    // the organization name set in the url
    const organizationName = decodeURIComponent(organizationMatch?.params?.organizationName);

    // We may update the organization if:
    // * the user is an admin
    // * OR
    //   * The user is a non admin but has access to certain orgs (aka `isNonAdminWithOrgs`)
    //   * AND We are not on the projects tab
    const isAdminOrHasOrgAccess = isAdmin || (isNonAdminWithOrgs && !projectsMatch);

    let shouldUpdate: UpdateType = 'return';
    if (isAdminOrHasOrgAccess) {
      shouldUpdate = updateOrganization({
        account,
        isAdmin,
        organizationName,
        selectedOrganization,
      });
    } else if (isDM) {
      shouldUpdate = updateOrganizationIfDM({
        data,
        loading,
        organizationName,
        selectedOrganization,
      });
    }

    if (shouldUpdate === 'update') {
      setOrgByName(organizationName);
    } else if (shouldUpdate === 'return') {
      history.replace({
        pathname: '/',
        search: null,
      });
    }
  }
};
