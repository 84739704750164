import gql from 'graphql-tag';

export const GET_STREAM_VALUATION_SUMMARY = gql`
  query StreamValuationSummary($input: StreamValuationSummaryInput!) {
    streamValuationSummary(input: $input) {
      percentOfPropertiesIncluded
      percentOfBRCIncluded
      brcValueDiff
      brcPercentDiff
      negativeBRCValueDiff
      negativeBRCPercentDiff
      positiveBRCValueDiff
      positiveBRCPercentDiff
      avgReplacementCostPerFloorArea
      avgReplacementCostPerFloorAreaPartner
      brcDistribution {
        displayName
        buildingReplacementCost
        propertyCount
      }
    }
  }
`;
