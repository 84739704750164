import styled from '@emotion/styled';
import { EuiFlexGroup, EuiToolTip, Icon } from 'ui';

export const ValuationOutlierContainer = styled(EuiFlexGroup)`
  padding-top: 18px;
`;

export const SectionLabel = styled(EuiFlexGroup)`
  align-items: center;
`;

export const TooltipContainer = styled(EuiToolTip)`
  max-width: 400px !important;
`;

export const TooltipIcon = styled(Icon)`
  align-items: center;
  display: flex;
`;
