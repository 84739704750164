import React from 'react';
import {
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPanel,
  EuiTitle,
  Icon,
  Select,
  useToast,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUpdateSupportTicketMutation } from '@app/graphql/mutations/support/__generated__/updateSupportTicket.generated';
import { GET_SUPPORT_TICKET } from '@app/graphql/queries/support/getSupportTicket';
import {
  SupportTicket,
  TicketPriority,
  TicketStatus,
  UpdateSupportTicketInput,
} from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { UserSelect } from '../UserSelect';

interface Props {
  ticket: SupportTicket;
}

const statusOptions = [TicketStatus.Open, TicketStatus.Resolved, TicketStatus.Closed].map((s) => ({
  label: s,
  value: s,
}));

export const TicketAside: React.FC<Props> = ({
  ticket: { chatRoomID, title, org, priority, authorEmail, status, assigneeEmails, followerEmails },
}) => {
  const toast = useToast();
  const { account } = useAuth();
  const disableEdits = !account?.permissions?.admin;

  const [updateTicket] = useUpdateSupportTicketMutation({
    onError: (e) =>
      toast({ label: getErrorMessage(e), title: 'An error occurred', type: 'danger' }),
    refetchQueries: [{ query: GET_SUPPORT_TICKET, variables: { input: { id: chatRoomID } } }],
  });

  const baseTicketInput: UpdateSupportTicketInput = {
    assigneeEmails,
    chatRoomID,
    followerEmails,
    priority: priority || TicketPriority.None,
    status: status || TicketStatus.Open,
  };

  return (
    <EuiPanel>
      <EuiTitle size="m">
        <h2>{title}</h2>
      </EuiTitle>
      {!!org?.name && (
        <>
          <EuiHorizontalRule margin="s" />
          <EuiTitle size="s">
            <span>
              <a href={encodeUrl`/organizations/${org.name}/streams`}>
                <Icon name="users" /> {org.name}
              </a>
            </span>
          </EuiTitle>
        </>
      )}
      <br />
      <EuiTitle size="xs">
        <span>
          By <a href={`mailto:${authorEmail}`}>{authorEmail}</a>
        </span>
      </EuiTitle>
      <EuiHorizontalRule />
      <EuiForm>
        <EuiFormRow label="Ticket status">
          <Select
            options={statusOptions}
            value={status}
            disabled={disableEdits}
            onChange={(e) => {
              updateTicket({
                variables: {
                  input: {
                    ...baseTicketInput,
                    status: e as TicketStatus,
                  },
                },
              });
            }}
          />
        </EuiFormRow>
        <EuiFormRow label="Assigned to">
          <UserSelect
            initialSelected={assigneeEmails}
            disabled={disableEdits}
            internalUsersOnly
            onChange={(emails) =>
              updateTicket({
                variables: {
                  input: {
                    ...baseTicketInput,
                    assigneeEmails: emails,
                  },
                },
              })
            }
          />
        </EuiFormRow>
        <EuiFormRow label="Followers">
          <UserSelect
            initialSelected={followerEmails}
            disabled={disableEdits}
            allowUnknowns
            onChange={(emails) =>
              updateTicket({
                variables: {
                  input: {
                    ...baseTicketInput,
                    followerEmails: emails,
                  },
                },
              })
            }
          />
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );
};
