import { HazardExposureType } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import { attributeMappings, attributeOrder } from '@onarchipelago/cx/Reports/ReadinessReport/const';

const getFilteredMappings = (
  mappings: Record<string, string>,
  order: Array<string>,
): Record<string, string> => {
  const finalMappings: Record<string, string> = {};

  order
    .filter((attribute) => Object.keys(mappings).includes(attribute))
    .forEach((attribute) => {
      finalMappings[attribute] = mappings[attribute];
    });

  return finalMappings;
};

export default (hazard: HazardExposureType): Record<string, string> => {
  let finalAttributeMappings: Record<string, string>;
  let order: Array<string>;

  switch (hazard) {
    case 'All':
      finalAttributeMappings = {
        ...attributeMappings.EARTHQUAKE,
        ...attributeMappings.FLOOD,
        ...attributeMappings.WIND,
      };
      order = [...attributeOrder.EARTHQUAKE, ...attributeOrder.FLOOD, ...attributeOrder.WIND];
      break;
    case 'Earthquake':
      finalAttributeMappings = { ...attributeMappings.EARTHQUAKE };
      order = attributeOrder.EARTHQUAKE;
      break;
    case 'Flood':
      finalAttributeMappings = { ...attributeMappings.FLOOD };
      order = attributeOrder.FLOOD;

      break;
    case 'Wind':
      finalAttributeMappings = { ...attributeMappings.WIND };
      order = attributeOrder.WIND;
      break;
    default:
      throw new Error('Unexpected hazard exposure type.');
  }

  return getFilteredMappings(finalAttributeMappings, order);
};
