export const FilterLabelStyle = {
  marginRight: '4px',
};

const CommonFilterStyles = {
  margin: '4px',
  maxWidth: '280px',
};

export const OccupancyFilterStyles = {
  ...CommonFilterStyles,
};

export const ConstructionFilterStyles = {
  ...CommonFilterStyles,
};

export const StateFilterStyles = {
  ...CommonFilterStyles,
  maxWidth: '160px',
};

export const titleContainerStyles = {
  margin: '-4px',
};
