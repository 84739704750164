"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var css_1 = require("@emotion/css");
var colors_1 = require("../../../neutrons/colors/colors");
var brandGreen = function (fill) { return (0, css_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", " !important;\n  border-color: ", " !important;\n  color: ", " !important;\n"], ["\n  background: ", " !important;\n  border-color: ", " !important;\n  color: ", " !important;\n"])), fill ? colors_1.colors.brandGreen : colors_1.colors.white, colors_1.colors.brandGreen, fill ? colors_1.colors.white : colors_1.colors.brandGreen); };
var white = (0, css_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", " !important;\n  color: ", " !important;\n  background-color: ", " !important;\n  border-color: #e7e9f1 !important;\n  border-style: solid !important;\n"], ["\n  background: ", " !important;\n  color: ", " !important;\n  background-color: ", " !important;\n  border-color: #e7e9f1 !important;\n  border-style: solid !important;\n"])), colors_1.colors.white, colors_1.colors.black, colors_1.colors.white);
var oval = function (size) { return (0, css_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-color: ", " !important;\n  border-radius: 40px !important;\n  font-size: ", ";\n  font-weight: 500 !important;\n  height: ", " !important;\n"], ["\n  border-color: ", " !important;\n  border-radius: 40px !important;\n  font-size: ", ";\n  font-weight: 500 !important;\n  height: ", " !important;\n"])), colors_1.shadeColors.lightGray, size === "s" ? "12px !important" : undefined, size === "s" ? "24px" : "36px"); };
exports.default = {
    brandGreen: brandGreen,
    white: white,
    oval: oval,
};
var templateObject_1, templateObject_2, templateObject_3;
