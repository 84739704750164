import styled from '@emotion/styled';
import { EuiBasicTable } from 'ui';

// FIX ME
// @ts-ignore
export const Table = styled(EuiBasicTable)`
  ${({ color }: { color: string }) =>
    `
      .euiTableRowCell {
        border-top: 1px solid ${color};
        border-bottom: 1px solid ${color};
      }
      .euiTableHeaderCell {
        border-bottom: 1px solid ${color};
      }
    `}
`;
