import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { EuiText, EuiTextColor } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_PRECHECK_SERVICE } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { usePrecheckClient } from '../hooks/usePrecheckClient';
import { SharedWithList } from './SharedWithList';

const ALL_PRECHECK_USERS = gql`
  query allPreCheckUsers($input: AllPreCheckUsersInput!) {
    allPreCheckUsers(input: $input) {
      users {
        typeV2
        email
      }
    }
  }
`;

export const SharedWith = () => {
  const client = usePrecheckClient();
  const { selectedOrganization } = useUserSession();

  const preCheckServiceEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_PRECHECK_SERVICE,
  );

  if (!preCheckServiceEnabled) {return null;}

  const { data, loading } = useQuery(ALL_PRECHECK_USERS, {
    client,
    variables: {
      input: {
        orgName: selectedOrganization.name,
      },
    },
  });

  if (loading) {return <LoadingSpinnerV2 styles={{ height: '200px' }} />;}
  if (!data?.allPreCheckUsers || data.allPreCheckUsers.users.length === 0) {
    return null;
  }

  return (
    <>
      <EuiText size="s">
        <EuiTextColor color="subdued">Previously shared with users</EuiTextColor>
      </EuiText>
      <SharedWithList users={data.allPreCheckUsers.users} />
    </>
  );
};
