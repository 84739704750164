import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CasualtyFragmentDoc } from '../../fragments/__generated__/casualty.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCasualtyMutationVariables = Types.Exact<{
  input: Types.UpdateCasualtyInput;
}>;


export type UpdateCasualtyMutation = { __typename?: 'Mutation', updateCasualty: { __typename?: 'Casualty', id: string, organizationId: string, orgName: string, accountOrgId: string, accountOrgName: string, accountStatus?: string | null, analysisDate?: any | null, clientName?: string | null, clientNetWorth?: number | null, clientProspect?: Types.ClientProspect | null, typeOfBusiness?: string | null, sicCode?: string | null, naicsCode?: string | null, analysisNotes?: string | null, primaryContactEmail?: string | null, office?: string | null, region?: string | null, secondaryContactEmail?: string | null, requestedLinesOfBusiness?: Array<{ __typename?: 'requestedLinesOfBusiness', lineOfBusiness?: Types.LineOfBusiness | null, retention?: number | null, limit?: number | null, renewalOrRfpEffectiveDate?: any | null }> | null, exposures?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, exposureBase?: { __typename?: 'exposureBase', al?: string | null, wc?: string | null, gl?: Types.ExposureBaseInputGl | null } | null, retentions?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, deductibleAggregateLimit?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, policyEffectiveDates?: Array<{ __typename?: 'policyEffectiveDates', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: any | null, wc?: any | null, gl?: any | null }> | null } };


export const UpdateCasualtyDocument = gql`
    mutation UpdateCasualty($input: UpdateCasualtyInput!) {
  updateCasualty(input: $input) {
    ...casualty
  }
}
    ${CasualtyFragmentDoc}`;
export type UpdateCasualtyMutationFn = Apollo.MutationFunction<UpdateCasualtyMutation, UpdateCasualtyMutationVariables>;

/**
 * __useUpdateCasualtyMutation__
 *
 * To run a mutation, you first call `useUpdateCasualtyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasualtyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasualtyMutation, { data, loading, error }] = useUpdateCasualtyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasualtyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCasualtyMutation, UpdateCasualtyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCasualtyMutation, UpdateCasualtyMutationVariables>(UpdateCasualtyDocument, options);
      }
export type UpdateCasualtyMutationHookResult = ReturnType<typeof useUpdateCasualtyMutation>;
export type UpdateCasualtyMutationResult = Apollo.MutationResult<UpdateCasualtyMutation>;
export type UpdateCasualtyMutationOptions = Apollo.BaseMutationOptions<UpdateCasualtyMutation, UpdateCasualtyMutationVariables>;