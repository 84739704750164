import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonIcon,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiText,
  EuiTitle,
  Spacer,
  EuiAccordion,
  useToast,
} from 'ui';
import { useQueryState } from '@app/hooks/useQueryState';
import { useCopilotContext } from '../../context/context';
import { PropertySelect } from '../PropertyTab/PropertySelect';
import { EnrichmentTabContainer } from './EnrichmentTab.emotion';
import { Property, Provenance } from '@app/graphql/types';
import ConfirmationDialog from '@app/components/ConfirmationDialog/ConfirmationDialog';
import { usePropertyDocumentsPageQuery } from '@app/graphql/queries/documents/__generated__/propertyDocumentsPage.generated';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { FormProvider, useForm } from 'react-hook-form';
import { getAttributeRow } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

interface ProvenanceGroup {
  reportID: string;
  extracted: Array<Provenance>;
  others: Array<Provenance>;
}

export const EnrichmentTab: React.FC = () => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);

  const {
    workspaceData: {
      searchFormValues: { propertyArchipelagoID },
    },
    provenanceJobData,
    attributesQuery,
    submitProvenanceEnrichment,
    removeFromProvenanceEnrichment,
    addToProvenanceJob,
  } = useCopilotContext();
  const toast = useToast();
  const [qs, setQueryState] = useQueryState();
  const initialAttribute = qs.get('attributeName');

  const formMethods = useForm();

  const groups = {};
  const property = provenanceJobData?.provenanceJobData?.properties?.[0];

  const { loading: loadingDocuments, data: propertyDocumentsData } = usePropertyDocumentsPageQuery({
    variables: { propertyID: property?.property?.id, pageSize: 100 },
    onError: (err) => toast({ type: 'danger', title: getErrorMessage(err) }),
    skip: !property,
  });

  const reportMap = {};
  propertyDocumentsData?.propertyDocumentsPage?.documents?.forEach((doc) => {
    reportMap[doc.id] = doc;
  });

  const eigenAttributes = {};
  const attributeDocumentMap = {};
  property?.attributeComments?.forEach((comment) => {
    if (comment?.comments?.find((c) => c.message.toLowerCase().includes('eigen'))) {
      eigenAttributes[comment.attributeName] = true;
    }
  });

  property?.property?.attributeProvenance?.forEach((p) => {
    const docID = p.sourceDocuments?.[0]?.document?.id;

    if (!reportMap[docID]) {
      return;
    }

    attributeDocumentMap[p.attributeName] = docID;
    if (!groups[docID]) {
      groups[docID] = { extracted: [], others: [] };
    }

    if (!!eigenAttributes[p.attributeName]) {
      groups[docID].extracted = [...groups[docID].extracted, p];
    } else {
      groups[docID].others = [...groups[docID].others, p];
    }
  });

  useEffect(() => {
    if (!!formMethods) {
      Object.keys(groups).forEach((docID) => {
        [...groups[docID].extracted, ...groups[docID].others].forEach((p: Provenance) => {
          formMethods.setValue(p.attributeName, property?.property?.[p.attributeName]);
        });
      });
    }
  }, [JSON.stringify(groups), !!formMethods]);

  const groupedProvenance: Array<ProvenanceGroup> = Object.keys(groups).map((docID) => ({
    provenances: groups[docID],
    reportID: docID,
    reportName: reportMap[docID]?.filename,
    extracted: groups[docID].extracted,
    others: groups[docID].others,
  }));

  const applyChanges = async () => {
    setDialogLoading(true);

    const attributeOverrides = formMethods.getValues();
    const attributeOverrideNames = Object.keys(attributeOverrides);
    const provenances = [];

    attributeOverrideNames.forEach((attributeName) => {
      const value = attributeOverrides[attributeName];
      if (value !== property?.property?.[attributeName]) {
        provenances.push({ attributeName, documentID: attributeDocumentMap[attributeName], value });
      }
    });

    if (provenances.length > 0) {
      await addToProvenanceJob(provenances);
    }

    submitProvenanceEnrichment();
    setConfirmationDialogOpen(false);
    setDialogLoading(false);
  };

  const renderBody = () => {
    if (!provenanceJobData?.provenanceJobData) {
      return (
        <EuiEmptyPrompt
          title={
            <EuiTitle>
              <h4>This property doesn't have any active jobs</h4>
            </EuiTitle>
          }
          body={
            <>
              <EuiText>
                You can start one from the <b>Full Report</b> tab by browsing attribute values and
                clicking on <Button label="Add to Job" iconName="plusCircle" />
              </EuiText>
            </>
          }
        />
      );
    }

    const renderProvenanceField = (p: Provenance, documentID: string, disabled: boolean) => {
      const attribute = attributesQuery?.dict?.[p.attributeName]?.metadata;
      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            {getAttributeRow(
              attribute as IGraphQLAttributeMetadata,
              null,
              property.property as Property,
              { readOnly: disabled, labelAction: null },
            )}
          </EuiFlexItem>

          <EuiFlexItem style={{ visibility: disabled ? 'hidden' : 'unset' }}>
            <EuiFormRow hasEmptyLabelSpace>
              <ButtonIcon
                iconName={'x'}
                color="danger"
                onClick={() => removeFromProvenanceEnrichment(p.attributeName)}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    };

    return (
      <FormProvider {...formMethods}>
        <EuiForm>
          {groupedProvenance.map((group) => {
            const report = reportMap[group.reportID];
            return (
              <p>
                <EuiLink href={report?.httpURL} target="_blank">
                  {report?.filename}
                </EuiLink>

                <EuiAccordion
                  id={`extracted-fields-${group.reportID}`}
                  buttonContent={`Extracted fields (${group.extracted.length})`}
                  initialIsOpen
                >
                  {group.extracted.map((p) => renderProvenanceField(p, group.reportID, false))}
                </EuiAccordion>
                {group.others.length > 0 && (
                  <>
                    <Spacer size="m" />
                    <EuiAccordion
                      id={`other-fields-${group.reportID}`}
                      buttonContent={`Others (${group.others.length})`}
                    >
                      {group.others.map((p) => renderProvenanceField(p, group.reportID, true))}
                    </EuiAccordion>
                  </>
                )}
              </p>
            );
          })}
        </EuiForm>
      </FormProvider>
    );
  };
  return (
    <EnrichmentTabContainer>
      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onDecline={() => setConfirmationDialogOpen(false)}
        onSubmit={applyChanges}
        confirmBodyText="This property will be updated with the extracted values. Are you sure?"
        labelConfirm="Yes, apply changes"
        submitLoading={dialogLoading}
      />
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false} className="property-select">
              <EuiFormRow label="Select property">
                <PropertySelect
                  value={propertyArchipelagoID}
                  handleChange={(archipelagoID) =>
                    setQueryState({ archipelagoID, attributeName: initialAttribute })
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            {!!provenanceJobData?.provenanceJobData && (
              <EuiFlexItem grow={false}>
                <EuiFormRow hasEmptyLabelSpace>
                  <Button label="Apply Changes" onClick={() => setConfirmationDialogOpen(true)} />
                </EuiFormRow>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="report-body">{renderBody()}</EuiFlexItem>
      </EuiFlexGroup>
    </EnrichmentTabContainer>
  );
};
