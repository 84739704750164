import { css } from '@emotion/css';

export const contentContainer = css`
  flex-grow: 0;
`;

export const footerContainer = css`
  margin-top: 0;
`;

export const replacementCostLeftItemContainerStyle = {
  marginRight: '0px',
};

export const replacementCostRightItemContainerStyle = {
  marginLeft: '0px',
};

export const replacementCostFooterStyle = {
  fontSize: '14px',
};

export const statHeaderStyle = css`
  margin-right: 8px;
`;

export const statHeaderContainerStyle = css`
  display: flex;
  align-items: center;
`;

export const tooltipAnchorStyle = css`
  display: flex !important;
  align-items: center;
`;

export const subheaderTooltipContainerStyle = {
  marginRight: '4px',
};

export const subeaderTooltipTextStyle = {
  marginLeft: '4px',
};

export const subtitleStyle = {
  marginTop: '4px',
};
