import { GetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';
import { Task, TaskStatus, Attribute } from '@app/graphql/precheck/precheck.types';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

export interface IFormattedtask {
  assignees: Array<{
    email: string;
    name: string;
  }>;
  status: TaskStatus;
  attributes: Attribute[];
  id: string;
  priority: number;
  propertyId: string;
  propertyUid: string;
  propertyName: string;
  propertyCityAndState: string;
  tiv: string;
  rawTiv: number;
  recommendedDocuments: Array<string>;
  propertyDocuments: any;
  reasons: Array<CATReasons | COPEReason | ValuationReason | AttritionalReason>;
  property: Task['property'];
}
export interface ITaskContext {
  tasks: Array<IFormattedtask>;
  readyTasksCount: number;
  loading: boolean;
  error: ApolloError;
  filters: {
    documentOptions: Array<{ label: string; value: string }>;
    reasonOptions: Array<{
      label: string;
      value: CATReasons | COPEReason | ValuationReason | AttritionalReason;
    }>;
    userOptions: Array<{ label: string; value: string }>;
    name: string | null;
    users: Array<string>;
    dismissed: boolean;
    filtersApplied: boolean;
    documents: Array<string>;
    reasons: Array<CATReasons | COPEReason | ValuationReason | AttritionalReason>;
    setFilter: (filterName: string, filterValue: string | Array<string>) => void;
    clearFilters: () => void;
  };
  setSelectedTasks: (tasks: Array<IFormattedtask>) => void;
  selectedTasks: Array<IFormattedtask>;
  refetch: () => Promise<ApolloQueryResult<GetTasksQuery>>;
  totalTiv: number;
  filteredTiv: string;
  tivPercent: string;
  taskTotals: {
    attritional: {
      tiv: number;
      properties: number;
    };
    cat: {
      tiv: number;
      properties: number;
    };
    cope: {
      tiv: number;
      properties: number;
    };
    valuation: {
      tiv: number;
      properties: number;
    };
  };
}

export enum CATReasons {
  earthquake = 'Earthquake risk',
  wind = 'Wind risk',
  flood = 'Flood risk',
  storm = 'SCS risk',
}

export enum COPEReason {
  cope = 'Missing COPE data',
}

export enum ValuationReason {
  valuation = 'Valuation Outlier',
}

export enum AttritionalReason {
  attritional = 'Attritional risk',
}
