import React from 'react';
import { EuiFlexItem, EuiText } from 'ui';
import { useReportsContext } from '../contexts/reportsContext/reportsContext';
import { reportsHeader, reportsHeaderNoSubtitle, reportsSubtitle } from './ReportsHeader.emotion';

interface ReportsHeaderProps {
  header: string;
  subtitle?: string;
  subtitleDataTestId?: string;
  testId?: string;
}
export const ReportsHeader = ({
  header,
  testId,
  subtitle,
  subtitleDataTestId,
}: ReportsHeaderProps) => {
  const { streamName } = useReportsContext().stream;
  return (
    <EuiFlexItem grow={false}>
      <EuiFlexItem style={subtitle ? reportsHeader : reportsHeaderNoSubtitle} grow={false}>
        <EuiText data-testid={testId}>
          <h2>{header}</h2>
        </EuiText>
        <EuiText data-testid="report-stream-name" color="subdued">
          <p>{streamName}</p>
        </EuiText>
      </EuiFlexItem>
      {subtitle && (
        <EuiFlexItem style={reportsSubtitle} grow={false}>
          <EuiText data-testid={subtitleDataTestId}>
            <p>{subtitle}</p>
          </EuiText>
        </EuiFlexItem>
      )}
    </EuiFlexItem>
  );
};
