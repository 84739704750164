import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiFocusTrap } from 'ui';

export const AvatarContainer = styled(EuiFlexItem)`
  margin-right: 0px !important;
  .euiAvatar {
    margin: 0px;
  }
`;

export const OrgNameContainer = styled(EuiFlexItem)`
  overflow: hidden;
  .euiText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

// FIX ME
// @ts-ignore
export const FocusTrapContainer = styled(EuiFocusTrap)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OrgSwitcherBody = styled.div`
  flex: 1 1 auto;
  height: 100%;
`;

export const OrgSwitcherItem = styled(EuiFlexGroup)`
  &:hover,
  &:focus {
    // we were previously using tintOrShade($colorPrimary, 90%, 70%), but for now,
    // hardcoding the color since that's the value it nets out to, and we only have one theme
    background-color: #e6f1fb;
    cursor: pointer;
    .euiText {
      text-decoration: underline;
    }
  }
`;
