import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnalyticsConfigQueryVariables = Types.Exact<{
  input: Types.AnalyticsConfigInput;
}>;


export type AnalyticsConfigQuery = { __typename?: 'Query', analyticsConfig: { __typename?: 'AnalyticsConfigResponse', enterpriseReportingHostURL: string, signedJWT: string, analyticsConfig: { __typename?: 'AnalyticsConfig', name: string, workspaceID: string, insights?: Array<{ __typename?: 'Insight', id: string, filters?: Array<{ __typename?: 'AnalyticsFilter', id: string }> | null }> | null } } };


export const AnalyticsConfigDocument = gql`
    query AnalyticsConfig($input: AnalyticsConfigInput!) {
  analyticsConfig(input: $input) {
    enterpriseReportingHostURL
    signedJWT
    analyticsConfig {
      name
      workspaceID
      insights {
        id
        filters {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAnalyticsConfigQuery__
 *
 * To run a query within a React component, call `useAnalyticsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsConfigQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsConfigQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsConfigQuery, AnalyticsConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsConfigQuery, AnalyticsConfigQueryVariables>(AnalyticsConfigDocument, options);
      }
export function useAnalyticsConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsConfigQuery, AnalyticsConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsConfigQuery, AnalyticsConfigQueryVariables>(AnalyticsConfigDocument, options);
        }
export type AnalyticsConfigQueryHookResult = ReturnType<typeof useAnalyticsConfigQuery>;
export type AnalyticsConfigLazyQueryHookResult = ReturnType<typeof useAnalyticsConfigLazyQuery>;
export type AnalyticsConfigQueryResult = Apollo.QueryResult<AnalyticsConfigQuery, AnalyticsConfigQueryVariables>;