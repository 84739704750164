import styled from '@emotion/styled';
import { EuiFlexGroup, EuiPanel, EuiToolTip } from 'ui';

export const PanelContainer = styled(EuiPanel)`
  padding: 0;
`;

export const ContainerHeight = styled(EuiFlexGroup)`
  ${({ chartType }: { chartType: string }) => chartType === 'OccupancyConstruction' ? 'height: 445px;' : 'height: auto;'}
`;

export const ContainerWidth = styled(EuiFlexGroup)`
  ${({ chartType }: { chartType: string }) => chartType === 'OccupancyConstruction' ? 'width: 356px;' : 'width: 339px;'}
`;

export const TooltipContainer = styled(EuiToolTip)`
  ${({ label }: { label: string }) => label === 'None' ? 'display: none;' : ''}
`;
