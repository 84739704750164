import styled from '@emotion/styled';

export const IconContainer = styled.div`
  ${({
    isLeftIconClosed,
    isLeftIconOpen,
    isRightIconClosed,
    isRightIconOpen,
  }: {
    isLeftIconClosed: boolean;
    isLeftIconOpen: boolean;
    isRightIconClosed: boolean;
    isRightIconOpen: boolean;
  }) =>
    `
    ${
      isLeftIconClosed
        ? `
            margin-right: 8px;
            transform: scale(-1, 1);
          `
        : ''
    }
    ${isLeftIconOpen ? 'margin-right: 8px;' : ''}
    ${isRightIconClosed ? 'margin-left: 8px;' : ''}
    ${
      isRightIconOpen
        ? `
          margin-left: 8px;
          transform: scale(-1, 1);
        `
        : ''
    }
    `}
`;
