import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiModal, MultiSelect } from 'ui';

export const Modal = styled(EuiModal)`
  width: 680px;
`;

export const FilterAttributesContainer = styled(EuiFlexItem)`
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const MultiSelectContainer = styled(MultiSelect)`
  width: 300px;
`;

export const ClearFiltersContainer = styled(EuiFlexGroup)`
  padding-bottom: 16px;
`;

export const ButtonFooter = styled(EuiFlexGroup)`
  padding-top: 16px;
`;
