import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from '@emotion/styled';
import { TooltipFormatterContextObject } from 'highcharts';
import TextContent, { IProps } from './TextContent';

const Container = styled.div`
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  height: 113px;

  background: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  padding: 14px 17px;

  font-weight: 600;
  line-height: 130%;
  /* identical to box height, or 13px */
  letter-spacing: 0.0025em;

  color: #828282;
`;

const LargeContainer = styled.div`
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  height: 150px;

  background: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  padding: 14px 17px;

  font-weight: 600;
  line-height: 130%;
  /* identical to box height, or 13px */
  letter-spacing: 0.0025em;

  color: #828282;
`;

const ChartTooltip: FC<IProps> = ({
  label,
  data,
  dataSubtitle,
  change,
  footer,
  changeAnalysis,
}) => {
  if (typeof label === 'string' && label.length > 30) {
    return (
      <LargeContainer>
        <TextContent
          label={label}
          data={data}
          dataSubtitle={dataSubtitle}
          change={change}
          footer={footer}
          changeAnalysis={changeAnalysis}
          type="tooltip"
        />
      </LargeContainer>
    );
  }
  return (
    <Container>
      <TextContent
        label={label}
        data={data}
        dataSubtitle={dataSubtitle}
        change={change}
        footer={footer}
        changeAnalysis={changeAnalysis}
        type="tooltip"
      />
    </Container>
  );
};

function formatter(this: TooltipFormatterContextObject): false | string | Array<string> {
  // The myData attribute is not part of the point type so I'm casting to any for now.
  const { name: label, y: amount, series, myData } = this.point as any;

  const data = `${((amount || 0) * 100).toFixed(1)}% of TIV - \n ${label}`;
  const customData = myData || {};
  return ReactDOMServer.renderToStaticMarkup(
    <ChartTooltip
      label={label}
      data={data}
      change={customData.change}
      changeAnalysis={customData.changeAnalysis}
      footer={series.name}
    />,
  );
}

const chartTooltipConfig = {
  animation: true,
  backgroundColor: 'white',
  borderRadius: 0,
  borderWidth: 0,
  enabled: true,
  formatter,
  height: 600,
  hideDelay: 1,
  outside: true,
  padding: 0,
  shadow: false,
  style: {
    height: 600,
  },
  useHTML: true,
};

export default chartTooltipConfig;
