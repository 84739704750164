import React, { createContext, ReactNode, useContext, useState } from 'react';

interface FlyoutContext {
  isFlyoutOpen: boolean;
  FlyoutContent: ReactNode;
  showFlyout: (content: ReactNode) => void;
  closeFlyout: () => void;
}

export const FlyoutContext = createContext({} as FlyoutContext);

export const FlyoutProvider = ({ children }: { children: ReactNode }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const [FlyoutContent, setFlyoutContent] = useState<ReactNode>(<div />);

  const closeFlyout = () => {
    setIsFlyoutOpen(false);
  };
  const showFlyout = (content: ReactNode) => {
    setFlyoutContent(content);
    setIsFlyoutOpen(true);
  };

  return (
    <FlyoutContext.Provider
      value={{
        FlyoutContent,
        closeFlyout,
        isFlyoutOpen,
        showFlyout,
      }}
    >
      {children}
    </FlyoutContext.Provider>
  );
};

export const useFlyout = () => {
  const context = useContext(FlyoutContext);
  return context;
};
