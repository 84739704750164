import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import hazardFilterOptions from '../hazardFilterOptions';
import { Hazard, HazardExposureType, ReportsContext, Snapshot, SnapshotOption } from '../types';
import useGetScoredSnapshots from './useGetScoredSnapshots';

export default (streamSlug: string): ReportsContext['snapshots'] => {
  const [afterSnapshot, setAfterSnapshot] = useState<Snapshot>();
  const [afterSnapshotOptions, setAfterSnapshotOptions] = useState<SnapshotOption[]>([]);
  const [currentHazard, setCurrentHazard] = useState<HazardExposureType>(
    hazardFilterOptions[0].value,
  );
  const [currentSnapshot, setCurrentSnapshot] = useState<Snapshot>();
  const [hazardOptions, setHazardOptions] = useState<Hazard[]>();
  const previousCurrentSnapshot = usePrevious<Snapshot | undefined>(currentSnapshot);

  const { loading, snapshots, snapshotOptions } = useGetScoredSnapshots({
    currentSnapshot,
    setAfterSnapshot,
    setAfterSnapshotOptions,
    setCurrentSnapshot,
    streamSlug,
  });

  // Find the hazards for the current snapshot
  useEffect(() => {
    const hazards = hazardFilterOptions.filter((hazardOption) =>
      (currentSnapshot?.availableHazards || []).includes(hazardOption.value),
    );

    setHazardOptions(hazards);
  }, [currentSnapshot]);

  // Function for updating the afterSnapshot
  const setAfterSnapshotFinal = (value: string) => {
    const afterSnapshotFinal = (snapshots || []).find(
      (snapshot) => snapshot.snapshotName === value,
    );

    if (afterSnapshotFinal) {
      setAfterSnapshot(afterSnapshotFinal);
    }
  };

  // Function for updating the currentSnapshot
  const setCurrentSnapshotFinal = (value: string) => {
    const currentSnapshotFinal = (snapshots || []).find(
      (snapshot) => snapshot.snapshotName === value,
    );

    if (currentSnapshotFinal) {
      setCurrentSnapshot(currentSnapshotFinal);

      // Always reset back to "all hazards" when changing between snapshots
      if (currentHazard !== 'All') {
        setCurrentHazard(hazardFilterOptions[0].value);
      }
    }
  };

  // If the current snapshot gets updated, update the list of "after" snapshot options, because
  // the "after" snapshot can only be snapshots that come after the current snapshot
  useEffect(() => {
    if (currentSnapshot?.snapshotName !== previousCurrentSnapshot?.snapshotName) {
      const afterSnapshotOptionsFinal = snapshots
        ?.filter(
          (snapshot) =>
            new Date(snapshot.snapshotDate).valueOf() >=
              new Date(currentSnapshot?.snapshotDate || '').valueOf() &&
            snapshot.snapshotName !== currentSnapshot?.snapshotName,
        )
        .map((snapshot) => ({
          label: snapshot.snapshotName,
          value: snapshot.snapshotName,
        }));

      if (afterSnapshotOptionsFinal && afterSnapshotOptionsFinal.length > 0) {
        setAfterSnapshotFinal(afterSnapshotOptionsFinal[0].value);
        setAfterSnapshotOptions(afterSnapshotOptionsFinal);
      }
    }
  }, [currentSnapshot, previousCurrentSnapshot]);

  return {
    afterSnapshot,
    afterSnapshotOptions,
    currentHazard,
    currentSnapshot,
    hazardOptions,
    loading,
    setAfterSnapshot: setAfterSnapshotFinal,
    setCurrentHazard,
    setCurrentSnapshot: setCurrentSnapshotFinal,
    snapshotOptions,
  };
};
