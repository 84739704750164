import isNil from 'lodash/isNil';
import {
  ADDRESS_INPUT_KEYS,
  GEO_INPUT_KEYS,
  LAT_INPUT_KEY,
  LNG_INPUT_KEY,
} from '@app/cx/Stream/AddPropertyFlyout/constants';
import { makeOriginalAddressField } from '@app/cx/Stream/AddPropertyFlyout/utils';
import { PropertyDataInput } from '@app/graphql/jobs/jobs.types';
import { Property } from '@app/graphql/types';
import { CellPopoverFormValues } from '../../types';

export const handleAddressInputs = (
  formData: CellPopoverFormValues,
  property: Property,
  hasSelectedGoogleAddress: boolean,
  propertyData: PropertyDataInput,
  attributeNames: string[],
) => {
  const areAddressFieldsIdentical = ADDRESS_INPUT_KEYS.every(
    (key) => formData[key] === property[key],
  );

  const areGeoFieldsIdentical = GEO_INPUT_KEYS.every((key) => formData[key] === property[key]);
  let customerProvidedGeocode = false;

  if (hasSelectedGoogleAddress || areAddressFieldsIdentical) {
    propertyData = { ...formData };
    Object.entries(propertyData).forEach(([key, value]) => {
      if (value === '' || isNil(value)) {
        // need to set to null so that it will properly unset the attribute
        propertyData[key] = null;
      }
    });
    attributeNames = Object.keys(propertyData);
    if (!hasSelectedGoogleAddress && !areGeoFieldsIdentical) {
      customerProvidedGeocode = true;
    }
  } else {
    propertyData = { originalAddress: makeOriginalAddressField(formData) };
    attributeNames = ['originalAddress'];
    if (!areGeoFieldsIdentical) {
      propertyData[LAT_INPUT_KEY] = formData[LAT_INPUT_KEY];
      propertyData[LNG_INPUT_KEY] = formData[LNG_INPUT_KEY];
      attributeNames.push(LAT_INPUT_KEY, LNG_INPUT_KEY);
      customerProvidedGeocode = true;
    }
  }
  return {
    attributeNames,
    customerProvidedGeocode,
    propertyData,
  };
};
