import { css } from '@emotion/css';
import { dotSize } from './barConstants';

const dot = (pixelPosition: number) => css`
  background: #343741;
  border-radius: 50%;
  border: 1px solid white;
  height: ${dotSize}px;
  left: ${pixelPosition}px;
  position: absolute;
  top: -4px;
  width: ${dotSize}px;
`;

export default {
  dot,
};
