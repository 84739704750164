import React from 'react';
import { CallOut } from 'ui';

interface IProps {
  errors: Array<string>;
  onClose: () => void;
}

const PropertyManagersErrors: React.FC<IProps> = ({ errors }) => (
  <CallOut
    type="danger"
    title={`Could not register property managers, there were ${errors.length} errors`}
  >
    <ul>
      {errors.map((e, key) => (
        <li key={key}>{e}</li>
      ))}
    </ul>
  </CallOut>
);

export default PropertyManagersErrors;
