import { CASUALTY_LABEL, CASUALTY_PATH, SUPPORT_LABEL, SUPPORT_PATH } from './SideNav/constants';

export const STREAMS_LABEL = 'SOV';
export const STREAMS_PATH = 'streams';
export const SUBMISSIONS_LABEL = 'Accounts';
export const SUBMISSIONS_PATH = 'accounts';
export const LIBRARY_LABEL = 'Library';
export const LIBRARY_PATH = 'library';
export const PROJECTS_LABEL = 'Projects';
export const PROJECTS_PATH = 'projects';
export const USERS_LABEL = 'Users';
export const USERS_PATH = 'users';
export const INBOX_LABEL = 'Inbox';
export const INBOX_PATH = 'inbox';
export const PRECHECK_LABEL = 'Pre-Check';
export const PRECHECK_PATH = 'precheck';
export const HOME_PATH = 'home';
export const HOME_LABEL = 'Home';

export const STREAMS_TAB = {
  id: STREAMS_PATH,
  label: STREAMS_LABEL,
  path: STREAMS_PATH,
};

export const PRECHECK_TAB = {
  id: PRECHECK_PATH,
  label: PRECHECK_LABEL,
  path: PRECHECK_PATH,
};

export const SUBMISSIONS_TAB = {
  id: SUBMISSIONS_PATH,
  label: SUBMISSIONS_LABEL,
  path: SUBMISSIONS_PATH,
};

export const LIBRARY_TAB = {
  id: LIBRARY_PATH,
  label: LIBRARY_LABEL,
  path: LIBRARY_PATH,
};

export const PROJECTS_TAB = {
  id: PROJECTS_PATH,
  label: PROJECTS_LABEL,
  path: PROJECTS_PATH,
};

export const USERS_TAB = {
  id: USERS_PATH,
  label: USERS_LABEL,
  path: USERS_PATH,
};

export const INBOX_TAB = {
  id: INBOX_PATH,
  label: INBOX_LABEL,
  path: INBOX_PATH,
};

export const HOME_TAB = {
  id: HOME_PATH,
  label: HOME_LABEL,
  path: HOME_PATH,
};

export const SUPPORT_TAB = {
  id: SUPPORT_PATH,
  label: SUPPORT_LABEL,
  path: SUPPORT_PATH,
};

export const CASUALTY_TAB = {
  id: CASUALTY_PATH,
  label: CASUALTY_LABEL,
  path: CASUALTY_PATH,
};
