import React from 'react';
// FIX ME
// @ts-ignore
import commentAbsent from './icons/comment-absent.svg';
// FIX ME
// @ts-ignore
import commentPresent from './icons/comment-present.svg';
import { getEdits, getProvenance, ICell } from './Cell';
import Img from './Img';

const getIcon = (description?: string) => {
  if (description) {
    return commentPresent;
  }

  return commentAbsent;
};

const Description = (props: ICell) => {
  const id = props?.column?.id;
  const description = props.value as string;
  const provenance = getProvenance(props.prop, id);
  const icon = getIcon(description);
  const height = props.column?.formattingOptions?.height;

  const tooltip = {
    description,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <Img src={icon} alt="" title="" height={height} />;

  return { body, tooltip };
};

export default Description;
