import React, { FC } from 'react';
import { ReportProps } from '@onarchipelago/cx/Reports/types';
import moment from 'moment';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import UserViewsContainer from './UserViews/UserViewsContainer';
import { Panel, ReportContainer } from './EngagementReport.emotion';

const EngagementReport: FC<ReportProps> = ({ visible }) => {
  if (visible) {
    return (
      <ReportContainer direction="column" responsive={false}>
        <ReportsHeader
          header={`${STREAMS_LABEL} Engagement Report`}
          testId="engagement-report-title"
        />
        <EuiFlexItem grow={false}>
          <EuiText data-testid="engagement-report-subtitle">
            <p>{`Data as of ${moment().format('YYYY-MM-DD')}`}</p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <Panel hasShadow={false} paddingSize="l" />
                </EuiFlexItem>
                <EuiFlexItem>
                  <Panel hasShadow={false} paddingSize="l" />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <Panel hasShadow={false} paddingSize="l" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <Panel hasShadow={false} paddingSize="l">
            <UserViewsContainer />
          </Panel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Panel hasShadow={false} paddingSize="l" />
            </EuiFlexItem>
            <EuiFlexItem>
              <Panel hasShadow={false} paddingSize="l" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </ReportContainer>
    );
  }

  return null;
};

export default EngagementReport;
