import styled from '@emotion/styled';
import { EuiPanel } from 'ui';

export const FullPropertyTabContainer = styled(EuiPanel)`
  .property-select {
    min-width: 256px;
  }

  .property-tab-content {
    height: 100%;
  }

  .report-body {
    overflow-y: hidden;
  }
`;
