import React from 'react';
import { formatDefault } from '@app/utils/format';
import { getEdits, getProvenance, ICell } from './Cell';

const TwoLines = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const value = formatDefault(props.value, '-');
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: value,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <p>{value}</p>;

  return { body, tooltip };
};

export default TwoLines;
