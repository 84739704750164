import React, { useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTab, EuiTabs } from 'ui';
import { useDataInsights } from '../hooks/useDataInsights';
import { OverviewPanel } from './OverviewPanel';

export const DataInsightPanel = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = useDataInsights();

  const tabsWithOverview = [{ content: <OverviewPanel />, label: 'Overview' }, ...tabs];

  return (
    <EuiPanel grow={false} style={{ marginTop: '12px', padding: '24px 24px 8px 24px' }}>
      <>
        <EuiFlexGroup alignItems="baseline">
          <EuiFlexItem grow={false}></EuiFlexItem>
          <EuiFlexItem>
            <EuiTabs bottomBorder={false} size="s">
              {tabsWithOverview.map((_tab, index) => (
                <EuiTab
                  key={index}
                  isSelected={currentTab === index}
                  onClick={() => setCurrentTab(index)}
                >
                  {tabsWithOverview[index].label}
                </EuiTab>
              ))}
            </EuiTabs>
          </EuiFlexItem>
        </EuiFlexGroup>
        <div style={{ marginTop: '32px', minHeight: '325px' }}>
          {tabsWithOverview[currentTab].content}
        </div>
      </>
    </EuiPanel>
  );
};
