import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchTasksQueryVariables = Types.Exact<{
  input: Types.TaskSearchInput;
}>;


export type SearchTasksQuery = { __typename?: 'Query', searchTasks?: Array<{ __typename?: 'TaskSearchResponse', id: string, name: string, creationDate: string, completionDate?: string | null, assignee?: string | null, taskState: Types.TaskState, sortValues?: Array<string> | null, isFirst: boolean, formKey?: string | null, dueDate?: string | null, followUpDate?: string | null, property?: { __typename?: 'Property', archipelagoId: string, city?: string | null, locationId: string, county?: string | null, country?: string | null, locationName?: string | null, postalCode?: string | null, state?: string | null, streetAddress?: string | null } | null }> | null };


export const SearchTasksDocument = gql`
    query SearchTasks($input: TaskSearchInput!) {
  searchTasks(input: $input) {
    id
    name
    creationDate
    completionDate
    assignee
    taskState
    sortValues
    isFirst
    formKey
    dueDate
    followUpDate
    property {
      archipelagoId
      city
      locationId
      county
      country
      locationName
      postalCode
      state
      streetAddress
    }
  }
}
    `;

/**
 * __useSearchTasksQuery__
 *
 * To run a query within a React component, call `useSearchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchTasksQuery(baseOptions: Apollo.QueryHookOptions<SearchTasksQuery, SearchTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTasksQuery, SearchTasksQueryVariables>(SearchTasksDocument, options);
      }
export function useSearchTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTasksQuery, SearchTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTasksQuery, SearchTasksQueryVariables>(SearchTasksDocument, options);
        }
export type SearchTasksQueryHookResult = ReturnType<typeof useSearchTasksQuery>;
export type SearchTasksLazyQueryHookResult = ReturnType<typeof useSearchTasksLazyQuery>;
export type SearchTasksQueryResult = Apollo.QueryResult<SearchTasksQuery, SearchTasksQueryVariables>;