import React, { useContext, useState } from 'react';
import {
  Button,
  ButtonEmpty,
  CallOut,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  TextField,
  useToast,
} from 'ui';
import validator from 'validator';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useShareStreamLinkMutation } from '@app/graphql/mutations/stream/__generated__/ShareStreamLink.generated';
import { useTracker } from '@app/hooks/useTracker';
import { IGraphQLStream } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { Modal } from './InviteStreamModal.emotion';

interface IProps {
  stream: IGraphQLStream;
}

const InviteStreamModal: React.FC<IProps> = ({ stream }) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name;
  const mixpanel = useTracker('mixpanel');
  const toast = useToast();
  const { closeModal } = useContext(ModalContext);

  const [shareStreamLink, { loading }] = useShareStreamLinkMutation({
    onCompleted: () => {
      toast({
        label: `You've invited ${emailAddress} to ${stream.orgName} + ${stream.name}!`,
        title: 'Success',
      });
      setEmail('');
      closeModal();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const renderLink = () => `${window.location.origin}/streams/${stream.slug}`;
  const linkText = renderLink();

  const onClick = () => {
    shareStreamLink({
      variables: {
        input: {
          invitedUserId: emailAddress,
          streamSlug: stream.slug,
        },
      },
    });
  };
  const [emailAddress, setEmail] = useState<string>();

  return (
    <Modal onClose={closeModal}>
      <EuiModalHeader>
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiModalHeaderTitle>Invite to {STREAMS_LABEL}</EuiModalHeaderTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiText>Invite additional users to view this {STREAMS_LABEL} activity stream:</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiForm>
              <EuiFormRow fullWidth>
                <TextField
                  value={emailAddress}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  fullWidth
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem>
                <EuiText size="s">
                  This user will be invited to view {stream.orgName} + {stream.name}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <Button
                  size="m"
                  onClick={onClick}
                  label="Send"
                  disabled={!validator.isEmail(emailAddress || '') || loading}
                  loading={loading}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiCopy textToCopy={linkText}>
              {(copy) => (
                <CallOut title={linkText}>
                  <Button size="s" onClick={copy} label="Copy link to clipboard" />
                </CallOut>
              )}
            </EuiCopy>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <ButtonEmpty
                  onClick={closeModal}
                  label="Close"
                  loading={loading}
                  disabled={loading}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </Modal>
  );
};

export default InviteStreamModal;
