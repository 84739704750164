import { IS_DEV_ENV } from '@app/config';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export interface IRequestHeader {
  name: string;
  value: string;
}

export interface IDownloadFileRequest {
  url: string;
  headers?: Array<IRequestHeader>;
  filename?: string;
}

export interface IRejectValue {
  message: string;
  statusCode?: number;
}

export interface IResolveValue {
  statusCode: number;
}

const getFilenameFromDisposition = (disposition: string | null) => {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches?.[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }

  return '';
};

export const getFileURL = (fileID: string): string => `${window.location.origin}/files/${fileID}`;

type Download = (params: IDownloadFileRequest) => Promise<IResolveValue>;
// We use downloadFile because we can't set additional headers with window.open().
const download: Download = ({ url, headers, filename }) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);

    (headers || []).forEach((header) => {
      xhr.setRequestHeader(header.name, header.value);
    });

    xhr.responseType = 'blob';
    xhr.onload = () => {
      // Shameless copy from: https://gist.github.com/zynick/12bae6dbc76f6aacedf0
      if (xhr.status >= 200 && xhr.status <= 299) {
        const disposition = xhr.getResponseHeader('Content-Disposition');
        if (!disposition) {
          const value: IRejectValue = {
            message: 'Content-Disposition is required',
          };
          return reject(value);
        }

        const targetFilename = filename || getFilenameFromDisposition(disposition);
        const type = xhr.getResponseHeader('Content-Type') || undefined;

        const blob = new Blob([xhr.response], {
          type,
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the
          // blob for which they were created. These URLs will no longer resolve as the data
          // backing the URL has been freed."
          window.navigator.msSaveBlob(blob, targetFilename);
        } else {
          const URL = window.URL;
          const downloadUrl = URL.createObjectURL(blob);

          if (targetFilename) {
            // use HTML5 a[download] attribute to specify filename
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = targetFilename;
            document.body.appendChild(a);
            a.click();
          }

          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }

        return resolve({
          statusCode: xhr.status,
        });
      }

      const value: IRejectValue = {
        message: `Status Code 2xx expected. Instead got ${xhr.status}`,
        statusCode: xhr.status,
      };
      return reject(value);
    };
    xhr.onerror = (event) => {
      if (IS_DEV_ENV) {
        // only log error when in development mode
        console.error(event);
      }

      const value: IRejectValue = {
        message: 'ProgressEvent error',
      };
      reject(value);
    };
    xhr.send();
  });

export default download;
