/** @jsx jsx */
/** @jsxFrag jsx */
import { jsx } from '@emotion/react';
import { gradedMetrics } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/InsurerInsights';
import { GradeBadgeColors } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/insurerInsightsTypes';
import {
  getChartData,
  normalizeTitle,
} from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/insurerInsightsUtils';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Badge, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from 'ui';
import { Component } from '@app/queries/streams/types';
import { useUnderwritingAssessmentReportContext } from '../../underwritingAssessmentReportContext';
import { makeGradingSection } from '../utils';

interface InsurerHorizontalBarChartProps {
  component: Component;
}

function InsurerHorizontalBarChart({ component }: InsurerHorizontalBarChartProps) {
  const dataFormatted = getChartData(component, gradedMetrics);

  const { setInsurerInsightsTab, setGradingSection, setGradingFilter } =
    useUnderwritingAssessmentReportContext();

  if (!dataFormatted) {
    return <div>No chart</div>;
  }

  // the `metricsWithoutGradeDetails` metrics don't match any screen on the Grade Details tab.
  // We don't make them links (using the `onClick` prop) because they don't have destinations.
  // TODO:
  // - [ ] We need to figure out how to calculate these automatically without hardcoding these values.
  const metricsWithoutGradeDetails = ['USWS_AGGREGATION', 'CAEQ_AGGREGATION'];

  // Capture the condition to allow a metric to be a link or not.
  const canHaveLink = !metricsWithoutGradeDetails?.includes(component?.attribute);

  // Use the appropriate cursor for clickable metrics.
  const cursorType = canHaveLink ? 'pointer' : 'auto';

  // Use graded metrics if it's RE Risk or Predictive Risk
  const hasGradedMetrics = gradedMetrics.includes(component?.attribute);
  const pctOfTIVKey = hasGradedMetrics ? 'pctOfGradedTIVPd' : 'pctOfTIV';
  const pctOfPropertiesKey = hasGradedMetrics ? 'pctOfGradedProperties' : 'pctOfProperties';
  const pctOfPropertiesLabel = hasGradedMetrics ? '% of graded properties' : '% of properties';
  const pctOfTIVLabel = hasGradedMetrics ? '% of graded TIV' : '% of TIV';

  // Each metric might have its own section on the Grade Details tab. This function is a callback
  // to take the user there.
  const navigateToDetailsTab = (): void => {
    const val = makeGradingSection(
      component.attribute,
      component.__typename,
      component.propertyAssessmentMetrics,
    );
    setGradingSection(val);
    setInsurerInsightsTab('grading-details');
  };

  const options = {
    chart: {
      height: 15,
      margin: 0,
      type: 'bar',
    },

    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: cursorType,
        events: {
          click(event): void {
            if (canHaveLink) {
              const val = makeGradingSection(
                component.attribute,
                event.target.point.series.data[0].series.userOptions.__typename,
              );

              setGradingFilter(event.target.point.series.data[0].series.userOptions.name);
              setGradingSection(val);
              setInsurerInsightsTab('grading-details');
            }
          },
        },
        pointWidth: 8,
        stacking: 'percent',
      },
    },
    series: [...dataFormatted],
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: '#000',
      borderRadius: 4,
      color: '#fff',
      headerFormat: '<div style="font-weight: bold;margin-bottom: .5rem;">{series.name} Risk</div>',
      outside: true,
      pointFormatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        const { userOptions } = this.series;

        if (userOptions[pctOfPropertiesKey] && userOptions[pctOfTIVKey]) {
          return (
            `<div style="margin-bottom: .5rem;">${userOptions[pctOfPropertiesKey]}${pctOfPropertiesLabel} </div>` +
            `<div>${userOptions[pctOfTIVKey]}${pctOfTIVLabel}</div>`
          );
        }
        if (userOptions.__typename === 'PropertyAssessmentMetric') {
          return `<div style="margin-bottom: .5rem;">${userOptions.data[0]}% of inspections </div>`;
        }
        if (userOptions.data[0]) {
          return `<div style="margin-bottom: .5rem;">${userOptions.data[0]}${pctOfPropertiesLabel} </div>`;
        }
        return '<div />';
      },
      style: {
        color: '#fff',
      },
      useHTML: true,
    },
    xAxis: {
      categories: [],
      labels: {
        enabled: false,
      },
      lineColor: '#fff',
      maxPadding: 0,
      minPadding: 0,
    },
    yAxis: {
      gridLineColor: '#fff',
      labels: {
        enabled: false,
      },
      min: 0,
      title: false,
    },
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiFlexGroup
          alignItems="center"
          justifyContent="spaceBetween"
          css={{ marginBottom: '-30px' }}
        >
          <EuiFlexItem>
            <EuiToolTip position="top" content={component?.description}>
              <EuiText
                onClick={() => {
                  if (canHaveLink) {
                    navigateToDetailsTab();
                  }
                }}
                style={{ cursor: cursorType }}
              >
                {normalizeTitle(component?.attribute)}
              </EuiText>
            </EuiToolTip>
          </EuiFlexItem>
          <EuiFlexItem css={{ alignItems: 'end' }}>
            <div
              onClick={() => {
                if (canHaveLink) {
                  navigateToDetailsTab();
                }
              }}
            >
              <EuiToolTip position="top" content={component?.description}>
                <Badge
                  color={GradeBadgeColors[component.grade.color.toUpperCase()]}
                  label={component.grade.category}
                />
              </EuiToolTip>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default InsurerHorizontalBarChart;
