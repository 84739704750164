import React from 'react';
import { EuiConfirmModal } from 'ui';
import { useOrgModalContext } from '../../context/OrgModal.context';

export interface ModalContent {
  field: string;
  title: string;
  message: string;
  value: boolean;
}

interface Props {
  onClose: () => void;
  content: ModalContent;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmSettingsChangeModal: React.FC<Props> = ({
  onClose,
  content: { field, title, message, value },
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  const { values, updateValues } = useOrgModalContext();
  return (
    <EuiConfirmModal
      title={title}
      onCancel={onClose}
      onConfirm={() => {
        updateValues({ ...values, [field]: value });
        onClose();
      }}
      cancelButtonText={cancelText}
      confirmButtonText={confirmText}
      buttonColor="success"
      defaultFocusedButton="cancel"
    >
      {message}
    </EuiConfirmModal>
  );
};

export default ConfirmSettingsChangeModal;
