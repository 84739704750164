import { useEffect, useState } from 'react';
import { IAccountState } from '@app/containers/AuthProvider/types';

const formatStorageKey = (userId: string, key: string) => {
  const href = window?.location?.href;
  // eslint-disable-next-line no-useless-escape
  const domain = /:\/\/([^\/]+)/.exec(href)?.[1] || 'unknown';
  const environment = domain.split('.')[0].split(':')[0];
  return `${environment}::${userId}::${key}`;
};

export const getLocalStorageItem = <T>(userID: string, keyName: string): T => {
  const key = formatStorageKey(userID, keyName);
  return JSON.parse(localStorage.getItem(key)) as T;
};

export default (account: IAccountState | undefined, keyName: string, defaultValue: any) => {
  const userId = (account as IAccountState)?.userId || '';
  const key = formatStorageKey(userId, keyName);
  const stored = localStorage.getItem(key);
  const initial = stored ? JSON.parse(stored) : defaultValue;
  const [value, setValue] = useState(initial);

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  const clearValue = () => {
    localStorage.removeItem(key);
  };

  return [value, setValue, clearValue];
};
