import React from 'react';
import { EuiFormRow, Select } from 'ui';
import { IForm, ModalAction } from './types';

const reportingTypeOptions = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Broker customer', value: 'BrokerCustomer' },
  { label: 'Broker logo', value: 'BrokerLogo' },
  { label: 'Insurer customer', value: 'InsurerCustomer' },
  { label: 'Insurer logo', value: 'InsurerLogo' },
  { label: 'Insurer other', value: 'InsurerOther' },
  { label: 'Prior year acct', value: 'PriorYear' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Internal', value: 'Internal' },
];
const systemTypeOptions = (action: ModalAction) =>
  [
    {
      description: 'Test accounts act just like regular accounts but can also be deleted.',
      label: 'Test',
      value: 'Test',
    },
    {
      description: 'An account made purely for demo purposes.',
      label: 'Demo',
      value: 'Demo',
    },
    action === 'add' && {
      description: 'Create a regular account based off an existing account.',
      label: 'Template',
      value: 'Template',
    },
  ].filter(Boolean);

const reportingTypesWithSystem = { Internal: true, Unknown: true };
export const getOrgType = (orgType: string, reportingType: string): string | null => {
  if (orgType === 'Template') {
    return orgType;
  }
  return reportingTypesWithSystem[reportingType] ? orgType : null;
};

interface Props {
  disabled: boolean;
  action: ModalAction;
  values: IForm;
  withSystemType: boolean;

  onChange: (orgTypes: { orgType: string | null; reportingType: string }) => void;
}
const OrgTypeSelect: React.FC<Props> = ({ disabled, action, values, withSystemType, onChange }) => {
  const deriveOrgType = (reportingType: string) => {
    if (values.orgType === 'Template') {
      return values.orgType;
    }

    return reportingTypesWithSystem[reportingType] ? values?.orgType || 'Test' : null;
  };
  return (
    <>
      <EuiFormRow label="Reporting Type" fullWidth>
        <Select
          data-testid="organization-reporting-type-dropdown"
          options={reportingTypeOptions}
          onChange={(v) =>
            onChange({
              orgType: deriveOrgType(v),
              reportingType: v,
            })
          }
          value={values.reportingType}
          disabled={disabled}
          fullWidth
        />
      </EuiFormRow>
      {withSystemType && !!reportingTypesWithSystem[values?.reportingType] && (
        <EuiFormRow label="System Type" fullWidth>
          <Select
            data-testid="organization-system-type-dropdown"
            options={systemTypeOptions(action)}
            onChange={(v) =>
              onChange({
                orgType: v,
                reportingType: reportingTypesWithSystem[values?.reportingType]
                  ? values?.reportingType
                  : 'Internal',
              })
            }
            value={values.orgType}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
      )}
    </>
  );
};

export default OrgTypeSelect;
