import { JobType, Status } from '@app/graphql/jobs/jobs.types';

// From here: https://github.com/onarchipelago/jobs/blob/main/internal/jobs/internal/retry.go#L16
export const RETRY_JOB_TYPES = [
  JobType.Enrichment,
  JobType.EnrichmentWithProvenance,
  JobType.Ingest,
  JobType.Geo,
];

export const STATUS_ICON_MAP = {
  [Status.Completed]: { color: 'primary', icon: 'checkCircle' },
  [Status.Failed]: { color: 'danger', icon: 'alertTriangle' },
  [Status.TimedOut]: { color: 'warning', icon: 'clock' },
};
