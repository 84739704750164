import React from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import { PropertyMetadata } from '../types';
import { groupMetadata } from '../utils';
import DownloadButton from './DownloadButton';
import UploadButton from './UploadButton';
import styles from './UploadDownload.emotion';

interface Props {
  propertyMetadata: Array<PropertyMetadata>;
}

const UploadDownload: React.FC<Props> = ({ propertyMetadata }) => {
  const groupedMetadata = groupMetadata(propertyMetadata);

  return (
    <EuiFlexGroup data-testid="attribute-metadata-upload-download" className={styles.container}>
      <EuiFlexItem grow={false}>
        <DownloadButton data={groupedMetadata} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <UploadButton />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default UploadDownload;
