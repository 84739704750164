// Address & Geo Attributes
export const COUNTRY_INPUT_KEY = 'country';
export const COUNTRY_LABEL = 'Country';

export const STREET_ADDRESS_INPUT_KEY = 'streetAddress';
export const STREET_ADDRESS_LABEL = 'Street Address';

export const CITY_INPUT_KEY = 'city';
export const CITY_LABEL = 'City';

export const STATE_INPUT_KEY = 'state';
export const STATE_LABEL = 'State';

export const POSTAL_CODE_INPUT_KEY = 'postalCode';
export const POSTAL_CODE_LABEL = 'Zip/Postal Code';

export const COUNTY_INPUT_KEY = 'county';
export const COUNTY_LABEL = 'County';

export const LAT_INPUT_KEY = 'latitude';
export const LAT_LABEL = 'Latitude';

export const LNG_INPUT_KEY = 'longitude';
export const LNG_LABEL = 'Longitude';

// Currency Attributes
export const CURRENCY_INPUT_KEY = 'valuationCurrency';
export const CURRENCY_LABEL = 'Currency';

export const TIV_INPUT_KEY = 'totalInsuredValue';
export const TIV_LABEL = 'Total Insured Value';

export const RCV_INPUT_KEY = 'buildingReplacementCost';
export const RCV_LABEL = 'Replacement Cost Value';

export const CRC_INPUT_KEY = 'contentsReplacementCost';
export const CRC_LABEL = 'Contents Replacement Cost';

export const BIC_INPUT_KEY = 'businessInterruptionCost';
export const BIC_LABEL = 'Business Interruption Cost';

// Client Id Attribute
export const CLIENT_ID_KEY = 'locationId';
export const CLIENT_ID_LABEL = 'Client ID';

export enum AttributeDataType {
  Default = 'default',
  HazardText = 'hazardText',
  WebLink = 'webLink',
  Description = 'description',
  Integer = 'integer',
  Currency = 'currency',
  CurrencyLocal = 'currencyLocal',
  Number = 'number',
  Percentage = 'percentage',
  MeasurementDistanceLarge = 'measurementDistanceLarge',
  MeasurementDistanceSmall = 'measurementDistanceSmall',
  MeasurementArea = 'measurementArea',
  Boolean = 'boolean',
  SecondaryModifier = 'secondaryModifier',
  Construction = 'construction',
  Occupancy = 'occupancy',
  Year = 'year',
  Date = 'date',
}

export const CURRENCY_KEYS = [
  CURRENCY_INPUT_KEY,
  TIV_INPUT_KEY,
  RCV_INPUT_KEY,
  CRC_INPUT_KEY,
  BIC_INPUT_KEY,
];

export const DERIVED_CURRENCY_KEYS = [RCV_INPUT_KEY, CRC_INPUT_KEY, BIC_INPUT_KEY];

export const ADDRESS_INPUT_KEYS = [
  COUNTRY_INPUT_KEY,
  STREET_ADDRESS_INPUT_KEY,
  CITY_INPUT_KEY,
  STATE_INPUT_KEY,
  POSTAL_CODE_INPUT_KEY,
  COUNTY_INPUT_KEY,
];

export const GEO_INPUT_KEYS = [LAT_INPUT_KEY, LNG_INPUT_KEY];

// excludes postal and state because they are not always present for addresses
export const REQUIRED_EDIT_KEYS = [
  COUNTRY_INPUT_KEY,
  STREET_ADDRESS_INPUT_KEY,
  CITY_INPUT_KEY,
  COUNTY_INPUT_KEY,
  ...CURRENCY_KEYS,
];

export const ADDRESS_AND_GEO_INPUT_KEYS = [...ADDRESS_INPUT_KEYS, ...GEO_INPUT_KEYS];

export const DISABLED_MESSAGE =
  'Cannot Edit Attribute: Another user is already editing this attribute.';

export const DERIVED_MESSAGE = 'Value will auto calculate based on subsequent value inputs.';

export const OWNER_ATTRIBUTES_KEY = 'ownerAttributes';
export const CONSUMER_ATTRIBUTES_KEY = 'consumerAttributes';
export const LOSS_ATTRIBUTES_KEY = 'lossAttributes';
export const CUSTOM_ATTRIBUTES_KEY = 'customAttributes';

export enum TAB_IDS {
  REQUIRED_TAB_ID = 'required-attributes-tab',
  ADDITIONAL_TAB_ID = 'additional-attributes-tab',
  DOCUMENTS_TAB_ID = 'documents-tab',
}
