import React, { ReactNode, useEffect, useState } from 'react';
import { EuiScreenReaderOnly } from '@elastic/eui';
import { ButtonIcon, EuiBasicTable, EuiBasicTableColumn, Spinner } from 'ui';
import { Property } from '@app/graphql/types';
import { useCopilotContext } from '../../context/context';
import { AttributesTableContainer } from './AttributeTable.emotion';
import { PropertyDetails } from './PropertyDetails';
import { AttributeTableRow } from './types';
import { Feedback } from '../../types';

interface Props {
  disabled?: boolean;
  items: Array<AttributeTableRow>;
  onCorrection: (attributeName: string, value: string) => void;
  property: Property;
  onRating: (attributeName: string, rate: Feedback, finalAnswer: any) => void;
  onPropertyUpdate: () => void;
  loading?: boolean;
  expanded?: boolean;
  onRowExpanded?: (item: AttributeTableRow) => void;
  onRowClosed?: (item: AttributeTableRow) => void;
}

export const AttributesTable: React.FC<Props> = ({
  disabled,
  items,
  onRating,
  loading,
  expanded,
  onRowExpanded = () => {},
  onRowClosed = () => {},
}) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<Record<string, ReactNode>>(
    {},
  );

  useEffect(() => {
    if (expanded) {
      const item = items?.[0];
      setItemIdToExpandedRowMap({ [item?.id]: getDetails(item) });
      return;
    }

    const rowMap = {};
    items.forEach((item) => {
      if (item.expandedByDefault) {
        rowMap[item.id] = getDetails(item);
      }
    });

    setItemIdToExpandedRowMap(rowMap);
  }, [expanded, JSON.stringify(items)]);

  const getDetails = (attribute: AttributeTableRow) => {
    const { explanation, copilotValue } = attribute;
    if (loading) {
      return <Spinner />;
    }
    if (!copilotValue && !explanation) {
      return <div>No available answer</div>;
    }

    return <PropertyDetails attribute={attribute} onRating={onRating} />;
  };

  useEffect(() => {
    if (expanded) {
      const item = items?.[0];
      setItemIdToExpandedRowMap({ [item?.name]: getDetails(item) });
    } else {
      setItemIdToExpandedRowMap({});
    }
  }, [expanded, JSON.stringify(items)]);

  const {
    workspaceData: { tabNavigationStack },
    sovPropertyQuery,
  } = useCopilotContext();

  const currentTab = tabNavigationStack[tabNavigationStack.length - 1];
  const columns: Array<EuiBasicTableColumn<AttributeTableRow>> = [
    { field: 'displayName', name: 'Attribute' },
    {
      field: 'sovValue',
      name: 'SOV value',
      render: (v) => (sovPropertyQuery?.loading ? <Spinner /> : v),
    },
    {
      field: 'currentValue',
      name: 'Current value',
    },
    {
      field: 'copilotValue',
      name: currentTab === 'fullReport' ? 'Extracted value' : 'Copilot value',
    },
  ];

  const toggleDetails = (attribute: any) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

    if (itemIdToExpandedRowMapValues[attribute.id]) {
      delete itemIdToExpandedRowMapValues[attribute.id];
      onRowClosed(attribute);
    } else {
      itemIdToExpandedRowMapValues[attribute.id] = getDetails(attribute);
      onRowExpanded(attribute);
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const columnsWithExpandingRowToggle: Array<EuiBasicTableColumn<any>> = [
    ...columns,
    {
      align: 'right',
      isExpander: true,
      mobileOptions: { header: false },
      name: (
        <EuiScreenReaderOnly>
          <span>Expand row</span>
        </EuiScreenReaderOnly>
      ),
      render: (attribute: AttributeTableRow) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

        return (
          <ButtonIcon
            disabled={disabled}
            onClick={() => toggleDetails(attribute)}
            aria-label={itemIdToExpandedRowMapValues[attribute.id] ? 'Collapse' : 'Expand'}
            iconName={itemIdToExpandedRowMapValues[attribute.id] ? 'arrowDown' : 'arrowRight'}
          />
        );
      },
      width: '40px',
    },
  ];

  return (
    <AttributesTableContainer>
      <EuiBasicTable
        tableCaption="Demo of EuiBasicTable"
        itemId="id"
        items={items}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        rowHeader="displayName"
        columns={columnsWithExpandingRowToggle}
        loading={loading}
        rowProps={(item) => ({ className: item.rating ? `rated-row-${item.rating}` : undefined })}
      />
    </AttributesTableContainer>
  );
};
