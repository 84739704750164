import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { Button, EuiFlexGroup, EuiFlexItem, EuiToolTip } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { PrecheckRecommendationsButton } from '@app/cx/Stream/EditModal/Tabs/SnapshotsTab/PrecheckRecommendationsButton';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';
import {
  IGetSnapshotConfigData,
  IGetSnapshotConfigVariables,
} from '@app/queries/streams/snapshots/types';
import { ImportExportRecommendations } from './ImportExportRecommendations';

const withToolTip = (disabled, Component) => {
  if (disabled) {
    return (
      <EuiToolTip
        content="Add a visible snapshot that has recommendations generated on it using the snapshot tab before using precheck."
        position="top"
      >
        {Component}
      </EuiToolTip>
    );
  }

  return Component;
};

export const RegisterUserForm = ({ stream }) => {
  const { selectedOrganization } = useUserSession();

  const history = useHistory();
  const { closeModal } = useContext(ModalContext);

  const { loading: configLoading, data } = useQuery<
    IGetSnapshotConfigData,
    IGetSnapshotConfigVariables
  >(GET_STREAM_SNAPSHOT_CONFIG, { variables: { streamSlug: stream.slug } });

  const publicSnapshot = data?.snapshotConfig?.publishedSnapshotId;
  const snapshot = data?.snapshotConfig?.snapshotsInfo?.find(
    (info) =>
      info.snapshot.id === publicSnapshot &&
      info.propertyRecommendationStatus?.mlRecommendationsStatus === 'READY',
  );

  return (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem
              grow={false}
              // FIX ME
              // @ts-ignore
              direction="row"
            >
              {withToolTip(
                configLoading || !snapshot,
                <Button
                  label={`View ${STREAMS_LABEL}'s precheck`}
                  loading={configLoading}
                  disabled={configLoading || !snapshot}
                  onClick={() => {
                    closeModal();
                    history.push({
                      pathname: encodeUrl`/organizations/${selectedOrganization.name}/precheck/`,
                    });
                  }}
                />,
              )}
            </EuiFlexItem>
            <PrecheckRecommendationsButton streamSlug={stream.slug} />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <ImportExportRecommendations stream={stream} snapshots={data} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
