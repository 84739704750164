import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadDocumentMutationVariables = Types.Exact<{
  document: Types.Scalars['Upload']['input'];
  orgID: Types.Scalars['ID']['input'];
  streamID?: Types.InputMaybe<Types.Scalars['String']['input']>;
  notify?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  suggestions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type UploadDocumentMutation = { __typename?: 'Mutation', upload: { __typename?: 'Document', id: string } };


export const UploadDocumentDocument = gql`
    mutation uploadDocument($document: Upload!, $orgID: ID!, $streamID: String, $notify: Boolean, $suggestions: Boolean) {
  upload(
    document: $document
    orgID: $orgID
    streamID: $streamID
    notify: $notify
    suggestions: $suggestions
  ) {
    id
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *      orgID: // value for 'orgID'
 *      streamID: // value for 'streamID'
 *      notify: // value for 'notify'
 *      suggestions: // value for 'suggestions'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;