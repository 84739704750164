import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CasualtyFragment = { __typename?: 'Casualty', id: string, organizationId: string, orgName: string, accountOrgId: string, accountOrgName: string, accountStatus?: string | null, analysisDate?: any | null, clientName?: string | null, clientNetWorth?: number | null, clientProspect?: Types.ClientProspect | null, typeOfBusiness?: string | null, sicCode?: string | null, naicsCode?: string | null, analysisNotes?: string | null, primaryContactEmail?: string | null, office?: string | null, region?: string | null, secondaryContactEmail?: string | null, requestedLinesOfBusiness?: Array<{ __typename?: 'requestedLinesOfBusiness', lineOfBusiness?: Types.LineOfBusiness | null, retention?: number | null, limit?: number | null, renewalOrRfpEffectiveDate?: any | null }> | null, exposures?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, exposureBase?: { __typename?: 'exposureBase', al?: string | null, wc?: string | null, gl?: Types.ExposureBaseInputGl | null } | null, retentions?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, deductibleAggregateLimit?: Array<{ __typename?: 'historicalExposure', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: number | null, wc?: number | null, gl?: number | null }> | null, policyEffectiveDates?: Array<{ __typename?: 'policyEffectiveDates', policyEffectiveYear?: Types.PolicyEffectiveYearId | null, al?: any | null, wc?: any | null, gl?: any | null }> | null };

export const CasualtyFragmentDoc = gql`
    fragment casualty on Casualty {
  id
  organizationId
  orgName
  accountOrgId
  accountOrgName
  accountStatus
  analysisDate
  clientName
  clientNetWorth
  clientProspect
  typeOfBusiness
  sicCode
  naicsCode
  analysisNotes
  primaryContactEmail
  office
  region
  secondaryContactEmail
  requestedLinesOfBusiness {
    lineOfBusiness
    retention
    limit
    renewalOrRfpEffectiveDate
  }
  exposures {
    policyEffectiveYear
    al
    wc
    gl
  }
  exposureBase {
    al
    wc
    gl
  }
  retentions {
    policyEffectiveYear
    al
    wc
    gl
  }
  deductibleAggregateLimit {
    policyEffectiveYear
    al
    wc
    gl
  }
  policyEffectiveDates {
    policyEffectiveYear
    al
    wc
    gl
  }
}
    `;