import { useQuery } from '@apollo/client';
import {
  GetQualityData,
  GetQualityVariables,
  HazardCategory,
} from '@onarchipelago/cx/Reports/queries/getQuality';
import { IUnderwritingAssessmentReportContext } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/underwritingAssessmentReportContext';
// FIX ME
// @ts-ignore
import GET_QUALITY_SNAPSHOT_OVERALL from '@app/queries/streams/getQualitySnapshotOverall.gql';
import { RiskAttribute } from '../UnderwritingAssessmentReport/InsurerInsights/tabs/GradingDetails/types';

export default (
  streamSlug: string,
  snapshotName: string,
  tabName: 'grading-overview' | 'grading-details' | 'loss-history-details',
  gradingDetailsSection: RiskAttribute,
  gradingDetailsFilter: string,
  setTabName: (tabName: string) => void,
  setGradingSection: (value: RiskAttribute) => void,
  setGradingFilter: (value: string) => void,
): IUnderwritingAssessmentReportContext => {
  const { data, error, loading } = useQuery<GetQualityData, GetQualityVariables>(
    GET_QUALITY_SNAPSHOT_OVERALL,
    {
      variables: {
        input: {
          hazard: HazardCategory.All,
          snapshotName,
          streamSlug,
        },
      },
    },
  );

  return {
    gradingDetailsFilter,
    gradingDetailsSection,
    insurerInsightsTab: tabName,
    overviewData: data,
    overviewDataError: error,
    overviewDataIsLoading: loading,
    setGradingFilter,
    setGradingSection,
    setInsurerInsightsTab: setTabName,
  };
};
