import { css } from '@emotion/css';
import styled from '@emotion/styled';

const listContainer = css`
  border: 1px solid #e0e0e0;
  display: flex;
  flex: 1 1 1px;
  overflow-y: auto;
`;

const reefContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
`;

export const SearchContainer = styled.div`
  margin-bottom: 8px;
`;

export default {
  listContainer,
  reefContainer,
};
