import { AttributeEditInfo, Property } from '@app/graphql/types';
import { IGraphQLProperty } from '@app/queries/properties/generated_types';

export const getSourceType = (source: string): string | undefined => {
  if (!source) {
    return undefined;
  }

  const arr = source.split(/derived[ ]+from[ ]+/i);
  if (arr.length < 2) {
    return undefined;
  }

  return arr[1];
};

export const getEditRecords = (
  property: IGraphQLProperty | Property,
  attributeName: string,
): Array<AttributeEditInfo> =>
  property?.attributeEdits?.filter((e) => e.attributeName === attributeName);
