import React from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from 'ui';

const AddRuleAction: React.FC<ActionWithRulesProps> = ({ handleOnClick }) => (
    <Button
      data-testid="query-builder-wrapper-add-rule-action"
      label="Add Rule"
      onClick={handleOnClick}
      size="s"
    />
  );

export default AddRuleAction;
