import { number, object, string } from 'yup';
import {
  CITY_INPUT_KEY,
  CITY_LABEL,
  COUNTRY_INPUT_KEY,
  COUNTRY_LABEL,
  COUNTY_INPUT_KEY,
  COUNTY_LABEL,
  LAT_INPUT_KEY,
  LNG_INPUT_KEY,
  STATE_INPUT_KEY,
  STATE_LABEL,
  STREET_ADDRESS_INPUT_KEY,
  STREET_ADDRESS_LABEL,
} from '@app/cx/Stream/AddPropertyFlyout/constants';
import { fourDecimalTest, makeRequiredMessage } from '@app/cx/Stream/AddPropertyFlyout/yupSchema';

const getAddressFieldValidation = (label: string) =>
  string().required(makeRequiredMessage(label)).typeError(makeRequiredMessage(label));

export const yupSchema = object({
  [CITY_INPUT_KEY]: getAddressFieldValidation(CITY_LABEL),
  [COUNTRY_INPUT_KEY]: getAddressFieldValidation(COUNTRY_LABEL),
  [COUNTY_INPUT_KEY]: getAddressFieldValidation(COUNTY_LABEL),
  [LAT_INPUT_KEY]: number()
    .nullable(true)
    .min(-90, 'Latitude must be greater than -90.')
    .max(90, 'Latitude must be less than 90')
    .test(
      'lng-has-at-least-4-decimals',
      'Latitude should have at least 4 decimals for precision.',
      fourDecimalTest,
    ),
  [LNG_INPUT_KEY]: number()
    .nullable(true)
    .min(-180, 'Longitude must be greater than -180.')
    .max(180, 'Longitude must be less than 180')
    .test(
      'lat-has-at-least-4-decimals',
      'Longitude should have at least 4 decimals for precision.',
      fourDecimalTest,
    ),
  [STATE_INPUT_KEY]: string()
    .nullable(true)
    .when(COUNTRY_INPUT_KEY, {
      is: 'United States',
      then: getAddressFieldValidation(STATE_LABEL),
    }),
  [STREET_ADDRESS_INPUT_KEY]: getAddressFieldValidation(STREET_ADDRESS_LABEL),
}).required();
