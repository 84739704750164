import { formatGeoCode } from '@app/components/PropertiesGrid/GridCell/Geocode';
import { IGraphQLTIVChart } from '@app/queries/explorers/types';

export interface ITIVChartsBarMetadata {
  nameFormatter: (name: string) => string;
  colorFormatter: (name: string) => string;
  barIndexFormatter: (chart: IGraphQLTIVChart) => IGraphQLTIVChart;
}

const geoCodeColors = {
  Address: '#27AE60',
  County: '#BAEFCD',
  'Postal Code': '#6FCF97',
  'Roof Centroid': '#219653',
  State: '#E0F5E9',
  Street: '#37D279',
  Unknown: '#E0E0E0',
};

const sortDecreasing = (chart: IGraphQLTIVChart) => ({
  ...chart,
  values: [...chart.values].sort((a: any, b: any) => {
    if (a.label < b.label) {
      return 1;
    }
    if (a.label > b.label) {
      return -1;
    }
    return 0;
  }),
});

const sortIncreasing = (chart: IGraphQLTIVChart) => ({
  ...chart,
  values: [...chart.values].sort((a: any, b: any) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }),
});

const knownValidatedColors = {
  Known: '#27AE60',
  Unknown: '#E0E0E0',
  Validated: '#219653',
};
const knownValidatedMeta: ITIVChartsBarMetadata = {
  barIndexFormatter: sortIncreasing,
  colorFormatter: (name: string) => knownValidatedColors[name] || '#BDBDBD',
  nameFormatter: (name: string) => (name === 'Coded' ? 'Known' : name),
};

const tivChartsBarMetadata: { [index: string]: ITIVChartsBarMetadata } = {
  quality_geo_code_type: {
    barIndexFormatter: sortDecreasing,
    colorFormatter: (name: string) => geoCodeColors[name] || '#BDBDBD',
    nameFormatter: formatGeoCode,
  },
};

export const getBarMetadata = (explorerName: string): ITIVChartsBarMetadata =>
  tivChartsBarMetadata[explorerName] || knownValidatedMeta;
