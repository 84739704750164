import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip,Icon, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useReportsContext } from '../../contexts/reportsContext/reportsContext';
import { formatNumberValueToString } from '../utils/valuationsSummaryUtils';
import { useValuationSummaryReportContext } from '../ValuationSummaryReportContext';
import { BRCComparison } from './BRCComparison';
import { SUBHEADER_TOOLTIP } from './constants';
import {
  subeaderTooltipTextStyle,
  subheaderTooltipContainerStyle,
  subtitleStyle,
  tooltipAnchorStyle,
} from './ProvidedvsE2ValueSummary.emotion';
import ReplacementCostComparison from './ReplacementCostComparison';

const ProvidedvsE2VALUESummary: FC = () => {
  const { brcComparison, brcSummary, summaryData, loading } = useValuationSummaryReportContext();
  const { stream } = useReportsContext();
  const { displayCurrency } = stream;
  if (loading) {
    return (
      <LoadingSpinnerV2
        dataTestId="provided-vs-e2-value-summary-loading-spinner"
        styles={{ height: '313px' }}
      />
    );
  }

  const percentOfBRCIncluded = summaryData?.percentOfBRCIncluded
    ? `${formatNumberValueToString(summaryData?.percentOfBRCIncluded)}%`
    : 'N/A';

  return (
    <EuiFlexItem grow={false}>
      <>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            direction="column"
            alignItems="flexStart"
            justifyContent="center"
            gutterSize="s"
          >
            <EuiFlexItem grow={false}>
              <EuiText size="m">
                <h3>Provided building values vs. third party estimates</h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexGroup
              style={subtitleStyle}
              direction="row"
              alignItems="center"
              gutterSize="none"
            >
              <EuiFlexItem grow={false} style={subheaderTooltipContainerStyle}>
                <EuiToolTip anchorClassName={tooltipAnchorStyle} content={SUBHEADER_TOOLTIP}>
                  <Icon color="primary" name="info"></Icon>
                </EuiToolTip>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={subeaderTooltipTextStyle}>
                <EuiText size="m">
                  These valuations reflect {percentOfBRCIncluded} of the total{' '}
                  <b>Replacement Cost Value (RCV)</b> in this portfolio.
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexItem>
        <Spacer size="m" />
        <EuiFlexGroup
          direction="row"
          justifyContent="flexStart"
          style={{
            borderTop: '1px solid #E5E5E5',
            marginTop: '4px',
            paddingTop: '16px',
          }}
        >
          <BRCComparison brcSummary={brcSummary} />
          <EuiFlexItem>
            <ReplacementCostComparison
              comparisonMetrics={brcComparison}
              displayCurrency={displayCurrency}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <Spacer size="l" />
        <EuiFlexGroup direction="row" justifyContent="flexStart">
          <EuiFlexItem grow={false}>
            <EuiText size="s" color="subdued">
              Our third-party partner (e2Value) calculates estimates using location, occupancy,
              construction, year built, and square footage.
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </EuiFlexItem>
  );
};

export default ProvidedvsE2VALUESummary;
